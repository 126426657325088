import { Tooltip } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid-premium"
import { defineMessages } from "react-intl"

import { commonMessages } from "~/common-messages"
import { SafeFormattedMessage, StatusChip, Tags } from "~/components"
import { DateComponent } from "~/components/Date/DateComponent"
import { OrganizationName } from "~/components/OrganizationName"
import { UserName } from "~/components/UserName"
import { useOrganizationTagGroups } from "~/domains/analytics/tags/hooks/useOrganizationTagGroups"
import { AmountInList } from "~/domains/transactions/_shared/components/AmountInList"
import { columnsLabels } from "~/domains/transactions/invoices/components/list/InvoicesDataGrid/columnsLabels"
import { useTagsWithTagGroupName } from "~/domains/transactions/invoices/components/list/InvoicesList/useTagsWithTagGroupName"
import { PurchaseOrders } from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { useSafeIntl } from "~/hooks/useSafeIntl"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"

const messages = defineMessages({
    status: { id: "purchase.list.column.status", defaultMessage: "Status" },
    fulfillment: { id: "purchase.list.column.fulfillment", defaultMessage: "Fulfillment" },
    supplierName: { id: "purchase.list.column.supplierName", defaultMessage: "Supplier" },
    buyerName: { id: "purchase.list.column.buyerName", defaultMessage: "Buyer" },
    creationDate: { id: "purchase.list.column.creationDate", defaultMessage: "creationDate" },
    expectedDeliveryDate: { id: "purchase.list.column.expectedDeliveryDate", defaultMessage: "Delivery date" },
    amountExclTaxes: { id: "purchase.list.column.amountExclTaxes", defaultMessage: "Total (excl. taxes)" },
    requesterName: { id: "purchase.list.column.requesterName", defaultMessage: "Requester" },
    description: { id: "purchase.list.column.description", defaultMessage: "Description" },
    lines: { id: "purchase.list.column.lines", defaultMessage: "Lines" },
})

export const useGetPurchaseOrdersListTableColumns = (): GridColDef<PurchaseOrders>[] => {
    const { formatMessageCapitalized } = useSafeIntl()

    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const { tagGroups } = useOrganizationTagGroups(currentOrganizationId || "")
    const tags = useTagsWithTagGroupName(tagGroups)

    return [
        {
            field: "statusPresentation" satisfies keyof PurchaseOrders,
            headerName: formatMessageCapitalized(messages.status),
            type: "string",
            renderHeader: () => <SafeFormattedMessage {...messages.status} />,
            renderCell: ({ row }) =>
                row.statusPresentation && (
                    <StatusChip status={row.statusPresentation}>{row.statusPresentation}</StatusChip>
                ),
        },
        {
            field: "fulfillmentPresentation" satisfies keyof PurchaseOrders,
            headerName: formatMessageCapitalized(messages.fulfillment),
            type: "string",
            renderHeader: () => <SafeFormattedMessage {...messages.fulfillment} />,
            renderCell: ({ row }) =>
                row.fulfillmentPresentation && (
                    <StatusChip status={row.fulfillmentPresentation}>{row.fulfillmentPresentation}</StatusChip>
                ),
        },
        {
            field: "expectedDeliveryDate" satisfies keyof PurchaseOrders,
            headerName: formatMessageCapitalized(messages.expectedDeliveryDate),
            type: "date",
            renderHeader: () => <SafeFormattedMessage {...messages.expectedDeliveryDate} />,
            renderCell: ({ row }) => <DateComponent value={row.expectedDeliveryDate} />,
            valueGetter: ({ row }) => row.expectedDeliveryDate && new Date(row.expectedDeliveryDate),
        },
        {
            field: "vendor",
            headerName: formatMessageCapitalized(messages.supplierName),
            type: "string",
            renderHeader: () => <SafeFormattedMessage {...messages.supplierName} />,
            renderCell: ({ row }) => <OrganizationName organizationId={row.supplierId} />,
            hideable: true,
        },
        {
            field: "shortId" satisfies keyof PurchaseOrders,
            headerName: formatMessageCapitalized(columnsLabels.labelReference),
            type: "string",
            renderHeader: () => <SafeFormattedMessage {...columnsLabels.labelReference} />,
        },
        {
            field: "description" satisfies keyof PurchaseOrders,
            headerName: formatMessageCapitalized(messages.description),
            type: "string",
            flex: 2,
            renderHeader: () => <SafeFormattedMessage {...messages.description} />,
            renderCell: ({ row }) => (
                <Tooltip title={`${row.shortId} ${row.description ? `- ${row.description}` : ""}`}>
                    <div>{row.description ?? "-"}</div>
                </Tooltip>
            ),
        },
        {
            field: "requesterUser",
            type: "string",
            flex: 1,
            renderHeader: () => <SafeFormattedMessage {...messages.requesterName} />,
            renderCell: ({ row }) => row.requesterUserId && <UserName userId={row.requesterUserId} />,
        },
        {
            field: "client",
            type: "string",
            renderHeader: () => <SafeFormattedMessage {...messages.buyerName} />,
            renderCell: ({ row }) => <OrganizationName organizationId={row.buyerId} />,
        },
        {
            field: "tags" satisfies keyof PurchaseOrders,
            type: "string",
            filterable: false,
            renderHeader: () => <SafeFormattedMessage {...commonMessages.tags} />,
            renderCell: ({ row }) => <Tags organizationTags={tags} entityTagIds={row.tagIds} />,
            valueGetter: ({ row }) =>
                row.tagIds ? row.tagIds.map((tagId) => tags.find((tag) => tag.id === tagId)?.name).join(", ") : "",
        },
        {
            field: "invoiceNumber" satisfies keyof PurchaseOrders,
            headerName: formatMessageCapitalized(commonMessages.invoice),
            type: "string",
            renderHeader: () => <SafeFormattedMessage {...commonMessages.invoice} />,
        },
        {
            field: "lineCount" satisfies keyof PurchaseOrders,
            type: "number",
            width: 40,
            renderHeader: () => <SafeFormattedMessage {...messages.lines} />,
            valueGetter: ({ row }) => row.lineCount || row.lines.length,
        },
        {
            field: "totalAmount" satisfies keyof PurchaseOrders,
            type: "number",
            renderHeader: () => <SafeFormattedMessage {...messages.amountExclTaxes} />,
            renderCell: ({ row }) => <AmountInList amount={row.totalAmount} currency={row.currency} />,
        },
        {
            field: "createdAt" satisfies keyof PurchaseOrders,
            headerName: formatMessageCapitalized(messages.creationDate),
            type: "date",
            renderHeader: () => <SafeFormattedMessage {...messages.creationDate} />,
            renderCell: ({ row }) => <DateComponent value={row.creationDate} />,
            valueGetter: ({ row }) => row.creationDate && new Date(row.creationDate),
        },
    ]
}
