import { Box, Stack } from "@mui/material"
import {
    DataGridPremium,
    DataGridPremiumProps,
    GridColumnVisibilityModel,
    GridInitialState,
    GridPaginationModel,
    GridRowParams,
    GridSortModel,
    GridState,
    MuiEvent,
    useGridApiRef,
} from "@mui/x-data-grid-premium"
import { arSD, deDE, enUS, frFR, nbNO, plPL, ptBR, roRO, zhCN } from "@mui/x-data-grid-premium"
import { RiDownload2Line, RiFilter3Line } from "@remixicon/react"
import {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"

import { Card } from "~/components"
import { DataGridFooter } from "~/components/DataGrid/DataGridFooter"
import { DataGridPanelModal } from "~/components/DataGrid/DataGridPanelModal"
import { DataGridToolbar } from "~/components/DataGrid/DataGridToolbar"
import { TagObjectRecordI } from "~/domains/analytics/tags/types/TagObjectRecord"
import { globalActions, selectDataGridCommonState, selectDataGridStateByStateKey } from "~/store/global/globalSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "~/utils"

const customLocaleText = new Map([
    ["ar", arSD.components.MuiDataGrid.defaultProps.localeText],
    ["pt", ptBR.components.MuiDataGrid.defaultProps.localeText],
    ["zh", zhCN.components.MuiDataGrid.defaultProps.localeText],
    ["de", deDE.components.MuiDataGrid.defaultProps.localeText],
    ["fr", frFR.components.MuiDataGrid.defaultProps.localeText],
    ["en", enUS.components.MuiDataGrid.defaultProps.localeText],
    ["nb", nbNO.components.MuiDataGrid.defaultProps.localeText],
    ["pl", plPL.components.MuiDataGrid.defaultProps.localeText],
    ["ro", roRO.components.MuiDataGrid.defaultProps.localeText],
])

const AUTO_SIZE_OPTIONS = {
    includeHeaders: true,
    includeOutliers: true,
    outliersFactor: 1,
    expand: false,
}

interface DataGridPremiumWithStateProps extends DataGridPremiumProps {
    stateKey: string
    initialState?: GridInitialState
    paginationModel?: GridPaginationModel
    tags?: TagObjectRecordI
    hideToolbar?: boolean
    hideFooter?: boolean
    disableExport?: boolean
    pageSizeOptions?: number[]
    wrapInCard?: boolean
    onColumnVisibilityModelChange?: (columnVisibilityModel: GridColumnVisibilityModel) => void
    onPaginationModelChange?: (paginationModel: GridPaginationModel) => void
    onSortModelChange?: (sortModel: GridSortModel) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleGetSecondaryClickTarget?: (params: GridRowParams<any>) => string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onRowClick?: (params: GridRowParams<any>, event: MuiEvent<React.MouseEvent<HTMLElement>>) => void
}

export interface DataGridPremiumWithStateRef {
    api: ReturnType<typeof useGridApiRef>["current"] | null
}
export const DataGridPremiumWithState = forwardRef<DataGridPremiumWithStateRef, DataGridPremiumWithStateProps>(
    (
        {
            stateKey,
            initialState = {},
            paginationModel,
            tags,
            hideToolbar,
            hideFooter,
            disableExport,
            pageSizeOptions = PAGE_SIZE_OPTIONS,
            wrapInCard = true,
            onColumnVisibilityModelChange,
            onPaginationModelChange,
            onSortModelChange,
            handleGetSecondaryClickTarget,
            onRowClick,
            ...props
        },
        ref
    ) => {
        const { locale } = useIntl()
        const navigate = useNavigate()
        const gridRef = useRef<HTMLDivElement>(null)
        const [isApiReady, setIsApiReady] = useState(false)
        const [gridSortModel, setGridSortModel] = useState<GridSortModel>(initialState.sorting?.sortModel || [])
        const [isFilterModalOpen, setIsFilterModalOpen] = useState(false)
        const [isColumnsModalOpen, setIsColumnsModalOpen] = useState(false)

        const dataGridApiRef = useGridApiRef()

        const dispatch = useAppDispatch()
        const gridInitialState = {
            ...initialState,
            ...useAppSelector((state) => selectDataGridStateByStateKey(state, stateKey)),
        }
        const gridCommonState = useAppSelector(selectDataGridCommonState)

        const localeText = customLocaleText.has(locale) ? customLocaleText.get(locale) : customLocaleText.get("en")

        // Save state to Redux store
        useLayoutEffect(() => {
            const saveDataGridState = () => {
                if (!dataGridApiRef?.current?.exportState) return

                const currentState = dataGridApiRef.current.exportState()
                dispatch(globalActions.setDataGridState({ stateKey, state: currentState }))
            }

            window.addEventListener("beforeunload", saveDataGridState)

            return () => {
                window.removeEventListener("beforeunload", saveDataGridState)
                saveDataGridState()
            }
        }, [dataGridApiRef, stateKey, dispatch])

        // Auto size columns
        const autosizeColumns = useCallback(() => {
            if (!dataGridApiRef?.current?.autosizeColumns) return

            dataGridApiRef.current.autosizeColumns(AUTO_SIZE_OPTIONS)
        }, [dataGridApiRef])

        useLayoutEffect(() => {
            const rafId = window.requestAnimationFrame(autosizeColumns)

            return () => {
                window.cancelAnimationFrame(rafId)
            }
        }, [autosizeColumns])

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleSecondaryClickRow = (param: GridRowParams<any>, event: MuiEvent<React.MouseEvent<HTMLElement>>) => {
            if (!handleGetSecondaryClickTarget) {
                onRowClick?.(param, event)
                return
            }

            // ctrl + click open the invoice in a new tab
            const url = handleGetSecondaryClickTarget?.(param)

            if (event.ctrlKey || event.metaKey) {
                window.open(url, "_blank")
                return
            }

            navigate(url)
        }

        const handleSortModelChange = (newSortModel: GridSortModel) => {
            setGridSortModel(newSortModel)
            onSortModelChange?.(newSortModel)
            autosizeColumns()
        }

        const handleStateChange = (newState: GridState) => {
            const newDensity = newState.density.value
            dispatch(globalActions.setDataGridCommonState({ density: newDensity }))
        }

        useImperativeHandle(
            ref,
            () => ({
                api: isApiReady ? dataGridApiRef.current : null,
            }),
            [dataGridApiRef, isApiReady]
        )

        // Add this effect to track API readiness
        useEffect(() => {
            if (!dataGridApiRef.current) return

            if (gridInitialState.sorting?.sortModel?.length) {
                setGridSortModel(gridInitialState.sorting?.sortModel)
            }
            setIsApiReady(true)
        }, [dataGridApiRef, gridInitialState.sorting?.sortModel])

        // Set initial pagination model
        useEffect(() => {
            const initialPaginationModel = gridInitialState?.pagination?.paginationModel
            const initialPageSize = initialPaginationModel?.pageSize || DEFAULT_PAGE_SIZE
            const initialPage = initialPaginationModel?.page || 0

            if (
                onPaginationModelChange &&
                initialPaginationModel &&
                (initialPage !== paginationModel?.page || initialPageSize !== paginationModel?.pageSize)
            ) {
                onPaginationModelChange(initialPaginationModel as GridPaginationModel)
            }
            // This should trigger only once at initial render
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        const MemoizedPanel = useMemo(() => {
            const closePanelModal = () => {
                setIsFilterModalOpen(false)
                setIsColumnsModalOpen(false)
            }

            const PanelModal = () => (
                <DataGridPanelModal
                    open={{ filters: isFilterModalOpen, columns: isColumnsModalOpen }}
                    onClose={closePanelModal}
                />
            )

            return PanelModal
        }, [isFilterModalOpen, isColumnsModalOpen])

        const MemoizedToolbarElement = useMemo(() => {
            const openFiltersModal = () => setIsFilterModalOpen(true)
            const openColumnsModal = () => setIsColumnsModalOpen(true)

            const CustomToolbar = () =>
                hideToolbar ? undefined : (
                    <DataGridToolbar
                        openFilters={openFiltersModal}
                        openColumns={openColumnsModal}
                        tags={tags}
                        disableExport={disableExport}
                        {...props}
                    />
                )

            return CustomToolbar
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [hideToolbar, tags, disableExport])

        const MemoizedFooterElement = useMemo(() => {
            const CustomFooter = () => (hideFooter ? undefined : <DataGridFooter {...props} />)

            return CustomFooter
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [hideFooter])

        const Wrapper = wrapInCard ? Card : Box

        return (
            <Stack ref={gridRef}>
                <Wrapper sx={props.sx}>
                    {isApiReady && (
                        <DataGridPremium
                            {...props}
                            apiRef={dataGridApiRef}
                            initialState={gridInitialState}
                            sortModel={gridSortModel}
                            paginationModel={paginationModel}
                            density={gridCommonState.density}
                            slots={{
                                toolbar: MemoizedToolbarElement,
                                footer: MemoizedFooterElement,
                                panel: MemoizedPanel,
                                exportIcon: () => <RiDownload2Line size={18} />,
                                openFilterButtonIcon: () => <RiFilter3Line size={18} />,
                                columnFilteredIcon: () => <RiFilter3Line size={18} />,
                                ...props.slots,
                            }}
                            onColumnVisibilityModelChange={onColumnVisibilityModelChange}
                            onSortModelChange={handleSortModelChange}
                            onStateChange={handleStateChange}
                            onPaginationModelChange={onPaginationModelChange}
                            onRowClick={handleSecondaryClickRow}
                            localeText={localeText}
                            pageSizeOptions={pageSizeOptions}
                        />
                    )}
                </Wrapper>
            </Stack>
        )
    }
)

DataGridPremiumWithState.displayName = "DataGridPremiumWithState"
