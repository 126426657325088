import { Stack, TextField, Tooltip, Typography } from "@mui/material"
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium"
import cls from "classnames"
import React, { FC, useMemo } from "react"
import { Trash2 } from "react-feather"
import { useIntl } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import { IconButton } from "~/domains/orchestration/flows/components/IconButton"
import { AdvancedFields } from "~/domains/orchestration/flows/components/configuration/AdvancedFields"
import {
    MappingFooter,
    MappingGrid,
    MappingHeader,
} from "~/domains/orchestration/flows/components/configuration/MappingComponents"
import { useMappingConfiguration } from "~/domains/orchestration/flows/hooks"
import { messages } from "~/domains/orchestration/flows/locale"
import { ConfigurationProps, MappingNode } from "~/domains/orchestration/flows/types"
import { GridRow } from "~/domains/orchestration/flows/types/Editor"

import { ConfigurationNode } from "./ConfigurationNode"

export const MappingConfiguration: FC<ConfigurationProps<MappingNode>> = ({
    selectedNode,
    advancedFields,
    validateNode,
}): JSX.Element => {
    const {
        newMappingKey,
        hasKeyError,
        isEditing,
        cellModesModel,
        currentNode,
        setCurrentNode,
        gridRef,
        handleCustomCellClick,
        handleValueToMapChange,
        handleUpdateDefaultLabel,
        handleAddMappingKey,
        handleRemoveMappingKey,
        handleNewMappingKeyChange,
        handleKeyDown,
        handleRemoveDefaultValue,
        handleCellModesModelChange,
        handleCellEditStop,
        handleAddMappingValue,
    } = useMappingConfiguration(selectedNode)

    const { formatMessage } = useIntl()

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    // Prepare columns for the data grid
    const columns = useMemo<GridColDef[]>(() => {
        const keyColumn: GridColDef = {
            field: "key",
            headerName: "Key",
            width: 200,
            filterable: true,
            sortable: false,
            editable: true,
            renderCell: (params: GridRenderCellParams<GridRow>) => {
                const isDefaultRow = params.row.id === "default"

                if (isDefaultRow) {
                    return (
                        <Typography variant="caption">
                            <SafeFormattedMessage {...messages.mappingConfiguration.defaultValuesTitle} />
                        </Typography>
                    )
                }

                const handleRemoveRow = () => {
                    handleRemoveMappingKey(params.row.id)
                }

                return (
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Tooltip title={formatMessage(messages.mappingConfiguration.removeRowTooltip)}>
                            <span>
                                <IconButton size="small" onClick={handleRemoveRow} type="grey-light">
                                    <Trash2 size={14} />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Typography variant="caption">{params.row.key}</Typography>
                    </Stack>
                )
            },
        }

        const valueColumns = currentNode.defaultValues.map(
            (defaultValue, index): GridColDef => ({
                field: `value${index}`,
                headerName: defaultValue.label,
                width: 200,
                filterable: true,
                sortable: false,
                editable: true,

                renderHeader: () => (
                    <Stack direction="row" alignItems="center" spacing={1} height={40}>
                        <TextField
                            size="small"
                            fullWidth
                            value={defaultValue.label}
                            onChange={handleUpdateDefaultLabel(index)}
                        />
                        <Tooltip title={formatMessage(messages.mappingConfiguration.removeColumnTooltip)}>
                            <span>
                                <IconButton size="small" onClick={handleRemoveDefaultValue(index)} type="grey-light">
                                    <Trash2 size={14} />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Stack>
                ),
                renderCell: (params: GridRenderCellParams<GridRow>) => {
                    const isDefaultRow = params.row.id === "default"
                    const key = params.row.key
                    const value = isDefaultRow
                        ? currentNode.defaultValues[index].value
                        : currentNode.mappingTable?.[key]?.elements[index]?.value

                    return (
                        <Stack spacing={0.5}>
                            <Typography variant="caption" fontSize={14}>
                                {value}
                            </Typography>
                        </Stack>
                    )
                },
            })
        )

        return [keyColumn, ...valueColumns]
    }, [
        currentNode.defaultValues,
        currentNode.mappingTable,
        formatMessage,
        handleRemoveMappingKey,
        handleUpdateDefaultLabel,
        handleRemoveDefaultValue,
    ])

    // Prepare rows for the data grid
    const rows = useMemo<GridRow[]>(() => {
        // First row is the default values
        const defaultRow: GridRow = {
            id: "default",
            key: "Default Values",
            ...Object.fromEntries(currentNode.defaultValues.map((value, index) => [`value${index}`, value.value])),
        }

        // Rest of the rows are the mapping table entries
        const mappingRows: GridRow[] = Object.entries(currentNode.mappingTable).map(([key, entry]) => ({
            id: entry.id,
            key,
            ...Object.fromEntries(entry.elements.map((value, index) => [`value${index}`, value.value])),
        }))

        return [defaultRow, ...mappingRows]
    }, [currentNode.mappingTable, currentNode.defaultValues])

    return (
        <ConfigurationNode selectedNode={currentNode} validateNode={validateNode}>
            <Stack gap={2} className={configurationNodeItemClassName}>
                <MappingHeader
                    valueToMap={currentNode.valueToMap}
                    onValueToMapChange={handleValueToMapChange}
                    onAddColumn={handleAddMappingValue}
                    isEditing={isEditing}
                    hasKeyError={hasKeyError}
                />

                <MappingGrid
                    ref={gridRef}
                    stateKey={`${currentNode.slug}-mappingNode`}
                    rows={rows}
                    columns={columns}
                    cellModesModel={cellModesModel}
                    onCellModesModelChange={handleCellModesModelChange}
                    onCellEditStop={handleCellEditStop}
                    onCellClick={handleCustomCellClick}
                />

                <MappingFooter
                    newMappingKey={newMappingKey}
                    isEditing={isEditing}
                    hasKeyError={hasKeyError}
                    onNewMappingKeyChange={handleNewMappingKeyChange}
                    onKeyDown={handleKeyDown}
                    onAddMappingKey={handleAddMappingKey}
                    disabled={!newMappingKey.trim()}
                />
            </Stack>

            <AdvancedFields<MappingNode>
                fields={advancedFields}
                currentNode={currentNode}
                setCurrentNode={setCurrentNode}
            />
        </ConfigurationNode>
    )
}
