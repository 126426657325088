import { Link } from "@mui/material"
import React from "react"
import { Link as RouterLink, generatePath } from "react-router-dom"

import { INVOICE_EDIT_ROUTE } from "~/domains/transactions/invoices-v1/routes"
import { EDIT_INVOICE_SUPPLIER_ROUTE } from "~/domains/transactions/invoices/_views/supplier/routes"
import { useAppSelector } from "~/store/hooks"
import { useInvoiceById } from "~/store/invoice/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { InvoiceI, InvoiceId } from "~/types"

interface Props {
    invoiceId: InvoiceId
    fileName: string
}

const getInvoiceLinkName = (invoiceId: InvoiceId, invoice: InvoiceI | null, fileName?: string) => {
    if (invoice?.reference?.trim().length) return invoice.reference
    if (fileName?.length) return fileName
    return `#${invoiceId.split("-", 1)[0]}`
}

export const LinkToInvoice: React.FC<Props> = ({ invoiceId, fileName }) => {
    const { invoice } = useInvoiceById(invoiceId)
    const organization = useAppSelector(selectCurrentOrganization)

    const invoiceLinkName = getInvoiceLinkName(invoiceId, invoice, fileName)

    const shortenLinkName = invoiceLinkName.length > 18 ? invoiceLinkName.slice(0, 15) + "..." : invoiceLinkName

    const route =
        invoice?.supplier.organizationId === organization?.id
            ? generatePath(EDIT_INVOICE_SUPPLIER_ROUTE, { invoiceId })
            : generatePath(INVOICE_EDIT_ROUTE, { invoiceId })

    return (
        <Link component={RouterLink} to={route}>
            {shortenLinkName}
        </Link>
    )
}
