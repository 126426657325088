import { useMemo } from "react"

import { TagAndTagGroupNameI, TagGroupI, TagId } from "~/domains/analytics/tags/types"

const groupTagsFromTagGroup = (tagsGroupedById: Record<TagId, TagAndTagGroupNameI>, tagGroup: TagGroupI) => {
    return tagGroup.tags.reduce((acc, tag) => {
        acc[tag.tagId] = { ...tag, tagGroupName: tagGroup.name, tagGroupShortName: tagGroup.shortName }
        return acc
    }, tagsGroupedById)
}

export const useTagsGroupedByTagId = (tagGroups: TagGroupI[] | null) => {
    return useMemo<Record<TagId, TagAndTagGroupNameI>>(
        () => tagGroups?.reduce(groupTagsFromTagGroup, {}) ?? {},
        [tagGroups]
    )
}
