import { Stack, ToggleButton, ToggleButtonGroup } from "@mui/material"
import { ReactNode } from "react"
import { defineMessages } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import { ViewTypeI } from "~/types"

export const messages = defineMessages({
    labelSupplier: {
        id: "account.documents.menu.sent",
        defaultMessage: "Client invoices",
    },
    labelBuyer: {
        id: "account.documents.menu.received",
        defaultMessage: "Vendor invoices",
    },
})

interface ViewSwitcherTabProps {
    view: ViewTypeI | string
    labelSupplier?: ReactNode | string
    labelBuyer?: ReactNode | string
    actionSupplier: () => void
    actionBuyer: () => void
    size?: "small" | "medium"
    marginBottom?: number
}

export function ViewSwitcherTab({
    view,
    labelSupplier = <SafeFormattedMessage {...messages.labelSupplier} />,
    labelBuyer = <SafeFormattedMessage {...messages.labelBuyer} />,
    actionSupplier,
    actionBuyer,
    size = "medium",
    marginBottom = 2,
}: ViewSwitcherTabProps) {
    return (
        <Stack direction="row" justifyContent="center" spacing={1} width="100%" marginBottom={marginBottom}>
            <ToggleButtonGroup value={view} exclusive color="primary" size={size}>
                <ToggleButton value="buyer" onClick={actionBuyer}>
                    {labelBuyer}
                </ToggleButton>
                <ToggleButton value="supplier" onClick={actionSupplier}>
                    {labelSupplier}
                </ToggleButton>
            </ToggleButtonGroup>
        </Stack>
    )
}
