import { IconButton, Stack } from "@mui/material"
import { forwardRef } from "react"
import { ChevronDown, MoreVertical } from "react-feather"

import { Button, ButtonType } from "~/components"

interface ActionsMenuButtonProps {
    handleClick: (event: React.MouseEvent<HTMLButtonElement & MouseEvent>) => void
    disabled?: boolean
    labelButton?: string
    buttonType?: ButtonType
    icon?: React.ReactNode
    isActionIcon?: boolean
    size?: "small" | "medium"
    splitButton?: boolean
    splitButtonOnClick?: (event: React.MouseEvent<HTMLButtonElement & MouseEvent>) => void
}

export const ActionsMenuButton = forwardRef<HTMLButtonElement, ActionsMenuButtonProps>(
    (
        {
            handleClick,
            disabled,
            labelButton,
            buttonType,
            icon = <MoreVertical size={18} className="svg-grey" />,
            isActionIcon,
            size = "medium",
            splitButton = false,
            splitButtonOnClick,
        },
        ref
    ) => {
        if (splitButton) {
            return (
                <Stack direction="row">
                    <Button
                        ref={ref}
                        type={buttonType}
                        onClick={handleClick}
                        disabled={disabled}
                        size={size}
                        className="square-right"
                    >
                        {labelButton}
                    </Button>
                    <Button onClick={splitButtonOnClick} size={size} className="square-left">
                        <ChevronDown size={18} className="svg-white" />
                    </Button>
                </Stack>
            )
        }
        if (labelButton) {
            return (
                <Button ref={ref} type={buttonType} onClick={handleClick} disabled={disabled} size={size}>
                    {labelButton}
                    <ChevronDown size={18} className="svg-white" />
                </Button>
            )
        }

        return isActionIcon ? (
            <IconButton disabled={disabled} onClick={handleClick} size={size} ref={ref}>
                {icon}
            </IconButton>
        ) : (
            <Button ref={ref} type={buttonType} onClick={handleClick} disabled={disabled} size={size}>
                {icon}
            </Button>
        )
    }
)

ActionsMenuButton.displayName = "ActionsMenuButton"
