import { defineMessages, useIntl } from "react-intl"

import { Card } from "~/components"
import { OcrPayment } from "~/domains/payment"
import { DocumentData } from "~/domains/payment/types"
import { CurrencyCodes, ImportingInvoiceI, InvoiceI } from "~/types"

const messages = defineMessages({
    title: {
        id: "buyer.import.page.paymentDetails.title",
        defaultMessage: "Payment",
    },
})

interface DocumentPaymentProps {
    invoice: InvoiceI | ImportingInvoiceI
    dataLoaded: boolean
}

export function DocumentPayment({ dataLoaded, invoice }: DocumentPaymentProps) {
    const { formatMessage } = useIntl()
    const documentData: DocumentData = {
        number: invoice.reference ?? "",
        total: {
            amount: invoice.total || 0,
            currency: (invoice as InvoiceI)?.currency || CurrencyCodes.EUR,
        },
        dueDate: invoice.dueDate,
        issueDate: invoice.issueDate,
        iban: invoice.paymentDetails?.iban,
        accountNumber: invoice.paymentDetails?.accountNumber,
        paymentReferenceNumber: invoice.paymentReferenceNumber ?? null,
    }

    if (!invoice.id) {
        return null
    }

    return (
        <Card title={formatMessage(messages.title)} expandable>
            <OcrPayment
                loading={!dataLoaded}
                documentId={invoice.id}
                payeeId={invoice.supplier.organizationId}
                payerId={invoice.buyer.organizationId}
                documentData={documentData}
            />
        </Card>
    )
}
