export const PurchaseOrderStatusEnum = ["DRAFT", "SUBMITTED", "OPEN", "CLOSED"] as const
export type PurchaseOrderStatus = (typeof PurchaseOrderStatusEnum)[number]

export const PurchaseOrderStatusPresentationEnum = [
    "DRAFT",
    "SUBMITTED",
    "INTERNALLY_APPROVED",
    "OPEN",
    "CLOSED",
] as const
export type PurchaseOrderStatusPresentation = (typeof PurchaseOrderStatusPresentationEnum)[number]

export const PurchaseOrderFulfillmentPresentationEnum = [
    "PENDING",
    "IN_PREPARATION",
    "PARTIALLY_RECEIVED",
    "SHIPPED",
    "RECEIVED",
    "CANCELED",
] as const
export type PurchaseOrderFulfillmentPresentation = (typeof PurchaseOrderFulfillmentPresentationEnum)[number]

export type PurchaseOrderProgressStatus =
    | PurchaseOrderFulfillmentPresentation
    | PurchaseOrderStatusPresentation
    | "INVOICED"

export const ApprovalStatusTypeEnum = ["buyerApprovalStatus", "supplierApprovalStatus"] as const
export type ApprovalStatusType = (typeof ApprovalStatusTypeEnum)[number]

export const FulfillmentStatusTypeEnum = ["buyerFulfillment", "supplierFulfillment", "fulfillmentPresentation"] as const
export type FulfillmentStatusType = (typeof FulfillmentStatusTypeEnum)[number]

export const PurchaseOrderStatusCreateEnum = ["DRAFT", "SUBMITTED"] as const
export type PurchaseOrderStatusCreate = (typeof PurchaseOrderStatusCreateEnum)[number]
