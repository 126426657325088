const messages_ar = import.meta.glob("~/lang/ar/**/*.json", { eager: true }) as Record<string, unknown>
const messages_ch = import.meta.glob("~/lang/ch/**/*.json", { eager: true }) as Record<string, unknown>
const messages_de = import.meta.glob("~/lang/de/**/*.json", { eager: true }) as Record<string, unknown>
const messages_en = import.meta.glob("~/lang/en/**/*.json", { eager: true }) as Record<string, unknown>
const messages_fr = import.meta.glob("~/lang/fr/**/*.json", { eager: true }) as Record<string, unknown>
const messages_no = import.meta.glob("~/lang/no/**/*.json", { eager: true }) as Record<string, unknown>
const messages_pl = import.meta.glob("~/lang/pl/**/*.json", { eager: true }) as Record<string, unknown>
const messages_br = import.meta.glob("~/lang/br/**/*.json", { eager: true }) as Record<string, unknown>
const messages_ro = import.meta.glob("~/lang/ro/**/*.json", { eager: true }) as Record<string, unknown>

const reduceMessages = (acc: Record<string, string>, module: unknown) => ({
    ...acc,
    ...(module && typeof module === "object" && "default" in module && typeof module.default === "object"
        ? module.default
        : {}),
})

export const messages = {
    fr: Object.values(messages_fr).reduce(reduceMessages, {}),
    en: Object.values(messages_en).reduce(reduceMessages, {}),
    ar: Object.values(messages_ar).reduce(reduceMessages, {}),
    ch: Object.values(messages_ch).reduce(reduceMessages, {}),
    de: Object.values(messages_de).reduce(reduceMessages, {}),
    no: Object.values(messages_no).reduce(reduceMessages, {}),
    pl: Object.values(messages_pl).reduce(reduceMessages, {}),
    br: Object.values(messages_br).reduce(reduceMessages, {}),
    ro: Object.values(messages_ro).reduce(reduceMessages, {}),
}
