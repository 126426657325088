import { UserV2 } from "~/domains/identity/users-v2/types/UserV2Types"
import { UserI } from "~/types"

export const userV2FromApiAdapter = (userV2: UserV2): UserI => {
    return {
        id: userV2.userId,
        email: userV2.email ?? "",
        emailVerified: userV2.isActive,
        organizations: [],
        fullName: `${userV2.firstName} ${userV2.lastName}`.trim(),
        phoneNumber: userV2.phoneNumber,
        purchasePolicyAck: userV2.purchasePolicyAck,
        language: userV2.language,
    }
}
