import { useEffect } from "react"

import { useUploadInvoiceMutation } from "~/domains/_shared/ocr/api/ocrApi_RTK"
import { useAppDispatch } from "~/store/hooks"
import { ocrActions } from "~/store/ocr/ocrSlice"
import { InvoiceUserType, OrganizationId } from "~/types"

export const useUploadOCRDocumentV1 = (initiator: InvoiceUserType | null, organizationId?: OrganizationId) => {
    const [uploadInvoiceV2, { data, isLoading, reset }] = useUploadInvoiceMutation()
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (data?.jobId) {
            dispatch(ocrActions.newUploadSuccess(data.jobId))
        }
    }, [data, dispatch])

    useEffect(() => {
        return reset
    }, [reset])

    const uploadOCRDocument = async (file: File) => {
        try {
            dispatch(ocrActions.newUpload())
            const result = await uploadInvoiceV2({ file, initiator, organizationId })

            return result?.data
        } catch (error) {
            dispatch(ocrActions.newUploadFailed())
        }
    }

    return { uploadOCRDocument, data, loading: isLoading }
}
