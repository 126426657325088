import { Chip } from "@mui/material"
import { FC } from "react"

import { SafeFormattedMessage } from "~/components"
import { messages } from "~/domains/orchestration/flows/locale"

interface Props {
    canRestoreFlow: boolean
    onLoadLatest: () => void
}

export const HistoryActions: FC<Props> = ({ canRestoreFlow, onLoadLatest }) => {
    if (!canRestoreFlow) return null

    return (
        <Chip
            variant="outlined"
            color="primary"
            label={<SafeFormattedMessage {...messages.editor.restoreDraftChanges} />}
            onClick={onLoadLatest}
        />
    )
}
