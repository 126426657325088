import { Grid } from "@mui/material"
import { FC, useState } from "react"

import { ModalRequest } from "~/domains/transactions/invoices/_views/buyer/components"
import { ActionsHeader } from "~/domains/transactions/invoices/components/Actions/ActionsHeader"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { invoiceActions, selectInvoiceUserIds } from "~/store/invoice/invoiceSlice"
import { useGetAllUsersQuery } from "~/store/users/hooks"
import { InvoiceI } from "~/types"
import { InvoiceRequestI } from "~/types"

interface InvoiceActionsProps {
    invoice: InvoiceI
}

export const InvoiceActions: FC<InvoiceActionsProps> = ({ invoice }) => {

    const dispatch = useAppDispatch()
    const userIds = useAppSelector(selectInvoiceUserIds)

    const { users } = useGetAllUsersQuery(userIds)

    const [displayModal, setDisplayModal] = useState(false)
    const [, setModalConfirmUnmarkAsPaidVisible] = useState(false)

    const involvedUsers = users.filter((person) =>
        invoice?.involvedPeople.map(({ userId }) => userId).includes(person.id)
    )

    const handleDownloadPDF = () => {
        if (invoice?.fileLinks?.pdfLink) {
            window.location.href = invoice.fileLinks.pdfLink
        }
    }

    const handleUnmarkAsPaid = () => setModalConfirmUnmarkAsPaidVisible(true)

    const handleSendRequest = async (request: InvoiceRequestI) => {

        dispatch(
            invoiceActions.sendRequest({
                invoiceId: invoice.id,
                subject: request.subject,
                body: request.body,
                userId: request.userId,
            })
        )
    }

    return (
        <Grid item className="actions">
            <ActionsHeader
                organizationId={invoice.payerId}
                invoice={invoice}
                status={invoice.status}
                handleDownloadPDF={handleDownloadPDF}
                handleUnmarkAsPaid={handleUnmarkAsPaid}
                setDisplayModal={setDisplayModal}
                involvedUsers={involvedUsers}
            />
            <ModalRequest
                displayModal={displayModal}
                setDisplayModal={setDisplayModal}
                onConfirm={handleSendRequest}
                invoiceId={invoice.id}
                involvedPeople={involvedUsers}
            />
        </Grid>
    )
}
