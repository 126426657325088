import { useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"

import {
    AuthorizationFirstLetter,
    DomainName,
    DomainShorthand,
    ScopeName,
    ScopeShorthand,
} from "~/domains/identity/roles-permissions/types/RolesPermissions"
import {
    getPermissionIdString,
    permissionMessages,
    scopeMessages,
} from "~/domains/identity/roles-permissions/utils/permissions"
import { useAppSelector } from "~/store/hooks"
import { AuthorizationName, AuthorizationNames } from "~/types"
import { isDefined } from "~/utils/isDefined"

import { selectJwtPermissions } from "../rolesPermissionsSlice"

const findPermission = (permission: string, permissions: string[]): boolean => {
    return !!permissions.find((perm) => perm === permission)
}

const decodePermission = (shorthandAuthorization: string, domain: string, scope: string): string => {
    return `${AuthorizationFirstLetter[shorthandAuthorization]}:${
        domain.length <= 3 ? DomainShorthand[domain] : domain
    }:${scope.length <= 3 ? ScopeShorthand[scope] : scope}`
}

const decodePermissions = (encodedPermissions: string[]): string[] => {
    return encodedPermissions.flatMap((encodedPerm) => {
        const [shorthandAuthorization, domain, scope] = encodedPerm.split(":")
        return shorthandAuthorization.split("").map((authorization) => decodePermission(authorization, domain, scope))
    })
}

/**
 * @deprecated
 * Use useHasMultiplePermissions instead
 */
export const useHasPermissions = (
    organizationId: string,
    authorization: AuthorizationName,
    domain: DomainName,
    scope: ScopeName
) => {
    const { formatMessage } = useIntl()
    const [hasPermission, setHasPermission] = useState(false)
    const [permissionError, setPermissionError] = useState<string | null>(null)

    const userPermissions = useAppSelector(selectJwtPermissions)

    useEffect(() => {
        if (!isDefined(organizationId) || !Object.keys(userPermissions).length) {
            setHasPermission(false)
            setPermissionError("This user has no permission")
            return
        }

        if (!userPermissions[organizationId]) {
            setHasPermission(false)
            setPermissionError("This user has no permission for this organization")
            return
        }

        const organizationPermissions = decodePermissions(userPermissions[organizationId])

        const permission = `${authorization}:${domain}:${scope}`
        const userHasPermission = findPermission(permission, organizationPermissions)
        setHasPermission(userHasPermission)
        if (!userHasPermission) {
            setPermissionError(
                formatMessage(permissionMessages.errorPermission, {
                    permissionString: getPermissionIdString(permission, domain, scope, formatMessage),
                    scope: formatMessage(scopeMessages[scope]).toLowerCase(),
                })
            )
        }
    }, [authorization, domain, scope, userPermissions, organizationId])

    return { hasPermission, permissionError }
}

type PermissionsWithErrors = Record<AuthorizationName, { hasPermission: boolean; error: string | null }>

interface UseHasPermissionsProps {
    authorizations: AuthorizationNames[]
    organizationId?: string
    domain: DomainName
    scope: ScopeName
}

export const useHasMultiplePermissions = ({
    authorizations,
    organizationId,
    domain,
    scope,
}: UseHasPermissionsProps) => {
    const { formatMessage } = useIntl()

    const initialPermissions = authorizations.reduce((acc, authorization) => {
        acc[authorization] = { hasPermission: false, error: null }
        return acc
    }, {} as PermissionsWithErrors)

    const userPermissions = useAppSelector(selectJwtPermissions)

    const permissions = useMemo(() => {
        if (!isDefined(organizationId) || !Object.keys(userPermissions).length) {
            return authorizations.reduce((acc, authorization) => {
                acc[authorization] = { hasPermission: false, error: "This user has no permission" }
                return acc
            }, initialPermissions)
        }

        if (!userPermissions[organizationId]) {
            return authorizations.reduce((acc, authorization) => {
                acc[authorization] = {
                    hasPermission: false,
                    error: "This user has no permission for this organization",
                }
                return acc
            }, initialPermissions)
        }

        const organizationPermissions = decodePermissions(userPermissions[organizationId])

        return authorizations.reduce((acc, authorization) => {
            const permission = `${authorization}:${domain}:${scope}`
            const userHasPermission = findPermission(permission, organizationPermissions)
            const errorMessage = userHasPermission
                ? null
                : formatMessage(permissionMessages.errorPermission, {
                      permissionString: getPermissionIdString(permission, domain, scope, formatMessage),
                      scope: formatMessage(scopeMessages[scope]).toLowerCase(),
                  })

            acc[authorization] = { hasPermission: userHasPermission, error: errorMessage }
            return acc
        }, initialPermissions)
    }, [organizationId, userPermissions, authorizations, initialPermissions, domain, scope, formatMessage])

    return { permissions }
}
