import {
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableOwnProps,
    TableRow,
} from "@mui/material"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { ConfirmModal } from "~/components"
import { LightButton } from "~/components/LightButton"
import { getTemplateLineCustomFields } from "~/domains/transactions/invoices-v1/utils/getTemplateLineCustomFields"
import { selectDataGridCommonState } from "~/store/global/globalSlice"
import { useAppSelector } from "~/store/hooks"
import {
    CountryCode,
    DocumentWithLines,
    DocumentWithPrice,
    DocumentWithVersion,
    InvoiceI,
    InvoiceLineI,
    InvoiceWithId,
    OrganizationId,
    UserI,
} from "~/types"

import { InvoiceLineError } from "../../core"
import { InvoiceLine } from "../ModalInvoiceLines/InvoiceLine"

const messages = defineMessages({
    deleteSelected: {
        id: "invoice.invoiceLines.delete.button",
        defaultMessage: "Delete selected",
    },
    confirmBulkDeleteLinesTitle: {
        id: "invoice.invoiceLines.delete.bulk.title",
        defaultMessage: "Delete {number, plural, =0 {0 items} one {1 item} other {# items}}",
    },
    confirmBulkDeleteLinesPrompt: {
        id: "invoice.invoiceLines.delete.bulk.prompt",
        defaultMessage: "Are you sure you want to delete {number, plural, =0 {0 items} one {1 item} other {# items}}?",
    },
    reference: {
        id: "invoice.invoiceLines.reference",
        defaultMessage: "Reference",
    },
    quantity: {
        id: "invoice.invoiceLines.quantity",
        defaultMessage: "Quantity",
    },
    tax: {
        id: "invoice.invoiceLines.tax",
        defaultMessage: "Tax",
    },
    unitPrice: {
        id: "invoice.invoiceLines.unitPrice",
        defaultMessage: "Unit price",
    },
    description: {
        id: "invoice.invoiceLines.description",
        defaultMessage: "Description",
    },
    total: {
        id: "invoice.invoiceLines.total",
        defaultMessage: "Total",
    },
    discountTotal: {
        id: "invoice.invoiceLines.discountTotal",
        defaultMessage: "Discount",
    },
    totalExcludedTaxes: {
        id: "invoice.invoiceLines.totalExcludedTaxes",
        defaultMessage: "Total tax excl.",
    },
})

const TOLERANCE = 200

type Range = {
    from: number
    to: number
}

const isRectVisibleWithin = (rect: DOMRect, within: DOMRect) => {
    const { top: rectTop, bottom: rectBottom } = rect
    const { top: withinTop, bottom: withinBottom } = within
    if (rectTop < withinTop - TOLERANCE) return rectBottom > withinTop - TOLERANCE

    return rectTop < withinBottom + TOLERANCE
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const isWithinRange = (index: number, range: Range) => index >= range.from && index <= range.to

const getScrollParent = (node: HTMLElement | null) => {
    if (node === null) return null

    if (node.scrollHeight > node.clientHeight) return node

    return getScrollParent(node.parentElement)
}

interface VirtualLinesProps {
    countryCode: CountryCode
    invoice: DocumentWithLines & DocumentWithPrice & InvoiceWithId & DocumentWithVersion
    user: UserI
    organizationId?: OrganizationId
    errors?: InvoiceLineError[]
    onChange: (linePosition: number, updatePayload: Partial<InvoiceLineI>) => void
    onDelete: (linePosition: number) => Promise<boolean>
    onBulkDelete: (lines: number[]) => Promise<boolean>
    readonly?: boolean
    fieldClassName?: string
    highlightVATMissingLines?: boolean
}

export const VirtualLines = ({
    countryCode,
    user,
    invoice,
    highlightVATMissingLines,
    onBulkDelete,
    organizationId,
    onChange,
    onDelete,
}: VirtualLinesProps) => {
    const { formatMessage } = useIntl()
    const listContainerRef = useRef<HTMLTableElement | null>(null)
    const scrollParent = useRef<HTMLElement | null>(null)
    const currentRequest = useRef<number | null>(null)
    const [visibleLines, setVisibleLines] = useState<Range>({ from: 0, to: 4 })
    const [selected, setSelected] = useState<number[]>([])
    const [displayConfirmBulkDelete, setDisplayConfirmBulkDelete] = useState(false)

    const gridCommonState = useAppSelector(selectDataGridCommonState)

    const tableSize: TableOwnProps["size"] = gridCommonState?.density === "compact" ? "small" : "medium"

    const actualCheckVisibility = useCallback(() => {
        if (
            listContainerRef.current &&
            scrollParent.current &&
            typeof listContainerRef.current.checkVisibility === "function"
        ) {
            if (!(listContainerRef.current as HTMLElement).checkVisibility()) return

            const children = [...listContainerRef.current.children].filter((elem) =>
                elem.classList.contains("invoice-line")
            )
            const length = children.length
            const scrollRect = scrollParent.current.getBoundingClientRect()

            let minI = 0
            let maxI = length - 1
            let start: number | null = null
            let end: number | null = null

            while (minI <= maxI) {
                const i = Math.floor((minI + maxI) / 2)
                const element = children[i]

                if (!element) return

                const rect = element.getBoundingClientRect()

                if (isRectVisibleWithin(rect, scrollRect)) {
                    start = start === null ? i : Math.min(start, i)
                    end = end === null ? i : Math.max(end, i)

                    let j = i - 1
                    while (j >= 0 && isRectVisibleWithin(children[j].getBoundingClientRect(), scrollRect)) {
                        start = j
                        --j
                    }

                    let k = i + 1
                    while (k < length && isRectVisibleWithin(children[k].getBoundingClientRect(), scrollRect)) {
                        end = k
                        ++k
                    }

                    break
                } else if (rect.top < scrollRect.top) {
                    minI = i + 1
                } else {
                    maxI = i - 1
                }
            }

            if (start !== null && end !== null) {
                setVisibleLines((currentState: Range): Range => {
                    if (currentState.from !== start || currentState.to !== end) {
                        return { from: start, to: end }
                    }
                    return currentState
                })
            }
        }
    }, [])

    const checkVisibility = useCallback(() => {
        if (currentRequest.current) {
            window.clearTimeout(currentRequest.current)
        }
        currentRequest.current = window.setTimeout(actualCheckVisibility, 100)
    }, [actualCheckVisibility])

    useEffect(() => {
        scrollParent.current = getScrollParent(listContainerRef.current)
        window.addEventListener("resize", checkVisibility, { passive: true })
        window.addEventListener("scroll", checkVisibility, { passive: true })
        scrollParent.current && scrollParent.current.addEventListener("scroll", checkVisibility, { passive: true })

        return () => {
            window.removeEventListener("resize", checkVisibility)
            window.removeEventListener("scroll", checkVisibility)
            scrollParent.current && scrollParent.current.removeEventListener("scroll", checkVisibility)
        }
    }, [checkVisibility])

    const notVisibleLinesBeforeCount = visibleLines.from
    const notVisibleLinesAfterCount = Math.max(0, (invoice.lines?.length ?? 0) - visibleLines.to - 1)

    const onSelectAllClick = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
                invoice.lines && setSelected(invoice.lines.map((line) => line.linePosition))
            } else {
                setSelected([])
            }
        },
        [invoice.lines]
    )

    const onLineSelected = useCallback(
        (selectedLinePosition, isChecked) => {
            if (isChecked) {
                setSelected([...selected, selectedLinePosition])
            } else {
                setSelected(selected.filter((linePosition) => linePosition !== selectedLinePosition))
            }
        },
        [selected]
    )

    const onBulkDeleteClicked = useCallback((event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        event.stopPropagation()
        setDisplayConfirmBulkDelete(true)
    }, [])

    const confirmBulkDelete = useCallback(async () => {
        const isBulkDeleted = (await onBulkDelete?.(selected)) ?? false

        if (isBulkDeleted) {
            setSelected([])
            setDisplayConfirmBulkDelete(false)
            return true
        }
        return false
    }, [selected, onBulkDelete])

    const selectedLength = useMemo(() => selected.length, [selected])

    const templateLineCustomFields = getTemplateLineCustomFields(invoice as InvoiceI, organizationId || "")

    return (
        <>
            <TableContainer>
                <Table ref={listContainerRef} size={tableSize}>
                    <TableHead>
                        <TableRow>
                            <TableCell component="th">
                                {invoice.lines && (
                                    <div className="action-panel">
                                        <Checkbox
                                            indeterminate={selectedLength > 0 && selectedLength < invoice.lines.length}
                                            checked={selectedLength === invoice.lines.length}
                                            onChange={onSelectAllClick}
                                        />
                                        {selectedLength > 0 && (
                                            <LightButton onClick={onBulkDeleteClicked}>
                                                {formatMessage(messages.deleteSelected)}
                                            </LightButton>
                                        )}
                                    </div>
                                )}
                            </TableCell>
                            <TableCell component="th">{formatMessage(messages.description)}</TableCell>
                            {/*  <TableCell component="th">{formatMessage(messages.reference)}</TableCell> */}
                            {(templateLineCustomFields || []).map(({ id, name }) => (
                                <TableCell key={id} component="th">
                                    {name}
                                </TableCell>
                            ))}
                            <TableCell component="th">{formatMessage(messages.quantity)}</TableCell>
                            <TableCell component="th">{formatMessage(messages.unitPrice)}</TableCell>
                            <TableCell component="th">{formatMessage(messages.discountTotal)}</TableCell>
                            <TableCell component="th">{formatMessage(messages.tax)}</TableCell>
                            <TableCell component="th">{formatMessage(messages.totalExcludedTaxes)}</TableCell>
                            <TableCell component="th">Tags</TableCell>
                            <TableCell component="th"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.from(Array(notVisibleLinesBeforeCount)).map((_, index) => (
                            <TableRow className="invoice-line" key={index} />
                        ))}
                        {invoice.lines?.map((line, index) => (
                            <TableRow key={index + visibleLines.from}>
                                <InvoiceLine
                                    invoiceLine={line as InvoiceLineI}
                                    organizationId={organizationId}
                                    invoice={invoice}
                                    onChange={onChange}
                                    onDelete={onDelete}
                                    countryCode={countryCode}
                                    user={user}
                                    isSelected={selected.includes(line.linePosition)}
                                    onSelect={onLineSelected}
                                    highlight={highlightVATMissingLines && line.totalTax === 0 && !line.taxRateId}
                                />
                            </TableRow>
                        ))}
                        {Array.from(Array(notVisibleLinesAfterCount)).map((_, index) => (
                            <TableRow key={index + visibleLines.to} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <ConfirmModal
                title={formatMessage(messages.confirmBulkDeleteLinesTitle, { number: selected.length })}
                open={displayConfirmBulkDelete}
                close={() => setDisplayConfirmBulkDelete(false)}
                onConfirm={confirmBulkDelete}
            >
                {formatMessage(messages.confirmBulkDeleteLinesPrompt, { number: selected.length })}
            </ConfirmModal>
        </>
    )
}
