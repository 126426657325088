import {
    IOrganisationBusinessUnit,
    IOrganisationEstablishment,
    IOrganisationEstablishmentResponse,
} from "~/domains/identity/organization/types/OrganisationV2Types"

export const organisationEstablishmentFromApiAdapter = (
    organisationEstablishment: IOrganisationEstablishmentResponse
) => {
    return {
        siret: organisationEstablishment.siret,
        isHeadOffice: organisationEstablishment.isHeadOffice,
        mainEstablishment: organisationEstablishment.mainEstablishment
            ? mainEstablishmentFromApiAdapter(organisationEstablishment.mainEstablishment)
            : undefined,
        address: organisationEstablishment.address,
        activityStartDate: organisationEstablishment.activityStartDate,
        legalForm: organisationEstablishment.legalForm,
        officeId: organisationEstablishment.officeId,
        paymentMethod: organisationEstablishment.paymentMethod,
    }
}

export const mainEstablishmentFromApiAdapter = (mainEstablishment: IOrganisationEstablishment) => {
    return {
        siret: mainEstablishment.siret,
        isHead: mainEstablishment.isHead,
        addressLine1: mainEstablishment.addressLine1,
        city: mainEstablishment.city,
        country: mainEstablishment.country,
    }
}

export const businessUnitFromApiAdapter = (businessUnit: IOrganisationBusinessUnit) => {
    return {
        businessUnitId: businessUnit.businessUnitId,
        organizationId: businessUnit.organizationId,
        parentUnitId: businessUnit.parentUnitId,
        businessUnitType: businessUnit.businessUnitType,
        activityStartDate: businessUnit.activityStartDate,
        address: businessUnit.address,
        officeId: businessUnit.officeId,
        paymentMethodLinked: businessUnit.paymentMethodLinked,
        isHeadOffice: businessUnit.businessUnitType === "headOffice",
    }
}
