import { useCallback, useEffect } from "react"

import { organizationAddressToAddress } from "~/domains/transactions/purchase-orders/core/organizationAddressToAddress"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { CurrencyCodes, OrganizationI, OrganizationId } from "~/types"

import { PurchaseRequestDetails, PurchaseRequestStatus } from "../../types/PurchaseRequests"
import { purchaseRequestsActions, selectPurchaseRequest } from "../purchaseRequestsSlice"

const createEmptyPurchaseRequest = (organization: OrganizationI): PurchaseRequestDetails => ({
    id: "",
    organizationId: organization.id,
    supplierId: "",
    requesterUserId: "",
    totalAmount: 0,
    totalAmountExcludingTax: 0,
    taxRate: 0,
    status: PurchaseRequestStatus.DRAFT,
    description: "",
    currency: CurrencyCodes.EUR,
    recurrence: { type: "OneShot" },
    lines: [],
    documents: [],
    number: 0,
    purchaseOrder: null,
    expectedDeliveryDate: "",
    creationDate: "",
    submittedDate: null,
    shippingAddress: organization.address ? organizationAddressToAddress(organization.address) : null,
    permissions: {},
})

export const useCreateDraftPurchaseRequest = (organizationId: OrganizationId | undefined) => {
    const dispatch = useAppDispatch()
    const purchaseRequest = useAppSelector(selectPurchaseRequest)
    const currentOrganization = useAppSelector(selectCurrentOrganization)

    const createDraft = useCallback(() => {
        if (organizationId && currentOrganization && currentOrganization.id === organizationId) {
            dispatch(
                purchaseRequestsActions.createDraftPurchaseRequest(createEmptyPurchaseRequest(currentOrganization))
            )
        }
    }, [organizationId, currentOrganization, dispatch])

    useEffect(() => {
        if (organizationId && purchaseRequest.organizationId !== organizationId) {
            createDraft()
        }
    }, [createDraft, purchaseRequest.organizationId, organizationId])

    if (!organizationId || purchaseRequest.organizationId !== organizationId) return null

    return purchaseRequest
}
