import { Box, Stack, Typography, styled } from "@mui/material"
import { FC, useMemo } from "react"
import { Briefcase, Check, Clock, User, X } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { TooltipConditional } from "~/components/Tooltip/Tooltip"
import {
    ApprovalCheckI,
    ApprovalReviewerI,
    ApprovalReviewerType,
} from "~/domains/orchestration/flows-v0/types/Approval"
import { useGetAllUsersQuery } from "~/store/users/hooks"
import { OrganizationTeamI, UserI } from "~/types"

import { refusalMessages } from "./RefusalReason"

interface ReviewerRowProps {
    reviewer: ApprovalReviewerI
    check: ApprovalCheckI
    users: UserI[]
    teams: OrganizationTeamI[]
}

interface ReviewStatusProps {
    check: ApprovalCheckI
    id: string
    reviewerTeamUsersIds: string[]
}

const isUserType = (entity: UserI | OrganizationTeamI | undefined): entity is UserI => {
    return !!entity && "id" in entity
}

const isTeamType = (entity: UserI | OrganizationTeamI | undefined): entity is OrganizationTeamI => {
    return !!entity && "teamId" in entity
}

const messages = defineMessages({
    unknownTeam: {
        id: "common.unknownTeam",
        defaultMessage: "Unknown team",
    },
    unknownUser: {
        id: "common.unknownUser",
        defaultMessage: "Unknown user",
    },
})

const ReviewerName = styled(Typography)({
    cursor: "default",
    lineHeight: "var(--font-line-height-sm)",
})
// Status icon component
const ReviewStatus: FC<ReviewStatusProps> = ({ check, id, reviewerTeamUsersIds }) => {
    if (check.review.approvers.some((review) => review.userId === id || reviewerTeamUsersIds.includes(review.userId))) {
        return <Check data-testid="check-icon" color="var(--color-green)" size={16} />
    }
    if (check.review.refusers.some((review) => review.userId === id || reviewerTeamUsersIds.includes(review.userId))) {
        return <X data-testid="x-icon" color="var(--color-red)" size={16} />
    }
    return <Clock data-testid="clock-icon" color="var(--color-grey-light)" size={16} />
}

// Main component
export const ReviewerRow: FC<ReviewerRowProps> = ({ reviewer, check, users, teams }: ReviewerRowProps) => {
    const { formatMessage } = useIntl()
    const isUser = reviewer.type === ApprovalReviewerType.USER

    const entity = isUser
        ? users.find((user) => user.id === reviewer.userId)
        : teams.find((team) => team.teamId === reviewer.teamId)

    const reviewerTeamUsersIds = useMemo(() => {
        return isUser
            ? []
            : (teams.find((team) => team.teamId === reviewer.teamId)?.members.map((member) => member.userId) ?? [])
    }, [isUser, reviewer.teamId, teams])

    const { users: reviewerTeamUsers } = useGetAllUsersQuery(reviewerTeamUsersIds)

    const reviewerTeamUsersNames = reviewerTeamUsers.map((user) => user.fullName).join(", ")

    const IconComponent = isUserType(entity) ? User : Briefcase
    const userId = isUserType(entity) ? entity.id : ""
    const teamId = isTeamType(entity) ? entity.teamId : ""
    const userName = isUserType(entity) ? entity.fullName : formatMessage(messages.unknownUser)
    const teamName = isTeamType(entity) ? entity.name : formatMessage(messages.unknownTeam)

    const id = userId || teamId
    const name = isUserType(entity) ? userName : teamName

    const refuser = check.review.refusers.find((r) => r.userId === id || reviewerTeamUsersIds.includes(r.userId))
    const userRefusalReason = refuser?.refusalReason?.reason ?? ""
    const userRefusalMessage = refuser?.refusalReason?.message ?? ""

    const displayTeamTooltip = reviewerTeamUsersNames.length > 0 && !isUser

    return (
        <Stack direction="row" gap={1} key={id}>
            <Stack direction="row" gap={1} alignItems="top">
                <IconComponent size={16} color="var(--color-grey-light)" />

                <TooltipConditional title={reviewerTeamUsersNames} condition={displayTeamTooltip}>
                    <ReviewerName variant="caption">{name}</ReviewerName>
                </TooltipConditional>

                <Box>
                    <ReviewStatus check={check} id={id} reviewerTeamUsersIds={reviewerTeamUsersIds} />
                </Box>
                {userRefusalReason && (
                    <Typography
                        variant="caption"
                        color="var(--color-red)"
                        fontWeight="bold"
                        lineHeight="var(--font-line-height-sm)"
                    >
                        {refusalMessages[userRefusalReason]
                            ? formatMessage(refusalMessages[userRefusalReason])
                            : userRefusalReason}
                    </Typography>
                )}
                {userRefusalMessage && (
                    <Typography variant="caption" lineHeight="var(--font-line-height-sm)">
                        {userRefusalMessage}
                    </Typography>
                )}
            </Stack>
        </Stack>
    )
}
