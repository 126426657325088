import { Stack, Typography } from "@mui/material"
import Linkify from "linkify-react"
import { defineMessages } from "react-intl"

import { Card, Loader, SafeFormattedMessage } from "~/components"

import { FormItem } from "./FormItem"
import { FormViewerFooter } from "./FormViewerFooter"
import { FormViewerProvider, useFormViewerContext } from "./FormViewerProvider"

interface FormViewerProps {
    formToken: string
    viewMode?: string
}

const FORM_WIDTH = 800

const messages = defineMessages({
    formSent: {
        id: "smartForms.formSent",
        defaultMessage: "Form sent",
    },
    noQuestionsFound: {
        id: "smartForms.noQuestionsFound",
        defaultMessage: "No questions to answer",
    },
})

const IntroductoryText = ({ introductoryText }: { introductoryText: string }) => {
    return (
        <Typography variant="body1" className="mb-12 pre-line" color="var(--color-grey)">
            <Linkify options={{ target: "_blank" }}>{introductoryText}</Linkify>
        </Typography>
    )
}

export const FormViewer = ({ formToken }: FormViewerProps) => {
    const FormSection = () => {
        const { formData, currentSection, formLoading, visibleSections, readOnly } = useFormViewerContext()
        const section = formData?.sections[currentSection ?? 0]

        if (formLoading) {
            return (
                <Card>
                    <Stack direction="row" justifyContent="center" alignItems="center">
                        <Loader />
                    </Stack>
                </Card>
            )
        }

        if (!section || !visibleSections.length) {
            return (
                <Card>
                    <SafeFormattedMessage {...messages.noQuestionsFound} />
                </Card>
            )
        }

        return (
            <Card sx={{ padding: "var(--spacing-lg)" }} title={section.title}>
                {section.introductoryText && <IntroductoryText introductoryText={section.introductoryText} />}
                {section.questions.map((q) => (
                    <FormItem disabled={readOnly} key={q.id} question={q} />
                ))}
            </Card>
        )
    }

    const FormWrapper = () => {
        const { formData, formSent, visibleSections } = useFormViewerContext()
        return (
            <Stack className="main-box" maxWidth={FORM_WIDTH}>
                <h2 className="mb-12">{formData?.name}</h2>
                {formSent ? (
                    <Card>
                        <SafeFormattedMessage {...messages.formSent} />
                    </Card>
                ) : (
                    <>
                        {formData?.introductoryText && (
                            <IntroductoryText introductoryText={formData.introductoryText} />
                        )}
                        <FormSection />
                        {visibleSections.length > 0 && <FormViewerFooter />}
                    </>
                )}
            </Stack>
        )
    }

    return (
        <FormViewerProvider formToken={formToken}>
            <FormWrapper />
        </FormViewerProvider>
    )
}
