import { Autocomplete, Checkbox, Divider, FormControlLabel, Stack, TextField, Typography } from "@mui/material"
import cls from "classnames"
import React, { FC, useEffect, useState } from "react"
import { useIntl } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import {
    useGetOrganisationCustomFormsQuery,
    useLazyGetSurveyRequirementsQuery,
} from "~/domains/identity/custom-forms/api/customFormsApi"
import { CustomForm } from "~/domains/identity/custom-forms/types/CustomForms"
import { AdvancedFields } from "~/domains/orchestration/flows/components/configuration/AdvancedFields"
import { adaptExpressionToText, adaptTextToExpression } from "~/domains/orchestration/flows/core"
import { useOrganizationId } from "~/domains/orchestration/flows/hooks/useOrganizationId"
import { messages } from "~/domains/orchestration/flows/locale"
import {
    ConfigurationProps,
    CreateSurveyNode,
    EmailRespondent,
    RespondentType,
    UserRespondent,
} from "~/domains/orchestration/flows/types"
import { useAppSelector } from "~/store/hooks"
import { useFetchOrganizationMembers } from "~/store/organization/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { OrganizationMemberUserI } from "~/types"

import { ConfigurationNode } from "./ConfigurationNode"
import { SharedEmailConfiguration } from "./SharedEmailConfiguration"
import { SharedUserSelect } from "./SharedUserSelect"

const isOptionEqualToValue = (option: CustomForm, value: CustomForm) => option.id === value.id

const getOptionLabel = (option: CustomForm) => option.name

export const CreateSurveyConfiguration: FC<ConfigurationProps<CreateSurveyNode>> = ({
    selectedNode,
    advancedFields,
    validateNode,
}) => {
    const { formatMessage } = useIntl()
    const organization = useAppSelector(selectCurrentOrganization)
    const [members] = useFetchOrganizationMembers(organization!)

    const [currentNode, setCurrentNode] = useState(selectedNode)

    const organisationId = useOrganizationId()
    const { data: customForms = [], isLoading } = useGetOrganisationCustomFormsQuery({ organisationId })

    // Get survey requirements when form is selected
    const [getSurveyRequirements, { data: surveyRequirements }] = useLazyGetSurveyRequirementsQuery()

    const handleChangeForm = async (_, value: CustomForm | null) => {
        if (!value) return

        const surveyReq = await getSurveyRequirements({
            organizationId: organisationId,
            formId: value.id,
        }).unwrap()

        const context =
            surveyReq?.contextParameters.map((param) => ({
                key: param.key,
                value: "",
            })) || []

        setCurrentNode((prev) => ({
            ...prev,
            formId: value.id,
            context,
            metadata: {
                ...currentNode.metadata,
                additionalInformation: value.name,
            },
        }))
    }

    const handleNotificationsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentNode((prev) => ({
            ...prev,
            sendNotifications: event.target.checked,
        }))
    }

    const handleUserSelect = (_, values: OrganizationMemberUserI[]) => {
        const userRespondents: UserRespondent[] = values.map((member) => ({
            type: RespondentType.USER,
            userId: adaptTextToExpression(member.userId),
        }))

        const emailRespondents = currentNode.respondents.filter(
            (r): r is EmailRespondent => r.type === RespondentType.EMAIL
        )

        setCurrentNode((prev) => ({
            ...prev,
            respondents: [...emailRespondents, ...userRespondents],
        }))
    }

    const handleChangeEmails = (emails: string[]) => {
        setCurrentNode((prev) => ({
            ...prev,
            respondents: emails.map((email) => ({
                type: RespondentType.EMAIL,
                emailAddress: email,
            })),
        }))
    }

    const handleContextValueChange = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        setCurrentNode((prev) => ({
            ...prev,
            context: prev.context.map((entry) => (entry.key === key ? { ...entry, value } : entry)),
        }))
    }

    // Update context entries when survey requirements change
    useEffect(() => {
        const updateContext = async () => {
            const surveyReq = await getSurveyRequirements({
                organizationId: organisationId,
                formId: currentNode.formId,
            }).unwrap()

            if (surveyReq && surveyReq.contextParameters.length === 0) return

            // Create context entries from requirements if they don't exist
            const existingContextMap = new Map(currentNode.context.map((entry) => [entry.key, entry.value]))

            const newContext = surveyReq.contextParameters.map((param) => ({
                key: param.key,
                value: existingContextMap.get(param.key) || "",
            }))

            setCurrentNode((prev) => ({
                ...prev,
                context: newContext,
            }))
        }

        updateContext()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const form = customForms.find((f) => f.id === currentNode.formId) || null
    const selectedUsers = members.filter((member) =>
        currentNode.respondents.some(
            (r) => r.type === "UserRespondent" && adaptExpressionToText(r.userId) === member.userId
        )
    )

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    const emails = currentNode.respondents
        .filter((r): r is EmailRespondent => r.type === RespondentType.EMAIL)
        .map((r) => r.emailAddress)

    return (
        <ConfigurationNode selectedNode={currentNode} validateNode={validateNode}>
            <Stack gap={2} className={configurationNodeItemClassName}>
                <Autocomplete
                    options={customForms}
                    value={form}
                    onChange={handleChangeForm}
                    getOptionLabel={getOptionLabel}
                    isOptionEqualToValue={isOptionEqualToValue}
                    renderInput={(params) => (
                        <TextField {...params} label={formatMessage(messages.advancedFields.formId)} />
                    )}
                    loading={isLoading}
                />

                <FormControlLabel
                    control={<Checkbox checked={currentNode.sendNotifications} onChange={handleNotificationsChange} />}
                    label={
                        <SafeFormattedMessage
                            tagName="span"
                            {...messages.createSurveyConfiguration.sendNotifications}
                        />
                    }
                />
                <Divider />

                {Boolean(surveyRequirements?.contextParameters.length) && (
                    <>
                        <Stack gap={1}>
                            <SafeFormattedMessage tagName="h5" {...messages.createSurveyConfiguration.contextTitle} />
                            <Stack spacing={2}>
                                {(currentNode.context || []).map((entry) => (
                                    <Stack key={entry.key} spacing={1}>
                                        <Typography variant="subtitle2" fontWeight="medium">
                                            {entry.key}
                                        </Typography>
                                        <TextField
                                            value={entry.value}
                                            onChange={handleContextValueChange(entry.key)}
                                            fullWidth
                                            size="small"
                                        />
                                    </Stack>
                                ))}
                            </Stack>
                        </Stack>
                        <Divider />
                    </>
                )}

                <Stack gap={1}>
                    <SafeFormattedMessage tagName="h5" {...messages.createSurveyConfiguration.emailRespondents} />
                    <SharedEmailConfiguration
                        onChangeEmails={handleChangeEmails}
                        emails={emails}
                        label={formatMessage(messages.createSurveyConfiguration.emailLabel)}
                    />
                </Stack>

                <Divider />

                <Stack gap={1}>
                    <SafeFormattedMessage tagName="h5" {...messages.createSurveyConfiguration.userRespondents} />
                    <SharedUserSelect
                        members={members}
                        selectedUsers={selectedUsers}
                        onSelectUsers={handleUserSelect}
                        label={formatMessage(messages.createSurveyConfiguration.userLabel)}
                    />
                </Stack>
            </Stack>

            <AdvancedFields<CreateSurveyNode>
                fields={advancedFields}
                currentNode={currentNode}
                setCurrentNode={setCurrentNode}
            />
        </ConfigurationNode>
    )
}
