import { Configuration, CreateNodeProps, EditorNode, NodeType } from "~/domains/orchestration/flows/types/"

import {
    addToBudgetConfig,
    approveErpInvoiceConfig,
    approvePurchaseOrderConfig,
    approvePurchaseOrderLineConfig,
    approvePurchaseRequestConfig,
    approvePurchaseRequestLineConfig,
    assignDocumentToObjectConfig,
    assignTagGroupConfig,
    branchConfig,
    checkConfig,
    convertPrToPoConfig,
    copyLinkedDocumentsConfig,
    copyTagsConfig,
    createCustomFieldConfig,
    createPurchaseOrderConfig,
    createSurveyConfig,
    createTaskConfig,
    entityTriggerConfig,
    eventTriggerConfig,
    fetchCustomFieldsConfig,
    fetchPartnershipConfig,
    fitsToBudgetConfig,
    getTagByGroupConfig,
    ifConfig,
    invoiceToPurchaseOrderMatchConfig,
    mappingConfig,
    refusePurchaseOrderConfig,
    refusePurchaseRequestConfig,
    retractReviewsConfig,
    sendEmailConfig,
    setInvoiceLifecycleStatusConfig,
    setPartnershipFieldConfig,
    setPaymentMethodDetailsFieldConfig,
    setPoStatusConfig,
    setPurchaseOrderFulfillmentStatusConfig,
    suspendUntilSurveyCompletedConfig,
    updateCustomFieldConfig,
    updateTripletexLedgerConfig,
} from "./nodeConfiguration"

const nodeConfig = {
    [NodeType.EVENT_TRIGGER_NODE]: eventTriggerConfig,
    [NodeType.ENTITY_TRIGGER_NODE]: entityTriggerConfig,
    [NodeType.SET_PARTNERSHIP_FIELD_NODE]: setPartnershipFieldConfig,
    [NodeType.FETCH_PARTNERSHIP_NODE]: fetchPartnershipConfig,
    [NodeType.SET_PAYMENT_METHOD_DETAILS_FIELD_NODE]: setPaymentMethodDetailsFieldConfig,
    [NodeType.IF_NODE]: ifConfig,
    [NodeType.BRANCH_NODE]: branchConfig,
    [NodeType.CHECK_NODE]: checkConfig,
    [NodeType.SEND_EMAIL_NODE]: sendEmailConfig,
    [NodeType.ASSIGN_TAG_GROUP_NODE]: assignTagGroupConfig,
    [NodeType.GET_TAG_BY_GROUP_NODE]: getTagByGroupConfig,
    [NodeType.ADD_TO_BUDGET_NODE]: addToBudgetConfig,
    [NodeType.FITS_TO_BUDGET_NODE]: fitsToBudgetConfig,
    [NodeType.ASSIGN_DOCUMENT_TO_OBJECT_NODE]: assignDocumentToObjectConfig,
    [NodeType.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE]: invoiceToPurchaseOrderMatchConfig,
    [NodeType.SET_INVOICE_LIFECYCLE_STATUS_NODE]: setInvoiceLifecycleStatusConfig,
    [NodeType.SET_PO_FULFILLMENT_STATUS_NODE]: setPurchaseOrderFulfillmentStatusConfig,
    [NodeType.SET_PO_STATUS_NODE]: setPoStatusConfig,
    [NodeType.APPROVE_PURCHASE_REQUEST_NODE]: approvePurchaseRequestConfig,
    [NodeType.APPROVE_PURCHASE_REQUEST_LINE_NODE]: approvePurchaseRequestLineConfig,
    [NodeType.REFUSE_PURCHASE_REQUEST_NODE]: refusePurchaseRequestConfig,
    [NodeType.CREATE_PURCHASE_ORDER_NODE]: createPurchaseOrderConfig,
    [NodeType.APPROVE_PURCHASE_ORDER_NODE]: approvePurchaseOrderConfig,
    [NodeType.APPROVE_PURCHASE_ORDER_LINE_NODE]: approvePurchaseOrderLineConfig,
    [NodeType.REFUSE_PURCHASE_ORDER_NODE]: refusePurchaseOrderConfig,
    [NodeType.CONVERT_PR_TO_PO_NODE]: convertPrToPoConfig,
    [NodeType.FETCH_CUSTOM_FIELDS_NODE]: fetchCustomFieldsConfig,
    [NodeType.CREATE_CUSTOM_FIELD_NODE]: createCustomFieldConfig,
    [NodeType.UPDATE_CUSTOM_FIELD_NODE]: updateCustomFieldConfig,
    [NodeType.CREATE_SURVEY_NODE]: createSurveyConfig,
    [NodeType.SUSPEND_UNTIL_SURVEY_COMPLETED_NODE]: suspendUntilSurveyCompletedConfig,
    [NodeType.RETRACT_REVIEWS_NODE]: retractReviewsConfig,
    [NodeType.CREATE_TASK_NODE]: createTaskConfig,
    [NodeType.MAPPING_NODE]: mappingConfig,
    [NodeType.COPY_LINKED_DOCUMENTS_NODE]: copyLinkedDocumentsConfig,
    [NodeType.COPY_TAGS_NODE]: copyTagsConfig,
    [NodeType.UPDATE_TRIPLETEX_LEDGER_NODE]: updateTripletexLedgerConfig,
    [NodeType.APPROVE_ERP_INVOICE_NODE]: approveErpInvoiceConfig,
}

const createNode = (props: CreateNodeProps): EditorNode | null => {
    const { type, ...factoryProps } = props
    const configuration = nodeConfig[type] as Configuration<NodeType>

    if (!configuration) {
        return null
    }

    return configuration.factory(factoryProps)
}

const FLOW_NODE_TYPES = Object.keys(nodeConfig).reduce((acc, key) => {
    const configuration = nodeConfig[key] as Configuration<NodeType>
    acc[key] = configuration.Node
    return acc
}, {})

const FLOW_NODE_LIST = Object.values(nodeConfig)

export { nodeConfig, FLOW_NODE_TYPES, FLOW_NODE_LIST, createNode }
