import { Typography } from "@mui/material"
import React, { useState } from "react"

import { Button, Modal, SafeFormattedMessage } from "~/components"
import { organizationApi } from "~/domains/identity/organization/api/organisationApi"
import { OrganizationSelectorWithPartners } from "~/domains/identity/organization/components/OrganizationSelector"
import { CompanyResult } from "~/domains/identity/organization/types/CompanyAutocomplete"
import { organizationSelectorModalMessages } from "~/domains/orchestration/flows/locale"
import { CountryCode, OrganizationI, OrganizationId } from "~/types"

import "./ModalSelectOrganization.scss"

interface ModalSelectOrganizationProps {
    open: boolean
    close: () => void
    organizationId: OrganizationId | null
    organizationName?: string
    onSelect?: (organization: OrganizationI) => void
}

export const ModalSelectOrganization: React.FC<ModalSelectOrganizationProps> = ({
    open,
    close,
    organizationId,
    organizationName,
    onSelect,
}) => {
    const [companyResult, setCompanyResult] = useState<CompanyResult | undefined>()
    const [countryCode, setCountryCode] = useState<CountryCode>(CountryCode.UNKNOWN)

    const [organization, setOrganization] = useState<{
        countryCode: CountryCode
        name: string
        organizationId: string | null
    }>({
        countryCode: countryCode,
        name: organizationName ?? "",
        organizationId: organizationId,
    })

    const handleClose = () => {
        setCompanyResult(undefined)
        close()
    }

    const selectOrganization = async () => {
        const { organizationId: orgId } = organization
        if (!orgId) return

        const org = await organizationApi.getOrganizationById(orgId)
        if (!org) return

        onSelect?.(org)
        close()
    }

    const updateData = (data: Partial<typeof organization>) => {
        setOrganization((prev) => ({ ...prev, ...data }))
    }

    const onSave = () => true

    return (
        <Modal className="organization-selector-modal" open={open} onClose={handleClose}>
            <Modal.Header>
                <Typography variant="h4">
                    <SafeFormattedMessage {...organizationSelectorModalMessages.title} />
                </Typography>
            </Modal.Header>

            <Modal.Content>
                <OrganizationSelectorWithPartners
                    label=""
                    company={companyResult}
                    countryCode={countryCode}
                    onCountryCodeChange={setCountryCode}
                    updateData={updateData}
                    onSave={onSave}
                    dataLoaded={true}
                    suggestedCompanies={undefined}
                />
            </Modal.Content>
            <Modal.Footer>
                <Button type="neutral" onClick={handleClose}>
                    <SafeFormattedMessage {...organizationSelectorModalMessages.cancel} />
                </Button>
                <Button type="primary" onClick={selectOrganization}>
                    <SafeFormattedMessage {...organizationSelectorModalMessages.save} />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
