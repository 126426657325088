import { FormControl } from "@mui/material"
import React from "react"

import { CreateOrganizationContainer as ManualImportContainer } from "~/domains/identity/organization/components/CreateOrganizationContainer/CreateOrganizationContainer"
import { CompanyResult } from "~/domains/identity/organization/types/CompanyAutocomplete"
import { ImportInvoiceCompanyInfoI, OrganizationI } from "~/types"

interface PartnerOrganizationManualImportProps {
    updateData: (data: Partial<ImportInvoiceCompanyInfoI>) => void
    cancelManualImport: () => void
    companyResult: CompanyResult | undefined
    setCompanyResult: (companyResult: CompanyResult | undefined) => void
}

export const PartnerOrganizationManualImport: React.FC<PartnerOrganizationManualImportProps> = ({
    updateData,
    cancelManualImport,
    companyResult,
    setCompanyResult,
}) => {
    const handleManualOrganizationCreated = (newOrganization: OrganizationI) => {
        const newCompanyResult: CompanyResult = {
            type: "org",
            value: newOrganization,
        }
        setCompanyResult(newCompanyResult)
        const newPartner = {
            organizationId: newOrganization.id,
            countryCode: newOrganization.registration?.countryCode,
            name: newOrganization.name,
            registrationNumber: newOrganization.registration?.preferredRegistrationNumber?.registrationNumber,
        }
        updateData(newPartner)
        cancelManualImport()
    }

    return (
        <FormControl>
            <ManualImportContainer
                cancelManualImport={cancelManualImport}
                companyResult={companyResult}
                onOrganizationCreated={handleManualOrganizationCreated}
            />
        </FormControl>
    )
}
