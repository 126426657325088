import { Alert, Skeleton, Stack, Typography } from "@mui/material"
import { QueryStatus } from "@reduxjs/toolkit/query"
import { FC, ReactNode } from "react"
import { Link, generatePath } from "react-router-dom"

import { Chip, SafeFormattedMessage } from "~/components"
import { useFetchTasksQuery } from "~/domains/communication/tasks/api/tasksApi"
import { TaskProgressIcon } from "~/domains/communication/tasks/components/TaskProgressIcon"
import { taskPriorityLabel } from "~/domains/communication/tasks/core"
import { taskCommonMessages } from "~/domains/communication/tasks/core/taskCommonMessages"
import { EDIT_TASK_ROUTE } from "~/domains/communication/tasks/routes"
import { TaskI, TaskPriority, TaskStatus } from "~/domains/communication/tasks/types"
import { TaskQueryParams } from "~/domains/communication/tasks/types/TaskQueryParams"
import { OrganizationId, UserI } from "~/types"
import { Variant } from "~/utils/colors"

interface Props {
    organizationId: OrganizationId
    user: UserI
    emptyState: ReactNode
}

const colorPerPriority: Record<TaskPriority, Variant> = {
    [TaskPriority.LOW]: "neutral",
    [TaskPriority.MEDIUM]: "info",
    [TaskPriority.HIGH]: "warning",
    [TaskPriority.URGENT]: "error",
}

const isTaskNotRejected = (task: TaskI) => task.status !== TaskStatus.REJECTED

export const UserTasksList: FC<Props> = ({ organizationId, user, emptyState }) => {
    const queryParams: TaskQueryParams & { organizationId: OrganizationId } = {
        organizationId,
        assignee: user.id,
        page: 1,
        page_size: 12,
    }
    const { status, data, originalArgs } = useFetchTasksQuery(queryParams, { pollingInterval: 30000 })

    if (status === QueryStatus.rejected) {
        return (
            <Alert severity="error">
                <SafeFormattedMessage {...taskCommonMessages.errorFetchingTasks} />
            </Alert>
        )
    }
    if (!data || !user.id || status === QueryStatus.pending || originalArgs?.assignee !== user.id) {
        return (
            <Stack gap={2}>
                <Skeleton height={24} variant="rectangular" />
                <Skeleton height={24} variant="rectangular" />
                <Skeleton height={24} variant="rectangular" />
                <Skeleton height={24} variant="rectangular" />
                <Skeleton height={24} variant="rectangular" />
                <Skeleton height={24} variant="rectangular" />
            </Stack>
        )
    }

    if (data.items.length === 0) {
        return emptyState
    }

    return (
        <Stack gap={2}>
            {data.items.filter(isTaskNotRejected).map((task) => (
                <Link key={task.id} to={generatePath(EDIT_TASK_ROUTE, { taskId: task.id })}>
                    <Stack direction="row" gap={1}>
                        <TaskProgressIcon status={task.status} />
                        <Typography textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" flexGrow={1}>
                            {task.title}
                        </Typography>
                        {task.priority && (
                            <Chip variant={colorPerPriority[task.priority]} size="small">
                                <SafeFormattedMessage {...taskPriorityLabel[task.priority]} />
                            </Chip>
                        )}
                    </Stack>
                </Link>
            ))}
        </Stack>
    )
}
