import { SelectProps } from "@mui/material"

import { TagId } from "~/domains/analytics/tags/types"
import { BudgetDataWithMetricsI } from "~/domains/transactions/budget/types"
import { LineStatus } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import {
    CurrencyCodes,
    InvoiceBackendVersion,
    InvoiceFileLinksI,
    InvoiceLineI,
    InvoiceStatus,
    MonetaryInfo,
} from "~/types"
import { Variant } from "~/utils/colors"

export interface InvoiceAddress {
    city?: string
    country?: string
    street?: string
    street2?: string | null
    zipCode?: string
}

export enum InvoiceFieldLevel {
    Line = "Line",
    Document = "Document",
}

export type InvoiceFieldType = "Address" | "Date" | "DecimalNumber" | "IntegerNumber" | "MonetaryAmount" | "String"

export const lifecycleGroup = ["DRAFT", "IN_PROGRESS", "COMPLETED_OK", "COMPLETED_ERROR"] as const
export type LifecycleGroup = (typeof lifecycleGroup)[number]

export type LifecycleGroupWithVariant = {
    group: LifecycleGroup
    index: number
    variant: Variant
}

export type LifecycleGroupFormatted = [string, LifecycleGroupWithVariant][]

export const LifecycleGroupVariant: Record<LifecycleGroup, SelectProps["color"] | "grey"> = {
    COMPLETED_ERROR: "error",
    COMPLETED_OK: "success",
    DRAFT: "grey",
    IN_PROGRESS: "primary",
}

export interface InvoiceTemplateCustomField {
    id: string
    type: InvoiceFieldType
    name: string
    description: string
    level: InvoiceFieldLevel
}

export type InvoiceCustomFieldValue = string | MonetaryInfo | InvoiceAddress
export type InvoiceCustomFields = Record<string, InvoiceCustomFieldValue>

export interface InvoiceLifeCycleItem {
    status: string
    description: string
    adjacency: string[]
    group: LifecycleGroup
}

export interface InvoiceLifeCycle {
    id: string
    organizationId: string
    default: InvoiceLifeCycleItem
    other: InvoiceLifeCycleItem[]
}

export interface InvoiceTemplate {
    id: string
    name: string
    description: string
    lifecycle: InvoiceLifeCycle
    customFields: InvoiceTemplateCustomField[]
}

export interface InvoiceParty {
    id?: string
    initiator?: boolean
    involvement?: string
    customFields?: InvoiceCustomFields
    referenceNumber?: string
    contacts?: string[]
    billingAddress?: InvoiceAddress
    currentLifecycle?: string
    template?: InvoiceTemplate
}

export interface InvoiceLineItemParty {
    id: string
    customFields?: InvoiceCustomFields | null
}

export interface InvoiceLineItemCustomField {
    label: string | undefined
    description: string | undefined
    value: string
}

export type InvoiceLineItemAPI = {
    id: string
    description: string
    productReference?: string
    currency: CurrencyCodes
    quantity: number
    unitPrice: number
    unitPriceWithoutTax: number
    priceWithoutTax: string
    taxId: string
    tax: string
    shippingAddress?: InvoiceAddress | null
    parties?: Record<string, InvoiceLineItemParty>
    discountRate?: string

    // Temporary field to be removed
    approvalStatus?: LineStatus
    total?: number

    // Build in InvoiceItems component
    customFields?: Record<string, InvoiceLineItemCustomField>
}

export type InvoiceAPI = {
    version?: InvoiceBackendVersion
    status?: InvoiceStatus
    lifecycleStatus?: string
    number: string
    id: string
    invoiceId: string
    issuedAt: string
    updatedAt: string
    dueDate?: string
    paidAt?: string
    payerId: string
    sellerId: string
    parties: Record<string, InvoiceParty | null>
    lineItems: InvoiceLineI[]
    totalPriceWithoutTax: Record<CurrencyCodes, string>
    tax: Record<CurrencyCodes, { id: string; value: string }>
    totalAmountDue: MonetaryInfo[] & Record<CurrencyCodes, string>
    flatDiscount?: Record<CurrencyCodes, string>
    discountRate?: string
    paymentTerms?: string | null
    paymentInstructions?: string | null
    notes?: string | null
    fileLinks: InvoiceFileLinksI | null
    budgets: BudgetDataWithMetricsI[]
    nextCursor: string
    tagIds: TagId[]
    purchaseOrderId?: string
    hasDuplicates?: boolean
    purchaseOrderReference?: string
    paymentReferenceNumber?: string
}

export interface ManageInvoiceLineItem {
    id?: string
    description?: string
    currency: CurrencyCodes
    quantity: number
    unitPrice: string
    priceWithoutTax: string
    taxId: string
    tax: string
    shippingAddress?: InvoiceAddress | null
    parties: Record<string, InvoiceLineItemParty>
    discountRate?: string
}

export type InvoiceSummaries = Record<string, InvoiceSummary>

export type InvoiceSummary = {
    count: number
    sum: Record<CurrencyCodes, string>
}

export const invoiceSummaryTypes = ["all", "dueNext7Days", "dueLaterThan7Days", "lateInvoices", "remaining"] as const
export const invoicePaymentStatusTypes = ["dueNext7Days", "dueLaterThan7Days", "lateInvoices", "remaining"] as const

export type InvoiceSummaryTypes = (typeof invoiceSummaryTypes)[number]

export interface InvoiceSummaryAPI {
    all: InvoiceSummaries
    dueLaterThan7Days: InvoiceSummaries
    dueNext7Days: InvoiceSummaries
    lateInvoices: InvoiceSummaries
    remaining: InvoiceSummaries
}

export const invoiceStatuses = ["DRAFT", "REFUSED", "CONFIRMED", "VALIDATED", "PAID", "CANCELED"] as const
export type InvoiceStatuses = (typeof invoiceStatuses)[number]

export const EditableInvoiceStatuses = ["DRAFT"] as const
export type EditableInvoiceStatusesEnum = (typeof EditableInvoiceStatuses)[number]

export const InvoicesTab = ["ALL", "DRAFT", "REFUSED", "CONFIRMED", "VALIDATED", "PAID", "CANCELED"] as const
export type InvoicesTabEnum = (typeof InvoicesTab)[number]

export type TagPlus = {
    dimensionName: string
    tagName: string
    tagId: string
}
