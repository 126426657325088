import { Box, Stack, Typography } from "@mui/material"
import { useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { Button, Card, Loader, SafeFormattedMessage, TooltipConditional } from "~/components"
import { permissionMessages } from "~/domains/identity/roles-permissions/utils/permissions"
import { useHasPaymentPermissions } from "~/domains/payment/hooks"
import {
    useCreatePaymentMethodDetailsObjectMutation,
    useGetPartnerPaymentMethodDetailsQuery,
} from "~/domains/payment/payment-method-details/api/paymentMethodDetailsApi"
import { PaymentMethodDetailsCreateOrEditModal } from "~/domains/payment/payment-method-details/components/"
import { PaymentMethodDetailsSelector } from "~/domains/payment/payment-method-details/components/DocumentPaymentSection/PaymentMethodDetailsSelector"
import { PaymentMethodDetails } from "~/domains/payment/payment-method-details/types"
import { PaymentMethodType } from "~/domains/payment/payment-methods/types"
import { ObjectType, PaymentMethodDetailsFormType } from "~/domains/payment/types"

const messages = defineMessages({
    payee: {
        id: "payment.payeePaymentMethodDetails.payee",
        defaultMessage: "Payee",
    },
    paymentMethod: {
        id: "payment.payeePaymentMethodDetails.paymentMethod",
        defaultMessage: "Payment method",
    },
    addPaymentMethod: {
        id: "payment.payeePaymentMethodDetails.addPaymentMethod",
        defaultMessage: "+ Add a payment method",
    },
    linkCreated: {
        id: "payment.payeePaymentMethodDetails.linkCreated",
        defaultMessage: "The payment method has been linked to your organization",
    },
})

interface PayeePaymentMethodDetailsProps {
    payerId: string
    payeeId: string
    loading?: boolean
    label?: string
    selectedPaymentMethodDetailsId: string | null
    formType?: PaymentMethodDetailsFormType
    onPaymentMethodChanged: (paymentMethodDetailsId: string) => void
}

export const PayeePaymentMethodDetails = ({
    payerId,
    payeeId,
    label,
    loading = false,
    selectedPaymentMethodDetailsId,
    formType = PaymentMethodDetailsFormType.MODAL,
    onPaymentMethodChanged,
}: PayeePaymentMethodDetailsProps) => {
    const { formatMessage } = useIntl()
    const [createPaymentMethodDetailsObject, { isLoading: isCreating }] = useCreatePaymentMethodDetailsObjectMutation()
    const { data, isLoading, isFetching } = useGetPartnerPaymentMethodDetailsQuery({
        ownerId: payeeId,
        partnerId: payerId,
    })
    const [open, setOpen] = useState(false)
    const items = data?.items ?? []

    const { permissions } = useHasPaymentPermissions({ authorizations: ["create", "update"] })
    const hasCreatePermission = Boolean(permissions?.create?.hasPermission)
    const hasUpdatePermission = Boolean(permissions?.update?.hasPermission)

    const handleOpen = () => {
        if (!hasCreatePermission) return
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handlePaymentMethdDetailsChanged = (id: string) => {
        if (!hasUpdatePermission) return
        onPaymentMethodChanged(id)
    }

    const handlePaymentMethodDetailsCreated = async ({ id }: PaymentMethodDetails) => {
        if (payeeId && payeeId && payeeId !== payerId) {
            await createPaymentMethodDetailsObject({
                payment_method_details_id: id,
                object_id: payerId,
                object_type: ObjectType.ORGANIZATION,
            })
            toast.success(formatMessage(messages.linkCreated))
            if (onPaymentMethodChanged) {
                onPaymentMethodChanged(id)
            }
        }
    }

    if (isLoading || isFetching || isCreating) {
        return <Loader small />
    }

    return (
        <Card>
            <Stack spacing={2}>
                <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h4">{label}</Typography>
                    <TooltipConditional
                        title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                        condition={!hasCreatePermission}
                        placement="top"
                        arrow
                    >
                        <Box component="span">
                            <Button
                                size="x-small"
                                type="title"
                                disabled={!hasCreatePermission || loading || open}
                                onClick={handleOpen}
                            >
                                {formatMessage(messages.addPaymentMethod)}
                            </Button>
                        </Box>
                    </TooltipConditional>
                </Stack>
                {!open && items.length > 0 && (
                    <TooltipConditional
                        title={<SafeFormattedMessage {...permissionMessages.errorNoAccessAdministrator} />}
                        condition={!hasUpdatePermission}
                        placement="top"
                        arrow
                    >
                        <Box component="span">
                            <PaymentMethodDetailsSelector
                                label={formatMessage(messages.paymentMethod)}
                                value={selectedPaymentMethodDetailsId}
                                items={items}
                                loading={loading}
                                disabled={!hasUpdatePermission}
                                onItemSelect={handlePaymentMethdDetailsChanged}
                            />
                        </Box>
                    </TooltipConditional>
                )}
                {open && (
                    <PaymentMethodDetailsCreateOrEditModal
                        activeTab={PaymentMethodType.CARD}
                        open
                        formType={formType}
                        documentOwnerOrganizationId={payeeId}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        onPaymentMethodDetailsCreated={handlePaymentMethodDetailsCreated}
                    />
                )}
            </Stack>
        </Card>
    )
}
