import { Box, Stack, Tooltip, Typography } from "@mui/material"
import dayjs from "dayjs"
import { CheckCircle } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { Button, Card, Loader, NoPermission, SafeFormattedMessage } from "~/components"
import { LightButton } from "~/components/LightButton"
import { permissionMessages } from "~/domains/identity/roles-permissions/utils/permissions"
import { useHasPaymentPermissions } from "~/domains/payment/hooks"
import { ObjectAssignPicker } from "~/domains/payment/payment-batches/components"
import { ActivePayment } from "~/domains/payment/payment-method-details/components"
import { XMLGenerator } from "~/domains/payment/payment/components/DocumentPayment"
import { useManagePayment } from "~/domains/payment/payment/hooks/useManagePayment"
import { DocumentData, PaymentStatus } from "~/domains/payment/payment/types"
import { ObjectType } from "~/domains/payment/types"
import { CurrencyCodes } from "~/types"

const messages = defineMessages({
    updatePayment: {
        id: "payment.document.paymentDetails.updatePayment",
        defaultMessage: "Update",
    },
    paymentDetails: {
        id: "payment.document.paymentDetails",
        defaultMessage: "Payment details",
    },
    markAsPaid: {
        id: "payment.document.paymentDetails.markAsPaid",
        defaultMessage: "Mark as paid",
    },
    warningMessage: {
        id: "payment.document.paymentDetails.warningMessage",
        defaultMessage: "Notify your vendor that you have executed the payment",
    },
    paymentExecuted: {
        id: "payment.document.paymentDetails.paymentExecuted",
        defaultMessage: "You've set the invoice as paid",
    },
})

interface DocumentPaymentProps {
    paymentBlocked: boolean
    documentId: string
    payerId?: string | null
    payeeId?: string | null
    documentData?: DocumentData
    onPaymentUpdate?: (documentId: string) => void
    onMarkAsPaid?: () => void
}

export const DocumentPayment = ({
    documentId,
    paymentBlocked,
    payeeId,
    payerId,
    documentData,
    onPaymentUpdate,
    onMarkAsPaid,
}: DocumentPaymentProps) => {
    const { formatMessage } = useIntl()
    const { payment, assignedObject, isLoading, isUpdatingPayment, editPayment } = useManagePayment({
        documentId,
        payeeId,
        payerId,
        documentData,
    })

    const { permissions } = useHasPaymentPermissions({ authorizations: ["read", "update"] })
    const hasReadPermission = Boolean(permissions?.read?.hasPermission)
    const hasUpdatePermission = Boolean(permissions?.update?.hasPermission)

    const handleUpdatePayment = () => {
        if (onPaymentUpdate) {
            onPaymentUpdate(documentId)
        }
    }

    const handleMarkAsPaid = async () => {
        if (!hasUpdatePermission || paymentBlocked) return

        await editPayment({
            status: PaymentStatus.EXECUTED,
            date_validated: new Date().toISOString(),
            date_scheduled: null,
            value_date_expected: null,
        })
        if (onMarkAsPaid) {
            onMarkAsPaid()
        }
    }

    if (isLoading) {
        return (
            <Card title={formatMessage(messages.paymentDetails)}>
                <Loader position="center" small />
            </Card>
        )
    }

    if (!payment?.id) {
        return null
    }

    return (
        <Card
            header={
                <Stack direction="row" gap={1} alignItems="center">
                    <Typography variant="h4">{formatMessage(messages.paymentDetails)}</Typography>
                    {payment.status !== PaymentStatus.EXECUTED && hasReadPermission && hasUpdatePermission && (
                        <LightButton disabled={!hasUpdatePermission} onClick={handleUpdatePayment}>
                            {formatMessage(messages.updatePayment)}
                        </LightButton>
                    )}
                </Stack>
            }
            expandable
            isExpandedByDefault={hasReadPermission}
            sx={{
                width: "100%",
            }}
        >
            {hasReadPermission ? (
                <Stack spacing={2} flexDirection="column">
                    <ObjectAssignPicker
                        isPaid={payment?.status === PaymentStatus.EXECUTED}
                        assignedObject={assignedObject}
                        payload={{
                            objectId: documentId,
                            objectType: ObjectType.INVOICE,
                            amount: documentData?.total.amount ?? 0,
                            currency: documentData?.total.currency ?? CurrencyCodes.EUR,
                            dateScheduled: documentData?.dueDate ?? dayjs().format("YYYY-MM-DD"),
                            payeeId: payeeId ?? "",
                        }}
                    />
                    <ActivePayment
                        isBatched={!!assignedObject}
                        payment={payment}
                        paymentReferenceNumber={documentData?.paymentReferenceNumber ?? null}
                    />
                    {payment.status === PaymentStatus.EXECUTED ? (
                        <Box
                            component="div"
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            gap={2}
                        >
                            <CheckCircle size={48} color="var(--color-green)" />
                            <Typography component="h2" textAlign="center" color="var(--color-grey-light)">
                                {formatMessage(messages.paymentExecuted)}
                            </Typography>
                        </Box>
                    ) : (
                        <Stack spacing={2}>
                            {payment.destination_cash_id && <XMLGenerator paymentId={payment.id} />}
                            {!paymentBlocked && (
                                <Tooltip
                                    arrow
                                    placement="bottom"
                                    title={
                                        <SafeFormattedMessage
                                            {...(hasUpdatePermission
                                                ? messages.warningMessage
                                                : permissionMessages.errorNoAccessAdministrator)}
                                        />
                                    }
                                >
                                    <span>
                                        <Button
                                            type="primary-light"
                                            block
                                            disabled={!hasUpdatePermission || isUpdatingPayment}
                                            onClick={handleMarkAsPaid}
                                        >
                                            {formatMessage(messages.markAsPaid)}
                                        </Button>
                                    </span>
                                </Tooltip>
                            )}
                        </Stack>
                    )}
                </Stack>
            ) : (
                <NoPermission size="sm" hideIcon />
            )}
        </Card>
    )
}
