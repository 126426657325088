import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorization as prepareHeaders } from "~/api/prepareHeaders"
import { DashboardMetricsUrlResponse } from "~/domains/analytics/metrics/types/Metrics"

const BASE_URL = import.meta.env.VITE_API_METRICS_URL

export const metricsApi = createApi({
    reducerPath: "metricsApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, prepareHeaders }),
    endpoints: (builder) => ({
        getDashboardMetricsUrl: builder.query<
            string,
            { dashboardId: string; organizationId: string; supplierId?: string }
        >({
            query: ({ dashboardId, organizationId, supplierId }) => ({
                url: `dashboard/${dashboardId}`,
                params: { organizationId, ...(supplierId && { supplierId }) },
            }),
            transformResponse: (response: DashboardMetricsUrlResponse) => {
                return response.embedUrl
            },
            transformErrorResponse: (response) => ({
                meta: {
                    tags: {
                        domain: "analytics",
                        subdomain: "metrics",
                        feature: "get-dashboard-metrics-url",
                    },
                },
                ...response,
            }),
        }),
    }),
})

export const { useGetDashboardMetricsUrlQuery } = metricsApi
