import { Box, Stack } from "@mui/material"
import { AlertTriangle, Check } from "react-feather"
import { defineMessages } from "react-intl"

import { SafeFormattedMessage, TooltipWhite } from "~/components"
import { Button } from "~/components"
import { Separator } from "~/domains/orchestration/flows-v0/components/BlockSettings/BranchBlockSettings/Separator"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { invoiceActions, selectInvoiceCommunications } from "~/store/invoice/invoiceSlice"

const messages = defineMessages({
    markResolved: {
        id: "supplier.invoice.page.markResolved",
        defaultMessage: "Resolve all requests",
    },
    eventsInvoiceRequestInfo: {
        id: "events.type.info_requested",
        defaultMessage: "Information requested",
    },
})


export const ResolveAllRequestsInvoice = () => {
    const dispatch = useAppDispatch()

    const communications = useAppSelector(selectInvoiceCommunications)

    const handleMarkAsResolved = async () => {
        dispatch(invoiceActions.markAsResolved())
    }

    return (
        <>
            {communications.length > 0 && (
                <>
                    <TooltipWhite
                        title={
                            <Stack gap={1}>
                                <Box padding={2} paddingBottom={1}>
                                    {communications.map((communication, index) => (
                                        <Stack gap={1} key={index}>
                                            <h4>{communication.user?.fullName}</h4>
                                            <p>{communication.message}</p>
                                        </Stack>
                                    ))}
                                </Box>
                                <Separator />
                                <Box padding={2} paddingTop={1}>
                                    <Button onClick={handleMarkAsResolved} type="grey" size="small">
                                        <Check size={16} />
                                        <SafeFormattedMessage {...messages.markResolved} />
                                    </Button>
                                </Box>
                            </Stack>
                        }
                        arrow
                        padding="0"
                    >
                        <div>
                            <Button type="warning-light">
                                <AlertTriangle size={16} />
                                <SafeFormattedMessage {...messages.eventsInvoiceRequestInfo} />
                            </Button>
                        </div>
                    </TooltipWhite>
                </>
            )}
        </>
    )
}
