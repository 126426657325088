import {
    BaseQueryApi,
    BaseQueryExtraOptions,
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
} from "@reduxjs/toolkit/query"

export type ExtendedFetchBaseQueryError = FetchBaseQueryError & {
    meta?: Record<string, unknown>
}

type TaggedBaseQuery = {
    baseQueryFn: BaseQueryFn
    domain?: string
    subdomain?: string
}

export const taggedBaseQuery =
    ({ baseQueryFn, domain, subdomain }: TaggedBaseQuery) =>
    async (args: FetchArgs, api: BaseQueryApi, extraOptions: BaseQueryExtraOptions<BaseQueryFn>) => {
        const result = await baseQueryFn(args, api, extraOptions)

        if (result.error) {
            const error = result.error as ExtendedFetchBaseQueryError
            const existingMeta = error.meta ?? {}

            error.meta = {
                ...existingMeta,
                tags: {
                    domain,
                    subdomain,
                    endpointName: api.endpoint,
                },
            }
        }

        return result
    }
