import { Box } from "@mui/material"
import * as Sentry from "@sentry/react"
import { lazy, useCallback, useMemo, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { generatePath, useNavigate, useParams } from "react-router-dom"

import { commonMessages } from "~/common-messages"
import {
    CheckFileTypeResult,
    DatatableFiltersActions,
    HeaderH1,
    ModalBatchImport,
    Tabs,
    ViewSwitcherTab,
} from "~/components"
import { ModalResultImport } from "~/components/ModalBatchImport/ModalResultImport"
import { useUserObjectPermissionsCheckQuery } from "~/domains/identity/roles-permissions/api/spiceDbApi"
import { SpiceDBObjectType } from "~/domains/identity/roles-permissions/types/SpiceDB"
import {
    purchaseOrderApi,
    useGetPurchaseOrdersSummaryQuery,
} from "~/domains/transactions/purchase-orders/api/purchaseOrderApi"
import { TabLabelFromSummary } from "~/domains/transactions/purchase-orders/components/POList/TabLabel"
import { Filters } from "~/domains/transactions/purchase-orders/components/list/Filters"
import { purchaseOrdersMessages } from "~/domains/transactions/purchase-orders/pages/index"
import {
    PURCHASE_ORDERS_PAGINATED_ROUTE,
    PURCHASE_ORDER_NEW_ROUTE,
} from "~/domains/transactions/purchase-orders/routes"
import { purchaseOrdersActions } from "~/domains/transactions/purchase-orders/store/purchaseOrdersSlice"
import { PurchaseOrdersTab } from "~/domains/transactions/purchase-orders/types"
import { useImportBatchPurchaseOrder } from "~/domains/transactions/purchase-requests/store/hooks/useImportBatchPurchaseOrder"
import { useTitle } from "~/hooks"
import { selectUser } from "~/store/account/accountSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { AuthorizationName, ViewTypeI } from "~/types"
import { ImportBatchResponseI } from "~/types/ImportBatch"

const ListLazy = lazy(() =>
    import("~/domains/transactions/purchase-orders/components/POListPaginated/List").then(({ PaginatedList }) => ({
        default: PaginatedList,
    }))
)

const messages = defineMessages({
    title: {
        id: "purchase.orders.list.title",
        defaultMessage: "Purchase orders",
    },
})

const ACCEPTED_FILE_EXTENSIONS: string[] = ["csv", "xlsx"]
const NOTION_URL_BATCH_PR =
    "https://get-flowie.notion.site/Flowie-Documentation-File-Format-for-Purchase-Order-Upload-5c146e1f97ac4277baea19ff61a5d9d9?pvs=4"

export const PurchaseOrdersPaginated = () => {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { viewType } = useParams()

    const pageName = formatMessage(messages.title)
    useTitle(pageName)

    const [tabValue, setTabValue] = useState<PurchaseOrdersTab>(PurchaseOrdersTab.ALL)
    const [modalBatchImportVisible, setModalBatchImportVisible] = useState(false)
    const [modalResultImportVisible, setModalResultImportVisible] = useState(false)
    const [resultBatchImport, setResultBatchImport] = useState<ImportBatchResponseI | undefined>()

    const organizationId = useAppSelector(selectCurrentOrganizationId)
    const currentUser = useAppSelector(selectUser)

    const { data: purchaseOrderPermissions } = useUserObjectPermissionsCheckQuery(
        {
            userId: currentUser?.id ?? "",
            objectType: SpiceDBObjectType.PURCHASE_ORDER,
            organizationId: organizationId as string,
            authorizations: [AuthorizationName.CREATE],
        },
        { skip: !organizationId || !currentUser?.id }
    )

    const view = useMemo(() => viewType ?? ViewTypeI.buyer, [viewType]) as ViewTypeI

    const { importBatchPO, loading: loadingBatchImport } = useImportBatchPurchaseOrder(organizationId as string)

    const { data: purchaseOrdersSummary } = useGetPurchaseOrdersSummaryQuery({
        organizationId: organizationId as string,
        buyerId: view === ViewTypeI.buyer ? organizationId : undefined,
        supplierId: view === ViewTypeI.supplier ? organizationId : undefined,
    })

    const handleSwitchViewToBuyers = () =>
        navigate(generatePath(PURCHASE_ORDERS_PAGINATED_ROUTE, { viewType: ViewTypeI.buyer }))
    const handleSwitchViewToSuppliers = () =>
        navigate(generatePath(PURCHASE_ORDERS_PAGINATED_ROUTE, { viewType: ViewTypeI.supplier }))

    const onClickNewDraftPo = () => navigate(PURCHASE_ORDER_NEW_ROUTE)
    const closeModalBatchImport = () => setModalBatchImportVisible(false)
    const showResultImportModal = () => setModalResultImportVisible(true)
    const closeModalResultImport = () => setModalResultImportVisible(false)
    const onClickBatchImport = () => setModalBatchImportVisible(true)

    const handleChangeTab = (newValue: number | string) => {
        setTabValue(newValue as PurchaseOrdersTab)
        dispatch(purchaseOrdersActions.setCurrentPurchaseOrdersTab(newValue as PurchaseOrdersTab))
    }

    const handleFile = async (file: File) => {
        try {
            const result: ImportBatchResponseI = await importBatchPO(file)
            setResultBatchImport(result)
            closeModalBatchImport()
            showResultImportModal()
            dispatch(purchaseOrderApi.util.invalidateTags(["PaginatedPurchaseOrders"]))
        } catch (error) {
            console.error(error)
            Sentry.captureException(error, {
                extra: {
                    file,
                    organizationId,
                    page: "purchase-orders-paginated",
                },
            })
        }
    }

    const checkFileType = useCallback(
        (file: File): CheckFileTypeResult => {
            const fileExtension = file.name.split(".").pop()
            if (fileExtension && ACCEPTED_FILE_EXTENSIONS.includes(fileExtension)) {
                return { result: true, error: null }
            }

            return { result: false, error: formatMessage(purchaseOrdersMessages.errorWrongFileType) }
        },
        [formatMessage]
    )

    const handleClickInstructions = () => {
        window.open(NOTION_URL_BATCH_PR, "_blank")
    }

    const actions: DatatableFiltersActions[] = [
        { label: formatMessage(purchaseOrdersMessages.createPurchaseOrder), action: onClickNewDraftPo },
        { label: formatMessage(commonMessages.batchImport), action: onClickBatchImport },
    ]

    return (
        <>
            <HeaderH1 title={pageName} />
            <Box className="main-box">
                {organizationId && (
                    <>
                        <Filters actions={actions} hasPermission={purchaseOrderPermissions?.create} />
                        <ViewSwitcherTab
                            view={view}
                            labelSupplier={formatMessage(purchaseOrdersMessages.labelSupplier)}
                            labelBuyer={formatMessage(purchaseOrdersMessages.labelBuyer)}
                            actionBuyer={handleSwitchViewToBuyers}
                            actionSupplier={handleSwitchViewToSuppliers}
                        />

                        <Tabs
                            defaultValue={tabValue}
                            setChange={handleChangeTab}
                            tabs={Object.keys(PurchaseOrdersTab).map((tab) => {
                                const tabKey = tab as PurchaseOrdersTab

                                return {
                                    label: (
                                        <TabLabelFromSummary
                                            purchaseOrdersSummary={purchaseOrdersSummary}
                                            tab={tabKey}
                                        />
                                    ),
                                    value: tabKey,
                                    component: <ListLazy view={view} />,
                                }
                            })}
                        />
                    </>
                )}
            </Box>
            <ModalBatchImport
                open={modalBatchImportVisible}
                close={closeModalBatchImport}
                handleFile={handleFile}
                checkFileType={checkFileType}
                handleClickInstructions={handleClickInstructions}
                loading={loadingBatchImport}
                uploadTip={purchaseOrdersMessages.modalUploadTip}
                title={purchaseOrdersMessages.modalUploadTitle}
            />
            <ModalResultImport
                open={modalResultImportVisible}
                close={closeModalResultImport}
                showNewImportModal={onClickBatchImport}
                resultBatchImport={resultBatchImport}
                title={purchaseOrdersMessages.modalUploadTitle}
            />
        </>
    )
}
