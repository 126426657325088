import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { purchaseOrdersApi } from "~/domains/transactions/purchase-orders/api"
import { getPurchaseOrderId } from "~/domains/transactions/purchase-orders/core/purchaseOrder"
import { purchaseOrdersActions } from "~/domains/transactions/purchase-orders/store/purchaseOrdersSlice"
import { ListPurchaseOrders } from "~/domains/transactions/purchase-orders/types"
import { useAppDispatch } from "~/store/hooks"
import { OrganizationId } from "~/types"
import { sleep } from "~/utils"

const messages = defineMessages({
    purchaseOrderClosed: {
        id: "purchase.orders.purchaseOrderClosed",
        defaultMessage:
            "Your purchase {count, plural, =0 {} one {order has} other {orders have}} been successfully marked as closed.",
    },
    unableToMarkAsClosedPurchaseOrder: {
        id: "purchase.orders.unableToMarkAsClosedPurchaseOrder",
        defaultMessage:
            "An error occured: your purchase {count, plural, =0 {} one {order} other {orders}} could not be marked as closed.",
    },
    unableToMarkAsClosedSomePurchaseOrder: {
        id: "purchase.orders.unableToMarkAsClosedSomePurchaseOrder",
        defaultMessage:
            "An error occured: {count} purchase {count, plural, =0 {} one {order} other {orders}} could not be marked as closed.",
    },
})

export const useMarkAsClosedPurchaseOrders = () => {
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()

    return async (purchaseOrders: ListPurchaseOrders, organizationId: OrganizationId) => {
        const results = await Promise.allSettled(
            purchaseOrders.map(async (purchaseOrder) => {
                await purchaseOrdersApi.updateStatus(organizationId, purchaseOrder.id, "CLOSED")
            })
        )

        // We have to wait for the PO updates to be processed, cause we fetch them right after to get updated statuses
        await sleep(200)

        const resultPOs = await Promise.allSettled(
            results.map((r, index) => {
                const purchaseOrderIds = purchaseOrders.map((po) => po.id)
                const purchaseOrderId = getPurchaseOrderId(r, index, purchaseOrderIds)
                if (purchaseOrderId) {
                    return purchaseOrdersApi.findById(organizationId, purchaseOrderId)
                }
                return undefined
            })
        )

        const successClosedPOs = resultPOs.reduce((acc, r) => {
            if (r.status === "fulfilled" && r.value) {
                acc.push(r.value)
            }
            return acc
        }, [] as ListPurchaseOrders)

        const failClosedLength = results.length - successClosedPOs.length
        dispatch(purchaseOrdersActions.updatePurchaseOrders(successClosedPOs))
        if (failClosedLength > 0) {
            toast.error(
                formatMessage(
                    failClosedLength === results.length
                        ? messages.unableToMarkAsClosedPurchaseOrder
                        : messages.unableToMarkAsClosedSomePurchaseOrder,
                    { count: failClosedLength }
                )
            )
        } else {
            toast.success(formatMessage(messages.purchaseOrderClosed, { count: purchaseOrders.length }))
        }
        return true
    }
}
