import { Tab, Tabs, capitalize } from "@mui/material"
import { defineMessages } from "react-intl"
import { MessageDescriptor } from "react-intl"

import { commonMessages } from "~/common-messages"
import { SafeFormattedMessage } from "~/components"
import { useGetInvoicesSummaryQuery } from "~/domains/transactions/invoices-v1/api/invoiceApi"
import { useGetOrganizationInvoiceLifecycleQuery } from "~/domains/transactions/invoices-v1/api/invoiceLifeCycleApi"
import {
    InvoiceSummaryTypes,
    LifecycleGroup,
    invoicePaymentStatusTypes,
} from "~/domains/transactions/invoices-v1/types/Invoice"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { ViewTypes } from "~/types"
import { Variant } from "~/utils/colors"

const messages = defineMessages({
    all: {
        id: "invoice.summary.all",
        defaultMessage: "All",
    },
    dueNext7Days: {
        id: "invoice.summary.dueNext7Days",
        defaultMessage: "Due next week",
    },
    dueLaterThan7Days: {
        id: "invoice.summary.dueLaterThan7Days",
        defaultMessage: "Due later",
    },
    lateInvoices: {
        id: "invoice.summary.late",
        defaultMessage: "Late",
    },
    remaining: {
        id: "invoice.summary.remaining",
        defaultMessage: "Paid",
    },
})

const tabTypes = ["all", "lifecycle", "payments"] as const
type TabType = (typeof tabTypes)[number]

type TabProps = {
    label: React.ReactNode
    value: string | InvoiceSummaryTypes
    variant?: Variant
    type: TabType
}

interface InvoicesListTabsProps {
    currentSummaryType?: string
    status?: string
    view?: ViewTypes
    onChange: (status?: string, paymentStatus?: InvoiceSummaryTypes) => void
}

export const InvoicesListTabs = ({
    currentSummaryType = "all",
    status = "",
    view,
    onChange,
}: InvoicesListTabsProps) => {
    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)

    const { data: invoiceSummary } = useGetInvoicesSummaryQuery({
        organizationId: currentOrganizationId || "",
        payerId: view === "buyer" ? currentOrganizationId : undefined,
        sellerId: view === "supplier" ? currentOrganizationId : undefined,
    })
    const { data: invoiceLifecycle } = useGetOrganizationInvoiceLifecycleQuery(currentOrganizationId || "")

    const countAll = Object.values(invoiceSummary?.all || {}).reduce((acc, curr) => acc + curr.count, 0) ?? 0

    const countLifecycleStatusTab = (s: string) => invoiceSummary?.all?.[s]?.count

    const countPaymentStatusTab = (type: InvoiceSummaryTypes) =>
        Object.values(invoiceSummary?.[type] || {}).reduce((acc, curr) => acc + (curr?.count ?? 0), 0)

    const getLifecycleTabs = (il?: [string, { group: LifecycleGroup; index: number; variant: Variant }][]) => {
        const translateLabel = (name: string) => {
            const message = commonMessages[name] as MessageDescriptor
            return message ? <SafeFormattedMessage {...message} /> : capitalize(name)
        }

        return (il || []).map(([s, { variant }]) => ({
            label: (
                <span>
                    {translateLabel(s)}
                    <div className="tab-count"> {countLifecycleStatusTab(s)} </div>
                </span>
            ),
            property: "status",
            value: s,
            variant,
            type: "lifecycle",
        })) as TabProps[]
    }

    const getPaymentsTabs = () => {
        const translatePaymentLabel = (type: InvoiceSummaryTypes) =>
            messages[type] ? <SafeFormattedMessage {...messages[type]} /> : capitalize(type)

        return invoicePaymentStatusTypes.map((tab) => ({
            label: (
                <span>
                    {translatePaymentLabel(tab)}
                    <div className="tab-count"> {countPaymentStatusTab(tab)} </div>
                </span>
            ),
            value: tab as string,
            type: "payments",
        })) as TabProps[]
    }

    const tabs: TabProps[] = [
        {
            label: (
                <span>
                    <SafeFormattedMessage {...commonMessages.all} />
                    <div className="tab-count"> {countAll} </div>
                </span>
            ),
            value: "all",
            type: "lifecycle",
        },
        ...getLifecycleTabs(invoiceLifecycle),
        ...getPaymentsTabs(),
    ]

    const handleChangeTab = (e: React.SyntheticEvent, newValue: string | InvoiceSummaryTypes) => {
        const type = (e.currentTarget as HTMLButtonElement).dataset.type as TabType
        const lifecycleValue = type === "lifecycle" && newValue === "all" ? "" : newValue

        if (type === "payments") {
            onChange(undefined, newValue as InvoiceSummaryTypes)
        } else {
            onChange(lifecycleValue, undefined)
        }
    }

    const activeTab = currentSummaryType !== "all" ? currentSummaryType : status || "all"

    return (
        <Tabs
            value={activeTab}
            onChange={handleChangeTab}
            variant="scrollable"
            allowScrollButtonsMobile
            sx={{ borderBottom: 0 }}
        >
            {tabs.map((tab, index) => (
                <Tab key={index} label={tab.label} value={tab.value} data-type={tab.type} />
            ))}
        </Tabs>
    )
}
