import { Loader } from "~/components"
import { useGetAssignedObjectsByObjectIdQuery } from "~/domains/payment/payment-batches/api/paymentBatchesApi"
import { ObjectAssignInfo } from "~/domains/payment/payment-batches/components"
import { ObjectType, PaymentUI } from "~/domains/payment/types"

interface PaymentLinkedBatchProps {
    row: PaymentUI
}

export const PaymentLinkedBatch = ({ row }: PaymentLinkedBatchProps) => {
    const paymentObjectId =
        row?.paymentObjects?.find(({ objectType }) => objectType === ObjectType.TRANSACTION)?.objectId ?? ""

    const { data: objectsData, isLoading } = useGetAssignedObjectsByObjectIdQuery(
        { objectId: paymentObjectId },
        { skip: !paymentObjectId }
    )

    const batchAssignedObject =
        objectsData?.items?.find(
            ({ objectType, objectId }) => objectId === paymentObjectId && objectType === ObjectType.INVOICE
        ) ?? null

    if (isLoading) {
        return <Loader small />
    }

    if (!batchAssignedObject) {
        return null
    }

    return <ObjectAssignInfo mode="line" assignedObject={batchAssignedObject} />
}
