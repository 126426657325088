import * as t from "io-ts"

import { InvoiceOcrRawDataI } from "~/domains/_shared/ocr/types"
import { parseImportInvoiceCompanyInfoI } from "~/domains/transactions/invoices/types/InvoiceParsers"
import { CompanyIO, ImportInvoiceCompanyInfoIO, parseCompany } from "~/types"
import { getResultSuccessValue, isResultError, isResultSuccess } from "~/types/Result"
import { genericParser, optional } from "~/utils"

const OcrRawPaymentDetailsIO = t.partial({
    accountNumber: optional(t.string),
    bankName: optional(t.string),
    bicSwift: optional(t.string),
    country: optional(t.string),
    holderName: optional(t.string),
    iban: optional(t.string),
    kid: optional(t.string),
    sortCode: optional(t.string),
})

const InvoiceOcrRawDataIO = t.partial({
    supplier: optional(ImportInvoiceCompanyInfoIO),
    buyer: optional(ImportInvoiceCompanyInfoIO),
    suggestedSupplierCompanies: optional(t.array(CompanyIO)),
    suggestedBuyerCompanies: optional(t.array(CompanyIO)),
    paymentDetails: optional(OcrRawPaymentDetailsIO),
})

const InvoiceOcrDataIO = t.partial({
    ocrData: optional(InvoiceOcrRawDataIO),
})

export const invoiceOcrRawDataFromApiAdapter = (data: unknown): InvoiceOcrRawDataI => {
    const result = genericParser(data, InvoiceOcrDataIO)
    if (isResultError(result)) return {}

    return {
        buyer: result.result.ocrData?.buyer ? parseImportInvoiceCompanyInfoI(result.result.ocrData?.buyer) : undefined,
        supplier: result.result.ocrData?.supplier
            ? parseImportInvoiceCompanyInfoI(result.result.ocrData?.supplier)
            : undefined,
        suggestedSupplierCompanies: result.result.ocrData?.suggestedSupplierCompanies
            ? result.result.ocrData.suggestedSupplierCompanies
                  .map(parseCompany)
                  .filter(isResultSuccess)
                  .map(getResultSuccessValue)
            : null,
        suggestedBuyerCompanies: result.result.ocrData?.suggestedBuyerCompanies
            ? result.result.ocrData.suggestedBuyerCompanies
                  .map(parseCompany)
                  .filter(isResultSuccess)
                  .map(getResultSuccessValue)
            : null,
        paymentDetails: {
            accountNumber: result.result.ocrData?.paymentDetails?.accountNumber ?? null,
            bankName: result.result.ocrData?.paymentDetails?.bankName ?? null,
            bicSwift: result.result.ocrData?.paymentDetails?.bicSwift ?? null,
            country: result.result.ocrData?.paymentDetails?.country ?? null,
            holderName: result.result.ocrData?.paymentDetails?.holderName ?? null,
            iban: result.result.ocrData?.paymentDetails?.iban ?? null,
            kid: result.result.ocrData?.paymentDetails?.kid ?? null,
            sortCode: result.result.ocrData?.paymentDetails?.sortCode ?? null,
        },
    }
}
