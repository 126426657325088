import { IconButton, Stack } from "@mui/material"
import { RiAddLine, RiCloseLine } from "@remixicon/react"
import React, { FC, useState } from "react"

import { SafeFormattedMessage } from "~/components"
import { EnhancedTextField } from "~/domains/orchestration/flows/components/EnhancedTextField"
import { messages } from "~/domains/orchestration/flows/locale"
import { Reviewer, UserType, isTeamReviewer, isUserReviewer } from "~/domains/orchestration/flows/types"

interface ExpressionModeProps {
    expressionReviewers: Reviewer[]
    onExpressionReviewersChange: (reviewers: Reviewer[]) => void
}

export const ExpressionMode: FC<ExpressionModeProps> = ({ expressionReviewers, onExpressionReviewersChange }) => {
    const [userExpressions, setUserExpressions] = useState<string[]>(
        expressionReviewers.filter(isUserReviewer).map((r) => r.userId)
    )
    const [teamExpressions, setTeamExpressions] = useState<string[]>(
        expressionReviewers.filter(isTeamReviewer).map((r) => r.teamId)
    )

    const updateReviewers = (users: string[], teams: string[]) => {
        const userReviewers: Reviewer[] = users.filter(Boolean).map((userId) => ({
            userId,
            type: UserType.USER,
        }))

        const teamReviewers: Reviewer[] = teams.filter(Boolean).map((teamId) => ({
            teamId,
            type: UserType.TEAM,
        }))

        onExpressionReviewersChange([...userReviewers, ...teamReviewers])
    }

    const handleAddUserExpression = () => {
        const newExpressions = [...userExpressions, ""]
        setUserExpressions(newExpressions)
        updateReviewers(newExpressions, teamExpressions)
    }

    const handleAddTeamExpression = () => {
        const newExpressions = [...teamExpressions, ""]
        setTeamExpressions(newExpressions)
        updateReviewers(userExpressions, newExpressions)
    }

    const handleRemoveUserExpression = (index: number) => {
        const newExpressions = userExpressions.filter((_, i) => i !== index)
        setUserExpressions(newExpressions)
        updateReviewers(newExpressions, teamExpressions)
    }

    const handleRemoveTeamExpression = (index: number) => {
        const newExpressions = teamExpressions.filter((_, i) => i !== index)
        setTeamExpressions(newExpressions)
        updateReviewers(userExpressions, newExpressions)
    }

    const handleUserExpressionChange = (index: number) => (value: unknown) => {
        const newExpressions = [...userExpressions]
        newExpressions[index] = String(value)
        setUserExpressions(newExpressions)
        updateReviewers(newExpressions, teamExpressions)
    }

    const handleTeamExpressionChange = (index: number) => (value: unknown) => {
        const newExpressions = [...teamExpressions]
        newExpressions[index] = String(value)
        setTeamExpressions(newExpressions)
        updateReviewers(userExpressions, newExpressions)
    }

    return (
        <Stack gap={3} className="mt-12">
            <Stack gap={2}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <SafeFormattedMessage tagName="h6" {...messages.checkNodeConfiguration.userId} />
                    <IconButton onClick={handleAddUserExpression} size="small">
                        <RiAddLine size={16} />
                    </IconButton>
                </Stack>
                {userExpressions.map((expression, index) => (
                    <Stack key={index} direction="row" gap={1} alignItems="center">
                        <EnhancedTextField
                            value={expression}
                            onChange={handleUserExpressionChange(index)}
                            expressionOnly
                            fullWidth
                        />
                        <IconButton onClick={() => handleRemoveUserExpression(index)} size="small">
                            <RiCloseLine size={16} />
                        </IconButton>
                    </Stack>
                ))}
            </Stack>

            <Stack gap={2}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <SafeFormattedMessage tagName="h6" {...messages.checkNodeConfiguration.teamId} />
                    <IconButton onClick={handleAddTeamExpression} size="small">
                        <RiAddLine size={16} />
                    </IconButton>
                </Stack>
                {teamExpressions.map((expression, index) => (
                    <Stack key={index} direction="row" gap={1} alignItems="center">
                        <EnhancedTextField
                            value={expression}
                            onChange={handleTeamExpressionChange(index)}
                            expressionOnly
                            fullWidth
                        />
                        <IconButton onClick={() => handleRemoveTeamExpression(index)} size="small">
                            <RiCloseLine size={16} />
                        </IconButton>
                    </Stack>
                ))}
            </Stack>
        </Stack>
    )
}
