import * as Sentry from "@sentry/react"
import { useEffect } from "react"

import { InvoiceI, ViewTypeI } from "~/types"
import { FetchError } from "~/utils/apiClient"

export const useSetInvoiceContext = (
    invoiceId: string | undefined | null,
    viewType: ViewTypeI,
    invoice: InvoiceI | undefined | null,
    fetchError?: FetchError<InvoiceI> | null
) => {
    useEffect(() => {
        if (invoiceId) {
            Sentry.setTag("invoiceId", invoiceId)
        }
    }, [invoiceId])
    useEffect(() => {
        Sentry.setTag("invoiceViewType", viewType)
    }, [viewType])
    useEffect(() => {
        if (invoice) {
            Sentry.setContext("invoice", invoice)
        }
    }, [invoice])
    useEffect(() => {
        if (fetchError) {
            Sentry.setContext("invoiceFetchError", fetchError)
        }
    }, [fetchError])
}
