/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { PayloadAction } from "@reduxjs/toolkit"
import * as Sentry from "@sentry/react"
import { all, call, put, takeLatest } from "redux-saga/effects"

import { accountActions } from "~/store/account/accountSlice"
import { LoginPageType, auth0 } from "~/utils"

import { authActions } from "./authSlice"

function* isAuthenticated() {
    try {
        const result: boolean = yield call(auth0().isAuthenticated)
        if (result) {
            yield put(accountActions.fetchUser())
        } else {
            yield put(authActions.setIsConnected(false))
            yield put(accountActions.disconnect())
        }
    } catch (error) {
        yield put(authActions.setIsLoading(false))
        yield put(accountActions.disconnect())
        yield call(auth0().logout)
        Sentry.captureException(error)
    }
}

function* openPopupSignIn(action: PayloadAction<LoginPageType | undefined>) {
    const isPlaywright = window.location.search.includes("e2e=true")

    try {
        const page = action.payload ?? "signin"
        if (isPlaywright) {
            const result = yield call(auth0().loginWithRedirect, page)
            if (result !== "cancelled") {
                yield put(accountActions.fetchUser())
                yield put(authActions.setIsConnected(true))
            }
        } else {
            const result = yield call(auth0().login, page)
            if (result === "cancelled") {
                yield put(authActions.setClosePopup())
            } else {
                yield put(accountActions.fetchUser())
                yield put(authActions.setIsConnected(true))
            }
        }
    } catch (error) {
        Sentry.captureException(error)
    }
}

function* logout() {
    try {
        yield call(auth0().logout)
        yield put(accountActions.disconnect())
    } catch (error) {
        Sentry.captureException(error)
        yield put(accountActions.disconnect())
    }
}

export default function* authSaga() {
    yield all([
        takeLatest(authActions.isAuthenticated.type, isAuthenticated),
        takeLatest(authActions.openPopupSignIn.type, openPopupSignIn),
        takeLatest(authActions.logout.type, logout),
    ])
}
