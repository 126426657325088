import { Stack } from "@mui/material"

import { InvoiceSummary } from "~/domains/transactions/invoices-v1/components/InvoiceSummary"
import { InvoiceSummaryTypes } from "~/domains/transactions/invoices-v1/types/Invoice"
import { ViewTypes } from "~/types"

interface InvoicesListHeaderProps {
    currentSummaryType: InvoiceSummaryTypes
    currentStatus?: string
    view: ViewTypes
    setFilters: (summaryType: InvoiceSummaryTypes, status: string | undefined) => void
}

export const InvoicesListHeader = ({
    currentSummaryType,
    currentStatus,
    view,
    setFilters,
}: InvoicesListHeaderProps) => {
    return (
        <Stack direction="row" justifyContent="center" alignItems="center" maxWidth="100%">
            <InvoiceSummary
                currentStatus={currentStatus}
                currentSummaryType={currentSummaryType}
                view={view}
                setFilters={setFilters}
            />
        </Stack>
    )
}
