import { MessageDescriptor } from "react-intl"

import { AuthorizationName } from "~/types"

export enum SpiceDBObjectType {
    COMPANY = "company",
    ROLE = "role",
    USER = "user",
    TEAM = "team",
    PURCHASE_REQUEST = "purchase_request",
    PURCHASE_ORDER = "purchase_order",
    CATALOG = "catalog",
    INVOICE = "invoice",
    INVOICE_LIFECYCLE = "invoice_lifecycle",
}

export const AuthorizationNameEqualities: Partial<Record<AuthorizationName, AuthorizationName[]>> = {
    [AuthorizationName.READ]: [AuthorizationName.VIEW],
    [AuthorizationName.VIEW]: [AuthorizationName.READ],
    [AuthorizationName.EDIT]: [AuthorizationName.UPDATE],
    [AuthorizationName.UPDATE]: [AuthorizationName.EDIT],
}

export const SpiceDBSpecialAuthorization: Partial<Record<AuthorizationName, AuthorizationName[]>> = {
    [AuthorizationName.READ]: [AuthorizationName.VIEW],
    [AuthorizationName.EDIT]: [AuthorizationName.VIEW],
    [AuthorizationName.UPDATE]: [AuthorizationName.VIEW, AuthorizationName.EDIT],
    [AuthorizationName.APPROVE]: [AuthorizationName.VIEW],
    [AuthorizationName.CONVERT]: [AuthorizationName.VIEW],
    [AuthorizationName.OWNER]: [AuthorizationName.VIEW, AuthorizationName.EDIT, AuthorizationName.APPROVE],
    [AuthorizationName.CHECK]: [AuthorizationName.VIEW],
}

export const SpiceDBObjectAuthorizationName: Partial<Record<SpiceDBObjectType, Record<string, AuthorizationName[]>>> = {
    [SpiceDBObjectType.PURCHASE_REQUEST]: {
        authorized: [
            AuthorizationName.VIEW,
            AuthorizationName.EDIT,
            AuthorizationName.APPROVE,
            AuthorizationName.CONVERT,
            AuthorizationName.DELETE,
        ],
        unauthorized: [AuthorizationName.OWNER, AuthorizationName.CHECK],
    },
    [SpiceDBObjectType.CATALOG]: {
        authorized: [AuthorizationName.VIEW, AuthorizationName.CREATE, AuthorizationName.EDIT],
        unauthorized: [],
    },
    [SpiceDBObjectType.PURCHASE_ORDER]: {
        authorized: [
            AuthorizationName.VIEW,
            AuthorizationName.EDIT,
            AuthorizationName.APPROVE,
            AuthorizationName.DELETE,
            AuthorizationName.SEND,
            AuthorizationName.CLOSE,
            AuthorizationName.REOPEN,
            AuthorizationName.MARK_LINE_IN_PREPARATION,
            AuthorizationName.MARK_LINE_IN_TRANSIT,
            AuthorizationName.MARK_LINE_DELIVERED_OR_RECEIVED,
            AuthorizationName.MARK_LINE_CANCELED,
        ],
        unauthorized: [AuthorizationName.CHECK, AuthorizationName.SUBMIT],
    },
    [SpiceDBObjectType.INVOICE]: {
        authorized: [AuthorizationName.VIEW, AuthorizationName.EDIT],
        unauthorized: [AuthorizationName.CHECK],
    },
    [SpiceDBObjectType.INVOICE_LIFECYCLE]: {
        authorized: [AuthorizationName.EDIT],
        unauthorized: [],
    },
}

export const SpiceDBObjectRelatedPermissionTarget: Partial<Record<SpiceDBObjectType, SpiceDBObjectType[]>> = {
    [SpiceDBObjectType.INVOICE]: [SpiceDBObjectType.INVOICE_LIFECYCLE],
}

export interface SpiceDBObjectReferenceI {
    objectType: SpiceDBObjectType
    objectId: string
}

interface SpiceDBObjectReferenceWithNameI {
    objectType: SpiceDBObjectType
    objectId: string
    name?: string
    email?: string
    membersNumber?: number
}

export interface SpiceDBPermissionsLiteI extends SpiceDBObjectReferenceWithNameI {
    permissions: AuthorizationName[]
}

export interface SpiceDBSharePermissionsBodyDto {
    objects: SpiceDBObjectReferenceI[]
    authorizations: AuthorizationName[]
    isFirstTimeShare: boolean
    creatorOrganizationId: string
    recepientOrganizationId: string
}

export enum SpiceDBPurchaseRequestPermission {
    VIEWER = "viewer",
    EDITOR = "editor",
    APPROVER = "approver",
    CHECKER = "checker",
    CONVERTER = "converter",
}

export interface AuthzedSharePermissionsBodyDto {
    objects: SpiceDBObjectReferenceI[]
    authorizations: string[]
    isFirstTimeShare: boolean
    creatorOrganizationId: string
    recipientOrganizationId: string
}

export interface SpiceDBWriteRelationshipsResponse {
    writtenAt?: string
}

export const sharedObjectPermissionsMessages: Partial<Record<SpiceDBObjectType, MessageDescriptor>> = {
    [SpiceDBObjectType.PURCHASE_REQUEST]: {
        id: "share.object.modal.purchaseRequest",
        defaultMessage: "this purchase request",
    },
    [SpiceDBObjectType.PURCHASE_ORDER]: {
        id: "share.object.modal.purchaseOrder",
        defaultMessage: "this purchase order",
    },
    [SpiceDBObjectType.USER]: {
        id: "share.object.modal.user",
        defaultMessage: "this user",
    },
    [SpiceDBObjectType.TEAM]: {
        id: "share.object.modal.team",
        defaultMessage: "this team",
    },
    [SpiceDBObjectType.CATALOG]: {
        id: "share.object.modal.catalog",
        defaultMessage: "this catalog",
    },
    [SpiceDBObjectType.INVOICE]: {
        id: "share.object.modal.invoice",
        defaultMessage: "this invoice",
    },
}

export const sharedObjectPermissionsModalMessages: Record<string, MessageDescriptor> = {
    title: { id: "share.object.modal.title", defaultMessage: "Share permissions" },
    email: { id: "share.object.modal.email", defaultMessage: "Email" },
    supplierEmail: { id: "share.object.modal.supplierEmail", defaultMessage: "Supplier email" },
    buyerEmail: { id: "share.object.modal.buyerEmail", defaultMessage: "Buyer email" },
    selectOrganization: { id: "share.object.modal.selectOrganization", defaultMessage: "Select company" },
    share: { id: "share.object.modal.share", defaultMessage: "Share" },
    supplier: {
        id: "purchase.organization.supplier",
        defaultMessage: "Supplier",
    },
    buyer: {
        id: "purchase.organization.buyer",
        defaultMessage: "Buyer",
    },
    confirmDeleteSharing: {
        id: "share.object.modal.confirmDeleteSharing",
        defaultMessage: "Are you sure you want to delete this sharing?",
    },
    confirmDeleteTitle: {
        id: "share.object.modal.deleteSharing",
        defaultMessage: "Delete a sharing",
    },
    confirmRemoveAllPermissionsTitle: {
        id: "share.object.modal.confirmRemoveAllPermissionsTitle",
        defaultMessage: "Remove all permissions",
    },
    confirmRemoveAllPermissions: {
        id: "share.object.modal.confirmRemoveAllPermissions",
        defaultMessage: "Are you sure you want to remove all permissions?",
    },
    can: {
        id: "share.object.modal.can",
        defaultMessage: "Can ",
    },
    membersCount: {
        id: "share.object.modal.membersCount",
        defaultMessage: "{membersCount, plural, =0 {# member} one {# member} other {# members}}",
    },
    sharePermissionsSuccess: {
        id: "share.object.modal.sharePermissionsSuccess",
        defaultMessage:
            "{count, plural, =0 {No permission shared} one {Permission shared} other {Permissions shared}} to {objectType}",
    },
    shareMultiPermissionsSuccess: {
        id: "share.object.modal.shareMultiPermissionsSuccess",
        defaultMessage: "{count, plural, =0 {No permission shared} one {Permission shared} other {Permissions shared}}",
    },
    removePermissionsSuccess: {
        id: "share.object.modal.removePermissionsSuccess",
        defaultMessage:
            "{count, plural, =0 {No permission removed} one {Permission removed} other {Permissions removed}} from {objectType}",
    },
    sharedObjectsText: {
        id: "share.object.modal.sharedObjectsText",
        defaultMessage: "Have access to {objectType}:",
    },
    addMembersOrTeams: {
        id: "share.object.modal.addMembersOrTeams",
        defaultMessage: "Add members or teams",
    },
    members: {
        id: "share.object.modal.members",
        defaultMessage: "Members",
    },
    teams: {
        id: "share.object.modal.teams",
        defaultMessage: "Teams",
    },
    permissions: { id: "share.object.modal.permissions", defaultMessage: "Permissions" },
    removeAllTooltip: {
        id: "share.object.modal.removeAllTooltip",
        defaultMessage:
            "You can remove only permissions you already have. Owner and checker permissions can't be removed here.",
    },
    collaborators: {
        id: "share.object.modal.collaborators",
        defaultMessage: "{count, plural, =0 {no collaborator} one {1 collaborator} other {# collaborators}}",
    },
    createSharingSuccess: {
        id: "share.object.create.success",
        defaultMessage: "{objectType} shared",
    },
    deleteSharingSuccess: {
        id: "share.object.delete.success",
        defaultMessage: "Sharing deleted",
    },
    sendTitle: {
        id: "share.object.modal.send.title",
        defaultMessage: "Send to vendor",
    },
}
