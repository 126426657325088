/* eslint-disable complexity */
/* eslint-disable no-nested-ternary */
import { useCallback, useMemo } from "react"
import { Check, CheckCircle, Edit, FileText, Package, Send, Truck, UserCheck } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { Steps } from "~/components"
import { StepI } from "~/domains/transactions/_shared/components/Steps/Step"
import { selectPurchaseOrderRelationsState } from "~/domains/transactions/_shared/document-relations/store/documentRelationsSlice"
import { FulfillmentStatus } from "~/domains/transactions/_shared/types/Purchases"
import { isFulfillmentStatusCurrentOrCompleted } from "~/domains/transactions/purchase-orders/core/lifecycleLinePurchaseOrder"
import { isStatusCurrentOrCompleted } from "~/domains/transactions/purchase-orders/core/lifecyclePurchaseOrder"
import { allLinesApproved } from "~/domains/transactions/purchase-orders/core/purchaseOrder"
import {
    ApprovalStatusType,
    FulfillmentStatusType,
    PurchaseOrderApprovalStatus,
    PurchaseOrderLine,
    PurchaseOrderProgressStatus,
    PurchaseOrdersResponse,
} from "~/domains/transactions/purchase-orders/types"
import { useAppSelector } from "~/store/hooks"
import { ViewTypeI } from "~/types"

const messages = defineMessages({
    approved: {
        id: "purchase.orders.order.steps.approved",
        defaultMessage: "Approved",
    },
    PODraft: {
        id: "purchase.orders.order.steps.PODraft",
        defaultMessage: "Draft",
    },
    POSubmitted: {
        id: "purchase.orders.order.steps.POSubmitted",
        defaultMessage: "Submitted",
    },
    POShared: {
        id: "purchase.orders.order.steps.POShared",
        defaultMessage: "Shared",
    },
    inPreparation: {
        id: "purchase.orders.order.steps.inPreparation",
        defaultMessage: "In preparation",
    },
    inDelivery: {
        id: "purchase.orders.order.steps.isTransit",
        defaultMessage: "In transit",
    },
    delivered: {
        id: "purchase.orders.order.steps.delivered",
        defaultMessage: "Delivered",
    },
    received: {
        id: "purchase.orders.order.steps.received",
        defaultMessage: "Received",
    },
    invoiced: {
        id: "purchase.orders.order.steps.invoiced",
        defaultMessage: "Invoiced",
    },
    items: {
        id: "purchase.orders.order.steps.items",
        defaultMessage: "{current}/{total} items",
    },
})

const getFilteredLines = (
    lines: PurchaseOrderLine[],
    statusKey: ApprovalStatusType,
    approvalStatus: PurchaseOrderApprovalStatus
) => lines.filter((line) => line[statusKey] === approvalStatus).length

const getFilteredFulfillmentLines = (
    lines: PurchaseOrderLine[],
    fulfillmentKey: FulfillmentStatusType,
    minFulfillmentStatus: FulfillmentStatus
) => lines.filter((line) => isFulfillmentStatusCurrentOrCompleted(line[fulfillmentKey], minFulfillmentStatus)).length

interface StepsProps {
    PO: PurchaseOrdersResponse
    viewType: ViewTypeI
}

export function StepsPurchaseOrder({ PO, viewType }: StepsProps) {
    const { formatMessage } = useIntl()
    const isBuyerView = viewType === ViewTypeI.buyer

    const { purchaseOrderRelations } = useAppSelector(selectPurchaseOrderRelationsState)
    const areAllLinesApproved = useMemo(() => allLinesApproved(PO.lines, viewType), [PO.lines, viewType])

    const getNumberOfLines = useCallback(
        (statusOrFulfillment: PurchaseOrderProgressStatus): number => {
            const lines = PO.lines

            if (statusOrFulfillment === "INVOICED" && purchaseOrderRelations.length) {
                return lines.length
            }

            switch (statusOrFulfillment) {
                case "SUBMITTED":
                case "CLOSED":
                    return lines.length

                case "INTERNALLY_APPROVED":
                    return getFilteredLines(lines, "buyerApprovalStatus", "APPROVED")

                case "IN_PREPARATION": {
                    const buyerFulfillmentCount = getFilteredFulfillmentLines(
                        lines,
                        "buyerFulfillment",
                        "IN_PREPARATION"
                    )
                    const supplierFulfillmentCount = getFilteredFulfillmentLines(
                        lines,
                        "supplierFulfillment",
                        "IN_PREPARATION"
                    )

                    return Math.max(buyerFulfillmentCount, supplierFulfillmentCount)
                }

                case "PENDING":
                    return viewType === ViewTypeI.buyer
                        ? getFilteredLines(PO.lines, "supplierApprovalStatus", "APPROVED")
                        : getFilteredLines(PO.lines, "buyerApprovalStatus", "APPROVED")

                case "SHIPPED":
                    return getFilteredFulfillmentLines(lines, "buyerFulfillment", "OUT_FOR_DELIVERY")

                case "RECEIVED": {
                    const buyerFulfillmentCount = getFilteredFulfillmentLines(lines, "buyerFulfillment", "DELIVERED")
                    const supplierFulfillmentCount = getFilteredFulfillmentLines(
                        lines,
                        "supplierFulfillment",
                        "DELIVERED"
                    )

                    return Math.max(buyerFulfillmentCount, supplierFulfillmentCount)
                }
                default:
                    return 0
            }
        },
        [PO.lines, PO.status, purchaseOrderRelations]
    )

    const isPendingStatus = () => {
        const isCurrentStatusOpen = isStatusCurrentOrCompleted(PO.statusPresentation, "OPEN")
        if (isBuyerView) {
            const nbAcceptedLines = getFilteredLines(PO.lines, "supplierApprovalStatus", "APPROVED")
            return isCurrentStatusOpen && nbAcceptedLines === PO.lines.length
        }

        const nbAcceptedLines = getFilteredLines(PO.lines, "buyerApprovalStatus", "APPROVED")
        return isCurrentStatusOpen && nbAcceptedLines === PO.lines.length
    }

    let steps: StepI[]
    if (isBuyerView) {
        steps = [
            {
                key: "submitted",
                name: formatMessage(messages.POSubmitted),
                status: isStatusCurrentOrCompleted(PO.statusPresentation, "SUBMITTED") ? "done" : "next",
                icon: <Send size={18} />,
            },
            {
                key: "approved",
                name: formatMessage(messages.approved),
                linesInProgress: getNumberOfLines("INTERNALLY_APPROVED"),
                totalLines: PO.lines.length,
                status: isStatusCurrentOrCompleted(PO.statusPresentation, "INTERNALLY_APPROVED") ? "done" : "next",
                icon: <Check size={18} />,
            },
            {
                key: "pending",
                name: formatMessage(commonMessages.acceptedBySupplier),
                linesInProgress: getNumberOfLines("PENDING"),
                totalLines: PO.lines.length,
                status: isPendingStatus() ? "done" : "next",
                icon: <CheckCircle size={18} />,
            },
            {
                key: "inPreparation",
                name: formatMessage(messages.inPreparation),
                linesInProgress: getNumberOfLines("IN_PREPARATION"),
                totalLines: PO.lines.length,
                status: isStatusCurrentOrCompleted(PO.fulfillmentPresentation, "IN_PREPARATION") ? "done" : "next",
                icon: <Package size={18} />,
            },
            {
                key: "inDelivery",
                name: formatMessage(messages.inDelivery),
                linesInProgress: getNumberOfLines("SHIPPED"),
                totalLines: PO.lines.length,
                status: isStatusCurrentOrCompleted(PO.fulfillmentPresentation, "SHIPPED") ? "done" : "next",
                icon: <Truck size={18} />,
            },
            {
                key: "received",
                name: formatMessage(messages.received),
                linesInProgress: getNumberOfLines("RECEIVED"),
                totalLines: PO.lines.length,
                status: isStatusCurrentOrCompleted(PO.fulfillmentPresentation, "RECEIVED") ? "done" : "next",
                icon: <UserCheck size={18} />,
            },
            {
                key: "invoiced",
                name: formatMessage(messages.invoiced),
                status: purchaseOrderRelations?.length ? "done" : "next",
                icon: <FileText size={18} />,
            },
        ]

        if (PO.status === "DRAFT") {
            steps.unshift({
                key: "draft",
                name: formatMessage(messages.PODraft),
                status: "done",
                icon: <Edit size={18} />,
            })
        }
    } else {
        steps = [
            {
                key: "received",
                name: formatMessage(messages.received),
                status: isStatusCurrentOrCompleted(PO.status, "OPEN") ? "done" : "next",
                icon: <Send size={18} />,
            },
            {
                key: "approved",
                name: formatMessage(messages.approved),
                status:
                    isStatusCurrentOrCompleted(PO.statusPresentation, "OPEN") ||
                    ((isStatusCurrentOrCompleted(PO.fulfillmentPresentation, "PENDING") ||
                        !PO.fulfillmentPresentation) &&
                        areAllLinesApproved)
                        ? "done"
                        : "next",
                icon: <Check size={18} />,
            },
            {
                key: "inPreparation",
                name: formatMessage(messages.inPreparation),
                linesInProgress: getNumberOfLines("IN_PREPARATION"),
                totalLines: PO.lines.length,
                status: isStatusCurrentOrCompleted(PO.fulfillmentPresentation, "IN_PREPARATION") ? "done" : "next",
                icon: <Package size={18} />,
            },
            {
                key: "inDelivery",
                name: formatMessage(messages.inDelivery),
                linesInProgress: getNumberOfLines("SHIPPED"),
                totalLines: PO.lines.length,
                status: isStatusCurrentOrCompleted(PO.fulfillmentPresentation, "SHIPPED") ? "done" : "next",
                icon: <Truck size={18} />,
            },
            {
                key: "delivered",
                name: formatMessage(messages.delivered),
                linesInProgress: getNumberOfLines("RECEIVED"),
                totalLines: PO.lines.length,
                status: isStatusCurrentOrCompleted(PO.fulfillmentPresentation, "RECEIVED") ? "done" : "next",
                icon: <UserCheck size={18} />,
            },
            {
                key: "invoiced",
                name: formatMessage(messages.invoiced),
                status: purchaseOrderRelations?.length ? "done" : "next",
                icon: <FileText size={18} />,
            },
        ]
    }

    return <Steps steps={steps} />
}
