import { Stack, Typography } from "@mui/material"
import React from "react"

import { commonMessages } from "~/common-messages"
import { SafeFormattedMessage } from "~/components"
import { CollapsedObjects } from "~/domains/identity/roles-permissions/components/ShareObjectPermissionsModal/CollapsedObjects"
import { ObjectPermissions } from "~/domains/identity/roles-permissions/components/ShareObjectPermissionsModal/ObjectPermissions"
import { SpiceDBObjectType, SpiceDBPermissionsLiteI } from "~/domains/identity/roles-permissions/types/SpiceDB"

interface Props {
    objectId: string
    objectType: SpiceDBObjectType
    sharedPermissions?: SpiceDBPermissionsLiteI[] | null
    setShowOnlyList: (showOnlyList: boolean) => void
}

const MAX_OBJECTS = 6
export const ObjectsList: React.FC<Props> = ({ objectId, objectType, sharedPermissions, setShowOnlyList }) => {
    if (!sharedPermissions?.length) {
        return (
            <Typography>
                <SafeFormattedMessage {...commonMessages.noSharedPermissions} />
            </Typography>
        )
    }

    return (
        <Stack gap={1}>
            {sharedPermissions.length <= MAX_OBJECTS ? (
                <>
                    {sharedPermissions?.map((object) => (
                        <ObjectPermissions
                            key={object.objectId}
                            objectId={objectId}
                            objectType={objectType}
                            objectPermission={object}
                        />
                    ))}
                </>
            ) : (
                <CollapsedObjects sharedPermissions={sharedPermissions} setShowOnlyList={setShowOnlyList} />
            )}
        </Stack>
    )
}
