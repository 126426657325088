import * as Sentry from "@sentry/react"
import dayjs from "dayjs"
import { useCallback } from "react"
import { defineMessages, useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import { INVOICE_BUYER_ROUTE } from "~/domains/transactions/invoices/_views/buyer/routes"
import { notificationDefaultMessages } from "~/domains/transactions/invoices/_views/supplier/common/notificationDefaultMessages"
import { invoiceApi } from "~/domains/transactions/invoices/api/invoiceApi"
import { parseInvoice } from "~/domains/transactions/invoices/types/InvoiceParsers"
import { store } from "~/store"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { ocrActions } from "~/store/ocr/ocrSlice"
import { useFetchOrganization } from "~/store/organization/hooks"
import { InvoiceI, InvoiceStatus, InvoiceUserType, NotificationI } from "~/types"
import { isResultError } from "~/types/Result"

import {
    ConfirmInvoiceOptions,
    SetUserOrganizationIdResponse,
    getImportCompanyIdentifierOrThrow,
    setUserOrganizationId,
} from "../core"
import { invoiceActions, selectInvoice } from "../invoiceSlice"
import { useAddInvolvedPeopleAndJoinOrganization } from "./useAddInvolvedPeople"

export const errorRejectedInvoiceMessages = defineMessages({
    errorRejected: {
        id: "invoice.addInvoice.error.rejected",
        defaultMessage: "The invoice has been rejected by a workflow. You can't confirm it anymore.",
    },
})

export const useAddInvoice = (invoiceId: string | undefined, options: ConfirmInvoiceOptions) => {
    const invoice = useAppSelector(selectInvoice)
    const { organization: supplierOrganization } = useFetchOrganization(
        options.supplier ? options.supplier.organizationId : undefined
    )
    const { organization: buyerOrganization } = useFetchOrganization(
        options.buyer ? options.buyer.organizationId : undefined
    )

    const { formatMessage } = useIntl()

    const addInvolvedPeople = useAddInvolvedPeopleAndJoinOrganization(invoiceId)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const validateAndMarkAsPaidAlreadyPaidInvoice = async (invoiceToValidate: InvoiceI) => {
        try {
            await validateAlreadyPaidInvoice(invoiceToValidate)
            await markAsPaidAlreadyPaidInvoice(invoiceToValidate)
        } catch (error) {
            Sentry.captureException(error)
            dispatch(ocrActions.confirmInvoiceFailed(`${error}`))
            throw error
        }

        return invoiceToValidate
    }

    const waitForInvoiceStatusPolling = useCallback(
        async (status: InvoiceStatus) => {
            return new Promise<InvoiceStatus>((resolve) => {
                const interval = setInterval(async () => {
                    if (!invoiceId) return

                    try {
                        const invoiceResponse = await invoiceApi.getById(invoiceId)
                        if (isResultError(invoiceResponse)) {
                            throw invoiceResponse.error
                        }
                        const parsedInvoice = parseInvoice(invoiceResponse.result)
                        if (
                            parsedInvoice &&
                            (parsedInvoice.status === status || parsedInvoice.status === InvoiceStatus.REJECTED)
                        ) {
                            clearInterval(interval)
                            resolve(parsedInvoice.status)
                        }
                    } catch (error) {
                        Sentry.captureMessage(`Failed to fetch the invoice`, { extra: { error } })
                    }
                }, 250)
            })
        },
        [invoiceId]
    )

    const validateAlreadyPaidInvoice = useCallback(async (invoiceToValidate: InvoiceI) => {
        if (!invoiceToValidate.id) {
            throw new Error("invoiceId is not defined: cannot validate undefined invoice")
        }

        const status = await waitForInvoiceStatusPolling(InvoiceStatus.CONFIRMED)
        if (status === InvoiceStatus.CONFIRMED) {
            return invoiceApi.validate({ id: invoiceToValidate.id, comment: "", tags: [] })
        } else if (status === InvoiceStatus.REJECTED) {
            toast.error(formatMessage(errorRejectedInvoiceMessages.errorRejected))
            return navigate(generatePath(INVOICE_BUYER_ROUTE, { invoiceId: invoiceToValidate.id }))
        }
    }, [])

    const markAsPaidAlreadyPaidInvoice = useCallback(async (invoiceToMakAsPaid: InvoiceI) => {
        if (!invoiceToMakAsPaid.id) {
            throw new Error("invoiceId is not defined: cannot mark as paid undefined invoice")
        }

        const status = await waitForInvoiceStatusPolling(InvoiceStatus.VALIDATED)
        if (status === InvoiceStatus.VALIDATED) {
            return invoiceApi.markAsPaid(invoiceToMakAsPaid.id)
        } else if (status === InvoiceStatus.REJECTED) {
            toast.error(formatMessage(errorRejectedInvoiceMessages.errorRejected))
            return navigate(generatePath(INVOICE_BUYER_ROUTE, { invoiceId: invoiceToMakAsPaid.id }))
        }
    }, [])

    return useCallback(
        // eslint-disable-next-line complexity
        async (alreadyPaid?: boolean) => {
            if (!invoice) return

            if (!invoiceId) {
                throw new Error("InvoiceId is not defined")
            }
            if (invoice.supplier === undefined) {
                throw new Error("InvoiceId is not defined")
            }

            const supplierIdentifier = getImportCompanyIdentifierOrThrow(invoice.supplier, InvoiceUserType.SUPPLIER)
            let supplier: SetUserOrganizationIdResponse | null = invoice.supplier.organizationId
                ? {
                      organizationId: invoice.supplier.organizationId,
                  }
                : null
            if (!supplier) {
                supplier = await setUserOrganizationId(
                    options.supplier,
                    {
                        countryCode: invoice.supplier.countryCode,
                        email: invoice.supplier.email ?? "",
                        identifier: supplierIdentifier,
                        name: invoice.supplier.contactName ?? "",
                    },
                    supplierOrganization,
                    {
                        name: invoice.supplier.name,
                        countryCode: invoice.supplier.countryCode,
                        identifier: supplierIdentifier,
                        dunsNumber: invoice.supplier.dunsNumber,
                        registrationNumber: invoice.supplier.registrationNumber,
                        vatNumber: invoice.supplier.taxId,
                    }
                )
            }

            let buyer: SetUserOrganizationIdResponse | null = invoice.buyer.organizationId
                ? {
                      organizationId: invoice.buyer.organizationId,
                  }
                : null
            if (!buyer) {
                const buyerIdentifier = getImportCompanyIdentifierOrThrow(invoice.buyer, InvoiceUserType.BUYER)

                buyer = await setUserOrganizationId(
                    options.buyer,
                    {
                        countryCode: invoice.buyer.countryCode,
                        email: invoice.buyer.email ?? "",
                        identifier: buyerIdentifier,
                        name: invoice.buyer.contactName ?? "",
                    },
                    buyerOrganization,
                    {
                        name: invoice.buyer.name,
                        countryCode: invoice.buyer.countryCode,
                        identifier: buyerIdentifier,
                        dunsNumber: invoice.buyer.dunsNumber,
                        registrationNumber: invoice.buyer.registrationNumber,
                        vatNumber: invoice.buyer.taxId,
                    }
                )
            }

            // DO NOT REMOVE: necessary for bulk import
            const notification: NotificationI = {
                subject:
                    invoice.description ??
                    formatMessage(notificationDefaultMessages.subject, {
                        reference: invoice.reference,
                    }),
                body: formatMessage(notificationDefaultMessages.body, {
                    contactName: invoice.buyer.contactName ?? "",
                }),
            }
            dispatch(invoiceActions.setNotification(notification))

            const upToDateInvoice = selectInvoice(store.getState())
            const data = structuredClone(upToDateInvoice)

            if (!data) {
                throw new Error("Should never happen ! Invoice is not defined")
            }
            if (data.paymentDetails) {
                if (data.paymentDetails.iban === "") {
                    data.paymentDetails.iban = null
                }
            }

            const invoiceToAPI = {
                version: data.version,
                reference: data.reference ?? "",
                description: data.description ?? "",
                total: data.total,
                totalDiscount: data.totalDiscount ?? null,
                totalExcludedTaxes: data.totalExcludedTaxes,
                issueDate: data.issueDate ?? dayjs().format("YYYY-MM-DD"),
                dueDate: dayjs(data.dueDate).format("YYYY-MM-DD") ?? "",
                purchaseOrderNumber: data.purchaseOrderNumber ?? undefined,
                buyer: {
                    organizationId: buyer.organizationId,
                    involvedUserIds: buyer.userId ? [buyer.userId] : [],
                },
                supplier: {
                    organizationId: supplier.organizationId,
                    involvedUserIds: supplier.userId ? [supplier.userId] : [],
                },
                paymentDetails: data.paymentDetails ?? {},
                notification: notification,
                lines: data.lines ?? undefined,
            }
            // Temporary adding invoice to both APIs
            const result = await invoiceApi.save(invoice.id, invoiceToAPI)
            dispatch(invoiceActions.updatePartialInvoice({ status: InvoiceStatus.CONFIRMED }))

            await addInvolvedPeople(invoice.buyer.countryCode, supplierIdentifier)

            if (alreadyPaid) {
                return await validateAndMarkAsPaidAlreadyPaidInvoice(invoice)
            }
            return result
        },
        [supplierOrganization, buyerOrganization, invoice, dispatch, formatMessage, addInvolvedPeople, invoiceId]
    )
}
