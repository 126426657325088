import { Checkbox, ListItemText, MenuItem, capitalize } from "@mui/material"
import { useMemo } from "react"
import { useIntl } from "react-intl"

import { AuthorizationNameEqualities } from "~/domains/identity/roles-permissions/types/SpiceDB"
import { authorizationMessages } from "~/domains/identity/roles-permissions/utils/permissions"
import { hasUserAuthorization } from "~/domains/identity/roles-permissions/utils/spiceDB"
import { AuthorizationName } from "~/types"

interface PermissionsMenuItemsProps {
    permissionsSelected: AuthorizationName[]
    currentUserPermissions: AuthorizationName[]
    authorizedAuthorizations: AuthorizationName[]
    unauthorizedAuthorizations: AuthorizationName[]
    setPermissionsSelected: (permissions: AuthorizationName[]) => void
    handleChangePermission: (permissions: AuthorizationName[]) => void
}

export const PermissionsMenuItems: React.FC<PermissionsMenuItemsProps> = ({
    permissionsSelected,
    currentUserPermissions,
    authorizedAuthorizations,
    unauthorizedAuthorizations,
    setPermissionsSelected,
    handleChangePermission,
}) => {
    const { formatMessage } = useIntl()

    const handleItemClick = (authorization: AuthorizationName) => () => {
        const newPermissions = permissionsSelected.includes(authorization)
            ? permissionsSelected.filter((perm) => perm !== authorization)
            : [...permissionsSelected, authorization]
        setPermissionsSelected(newPermissions)
        handleChangePermission(newPermissions)
    }

    const menuItems = useMemo(() => {
        return [...unauthorizedAuthorizations, ...authorizedAuthorizations].reduce<JSX.Element[]>(
            (items, authorization) => {
                if (
                    unauthorizedAuthorizations.includes(authorization) &&
                    !permissionsSelected.includes(authorization)
                ) {
                    return items
                }

                const isChecked = Boolean(
                    permissionsSelected.includes(authorization) ||
                        AuthorizationNameEqualities[authorization]?.some((specialAuthorization) =>
                            permissionsSelected.includes(specialAuthorization)
                        )
                )

                const isDisabled =
                    unauthorizedAuthorizations.includes(authorization) ||
                    !hasUserAuthorization(currentUserPermissions, authorization)

                items.push(
                    <MenuItem
                        className="share-permissions-select-option"
                        key={authorization}
                        value={authorization}
                        disabled={isDisabled}
                        onClick={handleItemClick(authorization)}
                    >
                        <Checkbox checked={isChecked} />
                        <ListItemText primary={capitalize(formatMessage(authorizationMessages[authorization]))} />
                    </MenuItem>
                )

                return items
            },
            []
        )
    }, [
        unauthorizedAuthorizations,
        authorizedAuthorizations,
        permissionsSelected,
        currentUserPermissions,
        formatMessage,
    ])

    return menuItems
}
