import { Alert } from "@mui/material"
import React, { FC, useState } from "react"
import { Plus } from "react-feather"
import { useIntl } from "react-intl"
import { v4 as uuid } from "uuid"

import { Button, SafeFormattedMessage } from "~/components"
import { AdvancedFields } from "~/domains/orchestration/flows/components/configuration/AdvancedFields"
import { Branch } from "~/domains/orchestration/flows/components/configuration/Branch"
import { messages } from "~/domains/orchestration/flows/locale"
import { BranchNode, ConfigurationProps } from "~/domains/orchestration/flows/types"
import type { Branch as BranchType } from "~/domains/orchestration/flows/types"

import "./BranchConfiguration.scss"
import { ConfigurationNode } from "./ConfigurationNode"

const createBranch = (name: string): BranchType => ({
    id: uuid(),
    name,
    conditions: [],
    nextNode: null,
})

export const BranchConfiguration: FC<ConfigurationProps<BranchNode>> = ({
    selectedNode,
    advancedFields,
    validateNode,
}) => {
    const { formatMessage } = useIntl()
    const [currentNode, setCurrentNode] = useState(selectedNode)

    const [branches, setBranches] = useState(selectedNode.branches)

    const updateNode = (newBranches: BranchType[]) => {
        setCurrentNode({
            ...currentNode,
            branches: newBranches,
        })
    }

    const handleChangeBranch = (branch: BranchType) => {
        const newBranches = branches.map((b) => (b.id === branch.id ? branch : b))
        setBranches(newBranches)
        updateNode(newBranches)
    }

    const handleAddBranch = () => {
        const newBranch = createBranch(
            formatMessage(messages.branchConfiguration.branchTitle, {
                index: branches.length + 1,
            })
        )
        const newBranches = [...branches, newBranch]
        setBranches(newBranches)
        updateNode(newBranches)
    }

    const handleDeleteBranch = (branch: BranchType) => {
        const newBranches = branches.filter((b) => b.id !== branch.id)
        setBranches(newBranches)
        updateNode(newBranches)
    }
    const hasEmptyCondition = branches.some((branch) => {
        // Check if branch has no conditions
        if (branch.conditions.length === 0) return true

        // Check each condition group
        return branch.conditions.some((conditionGroup) => {
            // Check if condition group is empty
            if (conditionGroup.conditions.length === 0) return true

            // Check if any individual condition is empty
            return conditionGroup.conditions.some((condition) => condition.condition === "")
        })
    })

    return (
        <ConfigurationNode selectedNode={currentNode} validateNode={validateNode}>
            {hasEmptyCondition && (
                <Alert severity="info">
                    <SafeFormattedMessage {...messages.branchConfiguration.noEmptyConditionInBranchWarning} />
                </Alert>
            )}
            {branches.map((branch) => (
                <Branch
                    key={branch.id}
                    branch={branch}
                    handleChange={handleChangeBranch}
                    handleDeleteBranch={handleDeleteBranch}
                />
            ))}
            <Button
                type="primary-light"
                onClick={handleAddBranch}
                className="flows-branchConfiguration-newConditionGroup"
            >
                <Plus size={18} />
                <SafeFormattedMessage {...messages.branchConfiguration.newBranchLabel} />
            </Button>

            <AdvancedFields<BranchNode>
                fields={advancedFields}
                currentNode={currentNode}
                setCurrentNode={setCurrentNode}
            />
        </ConfigurationNode>
    )
}
