import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorizationAndOrganizationId as prepareHeaders } from "~/api/prepareHeaders"
import { ExtendedFetchBaseQueryError, taggedBaseQuery } from "~/api/rtkFetchBaseExtend"
import { commonMessages } from "~/common-messages"
import {
    ApiSurveyRequirements,
    CustomForm,
    CustomFormSurvey,
    SendFormResponse,
    SurveyLinkedObject,
    SurveyRequirements,
    UserSurvey,
    UserSurveyAnswer,
    UserSurveyApiResponse,
    UserSurveyMetadata,
    UserSurveyState,
    UserSurveyStats,
} from "~/domains/identity/custom-forms/types/CustomForms"

import {
    adaptCustomFormSurveyMetadataFromApi,
    adaptSurveyRequirementsFromApi,
    adaptUserSurveyAnswersFromApi,
    adaptUserSurveyFromApi,
} from "./fromApiAdapters"
import { fileAnswerToApiAdapter } from "./toApiAdapters"

const BASE_URL = import.meta.env.VITE_API_CUSTOM_FORMS_URL

export const customFormsApi = createApi({
    reducerPath: "customFormsApi",
    baseQuery: taggedBaseQuery({
        baseQueryFn: fetchBaseQuery({
            baseUrl: BASE_URL,
            prepareHeaders,
        }),
        domain: "identity",
        subdomain: "custom-forms",
    }),
    tagTypes: [
        "OrganisationCustomForms",
        "CustomForm",
        "PartnerCustomForms",
        "CustomFormStats",
        "SurveyState",
        "CustomFormSurveyStats",
    ],
    endpoints: (builder) => ({
        getOrganisationCustomForms: builder.query<CustomForm[], { organisationId: string }>({
            query: ({ organisationId }) => ({
                url: `${organisationId}/forms/`,
            }),
            providesTags: ["OrganisationCustomForms"],
        }),
        getFormByToken: builder.query<UserSurvey, { formToken: string }>({
            query: ({ formToken }) => ({
                url: `user-surveys/${formToken}/`,
            }),
            transformResponse: (response: UserSurveyApiResponse) => adaptUserSurveyFromApi(response),
        }),
        sendFormToPartner: builder.mutation<
            SendFormResponse,
            {
                organisationId: string
                formId: string
                respondentContacts: Array<{ email?: string; userId?: string }>
                respondentOrganizationId: string
                dueDate?: string
                linkedObjects?: SurveyLinkedObject[]
                context?: Record<string, string[]> // can be any object
            }
        >({
            query: ({
                organisationId,
                formId,
                respondentContacts,
                respondentOrganizationId,
                dueDate,
                linkedObjects,
                context,
            }) => ({
                url: `${organisationId}/forms/${formId}/surveys`,
                method: "POST",
                body: { respondentContacts, respondentOrganizationId, dueTimestamp: dueDate, linkedObjects, context },
            }),
            transformErrorResponse: (response: ExtendedFetchBaseQueryError) => ({
                ...response,
                meta: {
                    ...response.meta,
                    errorMessage: commonMessages.error,
                },
            }),
        }),
        getCustomForm: builder.query<CustomForm, { organisationId: string; formId: string }>({
            query: ({ organisationId, formId }) => ({
                url: `${organisationId}/forms/${formId}`,
            }),
            providesTags: ["CustomForm"],
        }),
        getCustomFormSurveys: builder.query<CustomFormSurvey[], { organisationId: string; formId: string }>({
            query: ({ organisationId, formId }) => ({
                url: `${organisationId}/forms/${formId}/surveys`,
            }),
            providesTags: ["CustomForm"],
        }),
        getCustomFormSurveysForPartner: builder.query<
            CustomFormSurvey[],
            { organisationId: string; respondentOrganizationId: string }
        >({
            query: ({ organisationId, respondentOrganizationId }) => ({
                url: `${organisationId}/forms/surveys/?respondent-organization-id=${respondentOrganizationId}`,
            }),
            providesTags: ["PartnerCustomForms"],
        }),
        getCustomFormSurveysForLinkedObject: builder.query<
            CustomFormSurvey[],
            { organisationId: string; linkedObjectId: string }
        >({
            query: ({ organisationId, linkedObjectId }) => ({
                url: `${organisationId}/forms/surveys?linked-object-id=${linkedObjectId}&linked-object-type=PARTNERSHIP`,
            }),
            providesTags: ["CustomForm"],
        }),
        saveUserAnswer: builder.mutation<
            { success: string },
            { surveyToken: string; questionId: string; answer: unknown }
        >({
            query: ({ surveyToken, questionId, answer }) => ({
                url: `user-surveys/${surveyToken}/answers`,
                method: "POST",
                body: { questionId, answer },
            }),
            invalidatesTags: ["CustomForm", "SurveyState"],
            transformErrorResponse: (response: ExtendedFetchBaseQueryError) => ({
                ...response,
                meta: {
                    ...response.meta,
                    errorMessage: commonMessages.error,
                },
            }),
        }),
        getCustomFormStats: builder.query<
            UserSurveyStats[],
            { organisationId: string; formId?: string; partnerOrganisationId?: string }
        >({
            query: ({ organisationId, formId, partnerOrganisationId }) => {
                const params = new URLSearchParams()
                // Conditionally add query params
                if (formId) params.append("form-id", formId)
                if (partnerOrganisationId) params.append("respondent-organization-id", partnerOrganisationId)

                return {
                    url: `${organisationId}/forms/surveys/stats?${params.toString()}`,
                }
            },
            providesTags: ["CustomFormStats"],
        }),
        getCustomFormStatsBySurvey: builder.query<
            UserSurveyStats,
            { organizationId: string; formId: string; surveyId: string }
        >({
            query: ({ organizationId, formId, surveyId }) => ({
                url: `${organizationId}/forms/${formId}/surveys/${surveyId}/stats`,
            }),
            providesTags: ["CustomFormSurveyStats"],
        }),
        getSurveyState: builder.query<UserSurveyState, { surveyToken: string }>({
            query: ({ surveyToken }) => ({
                url: `user-surveys/${surveyToken}/state`,
            }),
            providesTags: ["SurveyState"],
        }),
        completeForm: builder.mutation<{ success: string }, { surveyToken: string }>({
            query: ({ surveyToken }) => ({
                url: `user-surveys/${surveyToken}/completion`,
                method: "POST",
            }),
            invalidatesTags: ["CustomForm"],
            transformErrorResponse: (response: ExtendedFetchBaseQueryError) => ({
                ...response,
                meta: {
                    ...response.meta,
                    errorMessage: commonMessages.error,
                },
            }),
        }),
        uploadFileAnswer: builder.mutation<
            { success: string },
            { surveyToken: string; questionId: string; file: File }
        >({
            query: ({ surveyToken, questionId, file }) => {
                return {
                    url: `user-surveys/${surveyToken}/answers/files/${questionId}`,
                    method: "PUT",
                    body: fileAnswerToApiAdapter(file),
                    formData: true,
                }
            },
            invalidatesTags: ["CustomForm", "SurveyState"],
            transformErrorResponse: (response: ExtendedFetchBaseQueryError) => ({
                ...response,
                meta: {
                    ...response.meta,
                    errorMessage: commonMessages.errorUpload,
                },
            }),
        }),
        getSurveyAnswers: builder.query<
            UserSurveyAnswer[],
            { organizationId: string; formId: string; surveyId: string }
        >({
            query: ({ organizationId, formId, surveyId }) => ({
                url: `${organizationId}/forms/${formId}/surveys/${surveyId}/answers`,
            }),
            transformResponse: (response) => adaptUserSurveyAnswersFromApi(response as UserSurveyAnswer[]),
        }),
        getSurveyMetadata: builder.query<
            UserSurveyMetadata,
            { organizationId: string; formId: string; surveyId: string }
        >({
            query: ({ organizationId, formId, surveyId }) => ({
                url: `${organizationId}/forms/${formId}/surveys/${surveyId}`,
            }),
            transformResponse: (response) => adaptCustomFormSurveyMetadataFromApi(response as UserSurveyMetadata),
        }),
        getSurveyRequirements: builder.query<SurveyRequirements, { organizationId: string; formId: string }>({
            query: ({ organizationId, formId }) => ({
                url: `${organizationId}/forms/${formId}/surveys/requirements`,
            }),
            transformResponse: (response: ApiSurveyRequirements) => adaptSurveyRequirementsFromApi(response),
            transformErrorResponse: (response: ExtendedFetchBaseQueryError) => ({
                ...response,
                meta: {
                    ...response.meta,
                    errorMessage: commonMessages.error,
                },
            }),
        }),
    }),
})

export const {
    useGetOrganisationCustomFormsQuery,
    useGetFormByTokenQuery,
    useSendFormToPartnerMutation,
    useGetCustomFormQuery,
    useGetCustomFormSurveysQuery,
    useSaveUserAnswerMutation,
    useGetCustomFormSurveysForPartnerQuery,
    useGetCustomFormStatsQuery,
    useGetSurveyStateQuery,
    useCompleteFormMutation,
    useGetCustomFormSurveysForLinkedObjectQuery,
    useUploadFileAnswerMutation,
    useGetSurveyAnswersQuery,
    useGetCustomFormStatsBySurveyQuery,
    useGetSurveyMetadataQuery,
    useLazyGetSurveyRequirementsQuery,
} = customFormsApi
