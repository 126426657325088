import { Tab, Tabs } from "@mui/material"
import { GridColumnsPanel, GridFilterPanel, GridLogicOperator } from "@mui/x-data-grid-premium"
import { useState } from "react"

import { commonMessages } from "~/common-messages"
import { Button, Modal, SafeFormattedMessage, Size } from "~/components"

const filterFormProps = {
    logicOperatorInputProps: {
        variant: "outlined",
        size: "small",
    },
    columnInputProps: {
        variant: "outlined",
        size: "small",
    },
    operatorInputProps: {
        variant: "outlined",
        size: "small",
    },
    valueInputProps: {
        InputComponentProps: {
            variant: "outlined",
            size: "small",
        },
    },
}

const gridFilterPanelSX = {
    py: 2,
    "& .MuiDataGrid-filterForm": { px: 0, display: "flex", alignItems: "center" },
    "& .MuiDataGrid-filterFormLogicOperatorInput": { display: "none" },
    "& .MuiDataGrid-filterFormColumnInput": { mr: 1 },
    "& .MuiDataGrid-filterFormOperatorInput": { mr: 1 },
}

interface DataGridPanelModalProps {
    open: {
        filters: boolean
        columns: boolean
    }
    onClose: () => void
}

export const DataGridPanelModal = ({ open, onClose }: DataGridPanelModalProps) => {
    const isOpen = open.filters || open.columns
    const initialTab = open.filters ? "filters" : "columns"
    const [tab, setTab] = useState<"filters" | "columns">(initialTab)

    const handleChangeTab = (_, value: "filters" | "columns") => {
        setTab(value)
    }

    return (
        <Modal open={isOpen} onClose={onClose} size={Size.MD} minHeight={Size.MD}>
            <Modal.Header>
                <Tabs value={tab} onChange={handleChangeTab}>
                    <Tab label={<SafeFormattedMessage {...commonMessages.filters} />} value="filters" />
                    <Tab label={<SafeFormattedMessage {...commonMessages.columns} />} value="columns" />
                </Tabs>
            </Modal.Header>
            <Modal.Content>
                {tab === "filters" && (
                    <GridFilterPanel
                        logicOperators={[GridLogicOperator.And]}
                        filterFormProps={filterFormProps}
                        sx={gridFilterPanelSX}
                    />
                )}
                {tab === "columns" && <GridColumnsPanel />}
            </Modal.Content>
            <Modal.Footer>
                <Button type="neutral" onClick={onClose}>
                    <SafeFormattedMessage {...commonMessages.close} />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
