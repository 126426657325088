import { Avatar, CircularProgress, Snackbar, SnackbarProps, Stack, Tooltip, styled } from "@mui/material"
import React, { Fragment } from "react"
import { X } from "react-feather"
import { useIntl } from "react-intl"
import CsvDownloadButton from "react-json-to-csv"

import { ButtonType } from "~/components"
import { BulkButton } from "~/components/BulkActions/BulkButton"
import { BulkButtonContent } from "~/components/BulkActions/BulkButtonContent"
import { permissionMessages } from "~/domains/identity/roles-permissions/utils/permissions"

export const BulkActionsSnackbar = styled((props: SnackbarProps) => (
    <Snackbar
        {...props}
        autoHideDuration={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        ClickAwayListenerProps={{ mouseEvent: false, touchEvent: false }}
    />
))({
    background: "var(--primary-color)",
    borderRadius: "var(--border-radius-sm)",
    minWidth: "75% !important",
    zIndex: 1000,
})

export const BulkActionCount = styled("div")({
    fontWeight: "var(--font-weight-xl)",
    marginRight: "var(--spacing-md)",
    minWidth: "82px",
    color: "var(--color-white)",
    "& > span": {
        marginRight: "var(--spacing-xxs)",
    },
})

export type BulkActionButton = {
    label: string | React.ReactNode
    icon: React.ReactNode
    type?: ButtonType
    hasPermission?: boolean
    count?: number
} & (
    | {
          isCsvDownloadButton?: never
          list?: never
          onClick: (selected: string[]) => void
      }
    | {
          isCsvDownloadButton: boolean
          list: unknown[]
          onClick?: never
      }
)

type Props = {
    loading?: boolean
    selected: string[]
    buttons: BulkActionButton[]
    selectedMessage: string
    onClose: () => void
}

export const BulkActions: React.FC<Props> = ({ selected, buttons, onClose, selectedMessage, loading = false }) => {
    const { formatMessage } = useIntl()
    return (
        <BulkActionsSnackbar open onClose={onClose}>
            <Stack direction="row" alignItems="center" gap={2} padding="var(--spacing-xs) var(--spacing-md)">
                <BulkActionCount>
                    {loading ? (
                        <CircularProgress color="inherit" size={32} />
                    ) : (
                        <span>{`${selected.length} ${selectedMessage}`}</span>
                    )}
                </BulkActionCount>
                <Stack direction="row" gap={2} rowGap={1.5} alignItems="center" flexWrap="wrap">
                    {buttons.map((button, key) => (
                        <Fragment key={key}>
                            {button.isCsvDownloadButton && button.list ? (
                                <CsvDownloadButton
                                    data={button.list}
                                    filename="Flowie-export.csv"
                                    className="flowie-button type-primary-light"
                                >
                                    <BulkButtonContent button={button} />
                                </CsvDownloadButton>
                            ) : (
                                <>
                                    {button.hasPermission || typeof button.hasPermission === "undefined" ? (
                                        <BulkButton button={button} selected={selected} loading={loading} />
                                    ) : (
                                        <Tooltip title={formatMessage(permissionMessages.errorNoAccessAdministrator)}>
                                            <div>
                                                <BulkButton button={button} selected={selected} />
                                            </div>
                                        </Tooltip>
                                    )}
                                </>
                            )}
                        </Fragment>
                    ))}
                </Stack>
                <Avatar color="primary" sx={{ width: 24, height: 24 }} onClick={onClose}>
                    <X color="var(--primary-color)" size={18} />
                </Avatar>
            </Stack>
        </BulkActionsSnackbar>
    )
}
