import { FileText } from "react-feather"

import { CreateSurveyConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { RegularNode } from "~/domains/orchestration/flows/components/nodes"
import { adaptEntityTriggerProperties, adaptEventTriggerProperties } from "~/domains/orchestration/flows/core"
import {
    CreateSurveyNode as CreateSurveyNodeType,
    EditorNode,
    Node,
    NodeType,
    Trigger,
    TriggerMode,
} from "~/domains/orchestration/flows/types/"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"

import { createCommonNodeProperties } from "./commonConfig"

const createSurveyNode = (trigger: Trigger): Omit<CreateSurveyNodeType, keyof Node> => {
    const { respondentOrganizationId } =
        trigger.mode === TriggerMode.EVENT
            ? adaptEventTriggerProperties(trigger.type, trigger.slug)
            : adaptEntityTriggerProperties(trigger.type, trigger.slug)

    return {
        nextNode: null,
        formId: "",
        type: NodeType.CREATE_SURVEY_NODE,
        sendNotifications: false,
        respondents: [],
        context: [],
        respondentOrganizationId,
    }
}

const advancedFields: AllAdvancedFieldsUnion[] = [
    AllAdvancedFieldsUnion.FORM_ID,
    AllAdvancedFieldsUnion.RESPONDENT_ORGANIZATION_ID,
]

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.CREATE_SURVEY_NODE) return false

    if (node.context?.length) {
        return node.context.every((context) => context.value)
    }
    return Boolean(node.formId)
}

const createSurveyConfig: Configuration<NodeType.CREATE_SURVEY_NODE> = {
    type: NodeType.CREATE_SURVEY_NODE,
    baseSlug: "create_survey",
    Icon: FileText,
    Configuration: CreateSurveyConfiguration,
    advancedFields,
    Node: RegularNode,
    factory: (props: FactoryProps): CreateSurveyNodeType => ({
        ...createSurveyNode(props.trigger),
        ...createCommonNodeProperties(props),
    }),
    validateNode,
}

export { createSurveyConfig, advancedFields as createSurveyAdvancedFields }
