import { Divider, Stack, capitalize } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { Card } from "~/components"
import { useGetInvoicesSummaryQuery } from "~/domains/transactions/invoices-v1/api/invoiceApi"
import { lifecycleStatuses } from "~/domains/transactions/invoices-v1/core/lifecycleStatuses.conf"
import {
    InvoiceSummaries,
    InvoiceSummary as InvoiceSummaryI,
    InvoiceSummaryTypes,
} from "~/domains/transactions/invoices-v1/types/Invoice"
import { AnalyticBlock } from "~/domains/transactions/invoices/components/Stats"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { ViewTypes } from "~/types"

const getSumByStatus = (invoiceStatus: InvoiceSummaryTypes, summary: InvoiceSummaries) =>
    (summary[invoiceStatus] as InvoiceSummaryI).sum

interface InvoiceSummaryProps {
    currentStatus?: string
    currentSummaryType: InvoiceSummaryTypes
    view: ViewTypes
    setFilters: (summaryType: InvoiceSummaryTypes, status: string | undefined) => void
}

export const InvoiceSummary: FC<InvoiceSummaryProps> = ({ currentStatus, currentSummaryType, view, setFilters }) => {
    const { formatMessage } = useIntl()
    const [currentSummary, setCurrentSummary] = useState<InvoiceSummaryTypes>(currentSummaryType)

    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)

    const { data: invoiceSummary } = useGetInvoicesSummaryQuery({
        organizationId: currentOrganizationId ?? "",
        payerId: view === "buyer" ? currentOrganizationId : undefined,
        sellerId: view === "supplier" ? currentOrganizationId : undefined,
    })

    useEffect(() => setCurrentSummary(currentSummaryType), [currentSummaryType])

    const summary = invoiceSummary?.[currentSummary]

    const invoicesStatuses = Object.keys(summary ?? {}) as InvoiceSummaryTypes[]

    const isActive = (invoiceStatus: InvoiceSummaryTypes) => {
        return currentSummaryType === currentSummary && currentStatus === invoiceStatus
    }

    const handleClickStatus = (s: string) => () => {
        if (currentStatus === s) {
            setFilters(currentSummary, undefined)
        } else {
            setFilters(currentSummary, s)
        }
    }
    const getStatusTitle = (status: string) =>
        commonMessages[status] ? formatMessage(commonMessages[status]) : capitalize(status)

    const getIconAndColor = (status: string) => {
        const statusConf = (lifecycleStatuses[status] || lifecycleStatuses.default) as { icon: string; color: string }
        return { icon: statusConf.icon, color: statusConf.color }
    }

    return (
        <Stack alignItems="center" maxWidth="100%">
            {summary && (
                <>
                    <Card sx={{ padding: 0, width: "fit-content", maxWidth: "100%" }} className="overflow-x">
                        <Stack
                            className="overflow-x"
                            direction="row"
                            divider={<Divider orientation="vertical" flexItem sx={{ width: "1px" }} />}
                        >
                            {invoicesStatuses.map((invoiceStatus) => (
                                <AnalyticBlock
                                    key={invoiceStatus}
                                    title={getStatusTitle(invoiceStatus)}
                                    total={summary[invoiceStatus]?.count ?? 0}
                                    percent={100}
                                    prices={getSumByStatus(invoiceStatus, summary)}
                                    icon={getIconAndColor(invoiceStatus).icon}
                                    color={getIconAndColor(invoiceStatus).color}
                                    isActive={isActive(invoiceStatus)}
                                    onClick={handleClickStatus(invoiceStatus)}
                                />
                            ))}
                        </Stack>
                    </Card>
                </>
            )}
        </Stack>
    )
}
