import { Stack, styled } from "@mui/material"
import { GridColDef, GridRenderCellParams, GridValidRowModel, GridValueGetterParams } from "@mui/x-data-grid-premium"
import { CheckCircle } from "react-feather"
import { defineMessages } from "react-intl"

import { commonMessages } from "~/common-messages"
import { SafeFormattedMessage, StatusChip, getCountryFlag } from "~/components"
import {
    LineActions,
    LineScore,
    ManualVerifications,
    PaymentMethodDetailsApproval,
} from "~/domains/payment/payment-method-details/components"
import {
    BankTransferPaymentMethod,
    CardBrands,
    CardPaymentMethod,
    DirectDebitPaymentMethod,
    PaymentMethodDetails,
    PaymentMethodDetailsObject,
    PaymentMethodDetailsStatus,
    PaymentMethodDetailsToApprove,
} from "~/domains/payment/payment-method-details/types"
import { PaymentMethodType } from "~/domains/payment/payment-methods/types"
import { Countries, CountryCode, CurrencyCodes } from "~/types"

const messages = defineMessages({
    name: {
        id: "payment.paymentMethodDetailsList.name",
        defaultMessage: "Card alias",
    },
    emitter: {
        id: "payment.paymentMethodDetailsList.emitter",
        defaultMessage: "Emitter",
    },
    category: {
        id: "payment.paymentMethodDetailsList.category",
        defaultMessage: "Category",
    },
    credit: {
        id: "payment.paymentMethodDetailsList.credit",
        defaultMessage: "Credit",
    },
    debit: {
        id: "payment.paymentMethodDetailsList.debit",
        defaultMessage: "Debit",
    },
    prepaid: {
        id: "payment.paymentMethodDetailsList.prepaid",
        defaultMessage: "Prepaid",
    },
    expirationDate: {
        id: "payment.paymentMethodDetailsList.expirationDate",
        defaultMessage: "Expiration date",
    },
    cardNumber: {
        id: "payment.paymentMethodDetailsList.cardNumber",
        defaultMessage: "Card number",
    },
    currency: {
        id: "payment.paymentMethodDetailsList.currency",
        defaultMessage: "Currency",
    },
    country: {
        id: "payment.paymentMethodDetailsList.country",
        defaultMessage: "Issuing country",
    },
    kid: {
        id: "payment.paymentMethodDetailsList.kid",
        defaultMessage: "KID",
    },
    iban: {
        id: "payment.paymentMethodDetailsList.iban",
        defaultMessage: "IBAN",
    },
    bicSwift: {
        id: "payment.paymentMethodDetailsList.bicSwift",
        defaultMessage: "BIC / SWIFT",
    },
    holderName: {
        id: "payment.paymentMethodDetailsList.holderName",
        defaultMessage: "Holder name",
    },
    accountNumber: {
        id: "payment.paymentMethodDetailsList.accountNumber",
        defaultMessage: "Account number",
    },
    status: {
        id: "payment.paymentMethodDetailsList.status",
        defaultMessage: "Status",
    },
    bankName: {
        id: "payment.paymentMethodDetailsList.bankName",
        defaultMessage: "Bank name",
    },
    bankKey: {
        id: "payment.paymentMethodDetailsList.bankKey",
        defaultMessage: "Bank key",
    },
    sortCode: {
        id: "payment.paymentMethodDetailsList.sortCode",
        defaultMessage: "Sort code",
    },
    mandateReference: {
        id: "payment.paymentMethodDetailsList.mandateReference",
        defaultMessage: "Mandate reference",
    },
    mandateDate: {
        id: "payment.paymentMethodDetailsList.mandateDate",
        defaultMessage: "Mandate date",
    },
    mandateStatus: {
        id: "payment.paymentMethodDetailsList.mandateStatus",
        defaultMessage: "Mandate status",
    },
    score: {
        id: "payment.paymentMethodDetailsList.score",
        defaultMessage: "Score",
    },
})

const CardField = styled("span")(({ isExpired }: { isExpired: boolean }) => ({
    color: isExpired ? "var(--color-red)" : "inherit",
}))

export const buildMaskedCardNumber = (arr: string[]): string => {
    if (arr.length < 2) {
        return "-"
    }

    const firstPart = arr[0].substring(0, 4)
    const secondPart = arr[0].substring(4, 6)
    const lastPart = arr[1]

    return `${firstPart} ${secondPart}** **** ${lastPart}`
}

export const buildExpirationDate = ([month, year]: string[]): string => {
    const yearValue = year && year !== "None" ? +year % 100 : "-"
    return `${+month < 10 ? "0" + month : month}/${yearValue}`
}

const approvalColumn = <T extends GridValidRowModel & PaymentMethodDetails>(): GridColDef<T> => ({
    field: "approval",
    sortable: false,
    filterable: false,
    resizable: false,
    disableColumnMenu: true,
    minWidth: 130,
    renderHeader: () => <SafeFormattedMessage {...commonMessages.approval} />,
    renderCell: ({ row }: GridRenderCellParams<T, T>) => <PaymentMethodDetailsApproval row={row} />,
})

const actionsColumn = <T extends GridValidRowModel & PaymentMethodDetails>(): GridColDef<T> => ({
    field: "actions",
    sortable: false,
    filterable: false,
    resizable: false,
    disableColumnMenu: true,
    align: "center",
    width: 50,
    renderHeader: () => "",
    renderCell: ({ row }: GridRenderCellParams<T, T>) =>
        row.status !== PaymentMethodDetailsStatus.DELETED && <LineActions row={row} />,
})

const manualVerificationColumn = <T extends GridValidRowModel & PaymentMethodDetails>(): GridColDef<T> => ({
    field: "manualVerification",
    sortable: false,
    filterable: false,
    resizable: false,
    disableColumnMenu: true,
    width: 50,
    renderHeader: () => "",
    renderCell: ({ row }: GridRenderCellParams<T, T>) =>
        row?.id ? <ManualVerifications paymentMethodDetailsId={row.id} /> : null,
})

const scoreColumn = <T extends GridValidRowModel & PaymentMethodDetails>(): GridColDef<T> => ({
    field: "score",
    sortable: false,
    filterable: false,
    resizable: false,
    minWidth: 80,
    renderHeader: () => <SafeFormattedMessage {...messages.score} />,
    renderCell: ({ row }: GridRenderCellParams<T, T>) => (
        <LineScore paymentMethodDetailsId={row.id} currency={row.currency} />
    ),
})

const currencyColumn = <T extends { currency: CurrencyCodes | null }>(): GridColDef<T> => ({
    field: "currency",
    renderHeader: () => <SafeFormattedMessage {...messages.currency} />,
    renderCell: ({ value }: GridRenderCellParams<T>) => <span>{value ? value : "-"}</span>,
})

const countryColumn = <T extends { country: CountryCode | null }>(): GridColDef<T> => ({
    field: "country",
    minWidth: 160,
    renderHeader: () => <SafeFormattedMessage {...messages.country} />,
    renderCell: ({ value }: GridRenderCellParams<T>) =>
        value ? (
            <Stack direction="row" gap={1}>
                {getCountryFlag(value)} {Countries[value]}
            </Stack>
        ) : (
            "-"
        ),
})

const bankAccountNumberColumn = <T extends { bank_account_number: string }>(): GridColDef<T> => ({
    field: "bank_account_number",
    renderHeader: () => <SafeFormattedMessage {...messages.accountNumber} />,
    renderCell: ({ value }: GridRenderCellParams<T>) => <span>{value ? value : "-"}</span>,
})

const bankNameColumn = <T extends { bank_name: string }>(): GridColDef<T> => ({
    field: "bank_name",
    renderHeader: () => <SafeFormattedMessage {...messages.bankName} />,
    renderCell: ({ value }: GridRenderCellParams<T>) => <span>{value ? value : "-"}</span>,
})

const bankKeyColumn = <T extends { bank_key?: string }>(): GridColDef<T> => ({
    field: "bank_key",
    renderHeader: () => <SafeFormattedMessage {...messages.bankKey} />,
    renderCell: ({ value }: GridRenderCellParams<T>) => <span>{value ? value : "-"}</span>,
})

const sortCodeColumn = <T extends { sort_code?: string }>(): GridColDef<T> => ({
    field: "sort_code",
    renderHeader: () => <SafeFormattedMessage {...messages.sortCode} />,
    renderCell: ({ value }: GridRenderCellParams<T>) => <span>{value ? value : "-"}</span>,
})

const accountHolderNameColumn = <T extends { holder_name: string }>(): GridColDef<T> => ({
    field: "holder_name",
    renderHeader: () => <SafeFormattedMessage {...messages.holderName} />,
    renderCell: ({ value }: GridRenderCellParams<T>) => <span>{value ? value : "-"}</span>,
})

const statusColumn = <T extends { status: PaymentMethodDetailsStatus }>(): GridColDef<T> => ({
    field: "status",
    minWidth: 150,
    renderHeader: () => <SafeFormattedMessage {...messages.status} />,
    renderCell: ({ value }: GridRenderCellParams<T>) =>
        value ? <StatusChip status={(value as PaymentMethodDetailsStatus).toUpperCase()}>{value}</StatusChip> : "-",
})

const ibanColumn = <T extends { iban: string }>(): GridColDef<T> => ({
    field: "iban",
    minWidth: 250,
    flex: 1,
    renderHeader: () => <SafeFormattedMessage {...messages.iban} />,
    renderCell: ({ value }: GridRenderCellParams<T>) => <span>{value ? value : "-"}</span>,
})

const defaultColumn = <
    T extends { id: string; associated_objects?: PaymentMethodDetailsObject[] | undefined },
>(): GridColDef<T> => ({
    field: "default",
    width: 80,
    align: "center",
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    resizable: false,
    valueGetter: ({ row }: GridValueGetterParams<T>) => {
        const defaultLinkedObject = row?.associated_objects?.find(
            (object: PaymentMethodDetailsObject) => object?.payment_method_details_id === row.id && object.default
        )

        return defaultLinkedObject?.default
    },
    renderHeader: () => <SafeFormattedMessage {...commonMessages.default} />,
    renderCell: ({ value }: GridRenderCellParams<T>) => value && <CheckCircle color="var(--color-green)" size={20} />,
})

const kidColumn = <T extends { kid?: string | undefined }>(): GridColDef<T> => ({
    field: "kid",
    renderHeader: () => <SafeFormattedMessage {...messages.kid} />,
    renderCell: ({ value }: GridRenderCellParams<T>) => <span>{value ? value : "-"}</span>,
})

const getCardColumns = (hideApproval: boolean): GridColDef<CardPaymentMethod>[] => [
    ...(hideApproval ? [] : [approvalColumn<CardPaymentMethod>()]),
    statusColumn<CardPaymentMethod>(),
    {
        field: "card_nickname",
        minWidth: 180,
        flex: 1,
        renderHeader: () => <SafeFormattedMessage {...messages.name} />,
        renderCell: ({ value }: GridRenderCellParams<CardPaymentMethod>) => <span>{value || "-"}</span>,
    },
    {
        field: "card_number",
        minWidth: 180,
        flex: 1,
        valueGetter: (params: GridValueGetterParams<CardPaymentMethod>) => {
            if (!params?.row?.card_first_6_digits || !params?.row?.card_last_4_digits) {
                return []
            }
            return [params.row.card_first_6_digits, params.row.card_last_4_digits]
        },
        renderHeader: () => <SafeFormattedMessage {...messages.cardNumber} />,
        renderCell: ({ value }: GridRenderCellParams<CardPaymentMethod>) => (
            <span>{value?.length ? buildMaskedCardNumber(value) : "-"}</span>
        ),
    },
    {
        field: "card_expiration_date",
        minWidth: 70,
        valueGetter: (params: GridValueGetterParams<CardPaymentMethod>) => {
            if (!params?.row?.card_expiration_month || !params?.row?.card_expiration_year || !params?.row?.status) {
                return []
            }
            return [params.row.card_expiration_month, params.row.card_expiration_year, params.row.status]
        },
        renderHeader: () => <SafeFormattedMessage {...messages.expirationDate} />,
        renderCell: ({ value }: GridRenderCellParams<CardPaymentMethod>) => {
            if (!value || value.length < 3) return <span>-</span>
            const [month, year, status] = value as [string, string, PaymentMethodDetailsStatus]
            return (
                <CardField isExpired={status === PaymentMethodDetailsStatus.EXPIRED}>
                    {month && year ? buildExpirationDate([month, year]) : "-"}
                </CardField>
            )
        },
    },
    {
        field: "card_brand",
        flex: 1,
        renderHeader: () => <SafeFormattedMessage {...messages.emitter} />,
        renderCell: ({ value }: GridRenderCellParams<CardPaymentMethod>) => (
            <span>{value ? CardBrands[value] : "-"}</span>
        ),
    },
    scoreColumn<CardPaymentMethod>(),
    {
        field: "card_category",
        flex: 1,
        renderHeader: () => <SafeFormattedMessage {...messages.category} />,
        renderCell: ({ value }: GridRenderCellParams<CardPaymentMethod>) => (
            <span>{value && messages[value] ? <SafeFormattedMessage {...messages[value]} /> : "-"}</span>
        ),
    },
    currencyColumn<CardPaymentMethod>(),
    countryColumn<CardPaymentMethod>(),
    manualVerificationColumn<CardPaymentMethod>(),
    defaultColumn<CardPaymentMethod>(),
    actionsColumn<CardPaymentMethod>(),
]

const getBankTransferColumns = (hideApproval: boolean): GridColDef<BankTransferPaymentMethod>[] => [
    ...(hideApproval ? [] : [approvalColumn<BankTransferPaymentMethod>()]),
    statusColumn<BankTransferPaymentMethod>(),
    ibanColumn<BankTransferPaymentMethod>(),
    {
        field: "bic_swift",
        minWidth: 100,
        renderHeader: () => <SafeFormattedMessage {...messages.bicSwift} />,
        renderCell: ({ value }: GridRenderCellParams<BankTransferPaymentMethod>) => <span>{value ? value : "-"}</span>,
    },
    bankAccountNumberColumn<BankTransferPaymentMethod>(),
    scoreColumn<BankTransferPaymentMethod>(),
    bankNameColumn<BankTransferPaymentMethod>(),
    bankKeyColumn<BankTransferPaymentMethod>(),
    sortCodeColumn<BankTransferPaymentMethod>(),
    countryColumn<BankTransferPaymentMethod>(),
    kidColumn<BankTransferPaymentMethod>(),
    accountHolderNameColumn<BankTransferPaymentMethod>(),
    currencyColumn<BankTransferPaymentMethod>(),
    manualVerificationColumn<BankTransferPaymentMethod>(),
    defaultColumn<BankTransferPaymentMethod>(),
    actionsColumn<BankTransferPaymentMethod>(),
]

const getDirectDebitColumns = (hideApproval: boolean): GridColDef<DirectDebitPaymentMethod>[] => [
    ...(hideApproval ? [] : [approvalColumn<DirectDebitPaymentMethod>()]),
    bankAccountNumberColumn<DirectDebitPaymentMethod>(),
    {
        field: "mandate_reference",
        minWidth: 200,
        flex: 2,
        renderHeader: () => <SafeFormattedMessage {...messages.mandateReference} />,
    },
    {
        field: "mandate_date",
        minWidth: 120,
        flex: 1,
        renderHeader: () => <SafeFormattedMessage {...messages.mandateDate} />,
    },
    {
        field: "mandate_status",
        minWidth: 120,
        flex: 1,
        renderHeader: () => <SafeFormattedMessage {...messages.mandateStatus} />,
    },
    bankNameColumn<DirectDebitPaymentMethod>(),
    manualVerificationColumn<DirectDebitPaymentMethod>(),
    statusColumn<DirectDebitPaymentMethod>(),
    scoreColumn<DirectDebitPaymentMethod>(),
    currencyColumn<DirectDebitPaymentMethod>(),
    countryColumn<DirectDebitPaymentMethod>(),
    accountHolderNameColumn<DirectDebitPaymentMethod>(),
    manualVerificationColumn<DirectDebitPaymentMethod>(),
    defaultColumn<DirectDebitPaymentMethod>(),
    actionsColumn<DirectDebitPaymentMethod>(),
]

const getToApproveColumns = (hideApproval: boolean): GridColDef<PaymentMethodDetailsToApprove>[] => [
    ...(hideApproval ? [] : [approvalColumn<PaymentMethodDetailsToApprove>()]),
    bankAccountNumberColumn<PaymentMethodDetailsToApprove>(),
    ibanColumn<PaymentMethodDetailsToApprove>(),
    {
        field: "card_number",
        minWidth: 180,
        flex: 1,
        valueGetter: (params: GridValueGetterParams<PaymentMethodDetailsToApprove>) => {
            if (!params?.row?.card_first_6_digits || !params?.row?.card_last_4_digits) {
                return []
            }
            return [params.row.card_first_6_digits, params.row.card_last_4_digits]
        },
        renderHeader: () => <SafeFormattedMessage {...messages.cardNumber} />,
        renderCell: ({ value }: GridRenderCellParams<PaymentMethodDetailsToApprove>) => (
            <span>{value?.length ? buildMaskedCardNumber(value) : "-"}</span>
        ),
    },
    statusColumn<PaymentMethodDetailsToApprove>(),
    currencyColumn<PaymentMethodDetailsToApprove>(),
    actionsColumn<PaymentMethodDetailsToApprove>(),
]

export const getGridColumnsByActiveTab = (
    activeTab: PaymentMethodType,
    hideApproval: boolean
): GridColDef<PaymentMethodDetails>[] => {
    switch (activeTab) {
        case PaymentMethodType.CARD:
            return getCardColumns(hideApproval) as GridColDef<PaymentMethodDetails>[]
        case PaymentMethodType.BANK_TRANSFER:
            return getBankTransferColumns(hideApproval) as GridColDef<PaymentMethodDetails>[]
        case PaymentMethodType.DIRECT_DEBIT:
            return getDirectDebitColumns(hideApproval) as GridColDef<PaymentMethodDetails>[]
        case PaymentMethodType.TO_APPROVE:
            return getToApproveColumns(hideApproval) as GridColDef<PaymentMethodDetails>[]
        default:
            return []
    }
}
