import { useCallback, useEffect, useMemo, useState } from "react"

import { useGetBulkObjectsTags } from "~/domains/analytics/tags/hooks/useGetBulkObjectsTags"
import { selectSelectedTagsForFilter } from "~/domains/analytics/tags/store/tagsSlice"
import { SelectedTagI } from "~/domains/analytics/tags/types"
import { useGetPartnersQuery } from "~/domains/identity/partners/api/partnerApiV1"
import { bookOfRelationsActions, selectPartnersData } from "~/domains/identity/partners/store/bookOfRelationsSlice"
import { PartnersDataI } from "~/domains/identity/partners/types"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { OrganizationId, OrganizationMemberUserI } from "~/types"
import { WHITE_SPACE_REGEXP } from "~/utils/string"

type FetchPartnersResult = {
    partnersData: PartnersDataI[]
    filteredPartners?: PartnersDataI[]
    fetchPartners: () => void
    loading: boolean
    error: string | null
    skip?: boolean
}

const noFilter = () => true

const getPartnersFilter = (
    filter: string,
    selectedTagsForFilter: SelectedTagI[],
    members: OrganizationMemberUserI[]
) => {
    if ((!filter || !filter.length) && !selectedTagsForFilter.length) {
        return noFilter
    }
    const filterWords = filter.toLocaleLowerCase().split(WHITE_SPACE_REGEXP)

    return (partnersData: PartnersDataI) => {
        const organizationNameWords = partnersData.organizationName
            ? partnersData.organizationName.toLocaleLowerCase().split(WHITE_SPACE_REGEXP)
            : []
        const brandNameWords = partnersData.brandName
            ? partnersData.brandName.toLocaleLowerCase().split(WHITE_SPACE_REGEXP)
            : []
        const x3InternalIdWords =
            partnersData.supplier?.x3InternalId?.toLocaleLowerCase().split(WHITE_SPACE_REGEXP) || []
        const ifsInternalIdWords =
            partnersData.supplier?.ifsInternalId?.toLocaleLowerCase().split(WHITE_SPACE_REGEXP) || []
        const registrationNumberWords =
            partnersData.registration.preferredRegistrationNumber.registrationNumber
                ?.toLocaleLowerCase()
                .split(WHITE_SPACE_REGEXP) || []

        // filter by user full name of the requester
        const requesterId = partnersData.supplier?.requesterUserId || partnersData.buyer?.requesterUserId || ""
        const requesterWords = !requesterId
            ? []
            : [members.find((member) => member.userId === requesterId)?.user.fullName?.toLocaleLowerCase()]

        // filter by user full name of the responsible person
        const responsiblePersonId = partnersData.responsibleUserId || ""
        const responsiblePersonWords = !responsiblePersonId
            ? []
            : [members.find((member) => member.userId === responsiblePersonId)?.user.fullName?.toLocaleLowerCase()]

        const searchArray = [
            ...organizationNameWords,
            ...brandNameWords,
            ...(x3InternalIdWords || []),
            ...(ifsInternalIdWords || []),
            ...(registrationNumberWords || []),
            ...(requesterWords || []),
            ...(responsiblePersonWords || []),
        ]

        const hasSelectedTags = selectedTagsForFilter.length
            ? selectedTagsForFilter.every((selectedTag) =>
                  partnersData.tags?.some((tag) => tag.tagId === selectedTag.tagId)
              )
            : true
        const matchesSearchWords = filterWords.every((word) =>
            searchArray.some((partnersWord) => partnersWord && partnersWord.indexOf(word) >= 0)
        )

        return hasSelectedTags && matchesSearchWords
    }
}

export const useFetchPartnersData = (
    organizationId: OrganizationId | undefined,
    withMetrics: boolean = false,
    withDetails: boolean = false,
    members: OrganizationMemberUserI[] | undefined = [],
    skip?: boolean
): FetchPartnersResult => {
    const dispatch = useAppDispatch()

    const [partnersData, setPartnersData] = useState<PartnersDataI[]>([])
    const { partnersFilter, loading, error } = useAppSelector(selectPartnersData)
    const selectedTagsForFilter = useAppSelector(selectSelectedTagsForFilter)
    const {
        data,
        isLoading: loadingPartners,
        refetch: fetchPartners,
    } = useGetPartnersQuery(
        {
            organizationId: organizationId || "",
            withMetrics,
            withDetails,
        },
        { refetchOnMountOrArgChange: true, skip: !organizationId || skip }
    )

    const filteredPartners = useMemo(() => {
        const hasFilters = partnersFilter || selectedTagsForFilter.length
        if (!hasFilters) return null

        return partnersData?.filter(getPartnersFilter(partnersFilter, selectedTagsForFilter, members)) ?? []
    }, [partnersData, partnersFilter, selectedTagsForFilter, members])

    const { getBulkObjectsTags } = useGetBulkObjectsTags(organizationId)

    const fetchPartnersTags = useCallback(async () => {
        if (!data?.length) return

        dispatch(bookOfRelationsActions.setTagsLoading(true))
        const partnersObjectsTags = await getBulkObjectsTags(
            data.map((partnerData) => partnerData.organizationId),
            true
        )

        const partnersDataWithTags = data.map((partnerData) => ({
            ...partnerData,
            tags: partnersObjectsTags?.[partnerData.organizationId],
        }))

        setPartnersData(partnersDataWithTags)
        dispatch(bookOfRelationsActions.setTags(partnersObjectsTags))
        dispatch(bookOfRelationsActions.setTagsLoading(false))
    }, [getBulkObjectsTags, data])

    useEffect(() => {
        setPartnersData(data ?? [])
    }, [data])

    useEffect(() => {
        data && fetchPartnersTags()
    }, [data, fetchPartnersTags])

    return useMemo(() => {
        if (filteredPartners) {
            return {
                partnersData,
                filteredPartners,
                fetchPartners,
                loading: loadingPartners || loading,
                error,
            }
        }
        return { partnersData, fetchPartners, loading: loadingPartners || loading, error }
    }, [partnersData, filteredPartners, fetchPartners, loading, error, loadingPartners])
}
