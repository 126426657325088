import * as t from "io-ts"
import { MessageDescriptor, defineMessages } from "react-intl"

import { TagObjectI } from "~/domains/analytics/tags/types/TagObject"
import {
    CreatePartnerProfileAddressIO,
    CreatePartnerProfileContactIO,
    CreatePartnerProfileIO,
    CreatePartnerProfilePaymentDetailIO,
    OneSideDataIO,
    PartnerDataRegistrationIO,
    PartnerOrganizationIO,
    PartnerProfileAddressIO,
    PartnerProfileContactIO,
    PartnerProfileIO,
    PartnerProfilePaymentDetailIO,
    PartnersDataIO,
    PartnershipIO,
} from "~/domains/identity/partners/api/partnerDto"
import { CountryCode, OrganizationId, UserId } from "~/types"

export enum SourceType {
    ERP = "ERP",
    MANUAL = "MANUAL",
    INVOICE = "INVOICE",
    PURCHASE_ORDER = "PURCHASE_ORDER",
    PURCHASE_REQUEST = "PURCHASE_REQUEST",
    NONE = "NONE",
}

export enum CollaborationStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    REFUSED = "REFUSED",
    IN_REVIEW = "IN_REVIEW",
    CLOTURED = "CLOTURED",
    EXCLUDED = "EXCLUDED",
    DRAFT = "DRAFT",
}

export enum CollaborationStatusEiffage {
    TO_QUALIFY = "TO_QUALIFY", // Eiffage custom status
    AUTHORIZED = "AUTHORIZED", // Eiffage custom status
    REFERENCED = "REFERENCED", // Eiffage custom status
    STRATEGIC = "STRATEGIC", // Eiffage custom status
    TO_MONITOR = "TO_MONITOR", // Eiffage custom status
    SUSPENDED = "SUSPENDED", // Eiffage custom status
}

export enum CollaborationStatusSeche {
    INITIATED = "INITIATED", // Seche custom status
    REFERENCED = "REFERENCED", // Seche custom status
    BLOCKED = "BLOCKED", // Seche custom status
    INACTIVE = "INACTIVE", // Seche custom status
}

export type CollaborationStatusAll = CollaborationStatus | CollaborationStatusSeche | CollaborationStatusEiffage

export const CollaborationStatusOptionsDefault = [...Object.keys(CollaborationStatus)]
export const CollaborationStatusOptionsEiffage = [...Object.keys(CollaborationStatusEiffage)]
export const CollaborationStatusOptionsSeche = [...Object.keys(CollaborationStatusSeche)]

export const partnershipCollaborationStatusMessages: Record<CollaborationStatus, MessageDescriptor> = defineMessages({
    [CollaborationStatus.ACTIVE]: {
        id: "partners.bookofrelations.createPartnershipCollaborationStatus.active",
        defaultMessage: "Active",
    },
    [CollaborationStatus.INACTIVE]: {
        id: "partners.bookofrelations.createPartnershipCollaborationStatus.inactive",
        defaultMessage: "Inactive",
    },
    [CollaborationStatus.REFUSED]: {
        id: "partners.bookofrelations.createPartnershipCollaborationStatus.refused",
        defaultMessage: "Refused",
    },
    [CollaborationStatus.IN_REVIEW]: {
        id: "partners.bookofrelations.createPartnershipCollaborationStatus.inReview",
        defaultMessage: "In review",
    },
    [CollaborationStatus.CLOTURED]: {
        id: "partners.bookofrelations.createPartnershipCollaborationStatus.clotured",
        defaultMessage: "Clotured",
    },
    [CollaborationStatus.EXCLUDED]: {
        id: "partners.bookofrelations.createPartnershipCollaborationStatus.excluded",
        defaultMessage: "Excluded",
    },
    [CollaborationStatus.DRAFT]: {
        id: "partners.bookofrelations.createPartnershipCollaborationStatus.draft",
        defaultMessage: "Draft",
    },
    [CollaborationStatusEiffage.TO_QUALIFY]: {
        id: "partners.bookofrelations.createPartnershipCollaborationStatus.toQualify",
        defaultMessage: "To qualify",
    },
    [CollaborationStatusEiffage.AUTHORIZED]: {
        id: "partners.bookofrelations.createPartnershipCollaborationStatus.authorized",
        defaultMessage: "Authorized",
    },
    [CollaborationStatusEiffage.REFERENCED]: {
        id: "partners.bookofrelations.createPartnershipCollaborationStatus.referenced",
        defaultMessage: "Referenced",
    },
    [CollaborationStatusEiffage.STRATEGIC]: {
        id: "partners.bookofrelations.createPartnershipCollaborationStatus.strategic",
        defaultMessage: "Strategic",
    },
    [CollaborationStatusEiffage.TO_MONITOR]: {
        id: "partners.bookofrelations.createPartnershipCollaborationStatus.toMonitor",
        defaultMessage: "To monitor",
    },
    [CollaborationStatusEiffage.SUSPENDED]: {
        id: "partners.bookofrelations.createPartnershipCollaborationStatus.suspended",
        defaultMessage: "Suspended",
    },
    [CollaborationStatusSeche.BLOCKED]: {
        id: "partners.bookofrelations.createPartnershipCollaborationStatus.blocked",
        defaultMessage: "Blocked",
    },
    [CollaborationStatusSeche.INITIATED]: {
        id: "partners.bookofrelations.createPartnershipCollaborationStatus.initiated",
        defaultMessage: "Initiated",
    },
})

export const enum BookOfRelationsTab {
    ALL = "ALL",
    TO_VALIDATE = "TO_VALIDATE",
}

export const bookOfRelationsTabMessages: Record<BookOfRelationsTab, MessageDescriptor> = defineMessages({
    [BookOfRelationsTab.ALL]: { id: "partners.bookofrelations.tabs.all", defaultMessage: "All" },
    [BookOfRelationsTab.TO_VALIDATE]: { id: "partners.bookofrelations.tabs.toValidate", defaultMessage: "To validate" },
})

export const bookOfRelationsTabs: BookOfRelationsTab[] = [BookOfRelationsTab.ALL, BookOfRelationsTab.TO_VALIDATE]

export type PartnerDataRegistrationI = t.TypeOf<typeof PartnerDataRegistrationIO> & {
    countryCode: CountryCode
}

const PartnerAddressIO = t.type({
    city: t.string,
})
export type PartnerAddressI = t.TypeOf<typeof PartnerAddressIO>

export interface PartnerIdAndName {
    partnerId?: string
    partnerName?: string
}

export type OneSideDataI = t.TypeOf<typeof OneSideDataIO>
export type PartnersDataI = t.TypeOf<typeof PartnersDataIO> & {
    registration: PartnerDataRegistrationI
    tags?: TagObjectI[]
}
export type PartnerOrganizationI = t.TypeOf<typeof PartnerOrganizationIO> & {
    registration: PartnerDataRegistrationI
    collaborationStatus?: CollaborationStatusAll
}

export enum PartnershipType {
    SUPPLIER = "SUPPLIER",
    BUYER = "BUYER",
}

export enum PartnershipTypeOption {
    SUPPLIER = "SUPPLIER",
    BUYER = "BUYER",
    BOTH = "BOTH",
}

export type PartnershipI = t.TypeOf<typeof PartnershipIO> & {
    partnershipType: PartnershipType
    collaborationStatus: CollaborationStatus
    source: SourceType
    allowToPayInvoice: boolean | null
    allowToSendPO: boolean | null
}

export interface CreatePartnershipPayload {
    partnerId: string
    partnershipType: PartnershipTypeOption
    preferredStatus?: boolean
    verifiedStatus?: boolean
    collaborationStatus?: CollaborationStatus
    allowToPayInvoice?: boolean | null
    allowToSendPO?: boolean | null
}

export interface CreatePartnershipPayloadContact {
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    position: string
    language: string
    timezone: string
}

export interface CreatePartnershipPayloadWithContact {
    partnership: CreatePartnershipPayload
    contact?: CreatePartnershipPayloadContact
}

export interface UpdatePartnershipPayload {
    partnershipType: PartnershipType
    preferredStatus?: boolean
    verifiedStatus?: boolean
    collaborationStatus?: CollaborationStatus
    allowToSendPO?: boolean
    allowToPayInvoice?: boolean
}

export interface UpdatePartnershipByIdPayload {
    partnershipId: string
    preferredStatus?: boolean
    verifiedStatus?: boolean
    collaborationStatus?: CollaborationStatus
    allowToSendPO?: boolean
    allowToPayInvoice?: boolean
}

export interface BatchUpdatePartnershipPayload {
    partnershipId: string
    preferredStatus?: boolean
    verifiedStatus?: boolean
    collaborationStatus?: CollaborationStatus
    allowToSendPO?: boolean
    allowToPayInvoice?: boolean
}
export enum PartnerAttributes {
    PREFERRED = "preferredStatus",
    VERIFIED = "verifiedStatus",
    ALLOW_TO_SEND_PO = "allowToSendPO",
    ALLOW_TO_PAY_INVOICE = "allowToPayInvoice",
}

export enum StatusKey {
    VERIFIED = "VERIFIED",
    PREFERRED = "PREFERRED",
    ALLOW_TO_SEND_PO = "ALLOW_TO_SEND_PO",
    ALLOW_TO_PAY_INVOICE = "ALLOW_TO_PAY_INVOICE",
}
export enum PartnerNavItem {
    INVOICES = "invoices",
    INVOICES_V1 = "invoices-v1",
    PURCHASE_ORDERS = "purchaseOrders",
    PURCHASE_REQUESTS = "purchaseRequests",
    KYB = "kyb",
    ADDRESSES = "addresses",
    SHARE_CATALOGS = "shareCatalogs",
    CONTACTS = "contacts",
    COMPANY_DETAILS = "companyDetails",
    PAYMENT_DETAILS = "paymentDetails",
    SENT_FORMS = "questionnaires",
    METRICS = "metrics",
    OPERATIONAL_PERFORMANCE = "operationalPerformance",
    COMPANY_OFFICES = "companyOffices",
}

export interface PartnersReadDto {
    organizationId: OrganizationId
    withMetrics?: boolean
    withDetails?: boolean
}

export type PartialPartnerProfileContact = {
    contactName: string
    email: string
}

export type OptionParams = React.HTMLAttributes<HTMLLIElement> & {
    key: string
}

export type CreatePartnerProfileContactI = t.TypeOf<typeof CreatePartnerProfileContactIO>

export type PartnerProfileContactI = t.TypeOf<typeof PartnerProfileContactIO>

export type CreatePartnerProfileAddressI = t.TypeOf<typeof CreatePartnerProfileAddressIO>

export type PartnerProfileAddressI = t.TypeOf<typeof PartnerProfileAddressIO>

export type CreatePartnerProfilePaymentDetailI = t.TypeOf<typeof CreatePartnerProfilePaymentDetailIO>

export type PartnerProfilePaymentDetailI = t.TypeOf<typeof PartnerProfilePaymentDetailIO> & {
    source: SourceType
}

export type CreatePartnerProfileI = t.TypeOf<typeof CreatePartnerProfileIO>

export type PartnerProfileI = t.TypeOf<typeof PartnerProfileIO> & {
    responsibleUserId?: UserId
}

export type PartialPartnerProfile = Partial<PartnerProfileI> & {
    initiatorId: string
    partnerId: string
}

export type ProfileDetailsType = "contacts" | "paymentDetails" | "addresses"

export const OCR_ID = "contactOCRData"
export const CREATION_ID = "contactCreationData"

export enum PartnerContactGroup {
    "CREATION" = "CREATION",
    "PARTNER" = "PARTNER",
}

export const partnerSourcesMessages: Record<SourceType, MessageDescriptor> = defineMessages({
    [SourceType.ERP]: { id: "partners.bookofrelations.sources.ERP", defaultMessage: "ERP" },
    [SourceType.INVOICE]: { id: "partners.bookofrelations.sources.INVOICE", defaultMessage: "Invoice" },
    [SourceType.PURCHASE_REQUEST]: {
        id: "partners.bookofrelations.sources.PURCHASE_REQUEST",
        defaultMessage: "Purchase request",
    },
    [SourceType.PURCHASE_ORDER]: {
        id: "partners.bookofrelations.sources.PURCHASE_ORDER",
        defaultMessage: "Purchase order",
    },
    [SourceType.MANUAL]: { id: "partners.bookofrelations.sources.MANUAL", defaultMessage: "Manual" },
    [SourceType.NONE]: { id: "partners.bookofrelations.sources.NONE", defaultMessage: "-" },
})
