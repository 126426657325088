import { Grid, Stack } from "@mui/material"
import { CreditCard } from "react-feather"
import { useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { HeaderH1, NoPermission } from "~/components"
import { useHasPaymentPermissions } from "~/domains/payment/hooks"
import { PaymentsHeader } from "~/domains/payment/payment-batches/components"
import { PaymentsTabsI } from "~/domains/payment/payment-batches/types"
import { PaymentsList } from "~/domains/payment/payment/components"

export const PaymentsPage = () => {
    const { formatMessage } = useIntl()
    const { permissions } = useHasPaymentPermissions({ authorizations: ["read"] })
    const hasReadPermission = Boolean(permissions?.read?.hasPermission)

    return (
        <Stack gap={2}>
            <HeaderH1 title={formatMessage(commonMessages.cashflow)} icon={<CreditCard />} />
            <Grid className="main-box">
                <PaymentsHeader currentPage={PaymentsTabsI.PAYMENTS} />
                {hasReadPermission ? (
                    <Stack gap={2}>
                        <PaymentsList />
                    </Stack>
                ) : (
                    <NoPermission />
                )}
            </Grid>
        </Stack>
    )
}
