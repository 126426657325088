import { BulkActionButton } from "~/components"
import { userApi } from "~/domains/identity/account/api/userApi"
import { organizationApi } from "~/domains/identity/organization/api/organisationApi"
import {
    isStatusApprovable,
    isStatusCurrent,
    isStatusCurrentOrCompleted,
    isStatusNotCurrent,
    isStatusReceivable,
    isStatusSendable,
} from "~/domains/transactions/purchase-orders/core/lifecyclePurchaseOrder"
import {
    CreatePurchaseOrderDTO,
    CreatePurchaseOrderDTOV2,
    ListPurchaseOrders,
    ListPurchaseOrdersResponse,
    PurchaseOrderFulfillmentPresentation,
    PurchaseOrderLine,
    PurchaseOrderStatusCreate,
    PurchaseOrderStatusPresentation,
    PurchaseOrders,
    PurchaseOrdersResponseV2,
    PurchaseOrdersTab,
    UpdatePurchaseOrderDTOV2,
} from "~/domains/transactions/purchase-orders/types"
import { CreateLineDTOV2, CreatePOLineDTO } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { CurrencyCodes, OrganizationI, UserI, ViewTypeI } from "~/types"
import { SharedObjectType, SharedObjectWithName } from "~/types/SharedObjects"
import { fetchEntitiesInChunks } from "~/utils/fetchEntitiesInChunks"
import { isDefined } from "~/utils/isDefined"

export type POStatusCounts = Partial<
    Record<"PENDING" | PurchaseOrderFulfillmentPresentation | PurchaseOrderStatusPresentation, number>
>

export const countPurchaseOrderByStatus = (purchaseOrders: PurchaseOrders[]) =>
    purchaseOrders.reduce((count, { statusPresentation, fulfillmentPresentation }) => {
        count[fulfillmentPresentation] = (count[fulfillmentPresentation] ?? 0) + 1
        count[statusPresentation] = (count[statusPresentation] ?? 0) + 1

        if (statusPresentation === "OPEN" && !fulfillmentPresentation) {
            count["PENDING"] = (count["PENDING"] ?? 0) + 1
        }

        return count
    }, {} as POStatusCounts)

export const getPOsByViewAndTab = (tab: PurchaseOrdersTab, purchaseOrders: ListPurchaseOrders) => {
    switch (tab) {
        case PurchaseOrdersTab.ALL:
            return purchaseOrders.filter((po) => isStatusNotCurrent(po.statusPresentation, "CLOSED"))
        case PurchaseOrdersTab.OPEN:
            return purchaseOrders.filter((po) => isStatusCurrent(po.statusPresentation, "OPEN"))
        case PurchaseOrdersTab.DRAFT:
            return purchaseOrders.filter((po) => isStatusCurrent(po.statusPresentation, "DRAFT"))
        case PurchaseOrdersTab.SUBMITTED:
            return purchaseOrders.filter((po) => isStatusCurrent(po.statusPresentation, "SUBMITTED"))
        case PurchaseOrdersTab.INTERNALLY_APPROVED:
            return purchaseOrders.filter((po) => isStatusCurrent(po.statusPresentation, "INTERNALLY_APPROVED"))
        case PurchaseOrdersTab.PENDING:
            return purchaseOrders.filter(
                (po) =>
                    isStatusCurrent(po.fulfillmentPresentation, "PENDING") ||
                    (!po.fulfillmentPresentation && isStatusCurrent(po.statusPresentation, "OPEN"))
            )
        case PurchaseOrdersTab.IN_PREPARATION:
            return purchaseOrders.filter((po) => isStatusCurrent(po.fulfillmentPresentation, "IN_PREPARATION"))
        case PurchaseOrdersTab.SHIPPED:
            return purchaseOrders.filter((po) => isStatusCurrent(po.fulfillmentPresentation, "SHIPPED"))
        case PurchaseOrdersTab.PARTIALLY_RECEIVED:
            return purchaseOrders.filter((po) => isStatusCurrent(po.fulfillmentPresentation, "PARTIALLY_RECEIVED"))
        case PurchaseOrdersTab.RECEIVED:
            return purchaseOrders.filter((po) => isStatusCurrent(po.fulfillmentPresentation, "RECEIVED"))
        case PurchaseOrdersTab.CLOSED:
            return purchaseOrders.filter((po) => isStatusCurrent(po.statusPresentation, "CLOSED"))
        default:
            return purchaseOrders
    }
}

export const filterResults = (pos: ListPurchaseOrders, searchTerm: string, side: ViewTypeI): ListPurchaseOrders => {
    return pos.filter((po) =>
        side === ViewTypeI.buyer
            ? po.buyerName?.toLowerCase().includes(searchTerm)
            : po.supplierName?.toLowerCase().includes(searchTerm)
    )
}

export const allLinesApproved = (lines: PurchaseOrderLine[], viewType: ViewTypeI) => {
    return lines.every(
        (line) =>
            line[viewType === ViewTypeI.supplier ? "supplierApprovalStatus" : "buyerApprovalStatus"] === "APPROVED"
    )
}

export const allLinesRejected = (lines: PurchaseOrderLine[], viewType: ViewTypeI) => {
    return lines.every(
        (line) =>
            line[viewType === ViewTypeI.supplier ? "supplierApprovalStatus" : "buyerApprovalStatus"] === "REJECTED"
    )
}

interface GetPOBulkActionButtonsProps {
    currentTab: PurchaseOrdersTab
    view: ViewTypeI | string
    handleValidate: (currentTab: PurchaseOrdersTab) => BulkActionButton
    handleSend: (currentTab: PurchaseOrdersTab) => BulkActionButton
    handleReceived: (currentTab: PurchaseOrdersTab) => BulkActionButton
    handleAddTags: (currentTab: PurchaseOrdersTab) => BulkActionButton
    handleClose: (currentTab: PurchaseOrdersTab) => BulkActionButton
    handleDelete: (currentTab: PurchaseOrdersTab) => BulkActionButton
}
export const getPOBulkActionButtons = ({
    currentTab,
    view,
    handleValidate,
    handleSend,
    handleReceived,
    handleAddTags,
    handleClose,
    handleDelete,
}: GetPOBulkActionButtonsProps): BulkActionButton[] => {
    const isSupplierView = view === ViewTypeI.supplier
    const addTagsAction = handleAddTags(currentTab)
    const validateAction = handleValidate(currentTab)
    const sendAction = handleSend(currentTab)
    const receivedAction = handleReceived(currentTab)
    const closeAction = handleClose(currentTab)
    const deleteAction = handleDelete(currentTab)

    switch (currentTab) {
        case PurchaseOrdersTab.ALL:
            return [addTagsAction, validateAction, sendAction, receivedAction, closeAction, deleteAction].filter(
                isDefined
            )
        case PurchaseOrdersTab.DRAFT:
            return [addTagsAction, deleteAction].filter(isDefined)
        case PurchaseOrdersTab.SUBMITTED:
            return [addTagsAction, validateAction, deleteAction].filter(isDefined)
        case PurchaseOrdersTab.INTERNALLY_APPROVED:
            return [addTagsAction, sendAction, deleteAction].filter(isDefined)
        case PurchaseOrdersTab.OPEN:
            return [addTagsAction, receivedAction, closeAction].filter(isDefined)
        case PurchaseOrdersTab.PENDING:
            return [addTagsAction, receivedAction, closeAction].filter(isDefined)
        case PurchaseOrdersTab.IN_PREPARATION:
        case PurchaseOrdersTab.SHIPPED:
        case PurchaseOrdersTab.PARTIALLY_RECEIVED:
            return isSupplierView ? [addTagsAction] : [addTagsAction, receivedAction, closeAction].filter(isDefined)
        case PurchaseOrdersTab.RECEIVED:
            return isSupplierView ? [addTagsAction] : [addTagsAction, closeAction].filter(isDefined)
        default:
            return [addTagsAction]
    }
}

export const getApprovable = (
    purchaseOrderIds: string[],
    purchaseOrders: ListPurchaseOrders,
    view: ViewTypeI,
    checkForPermissions: boolean = false
): ListPurchaseOrders => {
    return purchaseOrderIds.reduce((acc, id) => {
        const po = purchaseOrders.find((purchaseOrder) => purchaseOrder.id === id)
        if (
            po &&
            (!checkForPermissions || po.permissions.approve) &&
            (isStatusApprovable(po.statusPresentation, view) || isStatusApprovable(po.fulfillmentPresentation, view)) &&
            !allLinesApproved(po.lines, view)
        ) {
            acc.push(po)
        }
        return acc
    }, [] as ListPurchaseOrders)
}

export const getSendable = (
    purchaseOrderIds: string[],
    purchaseOrders: ListPurchaseOrders,
    view: ViewTypeI,
    organizationSharings: SharedObjectWithName[],
    checkForPermissions: boolean = false
): string[] => {
    const sharedPOs = organizationSharings.filter(
        (share) => purchaseOrderIds.includes(share.objectId) && share.objectType === SharedObjectType.PurchaseOrder
    )
    return purchaseOrderIds.reduce((acc, id) => {
        const po = purchaseOrders.find((purchaseOrder) => purchaseOrder.id === id)
        const sharedPO = sharedPOs.find((share) => share.objectId === po?.id)
        if (
            po &&
            (!checkForPermissions || po.permissions.send) &&
            sharedPO &&
            isStatusSendable(po.statusPresentation, view) &&
            allLinesApproved(po.lines, view)
        ) {
            acc.push(po.id)
        }
        return acc
    }, [] as string[])
}

export const getReceivable = (
    purchaseOrderIds: string[],
    purchaseOrders: ListPurchaseOrders,
    checkForPermissions: boolean = false
): ListPurchaseOrders => {
    return purchaseOrderIds.reduce((acc, id) => {
        const po = purchaseOrders.find((purchaseOrder) => purchaseOrder.id === id)
        if (
            po &&
            (!checkForPermissions || po.permissions.mark_line_delivered_or_received) &&
            isStatusReceivable(po.fulfillmentPresentation)
        ) {
            acc.push(po)
        }
        return acc
    }, [] as ListPurchaseOrders)
}

export const getClosable = (
    purchaseOrderIds: string[],
    purchaseOrders: ListPurchaseOrders,
    checkForPermissions: boolean = false
): ListPurchaseOrders => {
    return purchaseOrderIds.reduce((acc, id) => {
        const po = purchaseOrders.find((purchaseOrder) => purchaseOrder.id === id)
        if (
            po &&
            (!checkForPermissions || po.permissions.close) &&
            isStatusCurrentOrCompleted(po.statusPresentation, "OPEN")
        ) {
            acc.push(po)
        }
        return acc
    }, [] as ListPurchaseOrders)
}

export const getDeletable = (
    purchaseOrderIds: string[],
    purchaseOrders: ListPurchaseOrders,
    checkForPermissions: boolean = false
): ListPurchaseOrders => {
    return purchaseOrderIds.reduce((acc, id) => {
        const po = purchaseOrders.find((purchaseOrder) => purchaseOrder.id === id)
        if (
            po &&
            (!checkForPermissions || po.permissions.delete) &&
            (isStatusCurrent(po.statusPresentation, "DRAFT") ||
                isStatusCurrent(po.statusPresentation, "SUBMITTED") ||
                isStatusCurrent(po.statusPresentation, "INTERNALLY_APPROVED"))
        ) {
            acc.push(po)
        }
        return acc
    }, [] as ListPurchaseOrders)
}

export const getPurchaseOrderId = (
    r: PromiseSettledResult<unknown>,
    index: number,
    purchaseOrderIds: string[]
): string | undefined => (r.status === "rejected" ? undefined : purchaseOrderIds[index])

export const showBulkActionButtonCount: Partial<Record<PurchaseOrdersTab, boolean>> = {
    [PurchaseOrdersTab.ALL]: true,
    [PurchaseOrdersTab.SUBMITTED]: true,
}

export const convertLineToCreatePOLineDTO = (line: PurchaseOrderLine): CreatePOLineDTO => {
    return {
        description: line.description,
        quantity: line.quantity,
        units: line.units ?? "",
        unitPrice: line.unitPrice,
        unitPriceExcludingTax: line.unitPriceExcludingTax,
        taxRate: line.taxRate,
        totalAmount: line.totalAmount,
        totalAmountExcludingTax: line.totalAmountExcludingTax,
        ...(line.supplierItemId ? { supplierItemId: line.supplierItemId } : { buyerItemId: line.buyerItemId }),
        totalTax: line.totalTax,
        discount: line.discount,
    }
}

export const convertPOtoCreatePODTO = (
    PO: PurchaseOrders,
    status: PurchaseOrderStatusCreate = "DRAFT"
): CreatePurchaseOrderDTO => {
    return {
        supplierId: PO.supplierId,
        buyerId: PO.buyerId,
        status,
        expectedDeliveryDate: PO.expectedDeliveryDate,
        requesterUserId: PO.requesterUserId ?? undefined,
        billingAddress: PO.billingAddress ?? undefined,
        shippingAddress: PO.shippingAddress ?? undefined,
        description: PO.description,
        currency: PO.currency,
        lines: PO.lines.map((line) => {
            return convertLineToCreatePOLineDTO(line)
        }),
    }
}

export const convertPOV2toPO = (purchaseOrder: PurchaseOrdersResponseV2): PurchaseOrders => {
    const {
        buyerId,
        creationDate,
        expectedDeliveryDate,
        id,
        requesterUserId,
        requesterOrganizationId,
        shippingAddress,
        shortId,
        status,
        statusPresentation,
        fulfillmentPresentation,
        supplierId,
        purchaseRequest,
        description,
        totals: purchaseOrderTotals,
        lines: purchaseOrderLines,
        lineCount,
        permissions,
        tagIds,
        invoiceNumber,
    } = purchaseOrder

    const currencyKey = Object.keys(purchaseOrderTotals)[0]

    const lines = purchaseOrderLines.map((line) => {
        const {
            id: lineId,
            description: lineDescription,
            quantity,
            originalQuantity,
            units,
            taxRate,
            totals: lineTotals,
            unitPrice,
            unitPriceExcludingTax,
            discount,
            deliveryNote = "",
            temporaryId = "",
            hasChanged = false,
            buyerFulfillment,
            supplierFulfillment,
            supplierItemId,
            buyerItemId,
            buyerApprovalStatus,
            supplierApprovalStatus,
        } = line

        return {
            id: lineId,
            description: lineDescription,
            quantity,
            originalQuantity: originalQuantity ?? quantity,
            taxRate,
            units: units ?? "",
            totalAmount: lineTotals ? +lineTotals.amount.amount : 0,
            totalAmountExcludingTax: lineTotals ? +lineTotals.amountExcludingTax.amount : 0,
            totalTax: lineTotals ? +lineTotals.tax.amount : 0,
            unitPrice: unitPrice ? +unitPrice.amount : 0,
            unitPriceExcludingTax: unitPriceExcludingTax ? +unitPriceExcludingTax.amount : 0,
            discount,
            deliveryNote,
            temporaryId,
            hasChanged,
            buyerFulfillment,
            supplierFulfillment,
            supplierItemId,
            buyerItemId,
            buyerApprovalStatus,
            supplierApprovalStatus,
            permissions,
        }
    })

    return {
        buyerId,
        creationDate,
        currency: currencyKey as CurrencyCodes,
        expectedDeliveryDate,
        id,
        requesterUserId,
        requesterOrganizationId,
        shippingAddress,
        shortId,
        status,
        statusPresentation,
        fulfillmentPresentation,
        supplierId,
        totalAmount: purchaseOrderTotals ? +purchaseOrderTotals[currencyKey].amount?.amount : 0,
        totalAmountExcludingTax: purchaseOrderTotals ? +purchaseOrderTotals[currencyKey].amountExcludingTax?.amount : 0,
        totalTax: purchaseOrderTotals ? +purchaseOrderTotals[currencyKey].tax?.amount : 0,
        purchaseRequest,
        description,
        lines,
        lineCount: lineCount ?? lines.length,
        supplierName: "",
        buyerName: "",
        permissions,
        tagIds,
        invoiceNumber,
    }
}

const convertLines = (lines: CreatePOLineDTO[] | PurchaseOrderLine[], currency: CurrencyCodes): CreateLineDTOV2[] => {
    return lines.map((line: CreatePOLineDTO | PurchaseOrderLine) => {
        const {
            description,
            quantity,
            units,
            taxRate,
            unitPrice,
            unitPriceExcludingTax,
            discount,
            id,
            buyerItemId,
            supplierItemId,
        } = line

        const discountType = discount?.type ?? "FLAT"
        const discountAmount =
            discountType === "PERCENTAGE"
                ? Number(((+discount?.amount || 0) / 100).toFixed(2))
                : Number((+discount?.amount || 0).toFixed(2))

        return {
            description,
            quantity,
            units: units ?? "",
            taxRate: Number(taxRate).toFixed(2),
            unitPrice: { amount: Number(unitPrice).toFixed(2), currency },
            unitPriceExcludingTax: { amount: Number(unitPriceExcludingTax).toFixed(2), currency },
            discount: { amount: Number(discountAmount).toFixed(2), type: discountType },
            ...(id ? { id } : {}),
            ...(buyerItemId ? { buyerItemId } : {}),
            ...(supplierItemId ? { supplierItemId } : {}),
        }
    })
}

export const convertPOtoPOV2 = (purchaseOrder: CreatePurchaseOrderDTO): CreatePurchaseOrderDTOV2 => {
    const {
        status,
        billingAddress,
        description,
        expectedDeliveryDate,
        shippingAddress,
        supplierId,
        buyerId,
        lines,
        currency,
    } = purchaseOrder

    return {
        status,
        billingAddress,
        description,
        shippingAddress,
        supplierId,
        buyerId,
        lines: convertLines(lines, currency),
        ...(expectedDeliveryDate ? { expectedDeliveryDate } : {}),
    }
}

export const convertPOtoPOV2ForUpdate = (purchaseOrder: PurchaseOrders): UpdatePurchaseOrderDTOV2 => {
    const { billingAddress, description, expectedDeliveryDate, shippingAddress, lines, currency } = purchaseOrder

    return {
        billingAddress,
        description,
        expectedDeliveryDate,
        shippingAddress,
        lines: convertLines(lines, currency),
    }
}

/**
 * @deprecated
 * Use UserName component instead
 */
export const enrichPurchaseOrdersWithNames = async (
    purchaseOrders: ListPurchaseOrdersResponse
): Promise<PurchaseOrders[]> => {
    const organizationIds = new Set<string>()
    const userIds = new Set<string>()

    purchaseOrders.forEach(({ buyerId, supplierId, requesterUserId }) => {
        if (buyerId) organizationIds.add(buyerId)
        if (supplierId) organizationIds.add(supplierId)
        if (requesterUserId) userIds.add(requesterUserId)
    })

    // Fetch organizations and users in chunks
    const [organizations, users] = await Promise.all([
        organizationIds.size
            ? fetchEntitiesInChunks([...organizationIds], organizationApi.fetchOrganizationsByIds)
            : ([] as OrganizationI[]),
        userIds.size ? fetchEntitiesInChunks([...userIds], userApi.findUsersByIds) : ([] as UserI[]),
    ])

    const organizationMap = new Map(organizations.map((org) => [org.id, org.name]))
    const userMap = new Map(users.map((user) => [user.id, user.fullName]))

    const POWithNames = purchaseOrders.map((po) => ({
        ...po,
        supplierName: organizationMap.get(po.supplierId) ?? "",
        buyerName: organizationMap.get(po.buyerId) ?? "",
        requesterName: userMap.get(po.requesterUserId ?? "") ?? "",
    }))

    return POWithNames
}
