import { useEffect, useState } from "react"
import { Check, X } from "react-feather"

import { Steps } from "~/components"
import { StepI } from "~/domains/transactions/_shared/components/Steps/Step"
import { usePatchUpdateInvoiceMutation } from "~/domains/transactions/invoices-v1/api/invoiceApi"
import { useGetOrganizationInvoiceLifecycleQuery } from "~/domains/transactions/invoices-v1/api/invoiceLifeCycleApi"
import { InvoiceLifeCycleItem, InvoiceParty } from "~/domains/transactions/invoices-v1/types/Invoice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { invoiceActions, selectInvoice } from "~/store/invoice/invoiceSlice"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"

export const InvoiceLifeCycle = () => {
    const dispatch = useAppDispatch()
    const invoice = useAppSelector(selectInvoice)
    const organizationId = useAppSelector(selectCurrentOrganizationId)

    const { data: organizationInvoiceLifeCycle } = useGetOrganizationInvoiceLifecycleQuery(organizationId || "")
    const [patchUpdateInvoice] = usePatchUpdateInvoiceMutation()

    const party = invoice?.parties?.[organizationId || ""]
    const [currentLifecycle, setCurrentLifecycle] = useState(party?.currentLifecycle)

    const lifecycle: InvoiceLifeCycleItem[] = [
        ...(party?.template?.lifecycle?.default ? [party.template.lifecycle.default] : []),
        ...(party?.template?.lifecycle?.other || []),
    ]

    const getIcon = (status: string) => {
        const lifecycleStatus = organizationInvoiceLifeCycle?.find((item) => item[0] === status)

        if (lifecycleStatus?.[1].group === "COMPLETED_ERROR") {
            return <X size={18} />
        } else {
            return <Check size={18} />
        }
    }

    const getType = (status: string) => {
        const lifecycleStatus = organizationInvoiceLifeCycle?.find((item) => item[0] === status)

        if (lifecycleStatus?.[1].group === "COMPLETED_ERROR") {
            return "error"
        } else {
            return "success"
        }
    }

    useEffect(() => {
        setCurrentLifecycle(party?.currentLifecycle)
    }, [party?.currentLifecycle])

    const handleUpdateLifecycle = async (status: string) => {
        if (!organizationId || !invoice) return

        try {
            setCurrentLifecycle(status)
            await patchUpdateInvoice({ id: invoice.id, parties: { [organizationId]: { currentLifecycle: status } } })
            const parties = { [organizationId]: { ...party, currentLifecycle: status } } as Record<string, InvoiceParty>
            dispatch(invoiceActions.updatePartialInvoice({ id: invoice.id, parties }))
        } catch (error) {
            setCurrentLifecycle(currentLifecycle)
        }
    }

    const steps: StepI[] = (lifecycle || []).map(({ status }) => ({
        key: status,
        name: status,
        status: status === currentLifecycle ? "done" : "next",
        type: getType(status),
        icon: getIcon(status),
        onClick: handleUpdateLifecycle,
    }))

    const paymentStep = {
        key: "payment",
        name: "paid",
        status: invoice?.paidAt ? "done" : "next",
    }

    const stepsWithPayment = paymentStep ? [...steps, paymentStep] : steps

    return <Steps steps={stepsWithPayment} />
}
