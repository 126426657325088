import { SupportedLanguages } from "~/constants/lang"
import { TagObjectI } from "~/domains/analytics/tags/types"
import { FulfillmentStatus, PurchaseLineDiscount } from "~/domains/transactions/_shared/types/Purchases"
import { BudgetDataWithMetricsI } from "~/domains/transactions/budget/types"
import { ProductVersionId } from "~/domains/transactions/catalog/types"
import {
    PurchaseOrderFulfillmentPresentation,
    PurchaseOrderProgressStatus,
    PurchaseOrderStatus,
    PurchaseOrderStatusCreate,
    PurchaseOrderStatusPresentation,
} from "~/domains/transactions/purchase-orders/types/PurchaseOrderStatus"
import { CreateLineDTO } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import {
    AmountsMonetaryData,
    CurrencyCodes,
    MonetaryInfo,
    OrganizationI,
    SpiceDBPermissionsResult,
    TotalAmountsMonetaryData,
    UserId,
} from "~/types"

export enum PurchaseOrderViewType {
    ADD = "ADD",
    EDIT = "EDIT",
    VIEW = "VIEW",
}
export interface CreatePurchaseOrderResponse {
    id: string
}

export interface Address {
    city: string
    country: string
    street: string
    street2?: string | null
    zipCode: string
}

export const PurchaseOrderApprovalStatusEnum = ["PENDING", "APPROVED", "REJECTED", "UNKNOWN"] as const
export type PurchaseOrderApprovalStatus = (typeof PurchaseOrderApprovalStatusEnum)[number]

export interface PurchaseOrderLineWithApprovalStatus {
    buyerApprovalStatus: PurchaseOrderApprovalStatus
    supplierApprovalStatus: PurchaseOrderApprovalStatus
}

export interface PurchaseOrderLineV2 extends PurchaseOrderLineWithApprovalStatus {
    id: string | null
    description: string
    quantity: number
    originalQuantity?: number
    units?: string
    taxRate: number
    unitPrice: MonetaryInfo
    unitPriceExcludingTax: MonetaryInfo
    discount: PurchaseLineDiscount
    deliveryNote?: string
    temporaryId?: string
    hasChanged?: boolean
    buyerFulfillment: FulfillmentStatus
    supplierFulfillment: FulfillmentStatus
    supplierItemId?: ProductVersionId
    buyerItemId?: ProductVersionId
    totals: AmountsMonetaryData
}

export interface PurchaseOrderLineCustomFields {
    id: string
    name: string
    value: string
}

export const PurchaseOrderLineDiscountType = ["FLAT", "PERCENTAGE", "PER_UNIT"] as const
export type PurchaseOrderLineDiscountType = (typeof PurchaseOrderLineDiscountType)[number]

export interface PurchaseOrderLineDiscount {
    amount: string
    type: PurchaseOrderLineDiscountType
}

export interface PurchaseOrderLine extends PurchaseOrderLineWithApprovalStatus {
    id: string | null
    description: string
    quantity: number
    originalQuantity?: number
    units?: string
    taxRate: number
    totalAmount: number
    totalAmountExcludingTax: number
    totalTax: number
    unitPrice: number
    unitPriceExcludingTax: number
    discount: PurchaseLineDiscount
    deliveryNote?: string
    temporaryId?: string
    hasChanged?: boolean
    buyerFulfillment: FulfillmentStatus
    supplierFulfillment: FulfillmentStatus
    supplierItemId?: ProductVersionId
    buyerItemId?: ProductVersionId
    customFields?: PurchaseOrderLineCustomFields[]
}

export interface PurchaseOrdersResponseV2 {
    billingAddress?: Address | null
    buyerId: string
    creationDate: string
    expectedDeliveryDate: string
    id: string
    lines: PurchaseOrderLineV2[]
    lineCount?: number
    requesterUserId?: string
    requesterOrganizationId?: string
    shippingAddress?: Address | null
    email?: string
    shortId: string
    status: PurchaseOrderStatus
    progress: PurchaseOrderProgressStatus
    statusPresentation: PurchaseOrderStatusPresentation
    fulfillmentPresentation: PurchaseOrderFulfillmentPresentation
    supplierId: string
    purchaseRequest: PurchaseOrderPurchaseRequest
    description: string
    totals: TotalAmountsMonetaryData
    permissions: SpiceDBPermissionsResult
    tagIds: string[]
    invoiceNumber?: string
}

export interface PurchaseOrdersResponse {
    billingAddress?: Address | null
    buyerId: string
    creationDate: string
    currency: CurrencyCodes
    expectedDeliveryDate: string
    id: string
    lines: PurchaseOrderLine[]
    lineCount?: number
    requesterUserId?: string
    requesterOrganizationId?: string
    shippingAddress?: Address | null
    shortId: string
    status: PurchaseOrderStatus
    statusPresentation: PurchaseOrderStatusPresentation
    fulfillmentPresentation: PurchaseOrderFulfillmentPresentation
    supplierId: string
    totalAmount: number
    totalAmountExcludingTax: number
    totalTax: number
    purchaseRequest: PurchaseOrderPurchaseRequest
    description: string
    permissions: SpiceDBPermissionsResult
    tagIds: string[]

    [x: string]: unknown
}

export interface PurchaseOrders extends PurchaseOrdersResponse {
    supplierName: string
    buyerName: string
    requesterName?: string
    tags?: TagObjectI[]
    budgets?: BudgetDataWithMetricsI[] | null
    buyer?: OrganizationI
    supplier?: OrganizationI
}

// For List-ResponsesGetPurchaseOrdersResponse, we can simply use an array of ResponsesGetPurchaseOrdersResponse
export type ListPurchaseOrdersResponse = PurchaseOrdersResponse[]
export type ListPurchaseOrders = PurchaseOrders[]

export interface PurchaseOrderPurchaseRequest {
    id: string
    number: string
}

export interface CreateLineDTOV2 {
    id?: string | null
    description: string
    quantity: number
    units?: string
    unitPrice: MonetaryInfo
    unitPriceExcludingTax: MonetaryInfo
    taxRate: string
    buyerItemId?: ProductVersionId
    supplierItemId?: ProductVersionId
    itemId?: ProductVersionId
}

export interface CreatePurchaseOrderDTOV2 {
    buyerId: string
    supplierId: string
    status: PurchaseOrderStatusCreate
    description: string
    expectedDeliveryDate?: string
    shippingAddress?: Address | null
    shortId?: string | null
    requesterUserId?: UserId
    billingAddress?: Address | null
    lines: CreateLineDTOV2[]
}

export interface CreatePurchaseOrderDTO {
    buyerId: string
    supplierId: string
    status: PurchaseOrderStatusCreate
    description: string
    currency: CurrencyCodes
    expectedDeliveryDate: string // Should be formatted as a Date in TypeScript
    lines: CreateLineDTO[]
    shippingAddress?: Address | null
    shortId?: string | null
    requesterUserId?: UserId
    billingAddress?: Address | null
}

export interface UpdatePurchaseOrderDTOV2 {
    billingAddress?: Address | null
    description: string
    expectedDeliveryDate: string
    lines: CreateLineDTOV2[]
    shippingAddress?: Address | null
    shortId?: string | null
}

export enum PurchaseOrdersTab {
    ALL = "ALL",
    DRAFT = "DRAFT",
    SUBMITTED = "SUBMITTED",
    INTERNALLY_APPROVED = "INTERNALLY_APPROVED",
    OPEN = "OPEN",
    PENDING = "PENDING",
    IN_PREPARATION = "IN_PREPARATION",
    SHIPPED = "SHIPPED",
    PARTIALLY_RECEIVED = "PARTIALLY_RECEIVED",
    RECEIVED = "RECEIVED",
    CLOSED = "CLOSED",
}

interface RegistrationInformation {
    registrationNumber?: string
    registrationType?: string
    vatNumber?: string
}

export interface PurchaseOrderPDFDTO {
    logo?: string
    description: string
    purchaseOrderId: string
    url?: string
    issueDate: string
    deliveryDate: string
    paymentTerms: string
    buyer: {
        name: string
        billingAddress: Address
        shippingAddress: Address
        contact?: Contact
        registrationInformation?: RegistrationInformation
    }
    supplier: {
        name: string
        address: Address
        contact?: Contact
        registrationInformation?: RegistrationInformation
    }
    items: PoToPdfItem[]
    currency: CurrencyCodes
    language: SupportedLanguages
    totalAmount: number
    totalAmountExcludingTax: number
    totalTax: number
    budgets: {
        id: string
        name: string
        description: string
        internalReference?: string
    }[]
}
export interface PoToPdfItem {
    description: string
    quantity: number
    unitPriceExcludingTax: number
    taxRate: number
    totalAmount: number
    unit?: string | undefined
    tags: {
        tagId: string
        name: string
        tagGroupId: string
        parentId?: string | null
        description?: string | null
        externalId?: string | null
        value?: string | null
        ratio?: number
        tagGroupName?: string
    }[]
}

export interface Contact {
    name: string
    phone?: string
    email?: string
}

export interface PurchaseOrderAPI {
    id: string
    lines: PurchaseOrderLineV2[]
}

export interface PurchaseOrderI {
    id: string
    lines: PurchaseOrderLineV2[]
}

export interface PurchaseOrderSummary {
    statusPresentation: Record<PurchaseOrderStatusPresentation, number>
    fulfillmentPresentation: Record<PurchaseOrderFulfillmentPresentation, number>
}
