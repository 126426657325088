import { useApprovalReview } from "~/domains/orchestration/flows-v0/hooks/useApprovalReview"
import { ApprovalObjectType } from "~/domains/orchestration/flows-v0/types"
import { PurchaseRequestDetails } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"

export const usePurchaseRequestReview = (purchaseRequest: PurchaseRequestDetails) => {
    const { isApprovalDone, isAdmin } = useApprovalReview({
        organisationId: purchaseRequest.organizationId,
        objectId: purchaseRequest.id,
        objectType: ApprovalObjectType.PURCHASE_REQUEST,
    })

    return { isApprovalDone, isAdmin }
}
