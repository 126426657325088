import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { defineMessages } from "react-intl"

import { prepareHeadersWithAuthorization as prepareHeaders } from "~/api/prepareHeaders"
import { FulfillmentStatus } from "~/domains/transactions/_shared/types/Purchases"
import { purchaseOrderAnalyticsFromApiAdapter } from "~/domains/transactions/purchase-orders/api/adapters/purchaseOrderAnalyticsFromApiAdapter"
import { purchaseOrderFromApiAdapter } from "~/domains/transactions/purchase-orders/api/adapters/purchaseOrderFromApiAdapter"
import { purchaseOrderPaginatedFromApiAdapter } from "~/domains/transactions/purchase-orders/api/adapters/purchaseOrderPaginatedFromApiAdapter"
import {
    GetPurchaseOrderSummaryQuery,
    PurchaseOrdersPaginatedAPI,
    PurchaseOrdersPaginatedQueryOptions,
} from "~/domains/transactions/purchase-orders/api/types/purchaseOrderApi.type"
import { PurchaseOrdersPaginatedResult } from "~/domains/transactions/purchase-orders/api/types/purchaseOrderApi.type"
import { DEFAULT_PURCHASE_ORDER_SORT_BY } from "~/domains/transactions/purchase-orders/components/POListPaginated/List"
import { PurchaseOrderI, PurchaseOrderSummary } from "~/domains/transactions/purchase-orders/types"
import { PurchaseOrderAPI } from "~/domains/transactions/purchase-orders/types"
import { buildQueryString, buildQueryStringFromFilterModel } from "~/utils/api/qs"
import { DEFAULT_PAGE_SIZE } from "~/utils/table"

const errors = defineMessages({
    unknownError: {
        id: "purchase.orders.order.statusUpdate.error",
        defaultMessage: "Something went wrong. Please contact your administrator",
    },
})

const BASE_URL_V3 = import.meta.env.VITE_API_PURCHASE_URL + "v3/organizations/"
const BASE_URL_V4 = import.meta.env.VITE_API_PURCHASE_URL + "v4/organizations/"

export const purchaseOrderApiTags = ["purchaseOrder"]

export const purchaseOrderApi = createApi({
    reducerPath: "purchaseOrderApi",
    baseQuery: fetchBaseQuery({
        prepareHeaders,
    }),
    tagTypes: [...purchaseOrderApiTags],
    endpoints: (builder) => ({
        updateFulfillmentStatus: builder.mutation<
            PurchaseOrderI,
            {
                organizationId: string
                purchaseOrderId: string
                lines: { id: string; quantity: number }[]
                status: FulfillmentStatus
            }
        >({
            query: ({ organizationId, purchaseOrderId, lines, status }) => ({
                url: `${BASE_URL_V3}${organizationId}/purchase-orders/${purchaseOrderId}/lines/fulfillment`,
                method: "POST",
                body: { lines, status },
            }),
            transformResponse: (response: PurchaseOrderAPI) => purchaseOrderFromApiAdapter(response),
            transformErrorResponse: (response) => ({
                meta: {
                    errorMessage: errors.unknownError,
                    catchSentryException: true,
                },
                ...response,
            }),
        }),
        getPurchaseOrders: builder.query<PurchaseOrdersPaginatedResult, PurchaseOrdersPaginatedQueryOptions>({
            query: ({
                organizationId,
                offset = 0,
                limit = DEFAULT_PAGE_SIZE,
                buyerId,
                supplierId,
                description,
                statusPresentation,
                fulfillmentPresentation,
                filterModel,
                tagIds,
                sortBy = DEFAULT_PURCHASE_ORDER_SORT_BY,
                ascending = true,
            }) => {
                const params = new URLSearchParams({
                    offset: offset.toString(),
                    limit: limit.toString(),
                    sortBy: sortBy as string,
                    ascending: ascending.toString(),
                    ...(buyerId ? { buyerId } : {}),
                    ...(supplierId ? { supplierId } : {}),
                })

                const queryStringFromParams = buildQueryString([
                    [!!description, "description", "$containsi", description, "&and"],
                    [
                        !!statusPresentation && !["ALL", "PENDING"].includes(statusPresentation),
                        "statusPresentation",
                        "$eq",
                        statusPresentation,
                        "&and",
                    ],
                    [
                        !!fulfillmentPresentation && !["ALL", "PENDING"].includes(fulfillmentPresentation),
                        "fulfillmentPresentation",
                        "$eq",
                        fulfillmentPresentation,
                        "&and",
                    ],
                    [statusPresentation === "PENDING", "statusPresentation", "$null", undefined, undefined],
                    [fulfillmentPresentation === "PENDING", "fulfillmentPresentation", "$null", undefined, undefined],
                    [!!tagIds?.length, "tagIds", "$in", `[${tagIds?.join(",")}]`, "&and"],
                ])
                const queryStringFromFilterModel = !filterModel ? [] : buildQueryStringFromFilterModel(filterModel)
                const queryString = [queryStringFromFilterModel, queryStringFromParams].filter(Boolean).join("&")

                return {
                    url: `${BASE_URL_V4}${organizationId}/purchase-orders${queryString && `?${queryString}`}`,
                    params,
                }
            },
            transformResponse: (response: PurchaseOrdersPaginatedAPI) => ({
                ...purchaseOrderPaginatedFromApiAdapter(response),
                tagIds: response.items.flatMap((item) => item.tagIds),
            }),
            transformErrorResponse: (response) => ({
                meta: {
                    errorMessage: errors.unknownError,
                    catchSentryException: true,
                },
                ...response,
            }),
            providesTags: purchaseOrderApiTags,
        }),
        getPurchaseOrdersSummary: builder.query<PurchaseOrderSummary, GetPurchaseOrderSummaryQuery>({
            query: ({ organizationId, buyerId, supplierId }) => {
                const params = new URLSearchParams({
                    ...(buyerId ? { buyerId } : {}),
                    ...(supplierId ? { supplierId } : {}),
                })
                return { url: `${BASE_URL_V4}${organizationId}/purchase-orders/summary`, params }
            },
            transformResponse: purchaseOrderAnalyticsFromApiAdapter,
        }),
    }),
})

export const { useUpdateFulfillmentStatusMutation, useGetPurchaseOrdersQuery, useGetPurchaseOrdersSummaryQuery } =
    purchaseOrderApi
