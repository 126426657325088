import { FileText } from "react-feather"

import { CopyLinkedDocumentsConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { RegularNode } from "~/domains/orchestration/flows/components/nodes"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    CopyLinkedDocumentsNode as CopyLinkedDocumentsNodeType,
    EditorNode,
    Node,
    NodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const copyLinkedDocumentsNode: Omit<CopyLinkedDocumentsNodeType, keyof Node> = {
    sourceObjectId: "",
    targetObjectId: "",
    nextNode: null,
    type: NodeType.COPY_LINKED_DOCUMENTS_NODE,
}

const advancedFields: AllAdvancedFieldsUnion[] = [
    AllAdvancedFieldsUnion.SOURCE_OBJECT_ID,
    AllAdvancedFieldsUnion.TARGET_OBJECT_ID,
]

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.COPY_LINKED_DOCUMENTS_NODE) return false
    return Boolean(node.sourceObjectId && node.targetObjectId)
}

const copyLinkedDocumentsConfig: Configuration<NodeType.COPY_LINKED_DOCUMENTS_NODE> = {
    type: NodeType.COPY_LINKED_DOCUMENTS_NODE,
    baseSlug: "copy-linked-documents",
    Icon: FileText,
    Configuration: CopyLinkedDocumentsConfiguration,
    Node: RegularNode,
    advancedFields,
    factory: (props: FactoryProps): CopyLinkedDocumentsNodeType => ({
        ...copyLinkedDocumentsNode,
        ...createCommonNodeProperties({ ...props }),
    }),
    validateNode,
}

export { copyLinkedDocumentsConfig, advancedFields as copyLinkedDocumentsAdvancedFields }
