import { customOnboardingRulesPerOrg } from "~/utils/featureFlag"

export interface CustomOnboardingRules {
    hasOnlySupplierCreation: boolean
    isContactRequired: boolean
    hasCustomFields: boolean
    hasPartnershipAttributes: boolean
}

const DEFAULT_CUSTOM_ONBOARDING_RULES: CustomOnboardingRules = {
    hasOnlySupplierCreation: false,
    isContactRequired: false,
    hasCustomFields: false,
    hasPartnershipAttributes: true,
}

export const getCustomRules = (organizationId: string): CustomOnboardingRules => {
    const orgSpecificRules = customOnboardingRulesPerOrg[organizationId] || {}
    return { ...DEFAULT_CUSTOM_ONBOARDING_RULES, ...orgSpecificRules }
}
