import { useHasMultiplePermissions } from "~/domains/identity/roles-permissions/store/hooks/useHasPermissions"
import { DomainName, ScopeName } from "~/domains/identity/roles-permissions/types/RolesPermissions"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { AuthorizationNames } from "~/types"

interface UseHasBudgetPermissionsProps {
    authorizations: AuthorizationNames[]
    organizationId?: string
}

export const useHasBudgetPermissions = ({ authorizations, organizationId }: UseHasBudgetPermissionsProps) => {
    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)

    const { permissions } = useHasMultiplePermissions({
        authorizations,
        organizationId: organizationId ?? currentOrganizationId ?? "",
        domain: DomainName.TRANSACTION,
        scope: ScopeName.BUDGETS,
    })

    return { permissions }
}
