import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorizationAndOrganizationId as prepareHeaders } from "~/api/prepareHeaders"
import { commonMessages } from "~/common-messages"
import { invoiceOcrRawDataFromApiAdapter } from "~/domains/_shared/ocr/api/invoiceOcrRawDataFromApiAdapter"
import {
    InvoiceOcrRawDataI,
    OcrDocumentId,
    ReprocessDocumentByInvoiceIdPayload,
    ReprocessDocumentPayload,
} from "~/domains/_shared/ocr/types"
import { InvoiceId, InvoiceUserType, OrganizationId } from "~/types"

const BASE_URL = import.meta.env.VITE_API_OCR_URL

export interface UploadOCRResultV2 {
    jobId: OcrDocumentId
}

type UploadInvoiceQuery = {
    file: File
    initiator: InvoiceUserType | null
    organizationId?: OrganizationId
}

export const ocrApi = createApi({
    reducerPath: "ocrApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, prepareHeaders }),
    endpoints: (builder) => ({
        uploadInvoice: builder.mutation<UploadOCRResultV2, UploadInvoiceQuery>({
            query: ({ file, initiator, organizationId }) => {
                const formData = new FormData()
                formData.append("file", file)
                return {
                    url: `/v2/upload-invoice`,
                    method: "POST",
                    params: { initiator, organizationId },
                    body: formData,
                }
            },
            transformErrorResponse: (response) => ({
                meta: {
                    errorMessage: commonMessages.error,
                    catchSentryException: true,
                },
                ...response,
            }),
            transformResponse: (data: unknown) => {
                if (!data || typeof data !== "object" || !("jobId" in data) || typeof data.jobId !== "string") {
                    throw new Error(`Invalid uploadInvoice response: ${JSON.stringify(data)}`)
                }
                return { jobId: data.jobId }
            },
        }),
        reprocessDocument: builder.query<boolean, ReprocessDocumentPayload>({
            query: ({ documentId }) => ({
                url: `document/${documentId}/reprocess`,
                responseHandler: "text",
            }),
            transformErrorResponse: (response) => ({
                meta: {
                    errorMessage: commonMessages.error,
                    catchSentryException: true,
                },
                ...response,
            }),
            transformResponse: (data: string) => {
                return data === "OK"
            },
        }),
        reprocessDocumentByInvoiceId: builder.query<boolean, ReprocessDocumentByInvoiceIdPayload>({
            query: ({ invoiceId }) => ({
                url: `document/by-invoice-id/${invoiceId}/reprocess`,
                responseHandler: "text",
            }),
            transformErrorResponse: (response) => ({
                meta: {
                    errorMessage: commonMessages.error,
                    catchSentryException: true,
                },
                ...response,
            }),
            transformResponse: (data: string) => {
                return data === "OK"
            },
        }),
        convertPdfToImages: builder.mutation<string[], File>({
            query: (file) => {
                const body = new FormData()
                body.append("file", file)
                return {
                    url: `pdf-to-images/process`,
                    formData: true,
                    body,
                    method: "POST",
                }
            },
            transformErrorResponse: (response) => ({
                meta: {
                    errorMessage: commonMessages.error,
                    catchSentryException: true,
                },
                ...response,
            }),
            transformResponse: (data) => {
                if (Array.isArray(data)) {
                    return data.filter((url) => typeof url === "string")
                }
                return []
            },
        }),
        fetchInvoiceRawData: builder.query<InvoiceOcrRawDataI, InvoiceId>({
            query: (invoiceId) => ({
                url: `document/by-invoice-id/${invoiceId}`,
            }),
            transformErrorResponse: (response) => ({
                meta: {
                    errorMessage: commonMessages.error,
                    disableToast: true,
                },
                ...response,
            }),
            transformResponse: invoiceOcrRawDataFromApiAdapter,
        }),
    }),
})

export const {
    useUploadInvoiceMutation,
    useLazyReprocessDocumentQuery,
    useLazyReprocessDocumentByInvoiceIdQuery,
    useConvertPdfToImagesMutation,
    useLazyFetchInvoiceRawDataQuery,
} = ocrApi
