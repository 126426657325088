import { DEFAULT_CURRENCY, ENTITY_DEFAULT_SLUG, EVENT_DEFAULT_SLUG } from "~/domains/orchestration/flows/constants"
import { useEditor, useEditorDispatch } from "~/domains/orchestration/flows/context/editorContext"
import { adaptEntityTriggerProperties, adaptEventTriggerProperties } from "~/domains/orchestration/flows/core"
import { Flow, NodeType, Trigger, isEventTriggerType } from "~/domains/orchestration/flows/types"
import { getAmountPath } from "~/domains/orchestration/flows/utils/editor"

interface FlowTriggerUpdate {
    updateFlowForTrigger: (trigger: Trigger) => void
}

export const useFlowTriggerUpdate = (): FlowTriggerUpdate => {
    const state = useEditor()
    const dispatch = useEditorDispatch()
    const currentFlow = state.flow

    const updateFlowForTrigger = (trigger: Trigger) => {
        const slug = isEventTriggerType(trigger)
            ? EVENT_DEFAULT_SLUG
            : currentFlow?.nodes.find((node) => node.type === NodeType.ENTITY_TRIGGER_NODE)?.slug || ENTITY_DEFAULT_SLUG

        const { objectType, transactionType, objectId, respondentOrganizationId } = isEventTriggerType(trigger)
            ? adaptEventTriggerProperties(trigger.type, slug)
            : adaptEntityTriggerProperties(trigger.type, slug)

        if (!currentFlow?.nodes) return
        const hasCurrencyConversion = trigger.currencyConversion !== null
        const currency = trigger.currencyConversion?.targetCurrency || DEFAULT_CURRENCY

        const updatedNodes = currentFlow.nodes.map((node) => {
            const updatedNode = { ...node }

            if ("objectType" in updatedNode && objectType) {
                updatedNode.objectType = objectType
            }
            if ("transactionType" in updatedNode && transactionType) {
                updatedNode.transactionType = transactionType
            }
            if ("transactionId" in updatedNode) {
                updatedNode.transactionId = objectId
            }
            if ("respondentOrganizationId" in updatedNode) {
                updatedNode.respondentOrganizationId = respondentOrganizationId
            }
            if ("objectId" in updatedNode && objectId) {
                updatedNode.objectId = objectId
            }
            if ("amount" in updatedNode) {
                const amountPath = getAmountPath(trigger, currency)
                if (amountPath) {
                    updatedNode.amount = amountPath
                }
            }
            if ("currency" in updatedNode && hasCurrencyConversion) {
                updatedNode.currency = trigger.currencyConversion?.targetCurrency || DEFAULT_CURRENCY
            }

            return updatedNode
        })

        const updatedFlow: Flow = {
            ...currentFlow,
            nodes: updatedNodes,
        }

        dispatch({
            type: "SET_FLOW",
            payload: updatedFlow,
        })
        dispatch({
            type: "PUSH_HISTORY",
            payload: updatedFlow,
        })
    }

    return { updateFlowForTrigger }
}
