import { useCallback, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { purchaseOrdersApi } from "~/domains/transactions/purchase-orders/api/purchaseOrdersApi"
import { PurchaseOrderStatus } from "~/domains/transactions/purchase-orders/types"
import { useAppDispatch } from "~/store/hooks"
import { OrganizationId } from "~/types"

const messages = defineMessages({
    submitted: {
        id: "purchase.orders.order.statusUpdate.submitted",
        defaultMessage: "Purchase order submitted",
    },
    open: {
        id: "purchase.orders.order.statusUpdate.open",
        defaultMessage: "Purchase order sent",
    },
    error: {
        id: "purchase.orders.order.statusUpdate.error",
        defaultMessage: "Something went wrong. Please contact your administrator",
    },
})

export const useUpdatePOStatus = (purchaseOrderId: string) => {
    const { formatMessage } = useIntl()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string>()
    const dispatch = useAppDispatch()

    const updateStatus = useCallback(
        async (organizationId: OrganizationId, status: PurchaseOrderStatus, id?: string) => {
            try {
                setLoading(true)
                await purchaseOrdersApi.updateStatus(organizationId, id ?? purchaseOrderId, status)
                setLoading(false)

                return toast.success(formatMessage(messages[status.toLowerCase()]))
            } catch (e) {
                setError(`${e}`)
                setLoading(false)
                toast.error(formatMessage(messages.error))
                throw e
            }
        },
        [purchaseOrderId, dispatch]
    )

    return {
        updateStatus,
        error,
        loading,
    }
}
