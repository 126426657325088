import { Typography } from "@mui/material"
import React, { PropsWithChildren } from "react"

import { Modal } from "~/components"

interface Props {
    visible: boolean
    title: string
    onClose: () => void
}

export const RefusalReasonsModal: React.FC<PropsWithChildren<Props>> = ({ children, visible, title, onClose }) => {
    return (
        <Modal open={visible} onClose={onClose}>
            <Modal.Header>
                <Typography variant="h2" gutterBottom>
                    {title}
                </Typography>
            </Modal.Header>
            {children}
        </Modal>
    )
}
