import dayjs from "dayjs"
import { MessageDescriptor } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"

import { SafeFormattedMessage } from "~/components"
import { DateComponent } from "~/components/Date"
import { BasicTabsTabProps } from "~/components/Tabs/BasicTabs"
import { DashboardEmptyState } from "~/domains/analytics/dashboard/components/DashboardEmptyState"
import { DashboardListItem } from "~/domains/analytics/dashboard/components/DashboardListItem"
import { DashboardTabCount } from "~/domains/analytics/dashboard/components/DashboardTabCount"
import { dashboardCommonMessages } from "~/domains/analytics/dashboard/dashboardCommonMessages"
import { PURCHASE_REQUEST_ROUTE } from "~/domains/transactions/purchase-requests/routes"
import {
    PurchaseRequestStatus,
    PurchaseRequestSummary,
} from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { UserI } from "~/types"

const prStatusLabels: Record<PurchaseRequestStatus, MessageDescriptor> = {
    DRAFT: {
        id: "purchase.requests.status.draft",
        defaultMessage: "Draft",
    },
    SUBMITTED: {
        id: "purchase.requests.status.submitted",
        defaultMessage: "Validation pending",
    },
    APPROVED: {
        id: "purchase.requests.status.approved",
        defaultMessage: "Approved",
    },
    REJECTED: {
        id: "purchase.requests.status.rejected",
        defaultMessage: "Rejected",
    },
    CONVERTED: {
        id: "purchase.requests.status.converted",
        defaultMessage: "Converted",
    },
}

// Number of items to render
const LIST_ITEMS_LIMIT = 20

export const useDashboardPRStatusTabs = (
    purchaseRequests: PurchaseRequestSummary[],
    user: UserI
): BasicTabsTabProps[] => {
    const navigate = useNavigate()
    const filterPurchaseRequests = (
        requests: PurchaseRequestSummary[],
        userId: string,
        status: PurchaseRequestStatus
    ) => {
        return requests
            .filter(
                ({ requesterUserId, status: requestStatus }) => requesterUserId === userId && requestStatus === status
            )
            .sort((a, b) => dayjs(b.creationDate).diff(dayjs(a.creationDate)))
            .slice(0, LIST_ITEMS_LIMIT)
    }

    // filter the purchase requests based on their status
    const submittedPurchaseRequests = filterPurchaseRequests(purchaseRequests, user.id, PurchaseRequestStatus.SUBMITTED)
    const approvedPurchaseRequests = filterPurchaseRequests(purchaseRequests, user.id, PurchaseRequestStatus.APPROVED)
    const draftPurchaseRequests = filterPurchaseRequests(purchaseRequests, user.id, PurchaseRequestStatus.DRAFT)

    /**
     * Renders purchase requests based on status
     * @param status
     * @returns
     */
    const renderPurchaseRequests = (filteredPurchaseRequests: PurchaseRequestSummary[]) => {
        if (!filteredPurchaseRequests.length) {
            return <DashboardEmptyState />
        }

        return (
            <>
                {filteredPurchaseRequests.map(
                    ({ id, description, supplierName, totalAmount, currency, creationDate, status }) => {
                        const formattedDescription = `${description} ${supplierName || ""} - ${totalAmount} ${currency}`
                        const approvalDate = (
                            <>
                                <SafeFormattedMessage {...dashboardCommonMessages.purchaseRequest} /> -
                                <DateComponent className="dashboard-list-item-date" value={creationDate} />
                            </>
                        )
                        const statusBadge = (
                            <span className={`pr-status status-${status}`}>
                                <SafeFormattedMessage {...prStatusLabels[status]} />
                            </span>
                        )

                        const itemOnClick = () =>
                            navigate(generatePath(PURCHASE_REQUEST_ROUTE, { purchaseRequestId: id }))

                        return (
                            <DashboardListItem
                                key={id}
                                onClick={itemOnClick}
                                firstLine={formattedDescription}
                                secondLine={approvalDate}
                                status={statusBadge}
                            />
                        )
                    }
                )}
            </>
        )
    }

    return [
        {
            label: (
                <div>
                    <SafeFormattedMessage {...prStatusLabels[PurchaseRequestStatus.SUBMITTED]} />
                    <DashboardTabCount count={submittedPurchaseRequests.length} />
                </div>
            ),
            value: PurchaseRequestStatus.SUBMITTED,
            component: renderPurchaseRequests(submittedPurchaseRequests),
        },
        {
            label: (
                <div>
                    <SafeFormattedMessage {...prStatusLabels[PurchaseRequestStatus.APPROVED]} />
                    <DashboardTabCount count={approvedPurchaseRequests.length} />
                </div>
            ),
            value: PurchaseRequestStatus.APPROVED,
            component: renderPurchaseRequests(approvedPurchaseRequests),
        },
        {
            label: (
                <div>
                    <SafeFormattedMessage {...prStatusLabels[PurchaseRequestStatus.DRAFT]} />
                    <DashboardTabCount count={draftPurchaseRequests.length} />
                </div>
            ),
            value: PurchaseRequestStatus.DRAFT,
            component: renderPurchaseRequests(draftPurchaseRequests),
        },
    ]
}
