/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react"

import EdfSmall from "~/assets/images/edf-small.svg"
import Edf from "~/assets/images/edf.svg"
import CndgSmall from "~/assets/images/logo-CNDG-small.webp"
import Cndg from "~/assets/images/logo-CNDG.webp"
import CpfaSmall from "~/assets/images/logo-CPFA-small.webp"
import Cpfa from "~/assets/images/logo-CPFA.webp"
import GhdcSmall from "~/assets/images/logo-GHDC-small.webp"
import Ghdc from "~/assets/images/logo-GHDC.webp"
import HeloraSmall from "~/assets/images/logo-HELORA-small.webp"
import Helora from "~/assets/images/logo-HELORA.webp"
import StVithSmall from "~/assets/images/logo-ST-VITH-small.webp"
import StVith from "~/assets/images/logo-ST-VITH.webp"
import ActuelVet from "~/assets/images/logo-actuel-vet.png"
import LogoArhm from "~/assets/images/logo-arhm.webp"
import logoBnpSmall from "~/assets/images/logo-bnp-small.svg"
import logoBnp from "~/assets/images/logo-bnp.svg"
import CoveaSmall from "~/assets/images/logo-covea-small.webp"
import Covea from "~/assets/images/logo-covea.webp"
import EiffageSmall from "~/assets/images/logo-eiffage-small.svg"
import Eiffage from "~/assets/images/logo-eiffage.svg"
import EpiCenter from "~/assets/images/logo-epi-center.png"
import Epsa from "~/assets/images/logo-epsa.jpeg"
import GroupRGSmall from "~/assets/images/logo-group-rg-small.jpeg"
import GroupRG from "~/assets/images/logo-group-rg.svg"
import KingFisher from "~/assets/images/logo-kingfisher.svg"
import LeroyMerlin from "~/assets/images/logo-leroy-merlin.png"
import Loreal from "~/assets/images/logo-loreal.png"
import MaisonDuMonde from "~/assets/images/logo-maison-du-monde.png"
import MongoDB from "~/assets/images/logo-mongodb.svg"
import SecheSmall from "~/assets/images/logo-seche-small.jpeg"
import Seche from "~/assets/images/logo-seche.webp"
import SezaneSmall from "~/assets/images/logo-sezane-small.svg"
import Sezane from "~/assets/images/logo-sezane.svg"
import logoStellantisSmall from "~/assets/images/logo-stellantis-small.png"
import logoStellantis from "~/assets/images/logo-stellantis.svg"
import Urcoopa from "~/assets/images/logo-urcoopa.png"
import Vbl from "~/assets/images/logo-vbl.png"
import MobilisSmall from "~/assets/images/mobilis-small.png"
import Mobilis from "~/assets/images/mobilis.webp"
import Ncc from "~/assets/images/ncc.svg"
import VeoliaSmall from "~/assets/images/veolia-small.svg"
import Veolia from "~/assets/images/veolia.webp"
import VivisolSmall from "~/assets/images/vivisol-small.webp"
import Vivisol from "~/assets/images/vivisol.webp"
import { LogoFlowie } from "~/components/OrganizationLogo/LogoFlowie"
import { LogoUcpa } from "~/components/OrganizationLogo/LogoUcpa"
import { useFetchOrganization } from "~/store/organization/hooks"
import { getInitials } from "~/store/organization/utils"
import { eiffageOrgs, flowieOrgs, secheOrgs, veoliaOrgs } from "~/utils/featureFlag"

const eiffageIds = Object.keys(eiffageOrgs || {})
const veoliaIds = Object.keys(veoliaOrgs || {})
const secheIds = Object.keys(secheOrgs || {})

const companyLogoConfig = {
    "664fb0bd62a6d65e3a13b511": { large: Edf, small: EdfSmall },
    "6668d1c72ba07a41e2467ff0": { large: Vivisol, small: VivisolSmall },
    ...Object.fromEntries(veoliaIds.map((id) => [id, { large: Veolia, small: VeoliaSmall }])),
    "66749bed4d459a2ad69b16ec": { large: Mobilis, small: MobilisSmall },
    ...Object.fromEntries(secheIds.map((id) => [id, { large: Seche, small: SecheSmall }])),
    "668dde162195b44a5119c484": { large: Covea, small: CoveaSmall },
    "66d60836c64c6a1546dfc928": { large: Cndg, small: CndgSmall },
    "66d96aaac64c6a1546dfc938": { large: Cpfa, small: CpfaSmall },
    "66d96224c64c6a1546dfc936": { large: Ghdc, small: GhdcSmall },
    "65ca1bdac70e2c64a7bd73c8": { large: Helora, small: HeloraSmall },
    "66d96d74c64c6a1546dfc93a": { large: StVith, small: StVithSmall },
    "671a5f422c738165af24a906": { large: Ncc, small: Ncc },
    "67287572bdc6ff7a9a787e17": { large: LogoArhm, small: LogoArhm },
    ...Object.fromEntries(eiffageIds.map((id) => [id, { large: Eiffage, small: EiffageSmall }])),
    "672b824fbdc6ff7a9a787ebb": { large: logoStellantis, small: logoStellantisSmall },
    "672b86d9bdc6ff7a9a787ebf": { large: logoBnp, small: logoBnpSmall },
    "67862f6d5986e16b181f5d10": { large: KingFisher, small: KingFisher },
    "67403e006b832228b77b3a7c": { large: Urcoopa, small: Urcoopa },
    "67977625f4d36163a44d40a1": { large: Epsa, small: Epsa },
    "66d0be41c64c6a1546dfc91e": { large: MongoDB, small: MongoDB },
    "67bc34b03e841b69b6d3fbc6": { large: Loreal, small: Loreal },
    "67bc34e83e841b69b6d3fbc8": { large: GroupRG, small: GroupRGSmall },
    "6670368d4d459a2ad69b16ca": { large: MaisonDuMonde, small: MaisonDuMonde },
    "67d1b30e06046658e878fd31": { large: LeroyMerlin, small: LeroyMerlin },
    "67d1b5ce06046658e878fd33": { large: ActuelVet, small: ActuelVet },
    "67d83ca5bc19ab656b975dc6": { large: EpiCenter, small: EpiCenter },
    "67d2ac5506046658e878fd39": { large: Vbl, small: Vbl },
}
const companyLogoWithoutPaddingConfig = {
    "67474d746b832228b77b3aa2": { large: Sezane, small: SezaneSmall },
}

const UPCAId = "66433572ebfa732fb3f5a59d"
const FlowieIds = Object.keys(flowieOrgs)

const Logo = ({
    logo,
    orgId,
    iconOnly,
    className,
}: {
    logo: { large: string; small: string }
    orgId: string
    iconOnly?: boolean
    className?: string
}) => {
    const logoSrc = iconOnly ? logo.small : logo.large

    return <img src={logoSrc} alt={orgId} className={className} />
}

interface OrganizationLogoProps {
    organisationId: string
    iconOnly?: boolean
    defaultLogo?: React.ReactNode
}

export const OrganizationLogo: React.FC<OrganizationLogoProps> = ({ organisationId, iconOnly, defaultLogo }) => {
    const { organization } = useFetchOrganization(organisationId)

    if (organisationId in companyLogoWithoutPaddingConfig) {
        return (
            <figure>
                <Logo
                    logo={companyLogoWithoutPaddingConfig[organisationId]}
                    orgId={organisationId}
                    iconOnly={iconOnly}
                    className="p-0"
                />
            </figure>
        )
    }

    if (organisationId in companyLogoConfig) {
        return (
            <figure>
                <Logo logo={companyLogoConfig[organisationId]} orgId={organisationId} iconOnly={iconOnly} />
            </figure>
        )
    }

    if (organisationId === UPCAId) {
        return (
            <figure>
                <LogoUcpa iconOnly={iconOnly} />
            </figure>
        )
    }

    if (FlowieIds.includes(organisationId)) {
        return (
            <figure>
                <LogoFlowie iconOnly={iconOnly} className="ps-12" />
            </figure>
        )
    }

    return <figure>{defaultLogo || getInitials(organization?.name ?? "")}</figure>
}
