import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorizationAndOrganizationId as prepareHeaders } from "~/api/prepareHeaders"
import { taggedBaseQuery } from "~/api/rtkFetchBaseExtend"
import { PermissionId } from "~/domains/identity/roles-permissions/types/RolesPermissions"
import { OrganizationId } from "~/types"

const BASE_URL = import.meta.env.VITE_API_ROLES_PERMISSIONS_URL

export const permissionsApi = createApi({
    reducerPath: "permissionsApi",
    baseQuery: taggedBaseQuery({
        baseQueryFn: fetchBaseQuery({
            baseUrl: BASE_URL,
            prepareHeaders,
        }),
        domain: "identity",
        subdomain: "permissions",
    }),
    endpoints: (builder) => ({
        getPermissions: builder.query<PermissionId[], OrganizationId>({
            query: (organizationId: OrganizationId) => ({
                url: `organizations/${organizationId}/permissions`,
            }),
        }),
    }),
})

export const { useGetPermissionsQuery } = permissionsApi
