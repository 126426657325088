import { AlignJustify } from "react-feather"

import { SetPartnershipConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { RegularNode } from "~/domains/orchestration/flows/components/nodes"
import { adaptEntityTriggerProperties } from "~/domains/orchestration/flows/core"
import { adaptEventTriggerProperties } from "~/domains/orchestration/flows/core"
import { isEventTriggerType } from "~/domains/orchestration/flows/types"
import { Trigger } from "~/domains/orchestration/flows/types"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    EditorNode,
    Node,
    NodeType,
    SetPartnershipFieldNode as SetPartnershipFieldNodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const setPartnershipFieldNode = (trigger: Trigger): Omit<SetPartnershipFieldNodeType, keyof Node> => {
    const { objectId } = isEventTriggerType(trigger)
        ? adaptEventTriggerProperties(trigger.type, trigger.slug)
        : adaptEntityTriggerProperties(trigger.type, trigger.slug)
    return {
        partnershipId: objectId,
        fieldToUpdate: ``,
        valueToSet: ``,
        nextNode: null,
        type: NodeType.SET_PARTNERSHIP_FIELD_NODE,
    }
}

const advancedFields: AllAdvancedFieldsUnion[] = [
    AllAdvancedFieldsUnion.FIELD_TO_UPDATE,
    AllAdvancedFieldsUnion.VALUE_TO_SET,
    AllAdvancedFieldsUnion.PARTNERSHIP_ID,
]

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.SET_PARTNERSHIP_FIELD_NODE) return false
    return Boolean(node.partnershipId && node.fieldToUpdate && node.valueToSet)
}

const setPartnershipFieldConfig: Configuration<NodeType.SET_PARTNERSHIP_FIELD_NODE> = {
    type: NodeType.SET_PARTNERSHIP_FIELD_NODE,
    baseSlug: "set-partnership-field",
    Icon: AlignJustify,
    Configuration: SetPartnershipConfiguration,
    advancedFields,
    Node: RegularNode,
    factory: (props: FactoryProps): SetPartnershipFieldNodeType => ({
        ...setPartnershipFieldNode(props.trigger),
        ...createCommonNodeProperties(props),
    }),
    validateNode,
}

export { setPartnershipFieldConfig, advancedFields as setPartnershipFieldAdvancedFields }
