import dayjs, { Dayjs } from "dayjs"

import {
    BankTransferFormState,
    BankTransferPaymentMethod,
    CardFormState,
    CardPaymentMethod,
    CreatePaymentMethodDetails,
    DirectDebitFormState,
    DirectDebitPaymentMethod,
    FormState,
    PaymentMethodDetails,
    PaymentMethodDetailsStatus,
} from "~/domains/payment/payment-method-details/types"
import { PaymentMethodType } from "~/domains/payment/payment-methods/types"
import { CountriesByName, CountryCode, CurrencyCodes, isValidCountryCode, OrganizationId } from "~/types"

const getMonthAndYearAsNumbers = (date?: Dayjs | null): { month: number; year: number } => {
    if (!date) {
        return { month: 0, year: 0 }
    }

    const month = date.month() + 1
    const year = date.year()

    return { month, year }
}

const getCardNumberParts = (cardNumber: string): { firstSix: string; lastFour: string } => {
    const sanitizedCardNumber = cardNumber.replace(/\s+/g, "")
    const firstSix = sanitizedCardNumber.substring(0, 6)
    const lastFour = sanitizedCardNumber.substring(sanitizedCardNumber.length - 4)

    return { firstSix, lastFour }
}

const getCountryCode = (country: CountryCode | string | null): CountryCode | null => {
    if (!country) {
        return null
    }

    if (country && isValidCountryCode(country)) {
        return country as CountryCode
    }

    return CountriesByName[country] ?? CountryCode.FR
}

export const mapToSave = (
    formData: FormState,
    organizationId: OrganizationId,
    id?: string
): CreatePaymentMethodDetails | PaymentMethodDetails => {
    const basePaymentMethod = {
        payment_method_id: formData.paymentMethodId,
        owner: organizationId,
        currency: formData.currency,
        country: formData.country,
        status: PaymentMethodDetailsStatus.PENDING,
        ...(id ? { id } : {}),
        ...(formData.kid && formData.country === CountryCode.NO ? { kid: formData.kid } : {}),
    }

    switch (formData.paymentMethodType) {
        case PaymentMethodType.CARD: {
            const cardFormData = formData as CardFormState
            const { month, year } = getMonthAndYearAsNumbers(cardFormData.cardExpirationDate)
            const { firstSix, lastFour } = getCardNumberParts(cardFormData.cardNumber)

            return {
                ...basePaymentMethod,
                card_category: cardFormData.cardCategory,
                card_expiration_month: month,
                card_expiration_year: year,
                card_first_6_digits: firstSix,
                card_last_4_digits: lastFour,
                card_nickname: cardFormData.name ?? "",
                card_token: "",
            } as CardPaymentMethod
        }

        case PaymentMethodType.BANK_TRANSFER: {
            const bankTransferFormData = formData as BankTransferFormState

            return {
                ...basePaymentMethod,
                bank_account_number: bankTransferFormData.bankAccountNumber ?? "",
                bank_name: bankTransferFormData.bankName ?? "",
                bank_key: bankTransferFormData.bankKey ?? "",
                sort_code: bankTransferFormData.sortCode ?? "",
                iban: bankTransferFormData.iban ?? "",
                bic_swift: bankTransferFormData.bicSwift ?? "",
                holder_name: bankTransferFormData.holderName ?? "",
            } as BankTransferPaymentMethod
        }
        case PaymentMethodType.DIRECT_DEBIT: {
            const directDebitFormData = formData as DirectDebitFormState

            return {
                ...basePaymentMethod,
                mandate_reference: directDebitFormData.mandateReference,
                mandate_date: directDebitFormData.mandateDate?.format("YYYY-MM-DD") ?? "",
                mandate_status: directDebitFormData.mandateStatus,
                bank_account_number: directDebitFormData.bankAccountNumber,
                holder_name: directDebitFormData.holderName,
                bank_name: directDebitFormData.bankName,
            } as DirectDebitPaymentMethod
        }

        default:
            throw new Error(`Unsupported payment method type: ${formData.paymentMethodType}`)
    }
}

export const mapToEdit = (data: PaymentMethodDetails): FormState => {
    const basePaymentMethod = {
        id: data.id,
        paymentMethodId: data.payment_method_id,
        paymentMethodType: data.payment_method_type,
        currency: data.currency ?? CurrencyCodes.EUR,
        country: getCountryCode(data?.country),
        kid: data.kid ?? "",
    }

    switch (data.payment_method_type) {
        case PaymentMethodType.CARD: {
            const {
                card_category,
                card_expiration_year,
                card_expiration_month,
                card_first_6_digits,
                card_last_4_digits,
                card_nickname,
            } = data as CardPaymentMethod
            return {
                ...basePaymentMethod,
                cardCategory: card_category,
                cardExpirationDate: dayjs(`${card_expiration_year}-${card_expiration_month}-01`).isValid()
                    ? dayjs(`${card_expiration_year}-${card_expiration_month}-01`)
                    : null,
                cardNumber: `${card_first_6_digits}${card_last_4_digits}`,
                name: card_nickname,
            } as CardFormState
        }
        case PaymentMethodType.BANK_TRANSFER: {
            const { bank_account_number, bank_name, iban, bic_swift, holder_name, bank_key, sort_code } =
                data as BankTransferPaymentMethod
            return {
                ...basePaymentMethod,
                bankAccountNumber: bank_account_number,
                bankName: bank_name,
                bankKey: bank_key,
                sortCode: sort_code,
                iban: iban,
                bicSwift: bic_swift,
                holderName: holder_name,
            } as BankTransferFormState
        }
        case PaymentMethodType.DIRECT_DEBIT: {
            const { mandate_date, mandate_reference, mandate_status, holder_name, bank_account_number, bank_name } =
                data as DirectDebitPaymentMethod

            return {
                ...basePaymentMethod,
                mandateReference: mandate_reference,
                mandateStatus: mandate_status,
                mandateDate: dayjs(mandate_date).isValid() ? dayjs(mandate_date) : null,
                holderName: holder_name,
                bankAccountNumber: bank_account_number,
                bankName: bank_name,
            } as DirectDebitFormState
        }

        default:
            throw new Error("Unsupported payment method type")
    }
}
