import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { taggedBaseQuery } from "~/api/rtkFetchBaseExtend"
import { organisationFromApiAdapter } from "~/domains/identity/organization/api/adapters/organisationFromApiAdapter"
import { GetOrganizationByIdQueryParams } from "~/domains/identity/organization/api/types/organizationV2Api.types"
import {
    IOrganisationBusinessUnit,
    IOrganisationEstablishmentResponse,
    IOrganizationLegalInfo,
} from "~/domains/identity/organization/types/OrganisationV2Types"
import { OrganizationI } from "~/types"

import {
    businessUnitFromApiAdapter,
    organisationEstablishmentFromApiAdapter,
} from "./adapters/organisationEstablishmentFromApiAdapter"

const BASE_URL = import.meta.env.VITE_API_ORG_V2_URL

export const organizationV2Api = createApi({
    reducerPath: "organizationV2Api",
    baseQuery: taggedBaseQuery({
        baseQueryFn: fetchBaseQuery({
            baseUrl: BASE_URL,
        }),
        domain: "identity",
        subdomain: "organzations",
    }),
    tagTypes: ["Organization", "OrganizationLegalInfo", "OrganizationOffices", "OrganizationBusinessUnits"],
    endpoints: (builder) => ({
        getOrganizationById: builder.query<OrganizationI, GetOrganizationByIdQueryParams>({
            query: ({ organizationId }) => ({
                url: `/organizations/${organizationId}`,
            }),
            providesTags: ["Organization"],
            transformResponse: organisationFromApiAdapter,
        }),
        getOrganizationEstablishmentBySiret: builder.query<IOrganisationEstablishmentResponse, { siret: string }>({
            query: ({ siret }) => ({
                url: `/organization-relationships/establishments/${siret}`,
            }),
            providesTags: ["Organization"],
            transformResponse: (response: IOrganisationEstablishmentResponse) => {
                return organisationEstablishmentFromApiAdapter(response)
            },
        }),
        getOrganizationLegalInfo: builder.query<IOrganizationLegalInfo, { organizationId: string }>({
            query: ({ organizationId }) => ({
                url: `/organizations/legal-info/${organizationId}`,
            }),
            providesTags: ["OrganizationLegalInfo"],
        }),
        getOrganizationEstablishmentsBySiren: builder.query<IOrganisationEstablishmentResponse[], { siren: string }>({
            query: ({ siren }) => ({
                url: `/organization-relationships/siren-establishments/${siren}`,
            }),
            providesTags: ["OrganizationOffices"],
            transformResponse: (response: IOrganisationEstablishmentResponse[]) => {
                return response.map((establishment) => organisationEstablishmentFromApiAdapter(establishment))
            },
        }),
        getOrganisationBusinessUnits: builder.query<IOrganisationBusinessUnit[], { organizationId: string }>({
            query: ({ organizationId }) => ({
                url: `/organizations/${organizationId}/business-units`,
            }),
            providesTags: ["OrganizationBusinessUnits"],
            transformResponse: (response: IOrganisationBusinessUnit[]) => {
                return response.map((businessUnit) => businessUnitFromApiAdapter(businessUnit))
            },
        }),
    }),
})

export const {
    useGetOrganizationByIdQuery,
    useLazyGetOrganizationByIdQuery,
    useGetOrganizationEstablishmentBySiretQuery,
    useGetOrganizationLegalInfoQuery,
    useGetOrganizationEstablishmentsBySirenQuery,
    useGetOrganisationBusinessUnitsQuery,
} = organizationV2Api
