import { ApiFlowId } from "~/domains/orchestration/flows/types"
import { Opaque } from "~/utils"

export type ApiRunId = Opaque<string, { readonly T: unique symbol }>

export enum ApiRunStatus {
    CANCELED = "Canceled",
    FAILED = "Failed",
    FINISHED = "Finished",
    QUEUED = "Queued",
    RUNNING = "Running",
    SUSPENDED = "Suspended",
}

export type ApiRunItem = {
    id: ApiRunId
    status: ApiRunStatus
    startedAt: string
    finishedAt?: string
}

export type ApiRuns = {
    runs: ApiRunItem[]
}

export type ApiRun = {
    id: ApiRunId
    flowId: ApiFlowId
    flowVersion: number
    status: ApiRunStatus
    state: {
        pathTaken: string[]
        storage: Record<string, unknown>
    }
    advancedError?: string
    error?: string
    startedAt: string
    finishedAt?: string
}
