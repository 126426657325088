import { CreditCard } from "react-feather"

import { FitsToBudgetConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { FitsToBudgetNode } from "~/domains/orchestration/flows/components/nodes"
import { DEFAULT_CURRENCY } from "~/domains/orchestration/flows/constants"
import { adaptEntityTriggerProperties, adaptEventTriggerProperties } from "~/domains/orchestration/flows/core"
import {
    AllAdvancedFieldsUnion,
    Configuration,
    CurrencyConversion,
    FactoryProps,
    Trigger,
    TriggerMode,
} from "~/domains/orchestration/flows/types"
import {
    EditorNode,
    FitsToBudgetNode as FitsToBudgetNodeType,
    Node,
    NodeType,
} from "~/domains/orchestration/flows/types/"
import { getAmountPath } from "~/domains/orchestration/flows/utils/editor"

import { createCommonNodeProperties } from "./commonConfig"

const fitsToBudgetNode = (
    trigger: Trigger,
    currencyConversion: CurrencyConversion | null
): Omit<FitsToBudgetNodeType, keyof Node> => {
    const { transactionType, objectId } =
        trigger.mode === TriggerMode.EVENT
            ? adaptEventTriggerProperties(trigger.type, trigger.slug)
            : adaptEntityTriggerProperties(trigger.type, trigger.slug)

    const hasCurrencyConversion = currencyConversion !== null
    const currency = hasCurrencyConversion ? currencyConversion.targetCurrency || DEFAULT_CURRENCY : DEFAULT_CURRENCY

    return {
        amount: getAmountPath(trigger, currency) || "",
        budgetId: ` `,
        transactionId: objectId,
        transactionType,
        currency: `'EUR'`,
        nextIfSuccess: null,
        nextIfFailure: null,
        type: NodeType.FITS_TO_BUDGET_NODE,
    }
}

const advancedFields: AllAdvancedFieldsUnion[] = [
    AllAdvancedFieldsUnion.AMOUNT,
    AllAdvancedFieldsUnion.TRANSACTION_ID,
    AllAdvancedFieldsUnion.TRANSACTION_TYPE,
    AllAdvancedFieldsUnion.CURRENCY,
]

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.FITS_TO_BUDGET_NODE) return false
    return Boolean(node.budgetId && node.transactionType)
}

const fitsToBudgetConfig: Configuration<NodeType.FITS_TO_BUDGET_NODE> = {
    type: NodeType.FITS_TO_BUDGET_NODE,
    baseSlug: "fits_to_budget",
    Icon: CreditCard,
    Configuration: FitsToBudgetConfiguration,
    advancedFields,
    Node: FitsToBudgetNode,
    factory: (props: FactoryProps): FitsToBudgetNodeType => ({
        ...fitsToBudgetNode(props.trigger, props.currencyConversion),
        ...createCommonNodeProperties(props),
    }),
    validateNode,
}
export { fitsToBudgetConfig, advancedFields as fitsToBudgetAdvancedFields }
