import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorizationAndOrganizationId as prepareHeaders } from "~/api/prepareHeaders"
import { ExtendedFetchBaseQueryError, taggedBaseQuery } from "~/api/rtkFetchBaseExtend"
import { commonMessages } from "~/common-messages"
import { bookOfRelationsActions } from "~/domains/identity/partners/store/bookOfRelationsSlice"
import {
    ComplianceProcessCreateDto,
    ComplianceProcessReadDto,
    ComplianceProcessUpdateDto,
    ElliproComplianceRiskI,
    LsegComplianceRiskI,
} from "~/domains/identity/partners/types/OrganizationComplianceRisk"
import { PartnersDataI, PartnersReadDto, PartnershipI } from "~/domains/identity/partners/types/Partners"
import { store } from "~/store"
import { OrganizationId } from "~/types"

const BASE_URL = import.meta.env.VITE_API_BOOK_OF_RELATIONS_URL + "v1"

export const partnersApiV1 = createApi({
    reducerPath: "partnersApiV1",
    baseQuery: taggedBaseQuery({
        baseQueryFn: fetchBaseQuery({
            baseUrl: BASE_URL,
            prepareHeaders,
        }),
        domain: "identity",
        subdomain: "partners",
    }),
    tagTypes: ["Partnership", "ComplianceProcess"],
    endpoints: (builder) => ({
        getPartnership: builder.query<PartnershipI[], { organizationId: string; partnerId: string }>({
            query: ({ organizationId, partnerId }) => ({
                url: `/organizations/${organizationId}/partners/${partnerId}`,
            }),
            providesTags: ["Partnership"],
        }),
        getPartners: builder.query<PartnersDataI[], PartnersReadDto>({
            query: ({ organizationId, withMetrics, withDetails }) => {
                if (!organizationId) {
                    return { url: "" }
                }

                return {
                    url: `/organizations/${organizationId}/partners`,
                    method: "GET",
                    params: {
                        ...(withMetrics && { withMetrics: true }),
                        ...(withDetails && { withDetails: true }),
                    },
                }
            },
            transformResponse: (response: PartnersDataI[]) => {
                const organizationNames: Record<OrganizationId, string> = {}

                response.forEach((partner: PartnersDataI) => {
                    organizationNames[partner.organizationId] = partner.brandName || partner.organizationName
                })
                store.dispatch(bookOfRelationsActions.setPartnerBrandNames(organizationNames))
                store.dispatch(bookOfRelationsActions.setPartners(response))

                return response
            },
        }),
        getComplianceProcessesByIds: builder.query<
            ElliproComplianceRiskI | LsegComplianceRiskI,
            ComplianceProcessReadDto
        >({
            query: ({ organizationId, complianceProcessId, complianceProcess, internalIds }) => {
                if (!organizationId || (!internalIds?.length && !complianceProcessId)) {
                    return { url: "" }
                }

                let url = `/organizations/${organizationId}/${complianceProcess}`

                if (complianceProcessId) {
                    url += `/${complianceProcessId}`
                }

                return {
                    url,
                    method: "GET",
                    params: internalIds ? { internalIds: internalIds.join(",") } : undefined,
                }
            },
        }),
        getComplianceProcessesByPartnerId: builder.query<
            ElliproComplianceRiskI | LsegComplianceRiskI,
            ComplianceProcessReadDto
        >({
            query: ({ organizationId, partnerId, complianceProcess }) => {
                if (!organizationId || !partnerId) {
                    return { url: "" }
                }

                return {
                    url: `/organizations/${organizationId}/partners/${partnerId}/${complianceProcess}`,
                    method: "GET",
                }
            },
            providesTags: ["ComplianceProcess"],
        }),
        createPartnerComplianceRisk: builder.mutation<void, ComplianceProcessCreateDto>({
            query: ({ organizationId, partnerId, complianceProcess, complianceRiskDto }) => ({
                url: `/organizations/${organizationId}/partners/${partnerId}/${complianceProcess}`,
                method: "POST",
                body: complianceRiskDto,
            }),
            invalidatesTags: ["ComplianceProcess"],
            transformErrorResponse: (response: ExtendedFetchBaseQueryError) => ({
                ...response,
                meta: {
                    ...response.meta,
                    errorMessage: commonMessages.error,
                },
            }),
        }),
        updatePartnerComplianceRisk: builder.mutation<void, ComplianceProcessUpdateDto>({
            query: ({ organizationId, processId, complianceProcess, complianceRiskDto }) => ({
                url: `/organizations/${organizationId}/${complianceProcess}/${processId}`,
                method: "PATCH",
                body: complianceRiskDto,
            }),
            invalidatesTags: ["ComplianceProcess"],
            transformErrorResponse: (response: ExtendedFetchBaseQueryError) => ({
                ...response,
                meta: {
                    ...response.meta,
                    errorMessage: commonMessages.error,
                },
            }),
        }),
    }),
})

export const {
    useGetPartnersQuery,
    useLazyGetPartnersQuery,
    useGetComplianceProcessesByIdsQuery,
    useGetComplianceProcessesByPartnerIdQuery,
    useCreatePartnerComplianceRiskMutation,
    useUpdatePartnerComplianceRiskMutation,
    useGetPartnershipQuery,
} = partnersApiV1
