import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react"
import { defineMessages } from "react-intl"

import { commonMessages } from "~/common-messages"
import { Button, Modal, SafeFormattedMessage } from "~/components"
import { TagsSelector } from "~/domains/analytics/tags/components/TagsSelector"
import { useOrganizationTagGroups } from "~/domains/analytics/tags/hooks"
import { TagI } from "~/domains/analytics/tags/types"
import "~/domains/transactions/invoices/_views/buyer/assets/css/Invoice.scss"
import { PurchaseOrders } from "~/domains/transactions/purchase-orders/types"
import { selectUser } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"
import { DocumentI, OrganizationI } from "~/types"

const messages = defineMessages({
    modalTitle: {
        id: "account.documents.modal.addTags.title",
        defaultMessage: "Add tags to selected invoice{s} ({number})",
    },
    stepAddTags: { id: "account.documents.modal.addTags.stepTags.title", defaultMessage: "Tag {those} invoice{s}" },
    addTags: { id: "account.documents.modal.addTags.button.addTags", defaultMessage: "Add tags" },
})

const isDocumentI = (object: DocumentI | PurchaseOrders): object is DocumentI => {
    return "invoiceId" in object
}
const isPurchaseOrder = (object: DocumentI | PurchaseOrders): object is PurchaseOrders => {
    return "id" in object
}

const initialSelected = [] as const

interface Props {
    organization: OrganizationI
    selected?: readonly string[]
    objects: DocumentI[] | PurchaseOrders[]
    displayModal: boolean
    setDisplayModal: Dispatch<SetStateAction<boolean>>
    onConfirm: (tagsToAdd: TagI[], tagsToRemove: TagI[]) => void
    onClose?: () => void
}

export function ModalAddTags({
    organization,
    selected = initialSelected,
    objects,
    displayModal,
    setDisplayModal,
    onConfirm,
    onClose,
}: Props) {
    const initialTags = useRef<TagI[]>([])
    const [selectedTags, setSelectedTags] = useState<TagI[]>([])

    const user = useAppSelector(selectUser)

    const { tagGroups } = useOrganizationTagGroups(organization.id)

    useEffect(() => {
        if (!selected.length || !objects.length) return

        const hasTags = objects?.[0]?.tags
        if (!hasTags) return

        const selectedObjects = objects.filter(
            (object) =>
                (isDocumentI(object) && selected.includes(object.invoiceId)) ||
                (isPurchaseOrder(object) && selected.includes(object.id))
        )

        const commonTagsIds = selectedObjects.reduce(
            (commonTags, object) => {
                return commonTags.filter((tagId) => object.tags?.some((objectTag) => objectTag.tagId === tagId))
            },
            (selectedObjects.length && selectedObjects[0].tags?.map((tag) => tag.tagId)) || []
        )

        const commonTags = tagGroups?.flatMap((tagGroup) =>
            tagGroup.tags.filter((tag) => commonTagsIds.includes(tag.tagId))
        )

        if (commonTags) {
            initialTags.current = commonTags
            setSelectedTags(commonTags)
        }
    }, [tagGroups, selected, objects])

    /* This effect only takes objects as used in PurchaseOrdersBulkActions
     * prefer use this effect instead of the one above
     */
    useEffect(() => {
        if (!objects.length) return

        const hasTagIds = objects?.[0]?.tagIds
        if (!hasTagIds) return

        const selectedObjects = !selected.length
            ? objects
            : objects.filter(
                  (object) =>
                      (isDocumentI(object) && selected.includes(object.invoiceId)) ||
                      (isPurchaseOrder(object) && selected.includes(object.id))
              )

        const initialTagIds = (selectedObjects?.[0]?.tagIds as string[]) || []

        const commonTagsIds = selectedObjects.reduce((acc, obj) => {
            if (!acc.length || !(obj.tagIds as string[]).length) return []

            return acc.filter((tagId) => (obj.tagIds as string[]).includes(tagId))
        }, initialTagIds)

        const commonTags = tagGroups?.flatMap((tagGroup) =>
            tagGroup.tags.filter((tag) => commonTagsIds.includes(tag.tagId))
        )

        if (commonTags) {
            initialTags.current = commonTags
            setSelectedTags(commonTags)
        }
    }, [tagGroups, selected, objects])

    const handleConfirm = useCallback(() => {
        const tagsToAdd = selectedTags.filter(
            (tag) => !initialTags.current.some((initialTag) => initialTag.tagId === tag.tagId)
        )
        const tagsToRemove = initialTags.current.filter(
            (initialTag) => !selectedTags.some((tag) => tag.tagId === initialTag.tagId)
        )
        onConfirm(tagsToAdd, tagsToRemove)
    }, [onConfirm, selectedTags])

    const handleClose = () => {
        setDisplayModal(false)
        onClose?.()
    }

    const nbSelected = selected.length || objects.length

    return (
        <Modal
            open={displayModal}
            onClose={handleClose}
            className="modal-add-tags modal-otp"
            aria-labelledby="modal-add-tags modal-otp"
        >
            <Modal.Header>
                <h4>
                    <SafeFormattedMessage
                        {...messages.modalTitle}
                        values={{
                            s: nbSelected > 1 ? "s" : "",
                            number: nbSelected,
                        }}
                    />
                </h4>
            </Modal.Header>

            <Modal.Content>
                {organization && user.id ? (
                    <TagsSelector
                        organizationId={organization.id}
                        selectedTags={selectedTags}
                        setSelectedTags={setSelectedTags}
                        tagsRecommandations={null}
                    />
                ) : null}
            </Modal.Content>

            <Modal.Footer>
                <Button onClick={handleClose} type="transparent">
                    <SafeFormattedMessage {...commonMessages.cancel} />
                </Button>
                <Button buttonType="submit" onClick={handleConfirm}>
                    <SafeFormattedMessage {...messages.addTags} />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
