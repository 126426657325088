import markerSDK from "@marker.io/browser"
import { useEffect } from "react"

import { UserI } from "~/domains/identity/account/types/UserTypes"
import { OrganizationId } from "~/domains/identity/organization/types/OrganizationTypes"

interface PropsType {
    user: UserI
    organizationId?: OrganizationId
}

export default function MarkerComponent({ user, organizationId }: PropsType) {
    useEffect(() => {
        if (user && user.email && user.email.endsWith("@flowie.fr")) {
            markerSDK
                .loadWidget({
                    project: import.meta.env.VITE_MARKERIO_PROJECT_ID,
                    reporter: {
                        email: user.email,
                        fullName: user.fullName ?? "",
                    },
                    customData: {
                        organizationId: organizationId ?? "",
                        environment: import.meta.env.VITE_ENV,
                    },
                })
                .then()
        }
    }, [user])

    return null
}
