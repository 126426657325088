import { useCallback, useEffect, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { TagObjectRecordI } from "~/domains/analytics/tags/types/TagObjectRecord"
import { OrganizationId } from "~/types"
import { isResultSuccess } from "~/types/Result"

import { parseTagObjectsRecord } from "../parsers/parseTagObjectsRecord"
import { useTagsApi } from "../tagsApi"

const messages = defineMessages({
    errorParsing: {
        id: "account.organization.tags.getObjectsTags.errorParsing",
        defaultMessage: "Error parsing TagObjectRecordI in getObjectsTags function.",
    },
})

export const useGetBulkObjectsTags = (organizationId?: OrganizationId, defaultObjectIds?: string[]) => {
    const { formatMessage } = useIntl()
    const tagsApi = useTagsApi()
    const [objectTags, setObjectTags] = useState<TagObjectRecordI>({})
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string | null>(null)

    const getBulkObjectsTags = useCallback(
        async (objectIds: string[], addNames?: boolean) => {
            if (organizationId && objectIds.length) {
                try {
                    setLoading(true)
                    const response = await tagsApi.getBulkObjectsTags(organizationId, objectIds, addNames)
                    if (response?.status >= 200 && response.status < 400 && !response.data) return
                    const result = parseTagObjectsRecord(response.data)
                    if (isResultSuccess(result)) {
                        setObjectTags(result.result)
                        return result.result
                    }
                    setError(formatMessage(messages.errorParsing))
                } catch (e) {
                    setError(`${e}`)
                    setObjectTags({})
                    throw e
                } finally {
                    setLoading(false)
                }
            }
        },
        [organizationId, setObjectTags, setLoading, setError]
    )

    useEffect(() => {
        if (defaultObjectIds && defaultObjectIds?.length > 0 && organizationId) {
            getBulkObjectsTags(defaultObjectIds, true)
        }
    }, [defaultObjectIds?.length, organizationId])

    return {
        getBulkObjectsTags,
        objectTags,
        loading,
        error,
    }
}
