import {
    Autocomplete,
    AutocompleteInputChangeReason,
    AutocompleteRenderGroupParams,
    AutocompleteRenderInputParams,
} from "@mui/material"
import React, { SyntheticEvent, useCallback, useEffect, useState } from "react"

import { SkeletonInput, contactStyledPopper } from "~/components"
import { RenderInputComponent } from "~/domains/identity/partners/components/PartnerSelectors/PartnerContactInputRendering"
import { RenderGroup } from "~/domains/identity/partners/components/PartnerSelectors/PartnerContactOptionGroupRendering"
import { useFetchPartnerContacts } from "~/domains/identity/partners/store/hooks"
import { usePartnerComputedName } from "~/domains/identity/partners/store/hooks"
import {
    CREATION_ID,
    OptionParams,
    PartnerContactGroup,
    PartnerProfileContactI,
} from "~/domains/identity/partners/types"
import { OrganizationId } from "~/types"

import { RenderOption, filterContactOptions } from "./PartnerContactOptionRendering"

const getOptionLabel = (option: PartnerProfileContactI | string | null): string => {
    if (!option) {
        return ""
    }
    if (typeof option === "string") {
        return option
    }
    return option.email ?? ""
}

const findPartnerContactByEmail = (
    contacts: PartnerProfileContactI[],
    email: string | null | undefined
): PartnerProfileContactI | undefined => {
    if (!email) {
        return undefined
    }
    return contacts?.find((contact) => contact.email === email)
}

interface Props {
    currentOrganizationId: OrganizationId | undefined
    partnerId: OrganizationId | null
    selectedContact: PartnerProfileContactI | null
    onContactChange: (contact: PartnerProfileContactI | null) => void
}

export const PartnerContactsSelector = ({
    currentOrganizationId,
    partnerId,
    selectedContact,
    onContactChange,
}: Props) => {
    const [inputValue, setInputValue] = useState<string>("")
    const { contacts, loading } = useFetchPartnerContacts(currentOrganizationId, partnerId as string)
    const partnerComputedName = usePartnerComputedName(partnerId)

    useEffect(() => {
        const matchedContact = findPartnerContactByEmail(contacts as PartnerProfileContactI[], selectedContact?.email)
        if (matchedContact) {
            onContactChange(matchedContact)
        }
    }, [selectedContact, contacts])

    const handleChange = useCallback(
        (_event: SyntheticEvent<Element, Event>, value: PartnerProfileContactI | string | null) => {
            if (!value) {
                onContactChange(null)
                return
            }
            const contact =
                typeof value === "string"
                    ? findPartnerContactByEmail(contacts as PartnerProfileContactI[], value) ||
                      ({ id: CREATION_ID, email: value } as PartnerProfileContactI)
                    : value
            onContactChange(contact)
        },
        [onContactChange, contacts]
    )

    const handleOnBlur = useCallback(() => {
        if (inputValue) {
            const contact =
                findPartnerContactByEmail(contacts as PartnerProfileContactI[], inputValue) ||
                ({ id: CREATION_ID, email: inputValue } as PartnerProfileContactI)
            onContactChange(contact)
        }
    }, [inputValue, contacts, onContactChange])

    const handleInputChange = useCallback(
        (_event: SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
            setInputValue(!value || reason === "clear" ? "" : value)
        },
        [setInputValue]
    )

    const handleRemove = () => {
        setInputValue("")
        onContactChange(null)
    }

    const isOptionEqualToValue = (option: PartnerProfileContactI, value: PartnerProfileContactI): boolean => {
        return option.id === value.id
    }

    const groupOptions = (option: PartnerProfileContactI) =>
        option.id === CREATION_ID ? PartnerContactGroup.CREATION : PartnerContactGroup.PARTNER

    const renderContactOption = (params: OptionParams, option: PartnerProfileContactI) => (
        <RenderOption key={option.id} params={params} option={option} />
    )

    const renderContactGroup = (params: AutocompleteRenderGroupParams) => (
        <RenderGroup key={params?.group} params={params} partnerCompanyName={partnerComputedName} />
    )

    const renderInput = (params: AutocompleteRenderInputParams) => (
        <RenderInputComponent params={params} selectedContact={selectedContact} handleRemove={handleRemove} />
    )

    if (loading) return <SkeletonInput />

    return (
        <Autocomplete
            disablePortal
            selectOnFocus
            handleHomeEndKeys
            freeSolo
            onBlur={handleOnBlur}
            PopperComponent={contactStyledPopper}
            value={selectedContact}
            isOptionEqualToValue={isOptionEqualToValue}
            onChange={handleChange}
            onInputChange={handleInputChange}
            renderInput={renderInput}
            inputValue={inputValue}
            options={partnerId && contacts ? contacts : []}
            getOptionLabel={getOptionLabel}
            renderOption={renderContactOption}
            filterOptions={filterContactOptions}
            groupBy={groupOptions}
            renderGroup={renderContactGroup}
        />
    )
}
