import {
    AnswerFieldType,
    ApiSurveyRequirements,
    CustomFormSurveyStatus,
    CustomFormSurveyStatusEnum,
    SurveyRequirements,
    UserSurveyAnswer,
    UserSurveyApiResponse,
    UserSurveyMetadata,
} from "~/domains/identity/custom-forms/types/CustomForms"

const adaptCustomFormSurveyStatusFromApi = (status: CustomFormSurveyStatus) => {
    const statuses = Object.keys(CustomFormSurveyStatusEnum)

    for (const s of statuses) {
        if (s in status) {
            return {
                ...status,
                // FIXME
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                status: CustomFormSurveyStatusEnum[s],
                percentage:
                    status[s] && typeof status[s] === "object" && "percentage" in status[s]
                        ? (status[s] as { percentage: number }).percentage
                        : 0,
            }
        }
    }
}

export const adaptUserSurveyFromApi = (response: UserSurveyApiResponse) => {
    const { form, answers, status, linkedObjects } = response
    return {
        createdAt: form.createdAt,
        sections: form.sections, // TODO: adapt sections with questions
        id: form.id,
        name: form.name,
        answers: adaptUserSurveyAnswersFromApi(answers),
        status,
        linkedObjects,
        introductoryText: form.introductoryText,
    }
}

const hasValue = (answer: unknown): answer is { value: unknown } => {
    return typeof answer === "object" && answer !== null && "value" in answer
}

export const adaptUserSurveyAnswersFromApi = (answers: UserSurveyAnswer[]) => {
    const fieldTypes = Object.keys(AnswerFieldType)
    const adaptedAnswers: UserSurveyAnswer[] = []
    for (const fieldType of fieldTypes) {
        for (const surveyAnswer of answers) {
            if (fieldType in surveyAnswer.answer) {
                adaptedAnswers.push({
                    ...surveyAnswer,
                    // FIXME
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    fieldType: AnswerFieldType[fieldType],
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    value: hasValue(surveyAnswer.answer[fieldType])
                        ? surveyAnswer.answer[fieldType].value
                        : surveyAnswer.answer[fieldType],
                })
            }
        }
    }

    return adaptedAnswers
}

export const adaptCustomFormSurveyMetadataFromApi = (response: UserSurveyMetadata) => {
    return {
        ...response,
        status: adaptCustomFormSurveyStatusFromApi(response.status),
    } as UserSurveyMetadata
}

export const adaptSurveyRequirementsFromApi = (response: ApiSurveyRequirements): SurveyRequirements => {
    return {
        linkedObjectType: response.linkedObjectType,
        contextParameters: response.contextParameters.map((parameter) => ({
            key: parameter.key,
            type: parameter.type,
        })),
    }
}
