/* eslint-disable max-lines */
import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit"

import { CustomFieldValueI } from "~/domains/identity/custom-fields/types/CustomFieldValue"
import { WorkflowReviewsI } from "~/domains/orchestration/flows-v0/types"
import { InvoiceCustomFieldsI, InvoiceLineCustomFieldsI } from "~/domains/transactions/invoices/core"
import { RootState } from "~/store"
import { buyerActions } from "~/store/invoice/buyerActions"
import { invoiceState } from "~/store/invoice/invoiceState"
import { supplierActions } from "~/store/invoice/supplierActions"
import {
    ComplianceStatusI,
    CountryCode,
    CreateInvoiceResponseI,
    EventI,
    ImportInvoiceCompanyInfoI,
    ImportingInvoiceI,
    InvoiceBackendVersion,
    InvoiceExportI,
    InvoiceFileLinksI,
    InvoiceI,
    InvoiceId,
    InvoicePaymentDetailsI,
    InvoiceStatus,
    InvoiceUserType,
    InvolvedPeopleI,
    MessageI,
    NotificationI,
    OrderDirection,
    OrganizationId,
    PaymentI,
    UserI,
    UserId,
} from "~/types"
import { FetchError } from "~/utils/apiClient"

const initialState = invoiceState

const invoiceSlice = createSlice({
    name: "invoice",
    initialState: initialState,
    reducers: {
        ...buyerActions,
        ...supplierActions,
        setBuyerId(state, action: PayloadAction<UserId>) {
            if (
                state.invoice &&
                !state.invoice.involvedPeople.some((involvedPerson) => involvedPerson.userId === action.payload)
            ) {
                state.invoice.involvedPeople = [{ userId: action.payload }, ...state.invoice.involvedPeople]
            }
        },
        setUserType(state, action: PayloadAction<InvoiceUserType>) {
            state.userType = action.payload
        },
        setNotification(state, action: PayloadAction<NotificationI>) {
            if (state.invoice) {
                state.invoice.notification = action.payload
            }
        },
        creatingInvoiceStarted(state, action: PayloadAction<string>) {
            state.creatingInvoice = {
                error: null,
                otherError: null,
                invoice: {
                    version: InvoiceBackendVersion.V0,
                    loaded: false,
                    id: action.payload,
                    status: InvoiceStatus.DRAFT,
                    buyerTaxes: [],
                    supplierTaxes: [],
                    buyer: {
                        countryCode: CountryCode.UNKNOWN,
                        name: "",
                        organizationId: null,
                        contactName: "",
                    },
                    supplier: {
                        countryCode: CountryCode.UNKNOWN,
                        name: "",
                        organizationId: null,
                        contactName: "",
                    },
                    reference: "",
                    total: null,
                    totalExcludedTaxes: null,
                    totalDiscount: null,
                },
            }
        },
        setCreatingInvoice(state, action: PayloadAction<CreateInvoiceResponseI | null>) {
            state.creatingInvoice = action.payload
            state.ocrCreated = true
        },
        setOcrCreated(state, action: PayloadAction<boolean>) {
            state.ocrCreated = action.payload
        },
        setInvoice(state, action: PayloadAction<InvoiceI | null>) {
            state.invoice = action.payload
        },
        updatePartialInvoice(state, action: PayloadAction<Partial<InvoiceI>>) {
            if (state.invoice) {
                state.invoice = {
                    ...state.invoice,
                    ...action.payload,
                }
            }
        },
        updatePartialInvoiceBuyer(state, action: PayloadAction<Partial<ImportInvoiceCompanyInfoI>>) {
            if (state.invoice) {
                state.invoice = {
                    ...state.invoice,
                    buyer: {
                        ...state.invoice.buyer,
                        ...action.payload,
                    },
                }
            }
        },
        updatePartialInvoiceSupplier(state, action: PayloadAction<Partial<ImportInvoiceCompanyInfoI>>) {
            if (state.invoice) {
                state.invoice = {
                    ...state.invoice,
                    supplier: {
                        ...state.invoice.supplier,
                        ...action.payload,
                    },
                }
            }
        },
        updatePaymentInvoice(state, action: PayloadAction<Partial<InvoicePaymentDetailsI>>) {
            if (state.invoice) {
                state.invoice = {
                    ...state.invoice,
                    paymentDetails: {
                        ...state.invoice.paymentDetails,
                        ...action.payload,
                    },
                }
            }
        },
        fetchInvoice(state) {
            state.loading = true
            state.fetchError = null
        },
        fetchInvoiceFailed(state) {
            state.loading = false
            // TODO: the error state was **never** used anywhere, I removed it but we might want to handle the error case somehow.
        },
        fetchInvoiceTypedFailed(state, action: PayloadAction<FetchError<InvoiceI>>) {
            state.loading = false
            state.fetchError = action.payload
        },
        addPeople(state, action: PayloadAction<string[]>) {
            state.userIds = [...new Set([...state.userIds, ...action.payload])]
        },
        addCommunication(state, action: PayloadAction<MessageI>) {
            state.communications.unshift(action.payload)
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        fetchCommunication(state, _action: PayloadAction<string>) {
            return
        },
        fetchCommunicationSuccess(state, action: PayloadAction<MessageI[]>) {
            state.communications = action.payload
            state.communicationsLoaded = true
        },
        fetchCommunicationFailed(state) {
            state.communicationsLoaded = true
            // TODO: the error state was **never** used anywhere, I removed it but we might want to handle the error case somehow.
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        fetchEvents(state, _action: PayloadAction<string>) {
            return
        },
        fetchEventsSuccess(state, action: PayloadAction<EventI[]>) {
            state.events = action.payload
            state.eventsLoaded = true
        },
        fetchEventsFailed(state) {
            state.eventsLoaded = true
            // TODO: the error state was **never** used anywhere, I removed it but we might want to handle the error case somehow.
        },
        setPayment(state, action: PayloadAction<PaymentI>) {
            if (state.invoice) {
                state.invoice.payments = [...(state.invoice.payments ?? []), action.payload]
            }
        },
        reset() {
            return initialState
        },
        resetPartial(state) {
            state.invoice = null
            state.creatingInvoice = null
        },
        markedAsPaid(state, action: PayloadAction<string>) {
            if (state.invoice) {
                state.invoice.status = InvoiceStatus.MARKED_AS_PAID
                state.invoice.paidAt = action.payload
            }
        },
        removeInvoiceApproval(state) {
            if (state.invoice) {
                state.invoice.status = InvoiceStatus.CONFIRMED
            }
        },
        unmarkAsPaid(state) {
            if (state.invoice) {
                state.invoice.status = InvoiceStatus.VALIDATED
            }
        },
        updatePartialFileLinks(state, action: PayloadAction<Partial<InvoiceFileLinksI>>) {
            if (state.creatingInvoice) {
                state.creatingInvoice.invoice.fileLinks = {
                    ...state.creatingInvoice.invoice.fileLinks,
                    ...action.payload,
                }
            }
            if (state.invoice) {
                state.invoice.fileLinks = {
                    ...state.invoice.fileLinks,
                    ...action.payload,
                }
            }
        },
        addOriginalImageLink(state, action: PayloadAction<{ pageIndex: number; url: string }>) {
            if (state.creatingInvoice) {
                state.creatingInvoice.invoice.fileLinks = {
                    ...state.creatingInvoice.invoice.fileLinks,
                    originalImageLinks: [
                        ...(state.creatingInvoice.invoice.fileLinks?.originalImageLinks ?? []).slice(
                            0,
                            action.payload.pageIndex
                        ),
                        action.payload.url,
                        ...(state.creatingInvoice.invoice.fileLinks?.originalImageLinks ?? []).slice(
                            action.payload.pageIndex + 1
                        ),
                    ],
                }
            }
            if (state.invoice) {
                state.invoice.fileLinks = {
                    ...state.invoice.fileLinks,
                    originalImageLinks: [
                        ...(state.invoice.fileLinks?.originalImageLinks ?? []).slice(0, action.payload.pageIndex),
                        action.payload.url,
                        ...(state.invoice.fileLinks?.originalImageLinks ?? []).slice(action.payload.pageIndex + 1),
                    ],
                }
            }
        },
        setInvoicePagination(state, action: PayloadAction<number>) {
            if (state.creatingInvoice) {
                state.creatingInvoice.invoice.fileLinks = {
                    ...state.creatingInvoice.invoice.fileLinks,
                    originalImageLinks:
                        !state.creatingInvoice.invoice.fileLinks?.originalImageLinks ||
                        state.creatingInvoice.invoice.fileLinks.originalImageLinks.length === 0
                            ? // For every page of the invoice we set the image as an empty string
                              // in order to display the pagination in the UI
                              Array(action.payload).map(() => "")
                            : state.creatingInvoice.invoice.fileLinks.originalImageLinks,
                }
            }
        },
        setImageLinks(state, action: PayloadAction<string[]>) {
            if (state.invoice) {
                state.invoice.fileLinks.imageLinks = action.payload
            }
        },
        markImportingInvoiceErrorAsResolved(state) {
            if (state.creatingInvoice) {
                state.creatingInvoice = null
            }
        },
        setCreatingInvoiceError(state, action: PayloadAction<string | null>) {
            if (state.creatingInvoice) {
                state.creatingInvoice = {
                    ...state.creatingInvoice,
                    otherError: action.payload,
                }
            }
        },
        updatePartialImportingInvoice(state, action: PayloadAction<Partial<ImportingInvoiceI>>) {
            if (state.creatingInvoice) {
                state.creatingInvoice.invoice = {
                    ...state.creatingInvoice.invoice,
                    ...action.payload,
                }
            }
        },
        updatePaymentImportingInvoice(state, action: PayloadAction<Partial<InvoicePaymentDetailsI>>) {
            if (state.creatingInvoice) {
                state.creatingInvoice.invoice = {
                    ...state.creatingInvoice.invoice,
                    paymentDetails: {
                        ...state.creatingInvoice.invoice.paymentDetails,
                        ...action.payload,
                    },
                }
            }
        },
        updatePartialSupplierForImportingInvoice(state, action: PayloadAction<Partial<ImportInvoiceCompanyInfoI>>) {
            if (state.creatingInvoice) {
                state.creatingInvoice.invoice.supplier = {
                    ...state.creatingInvoice.invoice.supplier,
                    ...action.payload,
                }
            }
        },
        updatePartialBuyerForImportingInvoice(state, action: PayloadAction<Partial<ImportInvoiceCompanyInfoI>>) {
            if (state.creatingInvoice) {
                state.creatingInvoice.invoice.buyer = {
                    ...state.creatingInvoice.invoice.buyer,
                    ...action.payload,
                }
            }
        },
        updateDocumentsSortBy(state, action: PayloadAction<string>) {
            state.documentsSortBy.sortBy = action.payload
        },
        updateDocumentsSortDirection(state, action: PayloadAction<OrderDirection.ASC | OrderDirection.DESC>) {
            state.documentsSortBy.direction = action.payload
        },
        fetchWorkflowReviews(state) {
            state.workflowReviews.loading = true
            state.workflowReviews.error = null
        },
        fetchWorkflowReviewsSuccess(state) {
            state.workflowReviews.loading = false
        },
        fetchWorkflowReviewsFailed(state, action: PayloadAction<string>) {
            state.workflowReviews.error = action.payload
        },
        setWorkflowReviews(state, action: PayloadAction<WorkflowReviewsI>) {
            state.workflowReviews.data = action.payload
        },
        fetchWorkflowReviewUsers(state) {
            state.workflowReviews.loading = true
            state.workflowReviews.error = null
        },
        fetchWorkflowReviewUsersSuccess(state) {
            state.workflowReviews.loading = false
        },
        fetchWorkflowReviewUsersFailed(state, action: PayloadAction<string>) {
            state.workflowReviews.error = action.payload
        },
        setWorkflowReviewUsers(state, action: PayloadAction<UserI[]>) {
            state.reviewUsers.data = action.payload
        },
        fetchComplianceStatus(state) {
            state.complianceStatus.loading = true
            state.complianceStatus.error = null
        },
        fetchComplianceStatusSuccess(state) {
            state.complianceStatus.loading = false
        },
        fetchComplianceStatusFailed(state, action: PayloadAction<string>) {
            state.complianceStatus.error = action.payload
        },
        setComplianceStatus(state, action: PayloadAction<ComplianceStatusI[]>) {
            state.complianceStatus.data = action.payload
        },
        fetchInvoiceExports(state, action: PayloadAction<OrganizationId>) {
            state.invoiceExports.organizationId = action.payload
            state.invoiceExports.loading = true
            state.invoiceExports.error = null
        },
        fetchInvoiceExportsSuccess(state, action: PayloadAction<InvoiceExportI[]>) {
            state.invoiceExports.loading = false
            state.invoiceExports.data = action.payload
        },
        fetchInvoiceExportsFailed(state, action: PayloadAction<string>) {
            state.invoiceExports.loading = false
            state.invoiceExports.error = action.payload
        },
        createInvoiceExportSuccess(state, action: PayloadAction<InvoiceExportI>) {
            state.invoiceExports.data.push(action.payload)
        },
        deleteInvoiceExportSuccess(state, action: PayloadAction<string>) {
            state.invoiceExports.data = state.invoiceExports.data.filter(
                (invoiceExport) => invoiceExport.id !== action.payload
            )
        },
        fetchInvoiceById(state, action: PayloadAction<InvoiceId>) {
            state.invoicesById[action.payload] = { invoice: null, loading: true, error: null }
        },
        fetchInvoiceByIdSuccess(state, action: PayloadAction<InvoiceI>) {
            state.invoicesById[action.payload.id] = { invoice: action.payload, loading: false, error: null }
        },
        fetchInvoiceByIdFailed(state, action: PayloadAction<{ invoiceId: InvoiceId; error: string }>) {
            state.invoicesById[action.payload.invoiceId] = {
                invoice: null,
                loading: false,
                error: action.payload.error,
            }
        },
        setInvoiceByIdState(state, action: PayloadAction<InvoiceI>) {
            if (state.invoicesById[action.payload.id]) {
                state.invoicesById[action.payload.id].invoice = action.payload
            }
        },
        resetInvoiceCustomFields(state) {
            state.invoiceCustomFields = null
        },
        setOneInvoiceCustomFields(state, action: PayloadAction<{ key: string; customField: CustomFieldValueI }>) {
            if (!state.invoiceCustomFields) {
                state.invoiceCustomFields = {} as InvoiceCustomFieldsI
            }
            state.invoiceCustomFields[action.payload.key] = action.payload.customField
        },
        setInvoiceCustomFields(state, action: PayloadAction<InvoiceCustomFieldsI>) {
            state.invoiceCustomFields = action.payload
        },
        resetInvoiceLineCustomFields(state) {
            state.invoiceLineCustomFields = {}
        },
        setOneInvoiceLineCustomFields(
            state,
            action: PayloadAction<{ lineId: string; key: string; customField: CustomFieldValueI }>
        ) {
            if (!state.invoiceLineCustomFields[action.payload.lineId]) {
                state.invoiceLineCustomFields[action.payload.lineId] = {} as InvoiceLineCustomFieldsI
            }
            state.invoiceLineCustomFields[action.payload.lineId][action.payload.key] = action.payload.customField
        },
        setInvoiceLineCustomFields(
            state,
            action: PayloadAction<{ customFields: InvoiceLineCustomFieldsI; lineId: string }>
        ) {
            state.invoiceLineCustomFields[action.payload.lineId] = action.payload.customFields
        },
    },
})

// Actions
export const invoiceActions = invoiceSlice.actions

// Selectors
export const selectInvoiceLoading = (state: RootState) => state.invoice.loading
export const selectInvoiceFetchError = (state: RootState) => state.invoice.fetchError
export const selectSendValidation = (state: RootState) => state.invoice.sendingValidation
export const selectValidationSent = (state: RootState) => state.invoice.validationSuccess
export const selectInvoice = (state: RootState) => state.invoice.invoice
export const selectInvoiceStatus = (state: RootState) => state.invoice.invoice?.status
export const selectInvoiceId = (state: RootState) => state.invoice.invoice?.id
export const selectInvoiceCommunications = (state: RootState) => state.invoice.communications
export const selectInvoiceEvents = (state: RootState) => state.invoice.events
export const selectInvoiceUserIds = (state: RootState) => state.invoice.userIds
export const selectSuggestedBuyerCompagnies = (state: RootState) =>
    state.invoice.creatingInvoice?.suggestedBuyerCompanies ?? undefined
export const selectSuggestedSupplierCompagnies = (state: RootState) =>
    state.invoice.creatingInvoice?.suggestedSupplierCompanies ?? undefined
export const selectDocumentsSortBy = (state: RootState) => state.invoice.documentsSortBy.sortBy
export const selectDocumentsSortDirection = (state: RootState) => state.invoice.documentsSortBy.direction
export const selectCreatingInvoice = (state: RootState) => state.invoice.creatingInvoice
export const selectOcrCreated = (state: RootState) => state.invoice.ocrCreated
export const selectInvolvedPeople = (state: RootState): InvolvedPeopleI[] => state.invoice.invoice?.involvedPeople ?? []
export const selectCreatingPaymentDetailsIban = (state: RootState) =>
    state.invoice.creatingInvoice?.paymentDetails?.iban

const selectWorkflowReviewsData = (state: RootState) => state.invoice.workflowReviews.data
const selectWorkflowReviewsLoading = (state: RootState) => state.invoice.workflowReviews.loading
const selectWorkflowReviewsError = (state: RootState) => state.invoice.workflowReviews.error
export const selectWorkflowReviews = createSelector(
    [selectWorkflowReviewsData, selectWorkflowReviewsLoading, selectWorkflowReviewsError],
    (workflowReviews, loading, error) => ({
        workflowReviews,
        loading,
        error,
    })
)

export const selectWorkflowReviewUsersData = (state: RootState) => state.invoice.reviewUsers
const selectWorkflowReviewUsersLoading = (state: RootState) => state.invoice.reviewUsers.loading
const selectWorkflowReviewUsersError = (state: RootState) => state.invoice.reviewUsers.error
export const selectWorkflowReviewUsers = createSelector(
    [selectWorkflowReviewUsersData, selectWorkflowReviewUsersLoading, selectWorkflowReviewUsersError],
    (reviewUsers, loading, error) => ({
        reviewUsers,
        loading,
        error,
    })
)

export const selectComplianceStatusData = (state: RootState) => state.invoice.complianceStatus.data
const selectComplianceStatusLoading = (state: RootState) => state.invoice.complianceStatus.loading
const selectComplianceStatusError = (state: RootState) => state.invoice.complianceStatus.error
export const selectComplianceStatus = createSelector(
    [selectComplianceStatusData, selectComplianceStatusLoading, selectComplianceStatusError],
    (complianceStatuses, loading, error) => ({
        complianceStatuses,
        loading,
        error,
    })
)

export const selectInvoiceExportsData = (state: RootState) => state.invoice.invoiceExports.data
const selectInvoiceExportsLoading = (state: RootState) => state.invoice.invoiceExports.loading
const selectInvoiceExportsError = (state: RootState) => state.invoice.invoiceExports.error
const selectInvoiceExportsOrganizationId = (state: RootState) => state.invoice.invoiceExports.organizationId
export const selectInvoiceExports = createSelector(
    [
        selectInvoiceExportsData,
        selectInvoiceExportsLoading,
        selectInvoiceExportsError,
        selectInvoiceExportsOrganizationId,
    ],
    (invoiceExports, loading, error, organizationId) => ({
        invoiceExports,
        loading,
        error,
        organizationId,
    })
)

export const selectInvoicesById = (state: RootState) => state.invoice.invoicesById
export const selectInvoiceCustomFields = (state: RootState) => state.invoice.invoiceCustomFields
export const selectInvoiceLineCustomFields = (state: RootState) => state.invoice.invoiceLineCustomFields

// Reducer
const invoiceReducer = invoiceSlice.reducer
export default invoiceReducer
