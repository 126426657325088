export const lifecycleStatuses = {
    default: {
        color: "var(--color-grey)",
        icon: "solar:file-bold-duotone",
    },
    rejected: {
        icon: "solar:bell-bing-bold-duotone",
        color: "var(--color-red)",
    },
    validated: {
        icon: "solar:check-circle-broken",
        color: "var(--color-primary)",
    },
    draft: {
        color: "var(--color-grey)",
        icon: "solar:file-bold-duotone",
    },
    accepted: {
        icon: "solar:check-square-bold",
        color: "var(--color-green)",
    },
    approved: {
        icon: "solar:dollar-broken",
        color: "var(--color-green)",
    },
    paid: {
        icon: "solar:dollar-minimalistic-bold",
        color: "var(--color-yellow)",
    },
}
