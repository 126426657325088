import { css, styled } from "@mui/material"
import { RemixiconComponentType, RiCloseLine, RiProgress1Line, RiProgress4Line, RiProgress8Line } from "@remixicon/react"
import { FC } from "react"

import { TaskStatus } from "~/domains/communication/tasks/types"

interface Props {
    status: TaskStatus
}
const iconStyle = css`
    flex-shrink: 0;
`

const progressByStatus: Record<TaskStatus, RemixiconComponentType> = {
    [TaskStatus.PENDING]: styled(RiProgress1Line)(iconStyle),
    [TaskStatus.IN_PROGRESS]: styled(RiProgress4Line)(iconStyle),
    [TaskStatus.COMPLETED]: styled(RiProgress8Line)(iconStyle),
    [TaskStatus.REJECTED]: styled(RiCloseLine)(iconStyle),
}

export const TaskProgressIcon: FC<Props> = ({ status }) => {
    const Icon = progressByStatus[status]
    return <Icon />
}
