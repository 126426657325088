import React, { useEffect, useRef } from "react"
import { useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { AutocompleteWithTags } from "~/components/AutocompleteWithTags/AutocompleteWithTags"
import { getFlattenedTags } from "~/domains/analytics/tags/core/tagGroupsAndTags"
import { selectSelectedTagsForFilter, tagsActions } from "~/domains/analytics/tags/store/tagsSlice"
import { SelectedTagI } from "~/domains/analytics/tags/types"
import { TagFilterProps } from "~/store/global/globalSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"

export const TagFilter: React.FC<TagFilterProps> = ({ tagGroups }) => {
    const { formatMessage } = useIntl()
    const selectedTagsRef = useRef<SelectedTagI[]>([])

    const dispatch = useAppDispatch()

    const selectedTags = useAppSelector(selectSelectedTagsForFilter)

    const options = getFlattenedTags(tagGroups)

    // Update ref on tag change
    useEffect(() => {
        selectedTagsRef.current = selectedTags
    }, [selectedTags])

    useEffect(() => {
        return () => {
            if (selectedTagsRef.current.length > 0) {
                dispatch(tagsActions.resetSelectedTagsForFilter())
            }
        }
    }, [])

    const handleSelectedTags = (tags: SelectedTagI[]) => {
        dispatch(tagsActions.setSelectedTagsForFilter(tags))
    }

    const groupBy = (option: SelectedTagI) => option.tagGroupName || ""

    return (
        <AutocompleteWithTags
            classname="autocomplete-with-tags-primary"
            width="275px"
            options={options}
            selectedEntities={selectedTags}
            setSelectedEntities={handleSelectedTags}
            numberOfTagsToShow={1}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.tagId === value.tagId}
            placeholder={formatMessage(commonMessages.filterByTags)}
            groupBy={groupBy}
            isChips={true}
        />
    )
}
