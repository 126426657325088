import { Tag } from "react-feather"

import { CopyTagsConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { RegularNode } from "~/domains/orchestration/flows/components/nodes"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import { CopyTagsNode as CopyTagsNodeType, EditorNode, Node, NodeType } from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const copyTagsNode: Omit<CopyTagsNodeType, keyof Node> = {
    sourceObjectId: "",
    targetObjectId: "",
    nextNode: null,
    type: NodeType.COPY_TAGS_NODE,
}

const advancedFields: AllAdvancedFieldsUnion[] = [
    AllAdvancedFieldsUnion.SOURCE_OBJECT_ID,
    AllAdvancedFieldsUnion.TARGET_OBJECT_ID,
]

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.COPY_TAGS_NODE) return false
    return Boolean(node.sourceObjectId && node.targetObjectId)
}

const copyTagsConfig: Configuration<NodeType.COPY_TAGS_NODE> = {
    type: NodeType.COPY_TAGS_NODE,
    baseSlug: "copy-tags",
    Icon: Tag,
    Configuration: CopyTagsConfiguration,
    Node: RegularNode,
    advancedFields,
    factory: (props: FactoryProps): CopyTagsNodeType => ({
        ...copyTagsNode,
        ...createCommonNodeProperties({ ...props }),
    }),
    validateNode,
}

export { copyTagsConfig, advancedFields as copyTagsAdvancedFields }
