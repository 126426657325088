import { Button, styled } from "@mui/material"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import dayjs, { Dayjs } from "dayjs"
import "dayjs/locale/fr"
import { useEffect, useState } from "react"
import { Edit3 } from "react-feather"
import { useIntl } from "react-intl"

import { ItemLabel } from "~/components"
import { DateComponent } from "~/components/Date"
import { stopPropagation } from "~/utils/events"

const Headline = styled("div")({
    display: "flex",
    alignItems: "center",
    gap: "4px",
})

const RequiredField = styled("div")({
    color: "var(--color-red)",
    fontSize: "12px",
})

interface DateProps {
    date?: string
    label: string
    edit?: boolean
    setDeliveryDate?: (date) => void
    required?: boolean
}

export function Date({ date, label, edit, setDeliveryDate, required = false }: DateProps) {
    const [editDate, setEditDate] = useState(false)

    const [dateValue, setDateValue] = useState<Dayjs>(dayjs(date))
    const { locale } = useIntl()

    useEffect(() => {
        setDateValue(dayjs(date))
    }, [date])

    useEffect(() => {
        if (editDate) {
            const onClick = (e: MouseEvent) => {
                const target = e.target as HTMLElement
                if (
                    !target.classList.contains("MuiSvgIcon-root") &&
                    !target.classList.contains("MuiPickersCalendarHeader-label")
                ) {
                    setEditDate(false)
                }
            }
            window.addEventListener("click", onClick)
            return () => {
                window.removeEventListener("click", onClick)
            }
        }
    }, [editDate])

    const handleEditDate = (event: React.MouseEvent) => {
        stopPropagation(event)
        setEditDate(true)
    }

    if (!edit) {
        return (
            <div>
                <ItemLabel>{label}</ItemLabel>
                <span>{<DateComponent value={date} />}</span>
            </div>
        )
    }

    return (
        <div>
            <Headline>
                <ItemLabel>{label}</ItemLabel>
                {required ? <RequiredField>*</RequiredField> : null}
            </Headline>

            {/* eslint-disable-next-line no-nested-ternary */}
            {!editDate ? (
                <span onClick={handleEditDate}>
                    <DateComponent value={date} />
                    <Button>
                        <Edit3 size={14} />
                    </Button>
                </span>
            ) : setDeliveryDate ? (
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                    {/*     
                    We remove 18 hours to avoid the date picker to show the next day,
                    because we add 18 hours to the date when update delivery date:
                    cf ~/dev/front/src/domains/transactions/purchase-orders/pages/PurchaseOrder.tsx -> setDeliveryDate
                    FIXME: remove this hack when we handle delivery date with date and not datetime
               
                    */}
                    <DatePicker
                        open={true}
                        label=""
                        value={dateValue.subtract(18, "hours")}
                        minDate={dayjs()}
                        onChange={(newValue: Dayjs | null) => {
                            setDeliveryDate(newValue)
                            setEditDate(false)
                        }}
                        slotProps={{
                            textField: {
                                className: "date-editor",
                                variant: "outlined",
                            },
                        }}
                    />
                </LocalizationProvider>
            ) : null}
        </div>
    )
}
