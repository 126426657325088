import { Stack, Typography } from "@mui/material"
import { XCircle } from "react-feather"
import { useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { BulkActionsSnackbar, Button } from "~/components"
import { useGetOrganizationInvoiceLifecycleQuery } from "~/domains/transactions/invoices-v1/api/invoiceLifeCycleApi"
import { InvoicesAddBudgets } from "~/domains/transactions/invoices-v1/components/list/actions/InvoicesAddBudgets"
import { InvoicesAddTags } from "~/domains/transactions/invoices-v1/components/list/actions/InvoicesAddTags"
import { InvoicesDownloadCSV } from "~/domains/transactions/invoices-v1/components/list/actions/InvoicesDownloadCSV"
import { InvoicesUpdateLifecycle } from "~/domains/transactions/invoices-v1/components/list/actions/InvoicesUpdateLifecycle"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { InvoiceI } from "~/types"

interface InvoicesBulkActionsProps {
    invoices: InvoiceI[]
    selected: string[]
    setSelected: (selected: string[]) => void
}

export const InvoicesBulkActions = ({ invoices, selected, setSelected }: InvoicesBulkActionsProps) => {
    const { formatMessage } = useIntl()

    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)

    const { data: organizationInvoiceLifecycle } = useGetOrganizationInvoiceLifecycleQuery(currentOrganizationId || "")

    const handleClose = () => setSelected([])

    const isOpen = selected.length > 0

    return (
        <BulkActionsSnackbar open={isOpen} onClose={handleClose}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                flexWrap="wrap"
                gap={2}
                padding={1}
                paddingInlineStart={2}
            >
                <Typography variant="body2" fontWeight={700} color="var(--color-white)">
                    {`${selected.length} ${formatMessage(commonMessages.invoices)}`}
                </Typography>

                <InvoicesAddTags invoices={invoices} selected={selected} onSuccess={handleClose} />

                <InvoicesAddBudgets selected={selected} onSuccess={handleClose} />

                {(organizationInvoiceLifecycle || []).map((lifecycle) => (
                    <InvoicesUpdateLifecycle
                        key={lifecycle[0]}
                        selected={selected}
                        lifecycleStatus={lifecycle}
                        onSuccess={handleClose}
                    />
                ))}

                <InvoicesDownloadCSV selected={selected} onSuccess={handleClose} />

                <Button onClick={handleClose}>
                    <XCircle color="var(--primary-color)" size={18} />
                </Button>
            </Stack>
        </BulkActionsSnackbar>
    )
}
