import { Stack, Typography } from "@mui/material"
import { defineMessages } from "react-intl"

import { commonMessages } from "~/common-messages"
import { Button, Card, SafeFormattedMessage } from "~/components"

import { useFormViewerContext } from "./FormViewerProvider"

const messages = defineMessages({
    nextSection: {
        id: "smartForms.nextSection",
        defaultMessage: "Next section",
    },
    previousSection: {
        id: "smartForms.previousSection",
        defaultMessage: "Previous section",
    },
    complete: {
        id: "smartForms.buttonComplete",
        defaultMessage: "Complete",
    },
    formSent: {
        id: "smartForms.formSent",
        defaultMessage: "Form sent",
    },
    formCompleted: {
        id: "smartForms.formCompleted",
        defaultMessage: "This form has been sent. You cannot make changes to it.",
    },
})

export const FormViewerFooter = () => {
    const {
        formData,
        currentSectionInvalid,
        validateSection,
        sendForm,
        surveyStateLoading,
        uploadFileAnswerLoading,
        nextSection,
        previousSection,
        previousButtonEnabled,
        nextButtonEnabled,
        readOnly,
    } = useFormViewerContext()

    if (!formData) {
        return null
    }

    const handleNextSection = () => {
        const isSectionInvalid = validateSection()

        if (!isSectionInvalid) {
            nextSection()
        }
    }

    const handleSendForm = () => {
        const isSectionInvalid = validateSection()

        if (!isSectionInvalid) {
            sendForm()
        }
    }

    return (
        <Card sx={{ position: "sticky", bottom: 0, marginTop: "var(--spacing-xs)" }}>
            <Stack justifyContent="space-between" direction="row" margin={1}>
                {previousButtonEnabled ? (
                    <Button onClick={previousSection}>
                        <SafeFormattedMessage {...messages.previousSection} />
                    </Button>
                ) : (
                    <div></div>
                )}
                {currentSectionInvalid ? (
                    <Stack display="flex" direction="row" alignItems="center">
                        <Typography variant="body2" color="error">
                            <SafeFormattedMessage {...commonMessages.errorMissingRequiredFields} />
                        </Typography>
                    </Stack>
                ) : (
                    <></>
                )}
                {readOnly && <SafeFormattedMessage {...messages.formCompleted} />}
                {nextButtonEnabled ? (
                    <Button onClick={handleNextSection} disabled={surveyStateLoading || uploadFileAnswerLoading}>
                        <SafeFormattedMessage {...messages.nextSection} />
                    </Button>
                ) : (
                    <div>
                        {!readOnly && (
                            <Button
                                className="flex-grow"
                                disabled={surveyStateLoading || uploadFileAnswerLoading}
                                onClick={handleSendForm}
                            >
                                <SafeFormattedMessage {...messages.complete} />
                            </Button>
                        )}
                    </div>
                )}
            </Stack>
        </Card>
    )
}
