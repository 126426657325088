import { CollaborationStatus } from "~/domains/identity/partners/types"
import {
    EntityType,
    ObjectType,
    PartnershipFieldToUpdate,
    RunStatus,
    TransactionType,
} from "~/domains/orchestration/flows/types"

export const OBJECT_TYPES = Object.values(ObjectType)

export const TRANSACTION_TYPES = Object.values(TransactionType)

export const PARTNERSHIP_FIELD_TO_UPDATE = Object.values(PartnershipFieldToUpdate)

export const COLLABORATION_STATUSES = Object.values(CollaborationStatus)

export const NODE_WIDTH = 368
export const NODE_MIN_HEIGHT = 90
export const SIDE_BAR_WIDTH = 400
export const SIDE_BAR_WIDE_WIDTH = 800
export const SIDE_BAR_WIDTH_RUN_EXPLORER = 650
export const SIDE_BAR_TOP_POSITION = 100

export const RUN_STATUS_COLORS = {
    [RunStatus.CANCELED]: "var(--color-grey)",
    [RunStatus.FAILED]: "var(--color-red)",
    [RunStatus.FINISHED]: "var(--color-green)",
    [RunStatus.RUNNING]: "var(--color-primary)",
    [RunStatus.SUSPENDED]: "var(--color-yellow)",
    [RunStatus.UNKNOWN]: "var(--color-grey-light)",
    [RunStatus.QUEUED]: "#2B7FFF",
}

export const ENTITY_TRIGGER_PROPERTY_MAP: Record<EntityType, string> = {
    [EntityType.PARTNERSHIP]: "partnership",
    [EntityType.PURCHASE_ORDER]: "purchaseOrder",
    [EntityType.PURCHASE_REQUEST]: "purchaseRequest",
    [EntityType.UNKNOWN]: "",
}

export const ENTITY_DEFAULT_SLUG = "entity_trigger"
export const EVENT_DEFAULT_SLUG = "trigger_event"

export const DEFAULT_CURRENCY = "EUR"
