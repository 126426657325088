export enum TagObjectType {
    INVOICE = "INVOICE",
    INVOICE_LINE = "INVOICE_LINE",
    PURCHASE_REQUEST = "PURCHASE_REQUEST",
    PURCHASE_ORDER = "PURCHASE_ORDER",
    PURCHASE_REQUEST_LINE = "PURCHASE_REQUEST_LINE",
    PURCHASE_ORDER_LINE = "PURCHASE_ORDER_LINE",
    BOOK_OF_RELATIONS = "BOOK_OF_RELATIONS",
    BUDGETS = "BUDGETS",
    PRODUCT_VARIANT = "PRODUCT_VARIANT",
    PAYMENT_BATCH = "PAYMENT_BATCH",
}
