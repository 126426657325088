/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Check, Edit3, Send, X } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { Steps } from "~/components"
import { StepI } from "~/domains/transactions/_shared/components/Steps/Step"
import {
    PurchaseRequestDetails,
    PurchaseRequestStatus,
} from "~/domains/transactions/purchase-requests/types/PurchaseRequests"

const messages = defineMessages({
    draft: {
        id: "purchase.requests.request.steps.draft",
        defaultMessage: "Draft",
    },
    sent: {
        id: "purchase.requests.request.steps.sent",
        defaultMessage: "Submitted",
    },
    create: {
        id: "purchase.requests.request.steps.create",
        defaultMessage: "Create",
    },
    done: {
        id: "purchase.requests.request.steps.done",
        defaultMessage: "Done",
    },
    approval: {
        id: "purchase.requests.request.steps.approval",
        defaultMessage: "Approval",
    },
    APPROVED: {
        id: "purchase.requests.request.steps.approved",
        defaultMessage: "Approved",
    },
    REJECTED: {
        id: "purchase.requests.request.steps.rejected",
        defaultMessage: "Rejected",
    },
    CONVERTED: {
        id: "purchase.requests.request.steps.converted",
        defaultMessage: "Done",
    },
    items: {
        id: "purchase.orders.order.steps.items",
        defaultMessage: "{current}/{total} items",
    },
})

interface StepsProps {
    PR: PurchaseRequestDetails
}

export const StepsPurchaseRequest = ({ PR }: StepsProps) => {
    const { formatMessage } = useIntl()

    const steps: StepI[] = [
        {
            key: "draft",
            name: formatMessage(messages.draft),
            status: "done",
            done: true,
            icon: <Edit3 size={18} />,
        },
        {
            key: "sent",
            name: formatMessage(messages.sent),
            status: PR.status !== PurchaseRequestStatus.DRAFT ? "done" : "next",
            done: PR.status !== PurchaseRequestStatus.DRAFT,
            icon: <Send size={18} />,
        },
        {
            key: "validation",
            name: (() => {
                if (PR.purchaseOrder) {
                    return formatMessage(messages.CONVERTED)
                } else if ([PurchaseRequestStatus.APPROVED, PurchaseRequestStatus.REJECTED].includes(PR.status)) {
                    return formatMessage(messages[PR.status])
                } else {
                    return formatMessage(messages.approval)
                }
            })(),
            done: [PurchaseRequestStatus.APPROVED, PurchaseRequestStatus.REJECTED].includes(PR.status),
            status: [PurchaseRequestStatus.APPROVED, PurchaseRequestStatus.REJECTED].includes(PR.status)
                ? "done"
                : "next",
            type: (() => {
                if (PR.status === PurchaseRequestStatus.REJECTED) {
                    return "error"
                } else if (PR.status === PurchaseRequestStatus.APPROVED) {
                    return "success"
                } else {
                    return "default"
                }
            })(),
            icon: (() => {
                if (PR.status === PurchaseRequestStatus.REJECTED) {
                    return <X size={18} />
                } else {
                    return <Check size={18} />
                }
            })(),
        },
    ]

    return <Steps steps={steps} />
}
