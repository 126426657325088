import { CountryCode } from "./CountryCode"

export enum RegistrationNumberType {
    FRENCH_SIRET = "SIRET (FR)",
    FRENCH_SIREN = "SIREN (FR)",
}

export const RegistrationNumberTypePerCountryCode: Partial<Record<CountryCode, RegistrationNumberType>> = {
    [CountryCode.FR]: RegistrationNumberType.FRENCH_SIRET,
}
