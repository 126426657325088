import { FC, useState } from "react"

import { Tabs } from "~/components"
import { useDashboardApprovalTabs } from "~/domains/analytics/dashboard/hooks"
import { DocumentForApproval } from "~/domains/analytics/dashboard/types/DocumentForApproval"

interface Props {
    approvals: DocumentForApproval[]
}

type DashboardApprovalsTab = "All" | "Purchase requests" | "Purchase orders" | "Invoices"

export const DashboardApprovalTabs: FC<Props> = ({ approvals }) => {
    const approvalTabs = useDashboardApprovalTabs(approvals)

    const [currentApprovalsTab, setCurrentApprovalsTab] = useState<DashboardApprovalsTab>("All")

    const onApprovalsTabChange = (tab: string | number) => {
        setCurrentApprovalsTab(tab as DashboardApprovalsTab)
    }

    return (
        <Tabs
            className="dashboard-metrics-tabs"
            defaultValue={currentApprovalsTab}
            setChange={onApprovalsTabChange}
            tabs={approvalTabs}
        />
    )
}
