import "dayjs/locale/fr"
import React from "react"
import { useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { AutocompleteWithTags, Button, Loader, Modal } from "~/components"
import { transactionTypeMessages } from "~/domains/transactions/budget/core/messages"
import { BudgetDataI, TransactionType } from "~/domains/transactions/budget/types"
import { useBulkAddInvoicesToBudgets } from "~/store/account/hooks/useBulkAddToBudgets"
import { useFetchBudgetsData } from "~/store/budget/hooks"
import { InvoiceBackendVersion, OrganizationId, ViewTypeI } from "~/types"

const messages = defineMessages({
    title: { id: "transactions.modal.addInvoicesToBudgets.title", defaultMessage: "Add invoices to a budgets" },
    selectBudget: { id: "transactions.modal.addInvoicesToBudgets.selectBudgets", defaultMessage: "Select budgets" },
})

interface Props {
    organizationId: OrganizationId
    selected: string[]
    view: ViewTypeI
    open: boolean
    onConfirm: () => void
    close: () => void
    version?: InvoiceBackendVersion
}

export const ModalAddInvoicesToBudgets: React.FC<Props> = ({
    organizationId,
    open,
    view,
    close,
    onConfirm,
    selected,
    version,
}) => {
    const { formatMessage } = useIntl()

    const [selectedBudgets, setSelectedBudgets] = useState<BudgetDataI[]>([])
    const { bulkAddInvoicesToBudgets, isLoading } = useBulkAddInvoicesToBudgets({
        organizationId,
        invoiceIds: selected,
        view,
        version,
    })
    const { budgetsData } = useFetchBudgetsData({ organizationId, withMetrics: false })

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (!selectedBudgets.length) return

        await bulkAddInvoicesToBudgets(selectedBudgets)
        setSelectedBudgets([])
        onConfirm()
    }

    const handleClose = () => close()

    return (
        <Modal open={open} onClose={handleClose}>
            <Modal.Header>
                <h4>
                    {formatMessage(messages.title, {
                        transactionType: formatMessage(transactionTypeMessages[TransactionType.INVOICE]),
                    })}
                </h4>
            </Modal.Header>
            <form onSubmit={onSubmit}>
                <Modal.Content>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <AutocompleteWithTags
                            options={budgetsData}
                            selectedEntities={selectedBudgets}
                            setSelectedEntities={setSelectedBudgets}
                            numberOfTagsToShow={3}
                            getOptionLabel={(budget) => budget.name}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            placeholder={formatMessage(messages.selectBudget)}
                            classname="contacts-role-name mb-12"
                        />
                    )}
                </Modal.Content>
                <Modal.Footer>
                    <Button type="transparent" onClick={close}>
                        {formatMessage(commonMessages.cancel)}
                    </Button>
                    <Button type="primary" buttonType="submit" disabled={isLoading}>
                        {formatMessage(commonMessages.save)}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}
