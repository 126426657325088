import { defineMessages } from "react-intl"

import { PurchaseLineDiscountType } from "~/domains/transactions/_shared/types"

export const discountTypeMessages = defineMessages<PurchaseLineDiscountType>({
    PER_UNIT: {
        id: "common.perUnit",
        defaultMessage: " / unit",
    },
    FLAT: {
        id: "common.flat",
        defaultMessage: "Flat",
    },
    PERCENTAGE: {
        id: "common.percentage",
        defaultMessage: "Percentage",
    },
})

export const discountTypeSymbols = {
    PER_UNIT: "unit",
    FLAT: "flat",
    PERCENTAGE: "%",
}

export const discountTypes = {
    ...discountTypeMessages,
}
