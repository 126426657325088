import { useState } from "react"
import { v4 as uuid } from "uuid"

import { Condition, Conditions } from "~/domains/orchestration/flows/types"

interface UseConditionsManagerProps {
    initialConditions: Conditions[]
    onConditionsChange: (conditions: Conditions[]) => void
}

export const useConditionsManager = ({ initialConditions, onConditionsChange }: UseConditionsManagerProps) => {
    const [conditions, setConditions] = useState(initialConditions)
    const [showConditionBuilder, setShowConditionBuilder] = useState(false)
    const [currentConditionGroupId, setCurrentConditionGroupId] = useState<string>("")
    const [currentConditionId, setCurrentConditionId] = useState<string>("")

    const handleResetConditionBuilder = () => {
        updateConditions([])
        setShowConditionBuilder(false)
        setCurrentConditionGroupId("")
        setCurrentConditionId("")
    }

    const handleCloseConditionBuilder = () => {
        setShowConditionBuilder(false)
        setCurrentConditionGroupId("")
        setCurrentConditionId("")
    }

    const updateConditions = (newConditions: Conditions[]) => {
        setConditions(newConditions)
        onConditionsChange(newConditions)
    }

    const handleChangeCondition = (condition: Conditions, conditionGroupId: string) => {
        const newConditions = conditions.map((g) => (g.id === conditionGroupId ? condition : g))
        updateConditions(newConditions)
    }

    const handleDelete = (conditionGroup: Conditions) => () => {
        const newConditions = conditions.filter((g) => g.id !== conditionGroup.id)
        updateConditions(newConditions)
    }

    const handleNewConditionItem = (condition: Condition, conditionGroupId: string) => {
        const currentGroup = conditions.find((g) => g.id === conditionGroupId)
        if (!currentGroup) return

        const newConditions = [...currentGroup.conditions, condition]
        handleChangeCondition(
            {
                id: currentGroup.id,
                conditions: newConditions,
            },
            conditionGroupId
        )

        setCurrentConditionId(condition.id)
        setCurrentConditionGroupId(conditionGroupId)
        setShowConditionBuilder(true)
    }

    const handleSaveConditionItem = (condition: Condition) => {
        setShowConditionBuilder(false)
        const currentGroup = conditions.find((g) => g.id === currentConditionGroupId)
        if (!currentGroup) return

        const newConditions = currentGroup.conditions.map((c) => (c.id === condition.id ? condition : c))
        handleChangeCondition(
            {
                id: currentGroup.id,
                conditions: newConditions,
            },
            currentConditionGroupId
        )
    }

    const handleAddConditionGroup = () => {
        const newConditionGroup = {
            id: uuid(),
            conditions: [],
        }
        updateConditions([...conditions, newConditionGroup])
    }

    const handleEditCondition = (conditionId: string, conditionGroupId: string) => {
        const group = conditions.find((g) => g.id === conditionGroupId)
        if (!group) return

        const condition = group.conditions.find((c) => c.id === conditionId)
        if (!condition) return

        setCurrentConditionId(conditionId)
        setCurrentConditionGroupId(conditionGroupId)
        setShowConditionBuilder(true)
    }

    const currentCondition = conditions
        .find((g) => g.id === currentConditionGroupId)
        ?.conditions.find((c) => c.id === currentConditionId)

    return {
        conditions,
        showConditionBuilder,
        currentCondition,
        handleChangeCondition,
        handleDelete,
        handleNewConditionItem,
        handleSaveConditionItem,
        handleAddConditionGroup,
        handleEditCondition,
        handleResetConditionBuilder,
        handleCloseConditionBuilder,
    }
}
