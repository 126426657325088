/* eslint-disable no-nested-ternary */
import React, { FormEvent, useCallback, useEffect, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { Button, Card, Loader, SafeFormattedMessage } from "~/components"
import { ErrorMessage } from "~/components/ErrorMessage"
import { CompanyAutocomplete } from "~/domains/identity/organization/components/CompanyAutocomplete"
import { CreateOrganizationContainer } from "~/domains/identity/organization/components/CreateOrganizationContainer/CreateOrganizationContainer"
import { ManualCompanyInputTrigger } from "~/domains/identity/organization/components/CreateOrganizationContainer/ManualInputContainer"
import { useEstablishmentState } from "~/domains/identity/organization/hooks"
import { CompanyResult, CompanyResultWithCreation } from "~/domains/identity/organization/types/CompanyAutocomplete"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { useFoundOrganization } from "~/store/organization/hooks"
import { organizationActions, selectCurrentOrganization } from "~/store/organization/organizationSlice"
import {
    CompanyRegistrationNumberI,
    CountryCode,
    EstablishmentI,
    OrganizationI,
    OrganizationRegistrationI,
    OrganizationRegistrationNumberI,
    UserI,
    WhitePagesIdTypes,
} from "~/types"

const messages = defineMessages({
    title: {
        id: "company.registration.createOrganization",
        defaultMessage: "Create or Request access to a company",
    },
    name: {
        id: "company.registration.name",
        defaultMessage: "Company Name",
    },
    create: {
        id: "company.registration.createButton",
        defaultMessage: "Create or Request",
    },
    siret: {
        id: "company.registration.siret",
        defaultMessage: "Siret",
    },
    registrationNumber: {
        id: "company.registrationNumber",
        defaultMessage: "Registration number",
    },
    membershipRequested: {
        id: "account.organization.cannotCreateRequestedMembershipInstead",
        defaultMessage: "This organization already exists. A request to join it has been sent.",
    },
})

interface Props {
    user: UserI
    onSuccess: (organization: OrganizationI | undefined) => void
    defaultCountryCode?: CountryCode
}

export const registrationNumberIsPrefered = (registrationNumber: CompanyRegistrationNumberI): boolean =>
    !!registrationNumber.isPreferred

const getPreferedRegistrationNumber = (
    companyResult: CompanyResultWithCreation
): OrganizationRegistrationNumberI | null => {
    if (companyResult.type === "wp") {
        return companyResult.value.registrationNumbers.find(registrationNumberIsPrefered) ?? null
    } else if (companyResult.type === "org") {
        return companyResult.value.registration?.preferredRegistrationNumber
    }
    return null
}

const getCompanyCountryCode = (company: CompanyResultWithCreation): CountryCode =>
    company.type === "org"
        ? (company.value.registration?.countryCode ?? CountryCode.UNKNOWN)
        : company.value.countryCode

const getCompanyTaxId = (company: CompanyResultWithCreation): string => {
    if (company.type === "org") {
        return company.value.registration?.vatNumber ?? ""
    } else if (company.type === "wp") {
        return company.value.taxId
    }
    return ""
}

export const FoundOrganization: React.FC<Props> = ({ user, onSuccess, defaultCountryCode = CountryCode.UNKNOWN }) => {
    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()

    const [company, setCompany] = useState<CompanyResult>()
    const currentOrganization = useAppSelector(selectCurrentOrganization)

    const [companyIdentifier, setCompanyIdentifier] = useState<string>("")
    const [registrationNumber, setRegistrationNumber] = useState<OrganizationRegistrationNumberI>({
        registrationNumber: "",
        registrationType: "UNKNOWN",
    })
    const [countryCode, setCountryCode] = useState<CountryCode>(defaultCountryCode)
    const [manualFoundation, setManualFoundation] = useState(false)

    const { foundOrganization, loading, error } = useFoundOrganization(user)
    const [selectedEstablishment, setSelectedEstablishment] = useEstablishmentState(
        company?.type === "wp" ? company.value : undefined
    )

    const hasRegistrationNumber = !!selectedEstablishment || registrationNumber.registrationNumber.length >= 5 // TODO: define the min length
    const hasCompanyName = !!company?.value.name

    // Set the DEFAULT country code to the current organization country code
    // This will default the company selector flag to the current organization country code
    useEffect(() => {
        if (currentOrganization?.id) {
            setCountryCode(currentOrganization?.registration?.countryCode ?? CountryCode.UNKNOWN)
        }
    }, [currentOrganization?.id, currentOrganization?.registration?.countryCode])

    const onCompanyChange = useCallback(
        (value: CompanyResult | undefined, establishmentToSelect?: EstablishmentI | undefined) => {
            setCompany(value)
            setSelectedEstablishment(establishmentToSelect)
        },
        [setCompany, setSelectedEstablishment]
    )

    const onOrganizationCreated = useCallback(
        (createdOrganization: OrganizationI, isFoundation?: boolean) => {
            if (createdOrganization && company && !isFoundation) {
                const registration: OrganizationRegistrationI = {
                    countryCode: getCompanyCountryCode(company),
                    legalName: company.value.name,
                    vatNumber: getCompanyTaxId(company),
                    preferredRegistrationNumber: registrationNumber,
                }
                if (company.type === "wp" && company.value.idType === WhitePagesIdTypes.DUNS) {
                    registration.dunsNumber = company.value.id
                }
                dispatch(
                    organizationActions.saveOrganizationRegistration({
                        organizationId: createdOrganization.id,
                        registration: registration,
                    })
                )
                if (selectedEstablishment) {
                    dispatch(
                        organizationActions.saveOrganizationAddress({
                            organizationId: createdOrganization.id,
                            address: {
                                addressLine: selectedEstablishment.addressLine1 ?? "",
                                secondaryAddressLine: selectedEstablishment.addressLine2 ?? "",
                                zipCode: selectedEstablishment.postalCode ?? "",
                                city: selectedEstablishment.city ?? "",
                                country: selectedEstablishment.country ?? "",
                            },
                        })
                    )
                } else if (company.type === "wp" && company.value.primaryAddress) {
                    dispatch(
                        organizationActions.saveOrganizationAddress({
                            organizationId: createdOrganization.id,
                            address: {
                                addressLine: company.value.primaryAddress.streetLine1 ?? "",
                                secondaryAddressLine: company.value.primaryAddress.streetLine2 ?? "",
                                zipCode: company.value.primaryAddress.postalCode ?? "",
                                city: company.value.primaryAddress.city ?? "",
                                country: company.value.primaryAddress.country ?? "",
                            },
                        })
                    )
                }
                onSuccess(createdOrganization)
            } else if (createdOrganization) {
                onSuccess(createdOrganization)
            }
        },
        [company, selectedEstablishment, registrationNumber, onSuccess]
    )

    useEffect(() => {
        if (company) {
            setCompanyIdentifier(selectedEstablishment?.id ?? company.value.id)
            const regNumber = getPreferedRegistrationNumber(company)
            if (regNumber) {
                setRegistrationNumber(regNumber)
            } else if (company.type === "wp" && company.value.idType === WhitePagesIdTypes.DUNS) {
                setRegistrationNumber({
                    registrationNumber: company.value.id,
                    registrationType: WhitePagesIdTypes.DUNS,
                })
            }
            if (company.type === "wp" && company.value.establishments?.length === 1) {
                setRegistrationNumber({
                    registrationNumber: company.value.establishments[0].id,
                    registrationType: "SIRET (FR)",
                })
            }
        } else {
            setCompanyIdentifier("")
        }
    }, [company, selectedEstablishment])

    const onSubmit = useCallback(
        (event: FormEvent) => {
            event.preventDefault()
            if (company) {
                const companyName = company.value.name
                const companyCountryCode = getCompanyCountryCode(company)

                if (companyName && companyIdentifier) {
                    foundOrganization(companyName, companyCountryCode, companyIdentifier).then(
                        ({ organization, membershipRequested }) => {
                            if (membershipRequested) {
                                toast.warning(formatMessage(messages.membershipRequested))
                            } else {
                                onOrganizationCreated(organization)
                            }
                        }
                    )
                }
            }
        },
        [onSuccess, foundOrganization, company, companyIdentifier, dispatch, formatMessage, onOrganizationCreated]
    )

    const onManualImportClick = useCallback(() => setManualFoundation(true), [])
    const cancelManualImport = useCallback(() => setManualFoundation(false), [])

    if (loading) {
        return (
            <Card title={formatMessage(messages.title)}>
                <Loader />
            </Card>
        )
    }

    return (
        <Card title={formatMessage(messages.title)}>
            {manualFoundation ? (
                <CreateOrganizationContainer
                    cancelManualImport={cancelManualImport}
                    onOrganizationCreated={onOrganizationCreated}
                    isFoundation={true}
                />
            ) : (
                <form onSubmit={onSubmit}>
                    <CompanyAutocomplete
                        company={company}
                        setCompany={onCompanyChange}
                        countryCode={countryCode}
                        setCountryCode={setCountryCode}
                        organizations={[]}
                        suggestedCompanies={[]}
                    />
                    <ManualCompanyInputTrigger onClick={onManualImportClick} />
                    <ErrorMessage>{error}</ErrorMessage>
                    <div style={{ marginTop: "16px" }}>
                        <Button onClick={onSubmit} disabled={!hasRegistrationNumber || !hasCompanyName}>
                            <SafeFormattedMessage {...messages.create} />
                        </Button>
                    </div>
                </form>
            )}
        </Card>
    )
}
