import { ApprovalBlock } from "~/domains/orchestration/flows-v0/components/ApprovalBlock"
import { ApprovalObjectType } from "~/domains/orchestration/flows-v0/types/Approval"
import { useHasPaymentPermissions } from "~/domains/payment/hooks"
import { useUpdatePaymentMethodDetailsMutation } from "~/domains/payment/payment-method-details/api/paymentMethodDetailsApi"
import {
    paymentMethodDetailsActions,
    selectSelectedOrganizationId,
} from "~/domains/payment/payment-method-details/store/paymentMethodDetailsSlice"
import { PaymentMethodDetails, PaymentMethodDetailsStatus } from "~/domains/payment/payment-method-details/types"
import { useAppDispatch, useAppSelector } from "~/store/hooks"

interface PaymentMethodDetailsApprovalProps {
    row: PaymentMethodDetails
}

export const PaymentMethodDetailsApproval = ({ row }: PaymentMethodDetailsApprovalProps) => {
    const organizationId = useAppSelector(selectSelectedOrganizationId)
    const dispatch = useAppDispatch()

    const { permissions } = useHasPaymentPermissions({ authorizations: ["approve"] })
    const hasApprovePermission = Boolean(permissions?.approve?.hasPermission)

    const [updatePaymentDetails] = useUpdatePaymentMethodDetailsMutation()

    const handleClearRow = () => {
        dispatch(paymentMethodDetailsActions.removeRowById(row.id))
    }

    const handleAddRowToApprove = () => {
        dispatch(paymentMethodDetailsActions.addRowToApprove({ row, organizationId: row.owner }))
    }

    const handleUpdateStatus = async () => {
        await updatePaymentDetails({ id: row.id, status: PaymentMethodDetailsStatus.PENDING }).unwrap()
    }

    if (!organizationId || row.status === PaymentMethodDetailsStatus.DELETED) {
        return null
    }

    return hasApprovePermission ? (
        <ApprovalBlock
            showRetractAll
            showRetract={false}
            organisationId={organizationId}
            objectId={row.id}
            objectType={ApprovalObjectType.PAYMENT_METHOD_DETAILS}
            displayType="inline"
            onApproved={handleClearRow}
            onRefused={handleClearRow}
            onRetract={handleAddRowToApprove}
            onRetractAll={handleUpdateStatus}
        />
    ) : null
}
