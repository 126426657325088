import { Stack } from "@mui/material"
import React from "react"

import { useProcessBulkReviewItems } from "~/domains/orchestration/flows-v0/hooks/useProcessBulkReviewItems"
import { ApprovalObjectType } from "~/domains/orchestration/flows-v0/types"
import { usePurchaseRequestReview } from "~/domains/transactions/purchase-requests/hooks/usePurchaseRequestReview"
import { usePurchaseRequestValidationActions } from "~/domains/transactions/purchase-requests/store/hooks"
import {
    PurchaseRequestDetails,
    PurchaseRequestStatus,
} from "~/domains/transactions/purchase-requests/types/PurchaseRequests"

import { ApprovePR } from "./ApprovePR"
import { RejectPR } from "./RejectPR"

interface Props {
    purchaseRequest: PurchaseRequestDetails
}

export const ApproveAllLines: React.FC<Props> = ({ purchaseRequest }) => {
    const canApprove = Boolean(purchaseRequest.permissions.approve)

    const { currentUserCanValidate, currentUserApproval, withinWorkflow, approve, refuse } =
        usePurchaseRequestValidationActions(purchaseRequest)

    const { isApprovalDone, isAdmin } = usePurchaseRequestReview(purchaseRequest)

    const { processBulkReviewItems } = useProcessBulkReviewItems()

    const handleReview = async (isApprove: boolean) => {
        if (purchaseRequest) {
            await processBulkReviewItems(
                purchaseRequest.lines.map((line) => line.id || ""),
                isApprove,
                ApprovalObjectType.PURCHASE_REQUEST_LINE
            )
        }

        if (isApprove && approve && canApprove) {
            approve()
        } else if (!isApprove && refuse && canApprove) {
            refuse()
        }
    }

    const handleRefuse = () => {
        handleReview(false)
    }

    const handleApprove = () => {
        handleReview(true)
    }

    const prStatusAllowsApproval = purchaseRequest.status === PurchaseRequestStatus.SUBMITTED
    const workflowAllowsApproval = (withinWorkflow && currentUserCanValidate && isApprovalDone) || isAdmin

    const hideActions = !prStatusAllowsApproval || !workflowAllowsApproval

    if (hideActions) return null

    return (
        <>
            <Stack gap={1} direction="row" alignItems="center" justifyContent="flex-end">
                <RejectPR
                    isApprovalDone={isApprovalDone}
                    canApprove={canApprove}
                    currentUserApproval={currentUserApproval}
                    onRefuse={handleRefuse}
                />
                <ApprovePR
                    isApprovalDone={isApprovalDone}
                    canApprove={canApprove}
                    currentUserApproval={currentUserApproval}
                    onApprove={handleApprove}
                />
            </Stack>
        </>
    )
}
