import * as Sentry from "@sentry/react"
import { useCallback } from "react"

import {
    SPICEDB_TAGS,
    spiceDbApi,
    useShareObjectPermissionsMutation,
} from "~/domains/identity/roles-permissions/api/spiceDbApi"
import { selectRelatedTargetsForObjectPermissions } from "~/domains/identity/roles-permissions/store/rolesPermissionsSlice"
import { SpiceDBObjectType, SpiceDBSharePermissionsBodyDto } from "~/domains/identity/roles-permissions/types/SpiceDB"
import { useAppDispatch, useAppSelector } from "~/store/hooks"

interface ShareObjectPermissionsProps {
    objectId: string
    objectType: SpiceDBObjectType
    body: SpiceDBSharePermissionsBodyDto
}

export const useShareObjectPermissions = () => {
    const dispatch = useAppDispatch()
    const [shareObjectPermissions, { isLoading }] = useShareObjectPermissionsMutation()
    const targets = useAppSelector(selectRelatedTargetsForObjectPermissions)

    const invalidateTags = () => dispatch(spiceDbApi.util.invalidateTags(SPICEDB_TAGS))

    const handleShareObjectPermissions = useCallback(
        async ({ objectId, objectType, body }: ShareObjectPermissionsProps) => {
            await shareObjectPermissions({ objectId, objectType, body })

            // Share permissions to related permission targets
            // Use-case: when user share edit permission to a invoice, we should also share the same permission to the invoice lifecycle
            if (targets?.length) {
                const result = await Promise.allSettled(
                    targets.map((target) =>
                        shareObjectPermissions({ ...target, body: { ...body, isFirstTimeShare: false } })
                    )
                )

                if (result.some((r) => r.status === "rejected")) {
                    Sentry.captureException("Failed to share permissions to related permission targets", {
                        extra: {
                            objectId,
                            objectType,
                            body,
                            targets,
                            result,
                        },
                    })
                }
            }

            // For race issue
            setTimeout(invalidateTags, 500)
        },
        [targets]
    )

    return {
        handleShareObjectPermissions,
        isLoading,
    }
}
