import React from "react"

import {
    CompactOrExpandedProps,
    OrganizationPendingJobsQueue,
} from "~/domains/transactions/invoices/_views/ocr/components/OcrPendingJobsQueue/OrganizationPendingJobsQueue"
import { selectUserId } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"

export const OcrPendingJobsQueue: React.FC<CompactOrExpandedProps> = (props) => {
    const userId = useAppSelector(selectUserId)

    if (!userId) return null
    return <OrganizationPendingJobsQueue userId={userId} {...props} />
}
