import { styled } from "@mui/material"
import React, { useCallback, useMemo } from "react"
import { MessageDescriptor, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { getInitialFromName } from "~/components/Avatar/getInitialFromName"
import { useUserObjectPermissionsCheckQuery } from "~/domains/identity/roles-permissions/api/spiceDbApi"
import { PermissionsSelect } from "~/domains/identity/roles-permissions/components/ShareObjectPermissionsModal/PermissionsSelect/PermissionsSelect"
import { useRemoveObjectPermissions } from "~/domains/identity/roles-permissions/hooks/useRemoveObjectPermissions"
import { useShareObjectPermissions } from "~/domains/identity/roles-permissions/hooks/useShareObjectPermissions"
import { useFetchObjectPermissions } from "~/domains/identity/roles-permissions/store/hooks"
import { ShareObjectPermissionsMutation } from "~/domains/identity/roles-permissions/types/RolesPermissions"
import {
    SpiceDBObjectAuthorizationName,
    SpiceDBObjectType,
    SpiceDBPermissionsLiteI,
    sharedObjectPermissionsMessages,
    sharedObjectPermissionsModalMessages,
} from "~/domains/identity/roles-permissions/types/SpiceDB"
import { getCurrentUserPermissions } from "~/domains/identity/roles-permissions/utils/spiceDB"
import { selectUser } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { AuthorizationName } from "~/types"

const Container = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "2px 8px",
    width: "100%",
    "&:hover": {
        backgroundColor: "var(--color-violet-lighter)",
    },
})
export const UserTeamContainer = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "8px",
})
const NameContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
})
export const Name = styled("div")({
    fontSize: "14px",
    lineHeight: "20px",
})
const Description = styled("div")({
    fontSize: "12px",
    lineHeight: "16px",
    color: "var(--color-grey-light)",
})
const PermissionsContainer = styled("div")({
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
})

interface Props {
    objectId: string
    objectType: SpiceDBObjectType
    targetObjectType?: SpiceDBObjectType
    objectPermission: SpiceDBPermissionsLiteI
    disabled?: boolean
}

export const ObjectPermissions: React.FC<Props> = ({
    objectId,
    objectType,
    targetObjectType,
    objectPermission,
    disabled = false,
}) => {
    const { formatMessage } = useIntl()
    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const currentUser = useAppSelector(selectUser)

    const { permissions, objectType: selectedObjectType, name, email, membersNumber } = objectPermission
    const { handleShareObjectPermissions } = useShareObjectPermissions()
    const { handleRemoveObjectPermissions } = useRemoveObjectPermissions()
    const { sharedPermissions } = useFetchObjectPermissions(objectId, objectType)
    const { data: userObjectPermissions } = useUserObjectPermissionsCheckQuery({
        userId: currentUser?.id ?? "",
        objectType: targetObjectType ?? objectType,
        organizationId: currentOrganizationId as string,
        authorizations: SpiceDBObjectAuthorizationName[targetObjectType ?? objectType]?.authorized ?? [],
    })

    const currentUserPermissions = useMemo(() => {
        return sharedPermissions && userObjectPermissions
            ? getCurrentUserPermissions(currentUser.id, userObjectPermissions, sharedPermissions)
            : []
    }, [sharedPermissions, currentUser.id, userObjectPermissions])

    const handlePermissionChangeSuccess = useCallback(
        async (newPermissions: AuthorizationName[]) => {
            if (!currentOrganizationId) return

            const {
                permissions: oPermissions,
                objectId: selectedObjectId,
                objectType: currentObjectType,
            } = objectPermission
            const permissionsToAdd = newPermissions.filter((permission) => !oPermissions.includes(permission))
            const permissionsToRemove = oPermissions.filter((permission) => !newPermissions.includes(permission))

            const payload: ShareObjectPermissionsMutation = {
                objectId,
                objectType,
                body: {
                    objects: [{ objectId: selectedObjectId, objectType: currentObjectType }],
                    authorizations: permissionsToAdd,
                    isFirstTimeShare: false,
                    creatorOrganizationId: currentOrganizationId,
                    recepientOrganizationId: currentOrganizationId,
                },
            }
            if (permissionsToAdd.length) {
                await handleShareObjectPermissions(payload)
                toast.success(
                    formatMessage(sharedObjectPermissionsModalMessages.sharePermissionsSuccess, {
                        count: permissionsToAdd.length,
                        objectType: formatMessage(
                            sharedObjectPermissionsMessages[currentObjectType] as MessageDescriptor
                        ),
                    })
                )
            } else if (permissionsToRemove.length) {
                await handleRemoveObjectPermissions({
                    ...payload,
                    body: { ...payload.body, authorizations: permissionsToRemove },
                })
                toast.success(
                    formatMessage(sharedObjectPermissionsModalMessages.removePermissionsSuccess, {
                        count: permissionsToRemove.length,
                        objectType: formatMessage(
                            sharedObjectPermissionsMessages[currentObjectType] as MessageDescriptor
                        ),
                    })
                )
            }
        },
        [
            currentOrganizationId,
            objectPermission,
            objectId,
            objectType,
            handleShareObjectPermissions,
            handleRemoveObjectPermissions,
            formatMessage,
        ]
    )

    return (
        <Container>
            <UserTeamContainer>
                <span className="company-logo">
                    <figure className="no-image noto-semi-bold">{getInitialFromName(name as string)}</figure>
                </span>
                <NameContainer>
                    <Name>{name}</Name>
                    <Description>
                        {selectedObjectType === SpiceDBObjectType.USER
                            ? email
                            : `${formatMessage(sharedObjectPermissionsModalMessages.membersCount, {
                                  membersCount: membersNumber,
                              })}`}
                    </Description>
                </NameContainer>
            </UserTeamContainer>
            <PermissionsContainer>
                <PermissionsSelect
                    currentUserPermissions={currentUserPermissions}
                    objectPermissions={permissions}
                    objectType={objectType}
                    handleChangePermission={handlePermissionChangeSuccess}
                    canSelect={!disabled}
                />
            </PermissionsContainer>
        </Container>
    )
}
