import { GridFilterModel } from "@mui/x-data-grid-premium"
import qs from "qs"

type Value = string | number | boolean | undefined
type GridValue = Value | Value[] | undefined
type QsValue = Value | Value[] | null | undefined

type QsOperator =
    | "$eq"
    | "$ne"
    | "$contains"
    | "$containsi"
    | "$notContains"
    | "$notContainsi"
    | "$gt"
    | "$gte"
    | "$lt"
    | "$lte"
    | "$null"
    | "$notNull"
    | "$in"
    | "$notIn"
    | "$between"

type QsLogicOperator = "&and" | "&or" | undefined

export type Filter = [boolean, string, QsOperator, QsValue, QsLogicOperator]

/*
 * This function is used to convert the operator from the grid to the operator used by the API
 * List of operators: https://docs.strapi.io/dev-docs/api/rest/filters-locale-publication#filtering
 */
export const getQsOperatorFromGridOperator = (operator: string): QsOperator => {
    switch (operator) {
        case "equals":
        case "is":
            return "$eq"
        case "notEqual":
        case "not":
            return "$ne"
        case "contains":
            return "$contains"
        case "notContains":
            return "$notContains"
        case "after":
            return "$gt"
        case "onOrAfter":
            return "$gte"
        case "before":
            return "$lt"
        case "onOrBefore":
            return "$lte"
        case "isEmpty":
            return "$null"
        case "isNotEmpty":
            return "$notNull"
        case "in":
            return "$in"
        case "notIn":
            return "$notIn"
        case "between":
            return "$between"

        default:
            return "$contains"
    }
}

const getQsValueFromGridValue = (value: GridValue): QsValue => {
    if (value === undefined) {
        return null
    }
    if (value === "true") {
        return true
    }
    if (value === "false") {
        return false
    }

    return value
}

export const buildQueryString = (params: Filter[]) => {
    const queryParams = params.reduce((acc, [hasValue, name, operator, value, logicOperator]) => {
        if (logicOperator === "&or") {
            console.error("logicOperator &or not handled")
        }

        if (hasValue) {
            if (operator === "$in" && Array.isArray(value)) {
                ;(value as string[]).map((v, i) => {
                    acc[`filters[${name}]`] = { [operator]: { [i]: v } }
                })
            } else if (operator === "$null") {
                acc[`filters[${name}]`] = { [operator]: "" }
            } else {
                acc[`filters[${name}]`] = { [operator]: value }
            }
        }
        return acc
    }, {})

    return qs.stringify(queryParams, { encode: false, arrayFormat: "brackets" })
}

export const buildQueryStringFromFilterModel = (filterModel: GridFilterModel) => {
    const filters = filterModel.items.reduce((acc, item) => {
        const value = getQsValueFromGridValue(item.value)
        const hasValue = ["isEmpty", "isNotEmpty"].includes(item.operator) || !!item.value

        return [
            ...acc,
            [
                hasValue,
                item.field,
                getQsOperatorFromGridOperator(item.operator),
                value,
                filterModel.logicOperator || "&and",
            ] as Filter,
        ]
    }, [] as Filter[])

    return buildQueryString(filters)
}
