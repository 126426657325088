import { Stack } from "@mui/material"
import dayjs from "dayjs"
import { useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { HeaderH1, ViewSwitcherTab } from "~/components"
import { InvoicesList } from "~/domains/transactions/invoices-v1/components/list/InvoicesList"
import { InvoicesListHeader } from "~/domains/transactions/invoices-v1/components/list/InvoicesListHeader"
import { InvoiceSummaryTypes } from "~/domains/transactions/invoices-v1/types/Invoice"
import { useTitle } from "~/hooks"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { ViewTypes } from "~/types"

const messages = defineMessages({
    title: { id: "invoices.title", defaultMessage: "Invoices" },
    seeingInvoices: {
        id: "invoices.seeingInvoices",
        defaultMessage: "Seeing {paid} invoices {dueDates}",
    },
    seeingInvoicesDueDates: {
        id: "invoices.seeingInvoicesDueDates",
        defaultMessage: "due between {dueBefore} and {dueAfter}",
    },
})

export const InvoicesPage = () => {
    const { formatMessage } = useIntl()
    const [currentSummaryType, setCurrentSummaryType] = useState<InvoiceSummaryTypes>("all")
    const [currentStatus, setCurrentStatus] = useState<string | undefined>(undefined)
    const [paid, setPaid] = useState<boolean | undefined>(undefined)
    const [dueBefore, setDueBefore] = useState<string | undefined>(undefined)
    const [dueAfter, setDueAfter] = useState<string | undefined>(undefined)
    const [view, setView] = useState<ViewTypes>("buyer")

    const currentOrganization = useAppSelector(selectCurrentOrganization)

    const pageName = formatMessage(messages.title)
    useTitle(pageName)

    const handleSetFilters = (summaryType?: InvoiceSummaryTypes, status?: string) => {
        setCurrentSummaryType(summaryType || "all")
        setCurrentStatus(status)

        switch (summaryType) {
            case "all":
                setDueBefore(undefined)
                setDueAfter(undefined)
                setPaid(undefined)
                break
            case "dueNext7Days":
                setDueBefore(dayjs().add(7, "day").format("YYYY-MM-DD"))
                setDueAfter(dayjs().format("YYYY-MM-DD"))
                setPaid(undefined)
                break
            case "dueLaterThan7Days":
                setDueBefore(undefined)
                setDueAfter(dayjs().add(7, "day").format("YYYY-MM-DD"))
                setPaid(undefined)
                break
            case "lateInvoices":
                setDueBefore(dayjs().format("YYYY-MM-DD"))
                setDueAfter(undefined)
                setPaid(false)
                break
            case "remaining":
                setDueBefore(undefined)
                setDueAfter(undefined)
                setPaid(true)
                break
            default:
                setDueBefore(undefined)
                setDueAfter(undefined)
                setPaid(undefined)
                break
        }
    }

    const handleSwitchViewToBuyer = () => {
        setView("buyer")
        handleSetFilters()
    }

    const handleSwitchViewToSupplier = () => {
        setView("supplier")
        handleSetFilters()
    }

    return (
        <>
            <HeaderH1
                title={pageName}
                mainAction={
                    <ViewSwitcherTab
                        view={view}
                        actionBuyer={handleSwitchViewToBuyer}
                        actionSupplier={handleSwitchViewToSupplier}
                        size="small"
                        marginBottom={0}
                    />
                }
            />
            <Stack className="main-box">
                <InvoicesListHeader
                    currentSummaryType={currentSummaryType}
                    currentStatus={currentStatus}
                    view={view}
                    setFilters={handleSetFilters}
                />

                <InvoicesList
                    organizationId={currentOrganization?.id ?? ""}
                    currentStatus={currentStatus}
                    currentSummaryType={currentSummaryType}
                    dueBefore={dueBefore}
                    dueAfter={dueAfter}
                    paid={paid}
                    view={view}
                    setFilters={handleSetFilters}
                />
            </Stack>
        </>
    )
}
