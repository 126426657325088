/* eslint-disable max-lines */
import { defineMessages } from "react-intl"

export const commonMessages = defineMessages({
    people: {
        id: "common.peopleCount",
        defaultMessage: "{peopleCount, plural, =0 {no one} one {# person} other {# people}}",
    },
    inDevelopment: {
        id: "common.inDevelopment",
        defaultMessage: "in development",
    },
    rowsPerPage: {
        id: "common.pagination.rowsPerPage",
        defaultMessage: "Rows per page:",
    },
    labelDisplayedRowsNoCount: {
        id: "common.pagination.labelDisplayedRowsNoCount",
        defaultMessage: "{from}-{to}",
    },
    labelDisplayedRows: {
        id: "common.pagination.labelDisplayedRows",
        defaultMessage: "{from}-{to} of {count}",
    },
    cancel: {
        id: "common.cancel",
        defaultMessage: "Cancel",
    },
    clear: {
        id: "common.clear",
        defaultMessage: "Clear",
    },
    cancelEdition: {
        id: "common.cancelEdition",
        defaultMessage: "Cancel edition",
    },
    confirm: {
        id: "common.confirm",
        defaultMessage: "Confirm",
    },
    save: {
        id: "common.save",
        defaultMessage: "Save",
    },
    search: {
        id: "common.search",
        defaultMessage: "Search",
    },
    archive: {
        id: "common.archive",
        defaultMessage: "Archive",
    },
    create: {
        id: "common.create",
        defaultMessage: "Create",
    },
    add: {
        id: "common.add",
        defaultMessage: "Add",
    },
    edit: {
        id: "common.edit",
        defaultMessage: "Edit",
    },
    view: {
        id: "common.view",
        defaultMessage: "View",
    },
    delete: {
        id: "common.delete",
        defaultMessage: "Delete",
    },
    close: {
        id: "common.close",
        defaultMessage: "Close",
    },
    closed: {
        id: "common.closed",
        defaultMessage: "Closed",
    },
    new: {
        id: "common.new",
        defaultMessage: "New",
    },
    supplier: {
        id: "common.supplier",
        defaultMessage: "Vendor",
    },
    buyer: {
        id: "common.buyer",
        defaultMessage: "Client",
    },
    error: {
        id: "common.error",
        defaultMessage: "We encountered an error. Please contact your administrator",
    },
    errorUpload: {
        id: "common.errorUpload",
        defaultMessage: "An error occurred while uploading the document",
    },
    from: {
        id: "common.form",
        defaultMessage: "Form",
    },
    email: {
        id: "common.mail",
        defaultMessage: "Email",
    },
    createdDate: {
        id: "common.createdDate",
        defaultMessage: "Created",
    },
    createdBy: {
        id: "common.createdBy",
        defaultMessage: "Created by",
    },
    updatedDate: {
        id: "common.updatedDate",
        defaultMessage: "Updated",
    },
    errorContact: {
        id: "common.errorContact",
        defaultMessage: "Something went wrong. Please contact your administrator",
    },
    errorNoOrganization: {
        id: "common.errorNoOrganization",
        defaultMessage: "This feature is only available for organizations, please create one in your settings.",
    },
    tags: {
        id: "common.tags",
        defaultMessage: "Tags",
    },
    externalIds: {
        id: "common.externalIds",
        defaultMessage: "External IDs",
    },
    radioYes: { id: "common.yes", defaultMessage: "Yes" },
    radioNo: { id: "common.no", defaultMessage: "No" },
    filterByTags: { id: "common.filterByTags", defaultMessage: "Filter by tags" },
    errorRequiredField: {
        id: "common.error.requiredField",
        defaultMessage: "This field is required",
    },
    errorMissingRequiredFields: {
        id: "common.error.missingRequiredFields",
        defaultMessage: "Some required fields are missing or incorrect.",
    },
    requiredField: {
        id: "common.field.required",
        defaultMessage: "{field} is required",
    },
    name: {
        id: "common.name",
        defaultMessage: "Name",
    },
    description: {
        id: "common.description",
        defaultMessage: "Description",
    },
    sku: {
        id: "common.sku",
        defaultMessage: "SKU",
    },
    unitPriceWithoutTax: {
        id: "common.unitPriceWithoutTax",
        defaultMessage: "Unit price (excl. taxes)",
    },
    price: {
        id: "common.price",
        defaultMessage: "Price",
    },
    unitQuantity: {
        id: "common.unitQuantity",
        defaultMessage: "Unit quantity",
    },
    status: {
        id: "common.status",
        defaultMessage: "Status",
    },
    brand: {
        id: "common.brand",
        defaultMessage: "Brand",
    },
    media: {
        id: "common.media",
        defaultMessage: "Media",
    },
    uploadTip: {
        id: "common.upload.fileTypesAndSize",
        defaultMessage: "We accept {FileTypes} files. No more than 2MB.",
    },
    private: {
        id: "common.private",
        defaultMessage: "Private",
    },
    public: {
        id: "common.public",
        defaultMessage: "Public",
    },
    show: {
        id: "common.show",
        defaultMessage: "Show",
    },
    hide: {
        id: "common.hide",
        defaultMessage: "Hide",
    },
    isActive: {
        id: "common.isActive",
        defaultMessage: "Is active",
    },
    catalogType: {
        id: "common.catalogType",
        defaultMessage: "Catalog type",
    },
    accessType: {
        id: "common.accessType",
        defaultMessage: "Access type",
    },
    created: {
        id: "common.created",
        defaultMessage: "Created: {date}",
    },
    updated: {
        id: "common.updated",
        defaultMessage: "Updated: {date}",
    },
    payments: {
        id: "common.payments",
        defaultMessage: "Payments",
    },
    paymentMethod: {
        id: "common.paymentMethod",
        defaultMessage: "Payment method",
    },
    paymentMethodDetails: {
        id: "common.paymentMethodDetails",
        defaultMessage: "Payment method details",
    },
    cashflow: {
        id: "common.cashflow",
        defaultMessage: "Cash flow",
    },
    contact: {
        id: "common.contact",
        defaultMessage: "Contact",
    },
    payment: {
        id: "common.payment",
        defaultMessage: "Payment",
    },
    poLine: {
        id: "common.poLine",
        defaultMessage: "PO line",
    },
    prLine: {
        id: "common.prLine",
        defaultMessage: "PR line",
    },
    partnerPaymentMethodDetails: {
        id: "common.partnerPaymentMethodDetails",
        defaultMessage: "Partner payment method details",
    },
    startDate: {
        id: "common.startDate",
        defaultMessage: "Start date",
    },
    endDate: {
        id: "common.endDate",
        defaultMessage: "End date",
    },
    recurrenceType: {
        id: "common.recurrenceType",
        defaultMessage: "Recurrence type",
    },
    recurrenceInterval: {
        id: "common.recurrenceInterval",
        defaultMessage: "Recurrence interval",
    },
    maximumAmount: {
        id: "common.maximumAmount",
        defaultMessage: "Maximum amount",
    },
    defaultCurrency: {
        id: "common.defaultCurrency",
        defaultMessage: "Default currency",
    },
    aggregatePayments: {
        id: "common.aggregatePayments",
        defaultMessage: "Aggregate payments",
    },
    collectPayments: {
        id: "common.collectPayments",
        defaultMessage: "Collect payments",
    },
    batch: {
        id: "common.batch",
        defaultMessage: "Batch",
    },
    transaction: {
        id: "common.transaction",
        defaultMessage: "Transaction",
    },
    country: {
        id: "common.country",
        defaultMessage: "Country",
    },
    product: {
        id: "common.product",
        defaultMessage: "Product",
    },
    productVariant: {
        id: "common.productVariant",
        defaultMessage: "Product variant",
    },
    addTitle: { id: "common.addTitle", defaultMessage: "Add {instance}" },
    editTitle: { id: "common.editTitle", defaultMessage: "Edit {instance}" },
    deleteTitle: { id: "common.deleteTitle", defaultMessage: "Delete {instance}" },
    deleteConfirmationMessage: {
        id: "common.deleteConfirmationMessage",
        defaultMessage: "Are you sure you want to delete this {instance}? This action cannot be undone.",
    },
    successCreateMessage: {
        id: "common.successCreateMessage",
        defaultMessage: "{instance} successfully created",
    },
    successUpdateMessage: {
        id: "common.successUpdateMessage",
        defaultMessage: "{instance} successfully updated",
    },
    successDeleteMessage: {
        id: "common.successDeleteMessage",
        defaultMessage: "{instance} successfully deleted",
    },
    successSaveMessage: {
        id: "common.successSaveMessage",
        defaultMessage: "{instance} successfully saved",
    },
    errorDeleteMessage: {
        id: "common.errorDeleteMessage",
        defaultMessage: "An error occurred while deleting the {instance}",
    },
    errorSaveMessage: {
        id: "common.errorSaveMessage",
        defaultMessage: "An error occurred while saving the {instance}",
    },
    advanced: {
        id: "common.advanced",
        defaultMessage: "Advanced",
    },
    currency: {
        id: "common.currency",
        defaultMessage: "Currency",
    },
    dateScheduled: {
        id: "common.dateScheduled",
        defaultMessage: "Scheduled date",
    },
    dateExecuted: {
        id: "common.dateExecuted",
        defaultMessage: "Executed date",
    },
    totalAmount: {
        id: "common.totalAmount",
        defaultMessage: "Total amount",
    },
    active: {
        id: "common.status.active",
        defaultMessage: "Active",
    },
    disabled: {
        id: "common.status.disabled",
        defaultMessage: "Disabled",
    },
    executed: {
        id: "common.status.executed",
        defaultMessage: "Executed",
    },
    failed: {
        id: "common.status.failed",
        defaultMessage: "Failed",
    },
    uploaded: {
        id: "common.status.uploaded",
        defaultMessage: "Uploaded",
    },
    validated: {
        id: "common.status.validated",
        defaultMessage: "Validated",
    },
    cancelled: {
        id: "common.status.cancelled",
        defaultMessage: "Cancelled",
    },
    draft: {
        id: "common.status.draft",
        defaultMessage: "Draft",
    },
    open: {
        id: "common.status.open",
        defaultMessage: "Open",
    },
    rejected: {
        id: "common.status.rejected",
        defaultMessage: "Rejected",
    },
    pending: {
        id: "common.status.pending",
        defaultMessage: "Pending",
    },
    confirmed: {
        id: "common.status.confirmed",
        defaultMessage: "Confirmed",
    },
    accepted: {
        id: "common.status.accepted",
        defaultMessage: "Accepted",
    },
    refused: {
        id: "common.status.refused",
        defaultMessage: "Refused",
    },
    canceled: {
        id: "common.status.canceled",
        defaultMessage: "Canceled",
    },
    approved: {
        id: "common.status.approved",
        defaultMessage: "Approved",
    },
    acceptedBySupplier: {
        id: "common.status.acceptedBySupplier",
        defaultMessage: "Accepted by supplier",
    },
    scheduled: {
        id: "common.status.scheduled",
        defaultMessage: "Scheduled",
    },
    completed: {
        id: "common.status.completed",
        defaultMessage: "Completed",
    },
    discontinued: {
        id: "common.status.discontinued",
        defaultMessage: "Discontinued",
    },
    all: {
        id: "common.all",
        defaultMessage: "All",
    },
    invoices: {
        id: "common.invoices",
        defaultMessage: "Invoices",
    },
    invoice: {
        id: "common.invoice",
        defaultMessage: "Invoice",
    },
    partner: {
        id: "common.partner",
        defaultMessage: "Partner",
    },
    download: {
        id: "common.download",
        defaultMessage: "Download",
    },
    missingMandatoryTagGroups: {
        id: "common.missingMandatoryTagGroups",
        defaultMessage:
            "You need to fill in the mandatory tag from {count, plural, one {# group} other {# groups}}: {tagGroups}",
    },
    submit: {
        id: "common.submit",
        defaultMessage: "Submit",
    },
    default: {
        id: "common.default",
        defaultMessage: "Default",
    },
    setAsDefault: {
        id: "common.setAsDefault",
        defaultMessage: "Set as default",
    },
    sharePermissions: {
        id: "common.sharePermissions",
        defaultMessage: "Share permissions",
    },
    sendOrder: {
        id: "common.sendOrder",
        defaultMessage: "Send order",
    },
    sent: {
        id: "common.sent",
        defaultMessage: "Sent",
    },
    share: {
        id: "common.share",
        defaultMessage: "Share",
    },
    validate: {
        id: "common.validate",
        defaultMessage: "Validate",
    },
    batchImport: {
        id: "common.batchImport",
        defaultMessage: "Import in batch (csv, xlsx)",
    },
    next: {
        id: "common.next",
        defaultMessage: "Next",
    },
    fulfillment: {
        id: "common.fulfillment",
        defaultMessage: "Fulfillment",
    },
    uploadFile: {
        id: "common.uploadFile",
        defaultMessage: "Upload file",
    },
    accept: {
        id: "common.accept",
        defaultMessage: "Accept",
    },
    refuse: {
        id: "common.refuse",
        defaultMessage: "Refuse",
    },
    approval: {
        id: "common.approval",
        defaultMessage: "Approval",
    },
    approve: {
        id: "common.approve",
        defaultMessage: "Approve",
    },
    inProgress: {
        id: "common.status.inProgress",
        defaultMessage: "In progress",
    },
    beta: {
        id: "common.beta",
        defaultMessage: "beta",
    },
    send: {
        id: "common.send",
        defaultMessage: "Send",
    },
    errorChangedOrganization: {
        id: "common.errorChangedOrganization",
        defaultMessage: "You have changed the organization. This organization does not have this document.",
    },
    reopen: {
        id: "common.reopen",
        defaultMessage: "Reopen",
    },
    total: {
        id: "common.total",
        defaultMessage: "Total",
    },
    noSharedPermissions: {
        id: "common.noSharedPermissions",
        defaultMessage: "No shared permissions",
    },
    analytics: {
        id: "common.analytics",
        defaultMessage: "Analytics",
    },
    communication: {
        id: "common.communication",
        defaultMessage: "Communication",
    },
    orchestration: {
        id: "common.orchestration",
        defaultMessage: "Orchestration",
    },
    identity: {
        id: "common.identity",
        defaultMessage: "Identity",
    },
    filters: {
        id: "common.filters",
        defaultMessage: "Filters",
    },
    columns: {
        id: "common.columns",
        defaultMessage: "Columns",
    },
    only: {
        id: "common.only",
        defaultMessage: "ONLY",
    },
    discount: {
        id: "common.discount",
        defaultMessage: "Discount",
    },
    amount: {
        id: "common.amount",
        defaultMessage: "Amount",
    },
    budgets: {
        id: "common.budgets",
        defaultMessage: "Budgets",
    },
})
