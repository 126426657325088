import { isRejectedWithValue } from "@reduxjs/toolkit"
import type { Dispatch, Middleware, MiddlewareAPI, UnknownAction } from "@reduxjs/toolkit"
import * as Sentry from "@sentry/react"
import { createIntl } from "react-intl"
import { toast } from "react-toastify"

import { messages } from "~/i18n/messages"
import { ErrorMeta, RootState, ServerError } from "~/store"

export const errorLoggerMiddleware: Middleware =
    (middlewareApi: MiddlewareAPI<Dispatch<UnknownAction>, RootState>) => (next) => (action: unknown) => {
        // We need to type check before using action properties

        if (!isRejectedWithValue(action)) {
            return next(action as UnknownAction)
        }

        const organizationId = middlewareApi.getState()?.organization?.currentOrganizationId || ""
        const userId = middlewareApi.getState()?.account?.userId || ""
        const locale = middlewareApi.getState()?.global?.lang || "en"
        const intl = createIntl({ locale, messages: messages[locale] })

        const payload = action.payload as {
            meta?: ErrorMeta
            error?: Error
            originalStatus?: number
            status?: number
            data?: ServerError
        }

        const meta = payload?.meta
        const serverError = payload?.data

        // For some url, the originalStatus is 200, even if the request is rejected
        const hasErrorStatus = (payload?.originalStatus || 200) >= 400 || (payload?.status ?? 200) >= 400

        if (!meta?.disableToast) {
            if (meta?.errorMessage && hasErrorStatus) {
                toast.error(intl.formatMessage(meta.errorMessage))
            } else if (serverError?.message || serverError?.error) {
                toast.error(serverError.message || serverError.error)
            }
        }

        if (!meta?.disableSentryException) {
            Sentry.captureException(action.type, {
                extra: {
                    organizationId,
                    userId,
                    error: payload,
                },
                tags: meta?.tags ?? {},
            })
        }

        return next(action)
    }
