import { Stack, Typography } from "@mui/material"
import React from "react"
import { MessageDescriptor, defineMessages, useIntl } from "react-intl"

import { Button, Loader, Modal, SafeFormattedMessage, Size } from "~/components"
import { CheckFileTypeResult, UploadDocumentBox } from "~/components/UploadDocument/UploadDocument"

const messages = defineMessages({
    cancel: { id: "common.cancel", defaultMessage: "Cancel" },
    import: { id: "purchase.requests.modalImportBatch.import", defaultMessage: "Import" },
    loadingMessage: {
        id: "purchase.requests.modalImportBatch.loadingMessage",
        defaultMessage: "Import in progress, it can take up to 20 seconds...",
    },
    formatMessage: {
        id: "purchase.requests.modalImportBatch.formatMessage",
        defaultMessage: "Find more detailed instructions <em>here.</em>",
    },
})

interface Props {
    open: boolean
    close: () => void
    handleFile: (file: File) => void
    checkFileType: (file: File) => CheckFileTypeResult
    handleClickInstructions?: () => void
    loading: boolean
    uploadTip: MessageDescriptor
    title: MessageDescriptor
}

export const ModalBatchImport: React.FC<Props> = ({
    open,
    close,
    handleFile,
    checkFileType,
    handleClickInstructions,
    loading,
    uploadTip,
    title,
}) => {
    const { formatMessage } = useIntl()

    return (
        <Modal open={open} onClose={close} size={Size.MD}>
            <Modal.Header>
                <h4>{formatMessage(title)}</h4>
            </Modal.Header>

            <Modal.Content>
                {loading ? (
                    <Stack justifyContent="center" alignItems="center" textAlign="center" gap={2}>
                        <Loader small />
                        <Typography variant="body2" color="var(--color-primary)">
                            {formatMessage(messages.loadingMessage)}
                        </Typography>
                    </Stack>
                ) : (
                    <Stack justifyContent="center" alignItems="center" gap={2}>
                        <UploadDocumentBox
                            handleFile={handleFile}
                            conditionMessage={formatMessage(uploadTip)}
                            checkFileType={checkFileType}
                        />

                        {handleClickInstructions ? (
                            <Typography
                                variant="body2"
                                color="var(--color-grey-light)"
                                onClick={handleClickInstructions}
                            >
                                <SafeFormattedMessage
                                    {...messages.formatMessage}
                                    values={{
                                        em: (msg) => <em style={{ color: "var(--color-primary)" }}>{msg}</em>,
                                    }}
                                />
                            </Typography>
                        ) : null}
                    </Stack>
                )}
            </Modal.Content>

            <Modal.Footer>
                <Button type="transparent" onClick={close}>
                    {formatMessage(messages.cancel)}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
