import { LinearProgress } from "@mui/material"
import {
    GridFilterModel,
    GridPaginationModel,
    GridRowParams,
    GridRowSelectionModel,
    GridSortModel,
    useGridApiRef,
} from "@mui/x-data-grid-premium"
import { useEffect, useMemo, useState } from "react"
import { generatePath, useNavigate } from "react-router-dom"

import { DataGridNoRows } from "~/components/DataGrid/DataGridNoRows"
import { DataGridPremiumWithState } from "~/components/DataGrid/DataGridPremiumWithState"
import { initialState } from "~/constants/dataGrid"
import { selectSelectedTagsForFilter } from "~/domains/analytics/tags/store/tagsSlice"
import { useGetPurchaseOrdersQuery } from "~/domains/transactions/purchase-orders/api/purchaseOrderApi"
import { PurchaseOrdersBulkActions } from "~/domains/transactions/purchase-orders/components/list/PurchaseOrdersBulkActions"
import { useGetPurchaseOrdersListTableColumns } from "~/domains/transactions/purchase-orders/hooks/useGetPurchaseOrdersListTableColumns"
import { PURCHASE_ORDER_ROUTE } from "~/domains/transactions/purchase-orders/routes"
import {
    purchaseOrdersActions,
    selectCurrentPurchaseOrdersTab,
    selectPurchaseOrderFilter,
} from "~/domains/transactions/purchase-orders/store/purchaseOrdersSlice"
import {
    PurchaseOrderFulfillmentPresentation,
    PurchaseOrderFulfillmentPresentationEnum,
    PurchaseOrderStatusPresentation,
    PurchaseOrderStatusPresentationEnum,
    PurchaseOrders,
} from "~/domains/transactions/purchase-orders/types"
import { selectDataGridStateByStateKey } from "~/store/global/globalSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { ViewTypeI } from "~/types"
import { DEFAULT_PAGE_SIZE } from "~/utils/table"

export const DEFAULT_PURCHASE_ORDER_SORT_BY = "expectedDeliveryDate"

const STATE_KEY = "purchaseOrdersList"

export interface PaginationQueryOptions extends GridPaginationModel {
    buyerId?: string
    supplierId?: string
    statusPresentation?: string
    fulfillmentPresentation?: string
    description?: string
    filterModel?: GridFilterModel
    tagIds?: string[]
}

interface PaginatedListProps {
    view: ViewTypeI
}

export const PaginatedList = ({ view }: PaginatedListProps) => {
    const apiRef = useGridApiRef()
    const [selectedRows, setSelectedRows] = useState<string[]>([])
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: DEFAULT_PURCHASE_ORDER_SORT_BY, sort: "desc" }])

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const organizationId = useAppSelector(selectCurrentOrganizationId)
    const purchaseOrderFilter = useAppSelector(selectPurchaseOrderFilter)
    const currentPurchaseOrdersTab = useAppSelector(selectCurrentPurchaseOrdersTab)
    const selectedTags = useAppSelector(selectSelectedTagsForFilter)

    const isStatusPresentation = useMemo(
        () => PurchaseOrderStatusPresentationEnum.includes(currentPurchaseOrdersTab as PurchaseOrderStatusPresentation),
        [currentPurchaseOrdersTab]
    )

    const isFulfillmentPresentation = useMemo(
        () =>
            PurchaseOrderFulfillmentPresentationEnum.includes(
                currentPurchaseOrdersTab as PurchaseOrderFulfillmentPresentation
            ),
        [currentPurchaseOrdersTab]
    )

    const storedState = useAppSelector((state) => selectDataGridStateByStateKey(state, STATE_KEY))

    const initialGridState = {
        ...initialState,
        ...storedState,
        columns: {
            ...storedState?.columns,
            columnVisibilityModel: {
                ...(storedState?.columns?.columnVisibilityModel ?? {}),
                supplierName: view === ViewTypeI.buyer,
                buyerName: view === ViewTypeI.supplier,
            },
        },
    }

    const initialQueryOptions = {
        page: initialGridState?.pagination?.paginationModel?.page ?? 0,
        pageSize: initialGridState?.pagination?.paginationModel?.pageSize ?? DEFAULT_PAGE_SIZE,
        buyerId: view === ViewTypeI.buyer ? organizationId : undefined,
        supplierId: view === ViewTypeI.supplier ? organizationId : undefined,
        description: purchaseOrderFilter ?? undefined,
        statusPresentation: isStatusPresentation ? currentPurchaseOrdersTab : undefined,
        fulfillmentPresentation: isFulfillmentPresentation ? currentPurchaseOrdersTab : undefined,
        filterModel: initialGridState?.filter?.filterModel ?? undefined,
        tagIds: selectedTags.map((selectedTag) => selectedTag.tagId),
    }
    const [queryOptions, setQueryOptions] = useState<PaginationQueryOptions>(initialQueryOptions)

    const { data, isLoading, isFetching } = useGetPurchaseOrdersQuery({
        organizationId: organizationId ?? "",
        offset: queryOptions.page * queryOptions.pageSize,
        limit: queryOptions.pageSize,
        statusPresentation: queryOptions.statusPresentation,
        fulfillmentPresentation: queryOptions.fulfillmentPresentation,
        description: queryOptions.description,
        buyerId: queryOptions.buyerId,
        supplierId: queryOptions.supplierId,
        filterModel: queryOptions.filterModel,
        tagIds: queryOptions.tagIds,
        sortBy: (sortModel?.[0]?.field || DEFAULT_PURCHASE_ORDER_SORT_BY) as keyof PurchaseOrders,
        ascending: sortModel?.[0]?.sort === "asc",
    })

    const purchaseOrders = data?.items ?? []
    const rowCountState = data?.total ?? 0

    const resetSearchQuery = () => {
        setQueryOptions(initialQueryOptions)
        dispatch(purchaseOrdersActions.setFilter(""))
    }

    const columns = useGetPurchaseOrdersListTableColumns()

    useEffect(() => {
        setQueryOptions((prev) => ({
            ...prev,
            description: purchaseOrderFilter,
        }))
    }, [purchaseOrderFilter])

    useEffect(() => {
        setQueryOptions((prev) => ({
            ...prev,
            tagIds: selectedTags.map((selectedTag) => selectedTag.tagId),
        }))
    }, [selectedTags])

    useEffect(() => {
        setQueryOptions((prev) => ({
            ...prev,
            buyerId: view === ViewTypeI.buyer ? organizationId : undefined,
            supplierId: view === ViewTypeI.supplier ? organizationId : undefined,
        }))
    }, [organizationId, view])

    const handlePaginationModelChange = (paginationModel: GridPaginationModel) =>
        setQueryOptions((prev) => ({ ...prev, ...paginationModel }))

    const handleRowClick = (params: GridRowParams<PurchaseOrders>) =>
        navigate(generatePath(PURCHASE_ORDER_ROUTE, { purchaseOrderId: params.row.id as string }))

    const handleSelectRows = (rowSelectionModel: GridRowSelectionModel) =>
        setSelectedRows(rowSelectionModel as string[])

    const handleFilterModelChange = (model: GridFilterModel) => {
        setQueryOptions((prev) => ({
            ...prev,
            filterModel: model,
        }))
    }

    const handleSortModelChange = (model: GridSortModel) => {
        setSortModel(model)
        resetSearchQuery()
    }

    return (
        <>
            <DataGridPremiumWithState
                stateKey={STATE_KEY}
                apiRef={apiRef}
                columns={columns}
                rows={purchaseOrders ?? []}
                loading={isFetching || isLoading}
                slots={{
                    loadingOverlay: LinearProgress,
                    noRowsOverlay: () => <DataGridNoRows resetSearchQuery={resetSearchQuery} />,
                }}
                initialState={initialGridState}
                sortingMode="server"
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                filterMode="server"
                filterModel={queryOptions.filterModel}
                filterDebounceMs={500}
                onFilterModelChange={handleFilterModelChange}
                onRowClick={handleRowClick}
                disableRowSelectionOnClick
                checkboxSelection
                rowSelectionModel={selectedRows}
                onRowSelectionModelChange={handleSelectRows}
                /* Server-side pagination */
                pagination
                rowCount={rowCountState}
                paginationMode="server"
                onPaginationModelChange={handlePaginationModelChange}
            />

            <PurchaseOrdersBulkActions
                selected={selectedRows}
                setSelected={setSelectedRows}
                purchaseOrders={purchaseOrders}
                view={view}
                tabValue={currentPurchaseOrdersTab}
            />
        </>
    )
}
