/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Alert, Stack, Typography } from "@mui/material"
import React, { memo, useCallback, useEffect, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { SafeFormattedMessage } from "~/components"
import { CheckFileTypeResult, UploadDocumentBox, boxSize } from "~/components/UploadDocument/UploadDocument"
import { useUploadDocument } from "~/components/UploadDocument/hooks"
import { useConvertPdfToImagesMutation } from "~/domains/_shared/ocr/api/ocrApi_RTK"
import { useDocumentById } from "~/domains/identity/documents/hooks"
import { DocumentObjectType, DocumentType, UploadDocumentDTO } from "~/domains/identity/documents/types"
import { useProcessDocumentMutation } from "~/domains/orchestration/copilot/api/processDocumentApi"
import { useGetManualVerificationsByIdQuery } from "~/domains/payment/payment-method-details/api/paymentMethodDetailsApi"
import { PaymentMethodUploadPreview } from "~/domains/payment/payment-method-details/components/modal/PaymentMethodUploadPreview"
import {
    paymentMethodDetailsActions,
    selectSelectedItem,
} from "~/domains/payment/payment-method-details/store/paymentMethodDetailsSlice"
import { ManualVerification, VerificationMethod } from "~/domains/payment/payment-method-details/types"
import { mapToEdit } from "~/domains/payment/payment-method-details/utils/mapPaymentMethodDetails"
import { PaymentMethodType } from "~/domains/payment/payment-methods/types"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { checkMultiFileTypes } from "~/utils/upload"

const messages = defineMessages({
    description: {
        id: "payment.paymentMethodDetailsManagement.upload.description",
        defaultMessage: "Upload your document containing bank details, we will extract the information for you.",
    },
    loadingMessage: {
        id: "common.upload.loadingMessage",
        defaultMessage: "Upload in progress... This may take a few seconds.",
    },
    errorWrongFileType: {
        id: "common.upload.error.errorWrongFileType",
        defaultMessage: "Wrong file format: we only accept {FileTypes} files.",
    },
    fileUploadWarning: {
        id: "payment.paymentMethodDetailsManagement.upload.fileUploadWarning",
        defaultMessage:
            "Previously, the payment method might have been created either by uploading a payment RIB file or by filling in the information manually. Going forward, you can upload the payment RIB file for any existing payment method, and it will be saved and displayed in the system for future reference.",
    },
})

const ACCEPTED_FILE_TYPES = ["application/pdf", "image/png", "image/webp", "image/jpeg"]
const ACCEPT_FILE_DISPLAY = "PDF, PNG, JPG"

export const PaymentMethodUpload: React.FC = memo(() => {
    const organizationId = useAppSelector(selectCurrentOrganizationId)
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()

    const selectedPaymentMethodDetails = useAppSelector(selectSelectedItem)
    const [processDocument, { isLoading: processDocumentLoading }] = useProcessDocumentMutation()
    const [convertPdfToImages, { data: pdfAsImages, reset: resetPdfToImagesResult }] = useConvertPdfToImagesMutation()
    const [preview, setPreview] = useState<string | null>(null)
    const [isPreviewLoading, setIsPreviewLoading] = useState(false)

    const { uploadDocument } = useUploadDocument(organizationId ?? "")
    const { document, loading } = useDocumentById(selectedPaymentMethodDetails?.documentId)

    const {
        data: manualVerificationsData,
        isLoading: isManualVerificationLoading,
        isSuccess,
    } = useGetManualVerificationsByIdQuery(selectedPaymentMethodDetails?.id ?? "", {
        skip: !selectedPaymentMethodDetails?.id,
    })

    const manualVerification =
        manualVerificationsData?.items?.find(
            (m: ManualVerification) =>
                m?.verification_method === VerificationMethod.DOCUMENT && m?.verification_documents?.document_id
        ) ?? null

    useEffect(() => {
        if (selectedPaymentMethodDetails && manualVerification?.verification_documents?.document_id && isSuccess) {
            dispatch(
                paymentMethodDetailsActions.setSelectedItem({
                    ...selectedPaymentMethodDetails,
                    documentId: manualVerification?.verification_documents.document_id,
                })
            )
        }
    }, [manualVerification?.id, selectedPaymentMethodDetails?.id, isSuccess])

    useEffect(() => {
        const fetchDocumentPreview = async () => {
            if (!document?.latestFileVersion || isPreviewLoading || pdfAsImages) return

            try {
                setIsPreviewLoading(true)

                const response = await fetch(document?.latestFileVersion)
                const blob = await response.blob()

                await convertPdfToImages(blob as File)
            } catch (error) {
                console.error("Error fetching document preview:", error)
            } finally {
                setIsPreviewLoading(false)
            }
        }

        fetchDocumentPreview()
    }, [document?.latestFileVersion, pdfAsImages, isPreviewLoading])

    const checkFileType = useCallback(
        (file: File): CheckFileTypeResult =>
            checkMultiFileTypes(
                file,
                ACCEPTED_FILE_TYPES,
                formatMessage(messages.errorWrongFileType, {
                    FileTypes: ACCEPT_FILE_DISPLAY,
                })
            ),
        [formatMessage]
    )

    const handleFile = useCallback(async (file: File) => {
        if (!organizationId) return
        resetPdfToImagesResult()
        setPreview(null)
        if (file.type.startsWith("application/")) {
            convertPdfToImages(file)
        } else {
            const reader = new FileReader()
            reader.onload = function (event) {
                if (event.target && typeof event.target.result === "string") {
                    setPreview(event.target.result)
                }
            }
            reader.readAsDataURL(file)
        }
        const uploadPaymentMethodData = await processDocument({ file, organizationId }).unwrap()

        const payload: UploadDocumentDTO = {
            file,
            name: file.name,
            documentType: DocumentType.PAYMENT_METHOD_DETAILS,
            objectId: uploadPaymentMethodData?.paymentMethodDetails?.id ?? "",
            objectType: DocumentObjectType.PAYMENT,
            organizationIds: [organizationId],
        }
        const uploadedDocument = await uploadDocument(payload)

        const bankTransfer = mapToEdit(uploadPaymentMethodData?.paymentMethodDetails?.bankTransfer)

        const toSave = {
            ...(selectedPaymentMethodDetails ?? {}),
            ...bankTransfer,
            paymentMethodType: PaymentMethodType.BANK_TRANSFER,
            id: selectedPaymentMethodDetails?.id,
            documentId: uploadedDocument?.id,
        }

        dispatch(paymentMethodDetailsActions.setSelectedItem(toSave))
    }, [])

    const pages = preview ? [preview] : pdfAsImages

    return processDocumentLoading ? (
        <Stack gap={1}>
            <PaymentMethodUploadPreview pages={pages} loading={true} />
            <Typography color="primary.main">{formatMessage(messages.loadingMessage)}</Typography>
        </Stack>
    ) : (
        <Stack gap={2}>
            {selectedPaymentMethodDetails?.id && !loading && (!pages || !pages?.length) && (
                <Alert severity="info">
                    <SafeFormattedMessage {...messages.fileUploadWarning} />
                </Alert>
            )}
            <PaymentMethodUploadPreview pages={pages} loading={isPreviewLoading || isManualVerificationLoading} />
            <UploadDocumentBox
                handleFile={handleFile}
                conditionMessage={formatMessage(commonMessages.uploadTip, {
                    FileTypes: ACCEPT_FILE_DISPLAY,
                })}
                checkFileType={checkFileType}
                accept={ACCEPTED_FILE_TYPES.join(",")}
                size={boxSize.MEDIUM}
            />
            <i>
                <SafeFormattedMessage {...messages.description} />
            </i>
        </Stack>
    )
})

PaymentMethodUpload.displayName = "PaymentMethodUpload"
