import React, { useState } from "react"
import { Send } from "react-feather"
import { MessageDescriptor, defineMessages } from "react-intl"

import { SafeFormattedMessage, ShareObjectModal } from "~/components"
import { OrganizationSummary } from "~/domains/transactions/_shared/components/Organizations/Organizations"
import { OrganizationId } from "~/types"
import { SharedObjectType } from "~/types/SharedObjects"

import { Button } from "../Button/Button"

const messages = defineMessages({
    share: { id: "share.object.modal.share", defaultMessage: "Share" },
})

interface Props {
    organizationId: OrganizationId
    objectType: SharedObjectType
    objectId: string
    buyerOrganization: OrganizationSummary | undefined
    supplierOrganization: OrganizationSummary | undefined
    buttonMessage?: MessageDescriptor
}

export const ShareButton: React.FC<Props> = ({
    organizationId,
    objectType,
    objectId,
    buyerOrganization,
    supplierOrganization,
    buttonMessage,
}) => {
    const [shareObjectModalOpen, setShareObjectModalOpen] = useState(false)

    const showShareObjectModal = () => setShareObjectModalOpen(true)
    const hideShareObjectModal = () => setShareObjectModalOpen(false)

    return (
        <>
            <Button type="menu-item" onClick={showShareObjectModal}>
                <Send size={16} />
                <SafeFormattedMessage {...(buttonMessage || messages.share)} />
            </Button>

            {shareObjectModalOpen && buyerOrganization && supplierOrganization ? (
                <ShareObjectModal
                    open={shareObjectModalOpen}
                    close={hideShareObjectModal}
                    organizationId={organizationId}
                    objectType={objectType}
                    objectId={objectId}
                    buyerOrganization={buyerOrganization}
                    supplierOrganization={supplierOrganization}
                    sendShareEmail={true}
                />
            ) : null}
        </>
    )
}
