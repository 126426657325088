import { Breadcrumbs } from "@mui/material"
import React from "react"
import { ChevronRight } from "react-feather"

import { Card } from "~/components"
import { Step, StepI } from "~/domains/transactions/_shared/components/Steps/Step"

interface StepsProps {
    steps: StepI[]
}

export const Steps = ({ steps }: StepsProps) => {
    return (
        <Card className="w-full p-0">
            <Breadcrumbs separator={<ChevronRight color="var(--color-grey)" size={14} />}>
                {steps.map((step) => (
                    <Step
                        {...step}
                        key={step.key}
                        status={step.status || (step.isCurrentLifecycle ? "next" : "done")}
                    />
                ))}
            </Breadcrumbs>
        </Card>
    )
}
