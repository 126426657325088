import { MessageDescriptor, defineMessages } from "react-intl"

import { UnitEnum } from "~/domains/transactions/_shared/types/Unit"

export const unitMessages: Record<UnitEnum, MessageDescriptor> = defineMessages({
    [UnitEnum.UNIT]: {
        id: "common.unit.UNIT",
        defaultMessage: "Unit",
    },
    [UnitEnum.LINEAR_METER]: {
        id: "common.unit.LINEAR_METER",
        defaultMessage: "Linear meter",
    },
    [UnitEnum.DAY]: {
        id: "common.unit.DAY",
        defaultMessage: "Day",
    },
    [UnitEnum.TON]: {
        id: "common.unit.TON",
        defaultMessage: "Ton",
    },
    [UnitEnum.AMOUNT]: {
        id: "common.unit.AMOUNT",
        defaultMessage: "Amount",
    },
    [UnitEnum.METRE_CUBE]: {
        id: "common.unit.METRE_CUBE",
        defaultMessage: "Cubic meter",
    },
    [UnitEnum.HOUR]: {
        id: "common.unit.HOUR",
        defaultMessage: "Hour",
    },
    [UnitEnum.BOX]: {
        id: "common.unit.BOX",
        defaultMessage: "Box",
    },
    [UnitEnum.MONTH]: {
        id: "common.unit.MONTH",
        defaultMessage: "Month",
    },
    [UnitEnum.METER]: {
        id: "common.unit.METER",
        defaultMessage: "Meter",
    },
    [UnitEnum.SQUARE_METER]: {
        id: "common.unit.SQUARE_METER",
        defaultMessage: "Square meter",
    },
    [UnitEnum.LITER]: {
        id: "common.unit.LITER",
        defaultMessage: "Liter",
    },
    [UnitEnum.KILOGRAM]: {
        id: "common.unit.KILOGRAM",
        defaultMessage: "Kilogram",
    },
    [UnitEnum.HALF_DAY]: {
        id: "common.unit.HALF_DAY",
        defaultMessage: "Half-day",
    },
    [UnitEnum.BAG]: {
        id: "common.unit.BAG",
        defaultMessage: "Bag",
    },
    [UnitEnum.PAIR]: {
        id: "common.unit.PAIR",
        defaultMessage: "Pair",
    },
    [UnitEnum.SET]: {
        id: "common.unit.SET",
        defaultMessage: "Set",
    },
    [UnitEnum.PIECE]: {
        id: "common.unit.PIECE",
        defaultMessage: "Piece",
    },
    [UnitEnum.CARTON]: {
        id: "common.unit.CARTON",
        defaultMessage: "Carton",
    },
    [UnitEnum.PACKAGE]: {
        id: "common.unit.PACKAGE",
        defaultMessage: "Package",
    },
    [UnitEnum.ROLL]: {
        id: "common.unit.ROLL",
        defaultMessage: "Roll",
    },
    [UnitEnum.BAGGIE]: {
        id: "common.unit.BAGGIE",
        defaultMessage: "Baggie",
    },
    [UnitEnum.CAN]: {
        id: "common.unit.CAN",
        defaultMessage: "Can",
    },
    [UnitEnum.LOT]: {
        id: "common.unit.LOT",
        defaultMessage: "Lot",
    },
    [UnitEnum.BAR]: {
        id: "common.unit.BAR",
        defaultMessage: "Bar",
    },
    [UnitEnum.WEEK]: {
        id: "common.unit.WEEK",
        defaultMessage: "Week",
    },
    [UnitEnum.KILOMETER]: {
        id: "common.unit.KILOMETER",
        defaultMessage: "Kilometer",
    },
    [UnitEnum.PALLET]: {
        id: "common.unit.PALLET",
        defaultMessage: "Pallet",
    },
    [UnitEnum.REEL]: {
        id: "common.unit.REEL",
        defaultMessage: "Reel",
    },
    [UnitEnum.TRUCK_TOUR]: {
        id: "common.unit.TRUCK_TOUR",
        defaultMessage: "Truck tour",
    },
    [UnitEnum.BOTTLE]: {
        id: "common.unit.BOTTLE",
        defaultMessage: "Bottle",
    },
    [UnitEnum.COIL]: {
        id: "common.unit.COIL",
        defaultMessage: "Coil",
    },
    [UnitEnum.QUARTER_DAY]: {
        id: "common.unit.QUARTER_DAY",
        defaultMessage: "Quarter day",
    },
    [UnitEnum.QUARTER]: {
        id: "common.unit.QUARTER",
        defaultMessage: "Quarter",
    },
    [UnitEnum.BUCKET]: {
        id: "common.unit.BUCKET",
        defaultMessage: "Bucket",
    },
    [UnitEnum.DRUM]: {
        id: "common.unit.DRUM",
        defaultMessage: "Drum",
    },
})

export const unitSymbolMessages: Record<UnitEnum, MessageDescriptor> = defineMessages({
    [UnitEnum.UNIT]: { id: "common.unit.symbol.UNIT", defaultMessage: "U" },
    [UnitEnum.LINEAR_METER]: { id: "common.unit.symbol.LINEAR_METER", defaultMessage: "ml" },
    [UnitEnum.DAY]: { id: "common.unit.symbol.DAY", defaultMessage: "d" },
    [UnitEnum.TON]: { id: "common.unit.symbol.TON", defaultMessage: "t" },
    [UnitEnum.AMOUNT]: { id: "common.unit.symbol.AMOUNT", defaultMessage: "amt" },
    [UnitEnum.METRE_CUBE]: { id: "common.unit.symbol.METRE_CUBE", defaultMessage: "m³" },
    [UnitEnum.HOUR]: { id: "common.unit.symbol.HOUR", defaultMessage: "h" },
    [UnitEnum.BOX]: { id: "common.unit.symbol.BOX", defaultMessage: "box" },
    [UnitEnum.MONTH]: { id: "common.unit.symbol.MONTH", defaultMessage: "mo" },
    [UnitEnum.METER]: { id: "common.unit.symbol.METER", defaultMessage: "m" },
    [UnitEnum.SQUARE_METER]: { id: "common.unit.symbol.SQUARE_METER", defaultMessage: "m²" },
    [UnitEnum.LITER]: { id: "common.unit.symbol.LITER", defaultMessage: "L" },
    [UnitEnum.KILOGRAM]: { id: "common.unit.symbol.KILOGRAM", defaultMessage: "kg" },
    [UnitEnum.HALF_DAY]: { id: "common.unit.symbol.HALF_DAY", defaultMessage: "half-d" },
    [UnitEnum.BAG]: { id: "common.unit.symbol.BAG", defaultMessage: "bag" },
    [UnitEnum.PAIR]: { id: "common.unit.symbol.PAIR", defaultMessage: "pr" },
    [UnitEnum.SET]: { id: "common.unit.symbol.SET", defaultMessage: "set" },
    [UnitEnum.PIECE]: { id: "common.unit.symbol.PIECE", defaultMessage: "pc" },
    [UnitEnum.CARTON]: { id: "common.unit.symbol.CARTON", defaultMessage: "ctn" },
    [UnitEnum.PACKAGE]: { id: "common.unit.symbol.PACKAGE", defaultMessage: "pkg" },
    [UnitEnum.ROLL]: { id: "common.unit.symbol.ROLL", defaultMessage: "roll" },
    [UnitEnum.BAGGIE]: { id: "common.unit.symbol.BAGGIE", defaultMessage: "bagg" },
    [UnitEnum.CAN]: { id: "common.unit.symbol.CAN", defaultMessage: "can" },
    [UnitEnum.LOT]: { id: "common.unit.symbol.LOT", defaultMessage: "lot" },
    [UnitEnum.BAR]: { id: "common.unit.symbol.BAR", defaultMessage: "bar" },
    [UnitEnum.WEEK]: { id: "common.unit.symbol.WEEK", defaultMessage: "wk" },
    [UnitEnum.KILOMETER]: { id: "common.unit.symbol.KILOMETER", defaultMessage: "km" },
    [UnitEnum.PALLET]: { id: "common.unit.symbol.PALLET", defaultMessage: "pallet" },
    [UnitEnum.REEL]: { id: "common.unit.symbol.REEL", defaultMessage: "reel" },
    [UnitEnum.TRUCK_TOUR]: { id: "common.unit.symbol.TRUCK_TOUR", defaultMessage: "tr" },
    [UnitEnum.BOTTLE]: { id: "common.unit.symbol.BOTTLE", defaultMessage: "btl" },
    [UnitEnum.COIL]: { id: "common.unit.symbol.COIL", defaultMessage: "coil" },
    [UnitEnum.QUARTER_DAY]: { id: "common.unit.symbol.QUARTER_DAY", defaultMessage: "qd" },
    [UnitEnum.QUARTER]: { id: "common.unit.symbol.QUARTER", defaultMessage: "qtr" },
    [UnitEnum.BUCKET]: { id: "common.unit.symbol.BUCKET", defaultMessage: "bucket" },
    [UnitEnum.DRUM]: { id: "common.unit.symbol.DRUM", defaultMessage: "drum" },
})
