import { useEffect, useState } from "react"

import { useLazyGetOrganizationByIdQuery } from "~/domains/identity/organization/api/organisationV2Api"
import { selectPartnersBrandNames } from "~/domains/identity/partners/store/bookOfRelationsSlice"
import { useAppSelector } from "~/store/hooks"

interface OrganizationNameProps {
    organizationId: string
}

export const OrganizationName = ({ organizationId }: OrganizationNameProps) => {
    const [organizationName, setOrganizationName] = useState<string | null>(null)
    const brandNames = useAppSelector(selectPartnersBrandNames)

    const [getOrganizationById, { data }] = useLazyGetOrganizationByIdQuery()

    useEffect(() => {
        if (!brandNames) return
        ;(async () => {
            if (brandNames[organizationId]) {
                setOrganizationName(brandNames[organizationId])
            } else {
                await getOrganizationById({ organizationId })
                setOrganizationName(data?.name ?? null)
            }
        })()
    }, [getOrganizationById, organizationId, brandNames, data])

    return <div>{organizationName ?? "-"}</div>
}
