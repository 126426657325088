import { useCallback, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { useAddTransactionToBudgetMutation } from "~/domains/transactions/budget/api/budgetApi_"
import { ApiErrorResponse } from "~/domains/transactions/budget/api/types/budgetApi.type"
import { AddTransactionPayloadI, BudgetDataI, TransactionType } from "~/domains/transactions/budget/types"
import { useGetInvoicesByIdsQuery } from "~/domains/transactions/invoices-v1/api/invoiceApi"
import { selectReceivedDocumentsField, selectSentDocumentsField } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { InvoiceBackendVersion, OrganizationId, ViewTypeI } from "~/types"

const messages = defineMessages({
    nothingToUpdate: {
        id: "account.documents.bulk.nothingToUpdate",
        defaultMessage: "No invoice to update",
    },
    addToBudgetsSuccess: {
        id: "account.documents.bulk.addToBudgets.success",
        defaultMessage: "{countSelected} invoice{sSelected} added in {countBudgets} budget{sCount}",
    },
    addToBudgetsError: {
        id: "account.documents.bulk.addToBudgets.error",
        defaultMessage: "Some transactions failed",
    },
    noChanges: {
        id: "transactions.modal.addInvoicesToBudgets.noChanges",
        defaultMessage: "There is no changes. Please select at least one budget.",
    },
})

interface UseBulkAddInvoicesToBudgetsProps {
    organizationId: OrganizationId
    invoiceIds: string[]
    view: ViewTypeI
    version?: InvoiceBackendVersion
}

export const useBulkAddInvoicesToBudgets = ({
    organizationId,
    invoiceIds,
    view,
    version,
}: UseBulkAddInvoicesToBudgetsProps) => {
    const { formatMessage } = useIntl()
    const [isLoading, setIsLoading] = useState(false)

    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const receivedDocuments = useAppSelector(selectReceivedDocumentsField)
    const sentDocuments = useAppSelector(selectSentDocumentsField)
    const invoices_old = view === ViewTypeI.supplier ? sentDocuments : receivedDocuments

    const { data: invoicesByIds } = useGetInvoicesByIdsQuery(
        {
            organizationId: currentOrganizationId || "",
            ids: invoiceIds,
        },
        {
            skip: version !== InvoiceBackendVersion.V1,
        }
    )

    const [addBudgetToTransaction] = useAddTransactionToBudgetMutation()

    const bulkAddInvoicesToBudgets = useCallback(
        async (budgets: BudgetDataI[]) => {
            const invoices = version === InvoiceBackendVersion.V1 ? invoicesByIds : invoices_old

            if (!invoices?.length) {
                toast.error(formatMessage(messages.nothingToUpdate))
                return
            }
            let errors = 0
            let successes = 0

            setIsLoading(true)

            await Promise.allSettled(
                budgets.map((budget) =>
                    Promise.allSettled(
                        invoiceIds.map(async (invoiceId) => {
                            const invoice = invoices.find((invoiceToAdd) => invoiceToAdd.invoiceId === invoiceId)

                            if (!invoice) return

                            const payload = {
                                transactionRefId: invoice.invoiceId as string,
                                transactionStatus: invoice.status,
                                transactionType: TransactionType.INVOICE,
                                amount: invoice.total ?? 0,
                            }

                            const { error } = await addBudgetToTransaction({
                                organizationId,
                                budgetId: budget.id,
                                payload: payload as AddTransactionPayloadI,
                            })

                            if ((error as ApiErrorResponse)?.status === 400) {
                                errors++
                            } else {
                                successes++
                            }
                        })
                    )
                )
            )

            if (errors > 0) {
                toast.error(formatMessage(messages.addToBudgetsError))
            }

            if (successes > 0) {
                const totalSuccesses = successes / budgets.length

                toast.success(
                    formatMessage(messages.addToBudgetsSuccess, {
                        countSelected: totalSuccesses,
                        sSelected: totalSuccesses === 1 ? "" : "s",
                        countBudgets: budgets.length,
                        sCount: budgets.length === 1 ? "" : "s",
                    })
                )
            }

            setIsLoading(false)
        },
        [addBudgetToTransaction, formatMessage, invoiceIds, invoicesByIds, invoices_old, organizationId, version]
    )

    return {
        bulkAddInvoicesToBudgets,
        isLoading,
    }
}
