export enum AuthorizationName {
    READ = "read",
    VIEW = "view",
    CREATE = "create",
    UPDATE = "update",
    EDIT = "edit",
    DELETE = "delete",
    APPROVE = "approve",
    CONVERT = "convert",
    CONVERT_TO_PO_API = "convert_to_purchase_order",
    CONVERT_TO_PO = "convertToPurchaseOrder",
    OWNER = "owner",
    CHECK = "check",
    SUBMIT = "submit",
    SEND = "send",
    ASSIGN_DOCUMENT = "assign_document",
    CLOSE = "close",
    REOPEN = "reopen",
    MARK_LINE_IN_PREPARATION = "mark_line_in_preparation",
    MARK_LINE_IN_TRANSIT = "mark_line_in_transit",
    MARK_LINE_DELIVERED_OR_RECEIVED = "mark_line_delivered_or_received",
    MARK_LINE_CANCELED = "mark_line_canceled",
    PAY = "pay",
}

export const authorizationNames = [
    "read",
    "view",
    "create",
    "update",
    "edit",
    "delete",
    "approve",
    "convert",
    "convert_to_purchase_order",
    "convertToPurchaseOrder",
    "owner",
    "check",
    "submit",
    "send",
    "close",
    "reopen",
    "mark_line_in_preparation",
    "mark_line_in_transit",
    "mark_line_delivered_or_received",
    "mark_line_canceled",
    "pay",
    "assign_document",
] as const
export type AuthorizationNames = (typeof authorizationNames)[number]

export type SpiceDBPermissionsResult = {
    [key in AuthorizationName]?: boolean
}
