import {
    CountryCode,
    EstablishmentI,
    OrganizationRegistrationI,
    RegistrationNumberType,
    WhitePagesIdTypes,
} from "~/types"

const SIREN_LENGTH = 9
export const extractSirenFromSiret = (siret: string): string => {
    return siret.slice(0, SIREN_LENGTH)
}

const SIRET_LENGTH = 14
export const isIdentifierFrenchSiret = (identifier: string, countryCode: CountryCode): boolean => {
    return countryCode === CountryCode.FR && identifier.length === SIRET_LENGTH
}
export const isRegistrationFrenchSiret = (registration: OrganizationRegistrationI): boolean => {
    if (!registration.preferredRegistrationNumber) {
        return false
    }

    const isRegistrationTypeUnknown = registration.preferredRegistrationNumber.registrationType === "UNKNOWN"
    if (isRegistrationTypeUnknown) {
        return isIdentifierFrenchSiret(
            registration.preferredRegistrationNumber.registrationNumber,
            registration.countryCode
        )
    }
    return (
        (registration.preferredRegistrationNumber.registrationType === RegistrationNumberType.FRENCH_SIRET ||
            registration.preferredRegistrationNumber.registrationType === WhitePagesIdTypes.FRENCH_SIRET) &&
        isIdentifierFrenchSiret(registration.preferredRegistrationNumber.registrationNumber, registration.countryCode)
    )
}

export const getHeadOfficeEstablishment = (establishments: EstablishmentI[]): EstablishmentI | undefined => {
    return establishments.find((establishment) => establishment.isHeadOffice)
}
