import { Box, Stack, Typography } from "@mui/material"
import { useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Button, Card, Loader, SafeFormattedMessage, TooltipConditional } from "~/components"
import { permissionMessages } from "~/domains/identity/roles-permissions/utils/permissions"
import { useHasPaymentPermissions } from "~/domains/payment/hooks"
import { useGetPaymentMethodDetailsQuery } from "~/domains/payment/payment-method-details/api/paymentMethodDetailsApi"
import { PaymentMethodDetailsSelector } from "~/domains/payment/payment-method-details/components"
import { PaymentMethodDetailsCreateOrEditModal } from "~/domains/payment/payment-method-details/components/"
import { PaymentMethodDetails, PaymentMethodDetailsFormType } from "~/domains/payment/payment-method-details/types"
import { PaymentMethodType } from "~/domains/payment/payment-methods/types"

const messages = defineMessages({
    payer: {
        id: "payment.payerPaymentMethodDetails.payer",
        defaultMessage: "Payer",
    },
    paymentMethod: {
        id: "payment.payerPaymentMethodDetails.paymentMethod",
        defaultMessage: "Payment method",
    },
    addPaymentMethod: {
        id: "payment.payerPaymentMethodDetails.addPaymentMethod",
        defaultMessage: "+ Add a payment method",
    },
})

interface PayerPaymentMethodDetailsProps {
    payerId: string
    loading?: boolean
    label?: string
    formType?: PaymentMethodDetailsFormType
    selectedPaymentMethodDetailsId: string | null
    onPaymentMethodChanged: (id: string, paymentMethodId?: string | null) => void
}

export const PayerPaymentMethodDetails = ({
    payerId,
    label,
    selectedPaymentMethodDetailsId,
    onPaymentMethodChanged,
    formType = PaymentMethodDetailsFormType.MODAL,
    loading = false,
}: PayerPaymentMethodDetailsProps) => {
    const { formatMessage } = useIntl()
    const [open, setOpen] = useState(false)
    const { data, isLoading, isFetching } = useGetPaymentMethodDetailsQuery({ owner: payerId })
    const items = data?.items ?? []

    const { permissions } = useHasPaymentPermissions({ authorizations: ["create", "update"] })
    const hasCreatePermission = Boolean(permissions?.create?.hasPermission)
    const hasUpdatePermission = Boolean(permissions?.update?.hasPermission)

    const handleOpen = () => {
        if (!hasCreatePermission) return
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handlePaymentMethdDetailsChanged = (id: string) => {
        if (!hasUpdatePermission) return
        const item = items.find((paymentMethodDetail) => paymentMethodDetail.id === id)
        onPaymentMethodChanged(id, item?.payment_method_id)
    }

    const handlePaymentMethodDetailsCreated = ({ id, payment_method_id }: PaymentMethodDetails) => {
        if (onPaymentMethodChanged) {
            onPaymentMethodChanged(id, payment_method_id)
        }
    }

    if (isLoading || isFetching) {
        return <Loader small />
    }

    return (
        <Card>
            <Stack spacing={2}>
                <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h4">{label ?? formatMessage(messages.payer)}</Typography>
                    <TooltipConditional
                        title={<SafeFormattedMessage {...permissionMessages.errorNoAccessAdministrator} />}
                        condition={!hasCreatePermission}
                        placement="top"
                        arrow
                    >
                        <Box component="span">
                            <Button
                                size="x-small"
                                type="title"
                                disabled={!hasCreatePermission || loading || open}
                                onClick={handleOpen}
                            >
                                {formatMessage(messages.addPaymentMethod)}
                            </Button>
                        </Box>
                    </TooltipConditional>
                </Stack>

                {!open && items.length > 0 && (
                    <TooltipConditional
                        title={<SafeFormattedMessage {...permissionMessages.errorNoAccessAdministrator} />}
                        condition={!hasUpdatePermission}
                        placement="top"
                        arrow
                    >
                        <Box component="span">
                            <PaymentMethodDetailsSelector
                                label={formatMessage(messages.paymentMethod)}
                                value={selectedPaymentMethodDetailsId}
                                items={items}
                                loading={loading}
                                disabled={!hasUpdatePermission}
                                onItemSelect={handlePaymentMethdDetailsChanged}
                            />
                        </Box>
                    </TooltipConditional>
                )}
                {open && (
                    <PaymentMethodDetailsCreateOrEditModal
                        activeTab={PaymentMethodType.BANK_TRANSFER}
                        open
                        formType={formType}
                        documentOwnerOrganizationId={payerId}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        onPaymentMethodDetailsCreated={handlePaymentMethodDetailsCreated}
                    />
                )}
            </Stack>
        </Card>
    )
}
