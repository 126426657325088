import { useEffect, useRef, useState } from "react"

import { isResultSuccess } from "~/types/Result"

import { useSocketIOContext } from "../components/SocketIOContext"
import { parseSubcriptionEvent } from "../subscriptionsParser"
import { SubscriptionEventI, SubscriptionType } from "../subscriptionsTypes"

export const useSubscribeToUpdate = (
    objectType: SubscriptionType,
    objectId: string | undefined,
    callback: (event: SubscriptionEventI) => void
) => {
    const { isConnected, ioSocket } = useSocketIOContext()
    const didUnmount = useRef(false)
    const [subscribeToInvoiceAknowledge, setSubscribeToInvoiceAknowledge] = useState(false)

    const callbackRef = useRef(callback)

    useEffect(() => {
        callbackRef.current = callback
    }, [callback])

    useEffect(() => {
        return () => {
            didUnmount.current = true
        }
    }, [didUnmount])

    useEffect(() => {
        setSubscribeToInvoiceAknowledge(false)
        if (objectId && isConnected) {
            ioSocket.on(objectId, (data) => {
                const event = parseSubcriptionEvent(data)
                if (isResultSuccess(event)) {
                    callbackRef.current(event.result)
                }
            })
            ioSocket.emitWithAck(`subscribe/${objectType}`, objectId).then(() => {
                setSubscribeToInvoiceAknowledge(true)
            })
            return () => {
                ioSocket.emit(`unsubscribe/${objectType}`, objectId)
            }
        }
    }, [objectType, objectId, isConnected])

    return { isConnected, subscribeToInvoiceAknowledge }
}
