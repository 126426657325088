import { Stack } from "@mui/material"
import { FC } from "react"

import { ActionsMenu } from "~/components"
import { ShareObjectPermissionsButton } from "~/domains/identity/roles-permissions/components/ShareObjectPermissionsModal/ShareObjectPermissionsButton"
import { SpiceDBObjectType } from "~/domains/identity/roles-permissions/types/SpiceDB"
import { PurchaseViewType } from "~/domains/transactions/_shared/types/Purchases"
import {
    CancelEditPR,
    ConvertPRtoPO,
    DeletePR,
    EditPR,
    SavePR,
    SubmitPR,
} from "~/domains/transactions/purchase-requests/components/Actions"
import { ApproveAllLines } from "~/domains/transactions/purchase-requests/components/Actions/ApproveAllLines"
import {
    PurchaseRequestDetails,
    PurchaseRequestStatus,
} from "~/domains/transactions/purchase-requests/types/PurchaseRequests"

interface PropsPRActions {
    PR: PurchaseRequestDetails
    mode: PurchaseViewType
}

export const ActionsHeader: FC<PropsPRActions> = ({ PR, mode }) => {
    const { id, status, organizationId, permissions, purchaseOrder } = PR

    const isConvertedToPO = Boolean(PR && purchaseOrder)
    const canEdit = !isConvertedToPO && permissions.edit
    const canSubmit = status === PurchaseRequestStatus.DRAFT && permissions.submit
    const canConvertToPO = !isConvertedToPO && permissions.convertToPurchaseOrder

    const actions = [
        ...(canEdit ? [{ render: <EditPR id={id} hasPermission={true} display="menu-item" /> }] : []),
        {
            render: (
                <ShareObjectPermissionsButton
                    objectId={id}
                    objectType={SpiceDBObjectType.PURCHASE_REQUEST}
                    organizationId={organizationId}
                    display="menu-item"
                />
            ),
        },
        ...(permissions.delete
            ? [
                  {
                      render: (
                          <DeletePR id={id} organizationId={organizationId} hasPermission={true} display="menu-item" />
                      ),
                  },
              ]
            : []),
    ]

    return (
        <Stack direction="row" gap={1}>
            {mode === PurchaseViewType.VIEW ? (
                <>
                    <ActionsMenu isActionIcon={false} actions={actions} />

                    {canSubmit && <SubmitPR organizationId={organizationId} id={id} hasPermission={true} />}

                    <ApproveAllLines purchaseRequest={PR} />

                    {canConvertToPO && <ConvertPRtoPO PR={PR} />}
                </>
            ) : (
                <>
                    <CancelEditPR id={PR.id} display="button" />
                    <SavePR id={id} organizationId={organizationId} />
                </>
            )}
        </Stack>
    )
}
