import { Autocomplete, AutocompleteChangeReason, Divider, Stack, TextField, Typography } from "@mui/material"
import { ChangeEvent, FC, useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"

import { Button } from "~/components"
import { useOrganizationTagGroups } from "~/domains/analytics/tags/hooks"
import { TagI } from "~/domains/analytics/tags/types"
import { ENTITY_TRIGGER_PROPERTY_MAP } from "~/domains/orchestration/flows/constants"
import { useEditor } from "~/domains/orchestration/flows/context/editorContext"
import { useOrganizationId } from "~/domains/orchestration/flows/hooks"
import { messages } from "~/domains/orchestration/flows/locale"
import { Condition, isEntityTriggerNode, isEventTriggerNode } from "~/domains/orchestration/flows/types"
import { getTriggerSlug } from "~/domains/orchestration/flows/utils/editor"

import "./ConditionBuilder.scss"

interface Props {
    condition: Condition | null
    handleChange?: (condition: Condition, tag?: TagI) => void
    handleSave?: (c: Condition) => void
    handleCancel?: () => void
}

export const ConditionBuilder: FC<Props> = ({ handleSave, handleChange, condition, handleCancel }) => {
    const { formatMessage } = useIntl()
    const [conditionValue, setConditionValue] = useState(condition?.condition || "")
    const state = useEditor()
    const { flow } = state

    const organizationId = useOrganizationId()

    const { tagGroups } = useOrganizationTagGroups(organizationId)
    const tags = useMemo(() => tagGroups?.flatMap((tagGroup) => tagGroup.tags) || [], [tagGroups])

    // Regex to find tag id in the condition : {{ trigger_event.id }} taggedWith 'baf18856-b8d9-4641-9990-5fa5bb0b97a7'
    // it gets the tag id between the quotes
    const tagIdRegex = /taggedWith '(.*)'/
    const tagId = tagIdRegex.exec(conditionValue)?.[1]

    const [tag, setTag] = useState<TagI | null>(null)

    const handleChangeTag = (_, value: TagI | null, reason: AutocompleteChangeReason) => {
        if (reason === "clear") {
            setConditionValue(conditionValue.replace(tagIdRegex, ""))
            setTag(null)
            return
        }

        if (!value) return
        const eventTriggerEventType = flow?.nodes.find(isEventTriggerNode)?.event || null
        const entityTriggerEntityType = flow?.nodes.find(isEntityTriggerNode)?.entity?.type || null
        const { nodes = [] } = flow || {}
        const slug = getTriggerSlug(eventTriggerEventType || entityTriggerEntityType, nodes) || ""

        let id = `$${slug}.id`

        if (entityTriggerEntityType) {
            id = `$${slug}.${ENTITY_TRIGGER_PROPERTY_MAP[entityTriggerEntityType]}.id`
        }

        const conditionValueWithTag = `${id} taggedWith '${value.tagId}'`

        setConditionValue(conditionValueWithTag)
        setTag(value)
        handleChange?.(
            {
                id: condition?.id || "",
                condition: conditionValueWithTag,
            },
            value
        )
    }

    const validateCondition = (value: string): boolean => {
        const trimmedValue = value.trim()
        return trimmedValue.length > 0
    }

    const handleSaveNewCondition = () => {
        if (!condition) return
        const trimmedValue = conditionValue.trim()
        if (!validateCondition(trimmedValue)) return

        const newCondition: Condition = {
            id: condition.id,
            condition: trimmedValue,
        }

        handleSave?.(newCondition)
    }

    const handleChangeCondition = (e: ChangeEvent<HTMLInputElement>) => {
        setConditionValue(e.target.value)
        setTag(null)
        handleChange?.({
            id: condition?.id || "",
            condition: e.target.value,
        })
    }

    const handleKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault()
            if (validateCondition(conditionValue)) {
                handleSaveNewCondition()
            }
        }
    }

    const tagsLoading = tagGroups === null

    useEffect(() => {
        if (tags.length > 0) {
            const initialTag = tags.find((t) => t.tagId === tagId) || null
            setTag(initialTag)
        }
    }, [tagId, tags])

    return (
        <Stack gap={2} className="flows-conditionBuilder">
            <TextField
                label={formatMessage(messages.conditionBuilder.label)}
                value={conditionValue}
                onChange={handleChangeCondition}
                onKeyDown={handleKeyPress}
                fullWidth
                multiline
            />

            <Divider />

            <Stack gap={2}>
                <Typography variant="caption">{formatMessage(messages.conditionBuilder.isTaggedWithLabel)}</Typography>
                <Autocomplete
                    loading={tagsLoading}
                    options={tags}
                    value={tag}
                    onChange={handleChangeTag}
                    getOptionLabel={(t: TagI) => t.name}
                    isOptionEqualToValue={(option: TagI, value: TagI) => option.tagId === value.tagId}
                    getOptionKey={(option: TagI) => option.tagId}
                    renderInput={(params) => (
                        <TextField {...params} label={formatMessage(messages.assignTagConfiguration.tagLabel)} />
                    )}
                />
            </Stack>
            <Stack direction="row" gap={1} justifyContent="flex-end" className="flows-conditionBuilder-actions">
                {handleCancel && (
                    <Button onClick={handleCancel} type="neutral">
                        {formatMessage(messages.conditionBuilder.cancelLabel)}
                    </Button>
                )}

                {handleSave && (
                    <Button onClick={handleSaveNewCondition} type="primary-light">
                        {formatMessage(messages.conditionBuilder.saveLabel)}
                    </Button>
                )}
            </Stack>
        </Stack>
    )
}
