import { MessageDescriptor, defineMessages } from "react-intl"

import { TaskPriority } from "~/domains/communication/tasks/types"

export const taskPriorityLabel: Record<TaskPriority, MessageDescriptor> = defineMessages({
    [TaskPriority.LOW]: { id: "communication.tasks.priority.low", defaultMessage: "Low" },
    [TaskPriority.MEDIUM]: { id: "communication.tasks.priority.medium", defaultMessage: "Medium" },
    [TaskPriority.HIGH]: { id: "communication.tasks.priority.high", defaultMessage: "High" },
    [TaskPriority.URGENT]: { id: "communication.tasks.priority.urgent", defaultMessage: "Urgent" },
})
