import { Stack } from "@mui/material"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { ChevronDown } from "react-feather"

import { Loader, OrganizationLogo } from "~/components"
import { selectUser } from "~/store/account/accountSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { useCurrentOrganization, useFetchOrganization } from "~/store/organization/hooks"
import { organizationActions } from "~/store/organization/organizationSlice"
import { OrganizationI, OrganizationId, OrganizationItemI } from "~/types"

import "./SelectOrganization.scss"

interface SideOrganizationProps {
    organization: OrganizationI
    hideOrganizationName?: boolean
    showChevron?: boolean
}

const SideOrganization: React.FC<SideOrganizationProps> = ({
    organization,
    hideOrganizationName = false,
    showChevron = false,
}) => {
    return (
        <Stack direction="row" alignItems="center" gap={1}>
            <OrganizationLogo organisationId={organization.id} iconOnly />

            {!hideOrganizationName && <span>{organization.name}</span>}
            {showChevron && <ChevronDown size={16} color="var(--color-grey-light)" className="chevron-down" />}
        </Stack>
    )
}

interface SideOrganizationItemProps {
    organizationId: OrganizationId
    close: () => void
    onChange?: (organizationId: OrganizationId) => void
}

const SideOrganizationItem: React.FC<SideOrganizationItemProps> = ({ organizationId, close, onChange }) => {
    const { organization, loading } = useFetchOrganization(organizationId)
    const dispatch = useAppDispatch()

    const selectOrganization = () => {
        dispatch(organizationActions.setCurrentOrganization(organizationId))
        onChange && onChange(organizationId)
        close()
    }

    return (
        <li className="organization-item" onClick={selectOrganization}>
            {loading ? <Loader small /> : <SideOrganization organization={organization} />}
        </li>
    )
}

interface SelectOrganizationProps {
    hideOrganizationName?: boolean
}

export const SelectOrganization: React.FC<SelectOrganizationProps> = ({ hideOrganizationName = false }) => {
    const [open, setOpen] = useState(false)
    const ignoreNextClick = useRef(false)

    const user = useAppSelector(selectUser)
    const currentOrganization = useCurrentOrganization(user.organizations)
    const toggle = useCallback(() => setOpen((o) => !o), [setOpen])
    const close = useCallback(() => setOpen(false), [setOpen])

    useEffect(() => {
        if (open) {
            const onClick = () => {
                if (ignoreNextClick.current) {
                    ignoreNextClick.current = false
                } else {
                    setOpen(false)
                }
            }
            window.addEventListener("click", onClick)
            return () => {
                window.removeEventListener("click", onClick)
            }
        }
    }, [open])

    const onRootClick = useCallback(() => {
        ignoreNextClick.current = true
    }, [ignoreNextClick])

    const isNotCurrentOrganization = useCallback(
        (organizationItem: OrganizationItemI) => organizationItem.id !== currentOrganization?.id,
        [currentOrganization]
    )

    const isOpen = open

    if (user.organizations.length === 0) return null

    return (
        <div className="organization-selector" onClick={onRootClick}>
            {currentOrganization && (
                <div className="organization-item" onClick={toggle}>
                    <SideOrganization
                        organization={currentOrganization}
                        showChevron
                        hideOrganizationName={hideOrganizationName}
                    />
                </div>
            )}
            {isOpen && (
                <ul className="organization-selector-list">
                    {user.organizations.filter(isNotCurrentOrganization).map((organization) => (
                        <SideOrganizationItem key={organization.id} organizationId={organization.id} close={close} />
                    ))}
                </ul>
            )}
        </div>
    )
}
