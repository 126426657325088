import React, { useState } from "react"
import { X } from "react-feather"
import { defineMessages } from "react-intl"

import { Button, SafeFormattedMessage, TooltipConditional } from "~/components"
import { permissionMessages } from "~/domains/identity/roles-permissions/utils/permissions"
import { ApprovalOverrideModal } from "~/domains/orchestration/flows-v0/components/ApprovalBlock/ApprovalOverrideModal"

const messages = defineMessages({
    refuse: {
        id: "purchase.requests.request.reject",
        defaultMessage: "Refuse",
    },
})

interface RejectPRProps {
    canApprove: boolean
    currentUserApproval?: boolean
    isApprovalDone: boolean
    onRefuse: () => void
}

export const RejectPR: React.FC<RejectPRProps> = ({ canApprove, currentUserApproval, isApprovalDone, onRefuse }) => {
    const [showModal, setShowModal] = useState(false)
    const permissionLabel = <SafeFormattedMessage {...permissionMessages.errorNoAccessAdministrator} />

    const handleClose = () => {
        setShowModal(false)
    }

    const handleConfirm = () => {
        onRefuse()
        handleClose()
    }

    const handleRefuse = () => {
        if (isApprovalDone) {
            onRefuse()
        } else {
            setShowModal(true)
        }
    }

    return (
        <>
            <TooltipConditional arrow title={permissionLabel} condition={!canApprove}>
                <div>
                    <Button
                        type="error-light"
                        onClick={handleRefuse}
                        disabled={!canApprove || currentUserApproval === false}
                        disableDelay={0}
                    >
                        <SafeFormattedMessage {...messages.refuse} />
                        <X size={16} />
                    </Button>
                </div>
            </TooltipConditional>
            <ApprovalOverrideModal open={showModal} onClose={handleClose} onConfirm={handleConfirm} />
        </>
    )
}
