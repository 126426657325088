import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorizationAndOrganizationId as prepareHeaders } from "~/api/prepareHeaders"
import { searchProductFromApiAdapters } from "~/domains/transactions/catalog-v1/api/adapters/catalogFromApiAdapters"
import {
    CatalogSearch,
    CatalogSearchAPIResponse,
    GetCatalogSearchQuery,
} from "~/domains/transactions/catalog-v1/types/CatalogProductSearch.type"
import { PaginatedOffsetLimitResponse } from "~/types/Pagination"

const BASE_URL = import.meta.env.VITE_API_CATALOG_V1_URL as string

export const catalogProductSearchApi = createApi({
    reducerPath: "catalogProductSearchApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, prepareHeaders }),
    tagTypes: ["Catalogs", "Catalog", "Search"],
    endpoints: (builder) => ({
        searchProducts: builder.query<PaginatedOffsetLimitResponse<CatalogSearch>, GetCatalogSearchQuery>({
            query: ({
                organizationId,
                query: { q, minPrice, maxPrice, catalogId, catalogStatus, orderDate, currency, orderQuantity },
                limit,
                offset,
            }) => {
                const params = new URLSearchParams({
                    ...(q && { q }),
                    ...(minPrice !== undefined && { minPrice: minPrice.toString() }),
                    ...(maxPrice !== undefined && { maxPrice: maxPrice.toString() }),
                    ...(orderDate && { orderDate }),
                    ...(currency && { currency }),
                    ...(orderQuantity && { orderQuantity: orderQuantity.toString() }),
                    ...(limit && { limit: limit.toString() }),
                    ...(offset && { offset: offset.toString() }),
                })
                catalogId?.forEach((id) => params.append("catalogId", id))
                catalogStatus?.forEach((status) => params.append("catalogStatus", status))

                return {
                    url: `organizations/${organizationId}/search/products`,
                    params,
                }
            },
            providesTags: ["Search"],
            transformResponse: (response: PaginatedOffsetLimitResponse<CatalogSearchAPIResponse>) => ({
                items: response.items.map(searchProductFromApiAdapters),
                offset: response.offset,
                limit: response.limit,
                totalResults: response.totalResults,
            }),
            transformErrorResponse: (response) => ({
                meta: {
                    tags: {
                        domain: "transaction",
                        subdomain: "catalog",
                        feature: "search-products",
                    },
                },
                ...response,
            }),
        }),
    }),
})

export const { useSearchProductsQuery } = catalogProductSearchApi
