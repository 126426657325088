import { Grid } from "@mui/material"
import * as Sentry from "@sentry/browser"
import React, { useEffect } from "react"
import { useIntl } from "react-intl"
import { toast } from "react-toastify"

import { Loader } from "~/components"
import { useGetFlowsQuery } from "~/domains/orchestration/flows/api/flowsApi"
import { useOrganizationId } from "~/domains/orchestration/flows/hooks"
import { messages } from "~/domains/orchestration/flows/locale"
import { SortBy } from "~/domains/orchestration/flows/types"
import { filterFlows, sortByDate, sortByName } from "~/domains/orchestration/flows/utils"

import { FlowCard } from "./FlowCard"

interface ListProps {
    filter: string
    showEnabledOnly: boolean
    sortBy: SortBy
}

export const List: React.FC<ListProps> = ({ filter, showEnabledOnly, sortBy }) => {
    // TODO: query params should be dynamic
    const organizationId = useOrganizationId()
    const { data, error, isLoading, refetch } = useGetFlowsQuery(false)
    const { formatMessage } = useIntl()

    const filterFn = filterFlows(filter, showEnabledOnly)
    const sortFn = sortBy === SortBy.CREATION_DATE ? sortByDate : sortByName

    const filteredAndSortedFlows = data?.flows.filter(filterFn).sort(sortFn)

    useEffect(() => {
        refetch()
    }, [organizationId, refetch])

    if (isLoading) {
        return <Loader fullscreen />
    }

    if (error) {
        Sentry.captureException(error)
        toast.error(formatMessage(messages.error.fetchingFlows))
        return null
    }

    if (!data) {
        return null
    }

    return (
        <Grid container spacing={2}>
            {filteredAndSortedFlows?.map((flow) => (
                <Grid item xs={12} sm={6} md={4} key={flow.id}>
                    <FlowCard flow={flow} />
                </Grid>
            ))}
        </Grid>
    )
}
