import { lazy } from "react"
import { Route } from "react-router-dom"

import { ProtectedRoutes } from "~/auth/ProtectedRoute"

import {
    PARTNERS_ROUTE,
    PARTNER_ADDRESSES_ROUTE,
    PARTNER_COMPANY_OFFICES_ROUTE,
    PARTNER_CONTACTS_ROUTE,
    PARTNER_DETAILS_ROUTE,
    PARTNER_INVOICES_ROUTE,
    PARTNER_INVOICES_ROUTE_V1,
    PARTNER_KYB_ROUTE,
    PARTNER_METRICS_ROUTE,
    PARTNER_OPERATIONAL_PERFORMANCE_ROUTE,
    PARTNER_PAYMENT_DETAILS_ROUTE,
    PARTNER_PURCHASE_ORDERS_ROUTE,
    PARTNER_SENT_FORMS_ROUTE,
    PARTNER_SHARE_CATALOGS_ROUTE,
} from "./routes"

const BookOfRelationsLazy = lazy(() =>
    import("./pages/BookOfRelations").then(({ BookOfRelations }) => ({ default: BookOfRelations }))
)
const PartnerOrganizationDetailsLazy = lazy(() =>
    import("./pages/PartnerOrganizationDetails").then(({ PartnerOrganizationDetails }) => ({
        default: PartnerOrganizationDetails,
    }))
)
const PartnerInvoicesLazy = lazy(() =>
    import("./pages/PartnerInvoicesPage").then(({ PartnerInvoices }) => ({ default: PartnerInvoices }))
)

const PartnerInvoicesV1Lazy = lazy(() =>
    import("./pages/PartnerInvoicesPageV1").then(({ PartnerInvoices }) => ({ default: PartnerInvoices }))
)

const PartnerPurchaseOrdersLazy = lazy(() =>
    import("./pages/PartnerPurchaseOrdersPage").then(({ PartnerPurchaseOrders }) => ({
        default: PartnerPurchaseOrders,
    }))
)

const PartnerAddressesPageLazy = lazy(() =>
    import("./pages/PartnerAddressesPage").then(({ PartnerAddressesPage }) => ({
        default: PartnerAddressesPage,
    }))
)

const PartnerContactsPageLazy = lazy(() =>
    import("./pages/PartnerContactsPage").then(({ PartnerContactsPage }) => ({
        default: PartnerContactsPage,
    }))
)

const PartnerPaymentDetailsPageLazy = lazy(() =>
    import("./pages/PartnerPaymentDetailsPage").then(({ PartnerPaymentDetailsPage }) => ({
        default: PartnerPaymentDetailsPage,
    }))
)

const PartnerKYBPageLazy = lazy(() =>
    import("./pages/PartnerKYBPage").then(({ PartnerKYBPage }) => ({
        default: PartnerKYBPage,
    }))
)

const PartnerSentFormsPageLazy = lazy(() =>
    import("./pages/PartnerSentFormsPage").then(({ PartnerSentFormsPage }) => ({
        default: PartnerSentFormsPage,
    }))
)

const MetricsLazy = lazy(() =>
    import("./pages/PartnerMetricsPage").then(({ PartnerMetricsPage }) => ({ default: PartnerMetricsPage }))
)

const PartnerOperationalPerformanceLazy = lazy(() =>
    import("./pages/PartnerOperationalPerformancePage").then(({ PartnerOperationalPerformancePage }) => ({
        default: PartnerOperationalPerformancePage,
    }))
)

const PartnerCompanyOfficesLazy = lazy(() =>
    import("./pages/PartnerCompanyOffices").then(({ PartnerCompanyOffices }) => ({
        default: PartnerCompanyOffices,
    }))
)

const PartnerShareCatalogsLazy = lazy(() =>
    import("~/domains/identity/partners/pages/PartnerShareCatalogsPage").then(({ PartnerShareCatalogsPage }) => ({
        default: PartnerShareCatalogsPage,
    }))
)

export default {
    routes: [
        <Route element={<ProtectedRoutes />} key="partners_route">
            <Route path={PARTNERS_ROUTE} element={<BookOfRelationsLazy />} />
            <Route path={PARTNER_DETAILS_ROUTE} element={<PartnerOrganizationDetailsLazy />} />
            <Route path={PARTNER_INVOICES_ROUTE} element={<PartnerInvoicesLazy />} />
            <Route path={PARTNER_INVOICES_ROUTE_V1} element={<PartnerInvoicesV1Lazy />} />
            <Route path={PARTNER_PURCHASE_ORDERS_ROUTE} element={<PartnerPurchaseOrdersLazy />} />
            <Route path={PARTNER_ADDRESSES_ROUTE} element={<PartnerAddressesPageLazy />} />
            <Route path={PARTNER_CONTACTS_ROUTE} element={<PartnerContactsPageLazy />} />
            <Route path={PARTNER_PAYMENT_DETAILS_ROUTE} element={<PartnerPaymentDetailsPageLazy />} />
            <Route path={PARTNER_SENT_FORMS_ROUTE} element={<PartnerSentFormsPageLazy />} />
            <Route path={PARTNER_KYB_ROUTE} element={<PartnerKYBPageLazy />} />
            <Route path={PARTNER_METRICS_ROUTE} element={<MetricsLazy />} />
            <Route path={PARTNER_OPERATIONAL_PERFORMANCE_ROUTE} element={<PartnerOperationalPerformanceLazy />} />
            <Route path={PARTNER_COMPANY_OFFICES_ROUTE} element={<PartnerCompanyOfficesLazy />} />
            <Route path={PARTNER_SHARE_CATALOGS_ROUTE} element={<PartnerShareCatalogsLazy />} />
        </Route>,
    ],
}
