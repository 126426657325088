import { Typography } from "@mui/material"
import { FC } from "react"
import { defineMessages, useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { Button, Modal, Size } from "~/components"

const messages = defineMessages({
    title: {
        id: "identity.account.acknowledgePurchasePolicyWall.stepOneTitle",
        defaultMessage: "Acknowledgement of the purchase policy",
    },
    disclaimer: {
        id: "identity.account.acknowledgePurchasePolicyWall.disclaimer",
        defaultMessage:
            "Hello, welcome to Crédipar's Flowie platform. In the next steps, you will read the purchasing policy, receive a summary, and then confirm your commitment.",
    },
})

interface Props {
    onConfirm: (confirmDate: Date) => void
}

export const AknowledgePurchasePolicyInitialConfirmationModal: FC<Props> = ({ onConfirm }) => {
    const { formatMessage } = useIntl()
    return (
        <Modal open size={Size.FULLSCREEN}>
            <Modal.Header>
                <Typography variant="h4" gutterBottom>
                    {formatMessage(messages.title)}
                </Typography>
            </Modal.Header>
            <Modal.Content>
                <Typography>{formatMessage(messages.disclaimer)}</Typography>
            </Modal.Content>
            <Modal.Footer>
                <Button onClick={() => onConfirm(new Date())}>{formatMessage(commonMessages.next)}</Button>
            </Modal.Footer>
        </Modal>
    )
}
