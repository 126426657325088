import * as Sentry from "@sentry/react"
import React, { useEffect } from "react"
import { IntlProvider, MessageFormatElement } from "react-intl"

import { SUPPORTED_LANGUAGES, SupportedLanguages } from "~/constants/lang"
import { useGetUserByIdQuery } from "~/domains/identity/users-v2/api/userV2Api"
import { selectUser } from "~/store/account/accountSlice"
import { StoredGlobalState, globalActions, selectLang } from "~/store/global/globalSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"

const getStoredLanguage = (): string | null => {
    try {
        const storedData = localStorage?.getItem("persist:global")
        if (!storedData) return null

        const parsedData = JSON.parse(storedData) as StoredGlobalState
        if (!parsedData?.lang) return null

        const storedLang = parsedData.lang.replace(/"/g, "") as string
        return getCorrectLanguage(storedLang)
    } catch (error) {
        Sentry.captureException("LocalesProvider: Error reading localStorage", { extra: { error } })
        return null
    }
}

const getCorrectLanguage = (lang: string | null | undefined): SupportedLanguages | null => {
    if (lang && SUPPORTED_LANGUAGES.includes(lang as SupportedLanguages)) return lang as SupportedLanguages
    return null
}

interface LocalesProviderProps extends React.PropsWithChildren {
    messages: Record<string, Record<string, string> | Record<string, MessageFormatElement[]> | undefined>
    locale: string
}

export const LocalesProvider = ({ messages, locale, children }: LocalesProviderProps) => {
    const dispatch = useAppDispatch()
    const lang = useAppSelector(selectLang)
    const currentUser = useAppSelector(selectUser)

    const dispatchUserLang = (newLang: SupportedLanguages) => dispatch(globalActions.setLang(newLang))

    // FIXME: We need this call to get the user language from Org v2
    // To remove when we switch to Org v2
    const { data: userData } = useGetUserByIdQuery({ userId: currentUser.id }, { skip: !currentUser.id })
    useEffect(() => {
        if (userData?.language) {
            const correctLang = getCorrectLanguage(userData.language)
            if (correctLang) {
                dispatchUserLang(correctLang)
            }
        }
    }, [userData])

    useEffect(() => {
        const storedLang = getStoredLanguage()
        const browserLang = getCorrectLanguage(navigator.language?.split("-")[0])
        const resolvedLang = storedLang || browserLang || lang
        dispatchUserLang(resolvedLang as SupportedLanguages)
    }, [])

    return (
        <IntlProvider messages={messages[lang]} locale={lang} defaultLocale={locale}>
            {children}
        </IntlProvider>
    )
}
