import { Tooltip } from "@mui/material"
import { useState } from "react"
import { Send } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { Button, SafeFormattedMessage, ShareObjectModal } from "~/components"
import { useGetPartnershipQuery } from "~/domains/identity/partners/api/partnerApiV1"
import usePartnerBrandname from "~/domains/identity/partners/store/hooks/usePartnerBrandname"
import useSupplierAllowed from "~/domains/identity/partners/store/hooks/useSupplierAllowed"
import { useFetchSharedObjects } from "~/domains/identity/roles-permissions/store/hooks/useFetchSharedObjects"
import { OrganizationSummary } from "~/domains/transactions/_shared/components/Organizations/Organizations"
import { useFetchPurchaseOrder } from "~/domains/transactions/purchase-orders/store/hooks/useFetchPurchaseOrder"
import { useUpdatePOStatus } from "~/domains/transactions/purchase-orders/store/hooks/useUpdatePOStatus"
import { purchaseOrdersActions } from "~/domains/transactions/purchase-orders/store/purchaseOrdersSlice"
import { PurchaseOrderStatus, PurchaseOrders } from "~/domains/transactions/purchase-orders/types"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { SharedObjectType } from "~/types/SharedObjects"
import { ViewTypeI } from "~/types/ViewType"

const messages = defineMessages({
    send: {
        id: "purchase.orders.order.action.send",
        defaultMessage: "Send",
    },
    supplierNotAllowed: {
        id: "purchase.requests.request.supplierNotAllowed",
        defaultMessage: "This supplier is not allowed to receive POs",
    },
})

interface SendPOProps {
    PO: PurchaseOrders
    viewType: ViewTypeI
}

export const SendPO = ({ PO, viewType }: SendPOProps) => {
    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()
    const [shareObjectModalOpen, setShareObjectModalOpen] = useState(false)

    const organisation = useAppSelector(selectCurrentOrganization)
    const { data: partnershipData, isLoading: partnershipDataLoading } = useGetPartnershipQuery(
        {
            organizationId: organisation?.id ?? "",
            partnerId: PO.supplierId ?? "",
        },
        {
            skip: !PO.supplierId || !organisation?.id,
        }
    )
    const canSend = useSupplierAllowed(partnershipData ?? [], partnershipDataLoading)
    const organizationSharings = useFetchSharedObjects(organisation?.id)
    const { updateStatus } = useUpdatePOStatus(PO.id)
    const { fetchPurchaseOrder } = useFetchPurchaseOrder(organisation?.id)
    const brandName = usePartnerBrandname(PO.supplierId, PO?.supplierName)

    const buyerOrganization: OrganizationSummary = {
        id: organisation?.id ?? "",
        name: organisation?.name ?? "",
        type: ViewTypeI.buyer,
    }

    const supplierOrganization: OrganizationSummary = {
        id: PO.supplierId ?? null,
        name: brandName,
        type: ViewTypeI.supplier,
    }

    const tooltipTitle = !canSend ? formatMessage(messages.supplierNotAllowed) : formatMessage(messages.send)

    const objectSharings = organizationSharings.filter(
        (share) => share.objectId === PO.id && share.objectType === SharedObjectType.PurchaseOrder
    )

    const updatePurchaseOrderStatus = async (status: PurchaseOrderStatus) => {
        if (!organisation?.id) return

        dispatch(purchaseOrdersActions.updatePO())
        await updateStatus(organisation?.id, status)
        await fetchPurchaseOrder(PO.id)
        dispatch(purchaseOrdersActions.updatePOSuccess())
    }

    const showShareObjectModal = () => setShareObjectModalOpen(true)
    const hideShareObjectModal = () => setShareObjectModalOpen(false)

    const handleSend = () => {
        if (viewType === ViewTypeI.buyer && objectSharings.length === 0) {
            showShareObjectModal()
        } else if (organisation?.id) {
            updatePurchaseOrderStatus("OPEN")
        }
    }

    const handleSharedObject = async () => {
        setTimeout(async () => {
            await updatePurchaseOrderStatus("OPEN")
            setShareObjectModalOpen(false)
        }, 500)
    }

    return (
        <>
            <Tooltip arrow title={tooltipTitle}>
                <div>
                    <Button type="primary-light" onClick={canSend ? handleSend : undefined} disabled={!canSend}>
                        <Send size={18} />
                        <SafeFormattedMessage {...messages.send} />
                    </Button>
                </div>
            </Tooltip>

            {shareObjectModalOpen ? (
                <ShareObjectModal
                    open={shareObjectModalOpen}
                    close={hideShareObjectModal}
                    objectType={SharedObjectType.PurchaseOrder}
                    objectId={PO.id}
                    organizationId={organisation?.id || ""}
                    buyerOrganization={buyerOrganization}
                    supplierOrganization={supplierOrganization}
                    sendShareEmail={false}
                    callback={handleSharedObject}
                />
            ) : null}
        </>
    )
}
