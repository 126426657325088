/**
 * There is a race condition between the approval/refusal action and checking the review progress:
 *
 * 1. When a user approves/refuses, we need to verify if this was the final required approval
 * 2. The review progress is only updated after the approval/refusal is processed
 * 3. We don't know upfront how many approvals are required
 * 4. So we have to wait for the review progress to update before we can determine if the flow is complete
 *
 * As a temporary workaround, we add an artificial delay before checking the updated progress.
 * TODO: Implement proper synchronization between approval actions and progress updates
 * An idea is to use a websocket to notify the client when the review progress is updated
 */
import { useCallback, useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"

import { useIsAdminRole } from "~/domains/identity/roles-permissions/store/hooks"
import {
    useApproveObjectMutation,
    useLazyGetObjectChecksQuery,
    useRefusalReasonsQuery,
    useRefuseObjectMutation,
    useRetractAllReviewsMutation,
    useRetractReviewMutation,
} from "~/domains/orchestration/flows-v0/api/approvalApi"
import { RefusalReasonBody } from "~/domains/orchestration/flows-v0/types"
import {
    ApprovalApiResponse,
    ApprovalCheckI,
    ApprovalObjectType,
    ApprovalReviewerI,
} from "~/domains/orchestration/flows-v0/types/Approval"
import { selectUserId } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"
import { useFetchOrganizationTeams } from "~/store/organization/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { useGetAllUsersQuery } from "~/store/users/hooks"
import { OrganizationTeamI } from "~/types"

interface UseApprovalReviewProps {
    organisationId: string
    objectId: string
    objectType: ApprovalObjectType
    onReviewed?: (approved: boolean) => void
    onApproved?: () => void
    onRefused?: () => void
    onRetract?: () => void
    onRetractAll?: () => void
    onApprovalRequired?: () => void
    closeRefusalReason?: () => void
}

const HTTP_STATUS_NO_CONTENT = 204

const isPartnerPaymentDetails = (pathname: string) => {
    return pathname.includes("partners") && pathname.includes("payment-details")
}

const isUserReviewer = (reviewer: ApprovalReviewerI) => {
    return reviewer.type === "User" && reviewer.userId !== undefined
}

const isTeamReviewer = (reviewer: ApprovalReviewerI) => {
    return reviewer.type === "Team" && reviewer.teamId !== undefined
}

const isCurrentUserReviewRequired = (reviewers: ApprovalReviewerI[], userId: string, teams: OrganizationTeamI[]) => {
    return reviewers.some(
        (reviewer) =>
            (isUserReviewer(reviewer) && reviewer.userId === userId) ||
            (isTeamReviewer(reviewer) &&
                teams?.some(
                    (team) => team.teamId === reviewer.teamId && team.members.some((member) => member.userId === userId)
                ))
    )
}

const isLastCheck = (progressResponse: ApprovalApiResponse) => {
    return progressResponse.checks.every(
        (check) =>
            check.passThreshold <= check.review.approvers.length ||
            check.refuseThreshold <= check.review.refusers.length
    )
}

export const useApprovalReview = ({
    organisationId,
    objectId,
    objectType,
    onReviewed,
    onApproved,
    onRefused,
    onRetract,
    onRetractAll,
    onApprovalRequired,
    closeRefusalReason,
}: UseApprovalReviewProps) => {
    const [loading, setLoading] = useState(true)
    const [isReviewing, setIsReviewing] = useState(false)
    const [reviewed, setReviewed] = useState(false)
    const [userIds, setUsersIds] = useState<string[]>([])

    const [isApprovalDone, setIsApprovalDone] = useState(false)

    const [isUserChecks, setIsUserChecks] = useState(true)
    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId) || ""
    const location = useLocation()
    const userId = useAppSelector(selectUserId)

    const isPartnerPaymentDetailApproval = isPartnerPaymentDetails(location.pathname)
    const reviewersId = isPartnerPaymentDetailApproval ? currentOrganizationId : organisationId

    const { users } = useGetAllUsersQuery(userIds)
    const { teams } = useFetchOrganizationTeams(reviewersId, true)
    const isAdmin = useIsAdminRole()

    const tempObjectType = isPartnerPaymentDetailApproval
        ? ApprovalObjectType.PARTNER_PAYMENT_METHOD_DETAILS
        : objectType

    const checkParams = useMemo(
        () => ({
            organisationId: isPartnerPaymentDetailApproval ? currentOrganizationId : organisationId,
            objectId,
            objectType: tempObjectType,
        }),
        [isPartnerPaymentDetailApproval, currentOrganizationId, organisationId, objectId, tempObjectType]
    )

    const [checkProgressTrigger, { data: progressResponse, isFetching: isProgressFetching }] =
        useLazyGetObjectChecksQuery()

    const { data: allReasons = { customReasons: [], defaultReasons: [] } } = useRefusalReasonsQuery(checkParams, {
        skip: !objectId,
    })

    const { customReasons, defaultReasons } = allReasons
    const refusalReasons = customReasons.length > 0 ? customReasons : defaultReasons

    const [approveObject, { isLoading: approveLoading }] = useApproveObjectMutation()
    const [refuseObject, { isLoading: refuseLoading }] = useRefuseObjectMutation()

    const [retractReview, { isLoading: retractLoading }] = useRetractReviewMutation()
    const [retractAllReviews, { isLoading: retractAllLoading }] = useRetractAllReviewsMutation()

    const alreadyReviewed = useCallback(
        (response: ApprovalApiResponse) => {
            return response.checks.some((check) =>
                [...check.review.approvers, ...check.review.refusers].some((reviewer) => reviewer.userId === userId)
            )
        },
        [userId]
    )

    const mutating = approveLoading || refuseLoading || retractLoading || isProgressFetching

    const isApprovalRequired = Boolean(progressResponse?.checks?.length)

    const isCurrentUserApprovalRequired = isApprovalRequired
        ? progressResponse?.checks.some((check) => isCurrentUserReviewRequired(check.reviewers, userId, teams))
        : false

    const isApprovalNeeded = (check: ApprovalCheckI) =>
        check.passThreshold <= check.review.approvers.length || check.refuseThreshold <= check.review.refusers.length

    const isUserApprovalDone = progressResponse?.checks
        .filter(isApprovalNeeded)
        .some((check) => isCurrentUserReviewRequired(check.reviewers, userId, teams))

    useEffect(() => {
        if (progressResponse) {
            const ids = progressResponse.checks.flatMap((check) => check.reviewers).map((reviewer) => reviewer.userId)
            setUsersIds(ids.filter((id) => id !== undefined))
            if (onApprovalRequired && isApprovalRequired) {
                onApprovalRequired()
            }
        }
    }, [progressResponse, isApprovalRequired, onApprovalRequired])

    useEffect(() => {
        if (userId && progressResponse) {
            setLoading(false)
            setReviewed(alreadyReviewed(progressResponse))
        }
    }, [progressResponse, userId, alreadyReviewed])

    useEffect(() => {
        if (checkParams.objectId && checkParams.objectType) {
            checkProgressTrigger({ ...checkParams, userChecks: false })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUserChecks, checkParams])

    useEffect(() => {
        if (mutating || isProgressFetching) return

        // FIXME: this is a hack to wait for the review to be updated
        const timeout = setTimeout(() => {
            if (progressResponse) {
                setIsApprovalDone(isLastCheck(progressResponse))
            }
        }, 3000)
        return () => clearTimeout(timeout)
    }, [progressResponse, mutating, isProgressFetching])

    const handleApprove = async () => {
        setIsReviewing(true)
        const response = await approveObject({ objectId, objectType: tempObjectType }).unwrap()
        // FIXME: this is a hack to wait for the review to be updated
        setTimeout(async () => {
            const progress = await checkProgressTrigger({ ...checkParams, userChecks: false }).unwrap()

            if (response === HTTP_STATUS_NO_CONTENT && isLastCheck(progress)) {
                onApproved?.()
                setIsReviewing(false)
                setReviewed(true)
                onReviewed?.(true)
            }
        }, 2000)
    }

    const handleUpdateApproval = async () => {
        const response = await retractReview({ objectId, objectType }).unwrap()
        if (response === HTTP_STATUS_NO_CONTENT) {
            onRetract?.()
        }
        setReviewed(false)
    }

    const handleRetractAll = async () => {
        const response = await retractAllReviews({ objectId, objectType: tempObjectType }).unwrap()
        if (response === HTTP_STATUS_NO_CONTENT) {
            onRetractAll?.()
        }
        setReviewed(false)
    }

    const handleRefuse = async (body?: RefusalReasonBody) => {
        setIsReviewing(true)

        const response = await refuseObject({
            objectId,
            objectType: tempObjectType,
            body,
        }).unwrap()

        closeRefusalReason?.()
        // FIXME: this is a hack to wait for the review to be updated
        setTimeout(async () => {
            const progress = await checkProgressTrigger({ ...checkParams, userChecks: false }).unwrap()

            if (response === HTTP_STATUS_NO_CONTENT && isLastCheck(progress)) {
                onRefused?.()
                setIsReviewing(false)
                setReviewed(true)
                onReviewed?.(false)
            }
        }, 2000)
    }

    const handleUserChecks = useCallback(() => {
        setIsUserChecks((prev) => !prev)
    }, [])

    return {
        loading,
        isReviewing,
        isUserChecks,
        reviewed,
        progressResponse,
        users,
        teams,
        retractLoading,
        retractAllLoading,
        refusalReasons,
        handleApprove,
        handleRefuse,
        handleUpdateApproval,
        handleRetractAll,
        handleUserChecks,
        isApprovalDone,
        isUserApprovalDone,
        isApprovalRequired,
        isCurrentUserApprovalRequired,
        isAdmin,
    }
}
