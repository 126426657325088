import { LinearProgress, Stack } from "@mui/material"
import {
    GridColDef,
    GridFilterModel,
    GridInitialState,
    GridPaginationModel,
    GridRowIdGetter,
    GridRowParams,
    GridRowSelectionModel,
    GridSortModel,
    useGridApiRef,
} from "@mui/x-data-grid-premium"
import { useCallback, useEffect, useMemo, useState } from "react"
import { generatePath, useNavigate } from "react-router-dom"

import { DataGridNoRows } from "~/components/DataGrid/DataGridNoRows"
import { DataGridPremiumWithState } from "~/components/DataGrid/DataGridPremiumWithState"
import { initialState } from "~/constants/dataGrid"
import { useOrganizationTagGroups, useTagsGroupedByTagId } from "~/domains/analytics/tags/hooks"
import { selectSelectedTagsForFilter } from "~/domains/analytics/tags/store/tagsSlice"
import {
    useGetInvoicesByIdsQuery,
    useGetInvoicesQuery,
    useGetInvoicesSummaryQuery,
} from "~/domains/transactions/invoices-v1/api/invoiceApi"
import { GetInvoicesQuerySortBy } from "~/domains/transactions/invoices-v1/api/types/invoiceApi.type"
import { InvoicesBulkActions } from "~/domains/transactions/invoices-v1/components/list/InvoicesBulkActions"
import { InvoicesListTabs } from "~/domains/transactions/invoices-v1/components/list/InvoicesListTabs"
import { useGetInvoicesListTableColumns } from "~/domains/transactions/invoices-v1/components/list/useGetInvoicesListTableColumns"
import { INVOICE_ROUTE } from "~/domains/transactions/invoices-v1/routes"
import { InvoiceSummaryTypes } from "~/domains/transactions/invoices-v1/types/Invoice"
import { EDIT_INVOICE_BUYER_ROUTE } from "~/domains/transactions/invoices/_views/buyer/routes"
import { EDIT_INVOICE_SUPPLIER_ROUTE } from "~/domains/transactions/invoices/_views/supplier/routes"
import { useAppSelector } from "~/store/hooks"
import { InvoiceI, InvoiceStatus, OrganizationId, ViewTypes } from "~/types"
import { DEFAULT_PAGE_SIZE } from "~/utils"

const getRowId: GridRowIdGetter<InvoiceI> = (invoice) => invoice.id

const STATE_KEY = "invoicesList"

const initialGridState: GridInitialState = {
    ...initialState,
    columns: {
        columnVisibilityModel: {
            issuedAt: false,
            elapsedTime: false,
        },
    },
}

interface InvoicesListProps {
    organizationId: OrganizationId
    currentStatus?: string
    currentSummaryType?: InvoiceSummaryTypes
    dueBefore?: string
    dueAfter?: string
    paid?: boolean
    view?: ViewTypes
    otherOrganizationIds?: string[]
    invoiceIds?: string[]
    hideSearch?: boolean
    hideToolbar?: boolean
    actionColumn?: GridColDef<InvoiceI>
    setFilters?: (summaryType?: InvoiceSummaryTypes, status?: string) => void
}

const getInvoiceRoute = (invoiceId: string, view: ViewTypes | undefined, status: InvoiceStatus) => {
    if (status === InvoiceStatus.DRAFT) {
        if (view === "supplier") {
            return generatePath(EDIT_INVOICE_SUPPLIER_ROUTE, { invoiceId })
        }
        return generatePath(EDIT_INVOICE_BUYER_ROUTE, { invoiceId })
    }
    return generatePath(INVOICE_ROUTE, { invoiceId })
}

export const InvoicesList = ({
    organizationId,
    currentStatus = "",
    currentSummaryType = "all",
    dueBefore,
    dueAfter,
    paid,
    view,
    otherOrganizationIds = [],
    invoiceIds,
    hideSearch,
    hideToolbar,
    actionColumn,
    setFilters,
}: InvoicesListProps) => {
    const navigate = useNavigate()
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "issuedAt", sort: "desc" }])
    const [filterModel, setFilterModel] = useState<GridFilterModel>()
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: initialState?.pagination?.paginationModel?.page || 0,
        pageSize: initialState?.pagination?.paginationModel?.pageSize || DEFAULT_PAGE_SIZE,
    })
    const [cursorStack, setCursorStack] = useState<string[]>([""])
    const [status, setStatus] = useState(currentStatus || "")
    const [selected, setSelected] = useState<string[]>([])

    const apiRef = useGridApiRef()

    const selectedTags = useAppSelector(selectSelectedTagsForFilter)
    const { tagGroups } = useOrganizationTagGroups(organizationId)
    const tagByTagId = useTagsGroupedByTagId(tagGroups)

    const {
        data,
        isFetching: isInvoicesLoading,
        refetch,
    } = useGetInvoicesQuery(
        {
            organizationIds: [organizationId, ...otherOrganizationIds],
            cursor: cursorStack[cursorStack.length - 1],
            pageSize: paginationModel.pageSize,
            status,
            dueBefore,
            dueAfter,
            paid,
            sortBy: sortModel?.[0]?.field as GetInvoicesQuerySortBy,
            ascending: sortModel?.[0]?.sort === "asc",
            tagIds: selectedTags.map((selectedTag) => selectedTag.tagId),
            payerId: view === "buyer" ? organizationId : undefined,
            sellerId: view === "supplier" ? organizationId : undefined,
            filterModel,
        },
        {
            skip: !!invoiceIds,
        }
    )

    const { data: invoicesByIds } = useGetInvoicesByIdsQuery(
        {
            organizationId,
            ids: invoiceIds || [],
        },
        {
            skip: !invoiceIds,
        }
    )

    const { data: invoiceSummary } = useGetInvoicesSummaryQuery({
        organizationId,
        payerId: view === "buyer" ? organizationId : undefined,
        sellerId: view === "supplier" ? organizationId : undefined,
    })

    const totalCount = Object.values(invoiceSummary?.all || {}).reduce((acc, curr) => acc + curr.count, 0)

    const invoices = useMemo(() => invoicesByIds || data?.items || [], [data, invoicesByIds])

    const columns = useGetInvoicesListTableColumns(organizationId, tagByTagId, actionColumn, currentStatus)

    const resetQuery = useCallback(() => {
        setCursorStack([""])
        setPaginationModel((prev) => ({ ...prev, page: 0 }))
    }, [])

    const clearFilters = () => {
        setFilterModel({ items: [] })
        setStatus("")
        setFilters?.("all", undefined)
        resetQuery()
        refetch()
    }

    useEffect(() => {
        setStatus(currentStatus)
        resetQuery()
    }, [
        organizationId,
        currentStatus,
        dueBefore,
        dueAfter,
        paid,
        view,
        selectedTags,
        filterModel,
        paginationModel?.pageSize,
        refetch,
        resetQuery,
    ])

    const handleRowClick = ({ row: { invoiceId, status: invoiceStatus } }: GridRowParams<InvoiceI>) => {
        navigate(getInvoiceRoute(invoiceId, view, invoiceStatus))
    }

    const handleChangeTab = (s?: string, ps?: InvoiceSummaryTypes) => {
        setStatus(s || "")
        setFilters?.(ps, s)
        resetQuery()
    }

    const handleSortModelChange = (model: GridSortModel) => {
        setSortModel(model)
        resetQuery()
    }

    const handleFilterModelChange = (model: GridFilterModel) => {
        setFilterModel(model)
        resetQuery()
    }

    const handleSelectRows = (rowSelectionModel: GridRowSelectionModel) => {
        setSelected(rowSelectionModel as string[])
    }

    const handlePaginationModelChange = (pm: GridPaginationModel) => {
        const newPage = pm.page

        if (newPage > paginationModel.page) {
            const lastItem = invoices?.[invoices.length - 1]
            const nextCursor = lastItem?.nextCursor

            if (!nextCursor || nextCursor === cursorStack[cursorStack.length - 1]) return

            setCursorStack((prev) => [...prev, nextCursor])
        } else if (newPage < paginationModel.page) {
            if (cursorStack.length <= 1) return

            setCursorStack((prev) => prev.slice(0, -1))
        }
        setPaginationModel({ ...pm, page: newPage })
    }

    return (
        <>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                {!hideSearch && (
                    <InvoicesListTabs
                        currentSummaryType={currentSummaryType}
                        status={status}
                        view={view}
                        onChange={handleChangeTab}
                    />
                )}
            </Stack>

            <DataGridPremiumWithState
                stateKey={STATE_KEY}
                apiRef={apiRef}
                initialState={initialGridState}
                sortingMode="server"
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                filterMode="server"
                filterModel={filterModel}
                filterDebounceMs={500}
                onFilterModelChange={handleFilterModelChange}
                rows={invoices}
                columns={columns}
                getRowId={getRowId}
                onRowClick={handleRowClick}
                disableRowSelectionOnClick
                checkboxSelection={!!setSelected}
                rowSelectionModel={selected}
                onRowSelectionModelChange={handleSelectRows}
                pagination
                paginationMode="server"
                paginationModel={paginationModel}
                rowCount={totalCount}
                onPaginationModelChange={handlePaginationModelChange}
                slots={{
                    loadingOverlay: LinearProgress,
                    noRowsOverlay: () => <DataGridNoRows resetSearchQuery={hideSearch ? undefined : clearFilters} />,
                }}
                loading={isInvoicesLoading}
                hideToolbar={hideToolbar}
            />

            <InvoicesBulkActions invoices={invoices} selected={selected} setSelected={setSelected} />
        </>
    )
}
