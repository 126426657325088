import { XYPosition } from "reactflow"

import { CurrencyCodes } from "~/types/Currencies"

import {
    AssignTagGroupNode,
    BranchNode,
    ConditionalNode,
    CurrencyConversion,
    CurrencyConversionMode,
    EditorNode,
    EntityTriggerNode,
    EntityType,
    Event,
    EventTriggerNode,
    FlowValidation,
    InvalidFlow,
    NodeType,
    ObjectType,
    PartnershipEntity,
    PoStatus,
    PurchaseOrderEntity,
    PurchaseRequestEntity,
    RegularNode,
    Reviewer,
    TeamReviewer,
    UserReviewer,
    UserType,
    ValidFlow,
} from "./Flows"

// type guard to check if node is an EventTriggerNode
export const isEventTriggerNode = (node: EditorNode): node is EventTriggerNode =>
    node.type === NodeType.EVENT_TRIGGER_NODE

// type guard to check if node is an EntityTriggerNode
export const isEntityTriggerNode = (node: EditorNode): node is EntityTriggerNode =>
    node.type === NodeType.ENTITY_TRIGGER_NODE

// type guard to check if string is Event
export const isEvent = (event: string): event is Event => Object.values(Event).includes(event as Event)

// type guard to check if string is ObjectType
export const isObjectType = (type: string): type is ObjectType => Object.values(ObjectType).includes(type as ObjectType)

// type guard to check if node is an AssignTagGroupNode
export const isAssignTagGroupNode = (node: EditorNode): node is AssignTagGroupNode =>
    node.type === NodeType.ASSIGN_TAG_GROUP_NODE

// type guard to check if node is an BranchNode
export const isBranchNode = (node: EditorNode): node is BranchNode => node.type === NodeType.BRANCH_NODE

// type guard to check if node has nextNode
export const hasNextNode = (node: EditorNode): node is EditorNode & RegularNode =>
    "nextNode" in node && node.nextNode !== null

// type guard to check if node is conditional
export const isConditionalNode = (node: EditorNode): node is EditorNode & ConditionalNode =>
    "nextIfSuccess" in node && "nextIfFailure" in node

// type guard to check if node is regular
export const isRegularNode = (node: EditorNode): node is EditorNode & RegularNode => "nextNode" in node
// type guard to check if metadata position is position
export const isPosition = (position: unknown): position is XYPosition => {
    if (typeof position !== "object" || position === null) return false
    if (!("x" in position) || !("y" in position)) return false
    if (typeof (position as XYPosition).x !== "number" || typeof (position as XYPosition).y !== "number") return false
    return true
}

// type guard to check if reviewer is a UserReviewer
export const isUserReviewer = (reviewer: Reviewer): reviewer is UserReviewer => reviewer.type === UserType.USER

// type guard to check if reviewer is a TeamReviewer
export const isTeamReviewer = (reviewer: Reviewer): reviewer is TeamReviewer => reviewer.type === UserType.TEAM

// type guard to check if flow is invalid
export const isFlowInvalid = (flowValidation: FlowValidation): flowValidation is InvalidFlow =>
    "error" in flowValidation

// type guard to check if flow is valid
export const isFlowValid = (flowValidation: FlowValidation): flowValidation is ValidFlow => "isValid" in flowValidation

// type guard to check if value is a PoStatus
export const isPoStatus = (value: unknown): value is PoStatus => Object.values(PoStatus).includes(value as PoStatus)

// type guard to check if value is a CurrencyConversion
export const isCurrencyConversion = (value: unknown): value is CurrencyConversion =>
    !!(value && typeof value === "object" && "mode" in value && "targetCurrency" in value)

// type guard to check if value is a CurrencyConversionMode
export const isCurrencyConversionMode = (value: unknown): value is CurrencyConversionMode => {
    if (typeof value !== "string") return false
    return Object.values(CurrencyConversionMode).includes(value as CurrencyConversionMode)
}

// type guard to check if value is a CurrencyCodes
export const isCurrencyCodes = (value: unknown): value is CurrencyCodes => {
    if (typeof value !== "string") return false
    return Object.values(CurrencyCodes).includes(value as CurrencyCodes)
}

//type guard to check if entity is a PartnershipEntity
export const isPartnershipEntity = (entity: unknown): entity is PartnershipEntity =>
    !!(entity && typeof entity === "object" && "type" in entity && entity.type === EntityType.PARTNERSHIP)

//type guard to check if entity is a PurchaseOrderEntity
export const isPurchaseOrderEntity = (entity: unknown): entity is PurchaseOrderEntity =>
    !!(entity && typeof entity === "object" && "type" in entity && entity.type === EntityType.PURCHASE_ORDER)

//type guard to check if entity is a PurchaseRequestEntity
export const isPurchaseRequestEntity = (entity: unknown): entity is PurchaseRequestEntity =>
    !!(entity && typeof entity === "object" && "type" in entity && entity.type === EntityType.PURCHASE_REQUEST)

//type guard to check if entity has currencyConversion
export const hasCurrencyConversion = (entity: unknown): entity is PurchaseOrderEntity | PurchaseRequestEntity =>
    isPurchaseOrderEntity(entity) || isPurchaseRequestEntity(entity)
