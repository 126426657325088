import { useCallback, useEffect } from "react"

import { SubscriptionEventI, SubscriptionEventType, SubscriptionType } from "~/domains/_shared/subscriptions"
import { useSubscribeToUpdate } from "~/domains/_shared/subscriptions/hooks"
import { useAppSelector } from "~/store/hooks"
import { useFetchPurchaseRequestWorkflows } from "~/store/workflow/hooks"
import { OrganizationId } from "~/types"

import { selectPurchaseRequest } from "../purchaseRequestsSlice"
import { useFetchPurchaseRequest } from "./useFetchPurchaseRequest"

export const usePurchaseRequest = (
    organizationId: OrganizationId | undefined,
    purchaseRequestId: string | undefined
) => {
    const purchaseRequest = useAppSelector(selectPurchaseRequest)

    const { fetchPurchaseRequest } = useFetchPurchaseRequest(organizationId)
    const { fetchPurchaseRequestChecks } = useFetchPurchaseRequestWorkflows(organizationId, purchaseRequestId)

    const doFetch = useCallback(() => {
        if (purchaseRequestId && organizationId) {
            fetchPurchaseRequest(purchaseRequestId)
        }
    }, [purchaseRequestId, organizationId, fetchPurchaseRequest])

    useEffect(() => {
        if (purchaseRequestId && organizationId) {
            doFetch()
        }
    }, [purchaseRequestId, organizationId, doFetch])

    const onPurchaseRequestEvent = useCallback(
        (event: SubscriptionEventI) => {
            if (event.type === SubscriptionEventType.SELF || event.type === SubscriptionEventType.WORKFLOW) {
                doFetch()
            }
            if (event.type === SubscriptionEventType.WORKFLOW) {
                fetchPurchaseRequestChecks()
            }
        },
        [doFetch, fetchPurchaseRequestChecks]
    )

    useSubscribeToUpdate(SubscriptionType.PURCHASE_REQUEST, purchaseRequestId, onPurchaseRequestEvent)

    return { purchaseRequest }
}
