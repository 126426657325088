import {
    Autocomplete,
    AutocompleteRenderGetTagProps,
    AutocompleteRenderInputParams,
    Chip,
    TextField,
    styled,
} from "@mui/material"
import React, { FC, useCallback } from "react"

import { useAppSelector } from "~/store/hooks"
import { useFetchOrganizationMembers } from "~/store/organization/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { OrganizationMemberUserI } from "~/types"

interface SharedUserSelectProps {
    members: OrganizationMemberUserI[]
    selectedUsers: OrganizationMemberUserI[]
    onSelectUsers: (event: React.SyntheticEvent, values: OrganizationMemberUserI[]) => void
    label: string
    loading?: boolean
    showIcon?: boolean
}
const Tags = styled(Chip)`
    margin: var(--spacing-xxs);
`

const renderTags = (value: OrganizationMemberUserI[], getTagProps: AutocompleteRenderGetTagProps) => {
    return value.map((option, index) => {
        const { onDelete } = getTagProps({ index })
        const name = option?.user?.fullName ?? ""
        return <Tags key={option.userId} label={name} variant="outlined" onDelete={onDelete} />
    })
}
const getOptionLabel = (option: OrganizationMemberUserI) => option?.user?.fullName ?? ""
const getOptionKey = (option: OrganizationMemberUserI) => option.userId
const isOptionEqualToValue = (option: OrganizationMemberUserI, value: OrganizationMemberUserI) =>
    option.userId === value.userId

export const SharedUserSelect: FC<SharedUserSelectProps> = ({
    selectedUsers,
    onSelectUsers,
    label,
    loading = false,
}) => {
    const organization = useAppSelector(selectCurrentOrganization)
    const [members] = useFetchOrganizationMembers(organization!)

    const renderInput = useCallback(
        (params: AutocompleteRenderInputParams) => <TextField {...params} label={label} />,
        [label]
    )

    return (
        <Autocomplete
            multiple
            autoComplete
            options={members}
            value={selectedUsers}
            onChange={onSelectUsers}
            getOptionKey={getOptionKey}
            getOptionLabel={getOptionLabel}
            filterSelectedOptions
            isOptionEqualToValue={isOptionEqualToValue}
            renderInput={renderInput}
            loading={loading}
            renderTags={renderTags}
        />
    )
}
