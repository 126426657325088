/* eslint-disable complexity */
import { PurchaseOrderSummaryAPI } from "~/domains/transactions/purchase-orders/api/types/purchaseOrderApi.type"
import { PurchaseOrderSummary } from "~/domains/transactions/purchase-orders/types"

export const purchaseOrderAnalyticsFromApiAdapter = (
    purchaseOrderSummary: PurchaseOrderSummaryAPI
): PurchaseOrderSummary => {
    const { statusPresentation, fulfillmentPresentation } = purchaseOrderSummary?.statusCounts || {
        status: {},
        statusPresentation: {},
        fulfillmentPresentation: {},
    }

    return {
        statusPresentation: {
            DRAFT: statusPresentation?.DRAFT || 0,
            SUBMITTED: statusPresentation?.SUBMITTED || 0,
            INTERNALLY_APPROVED: statusPresentation?.INTERNALLY_APPROVED || 0,
            OPEN: statusPresentation?.OPEN || 0,
            CLOSED: statusPresentation?.CLOSED || 0,
        },
        fulfillmentPresentation: {
            PENDING: fulfillmentPresentation?.PENDING || 0,
            IN_PREPARATION: fulfillmentPresentation?.IN_PREPARATION || 0,
            PARTIALLY_RECEIVED: fulfillmentPresentation?.PARTIALLY_RECEIVED || 0,
            SHIPPED: fulfillmentPresentation?.SHIPPED || 0,
            RECEIVED: fulfillmentPresentation?.RECEIVED || 0,
            CANCELED: fulfillmentPresentation?.CANCELED || 0,
        },
    }
}
