import { FormGroup, MenuItem, Select, SelectChangeEvent, SelectProps, Typography, capitalize } from "@mui/material"
import * as Sentry from "@sentry/browser"
import { useCallback, useEffect, useMemo, useState } from "react"

import { Button, ButtonType } from "~/components"
import { usePatchUpdateInvoiceMutation } from "~/domains/transactions/invoices-v1/api/invoiceApi"
import {
    InvoiceLifeCycleItem,
    InvoiceParty,
    LifecycleGroupVariant,
} from "~/domains/transactions/invoices-v1/types/Invoice"
import { InvoiceV1Payload } from "~/domains/transactions/invoices-v1/types/InvoicePayload"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { invoiceActions, selectInvoice } from "~/store/invoice/invoiceSlice"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"

const findLifecycleGroupVariant = (lifecycle: InvoiceLifeCycleItem[], status: string) => {
    const group = lifecycle.find((lc) => lc?.status === status)?.group
    return LifecycleGroupVariant[group || "DRAFT"]
}

export const InvoiceLifeCycleNextButton = () => {
    const dispatch = useAppDispatch()
    const invoice = useAppSelector(selectInvoice)
    const organizationId = useAppSelector(selectCurrentOrganizationId)
    const [patchUpdateInvoice] = usePatchUpdateInvoiceMutation()

    const party = invoice?.parties?.[organizationId || ""]
    const [adjacencyLifecycles, setAdjacencyLifecycles] = useState<
        { name: string; variant: SelectProps["color"] | "grey" }[] | null
    >(null)

    const lifecycle = useMemo(() => {
        const defaultLifecycle = [party?.template?.lifecycle?.default]
        const otherLifecycles = party?.template?.lifecycle?.other ?? []
        return [...defaultLifecycle, ...otherLifecycles].filter(Boolean) as InvoiceLifeCycleItem[]
    }, [party?.template?.lifecycle?.default, party?.template?.lifecycle?.other])

    useEffect(() => {
        const currentLifecycle = (lifecycle || []).find((lc) => lc?.status === party?.currentLifecycle)

        if (!lifecycle || !currentLifecycle) return

        const al = [
            { name: currentLifecycle?.status || "", variant: LifecycleGroupVariant[currentLifecycle.group] },
            ...(currentLifecycle?.adjacency || []).map((adjacency) => {
                return {
                    name: adjacency,
                    variant: findLifecycleGroupVariant(lifecycle, adjacency),
                }
            }),
        ]

        setAdjacencyLifecycles(al)
    }, [lifecycle, party?.currentLifecycle])

    const handleUpdateLifecycle = useCallback(
        async (e: SelectChangeEvent<string>) => {
            e.preventDefault()
            const nextLifecycle = e.target.value
            if (!organizationId || !invoice || !adjacencyLifecycles) return

            const parties = { [organizationId]: { ...party, currentLifecycle: nextLifecycle } } as Record<
                string,
                InvoiceParty
            >

            try {
                await patchUpdateInvoice({ id: invoice?.id, parties } as InvoiceV1Payload)
                dispatch(invoiceActions.updatePartialInvoice({ id: invoice.id, parties }))
            } catch (error) {
                Sentry.captureException(error)
            }
        },
        [organizationId, invoice, adjacencyLifecycles, party, patchUpdateInvoice, dispatch]
    )

    if (!adjacencyLifecycles?.length) return null

    return (
        <>
            {adjacencyLifecycles.length === 1 ? (
                <Button type={`${adjacencyLifecycles[0].variant}-light` as ButtonType} readOnly>
                    {capitalize(adjacencyLifecycles[0].name)}
                </Button>
            ) : (
                <FormGroup>
                    <Select
                        value={adjacencyLifecycles[0].name}
                        color={adjacencyLifecycles[0].variant as SelectProps["color"]}
                        onChange={handleUpdateLifecycle}
                        size="small"
                    >
                        {adjacencyLifecycles.map(({ name, variant }) => (
                            <MenuItem key={name} value={name}>
                                <Typography color={`${variant}.main`}>{capitalize(name)}</Typography>
                            </MenuItem>
                        ))}
                    </Select>
                </FormGroup>
            )}
        </>
    )
}
