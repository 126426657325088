import { Grid, Stack } from "@mui/material"
import { useEffect, useMemo } from "react"
import { AlertTriangle, X } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { batch } from "react-redux"
import { toast } from "react-toastify"

import { Button, Card, Modal, SafeFormattedMessage, Size } from "~/components"
import { useDocumentById } from "~/domains/identity/documents/hooks/useDocumentById"
import {
    useCreatePaymentMethodDetailsMutation,
    useGetManualVerificationsByIdQuery,
    useRequestManualVerificationMutation,
    useUpdatePaymentMethodDetailsMutation,
} from "~/domains/payment/payment-method-details/api/paymentMethodDetailsApi"
import {
    PaymentMethodFormBankTransfer,
    PaymentMethodFormCard,
    PaymentMethodFormDirectDebit,
    PaymentMethodUpload,
} from "~/domains/payment/payment-method-details/components"
import {
    selectSelectedItem,
    selectSelectedPaymentMethod,
} from "~/domains/payment/payment-method-details/store/paymentMethodDetailsSlice"
import { paymentMethodDetailsActions } from "~/domains/payment/payment-method-details/store/paymentMethodDetailsSlice"
import {
    BankTransferFormState,
    CardFormState,
    CreatePaymentMethodDetails,
    DirectDebitFormState,
    FormState,
    ManualVerification,
    PaymentMethodDetails,
    PaymentMethodDetailsFormType,
    VerificationMethod,
} from "~/domains/payment/payment-method-details/types"
import { mapToSave } from "~/domains/payment/payment-method-details/utils/mapPaymentMethodDetails"
import { useGetPaymentMethodsQuery } from "~/domains/payment/payment-methods/api/paymentMethodsApi"
import { PaymentMethodsSelector } from "~/domains/payment/payment-methods/components"
import { PaymentMethod, PaymentMethodType } from "~/domains/payment/payment-methods/types"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { OrganizationId } from "~/types"

const messages = defineMessages({
    addTitle: {
        id: "payment.paymentMethodDetailsManagement.addTitle",
        defaultMessage: "Add payment method",
    },
    editTitle: {
        id: "payment.paymentMethodDetailsManagement.editTitle",
        defaultMessage: "Edit payment method",
    },
    selectMessage: {
        id: "payment.paymentMethodDetailsManagement.selectMessage",
        defaultMessage: "Please select a payment method",
    },
    notSupportedMessage: {
        id: "payment.paymentMethodDetailsManagement.notSupportedMessage",
        defaultMessage: "This payment method is not supported yet",
    },
    successMessage: {
        id: "payment.paymentMethodDetailsManagement.successMessage",
        defaultMessage: "Payment method saved successfully",
    },
    paymentMethod: {
        id: "payment.paymentMethodDetailsManagement.paymentMethod",
        defaultMessage: "Payment method",
    },
})

const supportedPaymentMethods = [
    PaymentMethodType.CARD,
    PaymentMethodType.BANK_TRANSFER,
    PaymentMethodType.DIRECT_DEBIT,
]

interface PaymentMethodDetailsCreateOrEditModalProps {
    open: boolean
    activeTab: PaymentMethodType
    documentOwnerOrganizationId: OrganizationId
    formType?: PaymentMethodDetailsFormType
    onPaymentMethodDetailsCreated?: (data: PaymentMethodDetails) => void
    onClose: () => void
    onOpen?: () => void
}

export const PaymentMethodDetailsCreateOrEditModal = ({
    open,
    documentOwnerOrganizationId,
    activeTab,
    onPaymentMethodDetailsCreated,
    onClose,
    onOpen,
    formType = PaymentMethodDetailsFormType.MODAL,
}: PaymentMethodDetailsCreateOrEditModalProps) => {
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()
    const [createPaymentMethodDetails, { isLoading: createLoading }] = useCreatePaymentMethodDetailsMutation()
    const [updatePaymentMethodDetails, { isLoading: updateLoading }] = useUpdatePaymentMethodDetailsMutation()

    const { data: paymentMethods, isLoading: paymentMethodsLoading } = useGetPaymentMethodsQuery(null)
    const [requestManualVerification] = useRequestManualVerificationMutation()

    const selectedPaymentMethodDetails = useAppSelector(selectSelectedItem)
    const selectedPaymentMethod = useAppSelector(selectSelectedPaymentMethod)
    const paymentMethodType = selectedPaymentMethod?.payment_method_type ?? PaymentMethodType.BANK_TRANSFER
    const paymentMethodBasedOnTab = paymentMethods?.items.find((item) => item.payment_method_type === activeTab)
    const isBankTransfer = paymentMethodType === PaymentMethodType.BANK_TRANSFER

    const { document } = useDocumentById(selectedPaymentMethodDetails?.documentId)

    const { data: manualVerificationsData, isLoading: manualLoading } = useGetManualVerificationsByIdQuery(
        selectedPaymentMethodDetails?.id ?? "",
        {
            skip: !selectedPaymentMethodDetails?.id,
        }
    )
    const hasDocument =
        manualVerificationsData?.items?.some(
            (m: ManualVerification) => m?.verification_documents?.document_id === document?.id
        ) ?? false

    const isLoadingState = createLoading || updateLoading || paymentMethodsLoading || manualLoading

    const selectedPaymentMethodType =
        selectedPaymentMethod?.id ||
        selectedPaymentMethodDetails?.paymentMethodId ||
        paymentMethodBasedOnTab?.id ||
        null

    const handleClose = (_: React.SyntheticEvent, reason: "backdropClick" | "escapeKeyDown") => {
        if (reason && reason === "backdropClick") return
        clearStates()
    }

    const clearStates = () => {
        batch(() => {
            dispatch(paymentMethodDetailsActions.setSelectedItem(null))
            dispatch(paymentMethodDetailsActions.setSelectedPaymentMethod(null))
        })
        onClose()
    }

    const handleSave = async (values: FormState, resetForm: () => void) => {
        const paymentMethodDetailsId = selectedPaymentMethodDetails?.id
        const payload = mapToSave(
            {
                ...values,
                paymentMethodId: (selectedPaymentMethod?.id || paymentMethodBasedOnTab?.id) ?? null,
                paymentMethodType: selectedPaymentMethod?.payment_method_type ?? activeTab,
            },
            documentOwnerOrganizationId,
            paymentMethodDetailsId
        )

        const processVerification = async (pmdId: string) => {
            if (pmdId && selectedPaymentMethodDetails?.documentId && document && !hasDocument) {
                const { id, name, latestFileVersion } = document
                const verificationPayload: ManualVerification = {
                    payment_method_details_id: pmdId,
                    verification_method: VerificationMethod.DOCUMENT,
                    verification_documents: {
                        name,
                        url: latestFileVersion,
                        document_id: id,
                    },
                }

                await requestManualVerification(verificationPayload)
            }
        }

        if (paymentMethodDetailsId) {
            await updatePaymentMethodDetails(payload as PaymentMethodDetails).unwrap()
            await processVerification(selectedPaymentMethodDetails?.id ?? "")
        } else {
            const newPaymentMethodDetails = await createPaymentMethodDetails(
                payload as CreatePaymentMethodDetails
            ).unwrap()

            await processVerification(newPaymentMethodDetails.id)

            if (onPaymentMethodDetailsCreated) {
                onPaymentMethodDetailsCreated(newPaymentMethodDetails)
            }
        }

        clearStates()
        resetForm()
        toast.success(formatMessage(messages.successMessage))
    }

    const handlePaymentMethodChange = (paymentMethod: PaymentMethod | null) => {
        dispatch(paymentMethodDetailsActions.setSelectedPaymentMethod(paymentMethod))
    }

    const handleCancel = (resetForm: () => void) => {
        clearStates()
        resetForm()
    }

    const renderForm = useMemo(
        () => (
            <Stack flexGrow={1} gap={2}>
                <PaymentMethodsSelector
                    loading={paymentMethodsLoading}
                    items={paymentMethods?.items ?? []}
                    value={selectedPaymentMethodType}
                    disabled={isLoadingState}
                    label={formatMessage(messages.paymentMethod)}
                    onChange={handlePaymentMethodChange}
                />
                {(paymentMethodType || activeTab) === PaymentMethodType.CARD && (
                    <PaymentMethodFormCard
                        loading={isLoadingState}
                        selectedItem={selectedPaymentMethodDetails as CardFormState}
                        onSubmit={handleSave}
                        onCancel={handleCancel}
                    />
                )}
                {(paymentMethodType || activeTab) === PaymentMethodType.BANK_TRANSFER && (
                    <PaymentMethodFormBankTransfer
                        loading={isLoadingState}
                        selectedItem={selectedPaymentMethodDetails as BankTransferFormState}
                        onSubmit={handleSave}
                        onCancel={handleCancel}
                    />
                )}
                {(paymentMethodType || activeTab) === PaymentMethodType.DIRECT_DEBIT && (
                    <PaymentMethodFormDirectDebit
                        loading={isLoadingState}
                        selectedItem={selectedPaymentMethodDetails as DirectDebitFormState}
                        onSubmit={handleSave}
                        onCancel={handleCancel}
                    />
                )}
                {!!selectedPaymentMethod &&
                    !supportedPaymentMethods.includes(selectedPaymentMethod.payment_method_type) && (
                        <>
                            <AlertTriangle size={48} color="var(--color-grey-lighter)" />
                            <SafeFormattedMessage {...messages.notSupportedMessage} />
                        </>
                    )}
                <input type="hidden" name="documentId" value={selectedPaymentMethodDetails?.documentId} />
            </Stack>
        ),
        [
            paymentMethodsLoading,
            paymentMethods,
            selectedPaymentMethod,
            selectedPaymentMethodDetails,
            paymentMethodBasedOnTab,
            updateLoading,
            createLoading,
            formatMessage,
            paymentMethodType,
            activeTab,
            handlePaymentMethodChange,
            handleSave,
            handleCancel,
        ]
    )

    useEffect(() => {
        if (selectedPaymentMethodDetails && !open) {
            const paymentMethod =
                paymentMethods?.items.find((item) => item.id === selectedPaymentMethodDetails.paymentMethodId) ?? null

            dispatch(paymentMethodDetailsActions.setSelectedPaymentMethod(paymentMethod))
            if (onOpen) {
                onOpen()
            }
        }
    }, [open, paymentMethods, selectedPaymentMethodDetails, dispatch, onOpen])

    return formType === PaymentMethodDetailsFormType.MODAL ? (
        <Modal size={isBankTransfer ? Size.XXL : Size.MD} open={open} onClose={handleClose}>
            <Modal.Header>
                <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
                    <h4>
                        <SafeFormattedMessage
                            {...(selectedPaymentMethodDetails ? messages.editTitle : messages.addTitle)}
                        />
                    </h4>
                    <Button type="title" onClick={clearStates}>
                        <X size={24} color="var(--color-grey-light)" />
                    </Button>
                </Stack>
            </Modal.Header>
            <Modal.Content>
                <Grid container spacing={2}>
                    {isBankTransfer && (
                        <Grid item sm={12} lg={6}>
                            <PaymentMethodUpload />
                        </Grid>
                    )}
                    <Grid position="sticky" top={0} alignSelf="flex-start" item sm={12} lg={isBankTransfer ? 6 : 12}>
                        {renderForm}
                    </Grid>
                </Grid>
            </Modal.Content>
        </Modal>
    ) : (
        <Card>{renderForm}</Card>
    )
}
