import { useCallback, useState } from "react"

import { purchaseRequestApi } from "~/domains/transactions/purchase-requests/api/purchaseRequestsApi"
import { useAppDispatch } from "~/store/hooks"
import { OrganizationId } from "~/types"
import { ImportBatchResponseI } from "~/types/ImportBatch"

export const useImportBatchPurchaseRequest = (organizationId: OrganizationId) => {
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string>()

    const importBatchPR: (file: File) => Promise<ImportBatchResponseI> = useCallback(
        async (file: File) => {
            try {
                setLoading(true)
                const result: ImportBatchResponseI = await purchaseRequestApi.importBatchPR(organizationId, file)
                setLoading(false)
                return result
            } catch (e) {
                setError(`${e}`)
                setLoading(false)
                throw e
            } finally {
                setLoading(false)
            }
        },
        [organizationId, dispatch]
    )

    return {
        importBatchPR,
        loading,
        error,
    }
}
