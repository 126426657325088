import { Tooltip } from "@mui/material"
import { useState } from "react"
import { X } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { Button, SafeFormattedMessage } from "~/components"
import { ApprovalOverrideModal } from "~/domains/orchestration/flows-v0/components/ApprovalBlock/ApprovalOverrideModal"
import { useProcessBulkReviewItems } from "~/domains/orchestration/flows-v0/hooks/useProcessBulkReviewItems"
import { ApprovalObjectType } from "~/domains/orchestration/flows-v0/types/Approval"
import { useFetchPurchaseOrder } from "~/domains/transactions/purchase-orders/store/hooks/useFetchPurchaseOrder"
import { useUpdatePurchaseOrderApprovalStatus } from "~/domains/transactions/purchase-orders/store/hooks/useUpdatePurchaseOrderApprovalStatus"
import { PurchaseOrders } from "~/domains/transactions/purchase-orders/types"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"

const messages = defineMessages({
    reject: {
        id: "purchase.orders.order.action.reject",
        defaultMessage: "Reject",
    },
})

interface RejectPOProps {
    PO: PurchaseOrders
    needsNewApproval: boolean
    isFlowApprovalDone: boolean
}

export const RejectPO = ({ PO, needsNewApproval, isFlowApprovalDone }: RejectPOProps) => {
    const { formatMessage } = useIntl()
    const [showModal, setShowModal] = useState(false)

    const organization = useAppSelector(selectCurrentOrganization)
    const { processBulkReviewItems } = useProcessBulkReviewItems()
    const { updatePOApprovalStatus } = useUpdatePurchaseOrderApprovalStatus(PO.id)

    const { fetchPurchaseOrder } = useFetchPurchaseOrder(organization?.id)

    const reject = async () => {
        if (!organization?.id) return
        // if the PO needs approval with the new approval service:
        const lineIds = PO.lines.map((line) => line.id ?? "")
        if (needsNewApproval) {
            await processBulkReviewItems(lineIds, false, ApprovalObjectType.PURCHASE_ORDER_LINE)
        }
        await updatePOApprovalStatus(organization.id, lineIds, "REJECTED")
        await fetchPurchaseOrder(PO.id)
    }

    const handleReject = async () => {
        if (!isFlowApprovalDone) {
            setShowModal(true)
            return
        }
        reject()
    }

    const handleConfirm = () => {
        reject()
        handleClose()
    }

    const handleClose = () => {
        setShowModal(false)
    }

    return (
        <>
            <Tooltip title={formatMessage(messages.reject)}>
                <span>
                    <Button type="error-light" onClick={handleReject}>
                        <X size={18} />
                        <SafeFormattedMessage {...messages.reject} />
                    </Button>
                </span>
            </Tooltip>
            <ApprovalOverrideModal open={showModal} onClose={handleClose} onConfirm={handleConfirm} />
        </>
    )
}
