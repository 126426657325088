import { useCallback } from "react"

import { usePatchUpdateInvoiceMutation } from "~/domains/transactions/invoices-v1/api/invoiceApi"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"

interface UseBulkUpdateLifecycleProps {
    invoiceIds: string[]
}

export const useBulkUpdateLifecycle = ({ invoiceIds }: UseBulkUpdateLifecycleProps) => {
    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)

    const [patchUpdateInvoice] = usePatchUpdateInvoiceMutation()

    const bulkUpdateLifecycle = useCallback(
        async (newStatus: string) => {
            if (!currentOrganizationId) return

            await Promise.allSettled(
                invoiceIds.map(async (invoiceId) => {
                    await patchUpdateInvoice({
                        id: invoiceId,
                        parties: { [currentOrganizationId]: { currentLifecycle: newStatus } },
                    })
                })
            )
        },
        [currentOrganizationId, invoiceIds, patchUpdateInvoice]
    )

    return [bulkUpdateLifecycle]
}
