import { styled } from "@mui/material"
import { FC } from "react"

import { useGetInvoicesQuery } from "~/domains/transactions/invoices-v1/api/invoiceApi"
import { InvoiceDuplicateWarning as InvoiceDuplicateWarningDeprecated } from "~/domains/transactions/invoices/components/InvoiceDuplicateWarning/InvoiceDuplicateWarning"
import { InvoiceI } from "~/types"

const InvoiceDuplicateWarningContainer = styled("div")(() => ({
    width: "100%",
    display: "flex",
    "> div": {
        flex: 1,
    },
}))

interface InvoiceDuplicateWarningProps {
    invoice: InvoiceI
}

export const InvoiceDuplicateWarning: FC<InvoiceDuplicateWarningProps> = ({ invoice }) => {
    const { data: possibleDuplicates } = useGetInvoicesQuery({
        organizationIds: [invoice.buyerId, invoice.supplierId],
        number: invoice.number,
        pageSize: 100,
        cursor: "",
    })

    if (!possibleDuplicates?.items.length) return null

    const duplicates = possibleDuplicates.items.filter((item) => item.id !== invoice.id).map((item) => item.id)

    return (
        <InvoiceDuplicateWarningContainer>
            <InvoiceDuplicateWarningDeprecated duplicates={duplicates} />
        </InvoiceDuplicateWarningContainer>
    )
}
