import { styled } from "@mui/material"
import { GridCellModesModel, GridCellParams, GridColDef, MuiBaseEvent, MuiEvent } from "@mui/x-data-grid-premium"
import { forwardRef, useCallback } from "react"

import { DataGridPremiumWithState, DataGridPremiumWithStateRef } from "~/components/DataGrid/DataGridPremiumWithState"
import { GridRow } from "~/domains/orchestration/flows/types/Editor"

interface MappingGridProps {
    stateKey: string
    rows: GridRow[]
    columns: GridColDef[]
    cellModesModel: GridCellModesModel
    onCellClick: (params: GridCellParams, event: MuiEvent<MuiBaseEvent>) => void
    onCellModesModelChange: (model: GridCellModesModel) => void
    onCellEditStop: (params: GridCellParams, event: MuiEvent<MuiBaseEvent>) => void
}

const StyledDataGrid = styled(DataGridPremiumWithState)(() => ({
    border: "none",
    "& .MMuiDataGrid-columnHeaders": {
        padding: "6px",
    },
}))

export interface MappingGridRef {
    api: DataGridPremiumWithStateRef["api"]
}

export const MappingGrid = forwardRef<MappingGridRef, MappingGridProps>(
    ({ stateKey, rows, columns, cellModesModel, onCellClick, onCellModesModelChange, onCellEditStop }, ref) => {
        const getRowId = useCallback((row: GridRow) => row.id, [])

        return (
            <StyledDataGrid
                ref={ref}
                stateKey={stateKey}
                rows={rows}
                columns={columns}
                disableRowSelectionOnClick
                hideFooter
                hideFooterPagination
                disableColumnMenu
                disableMultipleColumnsFiltering
                disableMultipleRowSelection
                disableDensitySelector
                disableColumnReorder
                cellModesModel={cellModesModel}
                onCellClick={onCellClick}
                onCellModesModelChange={onCellModesModelChange}
                onCellEditStop={onCellEditStop}
                getRowId={getRowId}
            />
        )
    }
)

MappingGrid.displayName = "MappingGrid"
