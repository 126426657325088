import { FC } from "react"

import { DocumentPayment } from "~/domains/transactions/invoices/components/Payment"
import { InvoiceI } from "~/types"

interface InvoicePaymentEditProps {
    invoice: InvoiceI
}

export const InvoicePaymentEdit: FC<InvoicePaymentEditProps> = ({ invoice }) => {
    return <DocumentPayment dataLoaded={true} invoice={invoice} />
}
