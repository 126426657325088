import { OrganizationId } from "~/types"
import { Features, flowieOrgs, isFeatureDisabled } from "~/utils/featureFlag"
import { isFeatureEnabled } from "~/utils/featureFlag"

export const experimentalOrgs = { ...flowieOrgs, "6703e91850789d0f5f3e5b9f": true } // Nicolas staging

export const getSidebarFeatureFlags = (currentOrganizationId: OrganizationId | undefined) => {
    const isMetricsEnabled = isFeatureEnabled(Features.Metrics, currentOrganizationId)
    const isInvoiceV0Enabled = isFeatureEnabled(Features.InvoiceV0, currentOrganizationId)
    const isDocumentsEnabled = isFeatureEnabled(Features.Documents, currentOrganizationId)
    const isCopilotEnabled = isFeatureEnabled(Features.ChatBot, currentOrganizationId)
    const isCustomFormsEnabled = isFeatureEnabled(Features.CustomForms, currentOrganizationId)
    const isCatalogV1Enabled = isFeatureEnabled(Features.Catalog, currentOrganizationId)
    const isCommunicationPageEnabled = isFeatureEnabled(Features.CommunicationPage, currentOrganizationId)
    const isTasksEnabled = isFeatureEnabled(Features.Tasks, currentOrganizationId)
    const isPOsEnabled = !isFeatureDisabled(Features.PurchaseOrders, currentOrganizationId)
    const isPurchaseOrdersPaginatedEnabled = isFeatureEnabled(Features.PurchaseOrdersPaginated, currentOrganizationId)

    return {
        isMetricsEnabled,
        isInvoiceV0Enabled,
        isDocumentsEnabled,
        isCopilotEnabled,
        isCustomFormsEnabled,
        isCatalogV1Enabled,
        isCommunicationPageEnabled,
        isTasksEnabled,
        isPOsEnabled,
        isPurchaseOrdersPaginatedEnabled,
    }
}
