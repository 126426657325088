/* eslint-disable complexity */
import { type Connection, type Node, NodeMouseHandler, XYPosition, useReactFlow } from "@xyflow/react"
import "@xyflow/react/dist/style.css"
import React, { FC, memo, useCallback, useEffect, useRef, useState } from "react"
import { useIntl } from "react-intl"
import Selecto, { OnDragStart } from "react-selecto"

import { FlowBuilder } from "~/domains/orchestration/flows/components/FlowBuilder"
import { FlowWrapper } from "~/domains/orchestration/flows/components/FlowWrapper"
import { SideBar } from "~/domains/orchestration/flows/components/editor/SideBar"
import { NODE_WIDTH } from "~/domains/orchestration/flows/constants"
import { useEditor, useEditorDispatch } from "~/domains/orchestration/flows/context/editorContext"
import { connectNodes } from "~/domains/orchestration/flows/core"
import { createNode } from "~/domains/orchestration/flows/core"
import { useFlowValidator } from "~/domains/orchestration/flows/hooks"
import { messages } from "~/domains/orchestration/flows/locale"
import {
    EditorNode,
    Flow,
    NodeType,
    SideBarState,
    Trigger,
    TriggerMode,
    isEntityTriggerNode,
    isEventTriggerNode,
} from "~/domains/orchestration/flows/types"
import {
    generateReadableId,
    getCurrencyConversion,
    getSideBarWidth,
    getTriggerSlug,
} from "~/domains/orchestration/flows/utils"

const nodeWithWideSideBar = [NodeType.SEND_EMAIL_NODE, NodeType.CREATE_PURCHASE_ORDER_NODE, NodeType.MAPPING_NODE]

interface Props {
    flow: Flow
    hasWorkflowUpdatePermission: boolean
}

const MemoizedFlowBuilder = memo(FlowBuilder)

export const EditorPanel: FC<Props> = ({ flow, hasWorkflowUpdatePermission }) => {
    const reactFlowWrapper = useRef(null)
    const { formatMessage } = useIntl()
    const state = useEditor()

    const { error } = state

    const [selectedNode, setSelectedNode] = useState<EditorNode>()
    const { screenToFlowPosition } = useReactFlow()
    const dispatch = useEditorDispatch()

    const [currentNodeType, setCurrentNodeType] = useState<NodeType | null>(null)

    const [sidebarState, setSidebarState] = useState(SideBarState.OPEN)

    useFlowValidator(flow)

    const updateFlow = useCallback(
        (f: Flow) => {
            dispatch({
                type: "SET_FLOW",
                payload: f,
            })
            dispatch({
                type: "PUSH_HISTORY",
                payload: f,
            })
        },
        [dispatch]
    )
    const handleUpdatePosition = useCallback(
        (node: Node) => {
            const { nodes: flowNodes } = flow
            const currentFlowNode = flowNodes.find((fn) => fn.slug === node.id)
            if (!currentFlowNode) return

            const position = {
                x: Math.floor(node.position.x),
                y: Math.floor(node.position.y),
            }

            const hasPositionChanged =
                currentFlowNode.metadata.position.x !== position.x || currentFlowNode.metadata.position.y !== position.y

            if (hasPositionChanged) {
                dispatch({
                    type: "UPDATE_POSITION",
                    payload: {
                        ...currentFlowNode,
                        metadata: { ...currentFlowNode.metadata, position },
                    },
                })
            }
        },
        [flow, dispatch]
    )

    const handleConnect = useCallback(
        (params: Connection) => {
            const updatedNodes = connectNodes({ nodes: flow.nodes, params })
            updateFlow({ ...flow, nodes: updatedNodes })
        },
        [flow, updateFlow]
    )

    const handleSelectNode = useCallback<NodeMouseHandler<Node>>(
        (_, node) => {
            const selected = flow.nodes.find((n) => n.slug === node.id)

            if (!selected) return
            if (selectedNode && selected.slug === selectedNode.slug) return

            if (nodeWithWideSideBar.includes(selected.type)) {
                setSidebarState(SideBarState.WIDE)
            } else {
                setSidebarState(SideBarState.OPEN)
            }
            setSelectedNode(selected)
        },
        [flow.nodes, selectedNode]
    )

    const handleCreateNode = useCallback(
        (nodeType: NodeType, position: XYPosition) => {
            const id = generateReadableId(nodeType, flow.nodes)

            const entityTriggerNode = flow.nodes.find(isEntityTriggerNode)
            const eventTriggerNode = flow.nodes.find(isEventTriggerNode)

            const eventTriggerEventType = eventTriggerNode?.event || null
            const entityTriggerEntityType = entityTriggerNode?.entity?.type || null

            const currencyConversion = getCurrencyConversion(flow.nodes)

            const trigger: Trigger = eventTriggerEventType
                ? {
                      type: eventTriggerEventType,
                      mode: TriggerMode.EVENT,
                      slug: getTriggerSlug(eventTriggerEventType, flow.nodes) || "",
                      currencyConversion: null,
                  }
                : {
                      type: entityTriggerEntityType,
                      mode: TriggerMode.ENTITY,
                      slug: getTriggerSlug(entityTriggerEntityType, flow.nodes) || "",
                      currencyConversion,
                  }

            const title = messages.nodeTitle[nodeType]
            const name = title ? formatMessage(title) : ""

            const node = createNode({ type: nodeType, slug: id, position, trigger, name, currencyConversion })

            // TODO: Handle the case when node is null
            if (!node) return

            updateFlow({ ...flow, nodes: [...flow.nodes, node] })
        },
        [flow, formatMessage, updateFlow]
    )

    const handleAutoLayout = useCallback(
        async (layoutedNodes: Node[]) => {
            updateFlow({
                ...flow,
                nodes: flow.nodes.map((node) => {
                    const layoutedNode = layoutedNodes.find((n) => n.id === node.slug)
                    return {
                        ...node,
                        metadata: { ...node.metadata, position: layoutedNode?.position || node.metadata.position },
                    }
                }),
            })
        },
        [flow, updateFlow]
    )

    const handleUnselectNode = () => {
        setSelectedNode(undefined)
        setSidebarState(SideBarState.OPEN)
    }

    const handleSelect = (e: OnDragStart) => {
        // This prevent select box appearing when connecting nodes
        e.preventDrag()
    }

    // Sidebar handlers

    const handleSideBarNodeClick = (nodeType: NodeType) => (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()
        setCurrentNodeType(nodeType)

        // Add delta to avoid nodes stacking on top of each other
        const centerX = window.innerWidth / 2 + Math.random() * 100
        const centerY = window.innerHeight / 2 + Math.random() * 100

        // TODO: For now node width is hardcoded, it will be dynamic if needed
        const position = screenToFlowPosition({
            x: Math.floor(centerX - NODE_WIDTH / 2),
            y: Math.floor(centerY),
        })

        handleCreateNode(nodeType, position)
    }

    const handleSideBarDragStart =
        (nodeType: NodeType) =>
        (event: React.DragEvent<HTMLDivElement>): void => {
            setCurrentNodeType(nodeType)
            event.dataTransfer.effectAllowed = "move"
        }

    const handleToggleSideBar = (newState: SideBarState) => {
        setSidebarState(newState)
    }

    useEffect(() => {
        if (!selectedNode) return

        const currentNode = flow.nodes.find((node) => node.slug === selectedNode?.slug)

        if (!currentNode) handleUnselectNode()
    }, [flow.nodes, flow, selectedNode])
    const hasError = error !== null && error.trim().length > 0

    const hasSideBar = hasWorkflowUpdatePermission

    const sideBarWidth = hasSideBar ? getSideBarWidth(sidebarState) : 0

    return (
        <>
            {reactFlowWrapper.current && (
                <Selecto
                    container={reactFlowWrapper.current}
                    dragContainer={reactFlowWrapper.current}
                    selectableTargets={[".react-flow__node"]}
                    boundContainer={reactFlowWrapper.current}
                    continueSelect={false}
                    keyContainer={reactFlowWrapper.current}
                    hitRate={100}
                    onDragStart={handleSelect}
                />
            )}
            <FlowWrapper ref={reactFlowWrapper} $sidebarWidth={sideBarWidth}>
                <MemoizedFlowBuilder
                    flow={flow}
                    currentNodeType={currentNodeType}
                    onConnect={handleConnect}
                    onNodeClick={handleSelectNode}
                    onPaneClick={handleUnselectNode}
                    hasError={hasError}
                    error={error}
                    onCreateNode={handleCreateNode}
                    onAutoLayout={handleAutoLayout}
                    onUpdatePosition={handleUpdatePosition}
                />
            </FlowWrapper>
            {hasSideBar && (
                <SideBar
                    sidebarState={sidebarState}
                    selectedNode={selectedNode}
                    onDragStart={handleSideBarDragStart}
                    onNodeClick={handleSideBarNodeClick}
                    onUnselectNode={handleUnselectNode}
                    onToggleSideBar={handleToggleSideBar}
                />
            )}
        </>
    )
}
