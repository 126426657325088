import { Stack } from "@mui/material"
import { AlertTriangle } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { commonMessages } from "~/common-messages"
import { Card, SafeFormattedMessage } from "~/components"
import { useAddObjectToBatchMutation } from "~/domains/payment/payment-batches/api/paymentBatchesApi"
import { ObjectAssignForm, ObjectAssignInfo } from "~/domains/payment/payment-batches/components"
import { AssignedObject } from "~/domains/payment/payment-batches/types"

const messages = defineMessages({
    paymentBatch: {
        id: "payment.batches.paymentBatch",
        defaultMessage: "Payment batch",
    },
    addToBatchSuccess: {
        id: "payment.batches.addToBatchSuccess",
        defaultMessage: "Object added to batch successfully",
    },
    batchSelectionWarning: {
        id: "payment.batches.batchSelectionWarning",
        defaultMessage:
            "Adding this payment to a batch will automatically override the currently selected payment method.",
    },
})

interface ObjectAssignPickerProps {
    assignedObject: AssignedObject | null
    isPaid: boolean
    payload: AssignedObject
    onAssignDone?: () => void
}

export const ObjectAssignPicker = ({ assignedObject, isPaid, payload, onAssignDone }: ObjectAssignPickerProps) => {
    const { formatMessage } = useIntl()

    const [addToBatch, { isLoading: isAddingToBatch }] = useAddObjectToBatchMutation()

    const handleAddToBatch = async (batchId: string) => {
        const newObject = await addToBatch({
            assignedObject: payload,
            batchId,
        }).unwrap()

        if (newObject?.id) {
            onAssignDone?.()
            toast.success(formatMessage(messages.addToBatchSuccess))
        }
    }

    return assignedObject ? (
        <Card title={formatMessage(commonMessages.batch)} expandable>
            <ObjectAssignInfo mode="block" assignedObject={assignedObject} />
        </Card>
    ) : (
        !isPaid && (
            <Card title={formatMessage(commonMessages.batch)}>
                <Stack gap={2}>
                    <ObjectAssignForm loading={isAddingToBatch} onSubmit={handleAddToBatch} />
                    <Stack direction="row" gap={1}>
                        <AlertTriangle color="var(--color-yellow)" size={20} />
                        <SafeFormattedMessage {...messages.batchSelectionWarning} />
                    </Stack>
                </Stack>
            </Card>
        )
    )
}
