import { Menu, MenuItem } from "@mui/material"
import React, { ReactNode, useState } from "react"
import { useIntl } from "react-intl"

import { ButtonType, SafeFormattedMessage, TooltipConditional } from "~/components"
import { ActionsMenuButton } from "~/components/ActionsMenu/ActionsMenuButton"
import { permissionMessages } from "~/domains/identity/roles-permissions/utils/permissions"
import { stopPropagationAndPreventDefault } from "~/utils"

export type Actions<T> = {
    label?: ReactNode
    action?: ((dataType: T) => void) | (() => void)
    icon?: React.ReactNode
    render?: React.ReactNode
    disabled?: boolean
}

type ActionsMenuProps<T> = {
    actions: Actions<T>[]
    dataType?: T
    labelButton?: string
    disabled?: boolean
    buttonType?: ButtonType
    icon?: React.ReactNode
    isActionIcon?: boolean
    size?: "small" | "medium"
    splitButton?: boolean
    primaryAction?: () => void
}

export const ActionsMenu = <T,>({
    dataType,
    actions,
    labelButton,
    disabled,
    icon,
    buttonType = "grey",
    isActionIcon = true,
    size = "medium",
    splitButton = false,
    primaryAction,
}: ActionsMenuProps<T>) => {
    const { formatMessage } = useIntl()
    const [anchorEl, setAnchorEl] = useState<EventTarget & HTMLButtonElement & MouseEvent>()
    const open = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement & MouseEvent>) => {
        if (splitButton) {
            // for split button just call the primaryAction
            if (primaryAction) {
                primaryAction()
            }
        } else {
            stopPropagationAndPreventDefault(event)
            setAnchorEl(event.currentTarget)
        }
    }

    const handleSplitButtonClick = (event: React.MouseEvent<HTMLButtonElement & MouseEvent>) => {
        stopPropagationAndPreventDefault(event)
        setAnchorEl(event.currentTarget)
    }

    const handleClose = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        stopPropagationAndPreventDefault(event)
        setAnchorEl(undefined)
    }

    const handleDataAction = (
        actionExec: (dt: T) => void,
        dt: T,
        event: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => {
        actionExec(dt)
        handleClose(event)
    }

    const handleNoDataAction = (actionExec: () => void, event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        actionExec()
        handleClose(event)
    }

    const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, item: Actions<T>) => {
        stopPropagationAndPreventDefault(event)
        if (item.disabled) return

        return dataType
            ? handleDataAction(item?.action as () => void, dataType, event)
            : handleNoDataAction(item?.action as () => void, event)
    }

    if (!actions.length) return null

    return (
        <>
            <TooltipConditional
                title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                condition={!!disabled}
                placement="top"
                arrow
            >
                <div>
                    <ActionsMenuButton
                        handleClick={handleClick}
                        disabled={disabled}
                        labelButton={labelButton}
                        buttonType={buttonType}
                        icon={icon}
                        isActionIcon={isActionIcon}
                        size={size}
                        splitButton={splitButton}
                        splitButtonOnClick={handleSplitButtonClick}
                    />
                </div>
            </TooltipConditional>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className="menu-actions"
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 4px rgba(0,0,0,0.16))",
                            mt: 1.5,
                            "&::before": {
                                content: '""',
                                display: "block",
                                position: "absolute",
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: "background.paper",
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0,
                            },
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                {actions.map((action, key) =>
                    action.render ? (
                        <MenuItem key={key} disabled={action.disabled}>
                            {action.render}
                        </MenuItem>
                    ) : (
                        <TooltipConditional
                            key={key}
                            title={<SafeFormattedMessage {...permissionMessages.errorNoAccessAdministrator} />}
                            condition={!!action.disabled}
                            placement="left"
                            arrow
                        >
                            <div className="stop-propagation">
                                <MenuItem
                                    onClick={(event) => handleMenuItemClick(event, action)}
                                    disabled={action.disabled}
                                >
                                    {action.icon}
                                    <span className="menu-items-grey">{action.label}</span>
                                </MenuItem>
                            </div>
                        </TooltipConditional>
                    )
                )}
            </Menu>
        </>
    )
}
