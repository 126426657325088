import React, { useMemo } from "react"
import { useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { Button, Modal, Size } from "~/components"
import { CompanyResult } from "~/domains/identity/organization/types/CompanyAutocomplete"
import { OrganizationAddressI } from "~/types"

import { ModalCompanyDetails } from "./ModalCompanyDetails"
import { ModalOrganizationDetails } from "./ModalOrganizationDetails"
import { SavePayloadType } from "./types"

interface Props {
    open: boolean
    onClose: () => void
    onSave?: (payload: SavePayloadType) => void
    company: CompanyResult | undefined
    withOrganizationAddress?: boolean
    withSendingAddress?: boolean
    shippingAddress?: OrganizationAddressI
    readOnly?: boolean
}

export const ModalOrganizationSelectorDetails: React.FC<Props> = ({
    open,
    onClose,
    onSave,
    company,
    shippingAddress,
    withOrganizationAddress,
    withSendingAddress,
    readOnly,
}) => {
    const { formatMessage } = useIntl()

    const formId = useMemo(() => `form_modal_organization_${Math.round(Math.random() * 100000)}`, [])

    const isReadOnly = readOnly || !onSave

    return (
        <Modal open={open && (company?.type === "org" || company?.type === "wp")} onClose={onClose} size={Size.XL}>
            <Modal.Content>
                {company?.type === "org" && (
                    <ModalOrganizationDetails
                        id={formId}
                        registrationInfo={company.value.registration}
                        companyAddress={company.value.address ?? null}
                        sendingAddress={shippingAddress ?? company.value.address}
                        withOrganizationAddress={withOrganizationAddress}
                        withSendingAddress={withSendingAddress}
                        onSave={onSave}
                        readOnly={isReadOnly}
                        organizationId={company.value.id}
                    />
                )}
                {company?.type === "wp" && (
                    <ModalCompanyDetails
                        id={formId}
                        company={company.value}
                        withOrganizationAddress={withOrganizationAddress}
                        withSendingAddress={withSendingAddress}
                        onSave={onSave}
                        readOnly={isReadOnly}
                    />
                )}
            </Modal.Content>
            <Modal.Footer>
                <Button type="transparent" onClick={onClose}>
                    {formatMessage(onSave ? commonMessages.cancel : commonMessages.close)}
                </Button>
                {!isReadOnly && (
                    <Button type="primary" buttonType="submit" form={formId}>
                        {formatMessage(commonMessages.save)}
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    )
}
