/* eslint-disable max-lines */
/* eslint-disable no-shadow */
import { XYPosition } from "@xyflow/react"

import { Opaque } from "~/utils"

export type ApiFlowId = Opaque<string, { readonly T: unique symbol }>

export enum ApiFulfillmentStatus {
    IN_PREPARATION = "InPreparation",
    OUT_FOR_DELIVERY = "OutForDelivery",
    DELIVERED = "Delivered",
    CANCELED = "Canceled",
}

type ApiFilter = {
    conditions: Array<Array<string>>
}

export type ApiBranch = {
    name: string
    conditions: Array<Array<string>>
    nextNode?: string
}

export type ApiNode = {
    name?: string | null
    slug: string
    metadata: {
        position: XYPosition
        additionalInformation?: string
        group?: {
            id: string
            name: string
        }
    }
}

export enum ApiPoStatus {
    DRAFT = "Draft",
    SUBMITTED = "Submitted",
    OPEN = "Open",
    CLOSED = "Closed",
}

export enum ApiUserType {
    USER = "User",
    TEAM = "Team",
}

export type ApiUserReviewer = {
    userId: string
    type: ApiUserType.USER
}

export type ApiTeamReviewer = {
    teamId: string
    type: ApiUserType.TEAM
}

export type ApiReviewer = ApiUserReviewer | ApiTeamReviewer

export type ApiEventTriggerNode = ApiNode & {
    event: ApiEvent
    filter?: ApiFilter
    type: ApiNodeType.EVENT_TRIGGER_NODE
    nextNode?: string
}

export type ApiSetPartnershipFieldNode = ApiNode & {
    partnershipId: string
    fieldToUpdate: string
    valueToSet: string
    type: ApiNodeType.SET_PARTNERSHIP_FIELD_NODE
    nextNode?: string
}

export type ApiSetPaymentMethodDetailsFieldNode = ApiNode & {
    paymentMethodDetailsId: string
    fieldToUpdate: string
    valueToSet: string
    type: ApiNodeType.SET_PAYMENT_METHOD_DETAILS_FIELD_NODE
    nextNode?: string
}

export type ApiEmailRespondent = {
    type: "EmailRespondent"
    emailAddress: string
}

export type ApiUserRespondent = {
    type: "UserRespondent"
    userId: string
}

export type ApiSurveyRespondent = ApiEmailRespondent | ApiUserRespondent

export type ApiContextEntry = {
    key: string // Expression
    value: string // Expression
}

export type ApiCreateSurveyNode = ApiNode & {
    formId: string
    respondentOrganizationId?: string
    sendNotifications?: boolean
    respondents?: ApiSurveyRespondent[]
    context?: ApiContextEntry[]
    type: ApiNodeType.CREATE_SURVEY_NODE
    nextNode?: string
}

export type ApiRetractReviewsNode = ApiNode & {
    objectId: string
    objectType: ApiObjectType
    nextNode?: string
    type: ApiNodeType.RETRACT_REVIEWS_NODE
}

export type ApiIfNode = ApiNode & {
    slug: string
    condition: string
    nextIfTrue?: string
    nextIfFalse?: string
    type: ApiNodeType.IF_NODE
}

export type ApiCheckNode = ApiNode & {
    objectId: string
    objectType: ApiObjectType
    reviewers?: ApiReviewer[]
    passThreshold: number
    refuseThreshold: number
    nextIfPassed?: string
    nextIfRefused?: string
    type: ApiNodeType.CHECK_NODE
}

export type ApiSendEmailNode = ApiNode & {
    subject: string
    recipientAddresses: string[]
    body: string
    type: ApiNodeType.SEND_EMAIL_NODE
    nextNode?: string
}

export type ApiAddToBudgetNode = ApiNode & {
    budgetId: string
    transactionId: string
    transactionType: string
    amount: string
    failIfOverbudget: boolean
    type: ApiNodeType.ADD_TO_BUDGET_NODE
    currency?: string
    nextNode?: string
}

export type ApiInvoiceToPurchaseOrderMatchNode = ApiNode & {
    invoiceId: string
    type: ApiNodeType.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE
    nextIfMatched?: string
    nextIfNotMatched?: string
}

export type ApiSetInvoiceLifecycleStatusNode = ApiNode & {
    invoiceId: string
    statusToSet: string
    type: ApiNodeType.SET_INVOICE_LIFECYCLE_STATUS_NODE
    nextNode?: string
}

export type ApiFitsToBudgetNode = ApiNode & {
    budgetId: string
    transactionId: string
    transactionType: string
    amount: string
    currency: string
    type: ApiNodeType.FITS_TO_BUDGET_NODE
    nextIfFits?: string
    nextIfDoesNotFit?: string
}

export type ApiAssignTagNode = ApiNode & {
    objectId: string
    objectType: ApiObjectType
    tagId: string
    type: ApiNodeType.ASSIGN_TAG_NODE
    nextNode?: string
}

export type ApiUpdateTripletexLedgerNode = ApiNode & {
    ledgerId: string
    ledgerDate?: string | null
    ledgerDescription?: string | null
    accountToCredit: string
    accountToDebit: string
    amount: string
    amountGross: string
    currency: string
    type: ApiNodeType.UPDATE_TRIPLETEX_LEDGER_NODE
    nextNode?: string
}

export type ApiApprovePurchaseOrderNode = ApiNode & {
    purchaseOrderId: string
    type: ApiNodeType.APPROVE_PURCHASE_ORDER_NODE
    nextNode?: string
}

export type ApiApprovePurchaseOrderLineNode = ApiNode & {
    purchaseOrderLineId: string
    purchaseOrderId: string
    type: ApiNodeType.APPROVE_PURCHASE_ORDER_LINE_NODE
    nextNode?: string
}

export type ApiConvertPrToPoNode = ApiNode & {
    purchaseRequestId: string
    type: ApiNodeType.CONVERT_PR_TO_PO_NODE
    nextNode?: string
}

export type ApiApprovePurchaseRequestNode = ApiNode & {
    purchaseRequestId: string
    type: ApiNodeType.APPROVE_PURCHASE_REQUEST_NODE
    nextNode?: string
}

export type ApiApprovePurchaseRequestLineNode = ApiNode & {
    purchaseRequestId: string
    purchaseRequestLineId: string
    type: ApiNodeType.APPROVE_PURCHASE_REQUEST_LINE_NODE
    nextNode?: string
}

export type ApiBranchNode = ApiNode & {
    type: ApiNodeType.BRANCH_NODE
    nextNode?: string
    branches: ApiBranch[]
    default: string
}

export type ApiFetchCustomFieldsNode = ApiNode & {
    objectId: string
    type: ApiNodeType.FETCH_CUSTOM_FIELDS_NODE
    nextNode?: string
}

export type ApiMappingNodeElement = {
    label: string
    value: string
}

export type ApiMappingNode = ApiNode & {
    valueToMap: string
    mappingTable: Record<string, ApiMappingNodeElement[]>
    defaultValues: ApiMappingNodeElement[]
    type: ApiNodeType.MAPPING_NODE
    nextNode?: string
}

export type ApiGetTagByGroupNode = ApiNode & {
    objectId: string
    tagGroupId: string
    type: ApiNodeType.GET_TAG_BY_GROUP_NODE
    nextNode?: string
}

export type ApiUpdateCustomFieldNode = ApiNode & {
    customFieldId: string
    value: string
    type: ApiNodeType.UPDATE_CUSTOM_FIELD_NODE
    nextNode?: string
}

export enum ApiTaskPriority {
    LOW = "Low",
    MEDIUM = "Medium",
    HIGH = "High",
    URGENT = "Urgent",
}

export enum ApiTaskStatus {
    PENDING = "Pending",
    IN_PROGRESS = "InProgress",
    COMPLETED = "Completed",
    REJECTED = "Rejected",
}

export type ApiCreateTaskNode = ApiNode & {
    title?: string
    description?: string
    dueDate?: string
    priority?: ApiTaskPriority
    status?: ApiTaskStatus
    assignee?: string
    parentTaskId?: string
    public?: string
    followers?: string[]
    parties?: string[]
    type: ApiNodeType.CREATE_TASK_NODE
    nextNode?: string
}

export type ApiFetchPartnershipNode = ApiNode & {
    partnerId: string
    type: ApiNodeType.FETCH_PARTNERSHIP_NODE
    nextNode?: string
}

export type ApiCreateCustomFieldNode = ApiNode & {
    objectId: string
    customFieldName: string
    customFieldValue?: string
    type: ApiNodeType.CREATE_CUSTOM_FIELD_NODE
    nextNode?: string
}

export type ApiSetPoFulfillmentStatusNode = ApiNode & {
    purchaseOrderId: string
    statusToSet: ApiFulfillmentStatus
    type: ApiNodeType.SET_PO_FULFILLMENT_STATUS_NODE
    nextNode?: string
}

export type ApiSuspendUntilSurveyCompletedNode = ApiNode & {
    surveyId: string
    type: ApiNodeType.SUSPEND_UNTIL_SURVEY_COMPLETED_NODE
    nextNode?: string
}

export type ApiSetPoStatusNode = ApiNode & {
    purchaseOrderId: string
    statusToSet: ApiPoStatus
    type: ApiNodeType.SET_PO_STATUS_NODE
    nextNode?: string
}

export type ApiRefusePurchaseRequestNode = ApiNode & {
    purchaseRequestId: string
    type: ApiNodeType.REFUSE_PURCHASE_REQUEST_NODE
    nextNode?: string
}

export type ApiRefusePurchaseOrderNode = ApiNode & {
    purchaseOrderId: string
    type: ApiNodeType.REFUSE_PURCHASE_ORDER_NODE
    nextNode?: string
}

export type ApiMoney = {
    amount: string
    currency: string
}

export type ApiPurchaseOrderLine = {
    description: string
    quantity: string
    units?: string
    unitPrice: ApiMoney
    unitPriceExcludingTax: ApiMoney
    taxRate: string
    buyerItemId?: string
    supplierItemId?: string
}

export type ApiAddress = {
    street: string
    street2?: string
    city: string
    zipCode: string
    country: string
}

export enum ApiPoCreationStatus {
    DRAFT = "Draft",
    SUBMITTED = "Submitted",
}

export type ApiCreatePurchaseOrderNode = ApiNode & {
    buyerId: string
    supplierId: string
    description: string
    lines: ApiPurchaseOrderLine[]
    status: ApiPoCreationStatus
    supplierEmail?: string
    billingAddress?: ApiAddress
    shippingAddress?: ApiAddress
    shortId?: string
    expectedDeliveryDate?: string
    type: ApiNodeType.CREATE_PURCHASE_ORDER_NODE
    nextNode?: string
}

export type ApiAssignDocumentToObjectNode = ApiNode & {
    documentId: string
    objectId: string
    type: ApiNodeType.ASSIGN_DOCUMENT_TO_OBJECT_NODE
    nextNode?: string
}

export type ApiCopyTagsNode = ApiNode & {
    sourceObjectId: string
    targetObjectId: string
    type: ApiNodeType.COPY_TAGS_NODE
    nextNode?: string
}

export type ApiCopyLinkedDocumentsNode = ApiNode & {
    sourceObjectId: string
    targetObjectId: string
    type: ApiNodeType.COPY_LINKED_DOCUMENTS_NODE
    nextNode?: string
}

export enum ApiStartMode {
    MANUAL = "Manual",
    ON_ENTITY_CREATED = "OnEntityCreated",
}

export enum ApiRestartMode {
    MANUAL = "Manual",
    ON_ENTITY_UPDATED = "OnEntityUpdated",
    ON_ENTITY_UPDATED_IF_SUSPENDED = "OnEntityUpdatedIfSuspended",
}

export enum ApiEntityType {
    PARTNERSHIP = "Partnership",
    PURCHASE_ORDER = "PurchaseOrder",
    PURCHASE_REQUEST = "PurchaseRequest",
}

export type ApiEntityStartMode = {
    type: ApiStartMode
}

export type ApiEntityRestartMode = {
    type: ApiRestartMode
    continueFromSuspendedNode?: boolean
}

export type ApiPartnershipEntity = {
    type: ApiEntityType.PARTNERSHIP
    startMode: ApiEntityStartMode
    restartMode: ApiEntityRestartMode
}

export type ApiPurchaseOrderEntity = {
    type: ApiEntityType.PURCHASE_ORDER
    startMode: ApiEntityStartMode
    restartMode: ApiEntityRestartMode
    currencyConversion: ApiCurrencyConversion
}

export type ApiPurchaseRequestEntity = {
    type: ApiEntityType.PURCHASE_REQUEST
    startMode: ApiEntityStartMode
    restartMode: ApiEntityRestartMode
    currencyConversion: ApiCurrencyConversion
}

export type EnabledCurrencyConversion = {
    type: ApiCurrencyConversionMode.ENABLED
    targetCurrency: string
}

export type DisabledCurrencyConversion = {
    type: ApiCurrencyConversionMode.DISABLED
}

export enum ApiCurrencyConversionMode {
    ENABLED = "Enabled",
    DISABLED = "Disabled",
}

export type ApiCurrencyConversion = EnabledCurrencyConversion | DisabledCurrencyConversion

export type ApiEntity = ApiPartnershipEntity | ApiPurchaseOrderEntity | ApiPurchaseRequestEntity

export type ApiEntityTriggerNode = ApiNode & {
    entity: ApiEntity
    filter?: ApiFilter
    type: ApiNodeType.ENTITY_TRIGGER_NODE
    nextNode?: string
}

export type ApiApproveErpInvoiceNode = ApiNode & {
    invoiceId: string
    type: ApiNodeType.APPROVE_ERP_INVOICE_NODE
    nextNode?: string
}

export type ApiFlows = {
    flows: ApiFlowItem[]
}

export type ApiFlowItem = {
    id: ApiFlowId
    version: number
    name: string
    enabled: boolean
    archived: boolean
    createdAt: string
}

export type ApiFlowNode =
    | ApiEventTriggerNode
    | ApiIfNode
    | ApiSetPartnershipFieldNode
    | ApiCheckNode
    | ApiSendEmailNode
    | ApiAddToBudgetNode
    | ApiInvoiceToPurchaseOrderMatchNode
    | ApiSetInvoiceLifecycleStatusNode
    | ApiFitsToBudgetNode
    | ApiAssignTagNode
    | ApiUpdateTripletexLedgerNode
    | ApiApprovePurchaseOrderNode
    | ApiApprovePurchaseOrderLineNode
    | ApiConvertPrToPoNode
    | ApiApprovePurchaseRequestNode
    | ApiApprovePurchaseRequestLineNode
    | ApiBranchNode
    | ApiSetPaymentMethodDetailsFieldNode
    | ApiCreateSurveyNode
    | ApiRetractReviewsNode
    | ApiFetchCustomFieldsNode
    | ApiMappingNode
    | ApiGetTagByGroupNode
    | ApiUpdateCustomFieldNode
    | ApiCreateTaskNode
    | ApiFetchPartnershipNode
    | ApiCreateCustomFieldNode
    | ApiSetPoFulfillmentStatusNode
    | ApiSuspendUntilSurveyCompletedNode
    | ApiSetPoStatusNode
    | ApiRefusePurchaseRequestNode
    | ApiRefusePurchaseOrderNode
    | ApiCreatePurchaseOrderNode
    | ApiAssignDocumentToObjectNode
    | ApiCopyTagsNode
    | ApiCopyLinkedDocumentsNode
    | ApiEntityTriggerNode
    | ApiApproveErpInvoiceNode

export type ApiFlow = ApiFlowItem & {
    nodes: ApiFlowNode[]
    author: string
}

export type ApiFlowUpdate = Omit<ApiFlow, "id" | "author" | "createdAt">
export type ApiNewFlow = Omit<ApiFlowUpdate, "version" | "createdAt" | "archived">

export enum ApiNodeType {
    EVENT_TRIGGER_NODE = "EventTriggerNodeV2",
    SET_PARTNERSHIP_FIELD_NODE = "SetPartnershipFieldNode",
    SET_PAYMENT_METHOD_DETAILS_FIELD_NODE = "SetPaymentMethodDetailsFieldNode",
    IF_NODE = "IfNode",
    CHECK_NODE = "CheckNode",
    SEND_EMAIL_NODE = "SendEmailNode",
    ADD_TO_BUDGET_NODE = "AddToBudgetNode",
    INVOICE_TO_PURCHASE_ORDER_MATCH_NODE = "InvoiceToPurchaseOrderMatchNode",
    SET_INVOICE_LIFECYCLE_STATUS_NODE = "SetInvoiceLifecycleStatusNode",
    FITS_TO_BUDGET_NODE = "FitsToBudgetNode",
    ASSIGN_TAG_NODE = "AssignTagNode",
    UPDATE_TRIPLETEX_LEDGER_NODE = "UpdateTripletexLedgerNode",
    APPROVE_PURCHASE_ORDER_NODE = "ApprovePurchaseOrderNode",
    APPROVE_PURCHASE_ORDER_LINE_NODE = "ApprovePurchaseOrderLineNode",
    CONVERT_PR_TO_PO_NODE = "ConvertPrToPoNode",
    APPROVE_PURCHASE_REQUEST_NODE = "ApprovePurchaseRequestNode",
    APPROVE_PURCHASE_REQUEST_LINE_NODE = "ApprovePurchaseRequestLineNode",
    BRANCH_NODE = "BranchNode",
    CREATE_SURVEY_NODE = "CreateSurveyNode",
    RETRACT_REVIEWS_NODE = "RetractReviewsNode",
    FETCH_CUSTOM_FIELDS_NODE = "FetchCustomFieldsNode",
    MAPPING_NODE = "MappingNode",
    GET_TAG_BY_GROUP_NODE = "GetTagByGroupNode",
    UPDATE_CUSTOM_FIELD_NODE = "UpdateCustomFieldNode",
    CREATE_TASK_NODE = "CreateTaskNode",
    FETCH_PARTNERSHIP_NODE = "FetchPartnershipNode",
    CREATE_CUSTOM_FIELD_NODE = "CreateCustomFieldNode",
    SET_PO_FULFILLMENT_STATUS_NODE = "SetPoFulfillmentStatusNode",
    SUSPEND_UNTIL_SURVEY_COMPLETED_NODE = "SuspendUntilSurveyCompletedNode",
    SET_PO_STATUS_NODE = "SetPoStatusNode",
    REFUSE_PURCHASE_REQUEST_NODE = "RefusePurchaseRequestNode",
    REFUSE_PURCHASE_ORDER_NODE = "RefusePurchaseOrderNode",
    CREATE_PURCHASE_ORDER_NODE = "CreatePurchaseOrderNode",
    ASSIGN_DOCUMENT_TO_OBJECT_NODE = "AssignDocumentToObjectNode",
    COPY_TAGS_NODE = "CopyTagsNode",
    COPY_LINKED_DOCUMENTS_NODE = "CopyLinkedDocumentsNode",
    ENTITY_TRIGGER_NODE = "EntityTriggerNode",
    APPROVE_ERP_INVOICE_NODE = "ApproveErpInvoiceNode",
}

export enum ApiObjectType {
    ORGANIZATION_RELATIONSHIP = "OrganizationRelationship",
    INVOICE = "Invoice",
    BUDGET = "Budget",
    PURCHASE_ORDER = "PurchaseOrder",
    PURCHASE_ORDER_LINE = "PurchaseOrderLine",
    PURCHASE_REQUEST = "PurchaseRequest",
    PAYMENT_METHOD_DETAILS = "PaymentMethodDetails",
    PARTNER_PAYMENT_METHOD_DETAILS = "PartnerPaymentMethodDetails",
    // TODO: add these once we have the API for them
    // CONTACT = "Contact",
    // PAYMENT = "Payment",
}

export enum ApiEvent {
    PARTNERSHIP_CREATED = "PartnershipCreated",
    BUDGET_CREATED = "BudgetCreated",
    BUDGET_UPDATED = "BudgetUpdated",
    INVOICE_CREATED = "InvoiceCreated",
    INVOICE_UPDATED = "InvoiceUpdated",
    PURCHASE_REQUEST_CREATED = "PurchaseRequestCreated",
    PURCHASE_REQUEST_UPDATED = "PurchaseRequestUpdated",
    PURCHASE_REQUEST_LINE_CREATED = "PurchaseRequestLineCreated",
    PURCHASE_REQUEST_APPROVED = "PurchaseRequestApproved",
    INVOICE_TO_PURCHASE_ORDER_LINKED = "InvoiceToPurchaseOrderLinked",
    SUPPLIER_PURCHASE_ORDER_CREATED = "SupplierPurchaseOrderCreated",
    SUPPLIER_PURCHASE_ORDER_LINE_CREATED = "SupplierPurchaseOrderLineCreated",
    SUPPLIER_PURCHASE_ORDER_SENT = "SupplierPurchaseOrderSent",
    SUPPLIER_PURCHASE_ORDER_APPROVED = "SupplierPurchaseOrderApproved",
    BUYER_PURCHASE_ORDER_CREATED = "BuyerPurchaseOrderCreated",
    BUYER_PURCHASE_ORDER_LINE_CREATED = "BuyerPurchaseOrderLineCreated",
    BUYER_PURCHASE_ORDER_SENT = "BuyerPurchaseOrderSent",
    PURCHASE_ORDER_INTERNALLY_APPROVED = "PurchaseOrderInternallyApproved",
    PAYMENT_METHOD_DETAILS_CREATED = "PaymentMethodDetailsCreated",
    PARTNER_PAYMENT_METHOD_DETAILS_CREATED = "PartnerPaymentMethodDetailsCreated",
    SURVEY_COMPLETED = "SurveyCompleted",
}

// type guard to know if node is ApiFlowNode
export const isApiFlowNode = (node: unknown): node is ApiFlowNode => {
    return node !== null && typeof node === "object" && "slug" in node && "type" in node
}
