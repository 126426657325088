import { IconButton, Pagination, Stack, Typography } from "@mui/material"
import { ChangeEvent, FC, useState } from "react"
import { ChevronsLeft, ChevronsRight } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { Button, Modal, Size } from "~/components"
import { CheckItemProps } from "~/domains/identity/account/components/AknowledgePurchasePolicyWall/AknowledgeCheckItem"
import { AknowledgeCheckItemWithSlide } from "~/domains/identity/account/components/AknowledgePurchasePolicyWall/AknowledgeCheckItemWithSlide"

const messages = defineMessages({
    title: {
        id: "identity.account.acknowledgePurchasePolicyWall.stepThreeTitle",
        defaultMessage: "Acknowledgement of the purchase policy summary",
    },
})

interface Props {
    onConfirm: (confirmDate: Date) => void
}

const checkItems: CheckItemProps[] = [
    {
        primary: "Respect des Processus S2C et P2P",
        secondary: (
            <>
                <Typography component="div">
                    Suivre rigoureusement les deux sous-processus :<br />
                    - Source-to-Contract (S2C) pour la sélection des fournisseurs, la négociation et la
                    contractualisation.
                    <br />
                    - Procure-to-Pay (P2P) pour la gestion des commandes, des factures et des paiements.
                    <br />
                    Utiliser les outils et modèles standardisés (bons de commande, contrats types, grilles
                    d’évaluation).
                </Typography>
            </>
        ),
    },
    {
        primary: "Validation Budgétaire Obligatoire",
        secondary: (
            <>
                <Typography component="div">
                    Aucune dépense ne doit être engagée sans validation budgétaire préalable.
                </Typography>
                <Typography component="div">
                    Respecter les circuits d&apos;approbation définis selon les seuils financiers.
                </Typography>
            </>
        ),
    },
    {
        primary: "Mise en Concurrence Systématique",
        secondary: (
            <>
                <Typography component="div">
                    Consulter plusieurs fournisseurs, en respectant les seuils financiers.
                </Typography>
                <Typography component="div">
                    Justifier toute exception à la mise en concurrence avec un dossier formel validé.
                </Typography>
            </>
        ),
    },
    {
        primary: "Contrôle des Fournisseurs (KYS)",
        secondary: (
            <>
                <Typography component="div">
                    Réaliser des contrôles de Know Your Supplier (KYS) allégés ou renforcés selon la criticité du
                    fournisseur.
                </Typography>
                <Typography component="div">
                    Vérifier la conformité légale, la situation financière et les risques éthiques des fournisseurs
                    avant la contractualisation.
                </Typography>
            </>
        ),
    },
    {
        primary: "Traçabilité et Archivage des Données",
        secondary: (
            <>
                <Typography component="div">
                    Documenter chaque étape du processus d’achat (de la demande à la facturation).
                </Typography>
                <Typography component="div">
                    Conserver les preuves des décisions, validations et communications pour les audits internes et
                    externes.
                </Typography>
            </>
        ),
    },
    {
        primary: "Gestion des Risques Fournisseurs",
        secondary: (
            <>
                <Typography component="div">
                    Identifier les fournisseurs critiques et mettre en place un suivi de performance avec des KPI
                    adaptés.
                </Typography>
                <Typography component="div">
                    Prévoir des plans de continuité d’activité pour les fournisseurs stratégiques.
                </Typography>
            </>
        ),
    },
    {
        primary: "Conformité RSE et Prévention du Travail Dissimulé",
        secondary: (
            <>
                <Typography component="div">
                    S’assurer que les fournisseurs respectent les normes sociales, environnementales et éthiques.
                </Typography>
                <Typography component="div">
                    Contrôler les documents relatifs à la régularité sociale et fiscale des prestataires.
                </Typography>
            </>
        ),
    },
    {
        primary: "Sécurité des Informations et Confidentialité",
        secondary: (
            <>
                <Typography component="div">
                    Protéger les données sensibles en utilisant des clauses de confidentialité dans les contrats.
                </Typography>
                <Typography component="div">
                    Restreindre l’accès aux informations d’achat aux personnes autorisées uniquement.
                </Typography>
            </>
        ),
    },
    {
        primary: "Signalement des Incidents et Gestion des Conflits",
        secondary: (
            <>
                <Typography component="div">
                    Déclarer tout incident opérationnel ou éthique (fraude, non-conformité, conflit d’intérêts) via les
                    canaux internes prévus.
                </Typography>
                <Typography component="div">
                    Utiliser le réseau des référents anticorruption en cas de suspicion de pratiques douteuses.
                </Typography>
            </>
        ),
    },
    {
        primary: "Formation Continue et Sensibilisation",
        secondary: (
            <>
                <Typography component="div">
                    Participer aux formations sur les procédures d’achat, la conformité réglementaire et la lutte contre
                    la corruption.
                </Typography>
                <Typography component="div">
                    Se tenir informé des mises à jour de la politique d’achat et des nouvelles procédures.
                </Typography>
            </>
        ),
    },
]

export const AknowledgePurchasePolicySummaryModal: FC<Props> = ({ onConfirm }) => {
    const { formatMessage } = useIntl()
    const [currentItem, setCurrentItem] = useState(0)
    const [direction, setDirection] = useState<"left" | "right">("left")
    const [allItemsSeen, setAllItemsSeen] = useState(false)

    const onPrevious = () => {
        if (currentItem > 0) {
            setCurrentItem(currentItem - 1)
            setDirection("right")
        }
    }
    const onNext = () => {
        if (currentItem < checkItems.length - 1) {
            if (currentItem === checkItems.length - 2) {
                setAllItemsSeen(true)
            }
            setCurrentItem(currentItem + 1)
            setDirection("left")
        }
    }
    const onPaginationChange = (_event: ChangeEvent<unknown>, page: number) => {
        if (page === checkItems.length) {
            setAllItemsSeen(true)
        }
        setDirection(page - 1 < currentItem ? "right" : "left")
        setCurrentItem(page - 1)
    }
    return (
        <Modal open size={Size.FULLSCREEN}>
            <Modal.Header>
                <Typography variant="h4" gutterBottom>
                    {formatMessage(messages.title)}
                </Typography>
            </Modal.Header>
            <Modal.Content>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    height="100%"
                    maxHeight="calc(100vh - 350px)"
                    maxWidth="100%"
                    width="100%"
                >
                    <IconButton color="primary" onClick={onPrevious} disabled={currentItem === 0}>
                        <ChevronsLeft />
                    </IconButton>
                    <Stack
                        direction="row"
                        alignItems="center"
                        height="100vh"
                        maxHeight="100%"
                        maxWidth="calc(100% - 80px)"
                    >
                        {checkItems.map((props, i) => (
                            <AknowledgeCheckItemWithSlide
                                key={i}
                                {...props}
                                visible={currentItem === i}
                                direction={direction}
                            />
                        ))}
                    </Stack>
                    <IconButton color="primary" onClick={onNext} disabled={currentItem === checkItems.length - 1}>
                        <ChevronsRight />
                    </IconButton>
                </Stack>
            </Modal.Content>
            <Modal.Footer>
                <Stack direction="row" gap={1} flexGrow={1}>
                    <Stack flexBasis="15%" />
                    <Stack direction="row" gap={1} alignItems="center" justifyContent="center" flexGrow={1}>
                        <Pagination
                            count={checkItems.length}
                            page={currentItem + 1}
                            onChange={onPaginationChange}
                            color="primary"
                        />
                    </Stack>
                    <Stack flexBasis="15%" alignItems="flex-end">
                        <Button disabled={!allItemsSeen} onClick={() => onConfirm(new Date())}>
                            {formatMessage(commonMessages.validate)}
                        </Button>
                    </Stack>
                </Stack>
            </Modal.Footer>
        </Modal>
    )
}
