import { Stack } from "@mui/material"
import React, { useState } from "react"
import { Play } from "react-feather"
import { useIntl } from "react-intl"

import { HeaderH1, SafeFormattedMessage } from "~/components"
import { Filter } from "~/domains/orchestration/flows/components/Filter"
import { List } from "~/domains/orchestration/flows/components/List"
import { usePermission } from "~/domains/orchestration/flows/hooks/usePermission"
import { messages } from "~/domains/orchestration/flows/locale"
import { SortBy } from "~/domains/orchestration/flows/types"
import { useTitle } from "~/hooks"

export const FlowsList: React.FC = () => {
    const { formatMessage } = useIntl()
    const pageName = formatMessage(messages.page.title)
    const [searchFilter, setSearchFilter] = useState("")
    const [showEnabledOnly, setShowEnabledOnly] = useState(false)
    const [sortBy, setSortBy] = useState<SortBy>(SortBy.CREATION_DATE)

    const { hasWorkflowCreatePermission, permissionError } = usePermission()

    useTitle(pageName)

    return (
        <>
            <HeaderH1 title={pageName} icon={<Play />} />

            <Stack className="main-box">
                {!permissionError ? (
                    <Stack gap={2}>
                        <Filter
                            hasCreatePermission={hasWorkflowCreatePermission}
                            onFilterChange={setSearchFilter}
                            filter={searchFilter}
                            showEnabledOnly={showEnabledOnly}
                            onShowEnabledOnlyChange={setShowEnabledOnly}
                            sortBy={sortBy}
                            onSortChange={setSortBy}
                        />
                        <List filter={searchFilter} showEnabledOnly={showEnabledOnly} sortBy={sortBy} />
                    </Stack>
                ) : (
                    <SafeFormattedMessage tagName="p" {...messages.page.noOrganization} />
                )}
            </Stack>
        </>
    )
}
