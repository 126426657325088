import { useMemo } from "react"

import { useGetPartnersQuery } from "~/domains/identity/partners/api/partnerApiV1"
import {
    CollaborationStatus,
    PartnerAddressI,
    PartnerOrganizationI,
    PartnersDataI,
    PartnershipType,
} from "~/domains/identity/partners/types"
import { OrganizationAddressI, OrganizationId } from "~/types"

interface ReturnType {
    organizations: PartnerOrganizationI[]
    loading: boolean
}

const getPartialPartnerAddress = (partnerAddress: PartnerAddressI): OrganizationAddressI => ({
    addressLine: "",
    secondaryAddressLine: "",
    city: partnerAddress.city,
    zipCode: "",
    country: "",
})

const getCollaborationStatus = (
    partner: PartnersDataI,
    partnershipType: PartnershipType
): CollaborationStatus | undefined | null => {
    return partnershipType === PartnershipType.SUPPLIER
        ? partner.supplier?.collaborationStatus
        : partner.buyer?.collaborationStatus
}

export const usePartnersAsOrganizations = (
    partnershipType: PartnershipType,
    organizationId?: OrganizationId
): ReturnType => {
    const { data: partnersData, isLoading } = useGetPartnersQuery(
        {
            organizationId: organizationId || "",
            withMetrics: true,
        },
        { skip: !organizationId }
    )

    const partnersOrganizations = useMemo(() => {
        return ((partnersData || []) as PartnersDataI[])
            .reduce<PartnerOrganizationI[]>((acc, partner) => {
                const hasRelevantPartnership =
                    (partnershipType === PartnershipType.SUPPLIER && partner.supplier) ||
                    (partnershipType === PartnershipType.BUYER && partner.buyer)

                if (hasRelevantPartnership && partner.organizationName && partner.organizationId) {
                    acc.push({
                        id: partner.organizationId,
                        name: partner.organizationName,
                        registration: partner.registration,
                        address: partner.address ? getPartialPartnerAddress(partner.address) : undefined,
                        collaborationStatus: getCollaborationStatus(partner, partnershipType) ?? undefined,
                    })
                }

                return acc
            }, [])
            .sort((a, b) => a.name?.localeCompare(b.name))
    }, [partnersData, partnershipType])

    if (!organizationId) {
        return { organizations: [], loading: false }
    }
    if (!partnersData) {
        return { organizations: [], loading: isLoading }
    }

    return { organizations: partnersOrganizations, loading: isLoading }
}
