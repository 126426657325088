const isExpression = (value: unknown): boolean => {
    if (typeof value !== "string") return false

    // Check for current reference notation
    const hasReference = value.includes("{{") && value.includes("}}")

    // Check for new expression notation (when implemented)
    const hasNewExpression = value.includes("[[") && value.includes("]]")

    // Check for variable references with $ prefix (when implemented)
    const hasVariableReference = /\$[a-zA-Z][a-zA-Z0-9._]*/.test(value)

    return hasReference || hasNewExpression || hasVariableReference
}

export { isExpression }
