import { SpiceDBPermissionsResult } from "~/types"

export const purchaseRequestPermissionAdapter = (permissions: SpiceDBPermissionsResult) => {
    return {
        approve: Boolean(permissions.approve),
        convertToPurchaseOrder: Boolean(permissions.convert_to_purchase_order),
        delete: Boolean(permissions.delete),
        edit: Boolean(permissions.edit),
        submit: Boolean(permissions.submit),
    }
}
