import { useEffect, useState } from "react"

import { useOcrSocketIOContext } from "~/domains/transactions/invoices/_views/ocr/components/OcrSocketIOContext"
import { DocumentJobI, parseDocumentJob } from "~/domains/transactions/invoices/_views/ocr/types"
import { UserId } from "~/types"
import { getResultSuccessValue, isResultSuccess } from "~/types/Result"

export const useOcrJobsQueue = (userId: UserId) => {
    const { ioSocket, isConnected } = useOcrSocketIOContext()
    const [jobsQueue, setJobsQueue] = useState<DocumentJobI[]>([])

    useEffect(() => {
        ioSocket.emit("subscribe-pending-jobs", userId)
        ioSocket.on("pending-jobs", (data: unknown[]) => {
            if (Array.isArray(data)) {
                setJobsQueue(data.map(parseDocumentJob).filter(isResultSuccess).map(getResultSuccessValue))
            }
        })

        return () => {
            ioSocket.emit("unsubscribe-pending-jobs", userId)
        }
    }, [ioSocket, userId])

    return { jobsQueue, isConnected }
}
