import { Grid, Stack, Typography } from "@mui/material"
import { useMemo } from "react"
import { Layers } from "react-feather"
import { useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { ItemLabel, Loader, TooltipWhite } from "~/components"
import { useGetPaymentBatchInstanceQuery } from "~/domains/payment/payment-batches/api/paymentBatchesApi"
import { AssignedObject } from "~/domains/payment/payment-batches/types"
import { PartyPaymentMethodDetails } from "~/domains/payment/payment-method-details/components"

interface ObjectAssignInfoProps {
    mode?: "line" | "block"
    assignedObject: AssignedObject
}

export const ObjectAssignInfo = ({ assignedObject, mode = "block" }: ObjectAssignInfoProps) => {
    const { formatMessage, formatDate } = useIntl()
    const instanceId = assignedObject.paymentTransactionBatchInstanceId
    const { data, isLoading } = useGetPaymentBatchInstanceQuery(instanceId, { skip: !instanceId })

    const dataItems = useMemo(
        () => [
            {
                main: true,
                label: formatMessage(commonMessages.name),
                value: data?.name,
            },
            {
                label: formatMessage(commonMessages.dateScheduled),
                value: formatDate(data?.dateScheduled ?? ""),
            },
            {
                label: formatMessage(commonMessages.status),
                value: data?.status,
            },
            ...(data?.paymentMethodDetailsId
                ? [
                      {
                          main: true,
                          label: formatMessage(commonMessages.paymentMethod),
                          value: (
                              <PartyPaymentMethodDetails
                                  mode="inline"
                                  paymentMethodDetailsId={data?.paymentMethodDetailsId}
                              />
                          ),
                      },
                  ]
                : []),
        ],
        [data]
    )

    const instanceInfo = (
        <Grid container spacing={2}>
            {dataItems.map(({ label, value, main }) => (
                <Grid key={label} item xs={12} sm={main ? 12 : 6}>
                    <ItemLabel>{label}</ItemLabel>
                    <Typography>{value || "-"}</Typography>
                </Grid>
            ))}
        </Grid>
    )

    if (isLoading) {
        return <Loader small />
    }

    if (mode === "block") {
        return instanceInfo
    }

    return (
        <TooltipWhite sx={{ maxWidth: 300 }} title={instanceInfo} arrow>
            <Stack direction="row" gap={1}>
                <Layers size={18} />
                <Typography variant="body2">{data?.name}</Typography>
            </Stack>
        </TooltipWhite>
    )
}
