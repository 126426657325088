import { InvoiceLineItemAPI } from "~/domains/transactions/invoices-v1/types/Invoice"
import { CurrencyCodes } from "~/types"
import { InvoiceLineI } from "~/types"

export const lineItemFromApiAdapter = (lineItem: Partial<InvoiceLineItemAPI>, i: number): InvoiceLineI => {
    return {
        ...lineItem,
        id: lineItem.id || "",
        description: lineItem.description || "",
        productReference: lineItem.productReference || `REF-00${i}`,
        currency: lineItem.currency || CurrencyCodes.EUR,
        quantity: lineItem.quantity || 0,
        unitPrice: +(lineItem.unitPrice || 0),
        linePosition: i,
        priceWithoutTax: lineItem.priceWithoutTax || "0",
        // totalExcludedTaxes: +(lineItem.priceWithoutTax || "0") * (lineItem.quantity || 1), //@nicolas :  WHY ?  "* (lineItem.quantity || 1)" its already the total. priceWithoutTax is the total price, no need to multiply why the quantity
        totalExcludedTaxes: +(lineItem.priceWithoutTax || "0"),
        total: +(lineItem.priceWithoutTax || "0") * (lineItem.quantity || 1),
        taxId: lineItem.taxId || "",
        taxRateId: lineItem.taxId || "",
        tax: lineItem.tax || "0",
        totalTax: 0,
        unitPriceWithoutTax: lineItem.unitPriceWithoutTax || 0,
        discountTotal: +(lineItem.discountRate || "0"),
        discountRate: lineItem.discountRate || "0",
    }
}
