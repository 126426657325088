import * as Sentry from "@sentry/react"
import { useCallback, useEffect, useMemo } from "react"
import { useDispatch } from "react-redux"

import { budgetApi } from "~/domains/transactions/budget/api/budgetApi"
import { BudgetDataI } from "~/domains/transactions/budget/types"
import { useAppSelector } from "~/store/hooks"
import { OrganizationId } from "~/types"

import { budgetActions, selectBudgetDetailsData } from "../budgetSlice"

type FetchBudgetDetailsResult = {
    budgetDetails: BudgetDataI | null
    loading: boolean
    error: string | null
    refetchBudget: () => void
}

export const useFetchBudgetDetails = (
    organizationId: OrganizationId | undefined,
    budgetId: string | undefined
): FetchBudgetDetailsResult => {
    const dispatch = useDispatch()
    const { budgetDetails, loading, error } = useAppSelector(selectBudgetDetailsData)

    const fetchBudgetDetails = useCallback(() => {
        if (organizationId && budgetId) {
            dispatch(budgetActions.fetchBudgetDetails())
            budgetApi
                .fetchBudgetDetails(organizationId, budgetId)
                .then((data) => {
                    dispatch(budgetActions.fetchBudgetDetailsSuccess(data))
                })
                .catch((err) => {
                    Sentry.captureException(err)
                    dispatch(budgetActions.fetchBudgetDetailsFailed(err))
                })
        }
    }, [dispatch, budgetId, organizationId])

    useEffect(() => {
        fetchBudgetDetails()
    }, [fetchBudgetDetails])

    return useMemo(() => {
        return { budgetDetails, loading, error, refetchBudget: fetchBudgetDetails }
    }, [budgetDetails, loading, error, fetchBudgetDetails])
}
