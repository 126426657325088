import { useCallback, useMemo, useState } from "react"
import { Check, Package, Send, Tag, Trash, X } from "react-feather"
import { useIntl } from "react-intl"
import { toast } from "react-toastify"

import { commonMessages } from "~/common-messages"
import { BulkActionButton, BulkActions, ConfirmModal, SafeFormattedMessage } from "~/components"
import { ModalAddTags } from "~/domains/analytics/tags/components/ModalAddTags"
import { useTagsApi } from "~/domains/analytics/tags/tagsApi"
import { TagI } from "~/domains/analytics/tags/types"
import { useFetchSharedObjects } from "~/domains/identity/roles-permissions/store/hooks"
import { purchaseOrderApi, purchaseOrderApiTags } from "~/domains/transactions/purchase-orders/api/purchaseOrderApi"
import {
    getApprovable,
    getClosable,
    getDeletable,
    getPOBulkActionButtons,
    getReceivable,
    getSendable,
    showBulkActionButtonCount,
} from "~/domains/transactions/purchase-orders/core/purchaseOrder"
import { useApprovePurchaseOrders } from "~/domains/transactions/purchase-orders/hooks/useApprovePurchaseOrders"
import { useDeletePurchaseOrders } from "~/domains/transactions/purchase-orders/hooks/useDeletePurchaseOrders"
import { useMarkAsClosedPurchaseOrders } from "~/domains/transactions/purchase-orders/hooks/useMarkAsClosedPurchaseOrders"
import { useMarkAsReceivedPurchaseOrders } from "~/domains/transactions/purchase-orders/hooks/useMarkAsReceivedPurchaseOrders"
import { useSendPurchaseOrders } from "~/domains/transactions/purchase-orders/hooks/useSendPurchaseOrders"
import { purchaseOrdersMessages } from "~/domains/transactions/purchase-orders/pages"
import { PurchaseOrders, PurchaseOrdersTab } from "~/domains/transactions/purchase-orders/types"
import { ListPurchaseOrders } from "~/domains/transactions/purchase-orders/types"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { selectCurrentOrganization, selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { ViewTypeI } from "~/types"
import { SharedObjectType } from "~/types/SharedObjects"
import { sleep } from "~/utils/sleep"

interface PurchaseOrdersBulkActionsProps {
    purchaseOrders: ListPurchaseOrders
    selected: string[]
    setSelected: (selected: string[]) => void
    view: ViewTypeI
    tabValue: PurchaseOrdersTab
}

export const PurchaseOrdersBulkActions = ({
    purchaseOrders,
    selected,
    setSelected,
    view,
    tabValue,
}: PurchaseOrdersBulkActionsProps) => {
    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()

    const [selectedForApprove, setSelectedForApprove] = useState<ListPurchaseOrders>([])
    const [displayModalApprove, setDisplayModalApprove] = useState(false)
    const [selectedForSend, setSelectedForSend] = useState<string[]>([])
    const [displayModalSend, setDisplayModalSend] = useState(false)
    const [selectedForReceived, setSelectedForReceived] = useState<ListPurchaseOrders>([])
    const [displayModalReceived, setDisplayModalReceived] = useState(false)
    const [displayModalAddTags, setDisplayModalAddTags] = useState(false)
    const [selectedForClose, setSelectedForClose] = useState<ListPurchaseOrders>([])
    const [displayModalClose, setDisplayModalClose] = useState(false)
    const [selectedForDelete, setSelectedForDelete] = useState<ListPurchaseOrders>([])
    const [displayModalDelete, setDisplayModalDelete] = useState(false)

    const selectedPurchaseOrders = useMemo(() => {
        return selected.reduce((acc, id) => {
            const po = purchaseOrders.find((p) => p.id === id)
            if (po) {
                acc.push(po)
            }
            return acc
        }, [] as PurchaseOrders[])
    }, [selected, purchaseOrders])

    const currentOrganization = useAppSelector(selectCurrentOrganization)
    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)

    const handleHideModalApprove = () => setDisplayModalApprove(false)
    const handleHideModalSend = () => setDisplayModalSend(false)
    const handleHideModalReceived = () => setDisplayModalReceived(false)
    const handleHideModalClose = () => setDisplayModalClose(false)
    const handleHideModalDelete = () => setDisplayModalDelete(false)

    const tagsApi = useTagsApi()
    const organizationSharings = useFetchSharedObjects(currentOrganizationId)

    const approvePurchaseOrders = useApprovePurchaseOrders()
    const sendPurchaseOrders = useSendPurchaseOrders()
    const receivedPurchaseOrders = useMarkAsReceivedPurchaseOrders()
    const closePurchaseOrders = useMarkAsClosedPurchaseOrders()
    const deletePurchaseOrders = useDeletePurchaseOrders()

    const getApproveBulkActionButton = useCallback(
        (currentTab: PurchaseOrdersTab): BulkActionButton => {
            const handleApprovePurchaseOrder = (s: ListPurchaseOrders) => {
                if (!s.length) return toast.error(formatMessage(purchaseOrdersMessages.nothingToApprove))

                setSelectedForApprove(s)
                setDisplayModalApprove(true)
            }

            const approvablePOs = getApprovable(selected, purchaseOrders, view)
            const approvablePOsForUser = getApprovable(selected, purchaseOrders, view, true)
            return {
                label: formatMessage(purchaseOrdersMessages.approve),
                count: showBulkActionButtonCount[currentTab] ? approvablePOs.length : undefined,
                hasPermission: approvablePOs.length === 0 || approvablePOsForUser.length > 0,
                icon: <Check size={16} />,
                onClick: () => handleApprovePurchaseOrder(approvablePOsForUser),
            }
        },
        [selected, purchaseOrders, formatMessage, view]
    )

    const getSendBulkActionButton = useCallback(
        (currentTab: PurchaseOrdersTab): BulkActionButton => {
            const handleSendPurchaseOrder = (s: string[]) => {
                if (!s.length) return toast.error(formatMessage(purchaseOrdersMessages.nothingToSend))

                setSelectedForSend(s)
                setDisplayModalSend(true)
            }

            const sendablePOs = getSendable(selected, purchaseOrders, view, organizationSharings)
            const sendablePOsForUser = getSendable(selected, purchaseOrders, view, organizationSharings, true)
            return {
                label: formatMessage(purchaseOrdersMessages.send),
                count: showBulkActionButtonCount[currentTab] ? sendablePOs.length : undefined,
                hasPermission: sendablePOs.length === 0 || sendablePOsForUser.length > 0,
                icon: <Send size={16} />,
                onClick: () => handleSendPurchaseOrder(sendablePOsForUser),
            }
        },
        [selected, purchaseOrders, organizationSharings, formatMessage, view]
    )

    const getReceivedBulkActionButton = useCallback(
        (currentTab: PurchaseOrdersTab): BulkActionButton => {
            const handleReceivedPurchaseOrder = (s: ListPurchaseOrders) => {
                if (!s.length) return toast.error(formatMessage(purchaseOrdersMessages.nothingToMarkAsReceived))

                setSelectedForReceived(s)
                setDisplayModalReceived(true)
            }

            const receivablePOs = getReceivable(selected, purchaseOrders, true)
            const receivablePOsForUser = getReceivable(selected, purchaseOrders, true)
            return {
                label: formatMessage(purchaseOrdersMessages.received),
                count: showBulkActionButtonCount[currentTab] ? receivablePOs.length : undefined,
                hasPermission: receivablePOsForUser.length > 0,
                icon: <Package size={16} />,
                onClick: () => handleReceivedPurchaseOrder(receivablePOsForUser),
            }
        },
        [selected, purchaseOrders, formatMessage]
    )

    const getCloseBulkActionButton = useCallback(
        (currentTab: PurchaseOrdersTab): BulkActionButton => {
            const handleClosePurchaseOrder = (s: ListPurchaseOrders) => {
                if (!s.length) return toast.error(formatMessage(purchaseOrdersMessages.nothingToMarkAsClosed))

                setSelectedForClose(s)
                setDisplayModalClose(true)
            }

            const closablePOs = getClosable(selected, purchaseOrders, true)
            const closablePOsForUser = getClosable(selected, purchaseOrders, true)
            return {
                label: formatMessage(commonMessages.close),
                count: showBulkActionButtonCount[currentTab] ? closablePOs.length : undefined,
                hasPermission: closablePOsForUser.length > 0,
                icon: <X size={16} />,
                onClick: () => handleClosePurchaseOrder(closablePOsForUser),
            }
        },
        [selected, purchaseOrders, formatMessage]
    )

    const getDeleteBulkActionButton = useCallback(
        (currentTab: PurchaseOrdersTab): BulkActionButton => {
            const handleDeletePurchaseOrder = (s: ListPurchaseOrders) => {
                if (!selectCurrentOrganizationId.length)
                    return toast.error(formatMessage(purchaseOrdersMessages.nothingToDelete))

                setSelectedForDelete(s)
                setDisplayModalDelete(true)
            }

            const deletablePOs = getDeletable(selected, purchaseOrders, true)
            const deletablePOsForUser = getDeletable(selected, purchaseOrders, true)
            return {
                label: formatMessage(commonMessages.delete),
                count: showBulkActionButtonCount[currentTab] ? deletablePOs.length : undefined,
                hasPermission: deletablePOsForUser.length > 0,
                icon: <Trash size={16} />,
                type: "error-light",
                onClick: () => handleDeletePurchaseOrder(deletablePOsForUser),
            }
        },
        [selected, purchaseOrders, formatMessage]
    )

    const getAddTagsBulkActionButton = useCallback(
        (currentTab: PurchaseOrdersTab): BulkActionButton => {
            const handleAddTagsPurchaseOrder = (s: string[]) => {
                if (!s.length) return toast.error(formatMessage(purchaseOrdersMessages.nothingToAddTags))

                setDisplayModalAddTags(true)
            }

            return {
                label: formatMessage(purchaseOrdersMessages.addTags),
                count: showBulkActionButtonCount[currentTab] ? selected.length : undefined,
                icon: <Tag size={16} />,
                onClick: () => handleAddTagsPurchaseOrder(selected),
            }
        },
        [selected, formatMessage]
    )

    const bulkActionButtons = useMemo(() => {
        return getPOBulkActionButtons({
            currentTab: tabValue,
            view,
            handleValidate: getApproveBulkActionButton,
            handleSend: getSendBulkActionButton,
            handleReceived: getReceivedBulkActionButton,
            handleAddTags: getAddTagsBulkActionButton,
            handleClose: getCloseBulkActionButton,
            handleDelete: getDeleteBulkActionButton,
        })
    }, [
        tabValue,
        view,
        getApproveBulkActionButton,
        getSendBulkActionButton,
        getReceivedBulkActionButton,
        getAddTagsBulkActionButton,
        getCloseBulkActionButton,
        getDeleteBulkActionButton,
    ])

    const handleConfirmModalApprove = async () => {
        if (!currentOrganizationId) return

        setDisplayModalApprove(false)
        await approvePurchaseOrders(selectedForApprove, currentOrganizationId)
        dispatch(purchaseOrderApi.util.invalidateTags(purchaseOrderApiTags))
        setSelected([])
        setSelectedForApprove([])
    }

    const handleConfirmModalSend = async () => {
        if (!currentOrganizationId) return

        setDisplayModalSend(false)
        await sendPurchaseOrders(selectedForSend, currentOrganizationId)
        dispatch(purchaseOrderApi.util.invalidateTags(purchaseOrderApiTags))
        setSelected([])
        setSelectedForSend([])
    }

    const handleConfirmModalReceived = async () => {
        if (!currentOrganizationId) return

        setDisplayModalReceived(false)
        await receivedPurchaseOrders(selectedForReceived, currentOrganizationId)
        dispatch(purchaseOrderApi.util.invalidateTags(purchaseOrderApiTags))
        setSelected([])
        setSelectedForReceived([])
    }

    const handleConfirmModalClose = async () => {
        if (!currentOrganizationId) return

        setDisplayModalClose(false)
        await closePurchaseOrders(selectedForClose, currentOrganizationId)
        dispatch(purchaseOrderApi.util.invalidateTags(purchaseOrderApiTags))
        setSelected([])
        setSelectedForClose([])
    }

    const handleConfirmModalDelete = async () => {
        if (!currentOrganizationId) return

        setDisplayModalDelete(false)
        await deletePurchaseOrders(selectedForDelete, currentOrganizationId)
        dispatch(purchaseOrderApi.util.invalidateTags(purchaseOrderApiTags))
        setSelected([])
        setSelectedForDelete([])
    }

    const handleConfirmModalAddTags = async (tagsToAdd: TagI[], tagsToRemove: TagI[]) => {
        if (!currentOrganizationId) return false

        const objectType = "PurchaseOrder" as SharedObjectType

        await Promise.allSettled(
            await Promise.allSettled(
                selected.map(async (id) => {
                    tagsToRemove.forEach(async (t) => {
                        await tagsApi.deleteTagObject(currentOrganizationId, id, t.tagId)
                    })

                    const createBulkObjectTags = tagsToAdd.map((t) => ({
                        objectId: id,
                        tagId: t.tagId,
                        objectType,
                    }))

                    await tagsApi.createObjectTags(currentOrganizationId, createBulkObjectTags)
                })
            )
        )
        await sleep(200)

        dispatch(purchaseOrderApi.util.invalidateTags(purchaseOrderApiTags))
        setDisplayModalAddTags(false)
        setSelected([])
    }

    const handleClose = () => setSelected([])

    return !selected.length ? null : (
        <>
            <BulkActions
                selected={selected}
                buttons={bulkActionButtons}
                selectedMessage={formatMessage(purchaseOrdersMessages.selected, {
                    s: selected.length > 1 ? "s" : "",
                })}
                onClose={handleClose}
            />

            <ConfirmModal
                title={formatMessage(purchaseOrdersMessages.confirmApproveTitle)}
                open={displayModalApprove}
                close={handleHideModalApprove}
                onConfirm={handleConfirmModalApprove}
                confirmButtonType="primary"
            >
                <SafeFormattedMessage
                    {...purchaseOrdersMessages.confirmApproveMessage}
                    values={{ count: selectedForApprove.length }}
                />
            </ConfirmModal>

            <ConfirmModal
                title={formatMessage(purchaseOrdersMessages.confirmSendTitle)}
                open={displayModalSend}
                close={handleHideModalSend}
                onConfirm={handleConfirmModalSend}
                confirmButtonType="primary"
            >
                <SafeFormattedMessage
                    {...purchaseOrdersMessages.confirmSendMessage}
                    values={{ count: selectedForSend.length }}
                />
            </ConfirmModal>

            <ConfirmModal
                title={formatMessage(purchaseOrdersMessages.confirmReceivedTitle)}
                open={displayModalReceived}
                close={handleHideModalReceived}
                onConfirm={handleConfirmModalReceived}
                confirmButtonType="primary"
            >
                <SafeFormattedMessage
                    {...purchaseOrdersMessages.confirmReceivedMessage}
                    values={{ count: selectedForReceived.length }}
                />
            </ConfirmModal>

            <ConfirmModal
                title={formatMessage(purchaseOrdersMessages.confirmCloseTitle)}
                open={displayModalClose}
                close={handleHideModalClose}
                onConfirm={handleConfirmModalClose}
                confirmButtonType="primary"
            >
                <SafeFormattedMessage
                    {...purchaseOrdersMessages.confirmCloseMessage}
                    values={{ count: selectedForClose.length }}
                />
            </ConfirmModal>

            <ConfirmModal
                title={formatMessage(purchaseOrdersMessages.confirmDeleteTitle)}
                open={displayModalDelete}
                close={handleHideModalDelete}
                onConfirm={handleConfirmModalDelete}
                confirmButtonType="error-light"
            >
                <SafeFormattedMessage
                    {...purchaseOrdersMessages.confirmDeleteMessage}
                    values={{ count: selectedForDelete.length }}
                />
            </ConfirmModal>

            {currentOrganization && (
                <ModalAddTags
                    organization={currentOrganization}
                    objects={selectedPurchaseOrders}
                    displayModal={displayModalAddTags}
                    setDisplayModal={setDisplayModalAddTags}
                    onConfirm={handleConfirmModalAddTags}
                />
            )}
        </>
    )
}
