import { Stack, Typography, styled } from "@mui/material"
import React from "react"
import { Loader, Trash2 } from "react-feather"

import { SharedObjectWithName } from "~/types/SharedObjects"

const HoverableStack = styled(Stack)({
    padding: "8px",
    borderRadius: "var(--border-radius-xs)",
    "&:hover": {
        backgroundColor: "var(--color-violet-lighter)",
    },
})

export const SharedUser: React.FC<{
    shareObject: SharedObjectWithName
    callbackButton: (shareObject: SharedObjectWithName) => void
    loading: boolean
}> = ({ shareObject, callbackButton, loading }) => {
    const handleConfirmDeleteSharing = () => callbackButton(shareObject)

    return (
        <HoverableStack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="body1">
                {shareObject.userFullName && shareObject.userFullName !== "" ? `${shareObject.userFullName} - ` : ""}
                {shareObject.userEmail}
            </Typography>
            {loading ? (
                <Loader size="small" />
            ) : (
                <Trash2 className="cursor-pointer" size={18} onClick={handleConfirmDeleteSharing} />
            )}
        </HoverableStack>
    )
}
