import { List, Stack, Typography } from "@mui/material"
import React, { useMemo } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Card, Loader, SafeFormattedMessage } from "~/components"
import { ErrorMessage } from "~/components/ErrorMessage"
import { InvoicePicker } from "~/domains/transactions/invoices/components/InvoicePicker"
import { selectPurchaseViewType } from "~/domains/transactions/purchase-orders/store/purchaseOrdersSlice"
import { PurchaseOrders } from "~/domains/transactions/purchase-orders/types"
import { useAppSelector } from "~/store/hooks"
import { OrganizationId, SpiceDBPermissionsResult } from "~/types"
import { useFetchErrorMessage } from "~/utils/apiClient/errors"

import { useAddRelationToPurchaseOrder, usePurchaseOrderRelations } from "../../hooks"
import { UploadInvoiceBox } from "../UploadInvoiceBox/UploadInvoiceBox"
import { PurchaseOrderRelation } from "./PurchaseOrderRelation"

const messages = defineMessages({
    title: {
        id: "transactions.purchaseOrder.documentRelations.title",
        defaultMessage: "{count, plural, =0 {Invoice} one {Invoice} other {Invoices}}",
    },
    or: { id: "common.or", defaultMessage: "or" },
})

const canManageRelations = (permissions?: SpiceDBPermissionsResult) => {
    if (!permissions) return false

    return (
        permissions.edit ||
        permissions.submit ||
        permissions.send ||
        permissions.approve ||
        permissions.mark_line_in_preparation ||
        permissions.mark_line_in_transit ||
        permissions.mark_line_delivered_or_received ||
        permissions.mark_line_canceled
    )
}

interface Props {
    organizationId: OrganizationId
    purchaseOrder: PurchaseOrders
}

export const PurchaseOrderDocumentRelations: React.FC<Props> = ({ organizationId, purchaseOrder }) => {
    const { formatMessage } = useIntl()

    const viewType = useAppSelector(selectPurchaseViewType)

    const { loading, error, purchaseOrderRelations } = usePurchaseOrderRelations(organizationId, purchaseOrder.id)
    const addRelationToPurchaseOrder = useAddRelationToPurchaseOrder(organizationId, purchaseOrder.id)
    const errorMessage = useFetchErrorMessage(error)

    const invoiceIds = useMemo(() => purchaseOrderRelations.map(({ invoiceId }) => invoiceId), [purchaseOrderRelations])

    const canManagePORelations = canManageRelations(purchaseOrder.permissions)

    if (loading) {
        return <Loader small />
    }

    return (
        <Card title={formatMessage(messages.title, { count: purchaseOrderRelations.length })} expandable>
            <Stack spacing={1}>
                {loading ? (
                    <Loader small />
                ) : (
                    <List>
                        {purchaseOrderRelations.map((relation) => (
                            <PurchaseOrderRelation
                                key={relation.relationId}
                                purchaseOrderRelation={relation}
                                organizationId={organizationId}
                                purchaseOrderId={purchaseOrder.id}
                                viewType={viewType}
                                readOnly={!canManagePORelations}
                            />
                        ))}
                    </List>
                )}

                {canManagePORelations && (
                    <>
                        <ErrorMessage>{errorMessage}</ErrorMessage>

                        <InvoicePicker
                            organizationId={organizationId}
                            value={null}
                            onChange={addRelationToPurchaseOrder}
                            viewType={viewType}
                            blackListIds={invoiceIds}
                        />

                        <Typography variant="body2" className="text-center">
                            <SafeFormattedMessage {...messages.or} />
                        </Typography>

                        <UploadInvoiceBox purchaseOrderId={purchaseOrder.id} />
                    </>
                )}
            </Stack>
        </Card>
    )
}
