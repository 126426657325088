import { capitalize, useMediaQuery, useTheme } from "@mui/material"
import { Stack } from "@mui/material"
import { isNumber } from "lodash"
import { defineMessages, useIntl } from "react-intl"

import { ItemLabel, TooltipConditional } from "~/components"

const messages = defineMessages({
    items: {
        id: "purchase.orders.order.steps.items",
        defaultMessage: "{current}/{total} items",
    },
})

export interface StepI {
    key: string
    name: string
    value?: string
    isCurrentLifecycle?: boolean
    done?: boolean
    status?: "done" | "next" | string
    description?: string
    onClick?: (status: string) => void
    icon?: React.ReactNode
    linesInProgress?: number
    totalLines?: number
    type?: "error" | "success" | "warning" | "info" | "default"
}

export const Step = ({
    value,
    status,
    icon,
    name,
    description,
    totalLines,
    linesInProgress,
    onClick,
    done,
    type,
}: StepI) => {
    const { formatMessage } = useIntl()
    const theme = useTheme()
    const hideDescription = useMediaQuery(theme.breakpoints.down("lg"))
    const hideCount = useMediaQuery(theme.breakpoints.down("md"))

    const handleOnClick = () => {
        onClick?.(name)
    }

    const hasLines = isNumber(totalLines) && isNumber(linesInProgress)

    const isDone = done || status === "done"

    const getColor = () => {
        if (!isDone) {
            return "var(--color-grey-light)"
        }

        if (type === "error") {
            return "var(--color-red)"
        }

        return "var(--color-green)"
    }

    return (
        <TooltipConditional condition={!!description} title={description} arrow>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                columnGap={2}
                height={hasLines ? 100 : 60}
                padding={2}
                onClick={handleOnClick}
                color={getColor()}
                className={onClick ? "cursor-pointer" : ""}
            >
                {icon}

                <Stack>
                    {capitalize(name)}
                    {!hideDescription && <ItemLabel color={type}>{value || description}</ItemLabel>}

                    {!hideCount && hasLines && (
                        <ItemLabel color={type}>
                            {formatMessage(messages.items, {
                                current: linesInProgress,
                                total: totalLines,
                            })}
                        </ItemLabel>
                    )}
                </Stack>
            </Stack>
        </TooltipConditional>
    )
}
