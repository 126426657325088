import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import fileDownload from "js-file-download"
import { defineMessages } from "react-intl"

import { prepareHeadersWithAuthorization as prepareHeaders } from "~/api/prepareHeaders"
import { ExtendedFetchBaseQueryError, taggedBaseQuery } from "~/api/rtkFetchBaseExtend"
import { paymentFromApiAdapter } from "~/domains/payment/payment/api/adapters"
import { Payment, PaymentBatch, PaymentObject, PaymentRole, PaymentUI } from "~/domains/payment/payment/types"
import { PaginatedResponse, SwiftMessageType, XMLVersion } from "~/domains/payment/types"

const BASE_URL = import.meta.env.VITE_API_PAYMENT_URL_V2 as string

const tags = ["Payment"]

const messages = defineMessages({
    paymentUpdatedFailed: {
        id: "payment.document.paymentDetails.paymentUpdatedFailed",
        defaultMessage: "The payment update has failed",
    },
})

interface PaymentQueryParams {
    organizationId: string | undefined
    page: number
    pageSize: number
    role?: PaymentRole
    includeObjects?: boolean
}

export const paymentApi = createApi({
    reducerPath: "paymentApi",
    baseQuery: taggedBaseQuery({
        baseQueryFn: fetchBaseQuery({
            baseUrl: BASE_URL,
            prepareHeaders,
        }),
        domain: "payment",
        subdomain: "payments",
    }),
    tagTypes: tags,
    endpoints: (builder) => ({
        getPaymentsByOrganization: builder.query<PaginatedResponse<PaymentUI>, PaymentQueryParams>({
            query: ({ organizationId, page, pageSize, role, includeObjects = false }) => ({
                url: `/payment/by_organization/${organizationId}`,
                params: {
                    page,
                    role: role ?? PaymentRole.BOTH,
                    page_size: pageSize,
                    include_associated_objects: includeObjects,
                },
            }),
            providesTags: tags,
            transformResponse: (response: PaginatedResponse<Payment>) => {
                return {
                    ...response,
                    items: response.items.map(paymentFromApiAdapter),
                } as PaginatedResponse<PaymentUI>
            },
        }),
        getPaymentObjects: builder.query<PaginatedResponse<PaymentObject>, { object_id?: string; payment_id?: string }>(
            {
                query: ({ object_id, payment_id }) => ({
                    url: "/payment_object",
                    params: { ...(object_id && { object_id }), ...(payment_id && { payment_id }) },
                }),
                providesTags: tags,
            }
        ),
        getPaymentByObjectId: builder.query<PaginatedResponse<Payment>, string>({
            query: (objectId: string) => ({
                url: `/payment/by_object/${objectId}`,
            }),
            providesTags: tags,
        }),
        getPayment: builder.query<Payment, string>({
            query: (id: string) => ({
                url: `/payment/${id}`,
            }),
            providesTags: tags,
        }),
        generateXMLFile: builder.mutation<void, { payment_ids: string[]; message_type: XMLVersion }>({
            query: ({ payment_ids, message_type }) => ({
                url: "/payment/generate_iso20022",
                method: "POST",
                body: { payment_ids, message_type },
                responseHandler: (response) => response.blob(),
            }),
            transformResponse: (blob: Blob) => {
                if (blob) {
                    fileDownload(blob, "iso20022_payment.xml")
                }
            },
        }),
        generateSwiftFile: builder.mutation<
            void,
            { paymentIds: string[]; organization: Record<string, string>; messageType: SwiftMessageType }
        >({
            query: ({ paymentIds, messageType, organization }) => ({
                url: "/payment/generate_swift",
                method: "POST",
                body: { payment_ids: paymentIds, organization_name: organization, message_type: messageType },
                responseHandler: (response) => response.blob(),
            }),
            transformResponse: (blob: Blob) => {
                if (blob) {
                    fileDownload(blob, "payment-swift-file.txt")
                }
            },
        }),
        createPayment: builder.mutation<Payment, Partial<Payment>>({
            query: (payment: Payment) => ({
                url: "/payment",
                method: "POST",
                body: payment,
            }),
            invalidatesTags: tags,
        }),
        createPaymentObject: builder.mutation<PaymentObject, Partial<PaymentObject>>({
            query: (paymentObject: PaymentObject) => ({
                url: "/payment_object",
                method: "POST",
                body: paymentObject,
            }),
            invalidatesTags: tags,
        }),
        updatePayment: builder.mutation<Payment, Partial<Payment>>({
            query: ({ id, ...updateData }) => ({
                url: `/payment/${id}`,
                method: "PUT",
                body: updateData,
            }),
            invalidatesTags: tags,
            transformErrorResponse: (response: ExtendedFetchBaseQueryError) => ({
                ...response,
                meta: {
                    ...response.meta,
                    errorMessage: messages.paymentUpdatedFailed,
                },
            }),
        }),
        batchPayments: builder.mutation<void, PaymentBatch[]>({
            query: (payload: PaymentBatch[]) => ({
                url: "/payment/batch",
                method: "POST",
                body: { payload },
            }),
            invalidatesTags: tags,
        }),
    }),
})

export const {
    useGetPaymentsByOrganizationQuery,
    useGetPaymentQuery,
    useGetPaymentObjectsQuery,
    useGetPaymentByObjectIdQuery,
    useLazyGetPaymentByObjectIdQuery,
    useCreatePaymentMutation,
    useCreatePaymentObjectMutation,
    useUpdatePaymentMutation,
    useGenerateXMLFileMutation,
    useGenerateSwiftFileMutation,
    useBatchPaymentsMutation,
} = paymentApi
