import { TagObjectRecordI } from "~/domains/analytics/tags/types/TagObjectRecord"
import { ListPurchaseOrders, PurchaseOrders, PurchaseOrdersTab } from "~/domains/transactions/purchase-orders/types"
import { CurrencyCodes, ViewTypeI } from "~/types"

export interface PurchaseOrdersState {
    loading: boolean
    error: string | null
    filter: string
    organizationId: string | null
    viewType: ViewTypeI
    list: ListPurchaseOrders
    data: PurchaseOrders
    tagsLoading: boolean
    tags: TagObjectRecordI | undefined
    currentPurchaseOrdersTab: PurchaseOrdersTab
    needSaveShippingAndBillingAddress: boolean
}

export const purchaseOrdersState: PurchaseOrdersState = {
    loading: false,
    error: null,
    filter: "",
    organizationId: null,
    list: [],
    viewType: ViewTypeI.buyer,
    tagsLoading: false,
    tags: undefined,
    currentPurchaseOrdersTab: PurchaseOrdersTab.ALL,
    needSaveShippingAndBillingAddress: false,
    data: {
        id: "",
        shortId: "",
        billingAddress: {
            city: "",
            country: "",
            street: "",
            street2: "",
            zipCode: "",
        },
        buyerId: "",
        buyerName: "",
        supplierId: "",
        supplierName: "",
        description: "",
        requesterName: "",
        expectedDeliveryDate: "",
        creationDate: "",
        requesterUserId: "",
        totalAmount: 0,
        totalAmountExcludingTax: 0,
        totalTax: 0,
        purchaseRequest: {
            id: "",
            number: "",
        },
        status: "DRAFT",
        statusPresentation: "DRAFT",
        fulfillmentPresentation: "PENDING",
        currency: CurrencyCodes.EUR,
        lines: [],
        lineCount: 0,
        shippingAddress: {
            city: "",
            country: "",
            street: "",
            street2: "",
            zipCode: "",
        },
        permissions: {},
        tagIds: [],
    },
}
