import { MessageDescriptor, defineMessages } from "react-intl"

import { Currencies, CurrencyCodes } from "~/types"
import { clfSatremOrgs, eiffageOrgs, secheOrgs, veoliaOrgs } from "~/utils/featureFlag"

export enum EiffageCustomFields {
    ORGANISATION_NUMBER = "organisationNumber",
    ACCOUNT_NUMBER = "accountNumber",
    ID_LODACE_SPEND = "idLodaceSpend",
    WEB_SITE = "webSite",
    COVERED_REGION = "coveredRegion",
    INTERNATIONAL_PRESENCE = "internationalPresence",
    EIFFAGE_OPERATING_BRANCHES = "eiffageOperatingBranches",
    PURCHASING_MANAGER = "purchasingManager",
}

export const eiffageCustomFieldsMessages: Record<EiffageCustomFields, MessageDescriptor> = defineMessages({
    [EiffageCustomFields.ORGANISATION_NUMBER]: {
        id: "customfields.company.eiffage.organisationNumber",
        defaultMessage: "Organisation Number",
    },
    [EiffageCustomFields.ACCOUNT_NUMBER]: {
        id: "customfields.company.eiffage.accountNumber",
        defaultMessage: "Account Number",
    },
    [EiffageCustomFields.ID_LODACE_SPEND]: {
        id: "customfields.company.eiffage.idLodaceSpend",
        defaultMessage: "ID Lodace Spend",
    },
    [EiffageCustomFields.WEB_SITE]: {
        id: "customfields.company.eiffage.webSite",
        defaultMessage: "Web Site",
    },
    [EiffageCustomFields.COVERED_REGION]: {
        id: "customfields.company.eiffage.coveredRegion",
        defaultMessage: "Covered Region",
    },
    [EiffageCustomFields.INTERNATIONAL_PRESENCE]: {
        id: "customfields.company.eiffage.internationalPresence",
        defaultMessage: "International Presence",
    },
    [EiffageCustomFields.EIFFAGE_OPERATING_BRANCHES]: {
        id: "customfields.company.eiffage.eiffageOperatingBranches",
        defaultMessage: "Eiffage Operating Branches",
    },
    [EiffageCustomFields.PURCHASING_MANAGER]: {
        id: "customfields.company.eiffage.purchasingManager",
        defaultMessage: "Purchasing manager",
    },
})

export enum VeoliaCustomFields {
    RECURRING_MODE = "recurringMode",
    PAYMENT_TERMS_REQUIRED = "paymentTermsRequired",
    WARRANTY_PERIOD_REQUIRED = "warrantyPeriodRequired",
    COMPETITIVE_BIDDING_TRACKER = "competitiveBiddingTracker",
    APPROVED_BID_COMPARISON = "approvedBidComparison",
    PO_REGULARISATION = "poRegularisation",
    INCOTERMS = "incoterms",
    SUPPLIER_CHARTER_SIGNED = "supplierCharterSigned",
    SUPPLIER_STATUS = "supplierStatus",
}

export const VeoliaCustomFieldsMessages: Record<VeoliaCustomFields, MessageDescriptor> = defineMessages({
    [VeoliaCustomFields.RECURRING_MODE]: {
        id: "customfields.company.recurringMode",
        defaultMessage: "Recurrent or One-off Spend",
    },
    [VeoliaCustomFields.PAYMENT_TERMS_REQUIRED]: {
        id: "customfields.company.paymentTerms",
        defaultMessage: "Payment Terms Required",
    },
    [VeoliaCustomFields.WARRANTY_PERIOD_REQUIRED]: {
        id: "customfields.company.warrantyPeriod",
        defaultMessage: "Warranty Period Required",
    },
    [VeoliaCustomFields.COMPETITIVE_BIDDING_TRACKER]: {
        id: "customfields.company.competitiveBiddingTracker",
        defaultMessage: "Competitive Bidding Tracker",
    },
    [VeoliaCustomFields.APPROVED_BID_COMPARISON]: {
        id: "customfields.company.approvedBidComparison",
        defaultMessage: "Approved Bid Comparison",
    },
    [VeoliaCustomFields.PO_REGULARISATION]: {
        id: "customfields.company.poRegularisation",
        defaultMessage: "PO Regularisation",
    },
    [VeoliaCustomFields.INCOTERMS]: {
        id: "customfields.company.incoterms",
        defaultMessage: "Incoterms",
    },
    [VeoliaCustomFields.SUPPLIER_CHARTER_SIGNED]: {
        id: "customfields.company.supplierCharterSigned",
        defaultMessage: "Supplier Charter Signed",
    },
    [VeoliaCustomFields.SUPPLIER_STATUS]: {
        id: "customfields.company.supplierStatus",
        defaultMessage: "Supplier Status",
    },
})

export enum VeoliaRecurrenceMode {
    RECURRENT = "recurrent",
    ONEOFF = "oneOff",
}

export const recurrenceModeEnumMessages: Record<VeoliaRecurrenceMode, MessageDescriptor> = defineMessages({
    [VeoliaRecurrenceMode.RECURRENT]: {
        id: "purchase.requests.request.infos.enum.recurrent",
        defaultMessage: "Recurrent",
    },
    [VeoliaRecurrenceMode.ONEOFF]: {
        id: "purchase.requests.request.infos.enum.oneoff",
        defaultMessage: "One-off",
    },
})

export enum ObjectStrictDecision {
    YES = "Yes",
    NO = "No",
}

export const strictDecisionEnumMessages: Record<ObjectStrictDecision, MessageDescriptor> = defineMessages({
    [ObjectStrictDecision.YES]: {
        id: "customfields.company.enum.yes",
        defaultMessage: "Yes",
    },
    [ObjectStrictDecision.NO]: {
        id: "customfields.company.enum.no",
        defaultMessage: "No",
    },
})

export enum ObjectJustifiableDecision {
    YES = "Yes",
    NO = "No",
    JUSTIFIABLE = "Justifiable",
}

export const justifiableDecisionEnumMessages: Record<ObjectJustifiableDecision, MessageDescriptor> = defineMessages({
    [ObjectJustifiableDecision.YES]: {
        id: "customfields.company.enum.yes",
        defaultMessage: "Yes",
    },
    [ObjectJustifiableDecision.NO]: {
        id: "customfields.company.enum.no",
        defaultMessage: "No",
    },
    [ObjectJustifiableDecision.JUSTIFIABLE]: {
        id: "customfields.company.enum.justifiable",
        defaultMessage: "Justifiable",
    },
})

export enum incoTerms {
    EXW = "EXW",
    FCA = "FCA",
    FAS = "FAS",
    FOB = "FOB",
    CFR = "CFR",
    CIF = "CIF",
    CPT = "CPT",
    CIP = "CIP",
    DPU = "DPU",
    DAP = "DAP",
    DDP = "DDP",
}

export const incoTermsEnumMessages: Record<incoTerms, MessageDescriptor> = defineMessages({
    [incoTerms.EXW]: {
        id: "customfields.company.enum.incoterms.EXW",
        defaultMessage: "Ex works - Agreed place",
    },
    [incoTerms.FCA]: {
        id: "customfields.company.enum.incoterms.FCA",
        defaultMessage: "Free carrier - Agreed place",
    },
    [incoTerms.FAS]: {
        id: "customfields.company.enum.incoterms.FAS",
        defaultMessage: "Free alongside ship - Port of loading",
    },
    [incoTerms.FOB]: {
        id: "customfields.company.enum.incoterms.FOB",
        defaultMessage: "Free on board - Port of loading",
    },
    [incoTerms.CFR]: {
        id: "customfields.company.enum.incoterms.CFR",
        defaultMessage: "Cost and Freight - Port of destination",
    },
    [incoTerms.CIF]: {
        id: "customfields.company.enum.incoterms.CIF",
        defaultMessage: "Cost, Insurance and Freight - Port of destination",
    },
    [incoTerms.CPT]: {
        id: "customfields.company.enum.incoterms.CPT",
        defaultMessage: "Cost Paid To - Place of destination",
    },
    [incoTerms.CIP]: {
        id: "customfields.company.enum.incoterms.CIP",
        defaultMessage: "Cost and Insurance Paid To - Place of destination",
    },
    [incoTerms.DPU]: {
        id: "customfields.company.enum.incoterms.DPU",
        defaultMessage: "Delivered at Place Unloaded - Place of destination",
    },
    [incoTerms.DAP]: {
        id: "customfields.company.enum.incoterms.DAP",
        defaultMessage: "Delivered at Place - Place of destination",
    },
    [incoTerms.DDP]: {
        id: "customfields.company.enum.incoterms.DDP",
        defaultMessage: "Delivered Duty Paid - Place of destination",
    },
})

export enum SupplierStatus {
    ACTIVE = "active",
    INACTIVE = "inactive",
    BLOCKLISTED = "blocklisted",
}

export const supplierStatusEnumMessages: Record<SupplierStatus, MessageDescriptor> = defineMessages({
    [SupplierStatus.ACTIVE]: {
        id: "customfields.company.enum.supplierStatus.active",
        defaultMessage: "Active",
    },
    [SupplierStatus.INACTIVE]: {
        id: "customfields.company.enum.supplierStatus.inactive",
        defaultMessage: "Inactive",
    },
    [SupplierStatus.BLOCKLISTED]: {
        id: "customfields.company.enum.supplierStatus.blocklisted",
        defaultMessage: "Blocklisted",
    },
})

export enum SecheCustomFields {
    IS_EXISTING_ENTITY = "isExistingEntity",
    EXISTING_ENTITY_ID = "existingEntityId",
    IS_FACTOR = "isFactor",
    FACTOR_ID = "factorId",
    ERP = "erp",
    TARGETS = "targets",
    ESTIMATED_ANUAL_EXPENSES_AMOUNT = "estimatedAnnualExpensesAmount",
    ESTIMATED_ANUAL_EXPENSES_CURRENCY = "estimatedAnnualExpensesCurrency",
    CONDITIONS_ACCEPTED_BY_SUPPLIER = "conditionAcceptedBySupplier",
    REVENUE_YEAR_N_1 = "revenueYearN1",
    REVENUE_YEAR_N_2 = "revenueYearN2",
    IFS_INTERNAL_ID = "ifsInternalId",
    X3_INTERNAL_ID = "x3InternalId",
    JUSTIFICATION_FOR_SUPPLIER_CREATION = "justificationForSupplierCreation",
    OPERATIONAL_PERFORMANCE_STATUS = "operationalPerformanceStatus",
    JUSTIFICATION_FOR_SUPPLIER_CREATION_PLACEHOLDER = "Why do you want to create this new supplier?",
    PARENT_COMPANY = "parentCompany",
}

export const secheCustomFieldsMessages: Record<SecheCustomFields, MessageDescriptor> = defineMessages({
    [SecheCustomFields.IS_EXISTING_ENTITY]: {
        id: "customfields.company.seche.isExistingEntity",
        defaultMessage: "Existing entity to be converted into a supplier?",
    },
    [SecheCustomFields.EXISTING_ENTITY_ID]: {
        id: "customfields.company.seche.existingEntityId",
        defaultMessage: "Existing entity ID",
    },
    [SecheCustomFields.IS_FACTOR]: {
        id: "customfields.company.seche.isFactor",
        defaultMessage: "Is factor?",
    },
    [SecheCustomFields.FACTOR_ID]: {
        id: "customfields.company.seche.factorId",
        defaultMessage: "ID Supplier",
    },
    [SecheCustomFields.ERP]: {
        id: "customfields.company.seche.erp",
        defaultMessage: "ERP",
    },
    [SecheCustomFields.TARGETS]: {
        id: "customfields.company.seche.targets",
        defaultMessage: "Targeted services or items",
    },
    [SecheCustomFields.ESTIMATED_ANUAL_EXPENSES_AMOUNT]: {
        id: "customfields.company.seche.estimatedAnnualExpensesAmount",
        defaultMessage: "Estimated annual expenses amount",
    },
    [SecheCustomFields.ESTIMATED_ANUAL_EXPENSES_CURRENCY]: {
        id: "customfields.company.seche.estimatedAnnualExpensesCurrency",
        defaultMessage: "Estimated annual expenses currency",
    },
    [SecheCustomFields.CONDITIONS_ACCEPTED_BY_SUPPLIER]: {
        id: "customfields.company.seche.conditionsAcceptedBySupplier",
        defaultMessage: "Group payment conditions accepted by supplier",
    },
    [SecheCustomFields.REVENUE_YEAR_N_1]: {
        id: "customfields.company.seche.revenueN1",
        defaultMessage: "Revenue N-1",
    },
    [SecheCustomFields.REVENUE_YEAR_N_2]: {
        id: "customfields.company.seche.revenueN2",
        defaultMessage: "Revenue N-2",
    },
    [SecheCustomFields.IFS_INTERNAL_ID]: {
        id: "customfields.company.seche.ifsInternalId",
        defaultMessage: "IFS Internal ID",
    },
    [SecheCustomFields.X3_INTERNAL_ID]: {
        id: "customfields.company.seche.x3InternalId",
        defaultMessage: "X3 Internal ID",
    },
    [SecheCustomFields.JUSTIFICATION_FOR_SUPPLIER_CREATION]: {
        id: "customfields.company.seche.justificationForSupplierCreation",
        defaultMessage: "Justification for supplier creation",
    },
    [SecheCustomFields.PARENT_COMPANY]: {
        id: "customfields.company.seche.parentCompany",
        defaultMessage: "Parent company",
    },
    [SecheCustomFields.JUSTIFICATION_FOR_SUPPLIER_CREATION_PLACEHOLDER]: {
        id: "customfields.company.seche.justificationForSupplierCreationPlaceholder",
        defaultMessage: "Why do you want to create this new supplier?",
    },
    [SecheCustomFields.OPERATIONAL_PERFORMANCE_STATUS]: {
        id: "customfields.company.seche.operationalPerformanceStatus",
        defaultMessage: "Operational performance status",
    },
})

export enum SecheErp {
    IFS = "IFS",
    X3 = "X3",
    IFSX3 = "IFSX3",
}

export const erpEnumMessages: Record<SecheErp, MessageDescriptor> = defineMessages({
    [SecheErp.IFS]: {
        id: "customfields.company.seche.enum.erp.ifs",
        defaultMessage: "IFS",
    },
    [SecheErp.X3]: {
        id: "customfields.company.seche.enum.erp.x3",
        defaultMessage: "X3",
    },
    [SecheErp.IFSX3]: {
        id: "customfields.company.seche.enum.erp.ifsx3",
        defaultMessage: "IFS & X3",
    },
})

export enum ConditionAcceptedBySupplier {
    YES = "Yes",
    NO = "No",
    LME = "LME",
}

export enum JustificationForSupplierCreation {
    COSTS_REDUCTION = "costsReduction",
    ORGANISATIONAL_CHANGE = "organizationalChange",
    NEW_NEED = "newNeed",
    PROXIMITY_SERVICE = "proximityService",
    SUPPLIER_INSATISFACTION = "supplierInsatisfaction",
    DURABLE_DEVELOPMENT = "durableDevelopment",
    LEGAL_OBLIGATION = "legalObligation",
}

export const conditionAcceptedBySupplierEnumMessages: Record<ConditionAcceptedBySupplier, MessageDescriptor> =
    defineMessages({
        [ConditionAcceptedBySupplier.YES]: {
            id: "customfields.company.seche.enum.conditionAcceptedBySupplier.yes",
            defaultMessage: "Yes",
        },
        [ConditionAcceptedBySupplier.NO]: {
            id: "customfields.company.seche.enum.conditionAcceptedBySupplier.no",
            defaultMessage: "No",
        },
        [ConditionAcceptedBySupplier.LME]: {
            id: "customfields.company.seche.enum.conditionAcceptedBySupplier.lme",
            defaultMessage: "LME Exception (transport, temporary work, etc...)",
        },
    })

export const justificationForSupplierCreationPlaceholderMessages: Record<
    JustificationForSupplierCreation,
    MessageDescriptor
> = defineMessages({
    [JustificationForSupplierCreation.COSTS_REDUCTION]: {
        id: "customfields.company.seche.enum.justificationForSupplierCreation.costsReduction",
        defaultMessage: "Cost reduction",
    },
    [JustificationForSupplierCreation.ORGANISATIONAL_CHANGE]: {
        id: "customfields.company.seche.enum.justificationForSupplierCreation.organizationalChange",
        defaultMessage: "Organizational change (merger, acquisition etc.)",
    },
    [JustificationForSupplierCreation.NEW_NEED]: {
        id: "customfields.company.seche.enum.justificationForSupplierCreation.newNeed",
        defaultMessage: "New need, innovation",
    },
    [JustificationForSupplierCreation.PROXIMITY_SERVICE]: {
        id: "customfields.company.seche.enum.justificationForSupplierCreation.proximityService",
        defaultMessage: "Proximity service (plumbing, craftsman etc.)",
    },
    [JustificationForSupplierCreation.SUPPLIER_INSATISFACTION]: {
        id: "customfields.company.seche.enum.justificationForSupplierCreation.supplierInsatisfaction",
        defaultMessage: "Supplier dissatisfaction (delays, quality, services etc.)",
    },
    [JustificationForSupplierCreation.DURABLE_DEVELOPMENT]: {
        id: "customfields.company.seche.enum.justificationForSupplierCreation.durableDevelopment",
        defaultMessage: "Durable development",
    },
    [JustificationForSupplierCreation.LEGAL_OBLIGATION]: {
        id: "customfields.company.seche.enum.justificationForSupplierCreation.legalObligation",
        defaultMessage: "Legal obligation or imposed by the client",
    },
})

export const currencyCodeMessages: Record<CurrencyCodes, MessageDescriptor> = Currencies.reduce(
    (acc, { code, name }) => ({
        ...acc,
        [code]: { id: `currency.${code}`, defaultMessage: `${code} - ${name}` },
    }),
    {} as Record<CurrencyCodes, MessageDescriptor>
)

export const secheRequiredContactFields = ["lastName", "firstName", "email"]

export const secheRequiredCustomFields = [
    SecheCustomFields.IS_EXISTING_ENTITY,
    SecheCustomFields.IS_FACTOR,
    SecheCustomFields.TARGETS,
    SecheCustomFields.ESTIMATED_ANUAL_EXPENSES_AMOUNT,
    SecheCustomFields.ESTIMATED_ANUAL_EXPENSES_CURRENCY,
    SecheCustomFields.CONDITIONS_ACCEPTED_BY_SUPPLIER,
    SecheCustomFields.JUSTIFICATION_FOR_SUPPLIER_CREATION,
]

export const secheConditionallyRequiredCustomFields = [
    SecheCustomFields.IS_EXISTING_ENTITY,
    SecheCustomFields.IS_FACTOR,
    SecheCustomFields.CONDITIONS_ACCEPTED_BY_SUPPLIER,
]

export const secheSupplierConditionsWarningMessages: Partial<Record<ConditionAcceptedBySupplier, MessageDescriptor>> =
    defineMessages({
        [ConditionAcceptedBySupplier.LME]: {
            id: "customfields.company.seche.enum.conditionAcceptedBySupplier.warning.lme",
            defaultMessage: "Please specify in the Targeted services or items field",
        },
        [ConditionAcceptedBySupplier.NO]: {
            id: "customfields.company.seche.enum.conditionAcceptedBySupplier.warning.no",
            defaultMessage:
                "The group's payment terms are not exempted. This point must be clarified with the supplier, with the help of a buyer if necessary, before requesting the reference of this supplier",
        },
    })

export enum ClfSatremCustomFields {
    INTERNAL_ID = "internalId",
}

export const clfSatremCustomFieldsMessages: Record<ClfSatremCustomFields, MessageDescriptor> = defineMessages({
    [ClfSatremCustomFields.INTERNAL_ID]: {
        id: "customfields.company.clfSatrem.internalId",
        defaultMessage: "Internal ID",
    },
})

const eiffageIds = Object.keys(eiffageOrgs || {})
const veoliaIds = Object.keys(veoliaOrgs || {})
export const secheIds = Object.keys(secheOrgs || {})
const clfSatremIds = Object.keys(clfSatremOrgs || {})

export const customFieldsListByOrganizationId: Record<string, string[]> = {
    ...Object.fromEntries(secheIds.map((id) => [id, Object.values(SecheCustomFields)])),
    ...Object.fromEntries(eiffageIds.map((id) => [id, Object.values(EiffageCustomFields)])),
    ...Object.fromEntries(veoliaIds.map((id) => [id, Object.values(VeoliaCustomFields)])),
    ...Object.fromEntries(clfSatremIds.map((id) => [id, Object.values(ClfSatremCustomFields)])),
}

export enum DisplayContext {
    PARTNER_PROFILE,
    PARTNER_MODAL,
    PAYMENT_METHOD_DETAILS,
    PARTNER_BUSINESS_UNIT,
}
