import { ManageInvoiceLineItem } from "~/domains/transactions/invoices-v1/types/Invoice"
import { CurrencyCodes } from "~/types"
import { InvoiceLineI } from "~/types"

export const lineItemToApiAdapter = (lineItem: Partial<InvoiceLineI>): ManageInvoiceLineItem => {
    return {
        ...lineItem,
        id: lineItem.id || "",
        description: lineItem.description || "",
        currency: lineItem.currency || CurrencyCodes.EUR,
        quantity: lineItem.quantity || 0,
        unitPrice: lineItem.unitPrice?.toString() || "0",
        priceWithoutTax: lineItem.totalExcludedTaxes?.toString() || "0",
        taxId: lineItem.taxRateId || "",
        tax: lineItem.tax || "0",
        discountRate: lineItem.discountTotal?.toString() || "0",
        parties: lineItem.parties || {},
    }
}
