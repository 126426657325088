import { Stack, TextField, Tooltip, styled } from "@mui/material"
import { ChangeEvent, FC, KeyboardEvent } from "react"
import { Plus } from "react-feather"
import { useIntl } from "react-intl"

import { IconButton } from "~/domains/orchestration/flows/components/IconButton"
import { messages } from "~/domains/orchestration/flows/locale"

interface MappingFooterProps {
    newMappingKey: string
    hasKeyError: boolean
    onNewMappingKeyChange: (event: ChangeEvent<HTMLInputElement>) => void
    onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void
    onAddMappingKey: () => void
    disabled: boolean
    isEditing: boolean
}

const AddKeyButton = styled(IconButton)(() => ({
    margin: "var(--spacing-xxs)",
}))

export const MappingFooter: FC<MappingFooterProps> = ({
    newMappingKey,
    hasKeyError,
    onNewMappingKeyChange,
    onKeyDown,
    onAddMappingKey,
    disabled,
    isEditing,
}) => {
    const { formatMessage } = useIntl()

    const showError = hasKeyError && !isEditing && newMappingKey.length > 0

    const errorMessage = showError ? formatMessage(messages.mappingConfiguration.duplicateKeyError) : undefined

    return (
        <Stack direction="row" spacing={1} alignItems="top">
            <TextField
                size="small"
                fullWidth
                placeholder={formatMessage(messages.mappingConfiguration.newMappingKeyLabel)}
                value={newMappingKey}
                onChange={onNewMappingKeyChange}
                onKeyDown={onKeyDown}
                error={showError}
                helperText={errorMessage}
            />
            <Tooltip title={formatMessage(messages.mappingConfiguration.addRowTooltip)}>
                <span>
                    <AddKeyButton size="small" onClick={onAddMappingKey} type="grey" disabled={disabled}>
                        <Plus size={16} />
                    </AddKeyButton>
                </span>
            </Tooltip>
        </Stack>
    )
}
