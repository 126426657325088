import { DollarSign } from "react-feather"

import { AddToBudgetConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { RegularNode } from "~/domains/orchestration/flows/components/nodes"
import { DEFAULT_CURRENCY } from "~/domains/orchestration/flows/constants"
import { adaptEntityTriggerProperties, adaptEventTriggerProperties } from "~/domains/orchestration/flows/core"
import {
    AddToBudgetNode as AddToBudgetNodeType,
    Node,
    NodeType,
    Trigger,
    TriggerMode,
} from "~/domains/orchestration/flows/types/"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import { CurrencyConversion, EditorNode } from "~/domains/orchestration/flows/types/Flows"
import { getAmountPath } from "~/domains/orchestration/flows/utils/editor"

import { createCommonNodeProperties } from "./commonConfig"

const addToBudgetNode = (
    trigger: Trigger,
    currencyConversion: CurrencyConversion | null
): Omit<AddToBudgetNodeType, keyof Node> => {
    const { transactionType, objectId } =
        trigger.mode === TriggerMode.EVENT
            ? adaptEventTriggerProperties(trigger.type, trigger.slug)
            : adaptEntityTriggerProperties(trigger.type, trigger.slug)

    const hasCurrencyConversion = currencyConversion !== null
    const currency = hasCurrencyConversion ? currencyConversion.targetCurrency || DEFAULT_CURRENCY : DEFAULT_CURRENCY

    return {
        amount: getAmountPath(trigger, currency) || "",
        budgetId: ``,
        transactionId: objectId,
        transactionType,
        failIfOverbudget: false,
        currency: hasCurrencyConversion ? currency : DEFAULT_CURRENCY,
        nextNode: null,
        type: NodeType.ADD_TO_BUDGET_NODE,
    }
}

const advancedFields: AllAdvancedFieldsUnion[] = [
    AllAdvancedFieldsUnion.AMOUNT,
    AllAdvancedFieldsUnion.TRANSACTION_ID,
    AllAdvancedFieldsUnion.TRANSACTION_TYPE,
    AllAdvancedFieldsUnion.CURRENCY,
]

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.ADD_TO_BUDGET_NODE) return false
    return Boolean(node.budgetId && node.transactionId && node.transactionType && node.amount && node.currency)
}

const addToBudgetConfig: Configuration<NodeType.ADD_TO_BUDGET_NODE> = {
    type: NodeType.ADD_TO_BUDGET_NODE,
    baseSlug: "add_to_budget",
    Icon: DollarSign,
    Configuration: AddToBudgetConfiguration,
    Node: RegularNode,
    advancedFields,
    validateNode,
    factory: (props: FactoryProps): AddToBudgetNodeType => ({
        ...addToBudgetNode(props.trigger, props.currencyConversion),
        ...createCommonNodeProperties(props),
        error: true,
    }),
}

export { addToBudgetConfig, advancedFields as addToBudgetAdvancedFields }
