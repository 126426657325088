import { BaseTextFieldProps, InputAdornment, TextField } from "@mui/material"
import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react"
import { Edit } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { useAppDispatch } from "~/store/hooks"
import { ocrActions } from "~/store/ocr/ocrSlice"
import { CurrencyCodes } from "~/types"

import "./EditAmount.scss"

const messages = defineMessages({
    errorNaN: {
        id: "supplier.editAmount.errorNotANumber",
        defaultMessage: "{value} is not a number",
    },
    thisValue: {
        id: "supplier.editAmount.errorNotANumber.thisValue",
        defaultMessage: "This value",
    },
})

interface EditPriceProps extends BaseTextFieldProps {
    value: number | null
    id?: string
    placeholder?: string
    handleUpdate: (newAmount: number | null) => void
    disableHelpers?: boolean
    renderer?: (value: number) => ReactNode
    currency?: CurrencyCodes
    hideLabel?: boolean
}

export function EditAmount({
    value,
    id,
    placeholder,
    handleUpdate,
    fullWidth,
    disabled,
    disableHelpers,
    renderer,
    currency = CurrencyCodes.EUR,
    hideLabel = false,
    ...props
}: EditPriceProps) {
    const dispatch = useAppDispatch()
    const { formatMessage, formatNumber } = useIntl()

    const [amount, setAmount] = useState<string>(value === null ? "" : value.toString())
    const [editAmount, setEditAmount] = useState(false)
    const previousValue = useRef<number | null>(value)

    useEffect(() => {
        setAmount(value === null ? "" : value.toString())
    }, [value, setAmount])

    const startEdition = useCallback(() => {
        if (!props["aria-readonly"]) {
            previousValue.current = value
            setEditAmount(true)
            id && showShapes(id, "number")
        }
    }, [setEditAmount, value, props["aria-readonly"]])

    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setAmount(e.target.value)
        },
        [setAmount]
    )
    useEffect(() => {
        setAmount(value === null ? "" : value.toString())
    }, [value])

    const parseValueAndUpdate = useCallback(
        (v: string) => {
            const newValue = parseFloat(v)
            if (isNaN(newValue)) {
                toast.error(
                    formatMessage(messages.errorNaN, {
                        value: v && v !== "" ? v : formatMessage(messages.thisValue),
                    })
                )
            } else {
                handleUpdate(newValue)
                setEditAmount(false)
            }
        },
        [handleUpdate, setEditAmount, formatMessage]
    )

    const handleConfirm = useCallback(
        (event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.code === "Enter") {
                event.stopPropagation()
                event.preventDefault()
                parseValueAndUpdate(amount)
                return false
            }
        },
        [parseValueAndUpdate, amount]
    )

    const handleBlur = useCallback(() => {
        parseValueAndUpdate(amount ? amount : "0")
    }, [amount, parseValueAndUpdate])

    const onMount = useCallback((ref: HTMLInputElement | null) => {
        if (ref) {
            ref.blur()
        }
    }, [])

    const showShapes = (inputName, inputType) => {
        dispatch(ocrActions.updateOcrShowShapes(true))
        dispatch(ocrActions.updateOcrCurrentInput(inputName))
        dispatch(ocrActions.updateOcrCurrentInputType(inputType))
    }
    return !editAmount ? (
        <TextField
            {...props}
            value={
                // eslint-disable-next-line no-nested-ternary
                amount
                    ? renderer
                        ? renderer(parseFloat(amount))
                        : formatNumber(parseFloat(amount), {
                              style: "currency",
                              currency,
                              currencyDisplay: "narrowSymbol",
                          })
                    : "-"
            }
            placeholder={placeholder}
            label={hideLabel ? undefined : placeholder}
            type="text"
            onFocus={startEdition}
            inputRef={onMount}
            InputProps={{
                endAdornment: disableHelpers ? undefined : <Edit style={{ marginLeft: "12px" }} />,
            }}
            fullWidth={fullWidth}
            disabled={disabled}
        />
    ) : (
        <TextField
            {...props}
            value={amount}
            autoFocus
            placeholder={placeholder}
            label={hideLabel ? undefined : placeholder}
            type="number"
            onKeyDown={handleConfirm}
            onChange={onChange}
            onBlur={handleBlur}
            InputProps={{
                endAdornment: disableHelpers ? undefined : (
                    <InputAdornment position="end">
                        {formatNumber(0, { style: "currency", currency, maximumFractionDigits: 0 }).replace("0", "")}
                    </InputAdornment>
                ),
            }}
            fullWidth={fullWidth}
            disabled={disabled}
        />
    )
}
