import { Tooltip } from "@mui/material"
import React, { useState } from "react"
import { Share2 } from "react-feather"

import { commonMessages } from "~/common-messages"
import { Button, SafeFormattedMessage } from "~/components"
import { SpiceDBObjectType } from "~/domains/identity/roles-permissions/types/SpiceDB"
import { OrganizationId } from "~/types"

import { ShareObjectPermissionsModal } from "./ShareObjectPermissionsModal"

interface ShareObjectPermissionsButtonProps {
    organizationId: OrganizationId
    objectType: SpiceDBObjectType
    objectId: string
    display?: "button" | "menu-item"
}

export const ShareObjectPermissionsButton: React.FC<ShareObjectPermissionsButtonProps> = ({
    organizationId,
    objectType,
    objectId,
    display,
}) => {
    const [shareObjectModalOpen, setShareObjectModalOpen] = useState(false)

    const showShareObjectModal = () => setShareObjectModalOpen(true)
    const hideShareObjectModal = () => setShareObjectModalOpen(false)

    return (
        <>
            {display === "button" ? (
                <Tooltip arrow title={<SafeFormattedMessage {...commonMessages.sharePermissions} />}>
                    <span>
                        <Button type="grey" onClick={showShareObjectModal}>
                            <Share2 size={18} />
                        </Button>
                    </span>
                </Tooltip>
            ) : (
                <Button type="menu-item" onClick={showShareObjectModal}>
                    <Share2 size={16} />
                    <SafeFormattedMessage {...commonMessages.sharePermissions} />
                </Button>
            )}
            {shareObjectModalOpen && (
                <ShareObjectPermissionsModal
                    open={shareObjectModalOpen}
                    close={hideShareObjectModal}
                    organizationId={organizationId}
                    objectType={objectType}
                    objectId={objectId}
                />
            )}
        </>
    )
}
