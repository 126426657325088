// FIXME: split this file
/* eslint-disable max-lines */
import { Box } from "@mui/material"
import { lazy, useCallback, useMemo, useState } from "react"
import { Check, Package, Send, Tag, Trash, X } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { generatePath, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"

import { commonMessages } from "~/common-messages"
import {
    BulkActionButton,
    BulkActions,
    ConfirmModal,
    DatatableFiltersActions,
    HeaderH1,
    Tabs,
    ViewSwitcherTab,
} from "~/components"
import { ModalBatchImport } from "~/components/ModalBatchImport/ModalBatchImport"
import { ModalResultImport } from "~/components/ModalBatchImport/ModalResultImport"
import { CheckFileTypeResult } from "~/components/UploadDocument/UploadDocument"
import { withSocketIOProvider } from "~/domains/_shared/subscriptions/components/SocketIOContext"
import { ModalAddTags } from "~/domains/analytics/tags/components/ModalAddTags"
import { useOrganizationTagGroups } from "~/domains/analytics/tags/hooks"
import { TagI, TagObjectType } from "~/domains/analytics/tags/types"
import { useUserObjectPermissionsCheckQuery } from "~/domains/identity/roles-permissions/api/spiceDbApi"
import { useFetchSharedObjects } from "~/domains/identity/roles-permissions/store/hooks/useFetchSharedObjects"
import { SpiceDBObjectType } from "~/domains/identity/roles-permissions/types/SpiceDB"
import { useTagsWithTagGroupName } from "~/domains/transactions/invoices/components/list/InvoicesList/useTagsWithTagGroupName"
import { TabLabel } from "~/domains/transactions/purchase-orders/components/POList/TabLabel"
import { Filters } from "~/domains/transactions/purchase-orders/components/list/Filters"
import {
    getApprovable,
    getClosable,
    getDeletable,
    getPOBulkActionButtons,
    getPOsByViewAndTab,
    getReceivable,
    getSendable,
    showBulkActionButtonCount,
} from "~/domains/transactions/purchase-orders/core/purchaseOrder"
import { useAddTagsToPurchaseOrders } from "~/domains/transactions/purchase-orders/hooks/useAddTagsToPurchaseOrders"
import { useApprovePurchaseOrders } from "~/domains/transactions/purchase-orders/hooks/useApprovePurchaseOrders"
import { useDeletePurchaseOrders } from "~/domains/transactions/purchase-orders/hooks/useDeletePurchaseOrders"
import { useMarkAsClosedPurchaseOrders } from "~/domains/transactions/purchase-orders/hooks/useMarkAsClosedPurchaseOrders"
import { useMarkAsReceivedPurchaseOrders } from "~/domains/transactions/purchase-orders/hooks/useMarkAsReceivedPurchaseOrders"
import { useSendPurchaseOrders } from "~/domains/transactions/purchase-orders/hooks/useSendPurchaseOrders"
import { PURCHASE_ORDERS_ROUTE, PURCHASE_ORDER_NEW_ROUTE } from "~/domains/transactions/purchase-orders/routes"
import { useFetchPurchaseOrders } from "~/domains/transactions/purchase-orders/store/hooks"
import { purchaseOrdersActions } from "~/domains/transactions/purchase-orders/store/purchaseOrdersSlice"
import { ListPurchaseOrders, PurchaseOrdersTab } from "~/domains/transactions/purchase-orders/types"
import { useImportBatchPurchaseOrder } from "~/domains/transactions/purchase-requests/store/hooks/useImportBatchPurchaseOrder"
import { useTitle } from "~/hooks"
import { selectUser } from "~/store/account/accountSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { selectCurrentOrganization, selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { AuthorizationName, ViewTypeI } from "~/types"
import { ImportBatchResponseI } from "~/types/ImportBatch"

const ListLazy = lazy(() =>
    import("~/domains/transactions/purchase-orders/components/POList/List").then(({ List }) => ({ default: List }))
)

export const purchaseOrdersMessages = defineMessages({
    title: {
        id: "purchase.orders.list.title",
        defaultMessage: "Purchase orders",
    },
    labelSupplier: {
        id: "purchase.orders.list.labelSupplier",
        defaultMessage: "From buyer",
    },
    labelBuyer: {
        id: "purchase.orders.list.labelBuyer",
        defaultMessage: "Sent to supplier",
    },
    errorWrongFileType: {
        id: "purchase.list.modalImportBatch.errorWrongFileType",
        defaultMessage: "Wrong file format: we only accept CSV and XLSX files.",
    },
    modalUploadTitle: {
        id: "purchase.list.modalImportBatch.title",
        defaultMessage: "Import Purchase Orders in batch",
    },
    modalUploadTip: {
        id: "purchase.list.modalImportBatch.uploadBox.tip",
        defaultMessage: "Supported format : csv, xlsx. No more than 2mb",
    },
    selected: { id: "purchase.requests.table.selected", defaultMessage: "selected" },
    approve: { id: "purchase.orders.list.approve", defaultMessage: "Approve" },
    send: { id: "purchase.orders.list.send", defaultMessage: "Send" },
    received: { id: "purchase.orders.list.received", defaultMessage: "Received" },
    addTags: { id: "purchase.orders.list.addTags", defaultMessage: "Add tags" },
    nothingToApprove: {
        id: "purchase.orders.list.bulk.approve.nothingToApprove",
        defaultMessage:
            'Nothing to approve. To be able to approve a purchase order, it should be in status "Awaiting your approval".',
    },
    confirmApproveTitle: {
        id: "purchase.orders.list.bulk.approve.confirmApproveTitle",
        defaultMessage: "Approve purchase orders",
    },
    confirmApproveMessage: {
        id: "purchase.orders.list.bulk.approve.confirmApproveMessage",
        defaultMessage: "Are you sure to approve {count} purchase {count, plural, =0 {} one {order} other {orders}}?",
    },
    nothingToSend: {
        id: "purchase.orders.list.bulk.send.nothingToSend",
        defaultMessage:
            "Nothing to send. To be able to send a purchase order, it must be shared with a supplier and all lines need to be approved.",
    },
    confirmSendTitle: {
        id: "purchase.orders.list.bulk.send.confirmSendTitle",
        defaultMessage: "Send purchase orders",
    },
    confirmSendMessage: {
        id: "purchase.orders.list.bulk.send.confirmSendMessage",
        defaultMessage: "Are you sure to send {count} purchase {count, plural, =0 {} one {order} other {orders}}?",
    },
    nothingToMarkAsReceived: {
        id: "purchase.orders.list.bulk.received.nothingToMarkAsReceived",
        defaultMessage:
            "Nothing to mark as received. To be able to mark as received a purchase order, it must be at least mutually accepted.",
    },
    nothingToMarkAsClosed: {
        id: "purchase.orders.list.bulk.close.nothingToMarkAsClosed",
        defaultMessage:
            "Nothing to mark as closed. To be able to mark as closed a purchase order, it must be at least open.",
    },
    confirmReceivedTitle: {
        id: "purchase.orders.list.bulk.received.confirmReceivedTitle",
        defaultMessage: "Mark purchase orders as received",
    },
    confirmReceivedMessage: {
        id: "purchase.orders.list.bulk.received.confirmReceivedMessage",
        defaultMessage:
            "Are you sure to mark as received {count} purchase {count, plural, =0 {} one {order} other {orders}}?",
    },
    confirmCloseTitle: {
        id: "purchase.orders.list.bulk.close.confirmTitle",
        defaultMessage: "Close purchase orders",
    },
    confirmCloseMessage: {
        id: "purchase.orders.list.bulk.close.confirmMessage",
        defaultMessage: "Are you sure to close {count} purchase {count, plural, =0 {} one {order} other {orders}}?",
    },
    nothingToDelete: {
        id: "purchase.orders.list.bulk.delete.nothingToDelete",
        defaultMessage: "Nothing to delete. To be able to delete a purchase order, it should not be open yet.",
    },
    confirmDeleteTitle: {
        id: "purchase.orders.list.bulk.delete.confirmTitle",
        defaultMessage: "Delete purchase orders",
    },
    confirmDeleteMessage: {
        id: "purchase.orders.list.bulk.delete.confirmMessage",
        defaultMessage: "Are you sure to delete {count} purchase {count, plural, =0 {} one {order} other {orders}}?",
    },
    nothingToAddTags: { id: "purchase.orders.list.bulk.addTags.nothingToAddTags", defaultMessage: "No tag to add" },
    createPurchaseOrder: { id: "purchase.orders.newPO", defaultMessage: "Create a single PO" },
})

const ACCEPTED_FILE_EXTENSIONS: string[] = ["csv", "xlsx"]
const NOTION_URL_BATCH_PR =
    "https://get-flowie.notion.site/Flowie-Documentation-File-Format-for-Purchase-Order-Upload-5c146e1f97ac4277baea19ff61a5d9d9?pvs=4"

export const PurchaseOrders = withSocketIOProvider(() => {
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()

    const organizationId = useAppSelector(selectCurrentOrganizationId)
    const organization = useAppSelector(selectCurrentOrganization)
    const currentUser = useAppSelector(selectUser)

    const [selectedPOs, setSelectedPOs] = useState<string[]>([])
    const [modalBatchImportVisible, setModalBatchImportVisible] = useState(false)
    const [modalResultImportVisible, setModalResultImportVisible] = useState(false)
    const [resultBatchImport, setResultBatchImport] = useState<ImportBatchResponseI | undefined>()
    const [tabValue, setTabValue] = useState<PurchaseOrdersTab>(PurchaseOrdersTab.ALL)
    const [selectedForApprove, setSelectedForApprove] = useState<ListPurchaseOrders>([])
    const [displayModalApprove, setDisplayModalApprove] = useState(false)
    const [selectedForSend, setSelectedForSend] = useState<string[]>([])
    const [displayModalSend, setDisplayModalSend] = useState(false)
    const [selectedForReceived, setSelectedForReceived] = useState<ListPurchaseOrders>([])
    const [displayModalReceived, setDisplayModalReceived] = useState(false)
    const [selectedForAddTags, setSelectedForAddTags] = useState<string[]>([])
    const [displayModalAddTags, setDisplayModalAddTags] = useState(false)
    const [selectedForClose, setSelectedForClose] = useState<ListPurchaseOrders>([])
    const [displayModalClose, setDisplayModalClose] = useState(false)
    const [selectedForDelete, setSelectedForDelete] = useState<ListPurchaseOrders>([])
    const [displayModalDelete, setDisplayModalDelete] = useState(false)

    const pageName = formatMessage(purchaseOrdersMessages.title)
    useTitle(pageName)
    const { viewType } = useParams()
    const navigate = useNavigate()
    const view = useMemo(() => viewType ?? ViewTypeI.buyer, [viewType]) as ViewTypeI

    const { tagGroups } = useOrganizationTagGroups(organizationId)
    const { importBatchPO, loading: loadingBatchImport } = useImportBatchPurchaseOrder(organizationId as string)
    const {
        purchaseOrders,
        loading: loadingPurchaseOrders,
        refetchPurchaseOrders,
    } = useFetchPurchaseOrders(view, organizationId)

    const organizationSharings = useFetchSharedObjects(organizationId)

    const { data: purchaseRequestsPermissions } = useUserObjectPermissionsCheckQuery(
        {
            userId: currentUser?.id ?? "",
            objectType: SpiceDBObjectType.PURCHASE_ORDER,
            organizationId: organizationId as string,
            authorizations: [AuthorizationName.CREATE],
        },
        { skip: !organizationId || !currentUser?.id }
    )

    const approvePurchaseOrders = useApprovePurchaseOrders()
    const sendPurchaseOrders = useSendPurchaseOrders()
    const receivedPurchaseOrders = useMarkAsReceivedPurchaseOrders()
    const closePurchaseOrders = useMarkAsClosedPurchaseOrders()
    const deletePurchaseOrders = useDeletePurchaseOrders()
    const { addTagsToPurchaseOrders } = useAddTagsToPurchaseOrders()
    const tags = useTagsWithTagGroupName(tagGroups)

    const getApproveBulkActionButton = useCallback(
        (currentTab: PurchaseOrdersTab): BulkActionButton => {
            const handleApprovePurchaseOrder = (selected: ListPurchaseOrders) => {
                if (!selected.length) {
                    return toast.error(formatMessage(purchaseOrdersMessages.nothingToApprove))
                }

                setSelectedForApprove(selected)
                setDisplayModalApprove(true)
            }

            const approvablePOs = getApprovable(selectedPOs, purchaseOrders, view)
            const approvablePOsForUser = getApprovable(selectedPOs, purchaseOrders, view, true)
            return {
                label: formatMessage(purchaseOrdersMessages.approve),
                count: showBulkActionButtonCount[currentTab] ? approvablePOs.length : undefined,
                hasPermission: approvablePOs.length === 0 || approvablePOsForUser.length > 0,
                icon: <Check size={16} />,
                onClick: () => handleApprovePurchaseOrder(approvablePOsForUser),
            }
        },
        [selectedPOs, purchaseOrders, formatMessage, view]
    )

    const getSendBulkActionButton = useCallback(
        (currentTab: PurchaseOrdersTab): BulkActionButton => {
            const handleSendPurchaseOrder = (selected: string[]) => {
                if (!selected.length) {
                    return toast.error(formatMessage(purchaseOrdersMessages.nothingToSend))
                }

                setSelectedForSend(selected)
                setDisplayModalSend(true)
            }

            const sendablePOs = getSendable(selectedPOs, purchaseOrders, view, organizationSharings)
            const sendablePOsForUser = getSendable(selectedPOs, purchaseOrders, view, organizationSharings, true)
            return {
                label: formatMessage(purchaseOrdersMessages.send),
                count: showBulkActionButtonCount[currentTab] ? sendablePOs.length : undefined,
                hasPermission: sendablePOs.length === 0 || sendablePOsForUser.length > 0,
                icon: <Send size={16} />,
                onClick: () => handleSendPurchaseOrder(sendablePOsForUser),
            }
        },
        [selectedPOs, purchaseOrders, organizationSharings, formatMessage, view]
    )

    const getReceivedBulkActionButton = useCallback(
        (currentTab: PurchaseOrdersTab): BulkActionButton => {
            const handleReceivedPurchaseOrder = (selected: ListPurchaseOrders) => {
                if (!selected.length) {
                    return toast.error(formatMessage(purchaseOrdersMessages.nothingToMarkAsReceived))
                }

                setSelectedForReceived(selected)
                setDisplayModalReceived(true)
            }

            const receivablePOs = getReceivable(selectedPOs, purchaseOrders, true)
            const receivablePOsForUser = getReceivable(selectedPOs, purchaseOrders, true)
            return {
                label: formatMessage(purchaseOrdersMessages.received),
                count: showBulkActionButtonCount[currentTab] ? receivablePOs.length : undefined,
                hasPermission: receivablePOsForUser.length > 0,
                icon: <Package size={16} />,
                onClick: () => handleReceivedPurchaseOrder(receivablePOsForUser),
            }
        },
        [selectedPOs, purchaseOrders, formatMessage]
    )

    const getCloseBulkActionButton = useCallback(
        (currentTab: PurchaseOrdersTab): BulkActionButton => {
            const handleClosePurchaseOrder = (selected: ListPurchaseOrders) => {
                if (!selected.length) {
                    return toast.error(formatMessage(purchaseOrdersMessages.nothingToMarkAsClosed))
                }

                setSelectedForClose(selected)
                setDisplayModalClose(true)
            }

            const closablePOs = getClosable(selectedPOs, purchaseOrders, true)
            const closablePOsForUser = getClosable(selectedPOs, purchaseOrders, true)
            return {
                label: formatMessage(commonMessages.close),
                count: showBulkActionButtonCount[currentTab] ? closablePOs.length : undefined,
                hasPermission: closablePOsForUser.length > 0,
                icon: <X size={16} />,
                onClick: () => handleClosePurchaseOrder(closablePOsForUser),
            }
        },
        [selectedPOs, purchaseOrders, formatMessage]
    )

    const getDeleteBulkActionButton = useCallback(
        (currentTab: PurchaseOrdersTab): BulkActionButton => {
            const handleDeletePurchaseOrder = (selected: ListPurchaseOrders) => {
                if (!selected.length) {
                    return toast.error(formatMessage(purchaseOrdersMessages.nothingToDelete))
                }

                setSelectedForDelete(selected)
                setDisplayModalDelete(true)
            }

            const deletablePOs = getDeletable(selectedPOs, purchaseOrders, true)
            const deletablePOsForUser = getDeletable(selectedPOs, purchaseOrders, true)
            return {
                label: formatMessage(commonMessages.delete),
                count: showBulkActionButtonCount[currentTab] ? deletablePOs.length : undefined,
                hasPermission: deletablePOsForUser.length > 0,
                icon: <Trash size={16} />,
                type: "error-light",
                onClick: () => handleDeletePurchaseOrder(deletablePOsForUser),
            }
        },
        [selectedPOs, purchaseOrders, formatMessage]
    )

    const getAddTagsBulkActionButton = useCallback(
        (currentTab: PurchaseOrdersTab): BulkActionButton => {
            const handleAddTagsPurchaseOrder = (selected: string[]) => {
                if (!selected.length) {
                    return toast.error(formatMessage(purchaseOrdersMessages.nothingToAddTags))
                }

                setSelectedForAddTags(selected)
                setDisplayModalAddTags(true)
            }

            return {
                label: formatMessage(purchaseOrdersMessages.addTags),
                count: showBulkActionButtonCount[currentTab] ? selectedPOs.length : undefined,
                icon: <Tag size={16} />,
                onClick: () => handleAddTagsPurchaseOrder(selectedPOs),
            }
        },
        [selectedPOs, formatMessage]
    )

    const bulkActionButtons = useMemo(() => {
        return getPOBulkActionButtons({
            currentTab: tabValue,
            view,
            handleValidate: getApproveBulkActionButton,
            handleSend: getSendBulkActionButton,
            handleReceived: getReceivedBulkActionButton,
            handleAddTags: getAddTagsBulkActionButton,
            handleClose: getCloseBulkActionButton,
            handleDelete: getDeleteBulkActionButton,
        })
    }, [
        formatMessage,
        selectedPOs,
        purchaseOrders,
        tabValue,
        view,
        getApproveBulkActionButton,
        getSendBulkActionButton,
        getReceivedBulkActionButton,
        getAddTagsBulkActionButton,
        getCloseBulkActionButton,
        getDeleteBulkActionButton,
    ])

    const handleSelectedPR = (rowSelectionIds: string[]) => setSelectedPOs(rowSelectionIds)

    const handleFile = useCallback(
        async (file: File) => {
            try {
                const result: ImportBatchResponseI = await importBatchPO(file)
                setResultBatchImport(result)
                closeModalBatchImport()
                showResultImportModal()
                await refetchPurchaseOrders()
            } catch (error) {
                console.error(error)
            }
        },
        [importBatchPO, refetchPurchaseOrders]
    )

    const checkFileType = useCallback(
        (file: File): CheckFileTypeResult => {
            const fileExtension = file.name.split(".").pop()
            if (fileExtension && ACCEPTED_FILE_EXTENSIONS.includes(fileExtension)) {
                return { result: true, error: null }
            }

            return { result: false, error: formatMessage(purchaseOrdersMessages.errorWrongFileType) }
        },
        [formatMessage]
    )

    const handleClickInstructions = () => {
        window.open(NOTION_URL_BATCH_PR, "_blank")
    }

    const handleChangeTab = (newValue: number | string) => {
        setTabValue(newValue as PurchaseOrdersTab)
        clearSelection()
        dispatch(purchaseOrdersActions.setCurrentPurchaseOrdersTab(newValue as PurchaseOrdersTab))
    }

    const handleConfirmModalApprove = useCallback(async () => {
        if (organizationId) {
            setDisplayModalApprove(false)
            await approvePurchaseOrders(selectedForApprove, organizationId)
            clearSelection()
            setSelectedForApprove([])
            return true
        }
        return false
    }, [selectedForApprove, organizationId, approvePurchaseOrders])

    const handleConfirmModalSend = useCallback(async () => {
        if (organizationId) {
            setDisplayModalSend(false)
            await sendPurchaseOrders(selectedForSend, organizationId)
            clearSelection()
            setSelectedForSend([])
            return true
        }
        return false
    }, [selectedForSend, organizationId, sendPurchaseOrders])

    const handleConfirmModalReceived = useCallback(async () => {
        if (organizationId) {
            setDisplayModalReceived(false)
            await receivedPurchaseOrders(selectedForReceived, organizationId)
            clearSelection()
            setSelectedForReceived([])
            return true
        }
        return false
    }, [selectedForReceived, organizationId, receivedPurchaseOrders])

    const handleConfirmModalClose = useCallback(async () => {
        if (organizationId) {
            setDisplayModalClose(false)
            await closePurchaseOrders(selectedForClose, organizationId)
            clearSelection()
            setSelectedForClose([])
            return true
        }
        return false
    }, [selectedForClose, organizationId, closePurchaseOrders])

    const handleConfirmModalDelete = useCallback(async () => {
        if (organizationId) {
            setDisplayModalDelete(false)
            await deletePurchaseOrders(selectedForDelete, organizationId)
            clearSelection()
            setSelectedForDelete([])
            return true
        }
        return false
    }, [selectedForDelete, organizationId, deletePurchaseOrders])

    const handleConfirmModalAddTags = useCallback(
        async (tagsToAdd: TagI[], tagsToRemove: TagI[]) => {
            if (organizationId) {
                setDisplayModalAddTags(false)
                await addTagsToPurchaseOrders(
                    organizationId,
                    selectedForAddTags,
                    TagObjectType.PURCHASE_ORDER,
                    purchaseOrders,
                    tagsToAdd,
                    tagsToRemove
                )
                clearSelection()
                setSelectedForAddTags([])
                return true
            }
            return false
        },
        [selectedForAddTags, organizationId, purchaseOrders, addTagsToPurchaseOrders]
    )

    const handleHideModalApprove = () => setDisplayModalApprove(false)
    const handleHideModalSend = () => setDisplayModalSend(false)
    const handleHideModalReceived = () => setDisplayModalReceived(false)
    const handleHideModalClose = () => setDisplayModalClose(false)
    const handleHideModalDelete = () => setDisplayModalDelete(false)
    const closeModalBatchImport = () => setModalBatchImportVisible(false)
    const showResultImportModal = () => setModalResultImportVisible(true)
    const closeModalResultImport = () => setModalResultImportVisible(false)
    const onClickBatchImport = () => setModalBatchImportVisible(true)
    const clearSelection = () => setSelectedPOs([])

    const onClickNewDraftPo = () => navigate(PURCHASE_ORDER_NEW_ROUTE)
    const actions: DatatableFiltersActions[] = [
        { label: formatMessage(purchaseOrdersMessages.createPurchaseOrder), action: onClickNewDraftPo },
        { label: formatMessage(commonMessages.batchImport), action: onClickBatchImport },
    ]

    const handleSwitchViewToBuyers = () => navigate(generatePath(PURCHASE_ORDERS_ROUTE, { viewType: ViewTypeI.buyer }))
    const handleSwitchViewToSuppliers = () =>
        navigate(generatePath(PURCHASE_ORDERS_ROUTE, { viewType: ViewTypeI.supplier }))

    return (
        <>
            <HeaderH1 title={pageName} />
            <Box className="main-box">
                {organizationId && (
                    <>
                        <ViewSwitcherTab
                            view={view}
                            labelSupplier={formatMessage(purchaseOrdersMessages.labelSupplier)}
                            labelBuyer={formatMessage(purchaseOrdersMessages.labelBuyer)}
                            actionBuyer={handleSwitchViewToBuyers}
                            actionSupplier={handleSwitchViewToSuppliers}
                        />

                        <Filters actions={actions} hasPermission={purchaseRequestsPermissions?.create} />

                        <Tabs
                            defaultValue={tabValue}
                            setChange={handleChangeTab}
                            tabs={Object.keys(PurchaseOrdersTab).map((tab) => {
                                const tabKey = tab as PurchaseOrdersTab

                                return {
                                    label: <TabLabel purchaseOrders={purchaseOrders} tab={tabKey} />,
                                    value: tabKey,
                                    component: (
                                        <ListLazy
                                            purchaseOrders={getPOsByViewAndTab(tabKey, purchaseOrders)}
                                            organizationId={organizationId}
                                            view={view}
                                            tags={tags}
                                            rowSelectionModel={selectedPOs}
                                            handleSelectedRows={handleSelectedPR}
                                            loading={loadingPurchaseOrders}
                                        />
                                    ),
                                }
                            })}
                        />
                    </>
                )}
                <ModalBatchImport
                    open={modalBatchImportVisible}
                    close={closeModalBatchImport}
                    handleFile={handleFile}
                    checkFileType={checkFileType}
                    handleClickInstructions={handleClickInstructions}
                    loading={loadingBatchImport}
                    uploadTip={purchaseOrdersMessages.modalUploadTip}
                    title={purchaseOrdersMessages.modalUploadTitle}
                />
                <ModalResultImport
                    open={modalResultImportVisible}
                    close={closeModalResultImport}
                    showNewImportModal={onClickBatchImport}
                    resultBatchImport={resultBatchImport}
                    title={purchaseOrdersMessages.modalUploadTitle}
                />
                <ConfirmModal
                    title={formatMessage(purchaseOrdersMessages.confirmApproveTitle)}
                    open={displayModalApprove}
                    close={handleHideModalApprove}
                    onConfirm={handleConfirmModalApprove}
                    confirmButtonType="primary"
                >
                    {formatMessage(purchaseOrdersMessages.confirmApproveMessage, {
                        count: selectedForApprove.length,
                    })}
                </ConfirmModal>
                <ConfirmModal
                    title={formatMessage(purchaseOrdersMessages.confirmSendTitle)}
                    open={displayModalSend}
                    close={handleHideModalSend}
                    onConfirm={handleConfirmModalSend}
                    confirmButtonType="primary"
                >
                    {formatMessage(purchaseOrdersMessages.confirmSendMessage, {
                        count: selectedForSend.length,
                    })}
                </ConfirmModal>
                <ConfirmModal
                    title={formatMessage(purchaseOrdersMessages.confirmReceivedTitle)}
                    open={displayModalReceived}
                    close={handleHideModalReceived}
                    onConfirm={handleConfirmModalReceived}
                    confirmButtonType="primary"
                >
                    {formatMessage(purchaseOrdersMessages.confirmReceivedMessage, {
                        count: selectedForReceived.length,
                    })}
                </ConfirmModal>
                <ConfirmModal
                    title={formatMessage(purchaseOrdersMessages.confirmCloseTitle)}
                    open={displayModalClose}
                    close={handleHideModalClose}
                    onConfirm={handleConfirmModalClose}
                    confirmButtonType="primary"
                >
                    {formatMessage(purchaseOrdersMessages.confirmCloseMessage, {
                        count: selectedForClose.length,
                    })}
                </ConfirmModal>
                <ConfirmModal
                    title={formatMessage(purchaseOrdersMessages.confirmDeleteTitle)}
                    open={displayModalDelete}
                    close={handleHideModalDelete}
                    onConfirm={handleConfirmModalDelete}
                    confirmButtonType="error-light"
                >
                    {formatMessage(purchaseOrdersMessages.confirmDeleteMessage, {
                        count: selectedForDelete.length,
                    })}
                </ConfirmModal>
                {organization && (
                    <ModalAddTags
                        organization={organization}
                        selected={selectedForAddTags}
                        objects={purchaseOrders}
                        displayModal={displayModalAddTags}
                        setDisplayModal={setDisplayModalAddTags}
                        onConfirm={handleConfirmModalAddTags}
                    />
                )}
                {selectedPOs.length > 0 && (
                    <BulkActions
                        selected={selectedPOs}
                        onClose={clearSelection}
                        buttons={bulkActionButtons}
                        selectedMessage={formatMessage(purchaseOrdersMessages.selected, {
                            s: selectedPOs.length > 1 ? "s" : "",
                        })}
                    />
                )}
            </Box>
        </>
    )
})
