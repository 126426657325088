import { Link2 } from "react-feather"

import { AssignDocumentToObjectConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { RegularNode } from "~/domains/orchestration/flows/components/nodes"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    AssignDocumentToObjectNode as AssignDocumentToObjectNodeType,
    EditorNode,
    Node,
    NodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const assignDocumentToObjectNode: Omit<AssignDocumentToObjectNodeType, keyof Node> = {
    documentId: "",
    objectId: "",
    type: NodeType.ASSIGN_DOCUMENT_TO_OBJECT_NODE,
    nextNode: null,
}

const advancedFields: (keyof typeof assignDocumentToObjectNode)[] = [
    AllAdvancedFieldsUnion.OBJECT_ID,
    AllAdvancedFieldsUnion.DOCUMENT_ID,
]

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.ASSIGN_DOCUMENT_TO_OBJECT_NODE) return false
    return Boolean(node.documentId && node.objectId)
}

const assignDocumentToObjectConfig: Configuration<NodeType.ASSIGN_DOCUMENT_TO_OBJECT_NODE> = {
    type: NodeType.ASSIGN_DOCUMENT_TO_OBJECT_NODE,
    baseSlug: "assign-document-to-object",
    Icon: Link2,
    Configuration: AssignDocumentToObjectConfiguration,
    advancedFields,
    Node: RegularNode,
    factory: (props: FactoryProps): AssignDocumentToObjectNodeType => ({
        ...assignDocumentToObjectNode,
        ...createCommonNodeProperties(props),
    }),
    validateNode,
}

export { assignDocumentToObjectConfig, advancedFields as assignDocumentToObjectAdvancedFields }
