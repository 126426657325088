import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { defineMessages } from "react-intl"

import { prepareHeadersWithAuthorization as prepareHeaders } from "~/api/prepareHeaders"
import { ExtendedFetchBaseQueryError, taggedBaseQuery } from "~/api/rtkFetchBaseExtend"
import { OrganizationId } from "~/types"

const errors = defineMessages({
    errorUpload: {
        id: "common.error.errorUploadingDocument",
        defaultMessage: "An error occurred while uploading the document.",
    },
})

const BASE_URL = import.meta.env.VITE_API_DOCUMENT_INFO_EXTRACT_URL as string

export const processDocumentApi = createApi({
    reducerPath: "processDocumentApi",
    baseQuery: taggedBaseQuery({
        baseQueryFn: fetchBaseQuery({
            baseUrl: BASE_URL,
            prepareHeaders,
        }),
        domain: "ai",
        subdomain: "document-process",
    }),
    endpoints: (builder) => ({
        processDocument: builder.mutation<any, { file: File; organizationId: OrganizationId }>({
            query: ({ file, organizationId }) => {
                const formData = new FormData()
                formData.append("files", file)
                formData.append("data", JSON.stringify({ organizationId: organizationId ?? "" }))

                return {
                    url: "process_files",
                    method: "POST",
                    body: formData,
                }
            },
            transformErrorResponse: (response: ExtendedFetchBaseQueryError) => ({
                ...response,
                meta: {
                    ...response.meta,
                    errorMessage: errors.errorUpload,
                },
            }),
        }),
    }),
})

export const { useProcessDocumentMutation } = processDocumentApi
