import * as Sentry from "@sentry/react"
import { useCallback } from "react"
import { defineMessages, useIntl } from "react-intl"
import { useDispatch } from "react-redux"

import { purchaseOrdersApi } from "~/domains/transactions/purchase-orders/api"
import { enrichPurchaseOrdersWithNames } from "~/domains/transactions/purchase-orders/core/purchaseOrder"
import {
    purchaseOrdersActions,
    selectPurchaseOrders,
    selectPurchaseOrdersError,
    selectPurchaseOrdersLoading,
} from "~/domains/transactions/purchase-orders/store/purchaseOrdersSlice"
import { useAppSelector } from "~/store/hooks"
import { OrganizationId, ViewTypeI } from "~/types"

const messages = defineMessages({
    error: {
        id: "purchase.orders.list.errorFetch",
        defaultMessage: "We can't retrieve the purchase orders. Please contact your administrator",
    },
})

export const useFetchOrganizationPurchaseOrders = (view?: ViewTypeI, organizationId?: OrganizationId) => {
    const dispatch = useDispatch()
    const { formatMessage } = useIntl()
    const purchaseOrders = useAppSelector(selectPurchaseOrders)
    const loading = useAppSelector(selectPurchaseOrdersLoading)
    const error = useAppSelector(selectPurchaseOrdersError)

    const fetchPurchaseOrders = useCallback(
        async (withNames: boolean = true) => {
            if (!organizationId) return

            try {
                dispatch(purchaseOrdersActions.fetchPOs())
                const POs = await purchaseOrdersApi.findAll(organizationId)

                if (withNames) {
                    const POWithNames = await enrichPurchaseOrdersWithNames(POs)

                    // Filter purchase orders based on view
                    const POWithNamesFiltered = view
                        ? POWithNames.filter((po) => {
                              if (view === ViewTypeI.supplier) {
                                  return po.supplierId === organizationId
                              }
                              return po.buyerId === organizationId
                          })
                        : POWithNames

                    dispatch(purchaseOrdersActions.fetchPOsSuccess(POWithNamesFiltered))
                    return POWithNamesFiltered
                } else {
                    const POWithFakeNames = POs.map((po) => {
                        return {
                            ...po,
                            supplierName: "",
                            buyerName: "",
                            requesterName: "",
                        }
                    })

                    dispatch(purchaseOrdersActions.fetchPOsSuccess(POWithFakeNames))
                    return POWithFakeNames
                }
            } catch (e) {
                Sentry.captureException(e, { extra: { organizationId, view } })
                dispatch(purchaseOrdersActions.fetchPOsFailed(formatMessage(messages.error)))
            }
        },
        [organizationId, view, dispatch, formatMessage]
    )

    return { purchaseOrders, loading, error, fetchPurchaseOrders }
}
