import classnames from "classnames"
import React, { AriaAttributes, CSSProperties, forwardRef, useEffect, useRef, useState } from "react"

import "./Button.scss"

export { ButtonGroup } from "./ButtonGroup"
export type ButtonType =
    | "default"
    | "primary"
    | "primary-light"
    | "secondary"
    | "tertiary"
    | "tab"
    | "neutral"
    | "error"
    | "error-light"
    | "success"
    | "success-light"
    | "title"
    | "grey"
    | "grey-light"
    | "warning"
    | "warning-light"
    | "menu-item"
    | "close"
    | "transparent"
    | "split-button"
    | "split-button-caret"
type ButtonProps = React.PropsWithChildren &
    AriaAttributes & {
        onClick?: React.MouseEventHandler<HTMLButtonElement>
        text?: string | React.ReactNode
        type?: ButtonType
        className?: string
        disabled?: boolean
        rightIcon?: string
        buttonType?: "button" | "submit" | "reset"
        form?: string
        disableDelay?: number // New prop to control delay duration
        size?: "x-small" | "small" | "medium" | "large"
        readOnly?: boolean
        sx?: CSSProperties
        iconOnly?: boolean
        block?: boolean
    }

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            children,
            text,
            onClick,
            type = "primary",
            className,
            disabled,
            buttonType = "button",
            form,
            disableDelay = 300,
            size = "medium",
            readOnly,
            sx,
            iconOnly,
            block = false,
            ...ariaProps
        },
        ref
    ) => {
        const [isClicked, setIsClicked] = useState(false)
        const clickTimeoutRef = useRef<number | null>(null)

        const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
            if (isClicked) return

            setIsClicked(true)
            if (onClick) {
                onClick(event)
            }

            clickTimeoutRef.current = window.setTimeout(() => {
                setIsClicked(false)
            }, disableDelay)
        }

        useEffect(() => {
            return () => {
                if (clickTimeoutRef.current) {
                    clearTimeout(clickTimeoutRef.current)
                }
            }
        }, [])

        const classes = classnames("flowie-button", className, {
            [`type-${type}`]: !!type,
            [`size-${size}`]: !!size,
            "cursor-default": readOnly,
            "icon-only": iconOnly,
            "w-full": block,
        })

        return (
            <button
                ref={ref}
                onClick={onClick ? handleClick : undefined}
                className={classes}
                disabled={disabled || isClicked}
                type={buttonType}
                form={form}
                style={sx}
                {...ariaProps}
            >
                {text || children}
            </button>
        )
    }
)

Button.displayName = "Button"
