import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorizationAndOrganizationId as prepareHeaders } from "~/api/prepareHeaders"
import { taggedBaseQuery } from "~/api/rtkFetchBaseExtend"
import {
    adaptSharedObjectFromApi,
    adaptSharedObjectsFromApi,
} from "~/domains/identity/roles-permissions/api/adapters/sharesAdapters"
import {
    DeleteResult,
    RemoveSharedObjectMutation,
    ShareObjectMutation,
} from "~/domains/identity/roles-permissions/types/RolesPermissions"
import { OrganizationId } from "~/types"
import { SharedObject } from "~/types/SharedObjects"

const BASE_URL = import.meta.env.VITE_API_ROLES_PERMISSIONS_URL
const tags = ["Shares"]

export const sharesApi = createApi({
    reducerPath: "sharesApi",
    baseQuery: taggedBaseQuery({
        baseQueryFn: fetchBaseQuery({
            baseUrl: BASE_URL,
            prepareHeaders,
        }),
        domain: "identity",
        subdomain: "shares",
    }),
    tagTypes: tags,
    endpoints: (builder) => ({
        getAllSharedObjects: builder.query<SharedObject[], OrganizationId>({
            query: (organizationId: OrganizationId) => ({
                url: `organizations/${organizationId}/shares`,
            }),
            providesTags: tags,
            transformResponse: (response: SharedObject[]) => adaptSharedObjectsFromApi(response),
        }),
        shareObject: builder.mutation<SharedObject, ShareObjectMutation>({
            query: (body: ShareObjectMutation) => ({
                url: `organizations/${body.organizationId}/shares`,
                method: "POST",
                body,
            }),
            invalidatesTags: tags,
            transformResponse: (response: SharedObject) => adaptSharedObjectFromApi(response),
        }),
        removeSharedObject: builder.mutation<DeleteResult, RemoveSharedObjectMutation>({
            query: ({ organizationId, sharingId: sharedObjectId }) => ({
                url: `organizations/${organizationId}/shares/${sharedObjectId}`,
                method: "DELETE",
            }),
            invalidatesTags: tags,
        }),
    }),
})

export const { useGetAllSharedObjectsQuery, useShareObjectMutation, useRemoveSharedObjectMutation } = sharesApi
