import * as Sentry from "@sentry/react"
import { useEffect, useState } from "react"

/**
 * A hook to persist state in localStorage
 * @template T The type of the stored value
 * @param {string} key The localStorage key to store the value under
 * @param {T} defaultValue The default value to use if no value is stored
 * @returns {[T, React.Dispatch<React.SetStateAction<T>>]} A tuple containing the current value and a setter function
 */
const useLocalStorage = <T>(key: string, defaultValue: T): [T, React.Dispatch<React.SetStateAction<T>>] => {
    // Check if localStorage is available
    const isLocalStorageAvailable = () => typeof window !== "undefined" && window.localStorage !== null

    const [value, setValue] = useState<T>(() => {
        if (!isLocalStorageAvailable()) {
            return defaultValue
        }

        try {
            const item = localStorage.getItem(key)
            return item ? JSON.parse(item) : defaultValue
        } catch (error) {
            Sentry.captureException(error)
            return defaultValue
        }
    })

    useEffect(() => {
        if (!isLocalStorageAvailable()) {
            return
        }

        try {
            localStorage.setItem(key, JSON.stringify(value))
        } catch (error) {
            Sentry.captureException(error)
        }
    }, [value, key])

    return [value, setValue]
}

export default useLocalStorage
