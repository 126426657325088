import { GridFilterModel, GridSortModel } from "@mui/x-data-grid-premium"

import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "~/utils"

export const initialState = {
    columns: {
        columnVisibilityModel: {
            id: false,
        },
    },
    pagination: {
        paginationModel: {
            page: 0,
            pageSize: DEFAULT_PAGE_SIZE,
        },
        pageSizeOptions: PAGE_SIZE_OPTIONS,
    },
}

export const sortModel: GridSortModel = [
    {
        field: "creationDate",
        sort: "desc",
    },
]

export const filterModel: GridFilterModel = {
    items: [],
}
