import { Grid } from "@mui/material"
import React, { Dispatch, SetStateAction, useCallback, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { ConfirmModal, ItemLabel, SafeFormattedMessage } from "~/components"
import DocumentChip from "~/components/UploadDocument/DocumentChip/DocumentChip"
import { documentTypeMessages } from "~/components/UploadDocument/UploadDocument"
import { DocumentDataI, DraftDocumentI } from "~/components/UploadDocument/core/Documents"
import { useDeleteDocument } from "~/components/UploadDocument/hooks/useDeleteDocument"

import "./Documents.scss"

const messages = defineMessages({
    noFiles: {
        id: "documents.filesList.noFiles",
        defaultMessage: "No document attached",
    },
    delete: { id: "common.menuAction.actions.delete", defaultMessage: "Delete" },
    update: { id: "common.menuAction.actions.update", defaultMessage: "Edit" },
    confirmDeleteTitle: {
        id: "documents.filesList.deleteDocument",
        defaultMessage: "Delete a document",
    },
    confirmDeleteMessage: {
        id: "documents.filesList.confirmDeleteMessage",
        defaultMessage: "Are you sure to delete {name}? Any deletion is permanent.",
    },
    downloadError: {
        id: "documents.filesList.downloadError",
        defaultMessage: "Couldn't download the file, an issue occurred",
    },
})

interface DocumentsListProps {
    documents: DocumentDataI[] | DraftDocumentI[] | undefined
    setDraftDocuments?: Dispatch<SetStateAction<DraftDocumentI[]>>
    readonly?: boolean
}

export const DocumentsList: React.FC<DocumentsListProps> = ({ documents, setDraftDocuments, readonly = false }) => {
    const { formatMessage } = useIntl()
    const [currentDocument, setCurrentDocument] = useState<DocumentDataI | DraftDocumentI | undefined>()
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const { deleteDocument } = useDeleteDocument()

    const handleDownload = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        document: DocumentDataI | DraftDocumentI
    ) => {
        event.preventDefault()
        if ("latestFileVersion" in document && document.latestFileVersion) {
            window.open(document.latestFileVersion, "_blank")
        } else if ("file" in document && document.file) {
            const fileUrl = URL.createObjectURL(document.file)
            const newWindow = window.open(fileUrl, "_blank")
            if (newWindow) {
                newWindow.addEventListener(
                    "load",
                    () => {
                        URL.revokeObjectURL(fileUrl)
                    },
                    { once: true }
                )
            }
        } else {
            toast.error(formatMessage(messages.downloadError))
        }
    }

    const displayConfirmModal = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        document: DocumentDataI | DraftDocumentI
    ) => {
        event.preventDefault()
        setCurrentDocument(document)
        setShowConfirmModal(true)
    }

    const hideConfirmModal = () => setShowConfirmModal(false)

    const handleDeleteDocument = useCallback(() => {
        if (currentDocument && "id" in currentDocument && currentDocument?.id) {
            deleteDocument(currentDocument.id)
            setCurrentDocument(undefined)
            return true
        } else if (currentDocument && "file" in currentDocument && currentDocument?.file && setDraftDocuments) {
            setDraftDocuments((docs) => docs?.filter((doc) => doc !== currentDocument))
            setCurrentDocument(undefined)
            return true
        }
        setCurrentDocument(undefined)
        return false
    }, [currentDocument, deleteDocument, setDraftDocuments])

    return (
        <>
            {documents && documents.length ? (
                <>
                    <Grid container>
                        {documents.map((document: DraftDocumentI | DocumentDataI) => (
                            <Grid item key={document.documentType}>
                                <DocumentChip
                                    type={formatMessage(documentTypeMessages[document.documentType])}
                                    name={document.name}
                                    onClick={(e) => handleDownload(e, document)}
                                    onDelete={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                                        displayConfirmModal(e, document)
                                    }
                                    readonly={readonly}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <ConfirmModal
                        title={<SafeFormattedMessage {...messages.confirmDeleteTitle} />}
                        open={showConfirmModal}
                        close={hideConfirmModal}
                        onConfirm={handleDeleteDocument}
                    >
                        <SafeFormattedMessage
                            {...messages.confirmDeleteMessage}
                            values={{ name: currentDocument?.name }}
                        />
                    </ConfirmModal>
                </>
            ) : (
                <ItemLabel>
                    <SafeFormattedMessage {...messages.noFiles} />
                </ItemLabel>
            )}
        </>
    )
}
