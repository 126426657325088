import { MessageDescriptor, defineMessages } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"

import { SafeFormattedMessage, StatusChip } from "~/components"
import { DateComponent } from "~/components/Date"
import { DashboardEmptyState } from "~/domains/analytics/dashboard/components/DashboardEmptyState"
import { DashboardListItem } from "~/domains/analytics/dashboard/components/DashboardListItem"
import { DashboardTabCount } from "~/domains/analytics/dashboard/components/DashboardTabCount"
import { dashboardCommonMessages } from "~/domains/analytics/dashboard/dashboardCommonMessages"
import {
    DocumentForApproval,
    isInvoiceforApproval,
    isPurchaseOrderforApproval,
    isPurchaseRequestforApproval,
} from "~/domains/analytics/dashboard/types/DocumentForApproval"
import { INVOICE_BUYER_ROUTE } from "~/domains/transactions/invoices/_views/buyer/routes"
import { PURCHASE_ORDER_ROUTE } from "~/domains/transactions/purchase-orders/routes"
import { PURCHASE_REQUEST_ROUTE } from "~/domains/transactions/purchase-requests/routes"

const messages = defineMessages({
    purchaseOrder: {
        id: "purchase.orders.order.htmlTitle",
        defaultMessage: "Purchase order",
    },
    invoice: {
        id: "documents.documentTypes.invoice",
        defaultMessage: "Invoice",
    },
    approvalsAll: {
        id: "dashboard.approvals.tab.all",
        defaultMessage: "All",
    },
    approvalsPRs: {
        id: "dashboard.approvals.tab.prs",
        defaultMessage: "Purchase requests",
    },
    approvalsPOs: {
        id: "dashboard.approvals.tab.pos",
        defaultMessage: "Purchase orders",
    },
    approvalsInvoices: {
        id: "dashboard.approvals.tab.invoices",
        defaultMessage: "Invoices",
    },
})

// Number of items to render
const LIST_ITEMS_LIMIT = 20

const createSupplementary = (message: MessageDescriptor, date: string | null | undefined) => (
    <>
        <SafeFormattedMessage {...message} /> -
        <DateComponent className="dashboard-list-item-date" value={date} />
    </>
)

export const useDashboardApprovalTabs = (approvals: DocumentForApproval[]) => {
    const navigate = useNavigate()
    const allApprovals = approvals.slice(0, LIST_ITEMS_LIMIT)

    // filter the approvals based on their type
    const approvalsPRs = approvals.filter(isPurchaseRequestforApproval).slice(0, LIST_ITEMS_LIMIT)
    const approvalsPOs = approvals.filter(isPurchaseOrderforApproval).slice(0, LIST_ITEMS_LIMIT)
    const approvalsInvoices = approvals.filter(isInvoiceforApproval).slice(0, LIST_ITEMS_LIMIT)

    /**
     * Renders the list item for approvals
     * @param approval
     * @returns
     */
    const renderApprovalListItem = (approval: DocumentForApproval) => {
        let approvalSupplementary: JSX.Element | null = null
        let approvalLink: string
        let key: string = ""

        if (isPurchaseRequestforApproval(approval)) {
            key = approval.document.id
            approvalSupplementary = createSupplementary(
                dashboardCommonMessages.purchaseRequest,
                approval.document.submittedDate
            )
            approvalLink = generatePath(PURCHASE_REQUEST_ROUTE, {
                purchaseRequestId: approval.document.id,
            })
        } else if (isPurchaseOrderforApproval(approval)) {
            key = approval.document.id
            approvalSupplementary = createSupplementary(messages.purchaseOrder, approval.document.creationDate)
            approvalLink = generatePath(PURCHASE_ORDER_ROUTE, { purchaseOrderId: approval.document.id })
        } else {
            key = approval.document.invoiceId
            approvalSupplementary = createSupplementary(messages.invoice, approval.document.updateDate)
            approvalLink = generatePath(INVOICE_BUYER_ROUTE, { invoiceId: approval.document.invoiceId })
        }

        const description = approval.document.description
            ? `${approval.document.description}${approval.document.supplierName ? " - " : ""}${approval.document.supplierName || ""}`
            : approval.document.supplierName || ""

        const itemOnClick = () => {
            navigate(approvalLink)
        }

        return (
            <DashboardListItem
                key={key}
                onClick={itemOnClick}
                firstLine={description}
                secondLine={approvalSupplementary}
                status={
                    <>
                        <StatusChip status={approval.document.status}>{approval.document.status}</StatusChip>
                    </>
                }
            />
        )
    }

    return [
        {
            label: (
                <div>
                    <SafeFormattedMessage {...messages.approvalsAll} />
                    <DashboardTabCount count={allApprovals.length} />
                </div>
            ),
            value: "All",
            component: <>{allApprovals.map(renderApprovalListItem)}</>,
        },
        {
            label: (
                <div>
                    <SafeFormattedMessage {...messages.approvalsPRs} />
                    <DashboardTabCount count={approvalsPRs.length} />
                </div>
            ),
            value: "Purchase requests",
            component: approvalsPRs.length ? <>{approvalsPRs.map(renderApprovalListItem)}</> : <DashboardEmptyState />,
        },
        {
            label: (
                <div>
                    <SafeFormattedMessage {...messages.approvalsPOs} />
                    <DashboardTabCount count={approvalsPOs.length} />
                </div>
            ),
            value: "Purchase orders",
            component: approvalsPOs.length ? <>{approvalsPOs.map(renderApprovalListItem)}</> : <DashboardEmptyState />,
        },
        {
            label: (
                <div>
                    <SafeFormattedMessage {...messages.approvalsInvoices} />
                    <DashboardTabCount count={approvalsInvoices.length} />
                </div>
            ),
            value: "Invoices",
            component: approvalsInvoices.length ? (
                <>{approvalsInvoices.map(renderApprovalListItem)}</>
            ) : (
                <DashboardEmptyState />
            ),
        },
    ]
}
