import * as Sentry from "@sentry/react"
import { useCallback, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { selectPendingLineTags, tagsActions } from "~/domains/analytics/tags/store/tagsSlice"
import { SelectedTagI } from "~/domains/analytics/tags/types"
import { addLineIdToTags } from "~/domains/transactions/_shared/utils/purchasesUtils"
import { purchaseOrdersApi } from "~/domains/transactions/purchase-orders/api/purchaseOrdersApi"
import { useFetchPurchaseOrder } from "~/domains/transactions/purchase-orders/store/hooks/useFetchPurchaseOrder"
import { PurchaseOrders } from "~/domains/transactions/purchase-orders/types"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { OrganizationId } from "~/types"

import { purchaseOrdersActions } from "../purchaseOrdersSlice"
import { useSavePurchaseOrderPendingLineTags } from "./useSavePurchaseOrderPendingLineTags"

const messages = defineMessages({
    success: {
        id: "purchase.order.update.success",
        defaultMessage: "Purchase order updated",
    },
    error: {
        id: "purchase.order.update.error",
        defaultMessage: "Something went wrong. Please contact your administrator",
    },
})

export const hasPendingLineTags = (pendingTags: Record<string, SelectedTagI[]>, purchaseOrder: PurchaseOrders) =>
    purchaseOrder.lines.some((line) => line.temporaryId && pendingTags[line.temporaryId]?.length > 0)

export const useUpdatePurchaseOrder = (purchaseOrderId: string, organizationId?: OrganizationId) => {
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string>()
    const { fetchPurchaseOrder } = useFetchPurchaseOrder(organizationId)
    const pendingLineTags = useAppSelector(selectPendingLineTags)
    const savePurchaseOrderPendingLineTags = useSavePurchaseOrderPendingLineTags(organizationId)

    const updatePO = useCallback(
        async (PO: PurchaseOrders, showToast: boolean = true) => {
            if (organizationId) {
                try {
                    setLoading(true)

                    await purchaseOrdersApi.update(organizationId, purchaseOrderId, PO)

                    const purchaseOrder = await purchaseOrdersApi.findById(organizationId, purchaseOrderId)
                    if (purchaseOrder) {
                        if (hasPendingLineTags(pendingLineTags, PO)) {
                            await savePurchaseOrderPendingLineTags(PO, purchaseOrder)

                            const tags = purchaseOrder.lines.flatMap((line, lineIndex) => {
                                const temporaryId = PO.lines[lineIndex]?.temporaryId
                                // eslint-disable-next-line @typescript-eslint/no-shadow
                                const tags = temporaryId ? pendingLineTags[temporaryId] : null
                                return tags && line.id ? addLineIdToTags(tags, line.id) : []
                            })
                            dispatch(tagsActions.addNewLinesTags(tags))
                            dispatch(tagsActions.resetPendingTags())
                        }
                    }

                    dispatch(purchaseOrdersActions.updatePOSuccess())
                    await fetchPurchaseOrder(purchaseOrderId)
                    setLoading(false)
                    showToast && toast.success(formatMessage(messages.success))
                    return PO
                } catch (e) {
                    setError(`${e}`)
                    setLoading(false)
                    toast.error(formatMessage(messages.error))
                    console.error(`Error updating Purchase Order: ${purchaseOrderId}`, error)
                    Sentry.captureException(error, {
                        tags: { organizationId },
                        extra: { purchaseOrderId },
                    })
                    throw e
                }
            }
        },
        [organizationId, purchaseOrderId, fetchPurchaseOrder, dispatch, pendingLineTags]
    )

    return {
        updatePO,
        error,
        loading,
    }
}
