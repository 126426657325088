import { Button, Stack } from "@mui/material"
import {
    DataGridPremiumProps,
    GridPagination,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
} from "@mui/x-data-grid-premium"
import { RiEqualizer3Line } from "@remixicon/react"
import { useMemo } from "react"

import { TagFilter } from "~/components/Filters/TagsFilter"
import { filterTagGroupsByTagObjectRecord } from "~/domains/analytics/tags/core/tagGroupsAndTags"
import { useOrganizationTagGroups } from "~/domains/analytics/tags/hooks/useOrganizationTagGroups"
import { TagObjectRecordI } from "~/domains/analytics/tags/types/TagObjectRecord"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"

interface DataGridToolbarProps extends DataGridPremiumProps {
    openFilters: () => void
    openColumns: () => void
    tags?: TagObjectRecordI
    disableExport?: boolean
}

export const DataGridToolbar = ({ openFilters, openColumns, tags, disableExport, ...props }: DataGridToolbarProps) => {
    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const { tagGroups } = useOrganizationTagGroups(currentOrganizationId)

    const tagGroupsFiltered = useMemo(() => filterTagGroupsByTagObjectRecord(tagGroups, tags), [tagGroups, tags])

    return (
        <GridToolbarContainer>
            <Stack direction="row" justifyContent="space-between" spacing={1} width="100%">
                <TagFilter tagGroups={tagGroupsFiltered || []} />
                <Stack direction="row">
                    {!props.disableMultipleColumnsFiltering && (
                        <GridToolbarFilterButton componentsProps={{ button: { onClick: openFilters } }} />
                    )}
                    {!props.disableColumnSelector && (
                        <Button onClick={openColumns}>
                            <RiEqualizer3Line size={18} />
                        </Button>
                    )}
                    {!props.disableDensitySelector && <GridToolbarDensitySelector />}
                    {!disableExport && <GridToolbarExport />}
                    {props.pagination && <GridPagination labelRowsPerPage="" />}
                </Stack>
            </Stack>
        </GridToolbarContainer>
    )
}
