import * as t from "io-ts"

import { SpiceDBObjectType, SpiceDBSharePermissionsBodyDto } from "~/domains/identity/roles-permissions/types/SpiceDB"
import { AuthorizationName, OrganizationId, TeamId, UserId } from "~/types"
import { SharedObjectType } from "~/types/SharedObjects"
import { optional } from "~/utils/io-ts"

export enum AuthorizationFirstLetter {
    C = AuthorizationName.CREATE,
    R = AuthorizationName.READ,
    U = AuthorizationName.UPDATE,
    D = AuthorizationName.DELETE,
    A = AuthorizationName.APPROVE,
    V = AuthorizationName.CONVERT,
    K = AuthorizationName.CHECK,
    P = AuthorizationName.PAY,
    G = AuthorizationName.ASSIGN_DOCUMENT,
    L = AuthorizationName.CLOSE,
    O = AuthorizationName.REOPEN,
}

export enum DomainName {
    IDENTITY = "identity",
    TRANSACTION = "transaction",
    ORCHESTRATION = "orchestration",
    COMMUNICATION = "communication",
    ANALYTICS = "analytics",
    PAYMENT = "payment",
}

export const DomainShorthand: Record<string, DomainName> = {
    id: DomainName.IDENTITY,
    tx: DomainName.TRANSACTION,
    or: DomainName.ORCHESTRATION,
    co: DomainName.COMMUNICATION,
    an: DomainName.ANALYTICS,
    py: DomainName.PAYMENT,
}

export enum ScopeName {
    ORGANIZATIONS = "organizations",
    USERS = "users",
    TEAMS = "teams",
    TAGS = "tags",
    ROLES_PERMISSIONS = "roles-permissions",
    BOOK_OF_RELATIONS = "book-of-relations",
    PURCHASE_REQUESTS = "purchase-requests",
    PURCHASE_ORDERS = "purchase-orders",
    BUDGETS = "budgets",
    INVOICES = "invoices",
    INVOICE_LIFECYCLE = "invoice-lifecycle",
    WORKFLOWS = "workflows",
    PAYMENTS = "payments",
    CATALOGS = "catalogs",
}

/* For now TAGS, BUDGETS and TEAMS have no shorthand scope name */
export const ScopeShorthand: Record<string, ScopeName> = {
    org: ScopeName.ORGANIZATIONS,
    usr: ScopeName.USERS,
    wf: ScopeName.WORKFLOWS,
    po: ScopeName.PURCHASE_ORDERS,
    pr: ScopeName.PURCHASE_REQUESTS,
    inv: ScopeName.INVOICES,
    ilc: ScopeName.INVOICE_LIFECYCLE,
    bor: ScopeName.BOOK_OF_RELATIONS,
    rp: ScopeName.ROLES_PERMISSIONS,
    pay: ScopeName.PAYMENTS,
}

const defaultScopeAuthorization: AuthorizationName[] = [
    AuthorizationName.READ,
    AuthorizationName.CREATE,
    AuthorizationName.UPDATE,
    AuthorizationName.DELETE,
]
export const ScopeAuthorization: Record<ScopeName, AuthorizationName[]> = {
    [ScopeName.ORGANIZATIONS]: defaultScopeAuthorization,
    [ScopeName.USERS]: defaultScopeAuthorization,
    [ScopeName.TEAMS]: defaultScopeAuthorization,
    [ScopeName.TAGS]: defaultScopeAuthorization,
    [ScopeName.ROLES_PERMISSIONS]: defaultScopeAuthorization,
    [ScopeName.BOOK_OF_RELATIONS]: defaultScopeAuthorization,
    [ScopeName.PURCHASE_REQUESTS]: [...defaultScopeAuthorization, AuthorizationName.APPROVE, AuthorizationName.CONVERT],
    [ScopeName.PURCHASE_ORDERS]: [
        ...defaultScopeAuthorization,
        AuthorizationName.APPROVE,
        AuthorizationName.SUBMIT,
        AuthorizationName.SEND,
        AuthorizationName.CLOSE,
        AuthorizationName.REOPEN,
        AuthorizationName.MARK_LINE_IN_PREPARATION,
        AuthorizationName.MARK_LINE_IN_TRANSIT,
        AuthorizationName.MARK_LINE_DELIVERED_OR_RECEIVED,
        AuthorizationName.MARK_LINE_CANCELED,
    ],
    [ScopeName.BUDGETS]: [
        ...defaultScopeAuthorization,
        AuthorizationName.ASSIGN_DOCUMENT,
        AuthorizationName.CLOSE,
        AuthorizationName.REOPEN,
    ],
    [ScopeName.INVOICES]: [...defaultScopeAuthorization, AuthorizationName.APPROVE],
    [ScopeName.INVOICE_LIFECYCLE]: [AuthorizationName.UPDATE],
    [ScopeName.WORKFLOWS]: defaultScopeAuthorization,
    [ScopeName.PAYMENTS]: [...defaultScopeAuthorization, AuthorizationName.APPROVE, AuthorizationName.PAY],
    [ScopeName.CATALOGS]: [AuthorizationName.READ, AuthorizationName.CREATE, AuthorizationName.UPDATE],
}

const PermissionIdIO = t.string

export type PermissionId = t.TypeOf<typeof PermissionIdIO>

const CreateRoleIO = t.type({
    name: t.string,
    description: t.string,
    permissions: optional(t.array(PermissionIdIO)),
})

const UpdateRoleIO = t.partial({
    name: t.string,
    description: optional(t.string),
    permissions: optional(t.array(PermissionIdIO)),
})

const ApiRoleIO = t.intersection([
    CreateRoleIO,
    t.type({
        organizationId: t.string,
        id: t.string,
        creator: t.string,
        permissions: t.array(PermissionIdIO),
        createdAt: t.string,
        permissionSetId: t.string,
        roleTemplateId: optional(t.string),
    }),
    t.partial({
        roleTemplateId: t.string,
    }),
])

const RoleIO = t.intersection([
    ApiRoleIO,
    t.type({
        isRoleTemplate: t.boolean,
    }),
])

export type ApiRole = t.TypeOf<typeof ApiRoleIO>
export type Role = t.TypeOf<typeof RoleIO>
export type CreateRole = t.TypeOf<typeof CreateRoleIO>
export type UpdateRole = t.TypeOf<typeof UpdateRoleIO>
export type ApiRoleWithEntityIds = t.TypeOf<typeof ApiRoleIO> & {
    userIds?: UserId[]
    teamIds?: TeamId[]
}
export type RoleWithEntityIds = t.TypeOf<typeof RoleIO> & {
    userIds?: UserId[]
    teamIds?: TeamId[]
}

const ApiRoleTemplateIO = t.type({
    id: t.string,
    name: t.string,
    description: t.string,
    permissionSetId: t.string,
    permissions: t.array(PermissionIdIO),
})

const RoleTemplateIO = t.intersection([
    ApiRoleTemplateIO,
    t.type({
        isRoleTemplate: t.boolean,
    }),
])

export type ApiRoleTemplate = t.TypeOf<typeof ApiRoleTemplateIO>
export type RoleTemplate = t.TypeOf<typeof RoleTemplateIO>

export type RoleOrTemplate = RoleWithEntityIds | RoleTemplate
export type RolesAndTemplates = { roles: RoleWithEntityIds[]; roleTemplates: RoleTemplate[] }

const OrganizationPermissionIO = t.type({
    organizationId: t.string,
    permissions: t.array(PermissionIdIO),
})
export type OrganizationPermission = t.TypeOf<typeof OrganizationPermissionIO>

const UserRoleIO = t.intersection([
    t.type({
        organizationId: t.string,
        userId: t.string,
        roleId: t.string,
        creator: t.string,
        endDate: optional(t.string),
    }),
    t.partial({
        role: RoleIO,
    }),
])
export type UserRole = t.TypeOf<typeof UserRoleIO>

const TeamRoleIO = t.intersection([
    t.type({
        organizationId: t.string,
        teamId: t.string,
        roleId: t.string,
        creator: t.string,
        endDate: optional(t.string),
    }),
    t.partial({
        role: RoleIO,
    }),
])
export type TeamRole = t.TypeOf<typeof TeamRoleIO>

export enum RoleAssignedToType {
    USER = "user",
    TEAM = "team",
}

export type EntityAssignedToRole = {
    entityId: string
    entityType: RoleAssignedToType
}

export type CreateRoleMutation = {
    organizationId: OrganizationId
    role: CreateRole
}

export type UpdateRoleMutation = {
    organizationId: OrganizationId
    roleId: string
    role: UpdateRole
}

export type DeleteRoleMutation = {
    organizationId: OrganizationId
    roleId: string
}

type AddRolesToEntityMutation = {
    organizationId: OrganizationId
    roleIds?: string[]
    roleTemplateIds?: string[]
}

export type AddRolesToUserMutation = AddRolesToEntityMutation & {
    userId: UserId
}

export type AddRolesToTeamMutation = AddRolesToEntityMutation & {
    teamId: TeamId
}

type RemoveRoleFromEntityMutation = {
    organizationId: OrganizationId
    roleId: string
}

export type RemoveRoleFromUserMutation = RemoveRoleFromEntityMutation & {
    userId: UserId
}

export type RemoveRoleFromTeamMutation = RemoveRoleFromEntityMutation & {
    teamId: TeamId
}

export type ShareObjectMutation = {
    organizationId: OrganizationId
    objectId: string
    objectType: SharedObjectType
    userId: string
    userOrganizationId: OrganizationId
    sendShareEmail: boolean
    creator: string
}

export type RemoveSharedObjectMutation = {
    organizationId: OrganizationId
    sharingId: string
}

type SpiceDBObject = {
    objectId: string
    objectType: SpiceDBObjectType
}
export type GetObjectPermissionsQuery = SpiceDBObject & {
    organizationId: string
}

export type ShareObjectPermissionsMutation = SpiceDBObject & {
    body: SpiceDBSharePermissionsBodyDto
}

export type UserObjectPermissionsCheckQuery = {
    userId: UserId
    objectType: SpiceDBObjectType
    organizationId: OrganizationId
    authorizations: AuthorizationName[]
}

export type DeleteResult = {
    affected?: number | null
}

export enum permissionErrorType {
    "PERMISSION_ID_ALREADY_USED" = "PERMISSION_ID_ALREADY_USED",
    "PERMISSION_ENTITY_NOT_VALID" = "PERMISSION_ENTITY_NOT_VALID",
    "ORGANIZATION_ID_NOT_VALID" = "ORGANIZATION_ID_NOT_VALID",
    "PERMISSION_ID_NOT_WELL_FORMATED" = "PERMISSION_ID_NOT_WELL_FORMATED",
    "AUTHORIZATION_NAME_UNKNOWN" = "AUTHORIZATION_NAME_UNKNOWN",
    "DOMAIN_NAME_UNKNOWN" = "DOMAIN_NAME_UNKNOWN",
    "CANT_UPDATE_PREDEFINED_ROLE" = "CANT_UPDATE_PREDEFINED_ROLE",
    "CANT_DELETE_PREDEFINED_ROLE" = "CANT_DELETE_PREDEFINED_ROLE",
    "UPDATE_ROLE_MAX_RETRY" = "UPDATE_ROLE_MAX_RETRY",
    "CREATE_ROLE_MAX_RETRY" = "CREATE_ROLE_MAX_RETRY",
    "ROLE_ENTITY_NOT_VALID" = "ROLE_ENTITY_NOT_VALID",
    "USER_ROLE_ASSOCIATION_NOT_FOUND" = "USER_ROLE_ASSOCIATION_NOT_FOUND",
    "TEAM_ROLE_ASSOCIATION_NOT_FOUND" = "TEAM_ROLE_ASSOCIATION_NOT_FOUND",
    "ROLE_NOT_FOUND" = "ROLE_NOT_FOUND",
    "ADD_ROLE_PERMISSION_MAX_RETRY" = "ADD_ROLE_PERMISSION_MAX_RETRY",
    "PERMISSION_DATA_PROVIDED_NOT_VALID" = "PERMISSION_DATA_PROVIDED_NOT_VALID",
    "ROLE_DATA_PROVIDED_NOT_VALID" = "ROLE_DATA_PROVIDED_NOT_VALID",
}
