import { useCallback, useState } from "react"

import { useWorkflowsApi } from "~/domains/orchestration/flows-v0/api/workflowsApi"
import { PurchaseRequestDetails } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { selectUser } from "~/store/account/accountSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"

import { workflowActions } from "../workflowSlice"

export const usePurchaseRequestActions = (purchaseRequest: PurchaseRequestDetails) => {
    const workflowsApi = useWorkflowsApi()
    const user = useAppSelector(selectUser)
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(false)

    const approvePurchaseRequest = useCallback(async () => {
        setLoading(true)
        const result = await workflowsApi.approvePurchaseRequest(purchaseRequest.organizationId, purchaseRequest.id)
        if (result) {
            dispatch(
                workflowActions.approvePurchaseRequest({ purchaseRequestId: purchaseRequest.id, approver: user.id })
            )
        }
        setLoading(false)
        return result
    }, [workflowsApi, purchaseRequest, dispatch, user])

    const refusePurchaseRequest = useCallback(async () => {
        setLoading(true)
        const result = await workflowsApi.refusePurchaseRequest(purchaseRequest.organizationId, purchaseRequest.id)
        if (result) {
            dispatch(workflowActions.refusePurchaseRequest({ purchaseRequestId: purchaseRequest.id, refuser: user.id }))
        }
        setLoading(false)
        return result
    }, [workflowsApi, purchaseRequest, dispatch, user])

    return {
        loading,
        approvePurchaseRequest,
        refusePurchaseRequest,
    }
}
