import { Stack } from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import { ChevronDown, ChevronUp } from "react-feather"
import { useDispatch } from "react-redux"

import { ItemLabel, ProgressBar } from "~/components"
import { ApprovalCheckI } from "~/domains/orchestration/flows-v0/types/Approval"
import { usersActions } from "~/store/users/usersSlice"
import { OrganizationTeamI, UserI } from "~/types"

import { ReviewerRow } from "./ReviewerRow"

type Props = {
    check: ApprovalCheckI
    users: UserI[]
    teams: OrganizationTeamI[]
    isOpen?: boolean
}

export const ApprovalCheck = ({ check, users, teams, isOpen = false }: Props) => {
    const [open, setOpen] = useState(isOpen)

    const dispatch = useDispatch()

    useEffect(() => {
        const userIds = check.reviewers.map((reviewer) => reviewer.userId || "")
        dispatch(usersActions.fetchUsers(userIds))
    }, [check, dispatch])

    const approvalProgress = useMemo(() => {
        return check.review.approvers.length / check.passThreshold
    }, [check.review.approvers.length, check.passThreshold])

    // Determine the color of the progress bar based on refusers
    const progressBarColor = check.review.refusers.length > 0 ? "var(--color-yellow)" : "var(--color-green)"

    return (
        <Stack gap={1}>
            <>
                <ItemLabel>
                    {check.name} {check.review.approvers.length}/{check.passThreshold}
                </ItemLabel>
                <Stack direction="row" alignItems="center">
                    <ProgressBar
                        values={[{ percentage: approvalProgress, label: check.name, color: progressBarColor }]}
                        className="w-full"
                    />
                    {open ? (
                        <ChevronUp
                            data-testid="chevron-up"
                            color="var(--color-grey-light)"
                            size={14}
                            onClick={() => setOpen(false)}
                        />
                    ) : (
                        <ChevronDown
                            data-testid="chevron-down"
                            color="var(--color-grey-light)"
                            size={14}
                            onClick={() => setOpen(true)}
                        />
                    )}
                </Stack>
                <Stack gap={0.5}>
                    {open &&
                        check.reviewers.map((reviewer) => (
                            <ReviewerRow
                                key={reviewer.userId || reviewer.teamId}
                                reviewer={reviewer}
                                check={check}
                                users={users}
                                teams={teams}
                            />
                        ))}
                </Stack>
            </>
        </Stack>
    )
}
