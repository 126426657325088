import React from "react"
import { defineMessage, useIntl } from "react-intl"

import { usePartnersAsOrganizations } from "~/domains/identity/partners/store/hooks"
import { PartnershipType } from "~/domains/identity/partners/types/Partners"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"

import { BaseProps, OrganizationSelector } from "./OrganizationSelector"

const organizationGroupLabel = defineMessage({
    id: "company.autocomplete.myPartners",
    defaultMessage: "My partners",
})

export const OrganizationSelectorWithPartners: React.FC<BaseProps> = (props) => {
    const { formatMessage } = useIntl()
    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)

    const { organizations: partnersOrganizations } = usePartnersAsOrganizations(
        PartnershipType.SUPPLIER,
        currentOrganizationId || ""
    )

    return (
        <OrganizationSelector
            {...props}
            groupLabel={{ org: formatMessage(organizationGroupLabel) }}
            organizations={partnersOrganizations}
        />
    )
}
