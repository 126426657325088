import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { FetchError } from "~/utils/apiClient/errors"

import { sortMessagesByDate } from "../core"
import { MessageI, MessageId, MessageViewI, RoomI, RoomId } from "../types"
import { CommunicationState, RoomMessages } from "./CommunicationState"

const initialState: CommunicationState = {
    roomsByObjectId: {},
    loadingRoomsByObjectId: {},
    errorsLoadingRoomsByObjectId: {},

    messagesPerRoom: {},
    fetchingMessagesInRoom: {},
    errorsFetchingMessagesInRoom: {},
    messageToUpdate: null,
}

const communicationSlice = createSlice({
    name: "communication",
    initialState,
    reducers: {
        fetchRooms(state, { payload: { objectId } }: PayloadAction<{ objectId: string }>) {
            state.loadingRoomsByObjectId[objectId] = true
            state.errorsLoadingRoomsByObjectId[objectId] = null
        },
        fetchRoomsSuccess(
            state,
            { payload: { objectId, rooms } }: PayloadAction<{ objectId: string; rooms: RoomI[] }>
        ) {
            state.loadingRoomsByObjectId[objectId] = false
            state.roomsByObjectId[objectId] = rooms
        },
        fetchRoomsFailure(
            state,
            { payload: { objectId, error } }: PayloadAction<{ objectId: string; error: FetchError<RoomI> }>
        ) {
            state.loadingRoomsByObjectId[objectId] = false
            state.errorsLoadingRoomsByObjectId[objectId] = error
        },
        addRooms(state, { payload: { objectId, rooms } }: PayloadAction<{ objectId: string; rooms: RoomI[] }>) {
            state.roomsByObjectId[objectId] = [
                ...(state.roomsByObjectId[objectId] ?? []).filter((r) => !rooms.some((newRoom) => newRoom.id === r.id)),
                ...rooms,
            ]
        },

        updateMessage(state, { payload }: PayloadAction<MessageI>) {
            const allRooms = Object.keys(state.messagesPerRoom)
            for (const roomId of allRooms) {
                const roomMessages = state.messagesPerRoom[roomId]
                if (roomMessages) {
                    const messageIndex = roomMessages.messages.findIndex((m) => m.id === payload.id)
                    if (messageIndex >= 0) {
                        roomMessages.messages = [
                            ...roomMessages.messages.slice(0, messageIndex),
                            payload,
                            ...roomMessages.messages.slice(messageIndex + 1),
                        ]
                    }
                }
            }
        },

        deleteMessage(state, { payload: messageId }: PayloadAction<MessageId>) {
            const allRooms = Object.keys(state.messagesPerRoom)
            for (const roomId of allRooms) {
                const roomMessages = state.messagesPerRoom[roomId]
                if (roomMessages) {
                    roomMessages.messages = roomMessages.messages.filter((m) => m.id !== messageId)
                }
            }
        },

        pinMessage(state, { payload: { roomId, message } }: PayloadAction<{ roomId: RoomId; message: MessageI }>) {
            const objectId = Object.keys(state.roomsByObjectId).find((oId) => {
                const rooms = state.roomsByObjectId[oId]
                if (rooms) {
                    return rooms.some((room) => room.id === roomId)
                }
                return false
            })
            if (objectId) {
                const rooms = state.roomsByObjectId[objectId]
                if (rooms) {
                    state.roomsByObjectId[objectId] = [
                        ...rooms.map((room) =>
                            room.id === roomId
                                ? {
                                      ...room,
                                      pinnedMessages: [...room.pinnedMessages, message],
                                  }
                                : room
                        ),
                    ]
                }
            }
        },

        unpinMessage(
            state,
            { payload: { roomId, messageId } }: PayloadAction<{ roomId: RoomId; messageId: MessageId }>
        ) {
            const objectId = Object.keys(state.roomsByObjectId).find((oId) => {
                const rooms = state.roomsByObjectId[oId]
                if (rooms) {
                    return rooms.some((room) => room.id === roomId)
                }
                return false
            })
            if (objectId) {
                const rooms = state.roomsByObjectId[objectId]
                if (rooms) {
                    state.roomsByObjectId[objectId] = [
                        ...rooms.map(
                            (room): RoomI =>
                                room.id === roomId
                                    ? {
                                          ...room,
                                          pinnedMessages: [
                                              ...room.pinnedMessages.filter((message) => message.id !== messageId),
                                          ] as MessageI[],
                                      }
                                    : room
                        ),
                    ]
                }
            }
        },

        fetchMessageInRoom(state, { payload: { roomId } }: PayloadAction<{ roomId: RoomId }>) {
            state.fetchingMessagesInRoom[roomId] = true
        },

        fetchMessageInRoomSuccess(
            state,
            { payload: { roomId, roomMessages } }: PayloadAction<{ roomId: RoomId; roomMessages: RoomMessages }>
        ) {
            state.fetchingMessagesInRoom[roomId] = false
            state.messagesPerRoom[roomId] = {
                paginationInfo: roomMessages.paginationInfo,
                messages: [...roomMessages.messages, ...(state.messagesPerRoom[roomId]?.messages ?? [])].sort(
                    sortMessagesByDate
                ),
            }
        },

        fetchMessageInRoomFailure(
            state,
            { payload: { roomId, error } }: PayloadAction<{ roomId: RoomId; error: FetchError<RoomMessages> }>
        ) {
            state.fetchingMessagesInRoom[roomId] = false
            state.errorsFetchingMessagesInRoom[roomId] = error
        },

        addMessageInRoom(
            state,
            { payload: { roomId, message } }: PayloadAction<{ roomId: RoomId; message: MessageI }>
        ) {
            const roomMessages = state.messagesPerRoom[roomId]
            if (roomMessages) {
                if (!roomMessages.messages.find((m) => m.id === message.id)) {
                    roomMessages.messages.push(message)
                } else {
                    roomMessages.messages = roomMessages.messages.map((m) => (m.id === message.id ? message : m))
                }
            } else {
                state.messagesPerRoom[roomId] = {
                    messages: [message],
                }
            }
        },

        addMessageView(state, { payload }: PayloadAction<MessageViewI>) {
            const allRooms = Object.keys(state.messagesPerRoom)
            for (const roomId of allRooms) {
                const roomMessages = state.messagesPerRoom[roomId]
                if (roomMessages) {
                    const message = roomMessages.messages.find((m) => m.id === payload.messageId)
                    if (message) {
                        message.views = [
                            ...message.views.filter((messageView) => messageView.id !== payload.id),
                            payload,
                        ]
                    }
                }
            }
        },
        setMessageToUpdate(state, { payload }: PayloadAction<MessageI | null>) {
            state.messageToUpdate = payload
        },
    },
})

export const communicationActions = communicationSlice.actions
export const communicationReducer = communicationSlice.reducer
