import { Grid, TextField, styled } from "@mui/material"
import React, { ChangeEvent, FormEvent, useCallback, useContext, useEffect, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { commonMessages } from "~/common-messages"
import { Button, CountrySelector } from "~/components"
import { CallbackOutputType, OcrExtractContext } from "~/domains/transactions/invoices/components/OcrExtract"
import { selectUser } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"
import { useCreateOrganization, useFetchOrganization, useFoundOrganization } from "~/store/organization/hooks"
import { CountryCode, OrganizationI, OrganizationId } from "~/types"

import { SignInToCreateOrganizationModal } from "./SignInToCreateOrganizationModal"
import { CompanyData } from "./types"

const messages = defineMessages({
    corporateName: {
        id: "transactions.invoices.ocr.OrganizationPicker.corporateName",
        defaultMessage: "Corporate name",
    },
    registrationNumber: {
        id: "transactions.invoices.ocr.OrganizationPicker.registrationNumber",
        defaultMessage: "Business Registration Number",
    },
    taxNumber: {
        id: "transactions.invoices.ocr.OrganizationPicker.taxNumber",
        defaultMessage: "Tax number",
    },
    membershipRequested: {
        id: "account.organization.cannotCreateRequestedMembershipInstead",
        defaultMessage: "This organization already exists. A request to join it has been sent.",
    },
})

interface Props {
    onOrganizationCreated: (organization: OrganizationI, isFoundation?: boolean) => void
    companyData: CompanyData
    cancel: () => void
    isFoundation?: boolean
}

const FormFooter = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    gap: "8px",
    paddingTop: "8px",
})

export const CreateOrganizationForm: React.FC<Props> = ({
    isFoundation,
    companyData,
    onOrganizationCreated,
    cancel,
}) => {
    const { formatMessage } = useIntl()

    const user = useAppSelector(selectUser)
    const isAuthenticated = !!user.id

    const [displaySignInModal, setDisplaySignInModal] = useState(false)
    const [countryCode, setCountryCode] = useState(companyData.countryCode)
    const [name, setName] = useState(companyData.name ?? "")
    const [registrationNumber, setRegistrationNumber] = useState(companyData.registrationNumber ?? "")
    const [taxNumber, setTaxNumber] = useState(companyData.taxNumber ?? "")
    const [organizationId, setOrganizationId] = useState<OrganizationId>()
    const { organization } = useFetchOrganization(organizationId)
    const { setCallback } = useContext(OcrExtractContext)

    const [loading, setLoading] = useState(false)
    const createOrganization = useCreateOrganization()
    const { foundOrganization, loading: foundOrganizationLoading } = useFoundOrganization(user)

    useEffect(() => {
        return () => setOrganizationId(undefined)
    }, [])

    useEffect(() => {
        setCountryCode(companyData.countryCode)
        if (companyData.name) {
            setName(companyData.name)
        }
        if (companyData.registrationNumber) {
            setRegistrationNumber(companyData.registrationNumber)
        }
        if (companyData.taxNumber) {
            setTaxNumber(companyData.taxNumber)
        }
    }, [companyData])

    useEffect(() => {
        if (organization) {
            onOrganizationCreated(organization, isFoundation)
        }
    }, [organization, isFoundation, onOrganizationCreated])

    const onNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value)
    }, [])

    const onRegistrationNumberChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setRegistrationNumber(event.target.value)
    }, [])

    const onTaxNumberChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setTaxNumber(event.target.value)
    }, [])

    const doCreateOrganization = useCallback(async () => {
        setLoading(true)
        createOrganization(
            name,
            countryCode,
            registrationNumber,
            taxNumber
                ? {
                      countryCode,
                      legalName: name,
                      preferredRegistrationNumber: {
                          registrationNumber,
                          registrationType: "UNKNOWN",
                      },
                      vatNumber: taxNumber,
                  }
                : undefined
        )
            .then((result) => setOrganizationId(result?.id))
            .finally(() => setLoading(false))
    }, [isAuthenticated, name, countryCode, registrationNumber, taxNumber, createOrganization, onOrganizationCreated])

    const doFoundOrganization = useCallback(() => {
        setLoading(true)
        foundOrganization(name, countryCode, registrationNumber)
            .then(({ organization: foundedOrganization, membershipRequested }) => {
                if (membershipRequested) {
                    toast.warning(formatMessage(messages.membershipRequested))
                }
                setOrganizationId(foundedOrganization.id)
            })
            .finally(() => setLoading(false))
    }, [name, countryCode, registrationNumber, foundOrganization])

    const authGuardedCreateOrganization = useCallback(
        (event?: React.MouseEvent) => {
            if (event) {
                event.preventDefault()
                event.stopPropagation()
            }

            if (!isAuthenticated) {
                setDisplaySignInModal(true)
                return
            }

            if (isFoundation) {
                doFoundOrganization()
            } else {
                doCreateOrganization()
            }
        },
        [isAuthenticated, doCreateOrganization, doFoundOrganization, isFoundation]
    )

    const onSignInModalClose = useCallback(
        (isAuth: boolean) => {
            setDisplaySignInModal(false)
            if (isAuth) {
                if (isFoundation) {
                    doFoundOrganization()
                } else {
                    doCreateOrganization()
                }
            }
        },
        [isFoundation, doCreateOrganization, doFoundOrganization]
    )

    const onSubmit = useCallback(
        async (event: FormEvent<HTMLButtonElement>) => {
            event.preventDefault()
            event.stopPropagation()

            if (!event.currentTarget.checkValidity()) {
                event.currentTarget.reportValidity()
                return
            }
            authGuardedCreateOrganization()
        },
        [authGuardedCreateOrganization]
    )

    const handleCountryChange = (newCountryCode: CountryCode | null) => {
        if (newCountryCode) {
            setCountryCode(newCountryCode)
        }
    }

    return (
        <>
            <Grid container gap={2}>
                <CountrySelector required value={countryCode} onChange={handleCountryChange} variant="filled" />
                <TextField
                    required
                    label={formatMessage(messages.corporateName)}
                    placeholder={formatMessage(messages.corporateName)}
                    onChange={onNameChange}
                    value={name}
                    fullWidth
                    disabled={loading || foundOrganizationLoading}
                    onFocus={() => {
                        setCallback({ type: CallbackOutputType.STRING, function: setName })
                    }}
                />
                <TextField
                    required
                    label={formatMessage(messages.registrationNumber)}
                    placeholder={formatMessage(messages.registrationNumber)}
                    onChange={onRegistrationNumberChange}
                    value={registrationNumber}
                    disabled={loading || foundOrganizationLoading}
                    fullWidth
                    onFocus={() => {
                        setCallback({ type: CallbackOutputType.STRING, function: setRegistrationNumber })
                    }}
                />
                <TextField
                    label={formatMessage(messages.taxNumber)}
                    placeholder={formatMessage(messages.taxNumber)}
                    onChange={onTaxNumberChange}
                    value={taxNumber}
                    disabled={loading || foundOrganizationLoading}
                    fullWidth
                    onFocus={() => {
                        setCallback({ type: CallbackOutputType.STRING, function: setTaxNumber })
                    }}
                />
                <FormFooter>
                    <Button type="neutral" onClick={cancel}>
                        {formatMessage(commonMessages.cancel)}
                    </Button>
                    <Button buttonType="submit" disabled={loading || foundOrganizationLoading} onClick={onSubmit}>
                        {formatMessage(commonMessages.save)}
                    </Button>
                </FormFooter>
            </Grid>
            <SignInToCreateOrganizationModal open={displaySignInModal} onClose={onSignInModalClose} />
        </>
    )
}
