import { useEffect } from "react"
import { To, useNavigate } from "react-router-dom"

import { useHasChangedOrganization } from "~/domains/identity/organization/hooks/useHasChangedOrganization"
import { OrganizationId } from "~/types"

export const useRedirectIfOrganizationChanged = (organizationId: OrganizationId | undefined, to: To) => {
    const navigate = useNavigate()
    const hasChangedOrganization = useHasChangedOrganization(organizationId)

    useEffect(() => {
        if (hasChangedOrganization) {
            navigate(to, { replace: true })
        }
    }, [hasChangedOrganization, navigate, to])
}
