import React, { PropsWithChildren, createContext, useEffect, useMemo, useRef, useState } from "react"
import { Socket, io } from "socket.io-client"

const API_REACTIVE_URL = new URL(import.meta.env.VITE_API_REACTIVE_URL)
const WS_ORIGIN = `ws${API_REACTIVE_URL.protocol.endsWith("s:") ? "s" : ""}://${API_REACTIVE_URL.hostname}:${
    API_REACTIVE_URL.port
}`
const WS_PATH = "/reactive/ws"

type SocketIOContextI =
    | {
          ioSocket: Socket
          isConnected: boolean
      }
    | {
          ioSocket: null
          isConnected: false
      }

export const SocketIOContext = createContext<SocketIOContextI>({
    ioSocket: null,
    isConnected: false,
})

export const SocketIOContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const didUnmount = useRef(false)
    const [isConnected, setIsConnected] = useState(false)

    const ioSocket = useMemo<Socket>(() => {
        const socket = io(WS_ORIGIN, {
            path: WS_PATH,
            transports: ["websocket"],
        })
        return socket
    }, [])

    useEffect(() => {
        ioSocket.on("connect", () => {
            !didUnmount.current && setIsConnected(true)
        })
        ioSocket.on("disconnect", () => {
            !didUnmount.current && setIsConnected(false)
        })
        ioSocket.connect()
    }, [ioSocket])

    useEffect(() => {
        didUnmount.current = false
        return () => {
            didUnmount.current = true
        }
    }, [])

    return <SocketIOContext.Provider value={{ ioSocket, isConnected }}>{children}</SocketIOContext.Provider>
}
