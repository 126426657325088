import { Box, IconButton, ListSubheader, Menu, MenuItem, Stack, TextField, Tooltip, styled } from "@mui/material"
import { ChangeEvent, MouseEvent, ReactNode, useCallback, useMemo, useState } from "react"
import { ArrowDownCircle } from "react-feather"
import { MessageDescriptor, useIntl } from "react-intl"

import { PaymentMethodDetails, SelectorType } from "~/domains/payment/payment-method-details/types"
import { buildMaskedCardNumber } from "~/domains/payment/payment-method-details/utils/getGridColumnsByActiveTab"
import { PaymentMethodType } from "~/domains/payment/payment-methods/types"

const paymentTypeMessage: Partial<Record<PaymentMethodType, MessageDescriptor>> = {
    [PaymentMethodType.CARD]: {
        id: "payment.paymentMethodTabs.card",
        defaultMessage: "Card",
    },
    [PaymentMethodType.BANK_TRANSFER]: {
        id: "payment.paymentMethodTabs.bankTransfer",
        defaultMessage: "Bank transfer",
    },
    [PaymentMethodType.DIRECT_DEBIT]: {
        id: "payment.paymentMethodTabs.directDebit",
        defaultMessage: "Direct debit",
    },
}

const subheaderStyles = {
    fontSize: "var(--font-size-sm)",
    fontWeight: "var(--font-weight-xl)",
    textTransform: "uppercase",
    lineHeight: "var(--spacing-md)",
    color: "var(--color-grey-light)",
    padding: "var(--spacing-md) var(--spacing-md)",
}

const DataItem = styled("div")({
    color: "var(--color-grey-light)",
    fontSize: "var(--font-size-sm)",
})

interface PaymentMethodDetailsSelectorProps {
    label?: string
    value: string | null
    loading?: boolean
    disabled?: boolean
    items: PaymentMethodDetails[]
    selectorType?: SelectorType
    icon?: ReactNode | null
    onItemSelect: (id: string, paymentMethodId?: string | undefined) => void
}

export const PaymentMethodDetailsSelector = ({
    label,
    value,
    icon,
    items: paymentMethodDetails,
    onItemSelect,
    loading = false,
    disabled = false,
    selectorType = "input",
}: PaymentMethodDetailsSelectorProps) => {
    const { formatMessage } = useIntl()

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const open = Boolean(anchorEl)

    const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleSelectionChanged = (id: string) => () => {
        setAnchorEl(null)
        onItemSelect?.(id)
    }

    const groupedData = useMemo(() => {
        const groups: Partial<Record<PaymentMethodType, PaymentMethodDetails[]>> = {}

        paymentMethodDetails.forEach((detail) => {
            const type = detail.payment_method_type as PaymentMethodType
            if (!groups[type]) {
                groups[type] = []
            }
            groups[type].push(detail)
        })

        return Object.entries(groups).map(([type, details]) => ({
            payment_method_type: type as PaymentMethodType,
            details,
        }))
    }, [paymentMethodDetails])

    const handleChange = useCallback(
        (event: ChangeEvent<{ value: string }>) => {
            const id = event.target.value as string

            if (onItemSelect) {
                onItemSelect(id)
            }
        },
        [onItemSelect]
    )

    const renderMenuItem = useCallback(
        (item: PaymentMethodDetails) => {
            const { id, payment_method_type, currency } = item
            let content: ReactNode = null

            switch (payment_method_type) {
                case PaymentMethodType.CARD: {
                    const { card_first_6_digits, card_last_4_digits, card_nickname, card_brand } = item
                    const cardNumber = buildMaskedCardNumber([card_first_6_digits, card_last_4_digits])

                    content = (
                        <Stack gap="4px" direction="column">
                            <Box>{cardNumber}</Box>
                            <Stack gap="8px" direction="row">
                                {Boolean(card_nickname || card_brand || currency) && (
                                    <DataItem>
                                        {[card_nickname, card_brand, currency].filter(Boolean).join(" • ")}
                                    </DataItem>
                                )}
                            </Stack>
                        </Stack>
                    )
                    break
                }
                case PaymentMethodType.BANK_TRANSFER: {
                    const { iban, bank_account_number, bank_name, holder_name } = item
                    const bankInfo = iban || bank_account_number

                    content = (
                        <Stack gap="4px" direction="column">
                            <Box>{bankInfo}</Box>
                            <Stack gap="8px" direction="row">
                                {Boolean(bank_name || holder_name || currency) && (
                                    <DataItem>
                                        {[bank_name, holder_name, currency].filter(Boolean).join(" • ")}
                                    </DataItem>
                                )}
                            </Stack>
                        </Stack>
                    )
                    break
                }
                case PaymentMethodType.DIRECT_DEBIT: {
                    const { mandate_reference, bank_account_number, bank_name, holder_name } = item

                    content = (
                        <Stack gap="4px" direction="column">
                            <Box>{bank_account_number}</Box>
                            <Stack gap="8px" direction="row">
                                {Boolean(mandate_reference || bank_name || holder_name || currency) && (
                                    <DataItem>
                                        {[mandate_reference, bank_name, holder_name, currency]
                                            .filter(Boolean)
                                            .join(" • ")}
                                    </DataItem>
                                )}
                            </Stack>
                        </Stack>
                    )
                    break
                }
                default:
                    return null
            }

            return (
                <MenuItem
                    key={id}
                    value={id}
                    selected={id === value}
                    {...(selectorType === "button" ? { onClick: handleSelectionChanged(id) } : {})}
                >
                    <Stack gap="8px">{content}</Stack>
                </MenuItem>
            )
        },
        [paymentMethodDetails]
    )

    const groupData = groupedData.map(({ payment_method_type, details }, index) => [
        <ListSubheader sx={subheaderStyles} key={payment_method_type}>
            {paymentTypeMessage[payment_method_type]
                ? formatMessage(paymentTypeMessage[payment_method_type])
                : payment_method_type}
        </ListSubheader>,
        details.map(renderMenuItem),
        ...(index !== groupedData.length - 1
            ? [<MenuItem disabled key={`divider-${payment_method_type}`} sx={{ padding: 0 }} divider />]
            : []),
    ])

    if (selectorType === "button") {
        return (
            <>
                <Tooltip arrow title={label}>
                    <IconButton disabled={disabled} onClick={handleOpenMenu}>
                        {icon ?? <ArrowDownCircle size={18} />}
                    </IconButton>
                </Tooltip>
                <Menu
                    slotProps={{
                        paper: {
                            sx: {
                                minWidth: 300,
                                "&::before": {
                                    display: "none",
                                },
                            },
                        },
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => setAnchorEl(null)}
                >
                    {groupData}
                </Menu>
            </>
        )
    }

    return (
        <TextField
            fullWidth
            label={label}
            select
            value={value || ""}
            disabled={disabled || loading}
            variant="outlined"
            autoComplete="off"
            onChange={handleChange}
            SelectProps={{
                MenuProps: {
                    PaperProps: {
                        sx: {
                            maxHeight: 400,
                            overflowY: "auto",
                            ".MuiList-root": {
                                padding: 0,
                            },
                        },
                    },
                },
            }}
        >
            {groupData}
        </TextField>
    )
}
