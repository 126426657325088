import React, { useMemo } from "react"

import { JobQueueProgress } from "~/domains/transactions/invoices/_views/ocr/components/OcrPendingJobsQueue/JobQueueProgress"
import { PendingJobProgressView } from "~/domains/transactions/invoices/_views/ocr/components/OcrPendingJobsQueue/PendingJobProgressView"
import { useOcrJobsQueue } from "~/domains/transactions/invoices/_views/ocr/hooks"
import { DocumentJobStatus } from "~/domains/transactions/invoices/_views/ocr/types"
import { UserId } from "~/types"

type CompactProps = { compact: true; component?: React.ElementType }
type ExpandedProps = { compact?: false; component?: never }

export type CompactOrExpandedProps = CompactProps | ExpandedProps

type Props = {
    userId: UserId
} & CompactOrExpandedProps

export const OrganizationPendingJobsQueue: React.FC<Props> = ({ userId, component, compact }) => {
    const { jobsQueue } = useOcrJobsQueue(userId)
    const pendingJobs = useMemo(() => jobsQueue.filter((job) => job.status === DocumentJobStatus.PENDING), [jobsQueue])

    if (pendingJobs.length === 0 && compact) return null

    if (compact) return <JobQueueProgress jobsQueue={jobsQueue} component={component} />

    return <PendingJobProgressView jobsQueue={jobsQueue} />
}
