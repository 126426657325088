import { withSocketIOProvider } from "~/domains/_shared/subscriptions/components/SocketIOContext"
import { TransactionInBudgets } from "~/domains/transactions/_shared/components/TransactionInBudgets"
import { budgetApi } from "~/domains/transactions/budget/api/budgetApi"
import { TransactionType } from "~/domains/transactions/budget/types"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { useSubscribeInvoiceUpdates } from "~/store/invoice/hooks"
import { invoiceActions } from "~/store/invoice/invoiceSlice"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { InvoiceI, OrganizationI } from "~/types"

interface InvoiceBudgetsProps extends JSX.IntrinsicAttributes {
    invoice: InvoiceI
    buyerOrganization: OrganizationI | undefined
}

export const InvoiceBudgets = withSocketIOProvider(({ invoice, buyerOrganization }: InvoiceBudgetsProps) => {
    const dispatch = useAppDispatch()
    useSubscribeInvoiceUpdates(buyerOrganization?.id, invoice.id)

    const organizationId = useAppSelector(selectCurrentOrganizationId)

    const handleSuccess = async () => {
        const result = await budgetApi.getBudgetByTransaction(
            organizationId as string,
            invoice.id,
            TransactionType.INVOICE
        )
        dispatch(invoiceActions.setInvoice({ ...invoice, budgets: result }))
    }

    return (
        <TransactionInBudgets
            transaction={invoice}
            objectType={TransactionType.INVOICE}
            organizationId={organizationId || ""}
            handleSuccess={handleSuccess}
        />
    )
})
