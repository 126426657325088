import { Stack, styled } from "@mui/material"
import React, { FC, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

import { Loader, SafeFormattedMessage } from "~/components"
import { LeftChevronIcon } from "~/components/Icons"
import { useLazyGetRunsQuery } from "~/domains/orchestration/flows/api/runsApi"
import { IconButton } from "~/domains/orchestration/flows/components/IconButton"
import { SIDE_BAR_TOP_POSITION, SIDE_BAR_WIDTH_RUN_EXPLORER } from "~/domains/orchestration/flows/constants"
import { useEditorDispatch } from "~/domains/orchestration/flows/context/editorContext"
import { useFlowLatestVersion } from "~/domains/orchestration/flows/hooks"
import { messages } from "~/domains/orchestration/flows/locale"
import { FlowId, RunId, RunItem } from "~/domains/orchestration/flows/types"

import { RunExplorerItem } from "./RunExplorerItem"
import { RunExplorerList } from "./RunExplorerList"
import "./RunExplorerSideBar.scss"

interface Props {
    flowId: FlowId
}

const SideBarWrapper = styled("div")<{ width: number }>(({ width }) => ({
    width: `${width}px`,
    height: `calc(100vh - ${SIDE_BAR_TOP_POSITION}px)`,
    display: width === 0 ? "none" : "block",
}))

export const RunExplorerSideBar: FC<Props> = ({ flowId }) => {
    const location = useLocation()

    const [getRuns, { isLoading }] = useLazyGetRunsQuery()

    useEffect(() => {
        getRuns({ flowId, limit: 100, offset: 0 })
    }, [flowId, getRuns])

    const { state } = location as { state: { runId?: RunId } | null }
    const runId = state?.runId ?? null
    const version = useFlowLatestVersion(flowId)

    const [currentRunId, setCurrentRunId] = useState<RunId | null>(runId)

    const dispatch = useEditorDispatch()
    const isRunSelected = currentRunId !== null

    const handleClickBack = () => {
        setCurrentRunId(null)
    }

    useEffect(() => {
        if (!isRunSelected) {
            dispatch({ type: "SET_RUN", payload: null })
        }
    }, [dispatch, isRunSelected])

    const handleClickRow = (run: RunItem) => {
        setCurrentRunId(run.id)
    }

    const showRunExplorerList = !isRunSelected && !isLoading
    const showRunExplorerItem = isRunSelected && !isLoading

    return (
        <SideBarWrapper className="flows-run-explorer-sideBar" width={SIDE_BAR_WIDTH_RUN_EXPLORER}>
            <Stack
                className="flows-run-explorer-sideBar-header"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                {isRunSelected ? (
                    <IconButton type="close" onClick={handleClickBack}>
                        <LeftChevronIcon size={14} color="var(--primary-color)" strokeWidth="3" />
                    </IconButton>
                ) : null}
                <SafeFormattedMessage tagName="h4" {...messages.sideBar.explorerTitle} />
            </Stack>

            {showRunExplorerItem && <RunExplorerItem runId={currentRunId} />}
            {showRunExplorerList && (
                <RunExplorerList flowId={flowId} latestFlowVersion={version} handleClickRow={handleClickRow} />
            )}
            {isLoading && <Loader fullWidth />}
        </SideBarWrapper>
    )
}
