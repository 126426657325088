import { Box, FormControlLabel, Grid, MenuItem, Stack, Switch, TextField, Typography } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs, { Dayjs } from "dayjs"
import { FormEvent, useState } from "react"
import { useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { Button, CurrencySelector, Loader, SafeFormattedMessage } from "~/components"
import { useFormValidation } from "~/domains/payment/hooks"
import { PaymentBatchTags } from "~/domains/payment/payment-batches/components"
import { BatchRecurrenceType, CreatePaymentBatch, PaymentBatch } from "~/domains/payment/payment-batches/types"
import { batchFormValidationSchema, batchRecurrenceTypesMessages } from "~/domains/payment/payment-batches/utils"
import { PayerPaymentMethodDetails } from "~/domains/payment/payment-method-details/components"
import { PaymentMethodDetailsFormType } from "~/domains/payment/types"
import { CurrencyCodes, CurrencyI } from "~/types"

interface PaymentBatchAddEditFormProps {
    loading: boolean
    organizationId: string
    selectedItem?: PaymentBatch
    onCancel: (resetForm: () => void) => void
    onSubmit: (formData: CreatePaymentBatch, resetForm: () => void) => void
}

const initialState: CreatePaymentBatch = {
    name: "",
    description: "",
    recurrenceType: BatchRecurrenceType.MONTHLY,
    recurrenceInterval: 0,
    startDate: dayjs().format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
    creatorUserId: null,
    aggregatePayments: false,
    collectPayments: false,
    ownerId: null,
    defaultTotalAmount: 0,
    defaultCurrency: CurrencyCodes.EUR,
    defaultPaymentMethodId: null,
    defaultPaymentMethodDetailsId: null,
}

export const PaymentBatchAddEditForm = ({
    loading,
    organizationId,
    selectedItem,
    onSubmit,
    onCancel,
}: PaymentBatchAddEditFormProps) => {
    const { formatMessage, locale } = useIntl()
    const [formData, setFormData] = useState<CreatePaymentBatch>(selectedItem ?? initialState)

    const { errors, validate } = useFormValidation<CreatePaymentBatch>(formData, batchFormValidationSchema)

    const handleCancel = () => {
        onCancel?.(resetForm)
    }

    const handleChangeStartDate = (date: Dayjs | null) => {
        setFormData({
            ...formData,
            startDate: date?.isValid() ? date.format("YYYY-MM-DD") : null,
        })
    }

    const handleChangeEndDate = (date: Dayjs | null) => {
        setFormData({
            ...formData,
            endDate: date?.isValid() ? date.format("YYYY-MM-DD") : null,
        })
    }

    const resetForm = () => {
        setFormData(initialState)
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()
        e.stopPropagation()

        const isValid = validate()

        if (!isValid) return

        onSubmit?.(formData, resetForm)
    }

    const handleCurrencyChange = (currency: CurrencyI | null) => {
        setFormData({
            ...formData,
            defaultCurrency: currency?.code ?? CurrencyCodes.EUR,
        })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        const { name, value } = e.target

        setFormData({
            ...formData,
            [name]: value,
        })
    }

    const handleCheckedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()

        const { name, checked } = e.target

        setFormData({
            ...formData,
            [name]: checked,
        })
    }

    const handlePaymentMethodDetailsChanged = (
        defaultPaymentMethodDetailsId: string | null,
        defaultPaymentMethodId: string | null | undefined
    ) => {
        setFormData({
            ...formData,
            defaultPaymentMethodDetailsId,
            ...(defaultPaymentMethodId ? { defaultPaymentMethodId } : {}),
        })
    }

    return (
        <Box component="form" gap={2} display="flex" flexDirection="column" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="flex-start">
                <Grid container item xs={12} md={6} gap={2} alignItems="flex-start">
                    <Grid item xs={12}>
                        <TextField
                            label={formatMessage(commonMessages.name)}
                            name="name"
                            value={formData.name}
                            disabled={loading}
                            error={!!errors.name}
                            helperText={errors.name}
                            fullWidth
                            required
                            autoComplete="off"
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={formatMessage(commonMessages.description)}
                            name="description"
                            value={formData.description}
                            disabled={loading}
                            multiline
                            rows={3}
                            fullWidth
                            autoComplete="off"
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid container item spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label={formatMessage(commonMessages.recurrenceType)}
                                name="recurrenceType"
                                value={formData.recurrenceType}
                                disabled={loading}
                                fullWidth
                                autoComplete="off"
                                required
                                select
                                onChange={handleChange}
                            >
                                {Object.values(BatchRecurrenceType).map((key) => (
                                    <MenuItem key={key} value={key}>
                                        <SafeFormattedMessage {...batchRecurrenceTypesMessages[key]} />
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label={formatMessage(commonMessages.recurrenceInterval)}
                                name="recurrenceInterval"
                                value={formData.recurrenceInterval}
                                disabled={loading}
                                fullWidth
                                inputProps={{ min: 0, max: 100 }}
                                autoComplete="off"
                                type="number"
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                                <DatePicker
                                    views={["year", "month", "day"]}
                                    label={formatMessage(commonMessages.startDate)}
                                    minDate={dayjs()}
                                    format="YYYY-MM-DD"
                                    disabled={loading}
                                    value={dayjs(formData.startDate)}
                                    slots={{ textField: TextField }}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            required: true,
                                            error: !!errors.startDate,
                                            helperText: errors.startDate,
                                        },
                                    }}
                                    onChange={handleChangeStartDate}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                                <DatePicker
                                    views={["year", "month", "day"]}
                                    label={formatMessage(commonMessages.endDate)}
                                    minDate={dayjs()}
                                    format="YYYY-MM-DD"
                                    disabled={loading}
                                    value={dayjs(formData.endDate)}
                                    slots={{ textField: TextField }}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                        },
                                    }}
                                    onChange={handleChangeEndDate}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label={formatMessage(commonMessages.maximumAmount)}
                                name="defaultTotalAmount"
                                value={formData.defaultTotalAmount}
                                disabled={loading}
                                fullWidth
                                inputProps={{ min: 0 }}
                                autoComplete="off"
                                type="number"
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CurrencySelector
                                editMode
                                required
                                label={formatMessage(commonMessages.defaultCurrency)}
                                value={formData.defaultCurrency}
                                disabled={loading}
                                onChange={handleCurrencyChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Stack>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={formData.aggregatePayments}
                                            inputProps={{ "aria-label": "controlled" }}
                                            color="primary"
                                            name="aggregatePayments"
                                            onChange={handleCheckedChange}
                                        />
                                    }
                                    label={
                                        <Typography component="p" color="var(--color-grey-light)">
                                            {formatMessage(commonMessages.aggregatePayments)}
                                        </Typography>
                                    }
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={formData.collectPayments}
                                            inputProps={{ "aria-label": "controlled" }}
                                            color="primary"
                                            name="collectPayments"
                                            onChange={handleCheckedChange}
                                        />
                                    }
                                    label={
                                        <Typography component="p" color="var(--color-grey-light)">
                                            {formatMessage(commonMessages.collectPayments)}
                                        </Typography>
                                    }
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} md={6} spacing={2} alignSelf="flex-start">
                    <Grid item xs={12}>
                        <PaymentBatchTags objectId={selectedItem?.id} organizationId={organizationId} />
                    </Grid>
                    <Grid item xs={12}>
                        <PayerPaymentMethodDetails
                            payerId={organizationId}
                            loading={loading}
                            formType={PaymentMethodDetailsFormType.INLINE}
                            label={formatMessage(commonMessages.paymentMethodDetails)}
                            selectedPaymentMethodDetailsId={formData.defaultPaymentMethodDetailsId ?? null}
                            onPaymentMethodChanged={handlePaymentMethodDetailsChanged}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Stack flexDirection="row" justifyContent="flex-end" alignItems="center" gap={2}>
                <Button type="neutral" onClick={handleCancel}>
                    <SafeFormattedMessage {...commonMessages.cancel} />
                </Button>
                <Button type="primary" buttonType="submit">
                    {loading ? <Loader small /> : <SafeFormattedMessage {...commonMessages.save} />}
                </Button>
            </Stack>
        </Box>
    )
}
