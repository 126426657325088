import {
    FormControlLabel,
    InputAdornment,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Switch,
    TextField,
    Typography,
} from "@mui/material"
import React, { useState } from "react"
import { Search } from "react-feather"
import { useIntl } from "react-intl"

import { Button, Card, SafeFormattedMessage } from "~/components"
import { messages } from "~/domains/orchestration/flows/locale"
import { SortBy } from "~/domains/orchestration/flows/types"

import { ModalCreateFlow } from "./ModalCreateFlow"

interface FilterProps {
    hasCreatePermission: boolean
    onFilterChange: (filter: string) => void
    filter: string
    showEnabledOnly: boolean
    sortBy: SortBy
    onShowEnabledOnlyChange?: (value: boolean) => void
    onSortChange?: (value: SortBy) => void
}

export const Filter: React.FC<FilterProps> = ({
    hasCreatePermission,
    onFilterChange,
    filter,
    sortBy,
    showEnabledOnly,
    onShowEnabledOnlyChange,
    onSortChange,
}) => {
    const { formatMessage } = useIntl()

    const [showCreateFlowModal, setShowCreateFlowModal] = useState(false)

    const displayCreateFlowModal = () => setShowCreateFlowModal(true)
    const hideCreateFlowModal = () => setShowCreateFlowModal(false)

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onFilterChange(event.target.value)
    }

    const handleSortChange = (event: SelectChangeEvent<SortBy>) => {
        const value = event.target.value as SortBy
        onSortChange?.(value)
    }

    const handleShowEnabledOnlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onShowEnabledOnlyChange?.(event.target.checked)
    }

    return (
        <Card>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack alignItems="center" spacing={2} direction="row" width="100%">
                    <TextField
                        name="datatable-filter"
                        placeholder={formatMessage(messages.filter.searchPlaceholder)}
                        onChange={handleFilterChange}
                        value={filter}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search color="var(--color-grey-light)" size={14} />
                                </InputAdornment>
                            ),
                        }}
                        size="small"
                    />
                    <FormControlLabel
                        control={<Switch checked={showEnabledOnly} onChange={handleShowEnabledOnlyChange} />}
                        label={formatMessage(messages.filter.enabledOnly)}
                    />

                    <Select size="small" defaultValue={sortBy} onChange={handleSortChange}>
                        <MenuItem value={SortBy.NAME}>
                            <SafeFormattedMessage {...messages.filter.sortByName} />
                        </MenuItem>
                        <MenuItem value={SortBy.CREATION_DATE}>
                            <SafeFormattedMessage {...messages.filter.sortByDate} />
                        </MenuItem>
                    </Select>
                </Stack>
                <Button type="primary" onClick={displayCreateFlowModal} disabled={!hasCreatePermission}>
                    <Typography variant="body2">
                        <SafeFormattedMessage tagName="span" {...messages.filter.newFlow} />
                    </Typography>
                </Button>
            </Stack>
            <ModalCreateFlow visible={showCreateFlowModal} close={hideCreateFlowModal} />
        </Card>
    )
}
