import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { MessageDescriptor } from "react-intl"
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistStore } from "redux-persist"
import createSagaMiddleware from "redux-saga"

import documentReducer from "~/components/UploadDocument/store/documentSlice"
import { ocrApi } from "~/domains/_shared/ocr/api/ocrApi_RTK"
import { metricsApi } from "~/domains/analytics/metrics/api/metricsApi"
import { tagsReducer } from "~/domains/analytics/tags/store/tagsSlice"
import { tagsApiV1 } from "~/domains/analytics/tags/tagsApiV1"
import { communicationReducer } from "~/domains/communication/chat/store/CommunicationSlice"
import { emailsReducer } from "~/domains/communication/emails/store/emailsSlice"
import { tasksApi } from "~/domains/communication/tasks/api/tasksApi"
import { userV1Api } from "~/domains/identity/account/api/userV1Api"
import { customFieldsReducer } from "~/domains/identity/custom-fields/store/customFieldsSlice"
import { customFormsApi } from "~/domains/identity/custom-forms/api/customFormsApi"
import { documentsReducer } from "~/domains/identity/documents/store/documentsSlice"
import { organizationV2Api } from "~/domains/identity/organization/api/organisationV2Api"
import { partnersApiV1 } from "~/domains/identity/partners/api/partnerApiV1"
import bookOfRelationsReducer from "~/domains/identity/partners/store/bookOfRelationsSlice"
import { permissionsApi } from "~/domains/identity/roles-permissions/api/permissionsApi"
import { rolesApi } from "~/domains/identity/roles-permissions/api/rolesApi"
import { sharesApi } from "~/domains/identity/roles-permissions/api/sharesApi"
import { spiceDbApi } from "~/domains/identity/roles-permissions/api/spiceDbApi"
import rolesPermissionsReducer from "~/domains/identity/roles-permissions/store/rolesPermissionsSlice"
import { userV2Api } from "~/domains/identity/users-v2/api/userV2Api"
import { copilotApi } from "~/domains/orchestration/copilot/api/copilotApi"
import { processDocumentApi } from "~/domains/orchestration/copilot/api/processDocumentApi"
import { approvalApi } from "~/domains/orchestration/flows-v0/api/approvalApi"
import { flowsApi } from "~/domains/orchestration/flows/api/flowsApi"
import { runsApi } from "~/domains/orchestration/flows/api/runsApi"
import { paymentBatchesApi } from "~/domains/payment/payment-batches/api/paymentBatchesApi"
import { paymentMethodDetailsApi } from "~/domains/payment/payment-method-details/api/paymentMethodDetailsApi"
import paymentMethodsDetailsReducer from "~/domains/payment/payment-method-details/store/paymentMethodDetailsSlice"
import { paymentMethodsApi } from "~/domains/payment/payment-methods/api/paymentMethodsApi"
import { paymentTermsApi } from "~/domains/payment/payment-terms/api/paymentTermsApi"
import { paymentApi } from "~/domains/payment/payment/api/paymentApi"
import documentRelationsReducer from "~/domains/transactions/_shared/document-relations/store/documentRelationsSlice"
import { budgetApi } from "~/domains/transactions/budget/api/budgetApi_"
import { catalogApi } from "~/domains/transactions/catalog-v1/api/catalogApi"
import { catalogImportApi } from "~/domains/transactions/catalog-v1/api/catalogImportApi"
import { catalogProductApi } from "~/domains/transactions/catalog-v1/api/catalogProductApi"
import { catalogProductSearchApi } from "~/domains/transactions/catalog-v1/api/catalogProductSearchApi"
import { catalogProductVariantApi } from "~/domains/transactions/catalog-v1/api/catalogProductVariantApi"
import { catalogProductVariantMediaApi } from "~/domains/transactions/catalog-v1/api/catalogProductVariantMediaApi"
import { catalogProductVariantPriceApi } from "~/domains/transactions/catalog-v1/api/catalogProductVariantPriceApi"
import { catalogReducer } from "~/domains/transactions/catalog/store/catalogSlice"
import { invoiceApi } from "~/domains/transactions/invoices-v1/api/invoiceApi"
import { invoiceAuditLogApi } from "~/domains/transactions/invoices-v1/api/invoiceAuditLogApi"
import { invoiceLifeCycleApi } from "~/domains/transactions/invoices-v1/api/invoiceLifeCycleApi"
import { vatApiV1 } from "~/domains/transactions/invoices-v1/api/vatApi"
import { purchaseOrderApi } from "~/domains/transactions/purchase-orders/api/purchaseOrderApi"
import purchaseOrdersReducer from "~/domains/transactions/purchase-orders/store/purchaseOrdersSlice"
import purchaseRequestsReducer from "~/domains/transactions/purchase-requests/store/purchaseRequestsSlice"
import authReducer from "~/store/auth/authSlice"
import errorsReducer from "~/store/errors/errorsSlice"
import globalReducer from "~/store/global/globalSlice"
import invoiceReducer from "~/store/invoice/invoiceSlice"
import ocrReducer from "~/store/ocr/ocrSlice"
import { errorLoggerMiddleware } from "~/utils/api/errorLoggerMiddleware"

import accountReducer from "./account/accountSlice"
import budgetReducer from "./budget/budgetSlice"
import organizationReducer from "./organization/organizationSlice"
import rootSaga from "./rootSaga"
import usersReducer from "./users/usersSlice"
import workflowReducer from "./workflow/workflowSlice"

const rootReducer = combineReducers({
    errors: errorsReducer,
    global: globalReducer,
    auth: authReducer,
    invoice: invoiceReducer,
    ocr: ocrReducer,
    organization: organizationReducer,
    account: accountReducer,
    tags: tagsReducer,
    users: usersReducer,
    workflow: workflowReducer,
    purchaseRequests: purchaseRequestsReducer,
    purchaseOrders: purchaseOrdersReducer,
    rolesPermissions: rolesPermissionsReducer,
    budget: budgetReducer,
    document: documentReducer,
    customFields: customFieldsReducer,
    documentRelations: documentRelationsReducer,
    communication: communicationReducer,
    bookOfRelations: bookOfRelationsReducer,
    catalog: catalogReducer,
    emails: emailsReducer,
    documents: documentsReducer,
    paymentMethodDetails: paymentMethodsDetailsReducer,
    [flowsApi.reducerPath]: flowsApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [paymentMethodsApi.reducerPath]: paymentMethodsApi.reducer,
    [paymentMethodDetailsApi.reducerPath]: paymentMethodDetailsApi.reducer,
    [paymentTermsApi.reducerPath]: paymentTermsApi.reducer,
    [paymentBatchesApi.reducerPath]: paymentBatchesApi.reducer,
    [runsApi.reducerPath]: runsApi.reducer,
    [invoiceApi.reducerPath]: invoiceApi.reducer,
    [invoiceLifeCycleApi.reducerPath]: invoiceLifeCycleApi.reducer,
    [invoiceAuditLogApi.reducerPath]: invoiceAuditLogApi.reducer,
    [approvalApi.reducerPath]: approvalApi.reducer,
    [catalogApi.reducerPath]: catalogApi.reducer,
    [catalogImportApi.reducerPath]: catalogImportApi.reducer,
    [catalogProductApi.reducerPath]: catalogProductApi.reducer,
    [catalogProductSearchApi.reducerPath]: catalogProductSearchApi.reducer,
    [catalogProductVariantApi.reducerPath]: catalogProductVariantApi.reducer,
    [catalogProductVariantPriceApi.reducerPath]: catalogProductVariantPriceApi.reducer,
    [catalogProductVariantMediaApi.reducerPath]: catalogProductVariantMediaApi.reducer,
    [vatApiV1.reducerPath]: vatApiV1.reducer,
    [customFormsApi.reducerPath]: customFormsApi.reducer,
    [metricsApi.reducerPath]: metricsApi.reducer,
    [tagsApiV1.reducerPath]: tagsApiV1.reducer,
    [purchaseOrderApi.reducerPath]: purchaseOrderApi.reducer,
    [partnersApiV1.reducerPath]: partnersApiV1.reducer,
    [rolesApi.reducerPath]: rolesApi.reducer,
    [permissionsApi.reducerPath]: permissionsApi.reducer,
    [sharesApi.reducerPath]: sharesApi.reducer,
    [spiceDbApi.reducerPath]: spiceDbApi.reducer,
    [copilotApi.reducerPath]: copilotApi.reducer,
    [processDocumentApi.reducerPath]: processDocumentApi.reducer,
    [ocrApi.reducerPath]: ocrApi.reducer,
    [tasksApi.reducerPath]: tasksApi.reducer,
    [organizationV2Api.reducerPath]: organizationV2Api.reducer,
    [userV2Api.reducerPath]: userV2Api.reducer,
    [budgetApi.reducerPath]: budgetApi.reducer,
    [userV1Api.reducerPath]: userV1Api.reducer,
})

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    "ocr/uploadDocument",
                    "ocr/confirmInvoiceFailed",
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }).concat(
            sagaMiddleware,
            flowsApi.middleware,
            paymentApi.middleware,
            paymentMethodsApi.middleware,
            paymentMethodDetailsApi.middleware,
            paymentTermsApi.middleware,
            paymentBatchesApi.middleware,
            runsApi.middleware,
            invoiceApi.middleware,
            invoiceLifeCycleApi.middleware /* , routerMiddleware(history) */,
            invoiceAuditLogApi.middleware,
            approvalApi.middleware,
            catalogApi.middleware,
            catalogImportApi.middleware,
            catalogProductApi.middleware,
            catalogProductSearchApi.middleware,
            catalogProductVariantApi.middleware,
            catalogProductVariantPriceApi.middleware,
            catalogProductVariantMediaApi.middleware,
            vatApiV1.middleware,
            tagsApiV1.middleware,
            purchaseOrderApi.middleware,
            partnersApiV1.middleware,
            customFormsApi.middleware,
            metricsApi.middleware,
            rolesApi.middleware,
            permissionsApi.middleware,
            sharesApi.middleware,
            spiceDbApi.middleware,
            copilotApi.middleware,
            processDocumentApi.middleware,
            ocrApi.middleware,
            tasksApi.middleware,
            organizationV2Api.middleware,
            userV2Api.middleware,
            budgetApi.middleware,
            userV1Api.middleware,
            errorLoggerMiddleware
        ),
})
export const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

export type AppDispatch = typeof store.dispatch

export type RootState = ReturnType<typeof store.getState>

export type ErrorMeta = {
    errorMessage: MessageDescriptor
    /**
     * @deprecated
     * Use disableSentryException instead
     * Now, all errors are logged to Sentry by default
     */
    catchSentryException?: boolean
    disableSentryException?: boolean
    disableToast?: boolean
    tags?: Record<string, string>
}

export type ServerError = { error: string; message: string }
