import { Stack } from "@mui/material"
import { defineMessages, useIntl } from "react-intl"
import { generatePath } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import { commonMessages } from "~/common-messages"
import { Button, Loader, Modal, SafeFormattedMessage, Size } from "~/components"
import { CheckFileTypeResult, UploadDocumentBox } from "~/components/UploadDocument/UploadDocument"
import { useUploadOCRDocument } from "~/domains/_shared/ocr/hooks/useUploadDocument"
import { useUploadOCRDocumentV1 } from "~/domains/_shared/ocr/hooks/useUploadDocumentV1"
import { OCR_IMPORT } from "~/domains/_shared/ocr/routes"
import { InvoiceUserType } from "~/domains/transactions/invoices/types/InvoiceTypes"
import { selectPurchaseViewType } from "~/domains/transactions/purchase-orders/store/purchaseOrdersSlice"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { ViewTypeI } from "~/types"
import { Features } from "~/utils/featureFlag"
import { isFeatureEnabled } from "~/utils/featureFlag"
import { checkOneFileType } from "~/utils/upload"

const messages = defineMessages({
    title: {
        id: "transactions.purchaseOrder.documentRelations.uploadBox.title",
        defaultMessage: "Upload an invoice",
    },
    loading: {
        id: "purchase.orders.modalUploadInvoice.loadingMessage",
        defaultMessage: "Upload in progress...",
    },
    errorWrongFileType: {
        id: "transactions.purchaseOrder.documentRelations.uploadBox.errorWrongFileType",
        defaultMessage: "Wrong file format: we only accept PDF files.",
    },
    errorUpload: {
        id: "common.error.errorUploadingDocument",
        defaultMessage: "An error occurred while uploading the document.",
    },
})

const ACCEPTED_FILE_TYPES = "application/pdf"

interface ModalUploadInvoiceProps {
    open: boolean
    close: () => void
    purchaseOrderId: string
}

export const ModalUploadInvoice = ({ open, close, purchaseOrderId }: ModalUploadInvoiceProps) => {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()

    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const viewType = useAppSelector(selectPurchaseViewType)
    const initiator = viewType === ViewTypeI.buyer ? InvoiceUserType.BUYER : InvoiceUserType.SUPPLIER

    const isInvoiceV0Enabled = isFeatureEnabled(Features.InvoiceV0, currentOrganizationId)

    const { uploadOCRDocument: uploadOCRDocumentV1, loading: loading_V1 } = useUploadOCRDocumentV1(
        initiator,
        currentOrganizationId
    )

    const { uploadOCRDocument, loading: loading_V0 } = useUploadOCRDocument(initiator)

    const checkFileType = (file: File): CheckFileTypeResult =>
        checkOneFileType(file, ACCEPTED_FILE_TYPES, formatMessage(messages.errorWrongFileType))

    const redirectAfterUpload = (documentId: string) => {
        const searchParams = new URLSearchParams({ purchaseOrderId }).toString()
        navigate(
            `${generatePath(OCR_IMPORT, {
                initiator: viewType,
                documentId,
            })}?${searchParams}`
        )
    }
    const handleFile_V0 = async (file: File) => {
        const document = await uploadOCRDocument(file)

        if (!document?.id) {
            toast.error(formatMessage(messages.errorUpload))
            return
        }

        redirectAfterUpload(document.id)
    }

    const handleFile_V1 = async (file: File) => {
        const uploadedInvoice = await uploadOCRDocumentV1(file)

        if (!uploadedInvoice?.jobId) {
            toast.error(formatMessage(messages.errorUpload))
            return
        }

        redirectAfterUpload(uploadedInvoice.jobId)
    }

    const handleFile = !isInvoiceV0Enabled ? handleFile_V1 : handleFile_V0

    return (
        <Modal open={open} onClose={close} size={Size.MD}>
            <Modal.Header>
                <h4>
                    <SafeFormattedMessage {...messages.title} />
                </h4>
            </Modal.Header>

            <Modal.Content>
                {loading_V0 || loading_V1 ? (
                    <Stack alignItems="center" justifyContent="center" height="100%" gap={2}>
                        <Loader small />
                        <SafeFormattedMessage {...messages.loading} />
                    </Stack>
                ) : (
                    <UploadDocumentBox
                        handleFile={handleFile}
                        conditionMessage={formatMessage(commonMessages.uploadTip, {
                            FileTypes: ACCEPTED_FILE_TYPES,
                        })}
                        checkFileType={checkFileType}
                        accept={ACCEPTED_FILE_TYPES}
                    />
                )}
            </Modal.Content>

            <Modal.Footer>
                <Button type="transparent" onClick={close}>
                    <SafeFormattedMessage {...commonMessages.cancel} />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
