import { Stack } from "@mui/material"
import { DataGridPremiumProps, GridFooterContainer, GridPagination } from "@mui/x-data-grid-premium"

interface DataGridFooterProps extends DataGridPremiumProps {
    hideFooterRowCount?: boolean
}

export const DataGridFooter = (props: DataGridFooterProps) => {
    return !props.pagination ? null : (
        <GridFooterContainer>
            <Stack direction="row" justifyContent="flex-end" spacing={1} width="100%">
                <GridPagination />
            </Stack>
        </GridFooterContainer>
    )
}
