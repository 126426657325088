import { Stack } from "@mui/material"
import { FC, memo, useState } from "react"
import { DollarSign, FileText, Send, ShoppingBag, Users } from "react-feather"
import { defineMessages } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"

import { LEGACY_UPLOAD, NEW_UPLOAD_V1 } from "~/domains/_shared/ocr/routes"
import { DashboardCTAButton } from "~/domains/analytics/dashboard/components/DashboardCTAButton"
import { useDashboardPermissions } from "~/domains/analytics/dashboard/hooks/useDashboardPermissions"
import { ModalCreatePartnership } from "~/domains/identity/partners/components/ModalCreatePartnership/ModalCreatePartnership"
import { INVOICES_ROUTE } from "~/domains/transactions/invoices/routes"
import { PURCHASE_ORDER_NEW_ROUTE } from "~/domains/transactions/purchase-orders/routes"
import { PURCHASE_REQUEST_NEW_ROUTE } from "~/domains/transactions/purchase-requests/routes"
import { accountActions } from "~/store/account/accountSlice"
import { useAppDispatch } from "~/store/hooks"
import { InvoicesTab, OrganizationId, ViewTypeI } from "~/types"
import { Features, isFeatureEnabled } from "~/utils/featureFlag"

const messages = defineMessages({
    buttonRequestPurchase: {
        id: "dashboard.button.requestPurchase",
        defaultMessage: "Request a purchase",
    },
    buttonCreatePO: {
        id: "dashboard.button.importPO",
        defaultMessage: "Create a purchase order",
    },
    buttonAddPartner: {
        id: "dashboard.button.addPartner",
        defaultMessage: "Add a new partner",
    },
    importInvoice: {
        id: "dashboard.button.importInvoice",
        defaultMessage: "Import an invoice",
    },
    payInvoice: {
        id: "dashboard.button.payInvoice",
        defaultMessage: "Pay an invoice",
    },
})

interface Props {
    currentOrganizationId: OrganizationId | undefined
}

export const DashboardCallToActions: FC<Props> = memo(({ currentOrganizationId }) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const [showCreatePartnershipModal, setShowCreatePartnershipModal] = useState(false)
    const dashboardPermissions = useDashboardPermissions(currentOrganizationId)

    const isInvoicev0Enabled = isFeatureEnabled(Features.InvoiceV0, currentOrganizationId)

    const handlePurchaseRequest = () => {
        navigate(PURCHASE_REQUEST_NEW_ROUTE)
    }

    const handlePurchaseOrder = () => {
        navigate(PURCHASE_ORDER_NEW_ROUTE)
    }

    const handleVendorManagement = () => {
        setShowCreatePartnershipModal(true)
    }

    const handleImportInvoice = () => {
        if (!isInvoicev0Enabled) {
            navigate(NEW_UPLOAD_V1)
        } else {
            navigate(LEGACY_UPLOAD)
        }
    }
    const handlePayInvoice = () => {
        dispatch(accountActions.setCurrentInvoicesTab(InvoicesTab.PENDING_PAYMENT))
        navigate(generatePath(INVOICES_ROUTE, { view: ViewTypeI.buyer }))
    }

    const hideCreatePartnershipModal = () => setShowCreatePartnershipModal(false)
    const dashboardCTAButtons = [
        {
            icon: <Send size={24} />,
            label: messages.buttonRequestPurchase,
            onClick: handlePurchaseRequest,
            disabled: !dashboardPermissions.createPurchaseRequests,
        },
        {
            icon: <ShoppingBag size={24} />,
            label: messages.buttonCreatePO,
            onClick: handlePurchaseOrder,
            disabled: !dashboardPermissions.createPurchaseOrders,
        },
        {
            icon: <Users size={24} />,
            label: messages.buttonAddPartner,
            onClick: handleVendorManagement,
            disabled: !dashboardPermissions.createPartnership,
        },
        {
            icon: <FileText size={24} />,
            label: messages.importInvoice,
            onClick: handleImportInvoice,
            disabled: !dashboardPermissions.createInvoices,
        },
        {
            icon: <DollarSign size={24} />,
            label: messages.payInvoice,
            onClick: handlePayInvoice,
            disabled: !dashboardPermissions.payInvoices,
        },
    ]

    return (
        <>
            <Stack direction={{ xs: "column", sm: "row" }} gap={2} justifyContent="stretch">
                {dashboardCTAButtons.map((button) => (
                    <DashboardCTAButton key={button.label.id} {...button} />
                ))}
            </Stack>

            {currentOrganizationId && (
                <ModalCreatePartnership
                    open={showCreatePartnershipModal}
                    close={hideCreatePartnershipModal}
                    organizationId={currentOrganizationId}
                />
            )}
        </>
    )
})

DashboardCallToActions.displayName = "DashboardCallToActions"
