import { useHasPermissions } from "~/domains/identity/roles-permissions/store/hooks"
import { DomainName, ScopeName } from "~/domains/identity/roles-permissions/types/RolesPermissions"
import { AuthorizationName, OrganizationId } from "~/types"

export const useSidebarPermissions = (currentOrganizationId: OrganizationId | undefined) => {
    const { hasPermission: hasBudgetsPermission } = useHasPermissions(
        currentOrganizationId ?? "",
        AuthorizationName.READ,
        DomainName.TRANSACTION,
        ScopeName.BUDGETS
    )

    const { hasPermission: hasWorkflowsPermission } = useHasPermissions(
        currentOrganizationId ?? "",
        AuthorizationName.READ,
        DomainName.ORCHESTRATION,
        ScopeName.WORKFLOWS
    )

    const { hasPermission: hasInvoicesPermission } = useHasPermissions(
        currentOrganizationId ?? "",
        AuthorizationName.READ,
        DomainName.TRANSACTION,
        ScopeName.INVOICES
    )

    const { hasPermission: hasReadBookOfRelationsPermission } = useHasPermissions(
        currentOrganizationId ?? "",
        AuthorizationName.READ,
        DomainName.TRANSACTION,
        ScopeName.BOOK_OF_RELATIONS
    )

    const { hasPermission: hasCreateBookOfRelationsPermission } = useHasPermissions(
        currentOrganizationId ?? "",
        AuthorizationName.CREATE,
        DomainName.TRANSACTION,
        ScopeName.BOOK_OF_RELATIONS
    )

    return {
        hasBudgetsPermission,
        hasWorkflowsPermission,
        hasInvoicesPermission,
        hasBookOfRelationsPermission: hasReadBookOfRelationsPermission || hasCreateBookOfRelationsPermission,
    }
}
