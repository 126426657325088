import { Checkbox, IconButton, TableCell, TextField } from "@mui/material"
import debounce from "lodash/debounce"
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import { X } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { ConfirmModal } from "~/components"
import { TagObjectType } from "~/domains/analytics/tags/types"
import { LineTags } from "~/domains/transactions/_shared/components/Items/LineTags"
import { VatRateI } from "~/domains/transactions/invoices-v1/types/VatRate"
import { appendInvoiceCustomFieldsToLine } from "~/domains/transactions/invoices-v1/utils/appendInvoiceCustomFieldsToLines"
import { getTemplateLineCustomFields } from "~/domains/transactions/invoices-v1/utils/getTemplateLineCustomFields"
import { EditAmount } from "~/domains/transactions/invoices/_views/supplier/components"
import { PriceField } from "~/domains/transactions/invoices/components/InvoiceLines/PriceField"
import { QuantityField } from "~/domains/transactions/invoices/components/InvoiceLines/QuantityField"
import "~/domains/transactions/invoices/components/ModalInvoiceLines/InvoiceLine.scss"
import { InvoiceLineTaxField } from "~/domains/transactions/invoices/components/ModalInvoiceLines/InvoiceLineTaxField"
import { InvoiceLineError, InvoiceLineErrorType } from "~/domains/transactions/invoices/core"
import {
    CountryCode,
    DocumentWithLines,
    DocumentWithPrice,
    DocumentWithVersion,
    InvoiceI,
    InvoiceLineI,
    InvoiceWithId,
    OrganizationId,
    UserI,
} from "~/types"

const messages = defineMessages({
    reference: {
        id: "invoice.invoiceLines.reference",
        defaultMessage: "Reference",
    },
    quantity: {
        id: "invoice.invoiceLines.quantity",
        defaultMessage: "Quantity",
    },
    tax: {
        id: "invoice.invoiceLines.tax",
        defaultMessage: "Tax",
    },
    unitPrice: {
        id: "invoice.invoiceLines.unitPrice",
        defaultMessage: "Unit price",
    },
    description: {
        id: "invoice.invoiceLines.description",
        defaultMessage: "Description",
    },
    total: {
        id: "invoice.invoiceLines.total",
        defaultMessage: "Total",
    },
    discountTotal: {
        id: "invoice.invoiceLines.discountTotal",
        defaultMessage: "Discount",
    },
    totalExcludedTaxes: {
        id: "invoice.invoiceLines.totalExcludedTaxes",
        defaultMessage: "Total tax excl.",
    },
    confirmDeleteLineTitle: {
        id: "invoice.invoiceLines.confirmDeleteModal.title",
        defaultMessage: "Are you sure to delete this item?",
    },
    confirmDeleteLineMessage: {
        id: "invoice.invoiceLines.confirmDeleteModal.confirmMessage",
        defaultMessage: "This action cannot be undone, this item will be permanently lost.",
    },
})

interface Props {
    countryCode: CountryCode
    invoiceLine: InvoiceLineI
    invoice: DocumentWithLines & DocumentWithPrice & InvoiceWithId & DocumentWithVersion
    user: UserI
    onChange: (linePosition: number, updatePayload: Partial<InvoiceLineI>) => void
    onDelete: (linePosition: number) => Promise<boolean>
    onSelect: (linePosition: number, isChecked: boolean) => void
    isSelected: boolean
    organizationId?: OrganizationId
    error?: InvoiceLineError | undefined
    readonly?: boolean
    fieldClassName?: string
    highlight?: boolean
}

export const InvoiceLine: React.FC<Props> = ({
    countryCode,
    invoice,
    user,
    invoiceLine,
    organizationId,
    onChange,
    onDelete,
    onSelect,
    isSelected,
    error,
    readonly = false,
    fieldClassName = "",
    highlight,
}) => {
    const { formatMessage } = useIntl()
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [line, setLine] = useState<InvoiceLineI>(invoiceLine)

    const displayConfirmModal = useCallback(() => setShowConfirmModal(true), [])
    const hideConfirmModal = useCallback(() => setShowConfirmModal(false), [])

    useEffect(() => {
        if (invoiceLine !== line) {
            setLine(invoiceLine)
        }
    }, [invoiceLine])

    const debouncedOnChange = useCallback(
        debounce((value: Partial<InvoiceLineI>) => {
            onChange?.(line.linePosition, value)
        }, 500),
        [onChange]
    )

    const handleDelete = () => {
        if (!line.id) {
            onDelete?.(line.linePosition)
        } else {
            displayConfirmModal()
        }
    }

    const deleteLine = useCallback(() => {
        return onDelete(line.linePosition)
    }, [onDelete])

    const updateUnitPrice = useCallback(
        (newAmount: number | null) => {
            setLine((prev) => ({ ...prev, unitPrice: newAmount }) as InvoiceLineI)
            debouncedOnChange({ unitPrice: newAmount ?? undefined })
        },
        [line.linePosition, debouncedOnChange]
    )

    const updateTotalExcludedTaxes = useCallback(
        (newAmount: number | null) => {
            setLine((prev) => ({ ...prev, totalExcludedTaxes: newAmount }) as InvoiceLineI)
            debouncedOnChange({ totalExcludedTaxes: newAmount ?? undefined })
        },
        [line.linePosition, debouncedOnChange]
    )

    const updateDiscountTotal = useCallback(
        (newAmount: number | null) => {
            setLine((prev) => ({ ...prev, discountTotal: newAmount }) as InvoiceLineI)
            debouncedOnChange({ discountTotal: newAmount ?? undefined })
        },
        [line.linePosition, debouncedOnChange]
    )

    const updateTotal = useCallback(
        (newAmount: number | null) => {
            setLine((prev) => ({ ...prev, total: newAmount }) as InvoiceLineI)
            debouncedOnChange({ total: newAmount ?? undefined })
        },
        [line.linePosition, debouncedOnChange]
    )

    /* const onReferenceChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setLine((prev) => ({ ...prev, productReference: event.currentTarget.value }) as InvoiceLineI)
            debouncedOnChange({ productReference: event.currentTarget.value })
        },
        [line.linePosition, onChange]
    ) */

    const onQuantityChange = useCallback(
        (quantity: number | null) => {
            if (quantity) {
                setLine((prev) => ({ ...prev, quantity }) as InvoiceLineI)
                debouncedOnChange({ quantity })
            }
        },
        [line.linePosition, debouncedOnChange]
    )

    const onDescriptionChange = useCallback(
        (event: ChangeEvent<HTMLTextAreaElement>) => {
            const value = event.currentTarget.value
            setLine((prev) => ({ ...prev, description: value }) as InvoiceLineI)
            debouncedOnChange({ description: value })
        },
        [line.linePosition, debouncedOnChange]
    )

    const onTotalTaxChange = useCallback(
        (totalTax: number) => {
            setLine((prev) => ({ ...prev, totalTax }) as InvoiceLineI)
            debouncedOnChange({ totalTax })
        },
        [line.linePosition, debouncedOnChange]
    )

    const onVatRateChange = useCallback(
        (taxRate: VatRateI | null) => {
            const update: Partial<InvoiceLineI> = {
                taxRateId: taxRate ? taxRate.id : null,
            }
            if (taxRate) {
                update.totalTax = (line.totalExcludedTaxes * taxRate.rate) / 100
                update.total = line.totalExcludedTaxes + update.totalTax
            }
            setLine((prev) => ({ ...prev, ...update }) as InvoiceLineI)
            debouncedOnChange(update)
        },
        [line.linePosition, line.totalExcludedTaxes, debouncedOnChange]
    )

    const onCustomFieldChange = (
        field: keyof InvoiceLineI,
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const parties = line.parties || {}
        const customFields = parties[organizationId ?? ""]?.customFields || {}
        const updatedParties = {
            ...parties,
            [organizationId ?? ""]: {
                ...parties[organizationId ?? ""],
                customFields: {
                    ...customFields,
                    [field]: event.currentTarget.value,
                },
            },
        }

        setLine((prev) => ({ ...prev, parties: updatedParties }) as InvoiceLineI)
        debouncedOnChange({ customField: { [field]: event.currentTarget.value } })
    }

    const onErrorClick = useCallback(
        (e: InvoiceLineError) => {
            if (e.error === InvoiceLineErrorType.TotalDoesNotMatchComputedTotal) {
                updateTotal(e.computedValueError)
            } else if (
                e.error === InvoiceLineErrorType.UnitPriceAndQuantityDoesNotMatchTotalExcludedTaxes ||
                e.error === InvoiceLineErrorType.TotalDoesNotTakeChargeOfDiscount
            ) {
                updateTotalExcludedTaxes(e.computedValueError)
            }
        },
        [updateTotal, updateTotalExcludedTaxes]
    )

    const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        onSelect(line.linePosition, event.target.checked)
    }

    const lineWithCustomFields = useMemo(
        () => appendInvoiceCustomFieldsToLine(line, invoice as InvoiceI, organizationId || ""),
        [line, invoice, organizationId]
    )

    const templateLineCustomFields = getTemplateLineCustomFields(invoice as InvoiceI, organizationId || "")

    return (
        <>
            <TableCell className="flex-1">
                <Checkbox onChange={handleSelect} checked={isSelected} />
            </TableCell>
            <TableCell className="column-200">
                <TextField
                    className={`invoice-line-field invoice-line-description ${fieldClassName}`}
                    name="line-description"
                    placeholder={formatMessage(messages.description)}
                    onChange={onDescriptionChange}
                    value={line.description ? line.description.split(/<br ?\/>/i).join("\n") : ""}
                    inputProps={{
                        readOnly: readonly,
                    }}
                    aria-readonly={readonly}
                />
            </TableCell>
            {
                // Comment the column for now, since the feature is missing in the backend
                /* <TableCell>
                <TextField
                    className={`invoice-line-field invoice-line-reference ${fieldClassName}`}
                    name="line-reference"
                    placeholder={formatMessage(messages.reference)}
                    onChange={onReferenceChange}
                    value={line.productReference || ""}
                    required
                    inputProps={{
                        readOnly: readonly,
                    }}
                    aria-readonly={readonly}
                />
            </TableCell> */
            }

            {Object.entries(lineWithCustomFields?.customFields || {})?.map(([id, value], index) => (
                <TableCell key={id}>
                    <TextField
                        className={`invoice-line-field invoice-line-reference ${fieldClassName}`}
                        name={id}
                        placeholder={templateLineCustomFields?.[index]?.name}
                        onChange={(event) => onCustomFieldChange(id as keyof InvoiceLineI, event)}
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        value={value || ""}
                        required
                        inputProps={{
                            readOnly: readonly,
                        }}
                        aria-readonly={readonly}
                    />
                </TableCell>
            ))}

            <TableCell className="column-max-20">
                <QuantityField
                    quantity={line.quantity}
                    quantityLabel=""
                    onErrorClick={onErrorClick}
                    onQuantityChange={onQuantityChange}
                    error={error}
                    readonly={readonly}
                    fieldClassName={fieldClassName}
                    minwidth="20px"
                />
            </TableCell>
            <TableCell>
                <PriceField
                    price={line.unitPrice}
                    currency={line.currency}
                    fieldName="unitPrice"
                    onPriceChange={updateUnitPrice}
                    priceLabel=""
                    error={error}
                    readonly={readonly}
                    fieldClassName={fieldClassName}
                    onErrorClick={onErrorClick}
                />
            </TableCell>
            <TableCell>
                <EditAmount
                    className={`invoice-line-field invoice-line-amount ${fieldClassName}`}
                    value={line.discountTotal ?? 0}
                    currency={line.currency}
                    placeholder={formatMessage(messages.discountTotal)}
                    handleUpdate={updateDiscountTotal}
                    disableHelpers
                    inputProps={{
                        readOnly: readonly,
                    }}
                    aria-readonly={readonly}
                    error={error?.fields.includes("discountTotal")}
                    hideLabel
                />
            </TableCell>
            <TableCell>
                <InvoiceLineTaxField
                    vatRateId={line.taxRateId || null}
                    onVatRateChange={onVatRateChange}
                    totalTax={line.totalTax}
                    onTotalTaxChange={onTotalTaxChange}
                    classes={{
                        root: `invoice-line-field invoice-line-tax ${fieldClassName} ${
                            highlight ? "invoice-line-highlight" : ""
                        }`,
                    }}
                    countryCode={countryCode}
                    backendVersion={invoice.version}
                    readonly={readonly}
                    error={error?.fields.includes("totalTax")}
                    hideLabel
                />
            </TableCell>
            <TableCell>
                <PriceField
                    price={line.totalExcludedTaxes}
                    currency={line.currency}
                    fieldName="totalExcludedTaxes"
                    onPriceChange={updateTotalExcludedTaxes}
                    priceLabel={formatMessage(messages.totalExcludedTaxes)}
                    error={error}
                    readonly={readonly}
                    fieldClassName={fieldClassName}
                    onErrorClick={onErrorClick}
                    hideLabel
                />
            </TableCell>
            <TableCell>
                {user && organizationId && (
                    <LineTags
                        organizationId={organizationId}
                        line={line}
                        objectId={`${invoice.id}-${line.linePosition}`}
                        objectType={TagObjectType.INVOICE_LINE}
                    />
                )}
            </TableCell>
            <TableCell>
                {!readonly && (
                    <IconButton onClick={handleDelete}>
                        <X className="invoice-line-delete-btn" />
                    </IconButton>
                )}
            </TableCell>
            <ConfirmModal
                title={formatMessage(messages.confirmDeleteLineTitle)}
                open={showConfirmModal}
                close={hideConfirmModal}
                onConfirm={deleteLine}
            >
                {formatMessage(messages.confirmDeleteLineMessage)}
            </ConfirmModal>
        </>
    )
}
