import {
    useCreatePaymentTermObjectMutation,
    useDeletePaymentTermObjectMutation,
    useGetPaymentTermObjectsQuery,
    useGetPaymentTermsByObjectQuery,
    useGetPaymentTermsQuery,
    useUpdatePaymentTermObjectMutation,
} from "~/domains/payment/payment-terms/api/paymentTermsApi"
import { ObjectType } from "~/domains/payment/types"

interface ManagePaymentTerms {
    objectId?: string
    objectType?: ObjectType
}

interface ManagePaymentTermsOptions {
    showList?: boolean
}

export const useManagePaymentTerms = (
    { objectId, objectType }: ManagePaymentTerms,
    { showList = true }: ManagePaymentTermsOptions
) => {
    const [createPaymentTermObject, { isLoading: isLoadingCreatePaymentTermObject }] =
        useCreatePaymentTermObjectMutation()
    const [updatePaymentTermObject, { isLoading: isLoadingUpdatePaymentTermObject }] =
        useUpdatePaymentTermObjectMutation()
    const [deletePaymentTermObject, { isLoading: isLoadingDeletePaymentTermObject }] =
        useDeletePaymentTermObjectMutation()
    const { data: objectPaymentTermsData, isLoading: isLoadingOrgPaymentTerms } = useGetPaymentTermsByObjectQuery(
        objectId ?? "",
        {
            skip: !objectId,
            refetchOnMountOrArgChange: true,
        }
    )
    const objectPaymentTerms = objectPaymentTermsData?.items ?? []
    const objectPaymentTerm = objectPaymentTerms[0] ?? null

    const { data: paymentTermsData, isLoading: isLoadingPaymentTerms } = useGetPaymentTermsQuery(null, {
        skip: !showList,
    })
    const paymentTerms = paymentTermsData?.items ?? []

    const { data: paymentTermsObjectsData, isLoading: isLoadingPaymentTermsObject } = useGetPaymentTermObjectsQuery(
        { object_id: objectId ?? "" },
        { refetchOnMountOrArgChange: true, skip: !showList || !objectId }
    )
    const paymentTermsObjects =
        paymentTermsObjectsData?.items?.filter(({ object_type }) => !object_type || object_type === objectType) ?? []
    const defaultPaymentTermObject = paymentTermsObjects.find((item) => item.is_default) ?? null

    const loading =
        isLoadingPaymentTerms ||
        isLoadingOrgPaymentTerms ||
        isLoadingCreatePaymentTermObject ||
        isLoadingUpdatePaymentTermObject ||
        isLoadingDeletePaymentTermObject ||
        isLoadingPaymentTermsObject

    const deleteObject = async (paymentTermsObjectId: string) => {
        await deletePaymentTermObject(paymentTermsObjectId)
    }

    return {
        loading,
        objectPaymentTerm,
        paymentTerms,
        defaultPaymentTermObject,
        createPaymentTermObject,
        updatePaymentTermObject,
        deleteObject,
    }
}
