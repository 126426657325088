import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { defineMessages } from "react-intl"

import { prepareHeadersWithAuthorization } from "~/api/prepareHeaders"
import { paginatedTasksApiAdapter } from "~/domains/communication/tasks/api/paginatedTasksApiAdapter"
import { taskFromApiAdapter } from "~/domains/communication/tasks/api/taskFromApiAdapter"
import { CreateTaskPayload } from "~/domains/communication/tasks/types"
import { TaskI, TaskId } from "~/domains/communication/tasks/types/Task"
import { TaskQueryParams } from "~/domains/communication/tasks/types/TaskQueryParams"
import { OrganizationId } from "~/types"
import { PaginatedResponse } from "~/types/Pagination"

const errors = defineMessages({
    errorCreatingTask: {
        id: "communication.tasks.error.errorCreatingTask",
        defaultMessage: "An error occurred while creating a new task.",
    },
    errorFetchTasks: {
        id: "communication.tasks.error.errorFetchTasks",
        defaultMessage: "An error occurred while fetching tasks.",
    },
})

const BASE_URL = import.meta.env.VITE_API_TASKS_URL

type UpdateTaskPayload = Partial<CreateTaskPayload> & { organizationId: OrganizationId; taskId: TaskId }

export const tasksApi = createApi({
    reducerPath: "tasksApi",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        prepareHeaders: prepareHeadersWithAuthorization,
    }),
    tagTypes: ["Task"],
    endpoints: (builder) => ({
        createTask: builder.mutation<TaskI, CreateTaskPayload & { organizationId: OrganizationId }>({
            query: ({ organizationId, ...createTaskPayload }) => ({
                url: `organizations/${organizationId}/tasks/`,
                method: "POST",
                body: createTaskPayload,
            }),
            invalidatesTags: ["Task"],
            transformErrorResponse: (response) => ({
                meta: {
                    errorMessage: errors.errorCreatingTask,
                    catchSentryException: true,
                },
                ...response,
            }),
            transformResponse: (response) => taskFromApiAdapter(response),
        }),
        fetchTasks: builder.query<PaginatedResponse<TaskI>, TaskQueryParams & { organizationId: OrganizationId }>({
            query: ({ organizationId, ...queryParams }) => ({
                url: `organizations/${organizationId}/tasks/`,
                params: queryParams,
            }),
            providesTags: (result) =>
                result ? [...result.items.map(({ id }) => ({ type: "Task" as const, id })), "Task"] : ["Task"],
            transformErrorResponse: (response) => ({
                meta: {
                    errorMessage: errors.errorCreatingTask,
                    catchSentryException: true,
                },
                ...response,
            }),
            transformResponse: (response) => {
                return paginatedTasksApiAdapter(response)
            },
        }),
        fetchTask: builder.query<TaskI, { organizationId: OrganizationId; taskId: TaskId }>({
            query: ({ organizationId, taskId }) => ({
                url: `organizations/${organizationId}/tasks/${taskId}`,
            }),
            providesTags: (task) => (task ? [{ type: "Task" as const, id: task.id }] : ["Task"]),
            transformErrorResponse: (response) => ({
                meta: {
                    errorMessage: errors.errorCreatingTask,
                    catchSentryException: true,
                },
                ...response,
            }),
            transformResponse: (response) => {
                return taskFromApiAdapter(response)
            },
        }),
        updateTask: builder.mutation<TaskI, UpdateTaskPayload>({
            query: ({ organizationId, taskId, ...updateTaskPayload }) => ({
                url: `organizations/${organizationId}/tasks/${taskId}`,
                method: "PUT",
                body: updateTaskPayload,
            }),
            invalidatesTags: (task) =>
                task
                    ? [task.parentTaskId ? { type: "Task", id: task.parentTaskId } : { type: "Task", id: task.id }]
                    : ["Task"],
            transformErrorResponse: (response) => ({
                meta: {
                    errorMessage: errors.errorCreatingTask,
                    catchSentryException: true,
                },
                ...response,
            }),
            transformResponse: (response) => taskFromApiAdapter(response),
        }),
    }),
})

export const { useCreateTaskMutation, useFetchTasksQuery, useFetchTaskQuery, useUpdateTaskMutation } = tasksApi
