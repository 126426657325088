import { Box, Stack, TextField } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs, { Dayjs } from "dayjs"
import { useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { SafeFormattedMessage, TooltipConditional } from "~/components"
import { permissionMessages } from "~/domains/identity/roles-permissions/utils/permissions"
import { useHasPaymentPermissions } from "~/domains/payment/hooks"
import { Payment } from "~/domains/payment/payment/types"

const messages = defineMessages({
    dateOfPayment: {
        id: "payment.document.paymentDetails.paymentDate",
        defaultMessage: "Payment date",
    },
})

interface DocumentPaymentDateProps {
    loading?: boolean
    value?: string | null
    label?: string
    name: string
    onPaymentUpdate: (payment: Partial<Payment>) => void
}

export const DocumentPaymentDate = ({
    value,
    label,
    name,
    onPaymentUpdate,
    loading = false,
}: DocumentPaymentDateProps) => {
    const { formatMessage, locale } = useIntl()
    const [paymentDate, setPaymentDate] = useState<string | null>(value ?? null)
    const paymentDateValue = paymentDate ? dayjs(paymentDate) : null

    const { permissions } = useHasPaymentPermissions({ authorizations: ["update"] })
    const hasUpdatePermission = Boolean(permissions?.update?.hasPermission)

    const handleChangePaymentDate = (date: Dayjs | null) => {
        if (!hasUpdatePermission) return
        const validDate = date ? date.toISOString() : null

        setPaymentDate(validDate)
        onPaymentUpdate({
            [name]: validDate,
        })
    }

    return (
        <Stack>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                <TooltipConditional
                    title={<SafeFormattedMessage {...permissionMessages.errorNoAccessAdministrator} />}
                    condition={!hasUpdatePermission}
                    placement="top"
                    arrow
                >
                    <Box component="span">
                        <DatePicker
                            views={["year", "month", "day"]}
                            label={label || formatMessage(messages.dateOfPayment)}
                            format="YYYY-MM-DD"
                            disabled={!hasUpdatePermission || loading}
                            value={paymentDateValue}
                            slots={{ textField: TextField }}
                            slotProps={{
                                textField: {
                                    variant: "outlined",
                                    fullWidth: true,
                                },
                            }}
                            onChange={handleChangePaymentDate}
                        />
                    </Box>
                </TooltipConditional>
            </LocalizationProvider>
        </Stack>
    )
}
