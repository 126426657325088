import { useEffect, useMemo } from "react"
import { useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { Card } from "~/components"
import { TagsSelector } from "~/domains/analytics/tags/components/TagsSelector"
import { selectPendingTags, tagsActions } from "~/domains/analytics/tags/store/tagsSlice"
import { SelectedTagI, TagObjectType } from "~/domains/analytics/tags/types"
import { selectUserId } from "~/store/account/accountSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"

interface PaymentBatchTagsProps {
    organizationId: string
    objectId?: string
}

export const PaymentBatchTags = ({ objectId, organizationId }: PaymentBatchTagsProps) => {
    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()
    const selectedTags = useAppSelector(selectPendingTags)
    const userId = useAppSelector(selectUserId)

    const tagSelectorContext = useMemo(
        () => ({
            page: "payment-batch",
            organizationId,
            userId,
            objectId,
        }),
        [organizationId, userId, objectId]
    )

    const setSelectedTags = (tagsOrFunction: SelectedTagI[] | ((state: SelectedTagI[]) => SelectedTagI[])) =>
        dispatch(tagsActions.setPendingTags(tagsOrFunction))

    useEffect(() => {
        return () => {
            dispatch(tagsActions.setPendingTags([]))
        }
    }, [])

    return (
        <Card title={formatMessage(commonMessages.tags)} expandable>
            {objectId ? (
                <TagsSelector
                    organizationId={organizationId}
                    objectId={objectId}
                    userId={userId}
                    objectType={TagObjectType.PAYMENT_BATCH}
                    context={tagSelectorContext}
                />
            ) : (
                <TagsSelector
                    organizationId={organizationId}
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                    tagsRecommandations={null}
                />
            )}
        </Card>
    )
}
