export enum VerificationMethod {
    DOCUMENT = "document",
}

export interface VerificationDocument {
    name?: string | null
    url?: string | null
    object_id?: string | null
    document_id: string
}

export interface ManualVerification {
    id?: string
    payment_method_details_id: string
    verification_date?: string
    verification_documents?: VerificationDocument
    verification_method: VerificationMethod
    verification_notes?: string
    verified_by_organization_id?: string
    verified_by_user_id?: string
}
