import { Hash } from "react-feather"

import { GetTagByGroupConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { RegularNode } from "~/domains/orchestration/flows/components/nodes"
import { adaptEntityTriggerProperties } from "~/domains/orchestration/flows/core"
import { adaptEventTriggerProperties } from "~/domains/orchestration/flows/core"
import { isEventTriggerType } from "~/domains/orchestration/flows/types"
import { Trigger } from "~/domains/orchestration/flows/types"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    EditorNode,
    GetTagByGroupNode as GetTagByGroupNodeType,
    Node,
    NodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const getTagByGroupNode = (trigger: Trigger): Omit<GetTagByGroupNodeType, keyof Node> => {
    const { objectId } = isEventTriggerType(trigger)
        ? adaptEventTriggerProperties(trigger.type, trigger.slug)
        : adaptEntityTriggerProperties(trigger.type, trigger.slug)
    return {
        objectId,
        tagGroupId: "",
        type: NodeType.GET_TAG_BY_GROUP_NODE,
        nextNode: null,
    }
}

const advancedFields: AllAdvancedFieldsUnion[] = [AllAdvancedFieldsUnion.OBJECT_ID]

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.GET_TAG_BY_GROUP_NODE) return false
    return Boolean(node.objectId && node.tagGroupId)
}

const getTagByGroupConfig: Configuration<NodeType.GET_TAG_BY_GROUP_NODE> = {
    type: NodeType.GET_TAG_BY_GROUP_NODE,
    baseSlug: "get-tag-by-group",
    Icon: Hash,
    Configuration: GetTagByGroupConfiguration,
    advancedFields,
    Node: RegularNode,
    factory: (props: FactoryProps): GetTagByGroupNodeType => ({
        ...getTagByGroupNode(props.trigger),
        ...createCommonNodeProperties(props),
    }),
    validateNode,
}

export { getTagByGroupConfig, advancedFields as getTagByGroupAdvancedFields }
