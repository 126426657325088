import { Tooltip } from "@mui/material"
import { useState } from "react"
import { Check } from "react-feather"

import { commonMessages } from "~/common-messages"
import { Button, SafeFormattedMessage } from "~/components"
import { ApprovalOverrideModal } from "~/domains/orchestration/flows-v0/components/ApprovalBlock/ApprovalOverrideModal"
import { useProcessBulkReviewItems } from "~/domains/orchestration/flows-v0/hooks/useProcessBulkReviewItems"
import { ApprovalObjectType } from "~/domains/orchestration/flows-v0/types/Approval"
import { useFetchPurchaseOrder } from "~/domains/transactions/purchase-orders/store/hooks/useFetchPurchaseOrder"
import { useUpdatePurchaseOrderApprovalStatus } from "~/domains/transactions/purchase-orders/store/hooks/useUpdatePurchaseOrderApprovalStatus"
import { PurchaseOrders } from "~/domains/transactions/purchase-orders/types"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"

interface ApprovePOProps {
    PO: PurchaseOrders
    needsNewApproval: boolean
    isFlowApprovalDone: boolean
}

export const ApprovePO = ({ PO, needsNewApproval, isFlowApprovalDone }: ApprovePOProps) => {
    const organizationId = useAppSelector(selectCurrentOrganizationId)
    const { processBulkReviewItems } = useProcessBulkReviewItems()
    const { updatePOApprovalStatus } = useUpdatePurchaseOrderApprovalStatus(PO.id)
    const [showModal, setShowModal] = useState(false)
    const { fetchPurchaseOrder } = useFetchPurchaseOrder(organizationId)

    const approve = async () => {
        if (!organizationId) return
        const lineIds = PO.lines.map((line) => line.id ?? "")
        if (needsNewApproval) {
            await processBulkReviewItems(lineIds, true, ApprovalObjectType.PURCHASE_ORDER_LINE)
        }
        await updatePOApprovalStatus(organizationId, lineIds, "APPROVED")
        await fetchPurchaseOrder(PO.id)
    }
    const handleApprove = async () => {
        if (!isFlowApprovalDone) {
            setShowModal(true)
            return
        }
        approve()
    }

    const handleConfirm = () => {
        approve()
        handleClose()
    }

    const handleClose = () => {
        setShowModal(false)
    }

    const buttonLabel =
        !PO.requesterOrganizationId || PO.requesterOrganizationId === organizationId
            ? commonMessages.approve
            : commonMessages.accept
    const formattedButtonLabel = <SafeFormattedMessage {...buttonLabel} />

    return (
        <>
            <Tooltip title={formattedButtonLabel}>
                <span>
                    <Button type="primary-light" onClick={handleApprove}>
                        <Check size={18} />
                        {formattedButtonLabel}
                    </Button>
                </span>
            </Tooltip>
            <ApprovalOverrideModal open={showModal} onClose={handleClose} onConfirm={handleConfirm} />
        </>
    )
}
