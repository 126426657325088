import {
    CreatePartnershipPayload,
    CreatePartnershipPayloadWithContact,
    PartnershipTypeOption,
} from "~/domains/identity/partners/types"
import { CountryCode, ImportInvoiceCompanyInfoI } from "~/types"

export const createEmptyPartner = (countryCode = CountryCode.FR): ImportInvoiceCompanyInfoI => ({
    countryCode,
    name: "",
    contactName: "",
    organizationId: null,
})

export const createEmptyPartnershipPayload = (): CreatePartnershipPayload => ({
    partnerId: "",
    partnershipType: PartnershipTypeOption.SUPPLIER,
    preferredStatus: false,
    verifiedStatus: false,
})

export const createEmptyPartnershipPayloadWithContact = (): CreatePartnershipPayloadWithContact => ({
    partnership: createEmptyPartnershipPayload(),
    contact: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        position: "",
        language: "",
        timezone: "",
    },
})
