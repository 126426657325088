import {
    Autocomplete,
    AutocompleteRenderGroupParams,
    AutocompleteRenderInputParams,
    Grid,
    Popper,
    SxProps,
    TextField,
    Theme,
    styled,
} from "@mui/material"
import React, { Dispatch, ReactNode, useCallback, useEffect, useRef, useState } from "react"
import { Users } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { SafeFormattedMessage } from "~/components"
import { StatusChip } from "~/components"
import { useWhitePagesApi } from "~/domains/identity/organization/api/whitePagesApi"
import {
    CompanyAutocompleteDisplayType,
    CompanyAutocompleteType,
    CompanyResultWithCreation,
} from "~/domains/identity/organization/types/CompanyAutocomplete"
import { CompanyAutocompleteResult } from "~/domains/identity/organization/types/CompanyAutocomplete"
import {
    createAutocompleteOptionFromOrganization,
    createAutocompleteOptionFromWhitePagesResult,
    createDisabledOption,
    filterOptions,
    filterOptionsWithCreation,
    filterReferencedPartners,
    getCityAndPostalCode,
    getCompanyAutocompleteTypeLabel,
    getOptionLabel,
    getRegistrationNumber,
    hasCollaborationStatus,
} from "~/domains/identity/organization/utils/companyAutocomplete"
import { selectPartnersBrandNames } from "~/domains/identity/partners/store/bookOfRelationsSlice"
import { PartnerOrganizationI, partnershipCollaborationStatusMessages } from "~/domains/identity/partners/types"
import { selectPrCompanyAutocompleteOnlyWithPartners } from "~/domains/transactions/purchase-requests/store/purchaseRequestsSlice"
import { useAppSelector } from "~/store/hooks"
import { CountryCode, OrganizationI } from "~/types"
import { stopPropagationAndPreventDefault } from "~/utils/events"

const companyStatusMessages = defineMessages({
    tooManyOptions: {
        id: "company.autocomplete.tooManyOptions",
        defaultMessage: "Too many options, please refine your search",
    },
})

export const chipStyles: SxProps<Theme> = {
    margin: "-8px 0 -8px 2px",
    textAlign: "center",
    lineHeight: "12px",
}

const groupBy = (option: CompanyAutocompleteResult) => option.type

const GroupHeader = styled("div")(() => ({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    color: "var(--color-grey-light)",
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "18px",
    backgroundColor: "white",
}))

const GroupItems = styled("ul")({
    padding: 0,
})

const IconAndStatusContainer = styled("div")(() => ({
    display: "flex",
    gap: "8px",
    alignItems: "center",
}))

const StyledCity = styled("span")(() => ({
    color: "var(--color-grey-light)",
    marginLeft: "4px",
}))

const StyledRegistrationNumber = styled("div")(() => ({
    color: "var(--color-grey-light)",
}))

const noFilter = (options: CompanyAutocompleteResult[]) => options

const getReactKey = (option: CompanyAutocompleteResult) => {
    if (option.type === "disabled") return "disabled"
    if (option.type === "create") return "creation"
    return option.value.id
}

interface CompanyAutocompleteFieldProps {
    company?: CompanyResultWithCreation
    countryCode: CountryCode
    inputValue: string
    setInputValue: Dispatch<string>
    value?: CompanyAutocompleteResult
    onChange: (value: CompanyAutocompleteResult | undefined, inputValue: string) => void
    label?: React.ReactNode
    required?: boolean
    organizations: (OrganizationI | PartnerOrganizationI)[]
    enableCreation?: boolean
    groupLabel?: Partial<Record<CompanyAutocompleteType, ReactNode>>
    preventOnEnter?: boolean
    displayType?: CompanyAutocompleteDisplayType
}

const MAX_OPTIONS_TO_DISPLAY = 50
const WHITE_PAGES_DEBOUNCE_TIME = 350
const ORGANIZATIONS_SEARCH_DEBOUNCE_TIME = 350
export const CompanyAutocompleteField: React.FC<CompanyAutocompleteFieldProps> = ({
    countryCode,
    company,
    value,
    inputValue,
    setInputValue,
    label,
    required,
    onChange,
    organizations,
    enableCreation,
    groupLabel,
    preventOnEnter = false,
    displayType = "other",
}) => {
    const { formatMessage } = useIntl()

    const [open, setOpen] = useState(false)
    const [options, setOptions] = useState<CompanyAutocompleteResult[]>([])
    const [organizationsOptions, setOrganizationsOptions] = useState<CompanyAutocompleteResult[]>([])
    const whitePagesTimeoutRef = useRef<NodeJS.Timeout | null>(null)
    const organizationsTimeoutRef = useRef<NodeJS.Timeout | null>(null)
    const hasHilightedOptionRef = useRef(false)

    const inputValueRef = useRef<string>("")
    const inputRef = useRef<HTMLInputElement>()
    const brandNames = useAppSelector(selectPartnersBrandNames)
    const prCompanyAutocompleteOnlyWithPartners = useAppSelector(selectPrCompanyAutocompleteOnlyWithPartners)

    const whitePagesApi = useWhitePagesApi()

    const openPopper = () => setOpen(true)
    const closePopper = () => setOpen(false)

    useEffect(() => {
        if (company?.type === "org") {
            setInputValue(company.value.name)
            inputValueRef.current = company.value.name
        } else if (company?.type === "wp" && company.value.name !== inputValueRef.current) {
            setInputValue(company.value.name)
            inputValueRef.current = company.value.name
        }
    }, [company, setInputValue])

    const searchCompanies = useCallback(
        async (searchValue: string) => {
            if (searchValue.length === 0 || prCompanyAutocompleteOnlyWithPartners) {
                setOptions(organizationsOptions)
                return
            }

            const whitePagesResults = await whitePagesApi.autocomplete(countryCode, searchValue)
            const opts = [
                ...organizationsOptions,
                ...whitePagesResults.map(createAutocompleteOptionFromWhitePagesResult),
            ]
            setOptions(opts)
        },
        [countryCode, organizationsOptions, prCompanyAutocompleteOnlyWithPartners]
    )

    // Debounce the search to prevent too many requests
    // White-pages API
    useEffect(() => {
        if (whitePagesTimeoutRef.current) {
            clearTimeout(whitePagesTimeoutRef.current)
        }

        whitePagesTimeoutRef.current = setTimeout(() => {
            searchCompanies(inputValue)
        }, WHITE_PAGES_DEBOUNCE_TIME)

        return () => {
            if (whitePagesTimeoutRef.current) {
                clearTimeout(whitePagesTimeoutRef.current)
            }
        }
    }, [inputValue, searchCompanies])

    // Debounce the search to prevent too many requests
    // Search in organizations
    useEffect(() => {
        if (organizationsTimeoutRef.current) {
            clearTimeout(organizationsTimeoutRef.current)
        }

        organizationsTimeoutRef.current = setTimeout(() => {
            const filteredOrgs = prCompanyAutocompleteOnlyWithPartners
                ? filterReferencedPartners(organizations)
                : organizations

            const opts = filterOptions(
                filteredOrgs.map((org) => createAutocompleteOptionFromOrganization(org, brandNames)),
                {
                    inputValue,
                    getOptionLabel,
                }
            )

            if (opts.length > MAX_OPTIONS_TO_DISPLAY) {
                const showTooManyOptions = displayType !== "partner_creation" || inputValue.length > 0
                setOrganizationsOptions(
                    showTooManyOptions
                        ? [createDisabledOption(formatMessage(companyStatusMessages.tooManyOptions))]
                        : []
                )
            } else {
                setOrganizationsOptions(opts)
            }
        }, ORGANIZATIONS_SEARCH_DEBOUNCE_TIME)

        return () => {
            if (organizationsTimeoutRef.current) {
                clearTimeout(organizationsTimeoutRef.current)
            }
        }
    }, [organizations, inputValue, brandNames, prCompanyAutocompleteOnlyWithPartners, formatMessage])

    const onInputChange = useCallback(
        (_event: React.SyntheticEvent, newInputValue: string) => {
            setInputValue(newInputValue)
            inputValueRef.current = newInputValue
            if (!newInputValue || newInputValue.length === 0) {
                onChange(undefined, "")
            }
        },
        [setInputValue, onChange]
    )

    useEffect(() => {
        if (inputValue && inputValueRef.current !== inputValue) {
            if (inputRef.current) {
                inputRef.current.click()
            }
            openPopper()
        }
    }, [inputValue])

    const onAutocompleteChange = useCallback(
        (_event: React.SyntheticEvent, autocompleteResult: CompanyAutocompleteResult | string | null) => {
            if (typeof autocompleteResult === "string") return
            onChange(autocompleteResult || undefined, inputValue)
        },
        [onChange, inputValue]
    )

    const renderGroup = useCallback(
        (params: AutocompleteRenderGroupParams) => {
            const group = params.group as CompanyAutocompleteType
            return (
                <li key={params.key}>
                    <GroupHeader>
                        {groupLabel && groupLabel[group] ? (
                            groupLabel[group]
                        ) : (
                            <SafeFormattedMessage {...getCompanyAutocompleteTypeLabel(group)} />
                        )}
                    </GroupHeader>
                    <GroupItems>{params.children}</GroupItems>
                </li>
            )
        },
        [groupLabel]
    )

    const renderOptionType = useCallback(
        (option: CompanyAutocompleteResult) => {
            if (option.type === "org") {
                return (
                    <>
                        {hasCollaborationStatus(option) && (
                            <StatusChip status={option.value.collaborationStatus}>
                                <IconAndStatusContainer>
                                    <Users size={16} />
                                    <SafeFormattedMessage
                                        {...partnershipCollaborationStatusMessages[option.value.collaborationStatus]}
                                    />
                                </IconAndStatusContainer>
                            </StatusChip>
                        )}
                    </>
                )
            }
            if (option.type === "wp") {
                if (option.value.isOutOfBusiness) {
                    return (
                        <StatusChip status={commonMessages.closed.defaultMessage}>
                            {formatMessage(commonMessages.closed)}
                        </StatusChip>
                    )
                }
                return (
                    <StatusChip status={commonMessages.new.defaultMessage}>
                        {formatMessage(commonMessages.new)}
                    </StatusChip>
                )
            }
            return null
        },
        [formatMessage]
    )

    const renderOption = useCallback(
        (params: React.HTMLAttributes<HTMLLIElement>, option: CompanyAutocompleteResult) => {
            if (!option) return null
            const city = getCityAndPostalCode(option)
            const key = getReactKey(option)
            return (
                <li {...params} key={key} aria-disabled={option.type === "disabled"}>
                    <Grid container justifyContent="space-between" direction="row" alignItems="center" gap={1}>
                        <Grid item xs={8}>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: option.value.name || "",
                                }}
                            />
                            {city && <StyledCity>({city})</StyledCity>}
                            {option.type !== "create" && (
                                <StyledRegistrationNumber>{getRegistrationNumber(option)}</StyledRegistrationNumber>
                            )}
                        </Grid>
                        <Grid item>{renderOptionType(option)}</Grid>
                    </Grid>
                </li>
            )
        },
        [renderOptionType]
    )

    // Used to prevent the closing of the autocomplete when the user press enter if there is no highlighted option
    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" && !hasHilightedOptionRef.current) {
            stopPropagationAndPreventDefault(event)
        }
    }

    // Used to memorize if there is a highlighted option to allow the default autocomplete behavior
    const onHighlightChange = (_event: React.SyntheticEvent, option: CompanyAutocompleteResult | null) => {
        hasHilightedOptionRef.current = Boolean(option)
    }

    const renderInput = useCallback(
        (params: AutocompleteRenderInputParams) => (
            <TextField
                InputLabelProps={params.InputLabelProps}
                InputProps={params.InputProps}
                inputProps={params.inputProps}
                id={params.id}
                disabled={params.disabled}
                fullWidth={params.fullWidth}
                size={params.size}
                onKeyDown={preventOnEnter ? onKeyDown : undefined}
                label={
                    label ?? (
                        <SafeFormattedMessage
                            id="account.organization.companyAutocomplete.defaultLabel"
                            defaultMessage="Search company"
                        />
                    )
                }
                required={required}
            />
        ),
        [required, label, preventOnEnter]
    )

    if (countryCode === CountryCode.UNKNOWN) {
        return null
    }

    return (
        <Autocomplete
            freeSolo
            open={open}
            onOpen={openPopper}
            onClose={closePopper}
            inputValue={inputValue}
            onInputChange={onInputChange}
            options={options}
            onHighlightChange={onHighlightChange}
            getOptionLabel={getOptionLabel}
            value={value ?? ""}
            filterOptions={enableCreation ? filterOptionsWithCreation(formatMessage, countryCode) : noFilter}
            renderOption={renderOption}
            renderInput={renderInput}
            onChange={onAutocompleteChange}
            groupBy={groupBy}
            renderGroup={renderGroup}
            fullWidth
            PopperComponent={Popper}
            ref={inputRef}
        />
    )
}
