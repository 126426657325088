import { capitalize } from "@mui/material"
import { useState } from "react"
import { MessageDescriptor, defineMessages, useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { Button, ButtonType, ConfirmModal, SafeFormattedMessage, TooltipConditional } from "~/components"
import { permissionMessages } from "~/domains/identity/roles-permissions/utils/permissions"
import { useBulkUpdateLifecycle } from "~/domains/transactions/invoices-v1/hooks/useBulkUpdateLifecycle"
import { LifecycleGroupVariant, LifecycleGroupWithVariant } from "~/domains/transactions/invoices-v1/types/Invoice"

const messages = defineMessages({
    selected: { id: "account.members.selected", defaultMessage: "selected" },
    confirmLifecycleTitle: { id: "account.documents.bulk.confirmLifecycleTitle", defaultMessage: "Lifecycle invoices" },
    confirmLifecycleMessage: {
        id: "account.documents.bulk.confirmLifecycleMessage",
        defaultMessage: "Are you sure to change the status of {count, plural, one {# invoice} other {# invoices}}?",
    },
    confirmLifecycleButton: {
        id: "account.documents.bulk.confirmLifecycleButton",
        defaultMessage: "Change the status",
    },
})

interface InvoicesUpdateLifecycleProps {
    selected: string[]
    lifecycleStatus: [string, LifecycleGroupWithVariant]
    onSuccess: () => void
}

export const InvoicesUpdateLifecycle = ({ selected, lifecycleStatus, onSuccess }: InvoicesUpdateLifecycleProps) => {
    const { formatMessage } = useIntl()
    const [displayModalAddLifecycles, setDisplayModalAddLifecycles] = useState(false)

    const [bulkUpdateLifecycle] = useBulkUpdateLifecycle({ invoiceIds: selected })

    const openAddLifecyclesModal = () => setDisplayModalAddLifecycles(true)
    const closeAddLifecyclesModal = () => setDisplayModalAddLifecycles(false)

    const handleConfirmModalAddLifecycles = async () => {
        await bulkUpdateLifecycle(lifecycleStatus[0])
        closeAddLifecyclesModal()
        onSuccess()
    }

    const translateLabel = (name: string) => {
        const message = commonMessages[name] as MessageDescriptor
        return message ? formatMessage(message) : capitalize(name)
    }

    const name = translateLabel(lifecycleStatus[0])
    const color = LifecycleGroupVariant[lifecycleStatus[1].group]
    const buttonType = (color ? `${color}-light` : "primary-light") as ButtonType

    const hasPermission = true

    return (
        <>
            <TooltipConditional
                condition={!hasPermission}
                title={<SafeFormattedMessage {...permissionMessages.errorNoAccessAdministrator} />}
            >
                <div>
                    <Button type={buttonType} onClick={openAddLifecyclesModal}>
                        {name}
                    </Button>
                </div>
            </TooltipConditional>

            <ConfirmModal
                title={<SafeFormattedMessage {...messages.confirmLifecycleTitle} />}
                open={displayModalAddLifecycles}
                close={closeAddLifecyclesModal}
                onConfirm={handleConfirmModalAddLifecycles}
                confirmButtonType="primary"
            >
                <SafeFormattedMessage
                    {...messages.confirmLifecycleMessage}
                    values={{
                        count: selected.length,
                        s: selected.length > 1 ? "s" : "",
                    }}
                />
            </ConfirmModal>
        </>
    )
}
