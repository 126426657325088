import { styled } from "@mui/material"
import { FC, PropsWithChildren } from "react"
import { ChevronLeft, ChevronRight } from "react-feather"

import { IconButton } from "~/domains/orchestration/flows/components/IconButton"
import { SIDE_BAR_TOP_POSITION } from "~/domains/orchestration/flows/constants"
import { SideBarState } from "~/domains/orchestration/flows/types"
import { getSideBarWidth } from "~/domains/orchestration/flows/utils"

interface ToggleSideBarButtonProps {
    width: number
    onClick: () => void
    top?: number
}
const ToggleButton = styled(IconButton)<{ width: number; top: number }>(({ width = 0, top = 0 }) => ({
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "20px",
    minHeight: "24px",
    top: `${SIDE_BAR_TOP_POSITION + top}px`,
    right: `${width}px`,
    marginRight: "-10px",
    backgroundColor: "var(--color-white)",
    border: "1px solid var(--color-border-workflow)",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
    transition: "right 0.2s ease-in-out",
}))

const ToggleSideBarButton: FC<PropsWithChildren<ToggleSideBarButtonProps>> = ({
    width,
    onClick,
    children,
    top = 0,
}) => {
    return (
        <ToggleButton onClick={onClick} width={width} top={top}>
            {children}
        </ToggleButton>
    )
}

export const ToggleSideBarButtons = ({
    onClick,
    state,
}: {
    onClick: (state: SideBarState) => void
    state: SideBarState
}) => {
    const handleOpenSideBar = () => onClick(SideBarState.OPEN)

    const handleCloseSideBar = () => onClick(SideBarState.CLOSED)

    const handleWideSideBar = () => onClick(SideBarState.WIDE)

    const sideBarWidth = getSideBarWidth(state)

    if (state === SideBarState.CLOSED) {
        return (
            <ToggleSideBarButton onClick={handleOpenSideBar} width={sideBarWidth}>
                <ChevronLeft size={14} />
            </ToggleSideBarButton>
        )
    }
    if (state === SideBarState.OPEN) {
        return (
            <>
                <ToggleSideBarButton onClick={handleCloseSideBar} width={sideBarWidth}>
                    <ChevronRight size={14} />
                </ToggleSideBarButton>
                <ToggleSideBarButton onClick={handleWideSideBar} width={sideBarWidth} top={40}>
                    <ChevronLeft size={14} />
                </ToggleSideBarButton>
            </>
        )
    }
    if (state === SideBarState.WIDE) {
        return (
            <ToggleSideBarButton onClick={handleOpenSideBar} width={sideBarWidth}>
                <ChevronRight size={14} />
            </ToggleSideBarButton>
        )
    }
}
