import { Stack } from "@mui/material"
import * as Sentry from "@sentry/react"
import React, { useCallback, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { toast } from "react-toastify"

import { commonMessages } from "~/common-messages"
import { ConfirmModal, SafeFormattedMessage } from "~/components"
import { SharedUser } from "~/components/SharedObjectList/SharedUser"
import { useRemoveSharedObjectMutation } from "~/domains/identity/roles-permissions/api/sharesApi"
import { useFetchSharedObjects } from "~/domains/identity/roles-permissions/store/hooks"
import { sharedObjectPermissionsModalMessages } from "~/domains/identity/roles-permissions/types/SpiceDB"
import { OrganizationId } from "~/types"
import { SharedObjectType, SharedObjectWithName } from "~/types/SharedObjects"

const SharedObjectMemo = React.memo(SharedUser)

interface SharedObjectListProps {
    organizationId: OrganizationId
    objectId: string
    objectType: SharedObjectType
}

export const SharedObjectList = ({ organizationId, objectId, objectType }: SharedObjectListProps) => {
    const { formatMessage } = useIntl()

    const [confirmDeleteSharing, setConfirmDeleteSharing] = useState(false)
    const [currentSharing, setCurrentSharing] = useState<SharedObjectWithName | null>(null)

    const organizationSharings = useFetchSharedObjects(organizationId)
    const [removeSharedObject, { isLoading: loadingDelete }] = useRemoveSharedObjectMutation()

    const objectSharings = useMemo(
        () => organizationSharings.filter((share) => share.objectId === objectId && share.objectType === objectType),
        [organizationSharings, objectId, objectType]
    )

    const handleDeleteSharing = useCallback(async () => {
        if (currentSharing) {
            try {
                await removeSharedObject({ organizationId, sharingId: currentSharing.id })
                setCurrentSharing(null)
                toast.success(formatMessage(sharedObjectPermissionsModalMessages.deleteSharingSuccess))
                return true
            } catch (error) {
                toast.error(formatMessage(commonMessages.error))
                Sentry.captureException(new Error(`Issue deleting sharing #${currentSharing.id} - ${error}`), {
                    extra: {
                        currentSharing,
                        organizationId,
                    },
                })
            }
        }

        setCurrentSharing(null)
        return false
    }, [currentSharing, organizationId, removeSharedObject, formatMessage])

    const showConfirmDeleteSharing = (sharing: SharedObjectWithName) => {
        setConfirmDeleteSharing(true)
        setCurrentSharing(sharing)
    }

    const hideConfirmDeleteSharing = () => setConfirmDeleteSharing(false)

    return (
        <>
            <Stack direction="column" gap={2}>
                {objectSharings?.map((share) => (
                    <SharedObjectMemo
                        key={share.id}
                        shareObject={share}
                        callbackButton={showConfirmDeleteSharing}
                        loading={loadingDelete}
                    />
                ))}
            </Stack>

            <ConfirmModal
                open={confirmDeleteSharing}
                close={hideConfirmDeleteSharing}
                onConfirm={handleDeleteSharing}
                title={<SafeFormattedMessage {...sharedObjectPermissionsModalMessages.confirmDeleteTitle} />}
            >
                <SafeFormattedMessage {...sharedObjectPermissionsModalMessages.confirmDeleteSharing} />
            </ConfirmModal>
        </>
    )
}
