import { useCallback } from "react"
import { defineMessages, useIntl } from "react-intl"

import { displayAndCaptureError } from "~/displayAndCaptureError"
import { selectUser } from "~/store/account/accountSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { useCurrentOrganization } from "~/store/organization/hooks"

import { invoiceActions } from "../invoiceSlice"
import { useAddInvoice } from "./useAddInvoice"

const messages = defineMessages({
    error: {
        id: "common.confirmModal.actionFailed",
        defaultMessage: "An unexpected error occurred.",
    },
})

export const useAddBuyerInvoice = (invoiceId: string | undefined) => {
    const { formatMessage } = useIntl()
    const buyer = useAppSelector(selectUser)
    const organization = useCurrentOrganization(buyer.organizations)
    const addInvoice = useAddInvoice(invoiceId, {
        buyer: {
            userId: buyer.id,
            organizationId: organization ? organization.id : undefined,
        },
    })
    const dispatch = useAppDispatch()

    return useCallback(
        async (alreadyPaid?: boolean) => {
            dispatch(invoiceActions.addInvoice())
            try {
                const res = await addInvoice(alreadyPaid)
                dispatch(invoiceActions.addInvoiceSuccess())
                return res
            } catch (error) {
                dispatch(invoiceActions.addInvoiceFailed())
                displayAndCaptureError(formatMessage(messages.error), error)
                throw error
            }
        },
        [dispatch, addInvoice, formatMessage]
    )
}
