import { useEffect, useState } from "react"

import { InvoicePennylaneSynchronizationStatusI } from "~/domains/_shared/ocr/types/pennylaneSynchronizationStatus"
import { useVerpApi } from "~/domains/orchestration/verp/api/verpApi"
import { InvoiceI, InvoiceStatus } from "~/types"

export const useInvoicePennylaneSynchronizationStatus = (invoice?: InvoiceI | null) => {
    const verpAi = useVerpApi()
    const [status, setStatus] = useState<InvoicePennylaneSynchronizationStatusI | null>(null)
    const [loading, setLoading] = useState(false)

    const fetchInvoicePennylaneSynchronizationStatus = async () => {
        if (!invoice) return

        setLoading(true)
        try {
            const response = await verpAi.getInvoicePennylaneSynchronizationStatus(invoice.id)
            setStatus(response)
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (invoice && ![InvoiceStatus.DRAFT, InvoiceStatus.REJECTED].includes(invoice.status)) {
            fetchInvoicePennylaneSynchronizationStatus()
        }
    }, [invoice])

    return { status, loading }
}
