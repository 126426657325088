export enum SupportedLanguages {
    FR = "fr",
    EN = "en",
    AR = "ar",
    CH = "ch",
    DE = "de",
    NO = "no",
    PL = "pl",
    BR = "br",
    RO = "ro",
}

export const SUPPORTED_LANGUAGES = [
    SupportedLanguages.FR,
    SupportedLanguages.EN,
    SupportedLanguages.AR,
    SupportedLanguages.CH,
    SupportedLanguages.DE,
    SupportedLanguages.NO,
    SupportedLanguages.PL,
    SupportedLanguages.BR,
    SupportedLanguages.RO,
]
export const APP_DEFAULT_LANGUAGE = SupportedLanguages.EN
