import CircularProgress from "@mui/material/CircularProgress"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { alpha } from "@mui/material/styles"
import { FormattedNumber, defineMessages } from "react-intl"

import { ItemLabel, SafeFormattedMessage } from "~/components"
import Iconify from "~/components/iconify"
import { CurrencyCodes } from "~/types"

const messages = defineMessages({
    invoices: {
        id: "stats.invoices.invoices",
        defaultMessage: "Invoices",
    },
})

interface AnalyticBlockProps {
    icon: string
    title: string
    total: number
    percent: number
    prices: Partial<Record<CurrencyCodes, string>>
    color?: string
    isActive?: boolean
    onClick?: () => void
}

export const AnalyticBlock = ({
    title,
    total,
    icon,
    color,
    percent,
    prices,
    isActive,
    onClick,
}: AnalyticBlockProps) => {
    return (
        <Stack
            spacing={2}
            padding={2}
            direction="row"
            sx={{
                minWidth: 220,
                cursor: onClick ? "pointer" : "default",
                "&:first-of-type": {
                    borderRadius: "var(--border-radius-sm) 0 0 var(--border-radius-sm)",
                },
                "&:last-of-type": {
                    borderRadius: "0 var(--border-radius-sm) var(--border-radius-sm) 0",
                },
                "&:hover": {
                    backgroundColor: onClick ? "var(--color-primary-light)" : undefined,
                },
                border: isActive ? "1px solid var(--color-primary)" : undefined,
                backgroundColor: isActive ? "var(--color-primary-light)" : undefined,
            }}
            onClick={onClick}
        >
            <Stack alignItems="center" justifyContent="center" sx={{ position: "relative" }} height={52}>
                <Iconify
                    icon={icon}
                    width={28}
                    sx={{
                        color,
                        position: "absolute",
                    }}
                />

                <CircularProgress
                    variant="determinate"
                    value={percent}
                    size={52}
                    thickness={2}
                    sx={{
                        color,
                        opacity: 0.48,
                    }}
                />

                <CircularProgress
                    variant="determinate"
                    value={100}
                    size={52}
                    thickness={3}
                    sx={{
                        top: 0,
                        left: 0,
                        opacity: 0.48,
                        position: "absolute",
                        color: (theme) => alpha(theme.palette.grey[500], 0.16),
                    }}
                />
            </Stack>

            <Stack spacing={0.5} paddingTop={0.5}>
                <Typography variant="subtitle1" sx={{ lineHeight: "1.2" }}>
                    {title}
                </Typography>

                <ItemLabel>
                    {total} <SafeFormattedMessage {...messages.invoices} values={{ s: total > 1 ? "s" : "" }} />
                </ItemLabel>

                {Object.entries(prices).map(
                    ([currency, price]) =>
                        price !== "0" && (
                            <Typography key={currency} variant="subtitle2">
                                <FormattedNumber value={parseFloat(price)} style="currency" currency={currency} />
                            </Typography>
                        )
                )}
            </Stack>
        </Stack>
    )
}
