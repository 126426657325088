import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorizationAndOrganizationId as prepareHeaders } from "~/api/prepareHeaders"
import { taggedBaseQuery } from "~/api/rtkFetchBaseExtend"
import { adaptRunFromApi, adaptRunsFromApi } from "~/domains/orchestration/flows/api/adapters/fromApiAdapters"
import type {
    ApiRun,
    ApiRuns,
    GetRunQuery,
    GetRunsQuery,
    RestartRunQuery,
    Run,
    Runs,
} from "~/domains/orchestration/flows/types"

const BASE_URL = import.meta.env.VITE_API_FLOWS_URL

export const runsApi = createApi({
    reducerPath: "runsApi",
    baseQuery: taggedBaseQuery({
        baseQueryFn: fetchBaseQuery({
            baseUrl: BASE_URL,
            prepareHeaders,
        }),
        domain: "orchestration",
        subdomain: "runs",
    }),
    tagTypes: ["Runs"],
    endpoints: (builder) => ({
        getRun: builder.query<Run, GetRunQuery>({
            query: ({ runId }: GetRunQuery) => ({
                url: `runs/${runId}`,
            }),
            transformResponse: (response: ApiRun) => adaptRunFromApi(response),
        }),

        getRuns: builder.query<Runs, GetRunsQuery>({
            query: ({ flowId, limit, offset }: GetRunsQuery) => ({
                url: `runs`,
                params: { "flow-id": flowId, limit, offset },
            }),
            transformResponse: (response: ApiRuns) => adaptRunsFromApi(response),
            providesTags: ["Runs"],
        }),

        restartRun: builder.mutation<Run, RestartRunQuery>({
            query: ({ runId, version }: RestartRunQuery) => ({
                url: `runs/${runId}/restart`,
                method: "POST",
                params: { version },
            }),
            invalidatesTags: ["Runs"],
        }),
    }),
})

export const { useGetRunsQuery, useLazyGetRunsQuery, useGetRunQuery, useLazyGetRunQuery, useRestartRunMutation } =
    runsApi
