import { Stack } from "@mui/material"
import { FC } from "react"

import { ItemLabel } from "~/components"

interface Props {
    onClick: () => void
    firstLine: string | JSX.Element
    secondLine: string | JSX.Element
    status: string | JSX.Element
}

export const DashboardListItem: FC<Props> = ({ onClick, firstLine, secondLine, status }) => (
    <Stack direction="row" alignItems="center" gap={1} onClick={onClick} className="dashboard-list-item">
        <div className="dashboard-list-item-description truncate-text">
            <a>{firstLine}</a>
            <ItemLabel>{secondLine}</ItemLabel>
        </div>
        <div>{status}</div>
    </Stack>
)
