import { Stack, Typography } from "@mui/material"
import { useMemo } from "react"
import { Download } from "react-feather"

import { commonMessages } from "~/common-messages"
import { ActionsMenu, SafeFormattedMessage } from "~/components"
import { useHasPaymentPermissions } from "~/domains/payment/hooks"
import { SwiftMessageType, SwiftMessageTypes } from "~/domains/payment/types"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { PaymentI } from "~/types"

import { useGenerateSwiftFileMutation } from "../api/paymentApi"

interface PaymentLineActionsProps {
    row: PaymentI
}

export const PaymentLineActions = ({ row }: PaymentLineActionsProps) => {
    const { permissions } = useHasPaymentPermissions({ authorizations: ["read"] })
    const hasReadPermission = Boolean(permissions?.read?.hasPermission)
    const organization = useAppSelector(selectCurrentOrganization)

    const [generateSwiftFile] = useGenerateSwiftFileMutation()

    const handleDownload = (messageType: SwiftMessageType) => async () => {
        if (!organization?.id) return

        const organizationRecord = {
            [organization.id]: organization.name,
        }

        await generateSwiftFile({ paymentIds: [row.id], messageType, organization: organizationRecord })
    }

    const actions = useMemo(
        () => [
            ...SwiftMessageTypes.map((type) => ({
                label: (
                    <Stack direction="row" gap={1} alignItems="center">
                        <SafeFormattedMessage {...commonMessages.download} />
                        <Typography variant="caption">{type}</Typography>
                    </Stack>
                ),
                icon: <Download size={14} />,
                disabled: !hasReadPermission,
                action: handleDownload(type),
            })),
        ],
        [row, hasReadPermission]
    )

    return <ActionsMenu actions={actions} />
}
