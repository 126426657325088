import { File } from "react-feather"

import { CreatePurchaseOrderConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { RegularNode } from "~/domains/orchestration/flows/components/nodes"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    CreatePurchaseOrderNode as CreatePurchaseOrderNodeType,
    EditorNode,
    Node,
    NodeType,
    PoCreationStatus,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const createPurchaseOrderNode: Omit<CreatePurchaseOrderNodeType, keyof Node> = {
    buyerId: "",
    supplierId: "",
    description: "",
    lines: [],
    status: PoCreationStatus.DRAFT,
    nextNode: null,
    type: NodeType.CREATE_PURCHASE_ORDER_NODE,
}

const advancedFields: (keyof typeof createPurchaseOrderNode)[] = [
    AllAdvancedFieldsUnion.BUYER_ID,
    AllAdvancedFieldsUnion.SUPPLIER_ID,
]

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.CREATE_PURCHASE_ORDER_NODE) return false

    const typedNode = node as CreatePurchaseOrderNodeType

    if (typedNode.lines.length > 0) {
        if (typedNode.lines.some((line) => !line.description || !line.quantity || !line.unitPrice)) {
            return false
        }
    }

    if (typedNode.billingAddress) {
        if (
            !typedNode.billingAddress.street ||
            !typedNode.billingAddress.city ||
            !typedNode.billingAddress.zipCode ||
            !typedNode.billingAddress.country
        ) {
            return false
        }
    }

    if (typedNode.shippingAddress) {
        if (
            !typedNode.shippingAddress.street ||
            !typedNode.shippingAddress.city ||
            !typedNode.shippingAddress.zipCode ||
            !typedNode.shippingAddress.country
        ) {
            return false
        }
    }

    return Boolean(typedNode.buyerId && typedNode.supplierId && typedNode.description && typedNode.lines.length > 0)
}

const createPurchaseOrderConfig: Configuration<NodeType.CREATE_PURCHASE_ORDER_NODE> = {
    type: NodeType.CREATE_PURCHASE_ORDER_NODE,
    baseSlug: "create-purchase-order",
    Icon: File,
    Configuration: CreatePurchaseOrderConfiguration,
    advancedFields,
    Node: RegularNode,
    factory: (props: FactoryProps): CreatePurchaseOrderNodeType => ({
        ...createPurchaseOrderNode,
        ...createCommonNodeProperties({ ...props }),
    }),
    validateNode,
}

export { createPurchaseOrderConfig, advancedFields as createPurchaseOrderAdvancedFields }
