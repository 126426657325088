import { lazy } from "react"
import { Route } from "react-router-dom"

import { ProtectedRoutes } from "~/components"

import {
    ORGANIZATIONS_ROUTE,
    ORGANIZATION_CATALOGS_ROUTE,
    ORGANIZATION_CONTACTS_ROUTE,
    ORGANIZATION_CREATE_PRODUCT_ROUTE,
    ORGANIZATION_DETAILS_ROUTE,
    ORGANIZATION_EDIT_PRODUCT_ROUTE,
    ORGANIZATION_INFO_ROUTE,
    ORGANIZATION_INTEGRATION_ROUTE,
    ORGANIZATION_INVOICE_EXPORTS_ROUTE,
    ORGANIZATION_KYB_ROUTE,
    ORGANIZATION_PAYMENT_METHODS_ROUTE,
    ORGANIZATION_REFUSAL_REASONS_ROUTE,
    ORGANIZATION_ROUTE,
    ORGANIZATION_TAGS_ROUTE,
    ORGANIZATION_TEAMS_ROUTE,
    PROFILE_ROUTE,
} from "./routes"

const ProfileLazy = lazy(() => import("./pages/Profile").then(({ Profile }) => ({ default: Profile })))
const OrganizationsLazy = lazy(() =>
    import("~/domains/identity/account/pages/Organizations").then(({ Organizations }) => ({
        default: Organizations,
    }))
)
const OrganizationDetailsLazy = lazy(() =>
    import("~/domains/identity/account/pages/OrganizationDetails").then(({ OrganizationDetails }) => ({
        default: OrganizationDetails,
    }))
)
const OrganizationIntegrationsLazy = lazy(() =>
    import("~/domains/identity/account/pages/OrganizationIntegrations").then(({ OrganizationIntegrations }) => ({
        default: OrganizationIntegrations,
    }))
)
const OrganizationMembersLazy = lazy(() =>
    import("~/domains/identity/account/pages/OrganizationMembers").then(({ OrganizationMembers }) => ({
        default: OrganizationMembers,
    }))
)
const OrganizationTeamsLazy = lazy(() =>
    import("~/domains/identity/account/pages/OrganizationTeams").then(({ OrganizationTeams }) => ({
        default: OrganizationTeams,
    }))
)
const TagGroupsAndTagsLazy = lazy(() =>
    import("~/domains/analytics/tags/pages/TagGroupsAndTags").then(({ TagGroupsAndTags }) => ({
        default: TagGroupsAndTags,
    }))
)
const OrganizationInvoiceExportsLazy = lazy(() =>
    import("./pages/OrganizationInvoiceExports").then(({ OrganizationInvoiceExports }) => ({
        default: OrganizationInvoiceExports,
    }))
)
const OrganizationCatalogLazy = lazy(() =>
    import("./pages/OrganizationCatalog").then(({ OrganizationCatalog }) => ({
        default: OrganizationCatalog,
    }))
)
const OrganizationCreateProductLazy = lazy(() =>
    import("./pages/OrganizationCreateProduct").then(({ OrganizationCreateProduct }) => ({
        default: OrganizationCreateProduct,
    }))
)
const OrganizationEditProductLazy = lazy(() =>
    import("./pages/OrganizationEditProduct").then(({ OrganizationEditProduct }) => ({
        default: OrganizationEditProduct,
    }))
)
const OrganizationKYBLazy = lazy(() =>
    import("./pages/OrganizationKYB").then(({ OrganizationKYB }) => ({
        default: OrganizationKYB,
    }))
)

const OrganizationPaymentMethodsLazy = lazy(() =>
    import("./pages/OrganizationPaymentMethods").then(({ OrganizationPaymentMethods }) => ({
        default: OrganizationPaymentMethods,
    }))
)

const OrganizationApprovalsLazy = lazy(() =>
    import("./pages/OrganizationApprovals").then(({ OrganizationApprovals }) => ({
        default: OrganizationApprovals,
    }))
)
export default {
    routes: [
        <Route element={<ProtectedRoutes />} key="profile_route">
            <Route path={PROFILE_ROUTE} element={<ProfileLazy />} />
            <Route path={ORGANIZATION_INFO_ROUTE} element={<OrganizationDetailsLazy />} />
            <Route path={ORGANIZATION_INTEGRATION_ROUTE} element={<OrganizationIntegrationsLazy />} />
            <Route path={ORGANIZATION_CONTACTS_ROUTE} element={<OrganizationMembersLazy />} />
            <Route path={ORGANIZATION_TEAMS_ROUTE} element={<OrganizationTeamsLazy />} />
            <Route path={ORGANIZATION_TAGS_ROUTE} element={<TagGroupsAndTagsLazy />} />
            <Route path={ORGANIZATION_CREATE_PRODUCT_ROUTE} element={<OrganizationCreateProductLazy />} />
            <Route path={ORGANIZATION_EDIT_PRODUCT_ROUTE} element={<OrganizationEditProductLazy />} />
            <Route path={ORGANIZATION_CATALOGS_ROUTE} element={<OrganizationCatalogLazy />} />
            <Route path={ORGANIZATION_DETAILS_ROUTE} element={<OrganizationDetailsLazy />} />
            <Route path={ORGANIZATION_ROUTE} element={<OrganizationDetailsLazy />} />
            <Route path={ORGANIZATIONS_ROUTE} element={<OrganizationsLazy />} />
            <Route path={ORGANIZATION_KYB_ROUTE} element={<OrganizationKYBLazy />} />
            <Route path={ORGANIZATION_INVOICE_EXPORTS_ROUTE} element={<OrganizationInvoiceExportsLazy />} />
            <Route path={ORGANIZATION_PAYMENT_METHODS_ROUTE} element={<OrganizationPaymentMethodsLazy />} />
            <Route path={ORGANIZATION_REFUSAL_REASONS_ROUTE} element={<OrganizationApprovalsLazy />} />
        </Route>,
    ],
}
