import { Box } from "@mui/material"
import { skipToken } from "@reduxjs/toolkit/query"
import React, { useEffect } from "react"
import { Feather } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { generatePath, useParams } from "react-router-dom"

import { HeaderH1, Loader, Tabs } from "~/components"
import {
    useGetCustomFormQuery,
    useGetCustomFormStatsQuery,
    useGetCustomFormSurveysQuery,
} from "~/domains/identity/custom-forms/api/customFormsApi"
import { ResponsesDatagrid } from "~/domains/identity/custom-forms/components/ResponsesDatagrid"
import { CUSTOM_FORMS, CUSTOM_FORMS_ROUTE } from "~/domains/identity/custom-forms/routes"
import { useRedirectIfOrganizationChanged } from "~/domains/identity/organization/hooks"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"

import { UserSurveyStats } from "../types/CustomForms"

const messages = defineMessages({
    insights: {
        id: "smartForms.tabHeader.insights",
        defaultMessage: "Insights",
    },
    responses: {
        id: "smartForms.tabHeader.responses",
        defaultMessage: "Responses",
    },
    responsesHeader: {
        id: "smartForms.header.responses",
        defaultMessage: "Partner responses",
    },
    columnHeaderEmail: {
        id: "common.mail",
        defaultMessage: "Email",
    },
    columnHeaderCreatedDate: {
        id: "common.createdDate",
        defaultMessage: "Created date",
    },
})

export const CustomForm = () => {
    const [surveysWithStats, setSurveysWithStats] = React.useState<UserSurveyStats[]>([])
    const { formId } = useParams<{ formId: string }>()
    const { formatMessage } = useIntl()
    const organizationId = useAppSelector(selectCurrentOrganizationId)
    useRedirectIfOrganizationChanged(organizationId, generatePath(CUSTOM_FORMS_ROUTE))

    const { data: form, isLoading } = useGetCustomFormQuery(
        formId && organizationId ? { organisationId: organizationId, formId: formId } : skipToken
    )

    const { data: surveys } = useGetCustomFormSurveysQuery(
        formId && organizationId ? { organisationId: organizationId, formId: formId } : skipToken
    )

    // Fetch survey stats
    const { data: surveyStats } = useGetCustomFormStatsQuery(
        organizationId && formId ? { organisationId: organizationId, formId: formId } : skipToken
    )

    // Enrich the surveys with stats
    useEffect(() => {
        if (surveys && surveyStats) {
            const enrichedSurveys = surveys.map((survey) => {
                const surveyStat = surveyStats.find((stat) => stat.id === survey.id)
                return {
                    ...survey,
                    metrics: surveyStat?.metrics,
                } as UserSurveyStats
            })
            setSurveysWithStats(enrichedSurveys)
        }
    }, [surveys, surveyStats])

    return (
        <div>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <HeaderH1 title={form?.name || ""} icon={<Feather />} backLink={CUSTOM_FORMS} />
                    <Box data-testid="box" className="main-box">
                        <Tabs
                            className="tabs-communications"
                            tabs={[
                                {
                                    label: formatMessage(messages.responses),
                                    component: (
                                        <>
                                            {organizationId && form && (
                                                <ResponsesDatagrid
                                                    surveys={surveysWithStats}
                                                    formName={form?.name}
                                                    formMetrics={form?.metrics}
                                                />
                                            )}
                                        </>
                                    ),
                                },
                            ]}
                        />
                    </Box>
                </>
            )}
        </div>
    )
}
