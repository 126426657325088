import { useCallback } from "react"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { commonMessages } from "~/common-messages"
import { displayAndCaptureError } from "~/displayAndCaptureError"
import { useTagsApi } from "~/domains/analytics/tags/tagsApi"
import { CreateTagObjectI, TagI, TagObjectI, TagObjectType } from "~/domains/analytics/tags/types"
import { invoiceApi, invoiceApiTags } from "~/domains/transactions/invoices-v1/api/invoiceApi"
import { useAppDispatch } from "~/store/hooks"
import { InvoiceBackendVersion, OrganizationId, UserId, ViewTypeI } from "~/types"
import { isDefined } from "~/utils/isDefined"

import { accountActions } from "../accountSlice"

const messages = defineMessages({
    nothingToUpdate: {
        id: "account.documents.bulk.nothingToUpdate",
        defaultMessage: "No invoice to update",
    },
    addTagsSuccess: {
        id: "account.documents.bulk.addTags.success",
        defaultMessage: "{countTags} tag{sCount} added in {countSelected} invoice{sSelected}",
    },
})

export const useBulkAddTags = (
    organizationId: OrganizationId,
    userId: UserId,
    tagObjectType: TagObjectType,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tagContext?: any,
    version?: InvoiceBackendVersion
) => {
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()
    const tagsApi = useTagsApi()

    return useCallback(
        async (documentIds: string[], tagsToAdd: TagI[], tagsToRemove: TagI[], view: ViewTypeI) => {
            await Promise.allSettled(
                tagsToRemove.flatMap((tagToRemove) =>
                    documentIds.map((objectId) => tagsApi.deleteTagObject(organizationId, objectId, tagToRemove.tagId))
                )
            )
            const createBulkObjectTags = tagsToAdd.flatMap((tag) =>
                documentIds.map(
                    (objectId): CreateTagObjectI => ({
                        objectId,
                        tagId: tag.tagId,
                        objectType: tagObjectType,
                        objectContext: tagContext,
                    })
                )
            )
            await tagsApi.createObjectTags(organizationId, createBulkObjectTags)

            const objectTagsToRemove = documentIds.flatMap((objectId) =>
                tagsToRemove.map(
                    (tagToRemove): TagObjectI => ({
                        organizationId,
                        objectId,
                        tagId: tagToRemove.tagId,
                    })
                )
            )

            const count = tagsToAdd.length + tagsToRemove.length
            if (!count) {
                return toast.error(formatMessage(messages.nothingToUpdate))
            }

            if (version === InvoiceBackendVersion.V1) {
                dispatch(invoiceApi.util.invalidateTags(invoiceApiTags))
                return
            }

            try {
                dispatch(
                    accountActions.bulkAddTags({
                        selected: documentIds,
                        tagsToAdd: createBulkObjectTags
                            .map((tagObject): TagObjectI | null => {
                                const tag = tagsToAdd.find((tagtoAdd) => tagtoAdd.tagId === tagObject.tagId)
                                if (tag) {
                                    return {
                                        objectId: tagObject.objectId,
                                        tagId: tagObject.tagId,
                                        tagGroupName: tag.tagGroupId,
                                        tagName: tag.name,
                                        organizationId,
                                    }
                                }
                                return null
                            })
                            .filter(isDefined),
                        tagsToRemove: objectTagsToRemove,
                        view,
                    })
                )
                dispatch(accountActions.bulkSuccess())

                const countSelected = documentIds.length
                const countTags = tagsToAdd.length
                return toast.success(
                    formatMessage(messages.addTagsSuccess, {
                        countTags,
                        sCount: countTags > 1 ? "s" : "",
                        countSelected,
                        sSelected: countSelected > 1 ? "s" : "",
                    })
                )
            } catch (err) {
                displayAndCaptureError(formatMessage(commonMessages.error), err)
            }
        },
        [tagsApi, tagObjectType, organizationId, userId]
    )
}
