/* eslint-disable complexity */
import { Stack } from "@mui/material"
import { useEffect, useMemo, useState } from "react"

import { commonMessages } from "~/common-messages"
import { ActionsMenu, ShareButton } from "~/components"
import { useGetPartnershipQuery } from "~/domains/identity/partners/api/partnerApiV1"
import useSupplierAllowed from "~/domains/identity/partners/store/hooks/useSupplierAllowed"
import { ShareObjectPermissionsButton } from "~/domains/identity/roles-permissions/components/ShareObjectPermissionsModal/ShareObjectPermissionsButton"
import { SpiceDBObjectType } from "~/domains/identity/roles-permissions/types/SpiceDB"
import { useApprovalReview } from "~/domains/orchestration/flows-v0/hooks/useApprovalReview"
import { ApprovalObjectType } from "~/domains/orchestration/flows-v0/types"
import { OrganizationSummary } from "~/domains/transactions/_shared/components/Organizations/Organizations"
import { PurchaseViewType } from "~/domains/transactions/_shared/types/Purchases"
import {
    ApprovePO,
    CancelEditPO,
    ClosePO,
    DeletePO,
    DownloadPOPdf,
    EditPO,
    OutForDeliveryPO,
    ReOpenPO,
    ReceiveOrDeliverPO,
    RejectPO,
    SavePO,
    SendPO,
    SubmitPO,
} from "~/domains/transactions/purchase-orders/components/Actions"
import {
    canClosePurchaseOrder,
    canDeletePurchaseOrder,
    canDownloadPurchaseOrder,
    canEditPurchaseOrder,
    canMarkAllInPreparationPurchaseOrder,
    canMarkOutForDeliveryPurchaseOrder,
    canReOpenPurchaseOrder,
    canReceiveOrDeliverPurchaseOrder,
    canSendPurchaseOrder,
    canShareExternallyPurchaseOrder,
    canShareInternallyPurchaseOrder,
    canSubmitPurchaseOrder,
    isStatusApprovable,
} from "~/domains/transactions/purchase-orders/core/lifecyclePurchaseOrder"
import { allLinesRejected } from "~/domains/transactions/purchase-orders/core/purchaseOrder"
import { PurchaseOrders } from "~/domains/transactions/purchase-orders/types"
import { OrganizationI, ViewTypeI } from "~/types"
import { SharedObjectType } from "~/types/SharedObjects"

import { MarkAllInPreparation } from "./MarkAllInPreparation"

interface PropsPOActions {
    PO: PurchaseOrders
    organization: OrganizationI
    buyerOrganization: OrganizationSummary
    supplierOrganization: OrganizationSummary
    mode: PurchaseViewType
    viewType: ViewTypeI
    allLinesApproved: boolean
}

export const ActionsHeader: React.FC<PropsPOActions> = ({
    PO,
    organization,
    buyerOrganization,
    supplierOrganization,
    mode,
    viewType,
    allLinesApproved,
}) => {
    const { data: partnershipData, isLoading: partnershipDataLoading } = useGetPartnershipQuery(
        {
            organizationId: organization.id,
            partnerId: PO.supplierId,
        },
        {
            skip: !PO.supplierId,
        }
    )

    const {
        isApprovalRequired: isPOApprovalRequired,
        isAdmin,
        isApprovalDone,
    } = useApprovalReview({
        organisationId: organization.id,
        objectId: PO.id,
        objectType: ApprovalObjectType.PURCHASE_ORDER,
    })
    const [, setSupplierAllowed] = useState(false)

    const areAllLinesRejected = useMemo(() => allLinesRejected(PO.lines, viewType), [PO.lines, viewType])

    const supplierAllowed = useSupplierAllowed(partnershipData ?? [], partnershipDataLoading)

    useEffect(() => {
        setSupplierAllowed(supplierAllowed)

        return () => {
            setSupplierAllowed(false)
        }
    }, [partnershipData, partnershipDataLoading, supplierAllowed])

    const canSubmit = PO.permissions.submit && canSubmitPurchaseOrder(PO.statusPresentation)

    // If the current user action is to send the PO, we show the CTA
    const sendPermission = PO.permissions.send
    const mustSend = sendPermission && canSendPurchaseOrder(PO.statusPresentation, viewType)
    // This is for the actions menu, we show the menu item when the PO is not to be sent (not to have both buttons)
    // and it can be shared externally from "approved status" to end of its lifecycle
    const canSend = !mustSend && sendPermission && canShareExternallyPurchaseOrder(PO.statusPresentation)

    // This is for the share permissions modal. It's meant to be only internal
    const canShareInternally = canShareInternallyPurchaseOrder(PO.statusPresentation)

    const canDownload = canDownloadPurchaseOrder(PO.statusPresentation)

    const canEdit = canEditPurchaseOrder(PO.statusPresentation, PO.permissions.edit ?? false)

    const canDelete = PO.permissions.delete && canDeletePurchaseOrder(PO.statusPresentation)

    const canClose = PO.permissions.close && canClosePurchaseOrder(PO.statusPresentation)

    const canReOpen = PO.permissions.reopen && canReOpenPurchaseOrder(PO.statusPresentation)

    // Define reusable logical components
    const hasApprovePermission = PO.permissions.approve
    const isStatusEligibleForApproval = isStatusApprovable(PO.statusPresentation, viewType)
    const isStandardUserApprovalAllowed = !isPOApprovalRequired || isApprovalDone

    // Conditions for rejection
    const canReject =
        // Standard user with permissions can reject if not all lines rejected and approval flow allows
        (hasApprovePermission &&
            isStatusEligibleForApproval &&
            !areAllLinesRejected &&
            isStandardUserApprovalAllowed) ||
        // Admins can always reject if status is eligible
        (isStatusEligibleForApproval && isAdmin)

    // Conditions for approval
    const canApprove =
        // Standard user with permissions can approve if not all lines approved and approval flow allows
        (hasApprovePermission && isStatusEligibleForApproval && !allLinesApproved && isStandardUserApprovalAllowed) ||
        // Admins can always approve if status is eligible
        (isStatusEligibleForApproval && isAdmin)

    const canMarkAllInPreparation =
        PO.permissions.mark_line_in_preparation &&
        canMarkAllInPreparationPurchaseOrder(PO.statusPresentation, PO.fulfillmentPresentation, viewType)

    const canMarkOutForDelivery =
        PO.permissions.mark_line_in_transit &&
        canMarkOutForDeliveryPurchaseOrder(PO.statusPresentation, PO.fulfillmentPresentation, viewType)

    const canReceiveOrDeliver =
        PO.permissions.mark_line_delivered_or_received &&
        canReceiveOrDeliverPurchaseOrder(PO.statusPresentation, PO.fulfillmentPresentation, viewType)

    const actions = [
        ...(canEdit ? [{ render: <EditPO PO={PO} display="menu-item" /> }] : []),
        ...(canSend
            ? [
                  {
                      render: (
                          <ShareButton
                              objectId={PO.id}
                              objectType={SharedObjectType.PurchaseOrder}
                              organizationId={organization.id}
                              buyerOrganization={buyerOrganization}
                              supplierOrganization={supplierOrganization}
                              buttonMessage={commonMessages.sendOrder}
                          />
                      ),
                  },
              ]
            : []),
        ...(canShareInternally
            ? [
                  {
                      render: (
                          <ShareObjectPermissionsButton
                              objectId={PO.id}
                              objectType={SpiceDBObjectType.PURCHASE_ORDER}
                              organizationId={organization.id}
                              display="menu-item"
                          />
                      ),
                  },
              ]
            : []),
        ...(canDownload ? [{ render: <DownloadPOPdf purchaseOrder={PO} /> }] : []),
        ...(canDelete ? [{ render: <DeletePO PO={PO} viewType={viewType} display="menu-item" /> }] : []),
        ...(canReOpen ? [{ render: <ReOpenPO PO={PO} display="menu-item" /> }] : []),
    ]

    return (
        <Stack direction="row" spacing={1}>
            {mode === PurchaseViewType.VIEW ? (
                <>
                    <ActionsMenu isActionIcon={false} actions={actions} />

                    {canSubmit && <SubmitPO PO={PO} />}

                    {canReject && (
                        <RejectPO PO={PO} needsNewApproval={isPOApprovalRequired} isFlowApprovalDone={isApprovalDone} />
                    )}

                    {canApprove && (
                        <ApprovePO
                            PO={PO}
                            needsNewApproval={isPOApprovalRequired}
                            isFlowApprovalDone={isApprovalDone}
                        />
                    )}

                    {mustSend && <SendPO PO={PO} viewType={viewType} />}

                    {canMarkAllInPreparation && <MarkAllInPreparation PO={PO} />}

                    {canMarkOutForDelivery && <OutForDeliveryPO PO={PO} />}

                    {canReceiveOrDeliver && <ReceiveOrDeliverPO PO={PO} viewType={viewType} />}

                    {canClose && <ClosePO PO={PO} viewType={viewType} />}
                </>
            ) : (
                <>
                    <CancelEditPO PO={PO} />
                    <SavePO PO={PO} />
                </>
            )}
        </Stack>
    )
}
