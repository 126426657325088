import { Grid, List, ListItem, Stack, Tooltip } from "@mui/material"
import { FC, Fragment } from "react"
import { Edit2, Plus, Trash2 } from "react-feather"
import { v4 as uuid } from "uuid"

import { Button, SafeFormattedMessage } from "~/components"
import { CodeTypography } from "~/components/CodeTypography/CodeTypography"
import { IconButton } from "~/domains/orchestration/flows/components/IconButton"
import { messages } from "~/domains/orchestration/flows/locale"
import { Condition as ConditionType, Conditions } from "~/domains/orchestration/flows/types"

interface Props {
    conditions: Conditions
    handleChangeConditions: (conditions: Conditions, conditionGroupId: string) => void
    handleNewCondition: (condition: ConditionType, conditionGroupId: string) => void
    handleEditCondition: (conditionId: string, conditionGroupId: string) => void
}

export const Condition: FC<Props> = ({
    conditions,
    handleChangeConditions,
    handleNewCondition,
    handleEditCondition,
}) => {
    const handleDelete = (item: ConditionType) => () => {
        const newConditions = conditions.conditions.filter((c) => c.id !== item.id)
        handleChangeConditions(
            {
                id: conditions.id,
                conditions: newConditions,
            },
            conditions.id
        )
    }

    const handleAddCondition = () => {
        handleNewCondition(
            {
                id: uuid(),
                condition: "",
            },
            conditions.id
        )
    }

    const handleEdit = (item: ConditionType) => () => handleEditCondition(item.id, conditions.id)

    const conditionsList = conditions.conditions.map((item, index) => {
        if (!item.condition) return null

        return (
            <Fragment key={item.id}>
                {index !== 0 && (
                    <ListItem>
                        <SafeFormattedMessage
                            tagName="h5"
                            {...messages.eventTriggerConfiguration.andConditionOtherLabel}
                        />
                    </ListItem>
                )}
                <ListItem>
                    <Grid container alignItems="center" spacing={0.5}>
                        <Grid item xs={10}>
                            <Tooltip title={item.condition} placement="left">
                                <CodeTypography noWrap>{item.condition}</CodeTypography>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={2}>
                            <Stack direction="row" gap={0.5}>
                                <IconButton onClick={handleEdit(item)} type="grey-light" size="small">
                                    <Edit2 size={12} color="var(--color-grey)" />
                                </IconButton>

                                <IconButton onClick={handleDelete(item)} type="grey-light" size="small">
                                    <Trash2 size={12} color="var(--color-grey)" />
                                </IconButton>
                            </Stack>
                        </Grid>
                    </Grid>
                </ListItem>
            </Fragment>
        )
    })

    return (
        <Stack gap={1} className="flows-eventTriggerConfiguration-andConditions">
            <List>{conditionsList}</List>
            <Stack direction="column" gap={1} className="flows-eventTriggerConfiguration-newConditionSuggestions">
                <Button
                    type="grey-light"
                    onClick={handleAddCondition}
                    className="flows-eventTriggerConfiguration-newCondition"
                >
                    <Plus size={18} />
                    <SafeFormattedMessage {...messages.eventTriggerConfiguration.newConditionLabel} />
                </Button>
            </Stack>
        </Stack>
    )
}
