import { Box, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material"
import dayjs from "dayjs"
import { MouseEvent, useCallback, useEffect, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Card, Loader, NoPermission, SafeFormattedMessage, TooltipConditional } from "~/components"
import { permissionMessages } from "~/domains/identity/roles-permissions/utils/permissions"
import { useHasPaymentPermissions } from "~/domains/payment/hooks"
import { ObjectAssignPicker } from "~/domains/payment/payment-batches/components"
import {
    PayeePaymentMethodDetails,
    PayerPaymentMethodDetails,
} from "~/domains/payment/payment-method-details/components"
import { useGetPaymentMethodsQuery } from "~/domains/payment/payment-methods/api/paymentMethodsApi"
import { PaymentMethodsSelector } from "~/domains/payment/payment-methods/components"
import { PaymentMethod } from "~/domains/payment/payment-methods/types"
import { useManagePaymentTerms } from "~/domains/payment/payment-terms/hooks/useManagePaymentTerms"
import { PaymentDates } from "~/domains/payment/payment/components/"
import { useManagePayment } from "~/domains/payment/payment/hooks/useManagePayment"
import {
    DocumentData,
    DocumentPaymentStatus,
    Payment,
    PaymentStatus,
    SelectedPaymentMethods,
} from "~/domains/payment/payment/types"
import { toggleButtonsStyles } from "~/domains/payment/payment/utils/customStyles"
import { ObjectType, PaymentMethodDetailsFormType } from "~/domains/payment/types"
import { CurrencyCodes } from "~/types"

const messages = defineMessages({
    paid: {
        id: "payment.document.paymentDetails.paid",
        defaultMessage: "Paid",
    },
    scheduled: {
        id: "payment.document.paymentDetails.scheduled",
        defaultMessage: "Scheduled",
    },
    unpaid: {
        id: "payment.document.paymentDetails.unpaid",
        defaultMessage: "Not paid",
    },
    vendorPaymentMethod: {
        id: "payment.document.paymentDetails.vendorPaymentMethod",
        defaultMessage: "Where I make the payment",
    },
    buyerPaymentMethod: {
        id: "payment.document.paymentDetails.buyerPaymentMethod",
        defaultMessage: "How I plan to pay",
    },
    paymentMethod: {
        id: "payment.document.paymentDetails.paymentMethod",
        defaultMessage: "Payment method",
    },
    generalPaymentMethod: {
        id: "payment.document.paymentDetails.generalPaymentMethod",
        defaultMessage: "Default payment method",
    },
    or: {
        id: "payment.document.paymentDetails.or",
        defaultMessage: "or",
    },
})

const initialState = {
    payerPaymentMethodDetailsId: null,
    payeePaymentMethodDetailsId: null,
    paymentMethodId: null,
}

interface OcrPaymentProps {
    loading?: boolean
    payerId: string | null
    payeeId: string | null
    documentId: string
    documentData: DocumentData
}

export const OcrPayment = ({ payeeId, payerId, documentId, documentData, loading = false }: OcrPaymentProps) => {
    const { formatMessage } = useIntl()
    const { permissions } = useHasPaymentPermissions({ authorizations: ["read", "update"] })
    const hasReadPermission = Boolean(permissions?.read?.hasPermission)
    const hasUpdatePermission = Boolean(permissions?.update?.hasPermission)

    const [documentPaymentStatus, setDocumentPaymentStatus] = useState<DocumentPaymentStatus>(
        DocumentPaymentStatus.UNPAID
    )
    const [selectedPaymentMethods, setSelectedPaymentMethods] = useState<SelectedPaymentMethods>(initialState)
    const {
        isLoading,
        isUpdatingPayment,
        isSoonerDueDate,
        hasPayementTerms,
        payment,
        assignedObject,
        editPayment,
        getScheduledDate,
    } = useManagePayment({
        documentId,
        documentData,
        payeeId,
        payerId,
    })

    const {
        loading: isPaymentTermsLoading,
        defaultPaymentTermObject,
        deleteObject,
    } = useManagePaymentTerms({ objectId: payment?.id, objectType: ObjectType.PAYMENT }, { showList: true })

    const { data: paymentMethodsData, isLoading: isLoadingPaymentMethods } = useGetPaymentMethodsQuery(null)
    const paymentMethods = paymentMethodsData?.items ?? []

    const isLoadingState = isLoading || isLoadingPaymentMethods || isPaymentTermsLoading

    const onDocumentPaymentStatusChanged = useCallback(
        async (_: MouseEvent<HTMLElement>, value: DocumentPaymentStatus) => {
            if (!value || value === documentPaymentStatus) return

            const { dueDate } = documentData
            let paymentStatus: PaymentStatus

            switch (value) {
                case DocumentPaymentStatus.PAID:
                    paymentStatus = PaymentStatus.EXECUTED
                    break
                case DocumentPaymentStatus.SCHEDULED:
                    paymentStatus = PaymentStatus.SCHEDULED
                    break
                default:
                    paymentStatus = PaymentStatus.DRAFT
                    break
            }

            if (payment && payment.status !== paymentStatus) {
                await editPayment({
                    status: paymentStatus,
                    ...(paymentStatus === PaymentStatus.DRAFT
                        ? {
                              date_validated: null,
                              ...(dueDate ? { value_date_expected: dueDate } : {}),
                          }
                        : {}),
                    ...(paymentStatus === PaymentStatus.SCHEDULED
                        ? {
                              value_date_expected: null,
                              date_validated: null,
                              date_scheduled: getScheduledDate(),
                          }
                        : {}),
                    ...(paymentStatus === PaymentStatus.EXECUTED ? { value_date_expected: null } : {}),
                })

                if (paymentStatus !== PaymentStatus.DRAFT && defaultPaymentTermObject) {
                    await deleteObject(defaultPaymentTermObject.id)
                }
            }
            setDocumentPaymentStatus(value)
        },
        [defaultPaymentTermObject, documentPaymentStatus, payment, editPayment]
    )

    const handlePaymentDateUpdate = (paymentData: Partial<Payment>) => {
        editPayment(paymentData)
    }

    const handlePayerPaymentMethodChanged = (payerPaymentMethodDetailsId: string | null) => {
        setSelectedPaymentMethods((prev) => ({
            ...prev,
            payerPaymentMethodDetailsId: payerPaymentMethodDetailsId || null,
        }))
        editPayment({
            origin_cash_id: payerPaymentMethodDetailsId || null,
        })
    }

    const handlePayeePaymentMethodChanged = (payeePaymentMethodDetailsId: string | null) => {
        setSelectedPaymentMethods((prev) => ({ ...prev, payeePaymentMethodDetailsId }))
        editPayment({ destination_cash_id: payeePaymentMethodDetailsId })
    }

    const handleGlobalPaymentMethodChange = (paymentMethod: PaymentMethod | null) => {
        if (!hasUpdatePermission) return
        setSelectedPaymentMethods((prev) => ({ ...prev, paymentMethodId: paymentMethod?.id ?? null }))
        editPayment({ payment_method_id: paymentMethod?.id ?? null })
    }

    const handleBatchAssignmentDone = () => {
        if (payment?.origin_cash_id) {
            setSelectedPaymentMethods((prev) => ({ ...prev, payerPaymentMethodDetailsId: null }))
            editPayment({ origin_cash_id: null })
        }
    }

    useEffect(() => {
        if (!isLoadingState && payment && payment.id) {
            const { status, origin_cash_id, destination_cash_id } = payment
            let docPaymentStatus: DocumentPaymentStatus

            switch (status) {
                case PaymentStatus.EXECUTED:
                    docPaymentStatus = DocumentPaymentStatus.PAID
                    break
                case PaymentStatus.SCHEDULED:
                    docPaymentStatus = DocumentPaymentStatus.SCHEDULED
                    break
                default:
                    docPaymentStatus = DocumentPaymentStatus.UNPAID
                    break
            }

            setDocumentPaymentStatus(docPaymentStatus)
            setSelectedPaymentMethods((prev) => ({
                ...prev,
                payerPaymentMethodDetailsId: origin_cash_id ?? null,
                payeePaymentMethodDetailsId: destination_cash_id ?? null,
                paymentMethodId: payment.payment_method_id ?? null,
            }))
        }
    }, [payment, isLoadingState])

    if (isLoadingState || loading) {
        return <Loader />
    }

    if (!payment || !payment.id) {
        return null
    }

    return hasReadPermission ? (
        <Stack spacing={1} gap={1}>
            <TooltipConditional
                title={<SafeFormattedMessage {...permissionMessages.errorNoAccessAdministrator} />}
                condition={!hasUpdatePermission}
                placement="top"
                arrow
            >
                <Box component="span">
                    <ToggleButtonGroup
                        color="primary"
                        fullWidth
                        sx={toggleButtonsStyles}
                        value={documentPaymentStatus}
                        disabled={!hasUpdatePermission || isUpdatingPayment}
                        exclusive
                        onChange={onDocumentPaymentStatusChanged}
                    >
                        <ToggleButton value={DocumentPaymentStatus.PAID}>
                            <SafeFormattedMessage {...messages.paid} />
                        </ToggleButton>
                        <ToggleButton value={DocumentPaymentStatus.SCHEDULED}>
                            <SafeFormattedMessage {...messages.scheduled} />
                        </ToggleButton>
                        <ToggleButton value={DocumentPaymentStatus.UNPAID}>
                            <SafeFormattedMessage {...messages.unpaid} />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </TooltipConditional>
            <PaymentDates
                payment={payment}
                dueDate={documentData?.dueDate}
                isSoonerDueDate={isSoonerDueDate}
                hasPayementTerms={hasPayementTerms}
                loading={isUpdatingPayment}
                onPaymentDateUpdate={handlePaymentDateUpdate}
            />
            <Card title={formatMessage(messages.generalPaymentMethod)}>
                <TooltipConditional
                    title={<SafeFormattedMessage {...permissionMessages.errorNoAccessAdministrator} />}
                    condition={!hasUpdatePermission}
                    placement="top"
                    arrow
                >
                    <Box component="span">
                        <PaymentMethodsSelector
                            items={paymentMethods}
                            value={selectedPaymentMethods.paymentMethodId}
                            loading={isUpdatingPayment}
                            disabled={!hasUpdatePermission}
                            label={formatMessage(messages.paymentMethod)}
                            onChange={handleGlobalPaymentMethodChange}
                        />
                    </Box>
                </TooltipConditional>
            </Card>
            <Typography textAlign="center" component="p">
                <SafeFormattedMessage {...messages.or} />
            </Typography>
            {payeeId && payerId && (
                <PayeePaymentMethodDetails
                    payeeId={payeeId}
                    payerId={payerId}
                    formType={PaymentMethodDetailsFormType.INLINE}
                    label={formatMessage(messages.vendorPaymentMethod)}
                    loading={isUpdatingPayment}
                    selectedPaymentMethodDetailsId={selectedPaymentMethods.payeePaymentMethodDetailsId}
                    onPaymentMethodChanged={handlePayeePaymentMethodChanged}
                />
            )}
            {!assignedObject && payerId && (
                <PayerPaymentMethodDetails
                    payerId={payerId}
                    loading={isUpdatingPayment}
                    formType={PaymentMethodDetailsFormType.INLINE}
                    label={formatMessage(messages.buyerPaymentMethod)}
                    selectedPaymentMethodDetailsId={selectedPaymentMethods.payerPaymentMethodDetailsId}
                    onPaymentMethodChanged={handlePayerPaymentMethodChanged}
                />
            )}
            <ObjectAssignPicker
                isPaid={documentPaymentStatus === DocumentPaymentStatus.PAID}
                assignedObject={assignedObject}
                payload={{
                    objectId: documentId,
                    objectType: ObjectType.INVOICE,
                    amount: documentData.total.amount ?? 0,
                    currency: documentData.total.currency ?? CurrencyCodes.EUR,
                    dateScheduled: documentData.dueDate ?? dayjs().format("YYYY-MM-DD"),
                    payeeId,
                }}
                onAssignDone={handleBatchAssignmentDone}
            />
        </Stack>
    ) : (
        <NoPermission />
    )
}
