import { Flow, FlowItem } from "~/domains/orchestration/flows/types"

/**
 *
 * @param flow
 * @returns Flow
 * @description bump the version of the flow
 */
const bumpFlow = (flow: Flow): Flow => ({ ...flow, version: flow.version + 1 })

const sortByName = (a: FlowItem, b: FlowItem): number => {
    return a.name.localeCompare(b.name, undefined, { sensitivity: "base" })
}

const sortByDate = (a: FlowItem, b: FlowItem): number => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
}

const filterFlows = (searchTerm: string, showEnabledOnly: boolean) => {
    const normalizedSearch = searchTerm.toLowerCase().trim()

    return (flow: FlowItem): boolean => {
        // First check enabled status if required
        if (showEnabledOnly && !flow.enabled) {
            return false
        }

        // If no search term, just return based on enabled status
        if (!normalizedSearch) {
            return true
        }

        // Search in multiple fields
        return [
            flow.name,

            // Add any other searchable fields here
        ].some((field) => field?.toLowerCase().includes(normalizedSearch))
    }
}

export { bumpFlow, sortByName, sortByDate, filterFlows }
