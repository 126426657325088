import { FilterOptionsState, createFilterOptions } from "@mui/material"
import { MessageDescriptor, PrimitiveType, defineMessages } from "react-intl"

import {
    CompanyAutocompleteResult,
    CompanyAutocompleteType,
} from "~/domains/identity/organization/types/CompanyAutocomplete"
import { CollaborationStatusEiffage, PartnerOrganizationI } from "~/domains/identity/partners/types"
import { CountryCode, OrganizationI, OrganizationId, WhitePagesIdTypes, WhitePagesResultI } from "~/types"

export const CompanyAutocompleteTypeLabel: Record<CompanyAutocompleteType, MessageDescriptor> = defineMessages({
    org: {
        id: "company.autocomplete.myPartners",
        defaultMessage: "My partners",
    },
    wp: {
        id: "company.autocomplete.legalBase",
        defaultMessage: "Legal base",
    },
    create: {
        id: "company.autocomplete.customCreation",
        defaultMessage: "Custom creation",
    },
    disabled: {
        id: "company.autocomplete.myPartners",
        defaultMessage: "My partners",
    },
})

export const getCompanyAutocompleteTypeLabel = (companyType: CompanyAutocompleteType): MessageDescriptor =>
    CompanyAutocompleteTypeLabel[companyType]

export const createAutocompleteOptionFromOrganization = (
    organization: OrganizationI | PartnerOrganizationI,
    brandNames: Record<OrganizationId, string>
): CompanyAutocompleteResult => {
    const organizationName = brandNames[organization.id] ?? organization.name

    return {
        type: "org",
        value: {
            ...organization,
            name: organizationName,
        },
    }
}

export const createAutocompleteOptionFromWhitePagesResult = (
    whitePagesResult: WhitePagesResultI
): CompanyAutocompleteResult => ({
    type: "wp",
    value: whitePagesResult,
})

export const createDisabledOption = (name: string): CompanyAutocompleteResult => ({
    type: "disabled",
    value: {
        name,
    },
})

export const getCityAndPostalCode = (companyResult: CompanyAutocompleteResult): string => {
    const { type, value } = companyResult

    if (type === "org") {
        return value.address?.city ?? ""
    }

    if (type === "create" || type === "disabled") {
        return ""
    }

    const city = value.city ?? ""
    const postalCode = value.postalCode ?? ""
    return `${city} ${postalCode}`.trim()
}

export const WHITE_PAGES_ID_TYPES_WITH_SIREN = [WhitePagesIdTypes.FRENCH_SIRET, WhitePagesIdTypes.FRENCH_SIREN]
export const hasWhitePagesIdTypeSIREN = (type: WhitePagesIdTypes) => {
    if (WHITE_PAGES_ID_TYPES_WITH_SIREN.includes(type)) {
        return true
    }
    return WHITE_PAGES_ID_TYPES_WITH_SIREN.some((t) => type.startsWith(t))
}
export const formatFrenchSiretOrSiren = (id: string, type: WhitePagesIdTypes) => {
    if (hasWhitePagesIdTypeSIREN(type)) {
        return `${id.substring(0, 3)} ${id.substring(3, 6)} ${id.substring(6, 9)} ${id.substring(9, 14)}`
    }
    return id
}

export const getRegistrationNumber = (option: CompanyAutocompleteResult) => {
    if (option.type === "wp") {
        return formatFrenchSiretOrSiren(option.value.id, option.value.idType)
    }
    if (option.type === "org") {
        return formatFrenchSiretOrSiren(
            option.value.registration?.preferredRegistrationNumber?.registrationNumber ?? "",
            option.value.registration?.preferredRegistrationNumber?.registrationType as WhitePagesIdTypes
        )
    }
    return ""
}

export const filterOptions = createFilterOptions({
    stringify: (option: CompanyAutocompleteResult) =>
        option.type === "org"
            ? `${option.value.name} ${option.value.registration?.preferredRegistrationNumber?.registrationNumber}`
            : option.value.name,
})

export const filterOptionsWithCreation =
    (
        formatMessage: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string,
        countryCode: CountryCode
    ) =>
    (options: CompanyAutocompleteResult[], params: FilterOptionsState<CompanyAutocompleteResult>) => {
        const { inputValue } = params

        const isExistingInOrganizations = options.some(
            (option) => option.type === "org" && inputValue === option.value.name
        )
        const companyCreation: CompanyAutocompleteResult = {
            type: "create",
            value: {
                countryCode,
                name: inputValue,
                label: formatMessage(
                    {
                        id: "account.organization.companyAutocomplete.CreateCompany",
                        defaultMessage: `Add: "{inputValue}"`,
                    },
                    { inputValue }
                ),
            },
        }

        if (inputValue) {
            if (isExistingInOrganizations) {
                options.push(companyCreation)
            } else {
                options.unshift(companyCreation)
            }
        }

        return options
    }

export const getOptionLabel = (option: string | CompanyAutocompleteResult) => {
    if (typeof option === "string") return option
    return option.value.name
}

export const hasCollaborationStatus = (
    option: CompanyAutocompleteResult
): option is CompanyAutocompleteResult & { value: { collaborationStatus: string } } => {
    return option.type === "org" && option.value && "collaborationStatus" in option.value
}

export const isPartnerOrganizationI = (org: OrganizationI | PartnerOrganizationI): org is PartnerOrganizationI => {
    return "collaborationStatus" in org
}

export const filterReferencedPartners = (organizations: (OrganizationI | PartnerOrganizationI)[]) => {
    return organizations.filter(
        (org) => isPartnerOrganizationI(org) && org.collaborationStatus === CollaborationStatusEiffage.REFERENCED
    )
}
