import { Payment, PaymentObject, PaymentObjectUI, PaymentUI } from "~/domains/payment/payment/types"

export const paymentObjectFromApiAdapter = (paymentObject: PaymentObject): PaymentObjectUI => {
    return {
        id: paymentObject.id,
        paymentId: paymentObject.payment_id,
        objectId: paymentObject.object_id,
        objectType: paymentObject.object_type,
        linkCreatedById: paymentObject.link_created_by_id,
        linkCreationDatetime: paymentObject.link_creation_datetime,
    }
}

export const paymentFromApiAdapter = (payment: Payment): PaymentUI => {
    return {
        id: payment.id,
        amountSentData: {
            amount: payment.amount_sent_data?.amount ?? null,
            currency: payment.amount_sent_data?.currency,
        },
        amountReceivedData: {
            amount: payment.amount_received_data?.amount ?? null,
            currency: payment.amount_received_data?.currency,
        },
        payerId: payment.payer_id,
        payeeId: payment.payee_id,
        paymentMethodId: payment.payment_method_id ?? null,
        originCashId: payment.origin_cash_id ?? null,
        dateValidated: payment.date_validated ?? null,
        dateScheduled: payment.date_scheduled ?? null,
        dateCreated: payment.date_created ?? null,
        valueDateExpected: payment.value_date_expected ?? null,
        destinationCashId: payment.destination_cash_id ?? null,
        reference: payment.reference,
        comment: payment.comment,
        status: payment.status,
        executedById: payment.executed_by_id,
        authorizedById: payment.authorized_by_id,
        paymentMetadata: {
            invoiceNumber: payment.payment_metadata?.invoice_number ?? "",
            customerId: payment.payment_metadata?.customer_id,
        },
        paymentObjects: payment?.payment_objects?.map(paymentObjectFromApiAdapter) ?? [],
    }
}
