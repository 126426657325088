/* eslint-disable @typescript-eslint/no-shadow */
import { Grid, LinearProgress, Tooltip, styled } from "@mui/material"
import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react"
import { defineMessage, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { CountrySelector } from "~/components"
import { BoltIcon, InfoIcon } from "~/components/Icons"
import { useWhitePagesApi } from "~/domains/identity/organization/api/whitePagesApi"
import { CompanyAutocompleteField } from "~/domains/identity/organization/components/CompanyAutocomplete/CompanyAutocompleteField"
import {
    CompanyAutocompleteDisplayType,
    CompanyAutocompleteResult,
    CompanyAutocompleteType,
    CompanyResult,
    CompanyResultWithCreation,
} from "~/domains/identity/organization/types/CompanyAutocomplete"
import { getHeadOfficeEstablishment } from "~/domains/identity/organization/utils/siretToSiren"
import { PartnerOrganizationI } from "~/domains/identity/partners/types"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { CompanyI, CountryCode, EstablishmentI, OrganizationI, WhitePagesIdTypes, WhitePagesResultI } from "~/types"

const errorMessage = defineMessage({
    id: "company.autocomplete.errorFetchCompanyData",
    defaultMessage: "An error occured fetching company data, please fill the registration fields manually.",
})

export const suggestionsTooltipMessage = defineMessage({
    id: "company.autocomplete.textsSuggestionsTooltip",
    defaultMessage: "What our AI found from the invoice",
})

const SuggestedStringContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    gap: "8px",
})

const SuggestedString = styled("span")({
    cursor: "pointer",
    height: "22px",
    padding: "0px 4px",
    background: "var(--color-violet-lighter)",
    display: "inline-flex",
    gap: "4px",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "var(--color-grey)",
    alignItems: "center",
})

const SuggestedStringContent = styled("span")({
    flex: "1 1 auto",
    overflow: "hidden",
    textOverflow: "ellipsis",
})

const StyledBoltIcon = styled(BoltIcon)({
    flex: "0 0 18px",
})

type CompanyAutocompleteProps = {
    organizations: (OrganizationI | PartnerOrganizationI)[]
    suggestedCompanies: CompanyI[] | undefined
    countryCode: CountryCode
    setCountryCode: (countryCode: CountryCode) => void
    groupLabel?: Partial<Record<CompanyAutocompleteType, ReactNode>>
    suggestedStrings?: string[]
    displayType?: CompanyAutocompleteDisplayType
} & (
    | {
          company: CompanyResult | undefined
          enableCreation?: false
          setCompany: (company: CompanyResult | undefined, establishmentToSelect?: EstablishmentI | undefined) => void
      }
    | {
          company: CompanyResultWithCreation | undefined
          enableCreation: true
          setCompany: (
              company: CompanyResultWithCreation | undefined,
              establishmentToSelect?: EstablishmentI | undefined
          ) => void
      }
)

const DEFAULT_COUNTRY_CODE = CountryCode.FR
export const CompanyAutocomplete: React.FC<CompanyAutocompleteProps> = ({
    setCompany,
    company,
    organizations,
    suggestedCompanies,
    enableCreation,
    countryCode,
    setCountryCode,
    groupLabel,
    suggestedStrings,
    displayType = "other",
}) => {
    const { formatMessage } = useIntl()
    const [autocompleteCompany, setAutocompleteCompany] = useState<CompanyAutocompleteResult>()
    const [inputValue, setInputValue] = useState("")
    const [companyLoading, setCompanyLoading] = useState(false)

    const inputValueRef = useRef<string>()
    const whitePagesApi = useWhitePagesApi()
    const currentOrganization = useAppSelector(selectCurrentOrganization)

    // If the country code is unknown, set it to the current organization country code
    // It means that if you want to have the company selector flag to the current organization country code, you need to set the country code to unknown on the parent component
    useEffect(() => {
        if (countryCode === CountryCode.UNKNOWN && currentOrganization?.id) {
            setCountryCode(currentOrganization?.registration?.countryCode ?? DEFAULT_COUNTRY_CODE)
        }
    }, [countryCode, setCountryCode, currentOrganization?.id, currentOrganization?.registration?.countryCode])

    useEffect(() => {
        if (company?.value && countryCode === CountryCode.UNKNOWN) {
            setCountryCode(DEFAULT_COUNTRY_CODE)
        }
    }, [company?.value, countryCode, setCountryCode])

    useEffect(() => {
        if (suggestedCompanies?.length !== 1) return

        const suggestedCompany = suggestedCompanies[0]

        setAutocompleteCompany({
            type: "wp",
            value: {
                id: suggestedCompany.id,
                idType:
                    suggestedCompany.countryCode === CountryCode.FR
                        ? WhitePagesIdTypes.FRENCH_SIRET
                        : WhitePagesIdTypes.DUNS,
                name: suggestedCompany.name,
                city: suggestedCompany.primaryAddress?.city ?? "",
                primaryAddress: suggestedCompany.primaryAddress,
                isOutOfBusiness: false,
                countryCode: suggestedCompany.countryCode,
                postalCode: suggestedCompany.primaryAddress?.postalCode ?? "",
            },
        })
        setCompany({ type: "wp", value: suggestedCompany })
    }, [suggestedCompanies, setCompany])

    const setDefaultCompany = (value: WhitePagesResultI) => {
        setCompany({
            type: "wp",
            value: {
                id: value.id,
                idType: value.idType,
                name: value.name,
                taxId: "",
                countryCode: value.countryCode,
                registrationNumbers: [],
            },
        })
        setCountryCode(CountryCode.FR)
    }

    const setFrenchCompany = async (value: WhitePagesResultI) => {
        try {
            const companyResult = await whitePagesApi.fetchCompanyBySiren(value.id)
            let establishmentToSelect = companyResult.establishments?.find(
                (establishment) => establishment.id === inputValueRef.current || establishment.id === value.id
            )
            if (!establishmentToSelect && companyResult.establishments && companyResult.establishments.length > 0) {
                establishmentToSelect = getHeadOfficeEstablishment(companyResult.establishments)
            }
            if (establishmentToSelect && establishmentToSelect.name === "") {
                establishmentToSelect = { ...establishmentToSelect, name: companyResult.name }
            }
            setCompany({ type: "wp", value: companyResult }, establishmentToSelect)
            setCountryCode(companyResult.countryCode)
        } catch (e) {
            toast.error(formatMessage(errorMessage))
            setDefaultCompany(value)
        }
    }

    const setForeignCompany = async (value: WhitePagesResultI) => {
        try {
            const companyResult = await whitePagesApi.fetchCompanyByDunsNumber(value.id)
            const establishmentToSelect = companyResult.establishments?.find(
                (establishment) => establishment.id === inputValueRef.current
            )
            setCompany({ type: "wp", value: companyResult }, establishmentToSelect)
            setCountryCode(companyResult.countryCode)
        } catch (e) {
            toast.error(formatMessage(errorMessage))
            setDefaultCompany(value)
        }
    }

    useEffect(() => {
        if (autocompleteCompany?.value?.name === company?.value?.name) return

        if (autocompleteCompany?.type === "org") {
            setCompany({ type: "org", value: autocompleteCompany.value })
            setCountryCode(autocompleteCompany.value?.registration.countryCode ?? CountryCode.UNKNOWN)

            return
        }

        if (autocompleteCompany?.type === "wp") {
            setCompanyLoading(true)
            if (
                autocompleteCompany.value.idType === WhitePagesIdTypes.FRENCH_SIRET ||
                autocompleteCompany.value.idType === WhitePagesIdTypes.FRENCH_SIREN
            ) {
                setFrenchCompany(autocompleteCompany.value).finally(() => {
                    setCompanyLoading(false)
                })
            } else {
                setForeignCompany(autocompleteCompany.value).finally(() => {
                    setCompanyLoading(false)
                })
            }
        }
    }, [autocompleteCompany, formatMessage, setCompany, setCountryCode])

    const onAutocompleteChange = useCallback(
        (value: CompanyAutocompleteResult | undefined, newInputValue: string) => {
            if (value?.type === "wp") {
                inputValueRef.current = newInputValue
            }
            setAutocompleteCompany(value)
            setCompany(undefined)
        },
        [setAutocompleteCompany, setCompany]
    )

    const onCountryCodeChange = (code: CountryCode | null) => {
        if (!code || countryCode === code) return

        setCountryCode(code)
        setAutocompleteCompany(undefined)
        setInputValue("")
    }

    const onSuggestedStringClick = (suggestedString: string) => () => setInputValue(suggestedString)

    const renderSuggestedString = (suggestedString: string, i: number) => (
        <SuggestedString key={i} onClick={onSuggestedStringClick(suggestedString)}>
            <SuggestedStringContent>{suggestedString}</SuggestedStringContent>
            <StyledBoltIcon />
        </SuggestedString>
    )

    const countryCodeToDisplay =
        countryCode === CountryCode.UNKNOWN
            ? (currentOrganization?.registration?.countryCode ?? DEFAULT_COUNTRY_CODE)
            : countryCode

    return (
        <>
            <Grid container direction="column" gap={2}>
                <CountrySelector required value={countryCodeToDisplay} onChange={onCountryCodeChange} />
                <CompanyAutocompleteField
                    company={company}
                    countryCode={countryCodeToDisplay}
                    value={autocompleteCompany}
                    onChange={onAutocompleteChange}
                    organizations={organizations}
                    enableCreation={enableCreation}
                    groupLabel={groupLabel}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    displayType={displayType}
                    preventOnEnter
                />
                {companyLoading && <LinearProgress color="primary" />}
                {suggestedStrings && suggestedStrings.length > 0 && (
                    <SuggestedStringContainer>
                        {suggestedStrings.map(renderSuggestedString)}
                        <Tooltip title={formatMessage(suggestionsTooltipMessage)}>
                            <Grid item>
                                <InfoIcon color="var(--color-silver)" />
                            </Grid>
                        </Tooltip>
                    </SuggestedStringContainer>
                )}
            </Grid>
        </>
    )
}
