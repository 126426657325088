import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorizationAndOrganizationId as prepareHeaders } from "~/api/prepareHeaders"
import { ExtendedFetchBaseQueryError, taggedBaseQuery } from "~/api/rtkFetchBaseExtend"
import { commonMessages } from "~/common-messages"
import { adaptSpiceDBPermissionsResult } from "~/domains/identity/roles-permissions/api/adapters/spiceDBAdapters"
import {
    GetObjectPermissionsQuery,
    ShareObjectPermissionsMutation,
    UserObjectPermissionsCheckQuery,
} from "~/domains/identity/roles-permissions/types/RolesPermissions"
import { SpiceDBPermissionsLiteI } from "~/domains/identity/roles-permissions/types/SpiceDB"
import { SpiceDBPermissionsResult } from "~/types"

const BASE_URL = import.meta.env.VITE_API_ROLES_PERMISSIONS_URL
export const SPICEDB_TAGS = ["ObjectPermissions"]

export const spiceDbApi = createApi({
    reducerPath: "spiceDbApi",
    baseQuery: taggedBaseQuery({
        baseQueryFn: fetchBaseQuery({
            baseUrl: BASE_URL,
            prepareHeaders,
        }),
        domain: "identity",
        subdomain: "spice-db",
    }),
    tagTypes: SPICEDB_TAGS,
    endpoints: (builder) => ({
        getObjectPermissions: builder.query<SpiceDBPermissionsLiteI[], GetObjectPermissionsQuery>({
            query: ({ objectId, objectType, organizationId }) => ({
                url: `spiceDB/organizations/${organizationId}/${objectId}/${objectType}/permissions`,
            }),
            providesTags: SPICEDB_TAGS,
        }),
        shareObjectPermissions: builder.mutation<SpiceDBPermissionsLiteI, ShareObjectPermissionsMutation>({
            query: ({ objectId, objectType, body }) => ({
                url: `spiceDB/${objectId}/${objectType}/permissions`,
                method: "POST",
                body,
            }),
            transformErrorResponse: (response: ExtendedFetchBaseQueryError) => ({
                ...response,
                meta: {
                    ...response.meta,
                    errorMessage: commonMessages.error,
                },
            }),
        }),
        removeObjectPermissions: builder.mutation<SpiceDBPermissionsLiteI, ShareObjectPermissionsMutation>({
            query: ({ objectId, objectType, body }) => ({
                url: `spiceDB/${objectId}/${objectType}/permissions`,
                method: "DELETE",
                body,
            }),
            transformErrorResponse: (response: ExtendedFetchBaseQueryError) => ({
                ...response,
                meta: {
                    ...response.meta,
                    errorMessage: commonMessages.error,
                },
            }),
        }),
        userObjectPermissionsCheck: builder.query<SpiceDBPermissionsResult, UserObjectPermissionsCheckQuery>({
            query: ({ userId, objectType, organizationId, authorizations }) => ({
                url: `spiceDB/${objectType}/users/${userId}/permissions`,
                method: "POST",
                body: { organizationId, authorizations },
            }),
            transformResponse: adaptSpiceDBPermissionsResult,
        }),
    }),
})

export const {
    useGetObjectPermissionsQuery,
    useShareObjectPermissionsMutation,
    useRemoveObjectPermissionsMutation,
    useUserObjectPermissionsCheckQuery,
} = spiceDbApi
