import { Stack, Tooltip } from "@mui/material"
import React from "react"

import { Loader, TooltipWhite } from "~/components"
import { Tag, TagGroup } from "~/components/Tags/Tag"
import { TagTooltip } from "~/domains/analytics/tags/components/TagsSelector/TagTooltip"
import { TagAndTagGroupNameI, TagObjectI } from "~/domains/analytics/tags/types"

const getTagName = (tag: TagObjectI): string => tag?.tagName ?? " "

const getTagFirstLetter = (tag: TagObjectI): string => getTagName(tag).charAt(0) ?? " "

const getTagFromTagObject = (tagObject: TagObjectI, tags: TagAndTagGroupNameI[]) =>
    tags.find((tag) => tag.tagId === tagObject.tagId)

const getTagFullLabel = (tag: TagAndTagGroupNameI) => {
    const prefix = tag.tagGroupShortName ? `${tag.tagGroupShortName}: ` : `${tag.tagGroupName.substring(0, 3)}: `
    return `${prefix}${tag.name}`
}

interface TagsProps {
    organizationTags: TagAndTagGroupNameI[] | undefined
    entityTags?: TagObjectI[]
    entityTagIds?: string[]
    maxLength?: number
    loading?: boolean
}

export const Tags: React.FC<TagsProps> = ({ organizationTags, entityTags, entityTagIds, loading, maxLength = 4 }) => {
    if (!organizationTags || (!entityTags?.length && !entityTagIds?.length)) return null

    if (loading) return <Loader small />

    const tags = entityTagIds?.length
        ? organizationTags.reduce((acc, tag) => {
              if (entityTagIds.includes(tag.tagId)) {
                  acc.push({
                      tagId: tag.tagId,
                      tagName: tag.name,
                      tagGroupShortName: tag.tagGroupShortName,
                      tagGroupName: tag.tagGroupName,
                      organizationId: "NOT_NEEDED",
                      objectId: "NOT_NEEDED",
                  })
              }
              return acc
          }, [] as TagObjectI[])
        : entityTags || []

    return (
        <div>
            <TooltipWhite
                title={
                    <Stack direction="row" spacing={1}>
                        {tags.map((tag) => {
                            const tagWithTagGroup = getTagFromTagObject(tag, organizationTags)
                            return (
                                <Tooltip
                                    key={tag.tagId}
                                    title={<TagTooltip tag={getTagFromTagObject(tag, organizationTags)} />}
                                    arrow
                                    placement="top-start"
                                >
                                    <Tag fullName>
                                        {tagWithTagGroup ? getTagFullLabel(tagWithTagGroup) : tag.tagName}
                                    </Tag>
                                </Tooltip>
                            )
                        })}
                    </Stack>
                }
                arrow
                placement="top-start"
            >
                <div>
                    {tags.length === 1 ? (
                        <Tag>{getTagFirstLetter(tags[0])}</Tag>
                    ) : (
                        <TagGroup
                            max={maxLength}
                            total={tags.length}
                            renderSurplus={(surplus) => <Tag>+{surplus.toString()}</Tag>}
                        >
                            {tags.map((tag) => (
                                <Tag key={tag.tagId}>{getTagFirstLetter(tag)}</Tag>
                            ))}
                        </TagGroup>
                    )}
                </div>
            </TooltipWhite>
        </div>
    )
}
