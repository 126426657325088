import { useState } from "react"
import { FileText } from "react-feather"
import { defineMessages } from "react-intl"

import { Button, SafeFormattedMessage } from "~/components"

import { ModalUploadInvoice } from "../ModalUploadInvoice/ModalUploadInvoice"

const messages = defineMessages({
    button: {
        id: "transactions.purchaseOrder.documentRelations.uploadBox.uploadInvoice",
        defaultMessage: "Upload an invoice",
    },
})

interface UploadInvoiceBoxProps {
    purchaseOrderId: string
}

export const UploadInvoiceBox = ({ purchaseOrderId }: UploadInvoiceBoxProps) => {
    const [modalUploadInvoiceVisible, setModalUploadInvoiceVisible] = useState(false)

    const openModalUploadInvoice = () => setModalUploadInvoiceVisible(true)
    const closeModalUploadInvoice = () => setModalUploadInvoiceVisible(false)

    return (
        <>
            <Button type="tertiary" onClick={openModalUploadInvoice}>
                <FileText size={16} />
                <SafeFormattedMessage {...messages.button} />
            </Button>

            <ModalUploadInvoice
                open={modalUploadInvoiceVisible}
                close={closeModalUploadInvoice}
                purchaseOrderId={purchaseOrderId}
            />
        </>
    )
}
