import * as Sentry from "@sentry/react"
import { useCallback, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { selectPendingLineTags, tagsActions } from "~/domains/analytics/tags/store/tagsSlice"
import { useTagsApi } from "~/domains/analytics/tags/tagsApi"
import { useSavePOPendingCustomFieldObjects } from "~/domains/identity/custom-fields/hooks/useSavePOPendingCustomFieldObjects"
import { selectPendingCustomFieldObjects, selectPendingCustomFieldValues } from "~/domains/identity/custom-fields/store"
import {
    addLineIdToTags,
    hasPendingCustomFieldObjects,
    hasPendingCustomFieldValues,
    hasPendingLineTags,
} from "~/domains/transactions/_shared/utils/purchasesUtils"
import { selectUser } from "~/store/account/accountSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { isDefined } from "~/utils/isDefined"

import { purchaseOrdersApi } from "../../api"
import { CreatePurchaseOrderDTO } from "../../types/PurchaseOrders"
import { useSavePurchaseOrderPendingCustomFieldValues } from "./useSavePurchaseOrderPendingCustomFieldValues"
import { useSavePurchaseOrderPendingLineTags } from "./useSavePurchaseOrderPendingLineTags"
import { useSavePurchaseOrderPendingTags } from "./useSavePurchaseOrderPendingTags"

const messages = defineMessages({
    success: {
        id: "purchase.orders.create.success",
        defaultMessage: "Purchase order created",
    },
    error: {
        id: "purchase.orders.create.error",
        defaultMessage: "Something went wrong with creating this Purchase Order. Please contact your administrator",
    },
})

export const useCreatePurchaseOrder = (organizationId: string) => {
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string>()
    const user = useAppSelector(selectUser)
    const tagsApi = useTagsApi()
    const pendingLineTags = useAppSelector(selectPendingLineTags)
    const pendingCustomFieldValues = useAppSelector(selectPendingCustomFieldValues)
    const pendingCustomFieldObjects = useAppSelector(selectPendingCustomFieldObjects)
    const savePurchaseOrderPendingTags = useSavePurchaseOrderPendingTags(organizationId)
    const savePurchaseOrderPendingLineTags = useSavePurchaseOrderPendingLineTags(organizationId)
    const savePurchaseOrderPendingCustomFieldValues = useSavePurchaseOrderPendingCustomFieldValues(organizationId)
    const savePurchaseOrderPendingCustomFieldObjects = useSavePOPendingCustomFieldObjects(organizationId)

    const createPO = useCallback(
        async (newPO: CreatePurchaseOrderDTO, showToast: boolean = true) => {
            try {
                setLoading(true)
                const result = await purchaseOrdersApi.create(organizationId, newPO)
                savePurchaseOrderPendingTags(result.id)

                const hasPendingTags = hasPendingLineTags(pendingLineTags, newPO)
                const hasPendingCustomFields = hasPendingCustomFieldValues(pendingCustomFieldValues, newPO)
                const hasPendingCFObjects = hasPendingCustomFieldObjects(pendingCustomFieldObjects)

                if (hasPendingTags || hasPendingCustomFields || hasPendingCFObjects) {
                    try {
                        const purchaseOrder = await purchaseOrdersApi.findById(organizationId, result.id)
                        await Promise.allSettled([
                            hasPendingTags && savePurchaseOrderPendingLineTags(newPO, purchaseOrder),
                            hasPendingCustomFields && savePurchaseOrderPendingCustomFieldValues(newPO, purchaseOrder),
                            hasPendingCFObjects && savePurchaseOrderPendingCustomFieldObjects(purchaseOrder),
                        ])
                        if (hasPendingTags) {
                            const tags = purchaseOrder.lines
                                .flatMap((line, lineIndex) => {
                                    const temporaryId = newPO.lines[lineIndex]?.temporaryId
                                    const t = temporaryId ? pendingLineTags[temporaryId] : null
                                    return t && line.id ? addLineIdToTags(t, line.id) : []
                                })
                                .filter(isDefined)
                            dispatch(tagsActions.setLinesTags(tags))
                            dispatch(tagsActions.resetPendingTags())
                        }
                    } catch (err) {
                        Sentry.captureException(err, {
                            tags: { organizationId },
                            extra: { result, hasPendingTags, hasPendingCustomFields, hasPendingCFObjects },
                        })
                    }
                }
                setLoading(false)
                showToast && toast.success(formatMessage(messages.success))
                return result
            } catch (err) {
                setError(`${err}`)
                setLoading(false)
                console.error(`Error saving new Purchase Order:`, err)
                toast.error(formatMessage(messages.error))
                Sentry.captureException(err, {
                    tags: { organizationId },
                    extra: { newPO },
                })
                throw err
            }
        },
        [
            savePurchaseOrderPendingTags,
            savePurchaseOrderPendingLineTags,
            savePurchaseOrderPendingCustomFieldValues,
            savePurchaseOrderPendingCustomFieldObjects,
            organizationId,
            user,
            pendingLineTags,
            pendingCustomFieldValues,
            pendingCustomFieldObjects,
            dispatch,
            tagsApi,
        ]
    )

    return { createPO, loading, error }
}
