import { InvoiceSummaryAPI } from "~/domains/transactions/invoices-v1/types/Invoice"

export const invoiceAnalyticsFromApiAdapter = (invoiceSummary: InvoiceSummaryAPI) => {
    return {
        all: invoiceSummary.all,
        dueLaterThan7Days: invoiceSummary.dueLaterThan7Days,
        dueNext7Days: invoiceSummary.dueNext7Days,
        lateInvoices: invoiceSummary.lateInvoices,
        remaining: invoiceSummary.remaining,
    }
}
