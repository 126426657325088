import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { TagObjectRecordI } from "~/domains/analytics/tags/types/TagObjectRecord"
import { purchaseRequestsState } from "~/domains/transactions/purchase-requests/store/purchaseRequestsState"
import {
    PurchaseRequestDetails,
    PurchaseRequestLine,
    PurchaseRequestStatus,
    PurchaseRequestSummary,
    PurchaseRequestSummaryAPI,
    PurchaseRequestsTab,
} from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { RootState } from "~/store"
import { SpiceDBPermissionsResult } from "~/types"

const initialState = purchaseRequestsState

const purchaseRequestsSlice = createSlice({
    name: "purchase-requests",
    initialState: initialState,
    reducers: {
        createDraftPurchaseRequest(state, action: PayloadAction<PurchaseRequestDetails>) {
            state.data = action.payload
        },
        fetchPRs(state) {
            state.loading = true
            state.error = null
        },
        fetchPRsSuccess(state, action: PayloadAction<PurchaseRequestSummaryAPI[]>) {
            state.loading = false
            state.list = action.payload
            state.error = null
        },
        fetchPRsFailed(state, action: PayloadAction<string>) {
            state.loading = false
            state.error = action.payload
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        fetchPR(state, action: PayloadAction<string>) {
            state.loading = true
            state.error = null
        },
        fetchPRSuccess(state, action: PayloadAction<PurchaseRequestDetails>) {
            state.loading = false
            state.data = action.payload
            state.error = null
        },
        fetchPRFailed(state, action: PayloadAction<string>) {
            state.loading = false
            state.error = action.payload
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        updatePR(state, action: PayloadAction<string>) {
            state.loading = true
        },
        updatePRSuccess(state) {
            state.loading = false
        },
        updatePRFailed(state, action: PayloadAction<string>) {
            state.loading = false
            state.error = action.payload
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        updateData(state, action: PayloadAction<{ field: string; value: any }>) {
            state.data[action.payload.field] = action.payload.value
        },
        setLines(state, action: PayloadAction<PurchaseRequestLine[]>) {
            state.data.lines = action.payload
        },
        createPRError(state, action: PayloadAction<string>) {
            state.error = action.payload
        },
        setFilter(state, action: PayloadAction<string>) {
            state.filter = action.payload
        },
        setShouldUpdatePR(state, action: PayloadAction<boolean>) {
            state.shouldUpdatePR = action.payload
        },
        resetData(state) {
            state.data = initialState.data
        },
        reset() {
            return initialState
        },
        setTagsLoading(state, action: PayloadAction<boolean>) {
            state.tagsLoading = action.payload
        },
        setTags(state, action: PayloadAction<TagObjectRecordI | undefined>) {
            state.tags = action.payload
            state.tagsLoading = false
        },
        deletePurchaseRequests(state, action: PayloadAction<string[]>) {
            state.list = state.list.filter((pr) => !action.payload.includes(pr.id))
        },
        approvePurchaseRequests(state, action: PayloadAction<string[]>) {
            state.list = state.list.map((pr) => {
                if (action.payload.includes(pr.id)) {
                    pr.status = PurchaseRequestStatus.APPROVED
                }
                return pr
            })
        },
        rejectPurchaseRequests(state, action: PayloadAction<string[]>) {
            state.list = state.list.map((pr) => {
                if (action.payload.includes(pr.id)) {
                    pr.status = PurchaseRequestStatus.REJECTED
                }
                return pr
            })
        },
        convertPurchaseRequests(state, action: PayloadAction<PurchaseRequestSummary[]>) {
            state.list = state.list.map((pr) => {
                const purchaseRequest = action.payload.find((p) => p.id === pr.id)
                if (purchaseRequest) {
                    pr.purchaseOrder = purchaseRequest.purchaseOrder
                }
                return pr
            })
        },
        setCurrentPurchaseRequestsTab(state, action: PayloadAction<PurchaseRequestsTab>) {
            state.currentPurchaseRequestsTab = action.payload
        },
        setPurchaseRequestsPermissions(state, action: PayloadAction<SpiceDBPermissionsResult | null>) {
            state.permissions = { ...(!state.permissions ? {} : state.permissions), ...action.payload }
        },
        // TODO: Remove when Flows are ready to deal with partners collaboration status
        setPrCompanyAutocompleteOnlyWithPartners(state, action: PayloadAction<boolean>) {
            state.prCompanyAutocompleteOnlyWithPartners = action.payload
        },
    },
})

export const purchaseRequestsActions = purchaseRequestsSlice.actions

// // Selectors
export const selectPurchaseRequestsLoading = (state: RootState) => state.purchaseRequests.loading
export const selectPurchaseRequestsTags = (state: RootState) => state.purchaseRequests.tags
export const selectPurchaseRequestsTagsLoading = (state: RootState) => state.purchaseRequests.tagsLoading
export const selectPurchaseRequestsError = (state: RootState) => state.purchaseRequests.error
export const selectPurchaseRequests = (state: RootState) => state.purchaseRequests.list
export const selectPurchaseRequest = (state: RootState) => state.purchaseRequests.data
export const selectPurchaseRequestFilter = (state: RootState) => state.purchaseRequests.filter
export const selectShouldUpdatePR = (state: RootState) => state.purchaseRequests.shouldUpdatePR
export const selectPurchaseRequestsPermissions = (state: RootState) => state.purchaseRequests.permissions
export const selectPrCompanyAutocompleteOnlyWithPartners = (state: RootState) =>
    state.purchaseRequests.prCompanyAutocompleteOnlyWithPartners

// // Reducer
const purchaseRequestsReducer = purchaseRequestsSlice.reducer
export default purchaseRequestsReducer
