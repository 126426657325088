import { useGetUserRolesQuery } from "~/domains/identity/roles-permissions/api/rolesApi"
import { Role } from "~/domains/identity/roles-permissions/types/RolesPermissions"
import { ADMIN_ROLE_TEMPLATE_ID } from "~/domains/identity/roles-permissions/utils/permissions"
import { selectUserId } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"

export const useIsAdminRole = () => {
    const userId = useAppSelector(selectUserId)

    const organizationId = useAppSelector(selectCurrentOrganizationId)

    const invalidData = !organizationId || !userId

    const { data: userRoles } = useGetUserRolesQuery(
        { organizationId: organizationId ?? "", userId },
        { skip: invalidData }
    )

    if (invalidData) return false

    const isAdmin = userRoles?.some(({ roleTemplateId }: Role) => roleTemplateId === ADMIN_ROLE_TEMPLATE_ID)

    return isAdmin
}
