import { Stack, TextField } from "@mui/material"
import cls from "classnames"
import { ChangeEvent, FC, Fragment } from "react"
import { Plus, Trash2 } from "react-feather"

import { Button, SafeFormattedMessage } from "~/components"
import { IconButton } from "~/domains/orchestration/flows/components/IconButton"
import { useConditionsManager } from "~/domains/orchestration/flows/hooks/useConditionsManager"
import { messages } from "~/domains/orchestration/flows/locale"
import { Branch as BranchType } from "~/domains/orchestration/flows/types"

import { Condition } from "./Condition"
import { ConditionBuilder } from "./ConditionBuilder"

interface BranchProps {
    branch: BranchType
    handleChange: (branch: BranchType) => void
    handleDeleteBranch: (branch: BranchType) => void
}

export const Branch: FC<BranchProps> = ({ branch, handleChange, handleDeleteBranch }) => {
    const {
        conditions,
        showConditionBuilder,
        currentCondition,
        handleChangeCondition,
        handleDelete,
        handleNewConditionItem,
        handleSaveConditionItem,
        handleAddConditionGroup,
        handleEditCondition,
        handleCloseConditionBuilder,
    } = useConditionsManager({
        initialConditions: branch.conditions,
        onConditionsChange: (newConditions) => {
            handleChange({
                ...branch,
                conditions: newConditions,
            })
        },
    })

    const handleChangeBranchName = (e: ChangeEvent<HTMLInputElement>) => {
        handleChange({
            ...branch,
            name: e.target.value,
        })
    }

    const handleDeleteCurrentBranch = () => {
        handleDeleteBranch(branch)
    }

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    if (showConditionBuilder && currentCondition) {
        return (
            <ConditionBuilder
                condition={currentCondition}
                handleSave={handleSaveConditionItem}
                handleCancel={handleCloseConditionBuilder}
            />
        )
    }

    return (
        <>
            <Stack gap={1} className={configurationNodeItemClassName}>
                <Stack direction="row" justifyContent="space-between">
                    <TextField
                        value={branch.name}
                        onChange={handleChangeBranchName}
                        variant="standard"
                        InputProps={{
                            disableUnderline: true,
                        }}
                    />

                    <IconButton onClick={handleDeleteCurrentBranch} type="grey-light" size="small">
                        <Trash2 size={12} color="var(--color-grey)" />
                    </IconButton>
                </Stack>

                {conditions.map((g, index) => (
                    <Fragment key={g.id}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            className="flows-branchConfiguration-orTitle"
                        >
                            <SafeFormattedMessage
                                tagName="span"
                                {...(index === 0
                                    ? messages.eventTriggerConfiguration.orConditionFirstLabel
                                    : messages.eventTriggerConfiguration.orConditionOtherLabel)}
                            />

                            <IconButton onClick={handleDelete(g)} type="grey-light" size="small">
                                <Trash2 size={12} color="var(--color-grey)" />
                            </IconButton>
                        </Stack>
                        <Condition
                            conditions={g}
                            handleChangeConditions={handleChangeCondition}
                            handleNewCondition={handleNewConditionItem}
                            handleEditCondition={handleEditCondition}
                        />
                    </Fragment>
                ))}
                <Button
                    type="primary-light"
                    onClick={handleAddConditionGroup}
                    className="flows-branchConfiguration-newConditionGroup"
                >
                    <Plus size={18} />
                    <SafeFormattedMessage {...messages.branchConfiguration.newConditionGroupLabel} />
                </Button>
            </Stack>
        </>
    )
}
