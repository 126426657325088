import { Check } from "react-feather"

import { ApproveErpInvoiceConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { RegularNode } from "~/domains/orchestration/flows/components/nodes"
import { Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import { ApproveErpInvoiceNode, EditorNode, Node, NodeType } from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const approveErpInvoiceNode: Omit<ApproveErpInvoiceNode, keyof Node> = {
    invoiceId: "",
    type: NodeType.APPROVE_ERP_INVOICE_NODE,
    nextNode: null,
}

const advancedFields: (keyof ApproveErpInvoiceNode)[] = ["invoiceId"]

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.APPROVE_ERP_INVOICE_NODE) return false
    return Boolean(node.invoiceId)
}

const approveErpInvoiceConfig: Configuration<NodeType.APPROVE_ERP_INVOICE_NODE> = {
    type: NodeType.APPROVE_ERP_INVOICE_NODE,
    baseSlug: "approve-erp-invoice",
    Icon: Check,
    Configuration: ApproveErpInvoiceConfiguration,
    advancedFields,
    Node: RegularNode,
    factory: (props: FactoryProps): ApproveErpInvoiceNode => ({
        ...approveErpInvoiceNode,
        ...createCommonNodeProperties(props),
    }),
    validateNode,
}

export { approveErpInvoiceConfig, advancedFields as approveErpInvoiceAdvancedFields }
