import { FormControlLabel, MenuItem, Stack, Switch, TextField } from "@mui/material"
import cls from "classnames"
import React, { ChangeEvent, FC, useState } from "react"

import { CurrencySelector, SafeFormattedMessage } from "~/components"
import { AdvancedFields } from "~/domains/orchestration/flows/components/configuration/AdvancedFields"
import { ConfigurationNode } from "~/domains/orchestration/flows/components/configuration/ConfigurationNode"
import { useFlowTriggerUpdate } from "~/domains/orchestration/flows/hooks/useFlowTriggerUpdate"
import { messages } from "~/domains/orchestration/flows/locale"
import {
    Conditions,
    ConfigurationProps,
    CurrencyConversion,
    CurrencyConversionMode,
    Entity,
    EntityTriggerNode,
    EntityType,
    PartnershipEntity,
    PurchaseOrderEntity,
    PurchaseRequestEntity,
    RestartMode,
    StartMode,
    TriggerMode,
    hasCurrencyConversion,
    isPartnershipEntity,
} from "~/domains/orchestration/flows/types"
import { useSafeIntl } from "~/hooks/useSafeIntl"
import { CurrencyI } from "~/types"

import { SharedTriggerCondition } from "./SharedTriggerCondition"

const initialPartnershipEntity: PartnershipEntity = {
    type: EntityType.PARTNERSHIP,
    startMode: { type: StartMode.ON_ENTITY_CREATED },
    restartMode: { type: RestartMode.MANUAL },
}

const initialPurchaseOrder: PurchaseOrderEntity = {
    type: EntityType.PURCHASE_ORDER,
    startMode: { type: StartMode.ON_ENTITY_CREATED },
    restartMode: { type: RestartMode.MANUAL },
    currencyConversion: { type: CurrencyConversionMode.DISABLED },
}

const initialPurchaseRequest: PurchaseRequestEntity = {
    type: EntityType.PURCHASE_REQUEST,
    startMode: { type: StartMode.ON_ENTITY_CREATED },
    restartMode: { type: RestartMode.MANUAL },
    currencyConversion: { type: CurrencyConversionMode.DISABLED },
}

const getInitialEntity = (entity: EntityType): Entity => {
    if (entity === EntityType.PURCHASE_ORDER) return initialPurchaseOrder
    if (entity === EntityType.PURCHASE_REQUEST) return initialPurchaseRequest
    return initialPartnershipEntity
}

export const EntityTriggerConfiguration: FC<ConfigurationProps<EntityTriggerNode>> = ({
    selectedNode,
    advancedFields,
    validateNode,
}) => {
    const [currentNode, setCurrentNode] = useState(selectedNode)
    const [enableConditions, setEnableConditions] = useState(selectedNode.filter.length > 0)
    const { formatMessage } = useSafeIntl()
    const { updateFlowForTrigger } = useFlowTriggerUpdate()

    const initialConditions = selectedNode.filter
    const onConditionsChange = (newConditions: Conditions[]) => {
        setCurrentNode({
            ...currentNode,
            filter: newConditions,
            metadata: {
                ...currentNode.metadata,
            },
        })
    }

    const handleEnableConditions = () => {
        setEnableConditions(!enableConditions)
    }

    const handleEntityTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
        const type = e.target.value as EntityType

        const initialEntity = getInitialEntity(type)

        setCurrentNode({
            ...currentNode,
            entity: {
                ...initialEntity,
            },
        })

        // Update the entire flow
        updateFlowForTrigger({
            type,
            mode: TriggerMode.ENTITY,
            slug: currentNode.slug,
            currencyConversion: null,
        })
    }

    const handleStartModeChange = (e: ChangeEvent<HTMLInputElement>) => {
        const type = e.target.value as StartMode
        if (!currentNode.entity) return
        setCurrentNode({
            ...currentNode,
            entity: {
                ...currentNode.entity,
                startMode: { type },
            },
        })
    }

    const handleRestartModeChange = (e: ChangeEvent<HTMLInputElement>) => {
        const type = e.target.value as RestartMode
        if (!currentNode.entity) return
        setCurrentNode({
            ...currentNode,
            entity: {
                ...currentNode.entity,
                restartMode: {
                    ...(type === RestartMode.ON_ENTITY_UPDATED_IF_SUSPENDED && {
                        continueFromSuspendedNode: false,
                    }),
                    type,
                },
            },
        })
    }

    const handleContinueFromSuspendedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (
            !currentNode.entity ||
            !currentNode.entity.restartMode ||
            currentNode.entity.restartMode.type !== RestartMode.ON_ENTITY_UPDATED_IF_SUSPENDED
        )
            return
        setCurrentNode({
            ...currentNode,
            entity: {
                ...currentNode.entity,
                restartMode: {
                    ...currentNode.entity.restartMode,
                    continueFromSuspendedNode: e.target.checked,
                },
            },
        })
    }

    const handleCurrencyConversionModeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isPartnershipEntity(currentNode.entity) || !currentNode.entity) return
        const entity = currentNode.entity

        const type = e.target.checked ? CurrencyConversionMode.ENABLED : CurrencyConversionMode.DISABLED
        const currencyConversion: CurrencyConversion = {
            ...entity.currencyConversion,
            type,
            targetCurrency:
                type === CurrencyConversionMode.DISABLED ? undefined : entity.currencyConversion?.targetCurrency,
        }
        setCurrentNode({
            ...currentNode,
            entity: {
                ...currentNode.entity,
                currencyConversion,
            },
        })
        updateFlowForTrigger({
            type: currentNode.entity?.type,
            mode: TriggerMode.ENTITY,
            slug: currentNode.slug,
            currencyConversion,
        })
    }

    const handleTargetCurrencyChange = (currency: CurrencyI | null) => {
        if (isPartnershipEntity(currentNode.entity)) return
        if (!currentNode.entity) return
        if (currentNode.entity.currencyConversion?.type === CurrencyConversionMode.ENABLED) {
            const targetCurrency = currency?.code || undefined
            setCurrentNode({
                ...currentNode,
                entity: {
                    ...currentNode.entity,
                    currencyConversion: {
                        ...currentNode.entity.currencyConversion,
                        targetCurrency,
                    },
                },
            })
            updateFlowForTrigger({
                type: currentNode.entity?.type,
                mode: TriggerMode.ENTITY,
                slug: currentNode.slug,
                currencyConversion: {
                    ...currentNode.entity.currencyConversion,
                    targetCurrency,
                },
            })
        }
    }

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    const entityTypeArray = Object.values(EntityType).filter((type) => type !== EntityType.UNKNOWN)

    const isCurrencyConversionEnabled =
        hasCurrencyConversion(currentNode.entity) &&
        currentNode.entity?.currencyConversion?.type === CurrencyConversionMode.ENABLED

    return (
        <ConfigurationNode selectedNode={currentNode} validateNode={validateNode}>
            <Stack gap={4} className={configurationNodeItemClassName}>
                <TextField
                    select
                    label={<SafeFormattedMessage {...messages.entityTriggerConfiguration.entityTypeLabel} />}
                    value={currentNode.entity?.type || ""}
                    onChange={handleEntityTypeChange}
                    fullWidth
                >
                    {entityTypeArray.map((type) => (
                        <MenuItem key={type} value={type}>
                            <SafeFormattedMessage {...messages.entityType[type]} />
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    select
                    label={<SafeFormattedMessage {...messages.entityTriggerConfiguration.startModeLabel} />}
                    value={currentNode.entity?.startMode?.type || ""}
                    onChange={handleStartModeChange}
                    fullWidth
                >
                    {Object.values(StartMode).map((mode) => (
                        <MenuItem key={mode} value={mode}>
                            <SafeFormattedMessage {...messages.startMode[mode]} />
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    select
                    label={<SafeFormattedMessage {...messages.entityTriggerConfiguration.restartModeLabel} />}
                    value={currentNode.entity?.restartMode?.type || ""}
                    onChange={handleRestartModeChange}
                    fullWidth
                >
                    {Object.values(RestartMode).map((mode) => (
                        <MenuItem key={mode} value={mode}>
                            <SafeFormattedMessage {...messages.restartMode[mode]} />
                        </MenuItem>
                    ))}
                </TextField>

                {currentNode.entity?.restartMode?.type === RestartMode.ON_ENTITY_UPDATED_IF_SUSPENDED && (
                    <FormControlLabel
                        label={
                            <SafeFormattedMessage {...messages.entityTriggerConfiguration.continueFromSuspendedLabel} />
                        }
                        control={
                            <Switch
                                checked={currentNode.entity.restartMode.continueFromSuspendedNode}
                                onChange={handleContinueFromSuspendedChange}
                            />
                        }
                    />
                )}

                {hasCurrencyConversion(currentNode.entity) && (
                    <>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isCurrencyConversionEnabled}
                                    onChange={handleCurrencyConversionModeChange}
                                />
                            }
                            label={
                                <SafeFormattedMessage
                                    {...messages.entityTriggerConfiguration.currencyConversionModeLabel}
                                />
                            }
                        />

                        {isCurrencyConversionEnabled && (
                            <CurrencySelector
                                label={formatMessage(messages.entityTriggerConfiguration.targetCurrencyLabel)}
                                value={currentNode.entity?.currencyConversion?.targetCurrency || null}
                                onChange={handleTargetCurrencyChange}
                                editMode
                            />
                        )}
                    </>
                )}

                <SharedTriggerCondition
                    enableConditions={enableConditions}
                    onEnableConditionsChange={handleEnableConditions}
                    initialConditions={initialConditions}
                    onChangeCondition={onConditionsChange}
                />

                <AdvancedFields<EntityTriggerNode>
                    fields={advancedFields}
                    currentNode={currentNode}
                    setCurrentNode={setCurrentNode}
                />
            </Stack>
        </ConfigurationNode>
    )
}
