import { IconButton, Typography } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid-premium"
import dayjs from "dayjs"
import { AlertCircle, ShoppingBag } from "react-feather"
import { defineMessages } from "react-intl"
import { Link } from "react-router-dom"
import { generatePath } from "react-router-dom"

import { SafeFormattedMessage, StatusChip, Tags, TooltipConditional, TooltipWarning } from "~/components"
import { DateComponent } from "~/components/Date/DateComponent"
import { OrganizationName } from "~/components/OrganizationName"
import { TagAndTagGroupNameI, TagId, TagObjectI } from "~/domains/analytics/tags/types"
import { distanceToIssueDate } from "~/domains/identity/partners/core/getInvoiceTimes"
import { AmountInList } from "~/domains/transactions/_shared/components/AmountInList"
import { columnsLabels } from "~/domains/transactions/invoices/components/list/InvoicesDataGrid/columnsLabels"
import { InvoiceI } from "~/domains/transactions/invoices/types/InvoiceTypes"
import { PURCHASE_ORDER_ROUTE } from "~/domains/transactions/purchase-orders/routes"
import { useSafeIntl } from "~/hooks/useSafeIntl"
import { OrganizationId } from "~/types"
import { stopPropagation } from "~/utils"

const messages = defineMessages({
    vendor: { id: "invoices.vendor", defaultMessage: "Vendor" },
    client: { id: "invoices.client", defaultMessage: "Client" },
    tooltipDueDateInLessThan7Days: {
        id: "account.documents.dueDateInLessThan7Days",
        defaultMessage: "Due date in less than 7 days",
    },
    tooltipHasDuplicates: {
        id: "invoices.hasDuplicates",
        defaultMessage: "This invoice has duplicates",
    },
})

export const useGetInvoicesListTableColumns = (
    organizationId: OrganizationId,
    tagsByTagId: Record<TagId, TagAndTagGroupNameI>,
    actionColumn?: GridColDef<InvoiceI>,
    currentStatus?: string
): GridColDef<InvoiceI>[] => {
    const { formatMessageCapitalized } = useSafeIntl()
    return [
        ...(!currentStatus
            ? [
                  {
                      field: "lifecycleStatus",
                      type: "string",
                      filterable: false,
                      cellClassName: "p-0 ps-1",
                      minWidth: 80,
                      headerName: formatMessageCapitalized(columnsLabels.labelStatus),
                      renderHeader: () => <SafeFormattedMessage {...columnsLabels.labelStatus} />,
                      valueGetter: ({ row }: { row: InvoiceI }) => row.status,
                      renderCell: ({ row }: { row: InvoiceI }) => (
                          <StatusChip status={row.lifecycleStatus?.toUpperCase() || "DRAFT"}>
                              {row.lifecycleStatus}
                          </StatusChip>
                      ),
                  },
              ]
            : []),
        {
            field: "dueDate",
            type: "date",
            width: 100,
            headerName: formatMessageCapitalized(columnsLabels.labelDueDate),
            renderHeader: () => <SafeFormattedMessage {...columnsLabels.labelDueDate} />,
            valueGetter: (params) => params.row.dueDate && new Date(params.row.dueDate),
            renderCell: ({ row: invoice }) => {
                const isDueDateInLessThan7Days = dayjs(invoice.dueDate).diff(dayjs(), "days") < 7
                const paidDate = invoice.paidAt
                const shouldDisplayWarning = isDueDateInLessThan7Days && !paidDate

                return (
                    <TooltipConditional
                        title={<SafeFormattedMessage {...messages.tooltipDueDateInLessThan7Days} />}
                        condition={shouldDisplayWarning}
                        type="danger"
                        arrow
                    >
                        <Typography variant="body2" color={shouldDisplayWarning ? "error" : "inherit"}>
                            <DateComponent value={invoice.dueDate} />
                        </Typography>
                    </TooltipConditional>
                )
            },
        },
        {
            field: "updatedAt",
            type: "date",
            width: 100,
            headerName: formatMessageCapitalized(columnsLabels.labelUpdateDate),
            renderHeader: () => <SafeFormattedMessage {...columnsLabels.labelUpdateDate} />,
            valueGetter: (params) => params.row.updatedAt && new Date(params.row.updatedAt),
            renderCell: ({ row: invoice }) => <DateComponent value={invoice.updatedAt} />,
        },
        {
            field: "vendor",
            type: "string",
            flex: 1,
            sortable: false,
            filterable: false,
            headerName: formatMessageCapitalized(messages.vendor),
            renderHeader: () => <SafeFormattedMessage {...messages.vendor} />,
            renderCell: ({ row }) => <OrganizationName organizationId={row.sellerId} />,
        },
        {
            field: "client",
            type: "string",
            flex: 1,
            sortable: false,
            filterable: false,
            headerName: formatMessageCapitalized(messages.client),
            renderHeader: () => <SafeFormattedMessage {...messages.client} />,
            renderCell: ({ row }) => <OrganizationName organizationId={row.buyerId} />,
        },
        {
            field: "number",
            type: "string",
            flex: 1,
            headerName: formatMessageCapitalized(columnsLabels.labelReference),
            renderHeader: () => <SafeFormattedMessage {...columnsLabels.labelReference} />,
        },
        {
            field: "notes",
            type: "string",
            flex: 2,
            headerName: formatMessageCapitalized(columnsLabels.labelDescription),
            renderHeader: () => <SafeFormattedMessage {...columnsLabels.labelDescription} />,
            valueGetter: (params) => params.row.description,
        },
        {
            field: "elapsedTime",
            filterable: false,
            sortable: false,
            headerName: formatMessageCapitalized(columnsLabels.labelElapsedTime),
            renderHeader: () => <SafeFormattedMessage {...columnsLabels.labelElapsedTime} />,
            valueGetter: (params) => distanceToIssueDate(params.row),
        },
        {
            field: "tagIds",
            type: "array",
            filterable: false,
            sortable: false,
            minWidth: 0,
            headerName: formatMessageCapitalized(columnsLabels.labelTags),
            renderHeader: () => <SafeFormattedMessage {...columnsLabels.labelTags} />,
            renderCell: (params) => {
                const organizationTags = params.row.tagIds.reduce(
                    (acc: TagAndTagGroupNameI[], tagId): TagAndTagGroupNameI[] => {
                        const tag = tagsByTagId[tagId]
                        if (tag) {
                            acc.push(tag)
                        }
                        return acc
                    },
                    []
                )
                const entityTags = organizationTags.map((tag): TagObjectI => {
                    return {
                        ...tag,
                        tagId: tag.id ?? tag.tagId,
                        objectId: params.row.invoiceId,
                        tagName: tag.name,
                        organizationId,
                    }
                })
                return <Tags organizationTags={organizationTags} entityTags={entityTags} />
            },
        },
        {
            field: "purchaseOrderId",
            type: "string",
            sortable: false,
            width: 40,
            headerName: formatMessageCapitalized(columnsLabels.labelPONumber),
            renderHeader: () => <SafeFormattedMessage {...columnsLabels.labelPONumber} />,
            renderCell: (params) => {
                const purchaseOrderId = params.row.purchaseOrderId
                return (
                    purchaseOrderId && (
                        <IconButton size="small" onClick={stopPropagation}>
                            <Link to={generatePath(PURCHASE_ORDER_ROUTE, { purchaseOrderId })} target="_blank">
                                <ShoppingBag size={16} />
                            </Link>
                        </IconButton>
                    )
                )
            },
        },
        {
            field: "issuedAt",
            type: "date",
            width: 100,
            headerName: formatMessageCapitalized(columnsLabels.labelIssueDate),
            renderHeader: () => <SafeFormattedMessage {...columnsLabels.labelIssueDate} />,
            valueGetter: (params) => new Date(params.row.issuedAt),
            renderCell: ({ row: invoice }) => <DateComponent value={invoice.issuedAt} />,
        },
        {
            field: "totalAmountDue",
            type: "number",
            headerName: formatMessageCapitalized(columnsLabels.labelAmount),
            renderHeader: () => <SafeFormattedMessage {...columnsLabels.labelAmount} />,
            valueGetter: (params) => params.row.total,
            renderCell: ({ row: invoice }) => (
                <AmountInList amount={+(invoice.totalAmountDue?.[invoice.currency] ?? 0)} currency={invoice.currency} />
            ),
        },
        {
            field: "paidDate",
            type: "date",
            headerName: formatMessageCapitalized(columnsLabels.labelPaidAt),
            valueGetter: (params) => params.row.paidAt && new Date(params.row.paidAt),
            renderHeader: () => <SafeFormattedMessage {...columnsLabels.labelPaidAt} />,
            renderCell: ({ row: invoice }) => (invoice.paidAt ? <DateComponent value={invoice.paidAt} /> : "-"),
        },
        {
            field: "hasDuplicates",
            type: "boolean",
            width: 20,
            minWidth: 20,
            cellClassName: "p-0 pe-1",
            headerName: formatMessageCapitalized(columnsLabels.labelHasDuplicates),
            renderHeader: () => null,
            renderCell: ({ row: invoice }) =>
                invoice.hasDuplicates && (
                    <TooltipWarning title={<SafeFormattedMessage {...messages.tooltipHasDuplicates} />}>
                        <AlertCircle size={16} color="var(--color-yellow)" />
                    </TooltipWarning>
                ),
        },
        ...(actionColumn ? [actionColumn] : []),
    ]
}
