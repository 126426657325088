import { FetchBaseQueryError, createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { validate as validateUUID } from "uuid"

import { prepareHeadersWithAuthorizationAndOrganizationId as prepareHeaders } from "~/api/prepareHeaders"
import { commonMessages } from "~/common-messages"
import { invoiceAnalyticsFromApiAdapter } from "~/domains/transactions/invoices-v1/api/adapters/invoiceAnalyticsFromApiAdapter"
import { invoiceFromApiAdapter } from "~/domains/transactions/invoices-v1/api/adapters/invoiceFromApiAdapter"
import { invoiceToApiAdapter } from "~/domains/transactions/invoices-v1/api/adapters/invoiceToApiAdapter"
import { lineItemToApiAdapter } from "~/domains/transactions/invoices-v1/api/adapters/parts/lineItemToApiAdapter"
import {
    GetInvoicesByIdsQuery,
    GetInvoicesQuery,
    GetInvoicesSummaryQuery,
} from "~/domains/transactions/invoices-v1/api/types/invoiceApi.type"
import { InvoiceAPI, InvoiceSummaryAPI } from "~/domains/transactions/invoices-v1/types/Invoice"
import { InvoiceV1Payload } from "~/domains/transactions/invoices-v1/types/InvoicePayload"
import { InvoiceI, InvoiceLineI } from "~/types"
import { PaginatedCursorResponse } from "~/types/Pagination"
import { buildQueryString, buildQueryStringFromFilterModel } from "~/utils/api/qs"
import { DEFAULT_PAGE_SIZE } from "~/utils/table"

const BASE_OLD_URL = import.meta.env.VITE_API_INVOICES_URL + "v1"
const BASE_URL = import.meta.env.VITE_API_TRANSACTION_V2_INVOICES_URL + "invoices"
const BASE_RAW_URL = import.meta.env.VITE_API_TRANSACTION_V2_INVOICES_URL + ""

export const invoiceApiTags = ["invoice"]

export const invoiceApi = createApi({
    reducerPath: "invoiceApi",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        prepareHeaders,
    }),
    tagTypes: [...invoiceApiTags],
    endpoints: (builder) => ({
        getInvoices: builder.query<PaginatedCursorResponse<InvoiceI>, GetInvoicesQuery>({
            query: ({
                organizationIds,
                cursor,
                pageSize = DEFAULT_PAGE_SIZE,
                status = "",
                dueBefore,
                dueAfter,
                paid,
                sortBy = "issuedAt",
                ascending = true,
                tagIds,
                number,
                payerId,
                sellerId,
                filterModel,
            }) => {
                const params = new URLSearchParams({
                    ...(cursor ? { cursor } : {}),
                    ...(status ? { status } : {}),
                    ...(dueBefore ? { dueBefore } : {}),
                    ...(dueAfter ? { dueAfter } : {}),
                    ...(paid !== undefined ? { paid: paid.toString() } : {}),
                    ...(number ? { number } : {}),
                    pageSize: pageSize.toString(),
                    sortBy,
                    ascending: ascending.toString(),
                })
                organizationIds?.forEach((id) => params.append("organizationId", id))

                const queryStringFromFilterModel = !filterModel ? [] : buildQueryStringFromFilterModel(filterModel)
                const queryStringFromParams = !params
                    ? []
                    : buildQueryString([
                          [!!payerId, "payerId", "$eq", payerId, "&and"],
                          [!!sellerId, "sellerId", "$eq", sellerId, "&and"],
                          [!!tagIds?.length, "tagIds", "$in", tagIds, "&and"],
                      ])
                const queryString = [queryStringFromFilterModel, queryStringFromParams].filter(Boolean).join("&")

                organizationIds?.forEach((id) => params.append("organizationId", id))

                return {
                    url: `${BASE_URL}${queryString && `?${queryString}`}`,
                    params,
                }
            },
            transformErrorResponse: (response) => ({
                meta: {
                    errorMessage: commonMessages.error,
                },
                ...response,
            }),
            transformResponse: (response: InvoiceAPI[]) => ({
                items: (response || []).map((invoice) => invoiceFromApiAdapter(invoice)),
                tagIds: (response || []).flatMap((invoice) => invoice.tagIds),
            }),
            providesTags: invoiceApiTags,
        }),
        getInvoicesByIds: builder.query<InvoiceI[], GetInvoicesByIdsQuery>({
            query: ({ organizationId, ids }) => {
                const uuids = ids.filter((id) => validateUUID(id))
                return {
                    url: "/fetch",
                    method: "POST",
                    body: { organizationId, ids: uuids },
                }
            },
            transformResponse: (response: InvoiceAPI[]) =>
                (response || []).map((invoice) => invoiceFromApiAdapter(invoice)),
            providesTags: invoiceApiTags,
        }),
        getInvoicesSummary: builder.query<InvoiceSummaryAPI, GetInvoicesSummaryQuery>({
            query: ({ organizationId, payerId, sellerId }) => {
                const params = new URLSearchParams({
                    ...(payerId ? { payerId } : {}),
                    ...(sellerId ? { sellerId } : {}),
                })
                return { url: `${BASE_RAW_URL}/organizations/${organizationId}/invoices/summary`, params }
            },
            transformResponse: invoiceAnalyticsFromApiAdapter,
        }),
        getInvoice: builder.query<InvoiceI, string>({
            query: (id: string) => ({ url: `/${id}` }),
            transformErrorResponse: (response) => ({
                meta: {
                    errorMessage: commonMessages.error,
                },
                ...response,
            }),
            transformResponse: invoiceFromApiAdapter,
            providesTags: invoiceApiTags,
        }),
        createInvoice: builder.mutation<InvoiceI, Partial<InvoiceV1Payload>>({
            query: (invoice: Partial<InvoiceV1Payload>) => ({
                url: "",
                method: "POST",
                body: invoiceToApiAdapter(invoice),
            }),
            transformResponse: (response: InvoiceAPI) => invoiceFromApiAdapter(response),
            transformErrorResponse: (response: FetchBaseQueryError) => {
                return response.data
            },
        }),
        updateInvoice: builder.mutation({
            query: ({ id, ...invoice }: InvoiceV1Payload) => ({
                url: `/${id}`,
                method: "PUT",
                body: invoiceToApiAdapter(invoice),
            }),
            invalidatesTags: invoiceApiTags,
        }),
        deleteInvoice: builder.mutation({
            query: (id: string) => ({ url: `/${id}`, method: "DELETE" }),
        }),
        patchUpdateInvoice: builder.mutation<InvoiceI, InvoiceV1Payload>({
            query: ({ id, ...invoice }) => ({
                url: `/${id}`,
                method: "PATCH",
                body: invoiceToApiAdapter(invoice),
            }),
            invalidatesTags: invoiceApiTags,
        }),
        downloadInvoices: builder.mutation({
            query: (invoiceIds: string[]) => ({
                url: `${BASE_OLD_URL}/resources/invoice-pdf/batch`,
                method: "POST",
                body: { invoiceIds },
                headers: {
                    Accept: "application/octet-stream",
                },
                responseHandler: async (response: Response) => {
                    const blob = await response.blob()
                    const url = window.URL.createObjectURL(blob)
                    const link = document.createElement("a")
                    link.href = url
                    link.download = "invoices.zip"
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    window.URL.revokeObjectURL(url)
                },
            }),
        }),
        createLineItemInInvoice: builder.mutation({
            query: ({ invoiceId, line }: { invoiceId: string; line: InvoiceLineI }) => ({
                url: `/${invoiceId}/line-items`,
                method: "POST",
                body: lineItemToApiAdapter(line),
            }),
            invalidatesTags: invoiceApiTags,
        }),
        putUpdateLineItemInInvoice: builder.mutation({
            query: ({ invoiceId, line }: { invoiceId: string; line: InvoiceLineI }) => ({
                url: `/${invoiceId}/line-items/${line.id}`,
                method: "PUT",
                body: line,
            }),
        }),
        deleteLineItemInInvoice: builder.mutation({
            query: ({ invoiceId, id }: { invoiceId: string; id: string }) => ({
                url: `/${invoiceId}/line-items/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: invoiceApiTags,
        }),
        patchUpdateLineItemInInvoice: builder.mutation({
            query: ({ invoiceId, line }: { invoiceId: string; line: Partial<InvoiceLineI> }) => ({
                url: `/${invoiceId}/line-items/${line.id}`,
                method: "PATCH",
                body: lineItemToApiAdapter(line),
            }),
            invalidatesTags: invoiceApiTags,
        }),
    }),
})

export const {
    useGetInvoicesQuery,
    useGetInvoicesByIdsQuery,
    useGetInvoicesSummaryQuery,
    useGetInvoiceQuery,
    useCreateInvoiceMutation,
    useUpdateInvoiceMutation,
    useDeleteInvoiceMutation,
    usePatchUpdateInvoiceMutation,
    useDownloadInvoicesMutation,
    useCreateLineItemInInvoiceMutation,
    usePutUpdateLineItemInInvoiceMutation,
    useDeleteLineItemInInvoiceMutation,
    usePatchUpdateLineItemInInvoiceMutation,
} = invoiceApi
