import { TagObjectRecordI } from "~/domains/analytics/tags/types/TagObjectRecord"
import { DocumentI, DocumentIWithTotalTax } from "~/types"

import { roundMoney } from "./computeInvoiceLinesTotals"

/* The goal of this function is to order the CSV columns and add Total Tax column */
export const formatInvoiceForCSVExport = (invoice: DocumentI, tagObjects: TagObjectRecordI | undefined) => {
    const formattedInvoice: DocumentIWithTotalTax = {
        invoiceId: invoice.invoiceId,
        reference: invoice.reference,
        description: invoice.description,
        status: invoice.status,
        supplierId: invoice.supplierId && invoice.supplierId !== "" ? invoice.supplierId : undefined,
        supplierName: invoice.supplierName && invoice.supplierName !== "" ? invoice.supplierName : undefined,
        buyerId: invoice.buyerId && invoice.buyerId !== "" ? invoice.buyerId : undefined,
        buyerName: invoice.buyerName && invoice.buyerName !== "" ? invoice.buyerName : undefined,
        total: invoice.total,
        totalExcludedTaxes: invoice.totalExcludedTaxes,
        totalDiscount: invoice.totalDiscount,
        totalTax: roundMoney(invoice.total - invoice.totalExcludedTaxes),
        issueDate: invoice.issueDate,
        dueDate: invoice.dueDate,
        paidDate: invoice.paidDate,
        receptionDate: invoice.receptionDate,
        updateDate: invoice.updateDate,
        purchaseOrderNumber: invoice.purchaseOrderNumber,
        isSupplierIbanSet: invoice.isSupplierIbanSet,
        pdfLink: invoice.pdfLink,
        source: invoice.source,
        signed: invoice.signed,
        tagsWithGroups: "",
    }
    if (tagObjects && tagObjects[invoice.invoiceId]) {
        const tagsWithGroups: string[] = []
        tagObjects[invoice.invoiceId].map((tagObject) => {
            tagsWithGroups.push(`${tagObject.tagName} (${tagObject.tagGroupName})`)
        })
        formattedInvoice.tagsWithGroups = tagsWithGroups.join(" | ")
    }
    if (!invoice.supplierId) {
        delete formattedInvoice.supplierId
    }
    if (!invoice.supplierName) {
        delete formattedInvoice.supplierName
    }
    if (!invoice.buyerId) {
        delete formattedInvoice.buyerId
    }
    if (!invoice.buyerName) {
        delete formattedInvoice.buyerName
    }
    return formattedInvoice
}
