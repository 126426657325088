import { Grid, Skeleton, Stack } from "@mui/material"
import { Home } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { Card, HeaderH1, SafeFormattedMessage } from "~/components"
import "~/domains/analytics/dashboard/assets/Dashboard.scss"
import { DashboardApprovalTabs } from "~/domains/analytics/dashboard/components/DashboardApprovalTabs"
import { DashboardCallToActions } from "~/domains/analytics/dashboard/components/DashboardCallToActions"
import { DashboardEmptyState } from "~/domains/analytics/dashboard/components/DashboardEmptyState"
import { DashboardMetricsCard } from "~/domains/analytics/dashboard/components/DashboardMetricsCard"
import { DashboardPurchaseRequestTabs } from "~/domains/analytics/dashboard/components/DashboardPurchaseRequestTabs"
import { useDashboardMetrics } from "~/domains/analytics/dashboard/hooks"
import { UserTasksList } from "~/domains/communication/tasks/components/UserTasksList"
import { useFetchPurchaseOrders } from "~/domains/transactions/purchase-orders/store/hooks"
import { useFetchPurchaseRequests } from "~/domains/transactions/purchase-requests/store/hooks"
import { useTitle } from "~/hooks"
import { selectUser, selectUserLoading } from "~/store/account/accountSlice"
import { useGetReceivedDocuments } from "~/store/account/hooks"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { Features, isFeatureEnabled } from "~/utils/featureFlag"

const messages = defineMessages({
    title: {
        id: "dashboard.header.title",
        defaultMessage: "Dashboard",
    },
    headerWelcome: {
        id: "dashboard.header.welcome",
        defaultMessage: "Welcome {name}",
    },
    prCardHeading: {
        id: "dashboard.card.pr.heading",
        defaultMessage: "Purchase requests created",
    },
    prCardPercentage: {
        id: "dashboard.card.pr.percentage",
        defaultMessage: "from previous month",
    },
    prCardValue: {
        id: "dashboard.card.pr.value",
        defaultMessage: "PRs this month",
    },
    poCardHeading: {
        id: "dashboard.card.po.heading",
        defaultMessage: "Purchase orders created",
    },
    poCardPercentage: {
        id: "dashboard.card.po.percentage",
        defaultMessage: "from previous month",
    },
    poCardValue: {
        id: "dashboard.card.po.value",
        defaultMessage: "POs this month",
    },
    invoicesCardHeading: {
        id: "dashboard.card.invoices.heading",
        defaultMessage: "Invoices validated",
    },
    invoicesCardPercentage: {
        id: "dashboard.card.invoices.percentage",
        defaultMessage: "from previous month",
    },
    invoicesCardValue: {
        id: "dashboard.card.invoices.value",
        defaultMessage: "invoices this month",
    },
    myPurchaseRequests: {
        id: "dashboard.list.heading.myPurchaseRequests",
        defaultMessage: "My purchase requests",
    },
    myApprovals: {
        id: "dashboard.list.heading.myApprovals",
        defaultMessage: "Documents pending approval",
    },
    myTasks: {
        id: "dashboard.list.heading.myTasks",
        defaultMessage: "My tasks",
    },
})

const ListLoadingState = () => (
    <div className="dashboard-list-loading">
        <Skeleton variant="rectangular" className="dashboard-list-loading-skeleton" />
        <Skeleton variant="rectangular" className="dashboard-list-loading-skeleton" />
        <Skeleton variant="rectangular" className="dashboard-list-loading-skeleton" />
    </div>
)

export const Dashboard = () => {
    const { formatMessage } = useIntl()
    const user = useAppSelector(selectUser)
    const isUserLoading = useAppSelector(selectUserLoading)
    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const { purchaseRequests, loading: purchaseRequestsLoading } = useFetchPurchaseRequests(currentOrganizationId)
    const { purchaseOrders, loading: loadingPurchaseOrders } = useFetchPurchaseOrders(undefined, currentOrganizationId)
    const { receivedDocuments, loading: receivedDocumentsLoading } = useGetReceivedDocuments(
        {
            organizationId: currentOrganizationId,
            userId: user.id,
        },
        true
    )
    const { metrics, approvals } = useDashboardMetrics({
        purchaseRequests,
        purchaseOrders,
        receivedDocuments,
    })
    const areTasksEnabled = isFeatureEnabled(Features.Tasks, currentOrganizationId)
    const gridSize = areTasksEnabled ? 4 : 6
    const tasksColumnSize = 12 - 2 * gridSize // Width for tasks column after allocating space for PR and approvals

    useTitle(formatMessage(messages.title))

    return (
        <>
            <HeaderH1 title={formatMessage(messages.title)} icon={<Home />} />

            <div className="dashboard">
                <Stack paddingTop={5} paddingBottom={2}>
                    {!isUserLoading && (
                        <h4>
                            <b>
                                <SafeFormattedMessage
                                    {...messages.headerWelcome}
                                    values={{
                                        name: user.fullName,
                                    }}
                                />
                            </b>
                        </h4>
                    )}
                </Stack>

                <DashboardCallToActions currentOrganizationId={currentOrganizationId} />

                <Grid
                    container
                    spacing={3}
                    justifyContent="stretch"
                    flexDirection={{ xs: "column", sm: "row" }}
                    className="dashboard-metrics"
                >
                    <Grid item xs={12} sm={4}>
                        <DashboardMetricsCard
                            isLoading={purchaseRequestsLoading}
                            metricValue={metrics.pr.currentMonthCount}
                            percentage={metrics.pr.percentage}
                            heading={formatMessage(messages.prCardHeading)}
                            percentageLabel={<SafeFormattedMessage {...messages.prCardPercentage} />}
                            valueLabel={<SafeFormattedMessage {...messages.prCardValue} />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <DashboardMetricsCard
                            isLoading={loadingPurchaseOrders}
                            metricValue={metrics.po.currentMonthCount}
                            percentage={metrics.po.percentage}
                            heading={formatMessage(messages.poCardHeading)}
                            percentageLabel={<SafeFormattedMessage {...messages.poCardPercentage} />}
                            valueLabel={<SafeFormattedMessage {...messages.poCardValue} />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <DashboardMetricsCard
                            isLoading={receivedDocumentsLoading}
                            metricValue={metrics.invoices.currentMonthCount}
                            percentage={metrics.invoices.percentage}
                            heading={formatMessage(messages.invoicesCardHeading)}
                            percentageLabel={<SafeFormattedMessage {...messages.invoicesCardPercentage} />}
                            valueLabel={<SafeFormattedMessage {...messages.invoicesCardValue} />}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3} justifyContent="stretch" className="dashboard-metrics">
                    <Grid item xs={12} sm={gridSize} className="w-full">
                        <Card title={formatMessage(messages.myPurchaseRequests)} className="dashboard-list">
                            {purchaseRequestsLoading ? (
                                <ListLoadingState />
                            ) : (
                                <DashboardPurchaseRequestTabs purchaseRequests={purchaseRequests} user={user} />
                            )}
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={gridSize} className="w-full">
                        <Card title={formatMessage(messages.myApprovals)} className="dashboard-list">
                            {purchaseRequestsLoading || loadingPurchaseOrders || receivedDocumentsLoading ? (
                                <ListLoadingState />
                            ) : (
                                <DashboardApprovalTabs approvals={approvals} />
                            )}
                        </Card>
                    </Grid>

                    {areTasksEnabled && currentOrganizationId && (
                        <Grid item xs={12} sm={tasksColumnSize} className="w-full">
                            <Card title={formatMessage(messages.myTasks)} className="dashboard-list">
                                <UserTasksList
                                    organizationId={currentOrganizationId}
                                    user={user}
                                    emptyState={<DashboardEmptyState />}
                                />
                            </Card>
                        </Grid>
                    )}
                </Grid>
            </div>
        </>
    )
}
