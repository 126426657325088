import { defineMessages } from "react-intl"

export const taskCommonMessages = defineMessages({
    taskTitle: {
        id: "communication.tasks.taskTitle",
        defaultMessage: "Title",
    },
    taskDescription: {
        id: "communication.tasks.taskDescription",
        defaultMessage: "Description",
    },
    taskAssignee: {
        id: "communication.tasks.taskAssignee",
        defaultMessage: "Assignee",
    },
    taskStatus: {
        id: "communication.tasks.taskStatus",
        defaultMessage: "Status",
    },
    taskPriority: {
        id: "communication.tasks.taskPriority",
        defaultMessage: "Priority",
    },
    taskDueDate: {
        id: "communication.tasks.taskDueDate",
        defaultMessage: "Due date",
    },
    taskFollowers: {
        id: "communication.tasks.taskFollowers",
        defaultMessage: "Followers",
    },
    taskDocuments: {
        id: "communication.tasks.taskDocuments",
        defaultMessage: "Documents",
    },
    errorFetchingTasks: {
        id: "communication.tasks.error.errorFetchingTasks",
        defaultMessage: "An error occurred while fetching tasks",
    },
})
