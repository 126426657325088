import { Stack, TextField } from "@mui/material"
import React, { FC } from "react"
import { Trash2 } from "react-feather"
import { useIntl } from "react-intl"

import { IconButton } from "~/domains/orchestration/flows/components/IconButton"
import { messages } from "~/domains/orchestration/flows/locale"
import { BuyerOrSupplier, PurchaseOrderLine as PurchaseOrderLineType } from "~/domains/orchestration/flows/types"

interface Props {
    line: PurchaseOrderLineType
    onChange: (line: PurchaseOrderLineType) => void
    buyerOrSupplier: BuyerOrSupplier
    onDelete: () => void
}

export const PurchaseOrderLine: FC<Props> = ({ line, onChange, buyerOrSupplier, onDelete }) => {
    const intl = useIntl()

    const handleChange = (field: keyof PurchaseOrderLineType) => (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange({
            ...line,
            [field]: event.target.value,
        })
    }

    const handleCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange({
            ...line,
            unitPrice: { ...line.unitPrice, currency: event.target.value },
            unitPriceExcludingTax: { ...line.unitPriceExcludingTax, currency: event.target.value },
        })
    }

    const handleTaxRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange({
            ...line,
            taxRate: event.target.value,
        })
    }

    const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newUnitPrice = { ...line.unitPrice, amount: event.target.value }

        onChange({
            ...line,
            unitPrice: newUnitPrice,
        })
    }

    const handleUnitPriceExcludingTaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newUnitPriceExcludingTax = { ...line.unitPriceExcludingTax, amount: event.target.value }

        onChange({
            ...line,
            unitPriceExcludingTax: newUnitPriceExcludingTax,
        })
    }

    return (
        <Stack spacing={2}>
            <TextField
                id={`line-description-${line.id}`}
                label={intl.formatMessage(messages.createPurchaseOrderConfiguration.lineDescriptionLabel)}
                value={line.description}
                onChange={handleChange("description")}
                fullWidth
                required
            />
            <Stack direction="row" spacing={2}>
                <TextField
                    id={`line-quantity-${line.id}`}
                    label={intl.formatMessage(messages.createPurchaseOrderConfiguration.quantityLabel)}
                    value={line.quantity}
                    onChange={handleChange("quantity")}
                    fullWidth
                    required
                />
                <TextField
                    id={`line-units-${line.id}`}
                    label={intl.formatMessage(messages.createPurchaseOrderConfiguration.unitsLabel)}
                    value={line.units || ""}
                    onChange={handleChange("units")}
                    fullWidth
                />
            </Stack>
            <Stack direction="row" spacing={2}>
                <TextField
                    label={intl.formatMessage(messages.createPurchaseOrderConfiguration.unitPriceLabel)}
                    value={line.unitPrice.amount}
                    onChange={handlePriceChange}
                    fullWidth
                    required
                />
                <TextField
                    label={intl.formatMessage(messages.createPurchaseOrderConfiguration.unitPriceExcludingTaxLabel)}
                    value={line.unitPriceExcludingTax.amount}
                    onChange={handleUnitPriceExcludingTaxChange}
                    fullWidth
                    required
                />
                <TextField
                    label={intl.formatMessage(messages.createPurchaseOrderConfiguration.currencyLabel)}
                    value={line.unitPrice.currency}
                    onChange={handleCurrencyChange}
                    fullWidth
                    required
                />
            </Stack>
            <Stack direction="row" spacing={2}>
                <TextField
                    label={intl.formatMessage(messages.createPurchaseOrderConfiguration.taxRateLabel)}
                    value={line.taxRate}
                    onChange={handleTaxRateChange}
                    fullWidth
                    required
                />
            </Stack>
            <Stack direction="row" spacing={2}>
                {buyerOrSupplier === BuyerOrSupplier.BUYER && (
                    <TextField
                        label={intl.formatMessage(messages.createPurchaseOrderConfiguration.buyerItemIdLabel)}
                        value={line.buyerItemId || ""}
                        onChange={handleChange("buyerItemId")}
                        fullWidth
                    />
                )}
                {buyerOrSupplier === BuyerOrSupplier.SUPPLIER && (
                    <TextField
                        label={intl.formatMessage(messages.createPurchaseOrderConfiguration.supplierItemIdLabel)}
                        value={line.supplierItemId || ""}
                        onChange={handleChange("supplierItemId")}
                        fullWidth
                    />
                )}
            </Stack>
            <Stack direction="row" justifyContent="flex-end">
                <IconButton type="close" onClick={onDelete}>
                    <Trash2 size={18} color="var(--color-red)" />
                </IconButton>
            </Stack>
        </Stack>
    )
}
