import { MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material"
import cls from "classnames"
import { FC, useState } from "react"
import { useIntl } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import { AdvancedFields } from "~/domains/orchestration/flows/components/configuration/AdvancedFields"
import { useFlowTriggerUpdate } from "~/domains/orchestration/flows/hooks/useFlowTriggerUpdate"
import { messages } from "~/domains/orchestration/flows/locale"
import {
    Conditions,
    ConfigurationProps,
    Event,
    EventTriggerNode,
    TriggerMode,
    isEvent,
} from "~/domains/orchestration/flows/types"

import { ConfigurationNode } from "./ConfigurationNode"
import "./EventTriggerConfiguration.scss"
import { SharedTriggerCondition } from "./SharedTriggerCondition"

const EVENTS = Object.values(Event)

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 300,
            overflowY: "scroll" as const,
        },
    },
}

export const EventTriggerConfiguration: FC<ConfigurationProps<EventTriggerNode>> = ({
    selectedNode,
    advancedFields,
    validateNode,
}) => {
    const { formatMessage } = useIntl()
    const { updateFlowForTrigger } = useFlowTriggerUpdate()

    const hasConditions = selectedNode.filter.length > 0
    const [enableConditions, setEnableConditions] = useState(hasConditions)

    // Handle the current node
    const [currentNode, setCurrentNode] = useState(selectedNode)
    const initialConditions = selectedNode.filter
    const onConditionsChange = (newConditions: Conditions[]) => {
        setCurrentNode({
            ...currentNode,
            filter: newConditions,
            metadata: {
                ...currentNode.metadata,
            },
        })
    }

    const handleChangeEvent = (e: SelectChangeEvent<Event>): void => {
        const event = e.target.value
        if (!isEvent(event)) return

        setCurrentNode({
            ...currentNode,
            event,
        })

        // Update the entire flow
        updateFlowForTrigger({ type: event, mode: TriggerMode.EVENT, slug: currentNode.slug, currencyConversion: null })
    }

    const handleEnableConditions = () => {
        setEnableConditions(!enableConditions)
    }

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    return (
        <ConfigurationNode selectedNode={currentNode} validateNode={validateNode}>
            <Stack gap={2} className={configurationNodeItemClassName}>
                <SafeFormattedMessage tagName="h5" {...messages.eventTriggerConfiguration.eventTitle} />

                <Select
                    required
                    value={currentNode.event ?? ""}
                    onChange={handleChangeEvent}
                    fullWidth
                    MenuProps={MenuProps}
                >
                    {EVENTS.map((event) => (
                        <MenuItem key={event} value={event}>
                            {formatMessage(messages.event[event])}
                        </MenuItem>
                    ))}
                </Select>

                <SharedTriggerCondition
                    enableConditions={enableConditions}
                    onEnableConditionsChange={handleEnableConditions}
                    initialConditions={initialConditions}
                    onChangeCondition={onConditionsChange}
                />

                <AdvancedFields<EventTriggerNode>
                    fields={advancedFields}
                    currentNode={currentNode}
                    setCurrentNode={setCurrentNode}
                />
            </Stack>
        </ConfigurationNode>
    )
}
