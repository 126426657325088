/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Grid, Stack, Typography } from "@mui/material"
import { Info } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { ItemLabel, Loader, TooltipWhite } from "~/components"
import { useGetPaymentMethodDetailsItemQuery } from "~/domains/payment/payment-method-details/api/paymentMethodDetailsApi"
import {
    buildExpirationDate,
    buildMaskedCardNumber,
} from "~/domains/payment/payment-method-details/utils/getGridColumnsByActiveTab"
import { useGetPaymentMethodQuery } from "~/domains/payment/payment-methods/api/paymentMethodsApi"
import { PaymentMethodType } from "~/domains/payment/payment-methods/types"

const messages = defineMessages({
    iban: {
        id: "payment.partyPaymentMethodDetails.iban",
        defaultMessage: "Iban",
    },
    bicSwift: {
        id: "payment.partyPaymentMethodDetails.bicSwift",
        defaultMessage: "Bic / Swift",
    },
    bankAccountNumber: {
        id: "payment.partyPaymentMethodDetails.accountNumber",
        defaultMessage: "Bank account number",
    },
    bankName: {
        id: "payment.partyPaymentMethodDetails.bankName",
        defaultMessage: "Bank name",
    },
    currency: {
        id: "payment.partyPaymentMethodDetails.currency",
        defaultMessage: "Currency",
    },
    cardNumber: {
        id: "payment.partyPaymentMethodDetails.cardNumber",
        defaultMessage: "Card number",
    },
    expirationDate: {
        id: "payment.partyPaymentMethodDetails.expirationDate",
        defaultMessage: "Expiration date",
    },
    cardBrand: {
        id: "payment.partyPaymentMethodDetails.cardBrand",
        defaultMessage: "Card brand",
    },
    accountNumber: {
        id: "payment.partyPaymentMethodDetails.accountNumber",
        defaultMessage: "Account number",
    },
    mandateReference: {
        id: "payment.partyPaymentMethodDetails.mandateReference",
        defaultMessage: "Mandate reference",
    },
    mandateDate: {
        id: "payment.partyPaymentMethodDetails.mandateDate",
        defaultMessage: "Mandate date",
    },
})

interface PartyPaymentMethodDetailsProps {
    paymentMethodDetailsId?: string | null
    paymentMethodId?: string | null
    mode?: "block" | "inline"
}

interface DataItem {
    main?: boolean
    label: string
    value: string
}

export const PartyPaymentMethodDetails = ({
    paymentMethodDetailsId,
    paymentMethodId,
    mode = "block",
}: PartyPaymentMethodDetailsProps) => {
    const {
        data: paymentMethodDetails,
        isLoading: isLoadingPaymentMethodDetails,
        isFetching,
    } = useGetPaymentMethodDetailsItemQuery(paymentMethodDetailsId ?? "", {
        skip: !paymentMethodDetailsId,
    })
    const { data: paymentMethod, isLoading: isLoadingPaymentMethod } = useGetPaymentMethodQuery(paymentMethodId ?? "", {
        skip: !paymentMethodId,
    })
    const { formatMessage, formatDate, locale } = useIntl()
    const isLoading = isLoadingPaymentMethodDetails || isLoadingPaymentMethod || isFetching

    const buildBankTransferItems = (details: any) => {
        const { iban, bic_swift, bank_account_number, bank_name, currency } = details
        return [
            ...(iban
                ? [
                      {
                          main: true,
                          label: formatMessage(messages.iban),
                          value: iban,
                      },
                  ]
                : []),

            ...(bank_account_number
                ? [
                      {
                          main: true,
                          label: formatMessage(messages.bankAccountNumber),
                          value: bank_account_number,
                      },
                  ]
                : []),
            ...(bic_swift
                ? [
                      {
                          label: formatMessage(messages.bicSwift),
                          value: bic_swift,
                      },
                  ]
                : []),
            {
                label: formatMessage(messages.currency),
                value: currency,
            },
            {
                main: true,
                label: formatMessage(messages.bankName),
                value: bank_name,
            },
        ]
    }

    const buildCardItems = (details: any) => {
        const {
            card_first_6_digits,
            card_last_4_digits,
            card_expiration_month,
            card_expiration_year,
            card_brand,
            currency,
        } = details
        return [
            {
                main: true,
                label: formatMessage(messages.cardNumber),
                value: buildMaskedCardNumber([card_first_6_digits, card_last_4_digits]),
            },
            {
                label: formatMessage(messages.expirationDate),
                value: buildExpirationDate([card_expiration_month, card_expiration_year]),
            },
            {
                label: formatMessage(messages.cardBrand),
                value: card_brand,
            },
            {
                label: formatMessage(messages.currency),
                value: currency,
            },
        ]
    }

    const buildDirectDebitItems = (details: any) => {
        const { bank_account_number, mandate_reference, mandate_date, currency } = details
        return [
            {
                main: true,
                label: formatMessage(messages.accountNumber),
                value: bank_account_number,
            },
            {
                label: formatMessage(messages.mandateReference),
                value: mandate_reference,
            },
            {
                label: formatMessage(messages.mandateDate),
                value: formatDate(mandate_date),
            },
            {
                label: formatMessage(messages.currency),
                value: currency,
            },
        ]
    }

    const dataValues: DataItem[] = []

    if (paymentMethodDetails) {
        const { payment_method_type } = paymentMethodDetails

        if (payment_method_type === PaymentMethodType.BANK_TRANSFER) {
            dataValues.push(...buildBankTransferItems(paymentMethodDetails))
        }

        if (payment_method_type === PaymentMethodType.CARD) {
            dataValues.push(...buildCardItems(paymentMethodDetails))
        }

        if (payment_method_type === PaymentMethodType.DIRECT_DEBIT) {
            dataValues.push(...buildDirectDebitItems(paymentMethodDetails))
        }
    } else if (paymentMethod) {
        const { name, name_translations } = paymentMethod

        dataValues.push({
            main: true,
            label: "",
            value: name_translations?.[locale] ?? name,
        })
    }

    const renderContent = () => (
        <Grid container spacing={2}>
            {dataValues
                .filter((item) => !!item.value)
                .map(({ label, value, main }) => (
                    <Grid key={label} item xs={12} sm={main ? 12 : 6}>
                        {label && <ItemLabel>{label}</ItemLabel>}
                        <Typography>{value || "-"}</Typography>
                    </Grid>
                ))}
        </Grid>
    )

    if (isLoading) {
        return <Loader small />
    }

    if (!paymentMethod && !paymentMethodDetails) {
        return null
    }

    return mode === "inline" ? (
        <TooltipWhite sx={{ maxWidth: "250px" }} arrow title={renderContent()}>
            <Stack gap={1} display="flex" flexDirection="row" alignItems="center">
                <Typography lineHeight={2}>
                    {dataValues?.find(({ main }) => main && typeof main === "boolean")?.value ?? "-"}
                </Typography>
                <Info size={16} cursor="pointer" color="var(--color-grey)" />
            </Stack>
        </TooltipWhite>
    ) : (
        renderContent()
    )
}
