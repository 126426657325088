import { Box, Checkbox, Divider, Fade, FormControlLabel, Grid, Stack, Typography, styled } from "@mui/material"
import * as Sentry from "@sentry/browser"
import React, { FC, useEffect, useState } from "react"
import { FormattedDate, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { Loader, SafeFormattedMessage } from "~/components"
import { RunStepper } from "~/domains/orchestration/flows/components/RunStepper"
import { RUN_STATUS_COLORS } from "~/domains/orchestration/flows/constants"
import { useOrganizationId } from "~/domains/orchestration/flows/hooks/"
import { useRunQueryWithFlowData } from "~/domains/orchestration/flows/hooks/useRunQueryWithFlowData"
import { messages } from "~/domains/orchestration/flows/locale"
import { RunId, RunStatus } from "~/domains/orchestration/flows/types"

import { RunStorage } from "./RunStorage"

interface Props {
    runId: RunId
}

const SelectableIDs = styled(Stack)({
    userSelect: "text",
})

export const RunExplorerItem: FC<Props> = ({ runId }) => {
    const { formatMessage } = useIntl()

    const organizationId = useOrganizationId()
    const { data, isLoading, isError, error, refetch } = useRunQueryWithFlowData(runId)

    const [checked, setChecked] = useState(false)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked)
    }

    useEffect(() => {
        if (organizationId) {
            refetch()
        }
    }, [organizationId, refetch])

    if (isLoading) {
        return <Loader fullscreen />
    }
    if (isError) {
        toast.error(formatMessage(messages.error.loadingRuns))
        Sentry.captureException(error)
        return null
    }
    if (!data) {
        return null
    }

    const color = RUN_STATUS_COLORS[data.status]

    return (
        <Stack gap={2}>
            <Divider />
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="body2">
                    <SafeFormattedMessage {...messages.runInformation.flowVersion} />: {data.flowVersion}
                </Typography>

                <Typography variant="body2" color={color}>
                    <SafeFormattedMessage {...messages.runInformation.status} />: {data.status}
                </Typography>
            </Stack>

            <Divider />
            <Grid container>
                <Grid item xs={6}>
                    <RunStepper key={data.id} data={data} />
                </Grid>
                <Grid item xs={6} textAlign="right">
                    <Stack>
                        <Typography variant="caption" color={color}>
                            <SafeFormattedMessage {...messages.runInformation.startedAt} />:{" "}
                            <FormattedDate value={data.startedAt} dateStyle="short" timeStyle="short" />
                        </Typography>
                        {data.finishedAt ? (
                            <Typography variant="caption" color={color}>
                                <SafeFormattedMessage {...messages.runInformation.finishedAt} />:
                                <FormattedDate value={data.finishedAt} dateStyle="short" timeStyle="short" />
                            </Typography>
                        ) : null}
                    </Stack>
                </Grid>
            </Grid>

            {data.error && (
                <Typography variant="caption" color={RUN_STATUS_COLORS[RunStatus.FAILED]}>
                    <SafeFormattedMessage {...messages.runInformation.error} />: {data.error}
                </Typography>
            )}
            {data.advancedError && (
                <Box>
                    <FormControlLabel
                        control={<Checkbox checked={checked} onChange={handleChange} />}
                        label={
                            <Typography variant="caption">
                                <SafeFormattedMessage {...messages.runInformation.showAdvancedError} />
                            </Typography>
                        }
                    />

                    <Fade in={checked}>
                        <Typography variant="caption" color={RUN_STATUS_COLORS[RunStatus.FAILED]}>
                            {data.advancedError}
                        </Typography>
                    </Fade>
                </Box>
            )}

            <Divider />
            {data.state.storage && <RunStorage storage={data.state.storage} />}
            <Divider />
            <SelectableIDs>
                <Typography variant="caption" color="text.secondary">
                    <SafeFormattedMessage {...messages.runInformation.id} />: {data.id}
                </Typography>

                <Typography variant="caption" color="text.secondary">
                    <SafeFormattedMessage {...messages.runInformation.flowId} />: {data.flowId}
                </Typography>
            </SelectableIDs>
        </Stack>
    )
}
