import { capitalize } from "@mui/material"
import * as Sentry from "@sentry/browser"
import { MessageDescriptor, useIntl } from "react-intl"

export const useSafeIntl = () => {
    const { locale, formatMessage, ...props } = useIntl()

    const safeFormatMessage = (message: MessageDescriptor, transformer?: (message: string) => string) => {
        if (!message?.id) {
            console.error("Missing message id", {
                locale,
                defaultMessage: message?.defaultMessage,
            })
            Sentry.captureException("Missing message id", {
                extra: {
                    locale,
                    defaultMessage: message?.defaultMessage,
                },
            })
            return typeof message?.defaultMessage === "string" ? message?.defaultMessage : "translation missing"
        }

        return transformer ? transformer(formatMessage(message)) : formatMessage(message)
    }

    return {
        formatMessage: safeFormatMessage,
        formatMessageCapitalized: (message: MessageDescriptor) => safeFormatMessage(message, capitalize),
        ...props,
    }
}
