import { useState } from "react"
import { Tag } from "react-feather"
import { useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { Button, SafeFormattedMessage, TooltipConditional } from "~/components"
import { displayAndCaptureError } from "~/displayAndCaptureError"
import { ModalAddTags } from "~/domains/analytics/tags/components/ModalAddTags"
import { TagI } from "~/domains/analytics/tags/types/Tag"
import { TagObjectType } from "~/domains/analytics/tags/types/TagObjectType"
import { permissionMessages } from "~/domains/identity/roles-permissions/utils/permissions"
import { selectUser } from "~/store/account/accountSlice"
import { useBulkAddTags } from "~/store/account/hooks/useBulkAddTags"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { DocumentI, InvoiceBackendVersion, InvoiceI, ViewTypeI } from "~/types"

interface InvoicesAddTagsProps {
    invoices: InvoiceI[]
    selected: string[]
    onSuccess: () => void
}

export const InvoicesAddTags = ({ invoices, selected, onSuccess }: InvoicesAddTagsProps) => {
    const { formatMessage } = useIntl()
    const [displayModalAddTags, setDisplayModalAddTags] = useState(false)

    const openAddTagsModal = () => setDisplayModalAddTags(true)
    const closeAddTagsModal = () => setDisplayModalAddTags(false)

    const currentOrganization = useAppSelector(selectCurrentOrganization)
    const currentUser = useAppSelector(selectUser)

    const tagsContext = {
        page: "my-documents",
        user: currentUser,
        organization: currentOrganization,
        view: ViewTypeI.buyer,
    }

    const bulkAddTags = useBulkAddTags(
        currentOrganization?.id ?? "",
        currentUser?.id ?? "",
        TagObjectType.INVOICE,
        tagsContext,
        InvoiceBackendVersion.V1
    )

    const handleConfirmModalAddTags = (tagsToAdd: TagI[], tagsToRemove: TagI[]) => {
        try {
            bulkAddTags(selected, tagsToAdd, tagsToRemove, ViewTypeI.buyer)
            closeAddTagsModal()
            onSuccess()
        } catch (error) {
            displayAndCaptureError(formatMessage(commonMessages.error), error)
        }
    }

    const hasPermission = true

    return (
        <>
            <TooltipConditional
                condition={!hasPermission}
                title={<SafeFormattedMessage {...permissionMessages.errorNoAccessAdministrator} />}
            >
                <div>
                    <Button type="primary-light" onClick={openAddTagsModal}>
                        <SafeFormattedMessage {...commonMessages.tags} /> <Tag size={16} />
                    </Button>
                </div>
            </TooltipConditional>

            {currentOrganization && currentUser && (
                <ModalAddTags
                    organization={currentOrganization}
                    selected={selected}
                    objects={(invoices ?? []) as unknown as DocumentI[]}
                    displayModal={displayModalAddTags}
                    setDisplayModal={setDisplayModalAddTags}
                    onConfirm={handleConfirmModalAddTags}
                    onClose={closeAddTagsModal}
                />
            )}
        </>
    )
}
