import { PurchaseOrders } from "~/domains/transactions/purchase-orders/types"
import { PurchaseRequestSummary } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { ReceivedDocumentI } from "~/types"

export enum DocumentForApprovalType {
    PURCHASE_REQUEST,
    PURCHASE_ORDER,
    INVOICE,
}

export type PurchaseRequestForApproval = {
    type: DocumentForApprovalType.PURCHASE_REQUEST
    document: PurchaseRequestSummary
}

export type PurchaseOrderForApproval = {
    type: DocumentForApprovalType.PURCHASE_ORDER
    document: PurchaseOrders
}

export type InvoiceForApproval = {
    type: DocumentForApprovalType.INVOICE
    document: ReceivedDocumentI
}

export type DocumentForApproval = PurchaseRequestForApproval | PurchaseOrderForApproval | InvoiceForApproval

export const isPurchaseRequestforApproval = (approval: DocumentForApproval): approval is PurchaseRequestForApproval =>
    approval.type === DocumentForApprovalType.PURCHASE_REQUEST

export const isPurchaseOrderforApproval = (approval: DocumentForApproval): approval is PurchaseOrderForApproval =>
    approval.type === DocumentForApprovalType.PURCHASE_ORDER

export const isInvoiceforApproval = (approval: DocumentForApproval): approval is InvoiceForApproval =>
    approval.type === DocumentForApprovalType.INVOICE
