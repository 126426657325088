import { Stack } from "@mui/material"
import { defineMessages, useIntl } from "react-intl"

import { Card } from "~/components"
import { DocumentPaymentTerm } from "~/domains/payment/payment-terms/components"
import { Payment, PaymentStatus } from "~/domains/payment/types"

import { DocumentPaymentDate } from "./DocumentPaymentDate"
import { DueDateNotifications } from "./DueDateNotifications"

const messages = defineMessages({
    paymentDetails: {
        id: "payment.document.paymentDetails.paymentDetails",
        defaultMessage: "Payment details",
    },
    paymentDate: {
        id: "payment.document.paymentDetails.paymentDate",
        defaultMessage: "Payment date",
    },
    scheduledPaymentDate: {
        id: "payment.document.paymentDetails.scheduledPaymentDate",
        defaultMessage: "Scheduled payment date",
    },
    duePaymentDate: {
        id: "payment.document.paymentDetails.duePaymentDate",
        defaultMessage: "Payment due date",
    },
})

interface PaymentDatesProps {
    payment: Payment
    loading: boolean
    dueDate?: string | null
    hasPayementTerms: boolean
    isSoonerDueDate: boolean
    onPaymentDateUpdate: (paymentData: Partial<Payment>) => void
}

export const PaymentDates = ({
    payment,
    loading,
    dueDate,
    hasPayementTerms,
    isSoonerDueDate,
    onPaymentDateUpdate,
}: PaymentDatesProps) => {
    const { formatMessage } = useIntl()

    if (!payment?.id) {
        return null
    }

    return (
        <Card title={formatMessage(messages.paymentDetails)}>
            <Stack spacing={2}>
                {payment?.status === PaymentStatus.EXECUTED && (
                    <DocumentPaymentDate
                        name="date_validated"
                        loading={loading}
                        label={formatMessage(messages.paymentDate)}
                        value={payment.date_validated}
                        onPaymentUpdate={onPaymentDateUpdate}
                    />
                )}
                {payment?.status === PaymentStatus.SCHEDULED && (
                    <DocumentPaymentDate
                        name="date_scheduled"
                        loading={loading}
                        label={formatMessage(messages.scheduledPaymentDate)}
                        value={payment.date_scheduled}
                        onPaymentUpdate={onPaymentDateUpdate}
                    />
                )}
                {payment?.status === PaymentStatus.DRAFT && (
                    <>
                        <DocumentPaymentDate
                            name="value_date_expected"
                            loading={loading}
                            label={formatMessage(messages.duePaymentDate)}
                            value={payment.value_date_expected}
                            onPaymentUpdate={onPaymentDateUpdate}
                        />
                        <DocumentPaymentTerm loading={loading} paymentId={payment.id} />
                    </>
                )}
                {hasPayementTerms && dueDate && <DueDateNotifications isSooner={isSoonerDueDate} />}
            </Stack>
        </Card>
    )
}
