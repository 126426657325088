import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    CardContent,
    Grid,
    Paper,
    Stack,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material"
import dayjs from "dayjs"
import { useEffect, useMemo, useState } from "react"
import { ChevronDown } from "react-feather"
import { MessageDescriptor, defineMessages } from "react-intl"
import { generatePath, useParams } from "react-router-dom"

import { commonMessages } from "~/common-messages"
import { Card, Chip, HeaderH1, ItemLabel, ProgressBar, SafeFormattedMessage, TitlePlacement } from "~/components"
import {
    useGetCustomFormQuery,
    useGetCustomFormStatsBySurveyQuery,
    useGetSurveyAnswersQuery,
    useGetSurveyMetadataQuery,
} from "~/domains/identity/custom-forms/api/customFormsApi"
import { CustomFormsAnswerFile } from "~/domains/identity/custom-forms/components/CustomFormsAnswerFile"
import { CUSTOM_FORMS_FORM, CUSTOM_FORMS_ROUTE } from "~/domains/identity/custom-forms/routes"
import {
    AnswerFieldType,
    CustomFormSurveyStatusEnum,
    QuestionValue,
} from "~/domains/identity/custom-forms/types/CustomForms"
import { useRedirectIfOrganizationChanged } from "~/domains/identity/organization/hooks"
import { PARTNER_SENT_FORMS_ROUTE } from "~/domains/identity/partners/routes"
import { useAppSelector } from "~/store/hooks"
import { fetchAndDecodeOrganization } from "~/store/organization/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { OrganizationI } from "~/types"

const statusMessages: Record<CustomFormSurveyStatusEnum, MessageDescriptor> = defineMessages({
    [CustomFormSurveyStatusEnum.InProgress]: {
        id: "common.status.inProgress",
        defaultMessage: "In progress",
    },
    [CustomFormSurveyStatusEnum.Completed]: {
        id: "common.status.completed",
        defaultMessage: "Completed",
    },
    [CustomFormSurveyStatusEnum.Created]: {
        id: "common.status.created",
        defaultMessage: "Created",
    },
})

const messages: Record<string, MessageDescriptor> = defineMessages({
    surveyResults: {
        id: "smartForms.surveyResults",
        defaultMessage: "Survey results",
    },
    organization: {
        id: "smartForms.organization",
        defaultMessage: "Organization",
    },
    respondents: {
        id: "smartForms.respondents",
        defaultMessage: "Respondents",
    },
    metrics: {
        id: "smartForms.tabHeader.metrics",
        defaultMessage: "Metrics",
    },
    totalScore: {
        id: "smartForms.totalScore",
        defaultMessage: "Total score",
    },
    question: {
        id: "smartForms.question",
        defaultMessage: "Question",
    },
    answer: {
        id: "smartForms.answer",
        defaultMessage: "Answer",
    },
    answers: {
        id: "smartForms.answers",
        defaultMessage: "Answers",
    },
    alertingrScore: {
        id: "smartForms.surveyScore",
        defaultMessage: "Alerting score",
    },
})

const SURVEY_URL = `${import.meta.env.VITE_APP_URL}custom-forms/survey/`

const getAnswerContent = (answerContent: QuestionValue, fieldType: AnswerFieldType) => {
    if (Array.isArray(answerContent)) {
        return <strong>{answerContent.join(", ")}</strong>
    }

    if (fieldType === AnswerFieldType.DateFieldAnswer) {
        return <strong>{dayjs(answerContent as string).format("DD/MM/YYYY")}</strong>
    }

    if (typeof answerContent === "object" && answerContent !== null && "documentId" in answerContent) {
        return <CustomFormsAnswerFile answerContent={answerContent} />
    }

    if (typeof answerContent === "boolean") {
        return answerContent ? (
            <strong>
                <SafeFormattedMessage {...commonMessages.radioYes} />
            </strong>
        ) : (
            <strong>
                <SafeFormattedMessage {...commonMessages.radioNo} />
            </strong>
        )
    }

    if (typeof answerContent === "string") {
        return <strong>{answerContent}</strong>
    }

    return answerContent?.toString()
}

export const CustomFormSurveyResults = () => {
    const [partnerName, setPartnerName] = useState<string>()
    const { formId, surveyId, partnerOrganisationId } = useParams()
    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
    useRedirectIfOrganizationChanged(currentOrganizationId, generatePath(CUSTOM_FORMS_ROUTE))

    const { data: form } = useGetCustomFormQuery(
        {
            organisationId: currentOrganizationId!,
            formId: formId!,
        },
        {
            skip: !currentOrganizationId || !formId,
        }
    )

    const { data: surveyAnswers } = useGetSurveyAnswersQuery(
        {
            organizationId: currentOrganizationId!,
            formId: formId!,
            surveyId: surveyId!,
        },
        {
            skip: !currentOrganizationId || !formId || !surveyId,
        }
    )

    const { data: surveyStats } = useGetCustomFormStatsBySurveyQuery(
        {
            organizationId: currentOrganizationId!,
            formId: formId!,
            surveyId: surveyId!,
        },
        {
            skip: !currentOrganizationId || !formId || !surveyId,
        }
    )

    const { data: surveyMetadata } = useGetSurveyMetadataQuery(
        {
            organizationId: currentOrganizationId!,
            formId: formId!,
            surveyId: surveyId!,
        },
        {
            skip: !currentOrganizationId || !formId || !surveyId,
        }
    )

    const sections = useMemo(() => {
        if (!form || !surveyAnswers || !surveyStats) {
            return []
        }

        return form.sections.map((section) => ({
            ...section,
            score: surveyStats?.metrics?.sections.find((s) => s.sectionId === section.id)?.metric.value,
            questions: section.questions.map((question) => {
                const answer = surveyAnswers.find((a) => a.questionId === question.id)
                return {
                    ...question,
                    savedValue: answer?.value,
                    visible: answer ? answer.visible : true,
                    fieldType: answer?.fieldType,
                    score: surveyStats?.metrics?.questions.find((q) => q.questionId === question.id)?.metric.value,
                }
            }),
        }))
    }, [form, surveyAnswers, surveyStats])

    useEffect(() => {
        if (!surveyMetadata?.respondentOrganizationId) {
            return
        }

        const fetchPartnerName = async () => {
            const partnerOrganization: OrganizationI = await fetchAndDecodeOrganization(
                surveyMetadata.respondentOrganizationId
            )
            setPartnerName(partnerOrganization.name)
        }

        fetchPartnerName()
    }, [surveyMetadata])

    const backLink = partnerOrganisationId
        ? generatePath(PARTNER_SENT_FORMS_ROUTE, { organizationId: partnerOrganisationId })
        : generatePath(CUSTOM_FORMS_FORM, { formId: formId || "" })

    return (
        <div>
            <HeaderH1 backLink={backLink} title={<SafeFormattedMessage {...messages.surveyResults} />} />
            <Box data-testid="box" className="main-box">
                <Grid container spacing={3} justifyContent="stretch" flexDirection={{ xs: "column", sm: "row" }}>
                    <Grid item xs={12} md={8}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6" marginBottom={1}>
                                    <SafeFormattedMessage {...messages.answers} />
                                </Typography>
                                {sections?.map((section) => (
                                    <Accordion key={section.id}>
                                        <AccordionSummary
                                            expandIcon={<ChevronDown />}
                                            aria-controls={`panel-${section.title}-content`}
                                            id={`panel-${section.title}-header`}
                                        >
                                            <Stack direction="row" gap={2} justifyContent="space-between" width="100%">
                                                <Typography>
                                                    <strong>{section.title}</strong>
                                                </Typography>
                                                <Typography marginRight={2}>
                                                    {section.score !== undefined && (
                                                        <>
                                                            {surveyStats?.metrics?.label || (
                                                                <SafeFormattedMessage {...messages.metrics} />
                                                            )}
                                                            : {section.score}
                                                        </>
                                                    )}
                                                </Typography>
                                            </Stack>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell width="45%">
                                                                <SafeFormattedMessage {...messages.question} />
                                                            </TableCell>
                                                            <TableCell width="45%">
                                                                <SafeFormattedMessage {...messages.answer} />
                                                            </TableCell>
                                                            <TableCell width="10%">
                                                                {surveyStats?.metrics?.label || (
                                                                    <SafeFormattedMessage {...messages.metrics} />
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    {section.questions.map((question) => {
                                                        return (
                                                            <TableRow
                                                                key={question.id}
                                                                sx={{
                                                                    backgroundColor: question.visible
                                                                        ? "var(--color-white) !important"
                                                                        : "var(--color-grey-lighter) !important",
                                                                }}
                                                            >
                                                                <TableCell>{question.questionContent}</TableCell>
                                                                <TableCell>
                                                                    {getAnswerContent(
                                                                        question.savedValue as QuestionValue,
                                                                        question.fieldType as AnswerFieldType
                                                                    )}
                                                                </TableCell>
                                                                <TableCell>{question.score}</TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {form?.metrics?.enabled && (
                            <Card sx={{ marginBottom: 3 }}>
                                <Typography variant="h6">
                                    {surveyStats?.metrics?.label || <SafeFormattedMessage {...messages.metrics} />}:{" "}
                                    <strong>{surveyStats?.metrics?.total?.value}</strong>
                                </Typography>
                            </Card>
                        )}
                        <Card>
                            <Typography variant="h6">{partnerName}</Typography>
                            <Stack gap={2} sx={{ maxWidth: "400px" }}>
                                {surveyMetadata?.status?.status === CustomFormSurveyStatusEnum.InProgress ? (
                                    <ProgressBar
                                        title={`${surveyMetadata?.status?.percentage}%`}
                                        titlePlacement={TitlePlacement.TOP}
                                        values={[
                                            {
                                                percentage: surveyMetadata?.status?.percentage / 100,
                                                color: "var(--color-green)",
                                            },
                                        ]}
                                        className="w-full"
                                    />
                                ) : (
                                    <Stack direction="row" gap={2} marginTop={1}>
                                        <Chip
                                            variant={
                                                surveyMetadata?.status?.status === CustomFormSurveyStatusEnum.Completed
                                                    ? "success"
                                                    : "neutral"
                                            }
                                        >
                                            <SafeFormattedMessage
                                                {...statusMessages[
                                                    surveyMetadata?.status?.status as CustomFormSurveyStatusEnum
                                                ]}
                                            />
                                        </Chip>
                                    </Stack>
                                )}
                                {surveyMetadata?.status?.status !== CustomFormSurveyStatusEnum.Completed ? (
                                    <Typography variant="body2">
                                        <a
                                            href={`${SURVEY_URL}${surveyMetadata?.token}`}
                                            target="_blank"
                                            className="text-link"
                                            rel="noopener noreferrer"
                                        >
                                            {form?.name}
                                        </a>
                                    </Typography>
                                ) : null}
                                <Stack direction="column" gap={2}>
                                    <Typography variant="body1">
                                        <SafeFormattedMessage {...commonMessages.createdDate} />:{" "}
                                        <ItemLabel data-testid="created-date">
                                            {dayjs(surveyMetadata?.createdAt).format("DD/MM/YYYY")}
                                        </ItemLabel>
                                    </Typography>
                                    <Typography variant="body1">
                                        <SafeFormattedMessage {...commonMessages.updatedDate} />:{" "}
                                        <ItemLabel data-testid="updated-date">
                                            {dayjs(surveyMetadata?.updatedAt).format("DD/MM/YYYY")}
                                        </ItemLabel>
                                    </Typography>
                                    {surveyMetadata?.respondentContacts?.length ? (
                                        <>
                                            <SafeFormattedMessage {...messages.respondents} />:
                                            {surveyMetadata?.respondentContacts?.map((respondent) => {
                                                if (!respondent.email) {
                                                    return null
                                                }
                                                return (
                                                    <Typography key={respondent.userId} variant="body1">
                                                        <a key={respondent.userId} href={`mailto:${respondent.email}`}>
                                                            <u>{respondent.email}</u>
                                                        </a>
                                                    </Typography>
                                                )
                                            })}
                                        </>
                                    ) : null}
                                </Stack>
                            </Stack>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}
