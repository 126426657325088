import { generatePath, useNavigate } from "react-router-dom"

import { DocumentPayment } from "~/domains/payment"
import { INVOICE_EDIT_ROUTE } from "~/domains/transactions/invoices-v1/routes"
import { CurrencyCodes, InvoiceI } from "~/types"

interface InvoicePaymentProps {
    invoice: InvoiceI
}

export const InvoicePayment = ({ invoice }: InvoicePaymentProps) => {
    const navigate = useNavigate()
    const id = invoice?.id

    const documentData = {
        number: invoice?.reference ?? "",
        total: {
            amount: invoice?.total || 0,
            currency: invoice?.currency ?? CurrencyCodes.EUR,
        },
        dueDate: invoice?.dueDate,
        issueDate: invoice?.issueDate,
        iban: invoice?.paymentDetails?.iban,
        accountNumber: invoice?.paymentDetails?.accountNumber,
        paymentReferenceNumber: invoice?.paymentReferenceNumber ?? null,
    }

    const handlePaymentUpdate = (invoiceId: string): void => {
        if (invoiceId) {
            navigate(generatePath(INVOICE_EDIT_ROUTE, { invoiceId }))
        }
    }

    if (!id) {
        return null
    }

    return (
        <DocumentPayment
            paymentBlocked={false}
            documentId={id}
            documentData={documentData}
            onPaymentUpdate={handlePaymentUpdate}
        />
    )
}
