import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import React, { useCallback } from "react"
import { X as CloseMenu, Menu as HamburgerMenu } from "react-feather"
import { defineMessages } from "react-intl"

import { Button, SafeFormattedMessage } from "~/components"
import { authActions } from "~/store/auth/authSlice"
import { globalActions, selectDisplayMenu } from "~/store/global/globalSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"

const messages = defineMessages({
    buttonLogin: {
        id: "header.buttonLogin",
        defaultMessage: "Sign in",
    },
})

interface UserActionsProps {
    isConnected: boolean
}

export const UserActions = ({ isConnected }: UserActionsProps) => {
    const theme = useTheme()
    const dispatch = useAppDispatch()
    const displayMenu = useAppSelector(selectDisplayMenu)

    const isMobile = useMediaQuery(theme.breakpoints.down("lg"))

    const handleDisplayHamburgerMenu = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        dispatch(globalActions.setDisplayMenu())
    }
    const handleLogin = useCallback(() => {
        dispatch(authActions.openPopupSignIn())
    }, [dispatch])

    return (
        <Stack direction="row" alignItems="center" gap={1}>
            {isConnected ? null : (
                <Button type="primary" onClick={handleLogin}>
                    <Typography component="span" noWrap>
                        <SafeFormattedMessage {...messages.buttonLogin} />
                    </Typography>
                </Button>
            )}

            {isMobile && (
                <Button type="transparent" size="small" onClick={handleDisplayHamburgerMenu}>
                    {!displayMenu ? <HamburgerMenu size={22} /> : <CloseMenu size={22} />}
                </Button>
            )}
        </Stack>
    )
}
