import { BaseEdge, EdgeProps, getBezierPath } from "@xyflow/react"

import { useRunNodeStatus } from "~/domains/orchestration/flows/hooks"

export const EdgeWithoutLabel = ({
    source,
    target,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
}: EdgeProps) => {
    const [edgePath] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    })
    const { isInPath: isSourceInPath, nextInPath } = useRunNodeStatus(source)
    const { isInPath: isTargetInPath } = useRunNodeStatus(target)

    const isNextInPath = nextInPath === target

    const isInPath = isSourceInPath && isTargetInPath && isNextInPath

    const color = isInPath ? "var(--color-green)" : "var(--color-primary)"

    return (
        <>
            {isInPath && (
                <circle r="10" fill={color}>
                    <animateMotion dur="2s" repeatCount="indefinite" path={edgePath} />
                </circle>
            )}
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={{ ...style, stroke: color }} />
        </>
    )
}
