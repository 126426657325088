import { useEffect, useState } from "react"
import { Download } from "react-feather"
import CsvDownloadButton from "react-json-to-csv"

import { SafeFormattedMessage, TooltipConditional } from "~/components"
import { bulkActionsMessages } from "~/components/DataTable/helpers/invoices"
import { useGetBulkObjectsTags } from "~/domains/analytics/tags/hooks/useGetBulkObjectsTags"
import { permissionMessages } from "~/domains/identity/roles-permissions/utils/permissions"
import { useGetInvoicesByIdsQuery } from "~/domains/transactions/invoices-v1/api/invoiceApi"
import { formatInvoiceForCSVExport } from "~/domains/transactions/invoices/core/formatInvoiceForCSVExport"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { DocumentI, DocumentIWithTotalTax } from "~/types"

interface InvoicesDownloadCSVProps {
    selected: string[]
    onSuccess: () => void
}

export const InvoicesDownloadCSV = ({ selected }: InvoicesDownloadCSVProps) => {
    const [invoicesAsCSV, setInvoicesAsCSV] = useState<DocumentIWithTotalTax[]>([])

    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)

    const { data: invoicesByIds } = useGetInvoicesByIdsQuery({
        organizationId: currentOrganizationId || "",
        ids: selected,
    })

    const { getBulkObjectsTags } = useGetBulkObjectsTags(currentOrganizationId || "")

    useEffect(() => {
        const fetchCSVData = async () => {
            if (!invoicesByIds?.length) return

            const invoicesObjectsTags = await getBulkObjectsTags(
                invoicesByIds.map((d) => d.invoiceId),
                true
            )

            const results = await Promise.allSettled(
                invoicesByIds.map(async (invoice) =>
                    formatInvoiceForCSVExport(invoice as unknown as DocumentI, invoicesObjectsTags)
                )
            )

            const invoicesFormatted = results.reduce((acc, result) => {
                if (result.status === "fulfilled") {
                    acc.push(result.value)
                }
                return acc
            }, [] as DocumentIWithTotalTax[])

            setInvoicesAsCSV(invoicesFormatted)
        }

        fetchCSVData()
    }, [invoicesByIds, getBulkObjectsTags])

    const hasPermission = true

    return (
        <>
            <TooltipConditional
                condition={!hasPermission}
                title={<SafeFormattedMessage {...permissionMessages.errorNoAccessAdministrator} />}
            >
                <div>
                    <CsvDownloadButton
                        data={invoicesAsCSV}
                        className="flowie-button type-primary-light"
                        filename="invoices.csv"
                    >
                        <SafeFormattedMessage {...bulkActionsMessages.downloadCsv} />
                        <Download size={16} />
                    </CsvDownloadButton>
                </div>
            </TooltipConditional>
        </>
    )
}
