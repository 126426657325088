import { MenuItem, TextField } from "@mui/material"
import React, { ChangeEvent, useEffect, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Button, Modal, SafeFormattedMessage, Size } from "~/components"
import TrimmedTextField from "~/components/Form/TrimmedTextField"
import {
    CREATION_ID,
    CreatePartnerProfileContactI,
    CreatePartnershipPayloadContact,
    OCR_ID,
    PartnerProfileContactI,
} from "~/domains/identity/partners/types"
import { LanguageCode, languageCodeMessages } from "~/types/LanguageCode"

const messages = defineMessages({
    titleNew: { id: "partners.bookofrelations.modalContact.titleNew", defaultMessage: "New contact" },
    titleUpdate: { id: "partners.bookofrelations.modalContact.titleUpdate", defaultMessage: "Update contact" },
    lastName: { id: "partners.bookofrelations.contact.lastName", defaultMessage: "Last name" },
    firstName: { id: "partners.bookofrelations.contact.firstName", defaultMessage: "First name" },
    email: { id: "partners.bookofrelations.contact.email", defaultMessage: "Email" },
    phoneNumber: { id: "partners.bookofrelations.contact.phoneNumber", defaultMessage: "Phone number" },
    position: { id: "partners.bookofrelations.contact.position", defaultMessage: "Position" },
    language: { id: "partners.bookofrelations.contact.language", defaultMessage: "Language" },
    timezone: { id: "partners.bookofrelations.contact.timezone", defaultMessage: "Timezone" },
    save: { id: "partners.bookofrelations.contact.save", defaultMessage: "Add a contact" },
    update: { id: "partners.bookofrelations.contact.update", defaultMessage: "Update contact" },
    cancel: { id: "common.cancel", defaultMessage: "Cancel" },
})

const timezones = Intl.supportedValuesOf("timeZone")

const isExistingContact = (contact: CreatePartnerProfileContactI | PartnerProfileContactI | null | undefined) =>
    contact && "id" in contact && contact.id !== OCR_ID && contact.id !== CREATION_ID

interface Props {
    save: (payload: CreatePartnerProfileContactI | PartnerProfileContactI) => void
    open: boolean
    close: () => void
    contact?: CreatePartnerProfileContactI | PartnerProfileContactI | null
    requiredFields?: string[]
    label?: string
    modalMode?: boolean
    onChange?: (contact: CreatePartnershipPayloadContact) => void
    formInvalid?: boolean
}

// eslint-disable-next-line complexity
export const ModalContact: React.FC<Props> = ({
    save,
    open,
    close,
    contact,
    requiredFields,
    label,
    modalMode = true,
    onChange,
    formInvalid = false,
}) => {
    const { formatMessage } = useIntl()
    const [formData, setFormData] = useState(contact)
    const isNew = !isExistingContact(contact)

    useEffect(() => {
        if (contact) {
            setFormData(contact)
        }
    }, [contact])

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target

        const updatedData = {
            ...formData,
            [name]: value,
        }

        setFormData(updatedData)
        onChange?.(updatedData as CreatePartnershipPayloadContact)
    }

    const onSubmit = () => {
        if (isNew) {
            const payload: CreatePartnerProfileContactI | PartnerProfileContactI = { ...formData }
            save(payload)
        } else {
            save({ ...contact, ...formData })
        }
        close()
    }

    const formContent = (
        <form>
            <Modal.Content>
                <TextField
                    id="contact.lastName"
                    name="lastName"
                    className="mb-12"
                    label={formatMessage(messages.lastName)}
                    placeholder={formatMessage(messages.lastName)}
                    onChange={handleChange}
                    value={formData?.lastName}
                    fullWidth
                    required={requiredFields?.includes("lastName")}
                    error={requiredFields?.includes("lastName") && !formData?.lastName && formInvalid}
                />
                <TextField
                    id="contact.firstName"
                    name="firstName"
                    className="mb-12"
                    label={formatMessage(messages.firstName)}
                    placeholder={formatMessage(messages.firstName)}
                    onChange={handleChange}
                    value={formData?.firstName}
                    fullWidth
                    required={requiredFields?.includes("firstName")}
                    error={requiredFields?.includes("firstName") && !formData?.firstName && formInvalid}
                />
                <TrimmedTextField
                    id="contact.email"
                    name="email"
                    className="mb-12"
                    label={formatMessage(messages.email)}
                    placeholder={formatMessage(messages.email)}
                    onChange={handleChange}
                    value={formData?.email ?? ""}
                    fullWidth
                    type="email"
                    required={requiredFields?.includes("email")}
                    error={requiredFields?.includes("email") && !formData?.email && formInvalid}
                />
                <TextField
                    id="contact.phoneNumber"
                    name="phoneNumber"
                    className="mb-12"
                    label={formatMessage(messages.phoneNumber)}
                    placeholder={formatMessage(messages.phoneNumber)}
                    onChange={handleChange}
                    value={formData?.phoneNumber ?? ""}
                    fullWidth
                    required={requiredFields?.includes("phoneNumber")}
                    error={requiredFields?.includes("phoneNumber") && !formData?.phoneNumber && formInvalid}
                />
                <TextField
                    id="contact.position"
                    name="position"
                    className="mb-12"
                    label={formatMessage(messages.position)}
                    placeholder={formatMessage(messages.position)}
                    onChange={handleChange}
                    value={formData?.position ?? ""}
                    fullWidth
                    required={requiredFields?.includes("position")}
                    error={requiredFields?.includes("position") && !formData?.position && formInvalid}
                />
                <TextField
                    select
                    id="contact.language"
                    name="language"
                    className="mb-12"
                    label={formatMessage(messages.language)}
                    placeholder={formatMessage(messages.language)}
                    onChange={handleChange}
                    value={formData?.language ?? ""}
                    fullWidth
                    required={requiredFields?.includes("language")}
                    error={requiredFields?.includes("language") && !formData?.language && formInvalid}
                >
                    {Object.values(LanguageCode).map((language) => (
                        <MenuItem key={language} value={language}>
                            {formatMessage(languageCodeMessages[language])}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    select
                    id="contact.timezone"
                    name="timezone"
                    className="mb-12"
                    label={formatMessage(messages.timezone)}
                    placeholder={formatMessage(messages.timezone)}
                    onChange={handleChange}
                    value={formData?.timezone ?? ""}
                    fullWidth
                    required={requiredFields?.includes("timezone")}
                    error={requiredFields?.includes("timezone") && !formData?.timezone && formInvalid}
                >
                    {timezones.map((tz) => (
                        <MenuItem key={tz} value={tz}>
                            {tz}
                        </MenuItem>
                    ))}
                </TextField>
            </Modal.Content>
        </form>
    )

    if (!modalMode) {
        return formContent
    }

    return (
        <Modal open={open} size={Size.MD}>
            <Modal.Header>
                <h4>{label ?? <SafeFormattedMessage {...(isNew ? messages.titleNew : messages.titleUpdate)} />}</h4>
            </Modal.Header>
            {formContent}
            <Modal.Footer>
                <Button type="neutral" onClick={close}>
                    <SafeFormattedMessage {...messages.cancel} />
                </Button>
                <Button type="primary" onClick={onSubmit}>
                    <SafeFormattedMessage {...(isNew ? messages.save : messages.update)} />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
