import { FileMinus } from "react-feather"

import { RefusePurchaseOrderConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { RegularNode } from "~/domains/orchestration/flows/components/nodes"
import { adaptEntityTriggerProperties } from "~/domains/orchestration/flows/core"
import { adaptEventTriggerProperties } from "~/domains/orchestration/flows/core"
import { isEventTriggerType } from "~/domains/orchestration/flows/types"
import { Trigger } from "~/domains/orchestration/flows/types"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    EditorNode,
    Node,
    NodeType,
    RefusePurchaseOrderNode as RefusePurchaseOrderNodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const refusePurchaseOrderNode = (trigger: Trigger): Omit<RefusePurchaseOrderNodeType, keyof Node> => {
    const { objectId } = isEventTriggerType(trigger)
        ? adaptEventTriggerProperties(trigger.type, trigger.slug)
        : adaptEntityTriggerProperties(trigger.type, trigger.slug)
    return {
        purchaseOrderId: objectId,
        nextNode: null,
        type: NodeType.REFUSE_PURCHASE_ORDER_NODE,
    }
}

const advancedFields: AllAdvancedFieldsUnion[] = [AllAdvancedFieldsUnion.PURCHASE_ORDER_ID]

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.REFUSE_PURCHASE_ORDER_NODE) return false
    return Boolean(node.purchaseOrderId)
}

const refusePurchaseOrderConfig: Configuration<NodeType.REFUSE_PURCHASE_ORDER_NODE> = {
    type: NodeType.REFUSE_PURCHASE_ORDER_NODE,
    baseSlug: "refuse-purchase-order",
    Icon: FileMinus,
    Configuration: RefusePurchaseOrderConfiguration,
    advancedFields,
    Node: RegularNode,
    factory: (props: FactoryProps): RefusePurchaseOrderNodeType => ({
        ...refusePurchaseOrderNode(props.trigger),
        ...createCommonNodeProperties({ ...props, error: false }),
    }),
    validateNode,
}

export { refusePurchaseOrderConfig, advancedFields as refusePurchaseOrderAdvancedFields }
