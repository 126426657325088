/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Stack, ToggleButton, ToggleButtonGroup } from "@mui/material"
import {
    DataGridPremium,
    GridColDef,
    GridPaginationModel,
    GridRenderCellParams,
    GridValueGetterParams,
} from "@mui/x-data-grid-premium"
import { useState } from "react"
import { FormattedDate, FormattedNumber, defineMessages } from "react-intl"

import { commonMessages } from "~/common-messages"
import { Card, Loader, SafeFormattedMessage, StatusChip } from "~/components"
import { PartyPaymentMethodDetails } from "~/domains/payment/payment-method-details/components"
import { useGetPaymentsByOrganizationQuery } from "~/domains/payment/payment/api/paymentApi"
import { PaymentLineActions, PaymentLinkedBatch, PaymentLinkedTransaction } from "~/domains/payment/payment/components"
import { PaymentRole, PaymentUI } from "~/domains/payment/payment/types"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "~/utils"

const messages = defineMessages({
    myPaymentMethod: {
        id: "payment.document.paymentDetails.buyerPaymentMethod",
        defaultMessage: "My payment method",
    },
    vendorPaymentMethod: {
        id: "payment.document.paymentDetails.vendorPaymentMethod",
        defaultMessage: "Vendor payment method",
    },
    paymentsDue: {
        id: "payment.document.paymentsDue",
        defaultMessage: "Payments due",
    },
    paymentsReceived: {
        id: "payment.document.paymentsReceived",
        defaultMessage: "Payments received",
    },
})

const getRowId = (row: PaymentUI) => row?.id ?? ""

const renderPaymentMethodCell = ({ value }: GridRenderCellParams<PaymentUI>) => {
    if (!value) {
        return "-"
    }

    return <PartyPaymentMethodDetails mode="inline" paymentMethodDetailsId={value} />
}

const columns: GridColDef[] = [
    {
        field: "id",
        minWidth: 120,
        flex: 1,
        renderHeader: () => <SafeFormattedMessage {...commonMessages.transaction} />,
        renderCell: ({ row: { id, paymentMetadata } }: GridRenderCellParams<PaymentUI>) => (
            <PaymentLinkedTransaction paymentId={id} transactionNumber={paymentMetadata?.invoiceNumber} />
        ),
    },
    {
        field: "amountSentData",
        minWidth: 120,

        valueGetter: ({ row }: GridValueGetterParams<PaymentUI>) => [
            row.amountSentData?.amount,
            row.amountSentData?.currency,
        ],
        renderHeader: () => <SafeFormattedMessage {...commonMessages.totalAmount} />,
        renderCell: ({ value: [amount, currency] }: GridRenderCellParams<PaymentUI>) =>
            !amount ? "-" : <FormattedNumber value={amount} style="currency" currency={currency} />,
    },
    {
        field: "status",
        minWidth: 120,
        renderHeader: () => <SafeFormattedMessage {...commonMessages.status} />,
        renderCell: ({ value }: GridRenderCellParams<PaymentUI>) =>
            value ? <StatusChip status={value?.toUpperCase()}>{value?.toUpperCase()}</StatusChip> : "-",
    },
    {
        field: "batch",
        flex: 1,
        minWidth: 120,
        renderHeader: () => <SafeFormattedMessage {...commonMessages.batch} />,
        renderCell: ({ row }: GridRenderCellParams<PaymentUI>) => <PaymentLinkedBatch row={row} />,
    },
    {
        field: "dateCreated",
        minWidth: 120,
        renderHeader: () => <SafeFormattedMessage {...commonMessages.createdDate} />,
        renderCell: ({ row: { dateCreated } }: GridRenderCellParams<PaymentUI>) =>
            dateCreated ? (
                <FormattedDate value={dateCreated ?? ""} day="numeric" month="numeric" year="numeric" />
            ) : (
                "-"
            ),
    },
    {
        field: "dateScheduled",
        minWidth: 120,
        renderHeader: () => <SafeFormattedMessage {...commonMessages.dateScheduled} />,
        renderCell: ({ row: { dateScheduled } }: GridRenderCellParams<PaymentUI>) =>
            dateScheduled ? (
                <FormattedDate value={dateScheduled ?? ""} day="numeric" month="numeric" year="numeric" />
            ) : (
                "-"
            ),
    },
    {
        field: "originCashId",
        minWidth: 120,
        flex: 1,
        renderHeader: () => <SafeFormattedMessage {...messages.myPaymentMethod} />,
        renderCell: renderPaymentMethodCell,
    },
    {
        field: "destinationCashId",
        minWidth: 120,
        flex: 1,
        renderHeader: () => <SafeFormattedMessage {...messages.vendorPaymentMethod} />,
        renderCell: renderPaymentMethodCell,
    },
    {
        field: "actions",
        sortable: false,
        filterable: false,
        resizable: false,
        disableColumnMenu: true,
        align: "center",
        width: 60,
        renderHeader: () => "",
        renderCell: (params: GridRenderCellParams) => <PaymentLineActions row={params.row} />,
    },
]

export const PaymentsList = () => {
    const organizationId = useAppSelector(selectCurrentOrganizationId)
    const [paymentRole, setPaymentRole] = useState<PaymentRole>(PaymentRole.PAYER)
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: 0,
        pageSize: DEFAULT_PAGE_SIZE,
    })
    const { page, pageSize } = paginationModel
    const { data, isFetching, isLoading } = useGetPaymentsByOrganizationQuery(
        { organizationId, page: page + 1, pageSize, role: paymentRole, includeObjects: true },
        {
            skip: !organizationId,
        }
    )
    const payments = data?.items ?? []

    const handlePaginationModelChange = (model: GridPaginationModel) => {
        setPaginationModel(model)
    }

    const handlePaymentRoleChanged = (_: React.MouseEvent<HTMLElement>, newRole: PaymentRole) => {
        setPaymentRole((prevState) => (newRole && newRole !== prevState ? newRole : prevState))
    }

    if (!organizationId) {
        return null
    }

    if (isLoading) {
        return <Loader fullWidth />
    }

    return (
        <Stack gap={2}>
            <ToggleButtonGroup
                color="primary"
                value={paymentRole}
                disabled={isLoading}
                exclusive
                onChange={handlePaymentRoleChanged}
            >
                <ToggleButton value={PaymentRole.PAYER}>
                    <SafeFormattedMessage {...messages.paymentsDue} />
                </ToggleButton>
                <ToggleButton value={PaymentRole.PAYEE}>
                    <SafeFormattedMessage {...messages.paymentsReceived} />
                </ToggleButton>
            </ToggleButtonGroup>
            <Card>
                <DataGridPremium
                    columns={columns}
                    rows={payments}
                    getRowId={getRowId}
                    rowCount={data?.total ?? 0}
                    loading={isFetching}
                    rowSelection={false}
                    autoHeight
                    pagination
                    paginationMode="server"
                    disableRowSelectionOnClick
                    pageSizeOptions={PAGE_SIZE_OPTIONS}
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationModelChange}
                />
            </Card>
        </Stack>
    )
}
