import { LicenseInfo } from "@mui/x-license-pro"
import * as Sentry from "@sentry/react"
import { createRoot } from "react-dom/client"

import { version } from "../package.json"
import App from "./App"

const muiLicenceKey = import.meta.env.VITE_MUI_LICENCE_KEY
const env = import.meta.env.VITE_ENV

LicenseInfo.setLicenseKey(muiLicenceKey)

if (env !== "development") {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        ignoreErrors: ["Error: socket has been disconnected"],
        integrations: [Sentry.browserTracingIntegration({ tracePropagationTargets: [import.meta.env.VITE_APP_URL] })],
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        replaysSessionSampleRate: 0.0,
        replaysOnErrorSampleRate: 1.0,
        environment: env,
        release: version,
    })
}

const container = document.getElementById("root")
if (container) {
    const root = createRoot(container)
    container.setAttribute("notranslate", "true")
    container.setAttribute("translate", "no")
    root.render(<App />)
}
