import { useEffect, useState } from "react"

import { useGetObjectPermissionsQuery } from "~/domains/identity/roles-permissions/api/spiceDbApi"
import { SpiceDBObjectType, SpiceDBPermissionsLiteI } from "~/domains/identity/roles-permissions/types/SpiceDB"
import { useAppSelector } from "~/store/hooks"
import { useFetchOrganizationMembers, useFetchOrganizationTeams } from "~/store/organization/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"

interface SpiceDBStorePermissionsReturn {
    loading: boolean
    sharedPermissions?: SpiceDBPermissionsLiteI[]
    refetch: () => void
}

export const useFetchObjectPermissions = (
    objectId: string,
    objectType: SpiceDBObjectType
): SpiceDBStorePermissionsReturn => {
    const [sharedPermissionsWithNames, setSharedPermissionsWithNames] = useState<SpiceDBPermissionsLiteI[]>([])
    const currentOrganization = useAppSelector(selectCurrentOrganization)
    const [members] = useFetchOrganizationMembers(currentOrganization)
    const { teams } = useFetchOrganizationTeams(currentOrganization?.id, true)
    const {
        data: sharedPermissions,
        isLoading: loading,
        refetch,
    } = useGetObjectPermissionsQuery({ objectId, objectType, organizationId: currentOrganization?.id ?? "" })

    useEffect(() => {
        if (currentOrganization?.id && sharedPermissions && (members.length || teams.length)) {
            const userMap = new Map(members.map((user) => [user.userId, user.user]))
            const teamMap = new Map(teams.map((team) => [team.teamId, team]))

            const permissionsWithNames = sharedPermissions.reduce((acc, sharedObject) => {
                const { objectId: objId } = sharedObject
                const user = userMap.get(objId)
                const team = teamMap.get(objId)

                if (!user && !team) return acc

                return [
                    ...acc,
                    {
                        ...sharedObject,
                        name: user?.fullName ?? team?.name ?? "",
                        email: user?.email ?? undefined,
                        membersNumber: team?.members.length ?? undefined,
                    },
                ]
            }, [] as SpiceDBPermissionsLiteI[])
            setSharedPermissionsWithNames(permissionsWithNames)
        }
    }, [currentOrganization, sharedPermissions, members, teams])

    return { sharedPermissions: sharedPermissionsWithNames, loading, refetch }
}
