import { useState } from "react"
import { DollarSign } from "react-feather"

import { commonMessages } from "~/common-messages"
import { Button, SafeFormattedMessage, TooltipConditional } from "~/components"
import { ModalAddInvoicesToBudgets } from "~/domains/identity/account/components/ModalAddInvoicesToBudgets"
import { permissionMessages } from "~/domains/identity/roles-permissions/utils/permissions"
import { selectUser } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { InvoiceBackendVersion, ViewTypeI } from "~/types"

interface InvoicesAddBudgetsProps {
    selected: string[]
    onSuccess: () => void
}

export const InvoicesAddBudgets = ({ selected, onSuccess }: InvoicesAddBudgetsProps) => {
    const [displayModalAddBudgets, setDisplayModalAddBudgets] = useState(false)

    const openAddBudgetsModal = () => setDisplayModalAddBudgets(true)
    const closeAddBudgetsModal = () => setDisplayModalAddBudgets(false)

    const currentOrganization = useAppSelector(selectCurrentOrganization)
    const currentUser = useAppSelector(selectUser)

    const handleConfirmModalAddBudgets = () => {
        closeAddBudgetsModal()
        onSuccess()
    }

    const hasPermission = true

    return (
        <>
            <TooltipConditional
                condition={!hasPermission}
                title={<SafeFormattedMessage {...permissionMessages.errorNoAccessAdministrator} />}
            >
                <div>
                    <Button type="primary-light" onClick={openAddBudgetsModal}>
                        <SafeFormattedMessage {...commonMessages.budgets} /> <DollarSign size={16} />
                    </Button>
                </div>
            </TooltipConditional>

            {currentOrganization && currentUser && (
                <ModalAddInvoicesToBudgets
                    organizationId={currentOrganization.id}
                    selected={selected}
                    view={ViewTypeI.buyer}
                    open={displayModalAddBudgets}
                    close={closeAddBudgetsModal}
                    onConfirm={handleConfirmModalAddBudgets}
                    version={InvoiceBackendVersion.V1}
                />
            )}
        </>
    )
}
