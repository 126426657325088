import * as t from "io-ts"
import { MessageDescriptor } from "react-intl"

import { OrganizationId } from "~/types"
import { Opaque } from "~/utils"

// Ellipro

export enum ElliproScoreLabelI {
    JUDICIAL_LIQUIDATION = "Liquidation judiciaire",
    CESSATION = "Cessation",
    FOREIGN_HEADQUARTERS = "Siège à l'étranger",
}

const ElliproComplianceRiskIO = t.type({
    id: t.string,
    score: t.union([t.number, t.string]),
    scoreLabel: t.string,
    shortScoreLabel: t.string,
    riskClassification: t.string,
    lastUpdateDate: t.string,
    monitoringStartDate: t.string,
})

const ApiElliproComplianceRiskIO = t.intersection([
    ElliproComplianceRiskIO,
    t.type({
        createdAt: t.string,
        updatedAt: t.string,
        organizationId: t.string,
    }),
])

const ApiPatchElliproComplianceRiskIO = t.intersection([
    t.type({
        id: t.string,
    }),
    t.partial({
        score: t.number,
        scoreLabel: t.string,
        shortScoreLabel: t.string,
        riskClassification: t.string,
        lastUpdateDate: t.string,
    }),
])

export type ElliproProcessId = Opaque<string, { readonly T: unique symbol }>
export type ElliproComplianceRiskI = t.TypeOf<typeof ElliproComplianceRiskIO> & {
    id: ElliproProcessId
}
export type ApiElliproComplianceRiskI = t.TypeOf<typeof ApiElliproComplianceRiskIO>
export type ApiPatchElliproComplianceRiskI = t.TypeOf<typeof ApiPatchElliproComplianceRiskIO>

export const ElliproComplianceRiskFields = [
    // "score",
    // "scoreLabel",
    "shortScoreLabel",
    // "riskClassification",
    // "lastUpdateDate",
    "monitoringStartDate",
] as const
export type ElliproComplianceRiskFieldsEnum = (typeof ElliproComplianceRiskFields)[number]

export const ElliproRiskClassificationFields = [
    "Risque faible à quasi nul",
    "Risque moyen à faible",
    "Risque moyen à élevé",
    "Risque très élevé",
    "Risque avéré",
    "Non applicable",
]
export type ElliproRiskClassificationFieldsEnum = (typeof ElliproRiskClassificationFields)[number]

// LSEG

const LsegComplianceRiskIO = t.type({
    id: t.string,
    caseCreationDate: t.string,
    // riskStatus: t.string,
    notes: t.string,
    dataSet: t.string,
    resolutionStatus: t.string,
    lastResolutionDate: t.string,
})

const ApiLsegComplianceRiskIO = t.intersection([
    LsegComplianceRiskIO,
    t.type({
        id: t.string,
        createdAt: t.string,
        updatedAt: t.string,
        organizationId: t.string,
    }),
])

const ApiPatchLsegComplianceRiskIO = t.intersection([
    t.type({
        id: t.string,
    }),
    t.partial({
        caseCreationDate: t.string,
        // riskStatus: t.string,
        notes: t.string,
        dataSet: t.string,
        resolutionStatus: t.string,
        lastResolutionDate: t.string,
    }),
])

export type LsegProcessId = Opaque<string, { readonly T: unique symbol }>
export type LsegComplianceRiskI = t.TypeOf<typeof LsegComplianceRiskIO> & {
    id: LsegProcessId
}
export type ApiLsegComplianceRiskI = t.TypeOf<typeof ApiLsegComplianceRiskIO>
export type ApiPatchLsegComplianceRiskI = t.TypeOf<typeof ApiPatchLsegComplianceRiskIO>

export const LsegComplianceRiskFields = [
    "caseCreationDate",
    // "riskStatus",
    "notes",
    "dataSet",
    "resolutionStatus",
    "lastResolutionDate",
] as const
export type LsegComplianceRiskFieldsEnum = (typeof LsegComplianceRiskFields)[number]

export const LsegRiskStatusFields = ["LOW", "MEDIUM", "HIGH", "UNKNOWN"] as const
export type LsegRiskStatusFieldsEnum = (typeof LsegRiskStatusFields)[number]

// ComplianceRisk processes
const ComplianceRiskIO = t.type({
    ellipro: ElliproComplianceRiskIO,
    lseg: LsegComplianceRiskIO,
})

export type ComplianceRiskI = t.TypeOf<typeof ComplianceRiskIO>

export enum ComplianceRiskProcess {
    ELLIPRO = "ellipro",
    LSEG = "lseg",
}

export interface ComplianceProcessReadDto {
    organizationId: OrganizationId
    complianceProcess: ComplianceRiskProcess
    complianceProcessId?: ElliproProcessId | LsegProcessId
    internalIds?: string[]
    partnerId?: string
}

export interface ComplianceProcessCreateDto {
    organizationId: OrganizationId
    partnerId: string
    complianceProcess: ComplianceRiskProcess
    complianceRiskDto: Partial<ApiPatchElliproComplianceRiskI> | Partial<ApiPatchLsegComplianceRiskI>
}

export interface ComplianceProcessUpdateDto {
    organizationId: OrganizationId
    processId: ElliproProcessId | LsegProcessId
    complianceProcess: ComplianceRiskProcess
    complianceRiskDto: Partial<ApiPatchElliproComplianceRiskI> | Partial<ApiPatchLsegComplianceRiskI>
}

// Translation messages
export const ElliproComplianceRiskMessages: Partial<Record<keyof ElliproComplianceRiskI, MessageDescriptor>> = {
    score: {
        id: "partners.bookofrelations.partnerComplianceRisk.ellipro.score",
        defaultMessage: "Score",
    },
    scoreLabel: {
        id: "partners.bookofrelations.partnerComplianceRisk.ellipro.scoreLabel",
        defaultMessage: "Score label",
    },
    shortScoreLabel: {
        id: "partners.bookofrelations.partnerComplianceRisk.ellipro.shortScoreLabel",
        defaultMessage: "Short score label",
    },
    riskClassification: {
        id: "partners.bookofrelations.partnerComplianceRisk.ellipro.riskClassification",
        defaultMessage: "Risk classification",
    },
    lastUpdateDate: {
        id: "partners.bookofrelations.partnerComplianceRisk.ellipro.lastUpdateDate",
        defaultMessage: "Last update date",
    },
    monitoringStartDate: {
        id: "partners.bookofrelations.partnerComplianceRisk.ellipro.monitoringStartDate",
        defaultMessage: "Monitoring start date",
    },
}

export const ElliproScoreLabelMessages: Record<ElliproScoreLabelI, MessageDescriptor> = {
    [ElliproScoreLabelI.JUDICIAL_LIQUIDATION]: {
        id: "partners.bookofrelations.partnerComplianceRisk.ellipro.scoreLabel.judicial-liquidation",
        defaultMessage: "Judicial Liquidation",
    },
    [ElliproScoreLabelI.CESSATION]: {
        id: "partners.bookofrelations.partnerComplianceRisk.ellipro.scoreLabel.cessation",
        defaultMessage: "Cessation",
    },
    [ElliproScoreLabelI.FOREIGN_HEADQUARTERS]: {
        id: "partners.bookofrelations.partnerComplianceRisk.ellipro.scoreLabel.foreign-headquarters",
        defaultMessage: "Foreign Headquarters",
    },
}

export const ElliproRiskClassificationMessages: Record<ElliproRiskClassificationFieldsEnum, MessageDescriptor> = {
    [ElliproRiskClassificationFields[0]]: {
        id: "partners.bookofrelations.partnerComplianceRisk.ellipro.riskClassification.low-to-almost-no-risk",
        defaultMessage: "Low to almost no risk",
    },
    [ElliproRiskClassificationFields[1]]: {
        id: "partners.bookofrelations.partnerComplianceRisk.ellipro.riskClassification.medium-to-low-risk",
        defaultMessage: "Medium to low risk",
    },
    [ElliproRiskClassificationFields[2]]: {
        id: "partners.bookofrelations.partnerComplianceRisk.ellipro.riskClassification.medium-to-high-risk",
        defaultMessage: "Medium to high risk",
    },
    [ElliproRiskClassificationFields[3]]: {
        id: "partners.bookofrelations.partnerComplianceRisk.ellipro.riskClassification.very-high-risk",
        defaultMessage: "Very high risk",
    },
    [ElliproRiskClassificationFields[4]]: {
        id: "partners.bookofrelations.partnerComplianceRisk.ellipro.riskClassification.confirmed-risk",
        defaultMessage: "Confirmed risk",
    },
    [ElliproRiskClassificationFields[5]]: {
        id: "partners.bookofrelations.partnerComplianceRisk.ellipro.riskClassification.not-applicable",
        defaultMessage: "Not applicable",
    },
}

export const LsegComplianceRiskMessages: Partial<Record<keyof LsegComplianceRiskI, MessageDescriptor>> = {
    caseCreationDate: {
        id: "partners.bookofrelations.partnerComplianceRisk.lseg.caseCreationDate",
        defaultMessage: "Case creation date",
    },
    // riskStatus: {
    //     id: "partners.bookofrelations.partnerComplianceRisk.lseg.riskStatus",
    //     defaultMessage: "Risk status",
    // },
    notes: {
        id: "partners.bookofrelations.partnerComplianceRisk.lseg.notes",
        defaultMessage: "Notes",
    },
    dataSet: {
        id: "partners.bookofrelations.partnerComplianceRisk.lseg.dataSet",
        defaultMessage: "Data set",
    },
    resolutionStatus: {
        id: "partners.bookofrelations.partnerComplianceRisk.lseg.resolutionStatus",
        defaultMessage: "Resolution status",
    },
    lastResolutionDate: {
        id: "partners.bookofrelations.partnerComplianceRisk.lseg.lastResolutionDate",
        defaultMessage: "Last resolution date",
    },
}

// export const LsegRiskStatusMessages: Record<LsegRiskStatusFieldsEnum, MessageDescriptor> = {
//     [LsegRiskStatusFields[0]]: {
//         id: "partners.bookofrelations.partnerComplianceRisk.lseg.riskStatus.low",
//         defaultMessage: "Low",
//     },
//     [LsegRiskStatusFields[1]]: {
//         id: "partners.bookofrelations.partnerComplianceRisk.lseg.riskStatus.medium",
//         defaultMessage: "Medium",
//     },
//     [LsegRiskStatusFields[2]]: {
//         id: "partners.bookofrelations.partnerComplianceRisk.lseg.riskStatus.high",
//         defaultMessage: "High",
//     },
//     [LsegRiskStatusFields[3]]: {
//         id: "partners.bookofrelations.partnerComplianceRisk.lseg.riskStatus.unknown",
//         defaultMessage: "Unknown",
//     },
// }

// config

export interface FieldConfig {
    id: string
    name: string
    type?: "text" | "select" | "date"
    options?: Array<{ value: string; label: MessageDescriptor }>
}

export const ElliproFieldConfig: Record<ElliproComplianceRiskFieldsEnum, FieldConfig> = {
    // score: {
    //     id: "ellipro-score",
    //     name: "score",
    //     type: "text",
    // },
    // scoreLabel: {
    //     id: "ellipro-score-label",
    //     name: "scoreLabel",
    //     type: "text",
    // },
    shortScoreLabel: {
        id: "ellipro-short-score-label",
        name: "shortScoreLabel",
        type: "text",
    },
    // riskClassification: {
    //     id: "ellipro-risk-classification",
    //     name: "riskClassification",
    //     type: "select",
    //     options: [
    //         {
    //             value: "",
    //             label: {
    //                 id: "partners.bookofrelations.partnerComplianceRisk.ellipro.riskClassification.none",
    //                 defaultMessage: "None",
    //             },
    //         },
    //         ...ElliproRiskClassificationFields.map((field) => ({
    //             value: field,
    //             label: ElliproRiskClassificationMessages[field],
    //         })),
    //     ],
    // },
    // lastUpdateDate: {
    //     id: "ellipro-last-update-date",
    //     name: "lastUpdateDate",
    //     type: "date",
    // },
    monitoringStartDate: {
        id: "ellipro-monitoring-start-date",
        name: "monitoringStartDate",
        type: "date",
    },
}

export const isElliproProcess = (
    process: ElliproComplianceRiskI | LsegComplianceRiskI
): process is ElliproComplianceRiskI => process && "riskClassification" in process

export const LsegFieldConfig: Record<LsegComplianceRiskFieldsEnum, FieldConfig> = {
    caseCreationDate: { id: "lseg-case-creation-date", name: "caseCreationDate", type: "date" },
    // riskStatus: {
    //     id: "lseg-risk-status",
    //     name: "riskStatus",
    //     type: "select",
    //     options: [
    //         {
    //             value: "",
    //             label: {
    //                 id: "partners.bookofrelations.partnerComplianceRisk.lseg.riskStatus.none",
    //                 defaultMessage: "None",
    //             },
    //         },
    //         ...LsegRiskStatusFields.map((field) => ({
    //             value: field,
    //             label: LsegRiskStatusMessages[field],
    //         })),
    //     ],
    // },
    notes: { id: "lseg-notes", name: "notes", type: "text" },
    dataSet: { id: "lseg-data-set", name: "dataSet", type: "text" },
    resolutionStatus: { id: "lseg-resolution-status", name: "resolutionStatus", type: "text" },
    lastResolutionDate: { id: "lseg-last-resolution-date", name: "lastResolutionDate", type: "date" },
}
