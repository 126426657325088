import { Box, IconButton, LinearProgress, Stack, TextField, Typography } from "@mui/material"
import { useCallback, useState } from "react"
import { Trash } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { commonMessages } from "~/common-messages"
import { Button, Modal, SafeFormattedMessage, Size, UploadDocumentBox } from "~/components"
import { useUploadDocument } from "~/domains/identity/documents/hooks"
import { DocumentType } from "~/domains/identity/documents/types"
import {
    useRequestManualVerificationMutation,
    useUpdatePaymentMethodDetailsMutation,
} from "~/domains/payment/payment-method-details/api/paymentMethodDetailsApi"
import { selectSelectedOrganizationId } from "~/domains/payment/payment-method-details/store/paymentMethodDetailsSlice"
import { ManualVerification, VerificationMethod } from "~/domains/payment/payment-method-details/types"
import { useAppSelector } from "~/store/hooks"

const messages = defineMessages({
    title: {
        id: "payment.paymentMethodDetailsList.manualVerification.title",
        defaultMessage: "Payment method manual verification",
    },
    addVerification: {
        id: "payment.paymentMethodDetailsList.manualVerification.addVerification",
        defaultMessage: "Add document",
    },
    uploadMessage: {
        id: "payment.paymentMethodDetailsList.manualVerification.uploadMessage",
        defaultMessage:
            "To proceed with the manual verification of your payment method, please upload the required document. Once uploaded, your document will be reviewed as part of the verification process.",
    },
    verificationDone: {
        id: "payment.paymentMethodDetailsList.manualVerification.verificationDone",
        defaultMessage: "The manual payment method verification was successfully registered.",
    },
    note: {
        id: "payment.paymentMethodDetailsList.manualVerification.note",
        defaultMessage: "Note",
    },
})

const initialState = {
    file: null,
    note: "",
}

interface ManualVerificationRequestModalProps {
    open: boolean
    paymentMethodDetailsId: string
    onClose?: () => void
}

interface FormData {
    note: string
    file: File | null
}

export const ManualVerificationRequestModal = ({
    open,
    paymentMethodDetailsId,
    onClose,
}: ManualVerificationRequestModalProps) => {
    const { formatMessage } = useIntl()
    const organizationId = useAppSelector(selectSelectedOrganizationId)
    const [formData, setFormData] = useState<FormData>(initialState)

    const { uploadDocument, loading } = useUploadDocument(organizationId ?? "", null)
    const [requestManualVerification, { isLoading: isLoadingRequest }] = useRequestManualVerificationMutation()
    const [updatePaymentMethodDetails, { isLoading: isLoadingUpdate }] = useUpdatePaymentMethodDetailsMutation()
    const isLoadingState = loading || isLoadingUpdate || isLoadingRequest

    const resetFields = () => {
        setFormData(initialState)
    }

    const handleClose = () => {
        if (onClose) {
            onClose()
        }
        resetFields()
    }

    const handleSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            event.stopPropagation()

            const { note, file } = formData

            if (!note && !file) return

            const handleVerification = async (verificationPayload: ManualVerification) => {
                const manualVerification: ManualVerification = (await requestManualVerification(
                    verificationPayload
                ).unwrap()) as ManualVerification

                if (manualVerification?.id) {
                    toast.success(formatMessage(messages.verificationDone))
                    handleClose()
                }
            }

            if (file) {
                const response = await uploadDocument(file, DocumentType.BANK_ACCOUNT_DETAILS, paymentMethodDetailsId)
                if (response?.result?.id) {
                    const { id, name, latestFileVersion } = response.result
                    const verificationPayload: ManualVerification = {
                        payment_method_details_id: paymentMethodDetailsId,
                        verification_method: VerificationMethod.DOCUMENT,
                        verification_documents: {
                            name,
                            url: latestFileVersion,
                            document_id: id,
                        },
                        ...(note && { verification_notes: note }),
                    }

                    await handleVerification(verificationPayload)
                }
            } else {
                const verificationPayload: ManualVerification = {
                    payment_method_details_id: paymentMethodDetailsId,
                    verification_method: VerificationMethod.DOCUMENT,
                    ...(note && { verification_notes: note }),
                }

                await handleVerification(verificationPayload)
            }
        },
        [
            formData,
            formatMessage,
            organizationId,
            paymentMethodDetailsId,
            requestManualVerification,
            updatePaymentMethodDetails,
            uploadDocument,
        ]
    )

    const handleSetFile = (file: File) => {
        setFormData((prevState) => ({ ...prevState, file }))
    }

    const handleClearFile = () => {
        setFormData((prevState) => ({ ...prevState, file: null }))
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target
        setFormData((prevState) => ({ ...prevState, [name]: value }))
    }

    return (
        <Modal open={open} size={Size.MD} onClose={handleClose}>
            <Modal.Header>
                <h4>
                    <SafeFormattedMessage {...messages.title} />
                </h4>
            </Modal.Header>
            <Modal.Content>
                <Box
                    component="form"
                    id="manual-verification-form"
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    onSubmit={handleSubmit}
                >
                    <Typography variant="body1" color="var(--color-grey-light)">
                        <SafeFormattedMessage {...messages.uploadMessage} />
                    </Typography>
                    <Box>
                        {formData.file ? (
                            <Stack
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="space-between"
                                color="var(--color-grey)"
                            >
                                {formData.file.name}
                                {!isLoadingState && (
                                    <IconButton onClick={handleClearFile}>
                                        <Trash size={16} />
                                    </IconButton>
                                )}
                            </Stack>
                        ) : (
                            <UploadDocumentBox handleFile={handleSetFile} />
                        )}
                    </Box>

                    <TextField
                        label={formatMessage(messages.note)}
                        fullWidth
                        multiline
                        rows={4}
                        name="note"
                        value={formData.note}
                        disabled={isLoadingState}
                        onChange={handleChange}
                    />
                    {isLoadingState && <LinearProgress />}
                </Box>
            </Modal.Content>
            <Modal.Footer>
                <Button type="transparent" disabled={isLoadingState} onClick={handleClose}>
                    <SafeFormattedMessage {...commonMessages.cancel} />
                </Button>
                <Button
                    buttonType="submit"
                    form="manual-verification-form"
                    disabled={isLoadingState || (!formData.file && !formData.note)}
                >
                    <SafeFormattedMessage {...messages.addVerification} />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
