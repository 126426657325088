import { ListItemText, MenuItem, Select, SelectChangeEvent, Tooltip } from "@mui/material"
import classNames from "classnames"
import React, { useEffect, useState } from "react"
import { Info, Trash2 } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { ConfirmModal, SafeFormattedMessage } from "~/components"
import { PermissionsMenuItems } from "~/domains/identity/roles-permissions/components/ShareObjectPermissionsModal/PermissionsSelect/PermissionsMenuItems"
import {
    SpiceDBObjectAuthorizationName,
    SpiceDBObjectType,
    sharedObjectPermissionsModalMessages,
} from "~/domains/identity/roles-permissions/types/SpiceDB"
import {
    removeAllPermissionsExceptUnauthorized,
    removeAllUnauthorizedPermissions,
    renderSelectValue,
} from "~/domains/identity/roles-permissions/utils/spiceDB"
import { AuthorizationName } from "~/types"
import { isDefined } from "~/utils/isDefined"

import "./PermissionsSelect.scss"

const messages = defineMessages({
    removeAll: { id: "share.object.permissions.object.removeAll", defaultMessage: "Remove all permissions" },
})

const orderPermissions = (permissions: AuthorizationName[]) => {
    const permissionSet = new Set(permissions)

    return Object.values(AuthorizationName).filter((authorization) => permissionSet.has(authorization))
}

interface Props {
    currentUserPermissions: AuthorizationName[]
    objectPermissions: AuthorizationName[]
    objectType: SpiceDBObjectType
    handleChangePermission: (permissions: AuthorizationName[]) => void
    canSelect?: boolean
    showRemoveAll?: boolean
}

export const PermissionsSelect: React.FC<Props> = ({
    currentUserPermissions,
    objectPermissions,
    objectType,
    handleChangePermission,
    canSelect = true,
    showRemoveAll = true,
}) => {
    const { formatMessage } = useIntl()
    const [permissionsSelected, setPermissionsSelected] = useState<AuthorizationName[]>([])
    const [confirmRemoveAllPermissions, setConfirmRemoveAllPermissions] = useState(false)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    const authorizedAuthorizations = SpiceDBObjectAuthorizationName[objectType]?.authorized ?? []
    const unauthorizedAuthorizations = SpiceDBObjectAuthorizationName[objectType]?.unauthorized ?? []

    const hasOwnerOrCheckerPermission =
        permissionsSelected.includes(AuthorizationName.OWNER) || permissionsSelected.includes(AuthorizationName.CHECK)

    useEffect(() => {
        setPermissionsSelected(orderPermissions(objectPermissions))
    }, [objectPermissions])

    const onPermissionsChange = (event: SelectChangeEvent<AuthorizationName[]>) => {
        const { value } = event.target
        if ((value as string[]).includes("removeAll")) return

        const newPermissions =
            typeof value === "string" ? (value.split(",").filter(isDefined) as AuthorizationName[]) : value
        handleChangePermission(newPermissions)
        setPermissionsSelected(newPermissions)
    }

    const hideConfirmRemoveAllPermissions = () => setConfirmRemoveAllPermissions(false)
    const showConfirmRemoveAllPermissions = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        event.stopPropagation()
        if (removeAllUnauthorizedPermissions(objectType, permissionsSelected, currentUserPermissions).length) {
            setConfirmRemoveAllPermissions(true)
        }
    }

    const handleRemoveAllPermissions = () => {
        handleChangePermission(
            removeAllPermissionsExceptUnauthorized(objectType, permissionsSelected, currentUserPermissions)
        )
        setIsDropdownOpen(false)
        return true
    }

    const renderValueHandler = (selected: AuthorizationName[]) =>
        renderSelectValue(selected, canSelect ? sharedObjectPermissionsModalMessages.permissions : null, formatMessage)

    const onOpen = () => setIsDropdownOpen(true)
    const onClose = () => setIsDropdownOpen(false)

    const classes = classNames("share-permissions-select", { disabled: !canSelect })

    return (
        <>
            <Select
                className={classes}
                multiple
                displayEmpty
                value={permissionsSelected}
                renderValue={renderValueHandler}
                onChange={onPermissionsChange}
                disabled={!canSelect}
                open={isDropdownOpen}
                onClose={onClose}
                onOpen={onOpen}
            >
                <PermissionsMenuItems
                    permissionsSelected={permissionsSelected}
                    currentUserPermissions={currentUserPermissions}
                    authorizedAuthorizations={authorizedAuthorizations}
                    unauthorizedAuthorizations={unauthorizedAuthorizations}
                    setPermissionsSelected={setPermissionsSelected}
                    handleChangePermission={handleChangePermission}
                />
                {showRemoveAll && (
                    <MenuItem
                        className="share-permissions-select-option remove-option"
                        onClick={showConfirmRemoveAllPermissions}
                        value="removeAll"
                    >
                        <div className="remove-option-container">
                            <Trash2 size={16} />
                            <ListItemText primary={<SafeFormattedMessage {...messages.removeAll} />} />
                            {hasOwnerOrCheckerPermission && (
                                <Tooltip
                                    title={
                                        <SafeFormattedMessage
                                            {...sharedObjectPermissionsModalMessages.removeAllTooltip}
                                        />
                                    }
                                    arrow
                                    placement="top"
                                >
                                    <Info color="var(--color-grey)" size={16} />
                                </Tooltip>
                            )}
                        </div>
                    </MenuItem>
                )}
            </Select>
            <ConfirmModal
                open={confirmRemoveAllPermissions}
                close={hideConfirmRemoveAllPermissions}
                onConfirm={handleRemoveAllPermissions}
                title={formatMessage(sharedObjectPermissionsModalMessages.confirmRemoveAllPermissionsTitle)}
            >
                {formatMessage(sharedObjectPermissionsModalMessages.confirmRemoveAllPermissions)}
            </ConfirmModal>
        </>
    )
}
