import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorization as prepareHeaders } from "~/api/prepareHeaders"
import {
    AddTransactionToBudgetMutation,
    AddTransactionToBudgetMutationResponse,
    ApiErrorResponse,
    RemoveTransactionFromBudgetMutation,
    RemoveTransactionFromBudgetMutationResponse,
} from "~/domains/transactions/budget/api/types/budgetApi.type"

const BASE_URL = import.meta.env.VITE_API_BUDGET_URL + "organizations/"

export const budgetApi = createApi({
    reducerPath: "budgetApi",
    baseQuery: fetchBaseQuery({
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        addTransactionToBudget: builder.mutation<
            AddTransactionToBudgetMutationResponse,
            AddTransactionToBudgetMutation
        >({
            query: ({ organizationId, budgetId, payload }) => ({
                url: `${BASE_URL}${organizationId}/budgets/${budgetId}/transactions`,
                method: "POST",
                body: payload,
            }),
            transformErrorResponse: (response: ApiErrorResponse) => ({
                meta: {
                    tags: {
                        domain: "transaction",
                        subdomain: "budgets",
                        feature: "add-transaction-to-budget",
                    },
                },
                ...response,
            }),
        }),
        removeTransactionFromBudget: builder.mutation<
            RemoveTransactionFromBudgetMutationResponse,
            RemoveTransactionFromBudgetMutation
        >({
            query: ({ organizationId, transactionId }) => ({
                url: `${BASE_URL}${organizationId}/transactions/${transactionId}`,
                method: "DELETE",
            }),
            transformErrorResponse: (response: ApiErrorResponse) => ({
                meta: {
                    tags: {
                        domain: "transaction",
                        subdomain: "budgets",
                        feature: "remove-transaction-from-budget",
                    },
                },
                ...response,
            }),
        }),
    }),
})

export const { useAddTransactionToBudgetMutation, useRemoveTransactionFromBudgetMutation } = budgetApi
