import { CustomFieldObjectType } from "~/domains/identity/custom-fields/types/CustomFieldObjectType"
import {
    CreateCustomFieldObjectByNameRecord,
    CreateCustomFieldObjectPayload,
    CustomFieldValueI,
} from "~/domains/identity/custom-fields/types/CustomFieldValue"
import { OrganizationId } from "~/types"

export const createContextIdFromObjectAndOrgIds = (
    objectId: string,
    objectType: string,
    organizationId: OrganizationId | undefined
) => `${objectType}:${objectId}:${organizationId}`

export const convertToPayloadArray = (
    pendingCustomFieldObjects: CreateCustomFieldObjectByNameRecord,
    purchaseId: string,
    contextType: CustomFieldObjectType
): CreateCustomFieldObjectPayload[] => {
    const customFieldsObjectsToCreate = Object.values(pendingCustomFieldObjects).flat()

    return Object.values(customFieldsObjectsToCreate).map((customField) => ({
        ...customField,
        objectId: purchaseId,
        contextId: createContextIdFromObjectAndOrgIds(purchaseId, contextType, customField.organizationId),
    }))
}
const createCustomFieldTemplate = (
    id: string,
    objectId: string,
    objectType: string,
    currentOrganizationId: string | undefined,
    defaultValue?: string
) => {
    return {
        id,
        name: id,
        value: defaultValue ?? "",
        objectId,
        contextId: createContextIdFromObjectAndOrgIds(objectId, objectType, currentOrganizationId),
        contextType: objectType,
        organizationId: currentOrganizationId,
    }
}

export function getOrCreateCustomField(
    customFields: CustomFieldValueI[] | null,
    fieldName: string,
    objectId: string,
    objectType: CustomFieldObjectType,
    currentOrganizationId: string | undefined,
    defaultValue?: string
): CustomFieldValueI {
    if (!customFields) {
        return createCustomFieldTemplate(fieldName, objectId, objectType, currentOrganizationId, defaultValue)
    }
    const foundCustomField = customFields.find((cf) => cf.name === fieldName)
    return (
        foundCustomField ??
        createCustomFieldTemplate(fieldName, objectId, objectType, currentOrganizationId, defaultValue)
    )
}
