import { useEffect } from "react"
import { MessageDescriptor, useIntl } from "react-intl"

import { useEditor, useEditorDispatch } from "~/domains/orchestration/flows/context/editorContext"
import { messages } from "~/domains/orchestration/flows/locale"
import { Flow, FlowValidation, NodeType, isFlowInvalid } from "~/domains/orchestration/flows/types"

const eventTriggerNodeValidation = (
    flow: Flow,
    formatMessage: (message: MessageDescriptor) => string
): FlowValidation => {
    const eventTriggerNode = flow.nodes.filter((node) => node.type === NodeType.EVENT_TRIGGER_NODE)
    const entityTriggerNode = flow.nodes.filter((node) => node.type === NodeType.ENTITY_TRIGGER_NODE)

    // Check if flow has one trigger node
    if (eventTriggerNode.length < 1 && entityTriggerNode.length < 1) {
        return { isNotValid: true, error: formatMessage({ ...messages.error.noTrigger }) }
    }

    // Check if flow has only one trigger node
    if (eventTriggerNode.length + entityTriggerNode.length > 1) {
        return { isNotValid: true, error: formatMessage({ ...messages.error.multipleTriggers }) }
    }

    return { isValid: true }
}

const nodesValidation = (flow: Flow, formatMessage: (message: MessageDescriptor) => string): FlowValidation => {
    const hasError = flow.nodes.some((node) => node.error)

    if (hasError) {
        return { isNotValid: true, error: formatMessage({ ...messages.error.nodeError }) }
    }

    return { isValid: true }
}

const validateFlow = (flow: Flow, formatMessage: (message: MessageDescriptor) => string): FlowValidation => {
    // Validate event trigger node
    const eventTriggerNodeValidationResult = eventTriggerNodeValidation(flow, formatMessage)

    if (isFlowInvalid(eventTriggerNodeValidationResult)) {
        return eventTriggerNodeValidationResult
    }

    // Validate if any node has an error
    const nodesValidationResult = nodesValidation(flow, formatMessage)
    if (isFlowInvalid(nodesValidationResult)) {
        return nodesValidationResult
    }

    return { isValid: true }
}

export const useFlowValidator = (flow: Flow) => {
    const { formatMessage } = useIntl()
    const dispatch = useEditorDispatch()
    const { error } = useEditor()

    useEffect(() => {
        const flowValidation = validateFlow(flow, formatMessage)

        dispatch({
            type: "SET_FLOW_ERROR",
            payload: isFlowInvalid(flowValidation) ? flowValidation.error : null,
        })
    }, [dispatch, error, flow, formatMessage])
}
