import { Stack, Typography } from "@mui/material"
import { useState } from "react"
import { Edit } from "react-feather"

import { SafeFormattedMessage } from "~/components"
import { Card } from "~/components"
import { OrganizationI, OrganizationId } from "~/domains/identity/organization/types/OrganizationTypes"
import { organizationSelectorMessages } from "~/domains/orchestration/flows/locale"
import { isExpression } from "~/domains/orchestration/flows/utils"

import { ModalSelectOrganization } from "./ModalSelectOrganization"

interface OrganizationSelectorProps {
    title: string
    organizationId: OrganizationId | null
    organizationName?: string
    onOrganizationSelect: (organization: OrganizationI) => void
    isUserOrganization?: boolean
}

export const OrganizationSelector = ({
    title,
    organizationId,
    organizationName,
    isUserOrganization = false,
    onOrganizationSelect,
}: OrganizationSelectorProps) => {
    const [displaySelectOrganizationModal, setDisplaySelectOrganizationModal] = useState(false)

    const showSelectOrganizationModal = () => setDisplaySelectOrganizationModal(true)
    const hideSelectOrganizationModal = () => setDisplaySelectOrganizationModal(false)

    const handleModalClose = () => {
        hideSelectOrganizationModal()
    }

    const handleOrganizationSelect = (organization: OrganizationI) => {
        onOrganizationSelect?.(organization)
        hideSelectOrganizationModal()
    }

    const organizationIdType = organizationId ? isExpression(organizationId) : false
    const defaultLabel = organizationIdType ? (
        <SafeFormattedMessage {...organizationSelectorMessages.selectOrganization} />
    ) : (
        organizationId
    )

    return (
        <Card
            header={
                <Stack direction="row" alignItems="center" gap={1}>
                    <Typography variant="h4">{title}</Typography>
                </Stack>
            }
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap={1}
                width="100%"
                onClick={showSelectOrganizationModal}
            >
                {organizationName ? (
                    <Typography className="truncate-text">{organizationName}</Typography>
                ) : (
                    defaultLabel
                )}
                <Stack direction="row" alignItems="center" gap={1} className="cursor-pointer">
                    {!isUserOrganization && <Edit size={20} />}
                </Stack>
            </Stack>

            {displaySelectOrganizationModal && !isUserOrganization && (
                <ModalSelectOrganization
                    organizationId={organizationId}
                    organizationName={organizationName}
                    open={displaySelectOrganizationModal}
                    close={handleModalClose}
                    onSelect={handleOrganizationSelect}
                />
            )}
        </Card>
    )
}
