import { Stack, styled } from "@mui/material"
import React, { FC } from "react"
import { ArrowLeft } from "react-feather"

import { SafeFormattedMessage } from "~/components"
import { IconButton } from "~/domains/orchestration/flows/components/IconButton"
import { ToggleSideBarButtons } from "~/domains/orchestration/flows/components/ToggleSidebarButtons"
import { SIDE_BAR_TOP_POSITION } from "~/domains/orchestration/flows/constants"
import { nodeConfig } from "~/domains/orchestration/flows/core/"
import { messages } from "~/domains/orchestration/flows/locale"
import {
    AdvancedFieldsValues,
    Configuration,
    type EditorNode,
    NodeType,
    SideBarState,
} from "~/domains/orchestration/flows/types"
import { getSideBarWidth } from "~/domains/orchestration/flows/utils"

import { NodeList } from "./NodeList"
import "./SideBar.scss"

export interface SideBarProps {
    sidebarState: SideBarState
    selectedNode?: EditorNode
    onDragStart: (nodeType: NodeType) => (event: React.DragEvent<HTMLDivElement>) => void
    onNodeClick: (nodeType: NodeType) => (event: React.MouseEvent<HTMLDivElement>) => void
    onUnselectNode: () => void
    onToggleSideBar: (newState: SideBarState) => void
}

const SideBarWrapper = styled("div")<{ width: number }>(({ width }) => ({
    width: `${width}px`,
    height: `calc(100vh - ${SIDE_BAR_TOP_POSITION}px)`,
    display: width === 0 ? "none" : "block",
    transition: "width 0.3s ease-in-out",
}))

const getConfigurationComponent = (selectedNode: EditorNode) => {
    // Map node types to their configuration components

    const configuration: Configuration<NodeType> = nodeConfig[selectedNode.type]

    const ConfigComponent = configuration.Configuration
    if (!ConfigComponent) return null

    const advancedFields = configuration.advancedFields

    return (
        <ConfigComponent
            key={selectedNode.slug}
            selectedNode={selectedNode}
            advancedFields={advancedFields as AdvancedFieldsValues<EditorNode>}
            validateNode={configuration.validateNode}
        />
    )
}

export const SideBar: FC<SideBarProps> = ({
    sidebarState,
    onDragStart,
    selectedNode,
    onNodeClick,
    onUnselectNode,
    onToggleSideBar,
}) => {
    const sideBarWidth = getSideBarWidth(sidebarState)

    return (
        <>
            <ToggleSideBarButtons onClick={onToggleSideBar} state={sidebarState} />

            <SideBarWrapper className="flows-editor-sideBar" width={sideBarWidth}>
                <Stack
                    className="flows-editor-sideBar-header"
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <SafeFormattedMessage
                        tagName="h4"
                        {...(selectedNode ? messages.sideBar.configurationTitle : messages.sideBar.listTitle)}
                    />
                    {selectedNode && (
                        <IconButton type="close" onClick={onUnselectNode}>
                            <ArrowLeft size={14} />
                        </IconButton>
                    )}
                </Stack>

                {selectedNode ? (
                    getConfigurationComponent(selectedNode)
                ) : (
                    <NodeList onDragStart={onDragStart} onNodeClick={onNodeClick} showLabels direction="column" />
                )}
            </SideBarWrapper>
        </>
    )
}
