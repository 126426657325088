import { PurchaseOrdersPaginatedResult } from "~/domains/transactions/purchase-orders/api/types/purchaseOrderApi.type"
import { PurchaseOrdersPaginatedAPI } from "~/domains/transactions/purchase-orders/api/types/purchaseOrderApi.type"
import { convertPOV2toPO } from "~/domains/transactions/purchase-orders/core/purchaseOrder"

export const purchaseOrderPaginatedFromApiAdapter = (
    data: PurchaseOrdersPaginatedAPI
): PurchaseOrdersPaginatedResult => {
    return {
        items: data.items.map(convertPOV2toPO),
        offset: data.offset,
        limit: data.limit,
        total: data.total,
    }
}
