import { Menu, MenuItem, Stack, Typography, styled } from "@mui/material"
import { Handle, HandleProps } from "@xyflow/react"
import cls from "classnames"
import React, { FC, PropsWithChildren, useState } from "react"
import { AlertTriangle, CheckCircle, Copy, MoreHorizontal, Slash, Trash, XCircle } from "react-feather"
import { useIntl } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import { Button } from "~/components"
import { TooltipConditional } from "~/components/Tooltip/Tooltip"
import { NODE_MIN_HEIGHT } from "~/domains/orchestration/flows/constants"
import { NODE_WIDTH } from "~/domains/orchestration/flows/constants"
import { useEditorDispatch } from "~/domains/orchestration/flows/context/editorContext"
import { nodeConfig } from "~/domains/orchestration/flows/core"
import { useRunNodeStatus } from "~/domains/orchestration/flows/hooks"
import { messages } from "~/domains/orchestration/flows/locale"
import { Configuration, NodeType } from "~/domains/orchestration/flows/types"

import "./Node.scss"

interface Props {
    type: NodeType
    name: string
    slug: string
    information?: string
    handles: Array<HandleProps>
    selected?: boolean
    error?: boolean
    errorMessage?: string
}

const RunIcon = ({
    isLastNode,
    isError,
    isWarning,
    isInPath,
    isSuccess,
    className,
}: {
    isLastNode: boolean
    isError: boolean
    isWarning: boolean
    isInPath: boolean
    isSuccess: boolean
    className?: string
}) => {
    if (isLastNode && isError) return <XCircle size={18} color="var(--color-red)" className={className} />
    if (isLastNode && isWarning) return <AlertTriangle size={18} color="var(--color-yellow)" className={className} />
    if (isLastNode && isSuccess) return <CheckCircle size={18} color="var(--color-green)" className={className} />
    if (isInPath) return <CheckCircle size={18} color="var(--color-grey)" className={className} />
    return <Slash size={18} color="var(--color-grey)" className={className} />
}

const BigHandle = styled(Handle)`
    width: var(--spacing-lg);
    height: var(--spacing-lg);
    background-color: var(--primary-color-lighter);
    border: 1px solid var(--primary-color);
`

const NodeWrapper = styled(Stack)`
    width: ${NODE_WIDTH}px;
    min-height: ${NODE_MIN_HEIGHT}px;
`

export const Node: FC<PropsWithChildren<Props>> = ({
    type,
    slug,
    name,
    information,
    handles,
    children,
    selected = false,
    error = false,
    errorMessage,
}) => {
    const { formatMessage } = useIntl()
    const title = formatMessage(messages.nodeTitle[type])

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const dispatch = useEditorDispatch()

    const hasTooltip = Boolean(information && information?.length > 45)

    const displayedTitle = name || title
    // if the name is empty string or is the same as the title, we don't display the subtitle
    const displayedSubtitle = name === title || !name ? "" : title

    const { isLastNode, isError, isWarning, isInPath, isSuccess, isExplorer } = useRunNodeStatus(slug)

    const displayedError = errorMessage || formatMessage(messages.error.nodeError)

    const configuration: Configuration<NodeType> = nodeConfig[type]

    const EditorIcon = configuration.Icon

    const nodeClassName = cls("flows-node", {
        "flows-node-selected": selected,
        "flows-node-error": isError && isLastNode,
        "flows-node-warning": isWarning && isLastNode,
        "flows-node-success": isInPath && !isLastNode,
        "flows-node-success-last-node": isSuccess && isLastNode,
        "flows-node-in-path": isInPath,
    })

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleCopy = () => {
        dispatch({ type: "COPY_NODE", payload: slug })
    }

    const handleDelete = () => {
        dispatch({ type: "DELETE_NODE", payload: slug })
    }

    return (
        <NodeWrapper className={nodeClassName} gap={2}>
            <Stack className="flows-node-header" direction="row" alignItems="center" gap={1}>
                {isExplorer ? (
                    <RunIcon
                        isLastNode={isLastNode}
                        isError={isError}
                        isWarning={isWarning}
                        isInPath={isInPath}
                        isSuccess={isSuccess}
                        className="flows-node-header-icon"
                    />
                ) : (
                    <EditorIcon size={18} className="flows-node-header-icon" />
                )}
                <Stack direction="column">
                    <h5 className="flows-node-header-title">{displayedTitle}</h5>
                    <p className="flows-node-header-title-subtitle">{displayedSubtitle}</p>
                </Stack>

                {!isExplorer && (
                    <>
                        <Button className="flows-node-header-menu" type="title" onClick={handleOpen}>
                            <MoreHorizontal size={18} />
                        </Button>
                        {
                            // FIXME: use actions menu component
                            // Actions menu should have props to customize the menu placement
                            // and where the menu should be anchored to with the arrow auto-position
                        }

                        <Menu
                            open={open}
                            onClose={handleClose}
                            anchorEl={anchorEl}
                            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                            sx={{
                                "& .MuiPopover-paper:before": {
                                    left: "var(--spacing-sm)",
                                },
                            }}
                        >
                            <MenuItem onClick={handleCopy}>
                                <Copy size={18} />
                                <SafeFormattedMessage {...messages.common.copy} />
                            </MenuItem>
                            <MenuItem onClick={handleDelete}>
                                <Trash size={18} />
                                <SafeFormattedMessage {...messages.common.delete} />
                            </MenuItem>
                        </Menu>
                    </>
                )}
            </Stack>
            <Stack className="flows-node-content" direction="column">
                {children}
                {information && (
                    <TooltipConditional condition={hasTooltip} title={information} placement="right">
                        <Typography noWrap className="flows-node-content-information">
                            {information}
                        </Typography>
                    </TooltipConditional>
                )}
            </Stack>
            {handles.map((handle) => (
                <BigHandle
                    key={`${handle.type}${handle.id || ""}`}
                    {...handle}
                    className={cls("flows-node-handle", handle.className)}
                />
            ))}
            <Stack className="flows-node-footer" alignItems="center" justifyContent="space-between" gap={1}>
                {error && (
                    <Stack className="flows-node-footer-error" direction="row" alignItems="center" gap={1}>
                        <AlertTriangle size={18} color="var(--color-yellow)" />
                        {displayedError}
                    </Stack>
                )}
            </Stack>
        </NodeWrapper>
    )
}
