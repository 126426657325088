import { Stack, TextField } from "@mui/material"
import cls from "classnames"
import isEqual from "lodash/isEqual"
import React, { ChangeEvent, FC, PropsWithChildren, useEffect, useState } from "react"

import { SafeFormattedMessage } from "~/components"
import { useEditor, useEditorDispatch } from "~/domains/orchestration/flows/context/editorContext"
import { messages } from "~/domains/orchestration/flows/locale"
import { EditorNode, Node } from "~/domains/orchestration/flows/types"

import "./ConfigurationNode.scss"

export interface ConfigurationNodeProps {
    selectedNode: EditorNode
    validateNode: (node: EditorNode) => boolean
}

const adaptCommonNodeProperties = (node: EditorNode): Pick<Node, "name" | "slug" | "error"> => {
    return {
        name: node.name,
        slug: node.slug,
        error: node.error,
    }
}

export const ConfigurationNode: FC<PropsWithChildren<ConfigurationNodeProps>> = ({ selectedNode, children }) => {
    const dispatch = useEditorDispatch()

    const [commonNodeProperties, setCommonNodeProperties] = useState(adaptCommonNodeProperties(selectedNode))

    const { flow } = useEditor()

    useEffect(() => {
        // Check if node has changed

        const nodeToUpdate = flow?.nodes.find((node) => node.slug === selectedNode.slug)
        if (!nodeToUpdate) {
            return
        }
        const updatedNode = {
            ...selectedNode,
            ...commonNodeProperties,

            metadata: { ...selectedNode.metadata, position: nodeToUpdate.metadata.position },
        }
        if (isEqual(nodeToUpdate, updatedNode)) {
            return
        }

        const timeout = setTimeout(() => {
            dispatch({
                type: "UPDATE_NODE",
                payload: updatedNode,
            })
        }, 100)

        return () => clearTimeout(timeout)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNode, commonNodeProperties, dispatch])

    const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
        setCommonNodeProperties({ ...commonNodeProperties, name: e.target.value })
    }

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    return (
        <Stack className="flows-configurationNode" gap={2}>
            {commonNodeProperties.slug && (
                <Stack direction="row" justifyContent="flex-end" gap={1} className="flows-configurationNode-item-slug">
                    <SafeFormattedMessage {...messages.configurationNode.slugLabel} />
                    <span>{commonNodeProperties.slug}</span>
                </Stack>
            )}
            <Stack gap={1} className={configurationNodeItemClassName}>
                <TextField
                    label={<SafeFormattedMessage {...messages.configurationNode.nameLabel} />}
                    value={commonNodeProperties.name}
                    onChange={handleChangeName}
                    size="small"
                />
            </Stack>
            {children}
        </Stack>
    )
}
