import { TagObjectType } from "~/domains/analytics/tags/types"
import { OrganizationId } from "~/types"

const eiffageTagGroups = {
    // Eiffage staging
    "6673ee7e4d459a2ad69b16dc": {
        // Purchase family tag group
        "6f366015-5e7f-4e97-b0fc-e9d478c04b5e": [
            TagObjectType.INVOICE,
            TagObjectType.PURCHASE_REQUEST,
            TagObjectType.PURCHASE_ORDER,
        ],
    },
    // Eiffage production
    "673b1cd159797801c4ea7184": {
        // Purchase family tag group
        "19262d10-fd1f-4303-804e-a408fe32a938": [
            TagObjectType.INVOICE,
            TagObjectType.PURCHASE_REQUEST,
            TagObjectType.PURCHASE_ORDER,
        ],
        // Type of order tag group
        "b24fdf37-be2e-42d1-a141-82a1f97dee24": [
            TagObjectType.INVOICE,
            TagObjectType.PURCHASE_REQUEST,
            TagObjectType.PURCHASE_ORDER,
        ],
    },
}

const mandatoryTagGroups: Record<OrganizationId, Partial<Record<string, TagObjectType[]>>> = {
    ...eiffageTagGroups,
}

export const getMandatoryTagGroupIdsByTagObjectType = (
    organizationId: OrganizationId,
    tagObjectType: TagObjectType
): string[] => {
    const organizationMandatoryTagGroups = mandatoryTagGroups[organizationId]
    if (!organizationMandatoryTagGroups) return []

    return (
        Object.keys(organizationMandatoryTagGroups).filter(
            (tagGroupId) => organizationMandatoryTagGroups[tagGroupId]?.includes(tagObjectType) ?? false
        ) ?? []
    )
}
