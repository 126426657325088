import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorizationAndOrganizationId } from "~/api/prepareHeaders"
import { lifecycleFlatListFromApiAdapter } from "~/domains/transactions/invoices-v1/api/adapters/lifecycleFlatListFromApiAdapter"
import { LifecycleGroupFormatted } from "~/domains/transactions/invoices-v1/types/Invoice"
import { Lifecycle } from "~/domains/transactions/invoices-v1/types/Lifecycle"

const BASE_URL = import.meta.env.VITE_API_TRANSACTION_V1_INVOICES_URL + "invoices/"
const BASE_URL_V2 = import.meta.env.VITE_API_TRANSACTION_V2_INVOICES_URL + ""

export const invoiceLifeCycleApi = createApi({
    reducerPath: "invoiceLifeCycleApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, prepareHeaders: prepareHeadersWithAuthorizationAndOrganizationId }),
    endpoints: (builder) => ({
        getOrganizationInvoiceLifecycle: builder.query<LifecycleGroupFormatted, string>({
            query: (organizationId: string) => ({ url: `${BASE_URL_V2}organizations/${organizationId}/lifecycles` }),
            transformResponse: lifecycleFlatListFromApiAdapter,
        }),
        getInvoiceLifecycle: builder.query<Lifecycle, string>({
            query: (invoiceId: string) => ({ url: `${invoiceId}/lifecycle` }),
        }),
        createOrReplaceInvoiceLifecycle: builder.mutation({
            query: ({ invoiceId, status }: Lifecycle) => ({
                url: `${invoiceId}/lifecycle`,
                method: "POST",
                body: { status },
            }),
        }),
    }),
})

export const {
    useGetOrganizationInvoiceLifecycleQuery,
    useGetInvoiceLifecycleQuery,
    useCreateOrReplaceInvoiceLifecycleMutation,
} = invoiceLifeCycleApi
