import { Button, Stack, TextField, Typography } from "@mui/material"
import { ChangeEvent, FC } from "react"
import { AlertTriangle, Plus } from "react-feather"
import { useIntl } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import { messages } from "~/domains/orchestration/flows/locale"

interface MappingHeaderProps {
    valueToMap: string
    onValueToMapChange: (event: ChangeEvent<HTMLInputElement>) => void
    onAddColumn: () => void
    isEditing: boolean
    hasKeyError: boolean | null
}

export const MappingHeader: FC<MappingHeaderProps> = ({
    valueToMap,
    onValueToMapChange,
    onAddColumn,
    isEditing,
    hasKeyError,
}) => {
    const { formatMessage } = useIntl()

    const showError = hasKeyError && !isEditing
    const showWarning = isEditing

    return (
        <>
            <TextField
                fullWidth
                size="small"
                label={formatMessage(messages.mappingConfiguration.valueToMapLabel)}
                value={valueToMap}
                onChange={onValueToMapChange}
            />
            <Typography variant="subtitle2">
                <SafeFormattedMessage {...messages.mappingConfiguration.mappingTableTitle} />
            </Typography>
            <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
                flexDirection="row-reverse"
            >
                <Button variant="outlined" size="small" onClick={onAddColumn}>
                    <SafeFormattedMessage {...messages.mappingConfiguration.addColumn} />
                    <Plus size={16} />
                </Button>
                {showError && (
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <AlertTriangle size={16} color="var(--color-red)" />
                        <Typography variant="body2" color="var(--color-red)">
                            <SafeFormattedMessage {...messages.mappingConfiguration.duplicateKeyError} />
                        </Typography>
                    </Stack>
                )}
                {showWarning && (
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <AlertTriangle size={16} color="var(--color-yellow)" />
                        <Typography variant="body2" color="var(--color-yellow)">
                            <SafeFormattedMessage {...messages.mappingConfiguration.pressEnterToUpdate} />
                        </Typography>
                    </Stack>
                )}
            </Stack>
        </>
    )
}
