import { Box, Stack } from "@mui/material"
import classNames from "classnames"
import { MessageDescriptor } from "react-intl"

import { Button, SafeFormattedMessage, TooltipConditional } from "~/components"
import { permissionMessages } from "~/domains/identity/roles-permissions/utils/permissions"
import { useHasPaymentPermissions } from "~/domains/payment/hooks"
import { selectRowsToApprove } from "~/domains/payment/payment-method-details/store/paymentMethodDetailsSlice"
import { PaymentMethodType } from "~/domains/payment/payment-methods/types"
import { useAppSelector } from "~/store/hooks"

const tabs = [
    PaymentMethodType.BANK_TRANSFER,
    PaymentMethodType.DIRECT_DEBIT,
    PaymentMethodType.CARD,
    PaymentMethodType.TO_APPROVE,
]

const messages: Partial<Record<PaymentMethodType, MessageDescriptor>> = {
    [PaymentMethodType.CARD]: {
        id: "payment.paymentMethodTabs.card",
        defaultMessage: "Card",
    },
    [PaymentMethodType.BANK_TRANSFER]: {
        id: "payment.paymentMethodTabs.bankTransfer",
        defaultMessage: "Bank transfer",
    },
    [PaymentMethodType.DIRECT_DEBIT]: {
        id: "payment.paymentMethodTabs.directDebit",
        defaultMessage: "Direct debit",
    },
    [PaymentMethodType.TO_APPROVE]: {
        id: "payment.paymentMethodTabs.toApprove",
        defaultMessage: "To approve",
    },
}

interface PaymentMethodTabsProps {
    currentTab: PaymentMethodType
    organizationId: string
    onTabChange: (tab: PaymentMethodType) => void
}

export const PaymentMethodTabs = ({ currentTab, organizationId, onTabChange }: PaymentMethodTabsProps) => {
    const rowsToApprove = useAppSelector(selectRowsToApprove)
    const { permissions } = useHasPaymentPermissions({ authorizations: ["approve"] })
    const hasApprovePermission = Boolean(permissions?.approve?.hasPermission)

    const isDisabledApproveTab = (tab: PaymentMethodType): boolean =>
        !hasApprovePermission && tab === PaymentMethodType.TO_APPROVE

    const handleTabClick = (tab: PaymentMethodType) => () => {
        onTabChange(tab)
    }

    return (
        <Stack flexDirection="row" marginRight="auto">
            {tabs.map((tab) => (
                <TooltipConditional
                    key={tab}
                    title={<SafeFormattedMessage {...permissionMessages.errorNoAccessAdministrator} />}
                    condition={isDisabledApproveTab(tab)}
                    placement="bottom"
                    arrow
                >
                    <Box component="span">
                        <Button
                            type="tab"
                            className={classNames({
                                selected: currentTab === tab,
                            })}
                            disabled={isDisabledApproveTab(tab)}
                            onClick={handleTabClick(tab)}
                        >
                            <SafeFormattedMessage {...messages[tab]} />
                            {tab === PaymentMethodType.TO_APPROVE && (
                                <span className="tab-count">{rowsToApprove[organizationId]?.length ?? 0}</span>
                            )}
                        </Button>
                    </Box>
                </TooltipConditional>
            ))}
        </Stack>
    )
}
