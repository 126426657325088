import { Stack } from "@mui/material"
import classNames from "classnames"
import { useLocation } from "react-router-dom"
import { Link } from "react-router-dom"

import { commonMessages } from "~/common-messages"
import { SafeFormattedMessage } from "~/components/SafeFormattedMessage/SafeFormattedMessage"
import { SideBarMenuItemI } from "~/components/Sidebar/sideBarMenuItems"
import { TooltipConditional } from "~/components/Tooltip/Tooltip"
import { selectUser } from "~/store/account/accountSlice"
import { selectDisplayMenu, selectKeepSidebarOpened } from "~/store/global/globalSlice"
import { useAppSelector } from "~/store/hooks"

interface SideBarMenuItemProps {
    menuItem: SideBarMenuItemI
    disabled: boolean
    showBetaFlag?: boolean
}

export const SideBarMenuItem = ({ menuItem, disabled, showBetaFlag = false }: SideBarMenuItemProps) => {
    const location = useLocation()

    const sideBarOpened = useAppSelector(selectKeepSidebarOpened)
    const displayMenu = useAppSelector(selectDisplayMenu)
    const currentUser = useAppSelector(selectUser)

    const active = menuItem.routesActive?.length
        ? menuItem.routesActive?.some((route) => location.pathname.startsWith(route))
        : location.pathname.startsWith(menuItem.route)

    let to = disabled ? "#" : menuItem.route
    if (currentUser?.organizations.length === 0 && menuItem.routeWithNoOrganization) {
        to = menuItem.routeWithNoOrganization
    }

    const getButtonLabel = () => {
        if (showBetaFlag) {
            return (
                <Stack direction="row" alignItems="center" gap={1}>
                    <SafeFormattedMessage {...menuItem.message} />
                    <span>
                        (<SafeFormattedMessage {...commonMessages.beta} />)
                    </span>
                </Stack>
            )
        }
        return <SafeFormattedMessage {...menuItem.message} />
    }

    const buttonLabel = getButtonLabel()

    return (
        <TooltipConditional
            condition={!sideBarOpened && !displayMenu}
            type="primary"
            title={buttonLabel}
            placement="right"
            arrow
        >
            <Link to={to} className={classNames({ active, disabled })}>
                <Stack direction="row" alignItems="center" gap={1}>
                    <menuItem.icon size={18} />
                    {(sideBarOpened || displayMenu) && buttonLabel}
                </Stack>
            </Link>
        </TooltipConditional>
    )
}
