import { createContext, useCallback, useContext, useEffect, useState } from "react"

import {
    useCompleteFormMutation,
    useGetFormByTokenQuery,
    useGetSurveyStateQuery,
    useSaveUserAnswerMutation,
    useUploadFileAnswerMutation,
} from "~/domains/identity/custom-forms/api/customFormsApi"
import {
    CustomFormQuestion,
    FormViewerContextType,
    QuestionFieldTypeEnum,
    QuestionValue,
    UserSurveyForm,
} from "~/domains/identity/custom-forms/types/CustomForms"

const FormViewerContext = createContext<FormViewerContextType | null>(null)

interface FormViewerProviderProps {
    children: React.ReactNode
    formToken: string
}

// Context Provider for Form Data
export const FormViewerProvider = ({ children, formToken }: FormViewerProviderProps) => {
    const [currentSection, setCurrentSection] = useState<number | undefined>(undefined)
    const [formSent, setFormSent] = useState(false)
    const [formData, setFormData] = useState<UserSurveyForm | null>(null)
    const [currentSectionInvalid, setCurrentSectionInvalid] = useState(false)
    const [hiddenQuestions, setHiddenQuestions] = useState<string[]>([])
    const [visibleSections, setVisibleSections] = useState<string[]>([])
    const [focusQuestionId, setFocusQuestionId] = useState<string | null>(null)
    const { data: form, refetch, isFetching: formLoading } = useGetFormByTokenQuery({ formToken })
    const { data: surveyState, isFetching: surveyStateLoading } = useGetSurveyStateQuery({
        surveyToken: formToken,
    })
    const [saveUserAnswer] = useSaveUserAnswerMutation()
    const [uploadFileAnswer, { isLoading: uploadFileAnswerLoading }] = useUploadFileAnswerMutation()
    const [completeForm] = useCompleteFormMutation()
    useEffect(() => {
        if (form && form?.answers) {
            const enrichedSections = form.sections.map((section) => ({
                ...section,
                questions: section.questions
                    .map((q) => {
                        const answerObject = form.answers.find((a) => a.questionId === q.id)
                        if (!answerObject) {
                            return {
                                ...q,
                                invalid: false,
                            }
                        }

                        const savedValue = answerObject.value

                        return { ...q, savedValue, invalid: false }
                    })
                    .sort((a, b) => a.order - b.order),
            }))
            setFormData({ ...form, sections: enrichedSections })
        }
    }, [form])

    useEffect(() => {
        // need to fetch the form data when section changes
        if (currentSection !== undefined) {
            refetch()
        } else {
            const firstVisibleSectionIndex = getNextVisibleSection()
            setCurrentSection(firstVisibleSectionIndex)
        }
    }, [currentSection, refetch])

    useEffect(() => {
        if (surveyState) {
            setHiddenQuestions(
                surveyState.questions.reduce<string[]>((acc, q) => {
                    if (!q.visible) acc.push(q.questionId)
                    return acc
                }, [])
            )

            setVisibleSections(
                surveyState.sections.reduce<string[]>((acc, s) => {
                    if (s.visible) acc.push(s.sectionId)
                    return acc
                }, [])
            )
        }
    }, [surveyState])

    const updateQuestion = useCallback(
        (updatedQuestion: CustomFormQuestion) => {
            setFormData((prev) => {
                if (!prev) return null
                const updatedSections = prev.sections.map((section, idx) => {
                    if (idx === currentSection) {
                        return {
                            ...section,
                            questions: section.questions.map((q) =>
                                q.id === updatedQuestion.id ? updatedQuestion : q
                            ),
                        }
                    }
                    return section
                })
                return { ...prev, sections: updatedSections }
            })
        },
        [currentSection]
    )

    const validateSection = useCallback(() => {
        if (!formData || currentSection === undefined) return false

        const checkQuestionInvalid = (question: CustomFormQuestion) => {
            if (!question.required || hiddenQuestions.includes(question.id)) return false

            // false is a valid value for radio buttons
            if (QuestionFieldTypeEnum.YesNo in question.fieldType) return question.savedValue === undefined

            // For single and multichoice we also need to check the length of the array
            if (
                QuestionFieldTypeEnum.MultiChoice in question.fieldType ||
                QuestionFieldTypeEnum.SingleChoice in question.fieldType
            ) {
                return !question.savedValue || (Array.isArray(question.savedValue) && question.savedValue.length === 0)
            }

            return !question.savedValue
        }

        let firstInvalidQuestionId = ""
        let isInvalid = false
        const updatedQuestions = formData.sections[currentSection].questions.map((q) => {
            const invalid = checkQuestionInvalid(q)
            if (invalid) {
                isInvalid = true
                if (!firstInvalidQuestionId) {
                    firstInvalidQuestionId = q.id
                }
            }
            return { ...q, invalid }
        })

        // Set the first invalid question to focus on the element
        // this is done this way to avoid stroring refs in the context
        if (firstInvalidQuestionId) {
            setFocusQuestionId(firstInvalidQuestionId)
            // reset focus after a short delay to enable the behaviour where user
            // can click on the submit button again without filling out the field
            setTimeout(() => {
                setFocusQuestionId(null)
            }, 500)
        }

        // update state
        setFormData((prev) => {
            if (!prev) return null
            const updatedSections = prev.sections.map((section, idx) => {
                if (idx === currentSection) {
                    return {
                        ...section,
                        questions: updatedQuestions,
                    }
                }
                return section
            })
            return { ...prev, sections: updatedSections }
        })
        setCurrentSectionInvalid(isInvalid)
        return isInvalid
    }, [formData, currentSection, hiddenQuestions])

    const handleSaveAnswer = useCallback(
        (question: CustomFormQuestion, value) => {
            const answerKey = `${Object.keys(question.fieldType)[0]}Answer`
            const formattedValue =
                "YesNo" in question.fieldType ? value === "true" : (value as unknown as QuestionValue)

            if (QuestionFieldTypeEnum.FileField in question.fieldType) {
                uploadFileAnswer({
                    surveyToken: formToken,
                    questionId: question.id,
                    file: value as unknown as File,
                })
                updateQuestion({ ...question, savedValue: formattedValue })
            } else {
                saveUserAnswer({
                    surveyToken: formToken,
                    questionId: question.id,
                    answer: { [answerKey]: { value: formattedValue } },
                })

                updateQuestion({ ...question, savedValue: formattedValue })
            }
            setFocusQuestionId(null)
        },
        [saveUserAnswer, formToken, updateQuestion, uploadFileAnswer]
    )

    const sendForm = useCallback(() => {
        // check if user can complete the form
        if (visibleSections.length === formData?.sections.length) {
            completeForm({ surveyToken: formToken })
        }

        setFormSent(true)
    }, [formToken, completeForm, visibleSections, formData])

    const getNextVisibleSection = () => {
        if (currentSection === undefined) return 0

        return (
            formData?.sections.findIndex(
                (section, index) => visibleSections.includes(section.id) && index > currentSection
            ) ?? -1
        )
    }

    const getPreviousVisibleSection = () => {
        if (currentSection === undefined) return 0

        return (
            formData?.sections.findLastIndex(
                (section, index) => visibleSections.includes(section.id) && index < currentSection
            ) ?? -1
        )
    }

    const goToSection = (sectionIndex: number) => {
        if (currentSection !== undefined && sectionIndex !== -1) {
            setCurrentSection(sectionIndex)
        }
    }

    const nextSection = () => goToSection(getNextVisibleSection())
    const previousSection = () => goToSection(getPreviousVisibleSection())

    const previousButtonEnabled =
        currentSection !== undefined && currentSection > 0 && getPreviousVisibleSection() !== -1

    const nextButtonEnabled =
        currentSection !== undefined &&
        currentSection !== (formData?.sections || []).length - 1 &&
        getNextVisibleSection() > 0

    const contextValue = {
        formData,
        currentSection,
        formSent,
        readOnly: Boolean(form?.status.Completed),
        currentSectionInvalid,
        validateSection,
        handleSaveAnswer,
        focusQuestionId,
        hiddenQuestions,
        surveyStateLoading,
        formLoading,
        sendForm,
        uploadFileAnswerLoading,
        nextSection,
        previousSection,
        previousButtonEnabled,
        nextButtonEnabled,
        visibleSections,
    }

    return <FormViewerContext.Provider value={contextValue}>{children}</FormViewerContext.Provider>
}

// Custom Hook to Access Form Context
export const useFormViewerContext = () => {
    const context = useContext(FormViewerContext)
    if (!context) {
        throw new Error("useFormViewerContext must be used within FormViewerProvider")
    }
    return context
}
