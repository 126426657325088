import React from "react"
import { Check, X } from "react-feather"
import { MessageDescriptor, defineMessages, useIntl } from "react-intl"

import { Button, Modal, SafeFormattedMessage, Size } from "~/components"
import { ImportBatchResponseI } from "~/types/ImportBatch"

const messages = defineMessages({
    successes: {
        id: "purchase.requests.modalImportBatch.successes",
        defaultMessage: "{number} succesfully imported",
    },
    fails: {
        id: "purchase.requests.modalImportBatch.fails",
        defaultMessage: "{number} failed to import",
    },
    errorTitle: {
        id: "purchase.requests.modalImportBatch.errorTitle",
        defaultMessage: "The lines in your files concerned are:",
    },
    close: {
        id: "purchase.requests.modalImportBatch.close",
        defaultMessage: "Close",
    },
    newImport: {
        id: "purchase.requests.modalImportBatch.newImport",
        defaultMessage: "New Import",
    },
})

interface Props {
    open: boolean
    close: () => void
    showNewImportModal: () => void
    resultBatchImport: ImportBatchResponseI | undefined
    title: MessageDescriptor
}

export const ModalResultImport: React.FC<Props> = ({ open, close, showNewImportModal, resultBatchImport, title }) => {
    const { formatMessage } = useIntl()
    if (!resultBatchImport) {
        return null
    }
    const successesNumber = resultBatchImport.data ? resultBatchImport.data.length : 0
    const failsNumber = resultBatchImport.errors ? resultBatchImport.errors.length : 0

    const handleClose = () => close()

    const handleNewAction = () => {
        close()
        showNewImportModal()
    }

    return (
        <Modal open={open} onClose={handleClose} size={Size.MD}>
            <Modal.Header>
                <h4>{formatMessage(title)}</h4>
            </Modal.Header>

            <Modal.Content>
                <ul>
                    <li>
                        <span className="status status-done">
                            <Check size={12} />
                        </span>
                        <SafeFormattedMessage {...messages.successes} values={{ number: successesNumber }} />
                    </li>
                    {resultBatchImport.errors && failsNumber > 0 ? (
                        <li>
                            <span className="status status-error">
                                <X size={12} />
                            </span>
                            <SafeFormattedMessage {...messages.fails} values={{ number: failsNumber }} />
                        </li>
                    ) : null}
                </ul>
                {resultBatchImport.errors && failsNumber > 0 ? (
                    <div className="error-details-container">
                        <span className="error-details-title">
                            <SafeFormattedMessage {...messages.errorTitle} />
                        </span>

                        <ul className="error-details-line-box">
                            <li className="error-details-line">
                                {resultBatchImport.errors.map((status, index) => (
                                    <div key={index}>{status.idLine + " - " + status.error}</div>
                                ))}
                            </li>
                        </ul>
                    </div>
                ) : null}
            </Modal.Content>

            <Modal.Footer>
                <Button type="transparent" onClick={handleClose}>
                    {formatMessage(messages.close)}
                </Button>
                <Button type="primary" buttonType="submit" onClick={handleNewAction}>
                    {formatMessage(messages.newImport)}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
