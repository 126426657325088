import {
    InvoiceLifeCycle,
    LifecycleGroup,
    LifecycleGroupFormatted,
    LifecycleGroupVariant,
    lifecycleGroup,
} from "~/domains/transactions/invoices-v1/types/Invoice"
import { Variant } from "~/utils/colors"

const getGroupIndex = (group: LifecycleGroup) => lifecycleGroup.indexOf(group)

const getLifecycleGroupVariant = (group: LifecycleGroup) => LifecycleGroupVariant[group]

const getLifeCycleGroup = (group: LifecycleGroup) => ({
    group,
    index: getGroupIndex(group),
    variant: getLifecycleGroupVariant(group),
})

export const lifecycleFlatListFromApiAdapter = (lifecycle: InvoiceLifeCycle[]): LifecycleGroupFormatted => {
    const lc = lifecycle.reduce(
        (acc, item) => {
            item.other.forEach((otherItem) => {
                acc[otherItem.status.toLowerCase()] = getLifeCycleGroup(otherItem.group)
            })

            acc[item.default.status.toLowerCase()] = getLifeCycleGroup(item.default.group)
            return acc
        },
        {} as Record<LifecycleGroup, { group: LifecycleGroup; index: number; variant: Variant }>
    )

    return Object.entries(lc).sort((a, b) => a[1].index - b[1].index)
}
