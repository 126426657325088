import { defineMessages, useIntl } from "react-intl"
import { useParams } from "react-router-dom"

import { countPurchaseOrderByStatus } from "~/domains/transactions/purchase-orders/core/purchaseOrder"
import { PurchaseOrderSummary, PurchaseOrders, PurchaseOrdersTab } from "~/domains/transactions/purchase-orders/types"
import { ViewTypeI } from "~/types"

const messages = defineMessages({
    [PurchaseOrdersTab.ALL]: {
        id: "purchase.orders.tabs.all",
        defaultMessage: "All",
    },
    [PurchaseOrdersTab.OPEN]: {
        id: "common.status.open",
        defaultMessage: "Open",
    },
    [PurchaseOrdersTab.DRAFT]: {
        id: "purchase.orders.tabs.drafts",
        defaultMessage: "Drafts",
    },
    [PurchaseOrdersTab.SUBMITTED]: {
        id: "purchase.orders.tabs.submitted",
        defaultMessage: "Submitted",
    },
    [PurchaseOrdersTab.INTERNALLY_APPROVED]: {
        id: "purchase.orders.tabs.internalApproved",
        defaultMessage: "Approved",
    },
    [PurchaseOrdersTab.PENDING]: {
        id: "purchase.orders.tabs.pending",
        defaultMessage: "Pending",
    },
    [PurchaseOrdersTab.IN_PREPARATION]: {
        id: "purchase.orders.tabs.inPreparation",
        defaultMessage: "In preparation",
    },
    [PurchaseOrdersTab.SHIPPED]: {
        id: "purchase.orders.tabs.inTransit",
        defaultMessage: "In transit",
    },
    [PurchaseOrdersTab.PARTIALLY_RECEIVED]: {
        id: "purchase.orders.tabs.partiallyReceivedBuyer",
        defaultMessage: "Partially received",
    },
    [PurchaseOrdersTab.RECEIVED]: {
        id: "purchase.orders.tabs.receivedBuyer",
        defaultMessage: "Received",
    },
    [PurchaseOrdersTab.CLOSED]: {
        id: "purchase.orders.tabs.closed",
        defaultMessage: "Closed",
    },
})

const messagesVendor = defineMessages({
    ...messages,
    [PurchaseOrdersTab.PARTIALLY_RECEIVED]: {
        id: "purchase.orders.tabs.partiallyReceivedSeller",
        defaultMessage: "Partially delivered",
    },
    [PurchaseOrdersTab.RECEIVED]: {
        id: "purchase.orders.tabs.receivedSeller",
        defaultMessage: "Delivered",
    },
})

interface TabLabelProps {
    purchaseOrders: PurchaseOrders[]
    tab: PurchaseOrdersTab
}

export const TabLabel = ({ purchaseOrders, tab }: TabLabelProps) => {
    const { formatMessage } = useIntl()
    const { viewType } = useParams()

    const view = viewType ?? ViewTypeI.buyer

    const countByStatus = countPurchaseOrderByStatus(purchaseOrders)

    const message = view === ViewTypeI.buyer ? messages[tab] : messagesVendor[tab]

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const count = countByStatus[tab]

    return (
        <span>
            {formatMessage(message)}
            {tab === PurchaseOrdersTab.ALL || !count ? null : <div className="tab-count"> {count} </div>}
        </span>
    )
}

interface TabLabelFromSummaryProps {
    purchaseOrdersSummary?: PurchaseOrderSummary
    tab: PurchaseOrdersTab
}

export const TabLabelFromSummary = ({ purchaseOrdersSummary, tab }: TabLabelFromSummaryProps) => {
    const { formatMessage } = useIntl()
    const { viewType } = useParams()

    const view = viewType ?? ViewTypeI.buyer

    const message = view === ViewTypeI.buyer ? messages[tab] : messagesVendor[tab]

    const { statusPresentation, fulfillmentPresentation } = purchaseOrdersSummary ?? {}

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const count = statusPresentation?.[tab] ?? fulfillmentPresentation?.[tab] ?? 0

    return (
        <span>
            {formatMessage(message)}
            {tab === PurchaseOrdersTab.ALL || !count ? null : <div className="tab-count"> {count} </div>}
        </span>
    )
}
