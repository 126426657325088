import { AxiosResponse } from "axios"

import { CreatePurchaseResponse } from "~/domains/transactions/_shared/types/Purchases"
import {
    convertPRV2toPR,
    convertPRV2toPRSummary,
    convertPRtoPRV2,
    convertPRtoPRV2ForUpdate,
} from "~/domains/transactions/purchase-requests/core/purchaseRequest"
import {
    CreatePurchaseRequestDTOV2,
    PurchaseRequestDetails,
    PurchaseRequestSummaryAPI,
    PurchaseRequestV2,
    PurchaseRequestV2SummaryAPI,
    UpdatePurchaseRequestDTOV2,
} from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { PurchaseRequestConversionStatus } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { ImportBatchResponseI } from "~/types/ImportBatch"
import { Result } from "~/types/Result"

import axiosClient from "../../../../api/axiosClient"

const BASE_URL = import.meta.env.VITE_API_PURCHASE_URL + "organizations/"
const BASE_URL_V2 = import.meta.env.VITE_API_PURCHASE_URL + "v2/organizations/"
const IMPORT_BASE_URL = import.meta.env.VITE_API_IMPORT_FROM_FILES_URL

export const purchaseRequestApi = {
    create(organizationId: string, purchaseRequest: PurchaseRequestDetails): Promise<CreatePurchaseResponse> {
        const url = `${BASE_URL_V2}${organizationId}/purchase-requests`
        const purchaseRequestV2: CreatePurchaseRequestDTOV2 = convertPRtoPRV2(purchaseRequest)

        return axiosClient.post(url, purchaseRequestV2)
    },
    async findAll(organizationId: string): Promise<PurchaseRequestSummaryAPI[]> {
        const url = `${BASE_URL_V2}${organizationId}/purchase-requests`
        const purchaseRequestsV2: PurchaseRequestV2SummaryAPI[] = await axiosClient.get(url)
        const purchaseRequests: PurchaseRequestSummaryAPI[] = purchaseRequestsV2.map(convertPRV2toPRSummary)

        return purchaseRequests
    },
    async findById(organizationId: string, purchaseRequestId: string): Promise<PurchaseRequestDetails> {
        const url = `${BASE_URL_V2}${organizationId}/purchase-requests/${purchaseRequestId}`
        const purchaseRequestV2: PurchaseRequestV2 = await axiosClient.get(url)
        const purchaseRequest: PurchaseRequestDetails = convertPRV2toPR(purchaseRequestV2)

        return purchaseRequest
    },
    delete(organizationId: string, purchaseRequestId: string): Promise<AxiosResponse> {
        const url = `${BASE_URL}${organizationId}/purchase-requests/${purchaseRequestId}`
        return axiosClient.delete(url)
    },
    update(
        organizationId: string,
        purchaseRequestId: string,
        purchaseRequest: PurchaseRequestDetails
    ): Promise<AxiosResponse> {
        const url = `${BASE_URL_V2}${organizationId}/purchase-requests/${purchaseRequestId}`
        const purchaseRequestV2: UpdatePurchaseRequestDTOV2 = convertPRtoPRV2ForUpdate(purchaseRequest)
        return axiosClient.patch(url, purchaseRequestV2)
    },
    submit(organizationId: string, purchaseRequestId: string): Promise<AxiosResponse> {
        const url = `${BASE_URL}${organizationId}/purchase-requests/${purchaseRequestId}/submit`
        return axiosClient.post(url)
    },
    approve(organizationId: string, purchaseRequestId: string): Promise<AxiosResponse> {
        const url = `${BASE_URL}${organizationId}/purchase-requests/${purchaseRequestId}/approve`
        return axiosClient.post(url)
    },
    reject(organizationId: string, purchaseRequestId: string): Promise<AxiosResponse> {
        const url = `${BASE_URL}${organizationId}/purchase-requests/${purchaseRequestId}/reject`
        return axiosClient.post(url)
    },
    send(organizationId: string, purchaseRequestId: string): Promise<AxiosResponse> {
        const url = `${BASE_URL}${organizationId}/purchase-requests/${purchaseRequestId}/reject`
        return axiosClient.post(url)
    },
    lineApprove(organizationId: string, purchaseRequestId: string, lineId: string): Promise<AxiosResponse> {
        const url = `${BASE_URL}${organizationId}/purchase-requests/${purchaseRequestId}/lines/${lineId}/approve`
        return axiosClient.post(url)
    },
    lineReject(organizationId: string, purchaseRequestId: string, lineId: string): Promise<AxiosResponse> {
        const url = `${BASE_URL}${organizationId}/purchase-requests/${purchaseRequestId}/lines/${lineId}/reject`
        return axiosClient.post(url)
    },
    convertToPurchaseOrder(
        organizationId: string,
        purchaseRequestId: string,
        expectedDeliveryDate: string | null,
        status?: PurchaseRequestConversionStatus
    ): Promise<CreatePurchaseResponse> {
        const url = `${BASE_URL}${organizationId}/purchase-requests/${purchaseRequestId}/to-purchase-order`
        const data: { expectedDeliveryDate?: string; status?: PurchaseRequestConversionStatus } = {}
        if (expectedDeliveryDate) {
            data.expectedDeliveryDate = expectedDeliveryDate
        }
        if (status) {
            data.status = status
        }
        return axiosClient.post(url, data)
    },
    importBatchPR(organizationId: string, attachedFile: File): Promise<Result<ImportBatchResponseI>> {
        const formData = new FormData()
        formData.append("file", attachedFile)
        formData.append("organizationId", organizationId)

        return axiosClient.post(`${IMPORT_BASE_URL}purchase-request/upload-file`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
    },
}
