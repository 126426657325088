import { FC, useState } from "react"

import { Tabs } from "~/components"
import { useDashboardPRStatusTabs } from "~/domains/analytics/dashboard/hooks"
import {
    PurchaseRequestStatus,
    PurchaseRequestSummary,
} from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { UserI } from "~/types"

interface Props {
    purchaseRequests: PurchaseRequestSummary[]
    user: UserI
}

export const DashboardPurchaseRequestTabs: FC<Props> = ({ purchaseRequests, user }) => {
    const tabs = useDashboardPRStatusTabs(purchaseRequests, user)
    const [currentPrTab, setCurrentPrTab] = useState<PurchaseRequestStatus>(PurchaseRequestStatus.SUBMITTED)

    const onPrTabChange = (tab: string | number) => {
        setCurrentPrTab(tab as PurchaseRequestStatus)
    }

    return <Tabs className="dashboard-metrics-tabs" defaultValue={currentPrTab} setChange={onPrTabChange} tabs={tabs} />
}
