import { useCallback, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { batch } from "react-redux"

import { commonMessages } from "~/common-messages"
import { displayAndCaptureError } from "~/displayAndCaptureError"
import { useLazyFetchInvoiceRawDataQuery } from "~/domains/_shared/ocr/api/ocrApi_RTK"
import { budgetApi } from "~/domains/transactions/budget/api/budgetApi"
import { TransactionType } from "~/domains/transactions/budget/types"
import { invoiceApi } from "~/domains/transactions/invoices/api/invoiceApi"
import { useSetInvoiceContext } from "~/domains/transactions/invoices/hooks/useSetInvoiceContext"
import { getIsConnected } from "~/store/auth/authSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { useFetchOrganization } from "~/store/organization/hooks"
import { CountryCode, ImportingInvoiceI, InvoiceI, ViewTypeI } from "~/types"
import { isResultError } from "~/types/Result"

import { invoiceActions, selectInvoice, selectInvoiceFetchError } from "../invoiceSlice"
import { setInvoiceOrganizations } from "../utils/setInvoiceOrganizations"

type FetchInvoiceOptions = {
    viewType: ViewTypeI
    shouldReload?: number
    forceRefetch?: boolean | null
    withOcrData?: boolean
}

export const useFetchInvoice = (
    invoiceId: string | undefined,
    { viewType, shouldReload, forceRefetch, withOcrData }: FetchInvoiceOptions
) => {
    const { formatMessage } = useIntl()
    const [tmpInvoice, setTmpInvoice] = useState<InvoiceI | null>(null)
    const dispatch = useAppDispatch()
    const invoice = useAppSelector(selectInvoice)
    const fetchError = useAppSelector(selectInvoiceFetchError)
    useSetInvoiceContext(invoiceId, viewType, invoice, fetchError)

    const isConnected = useAppSelector(getIsConnected)

    const { organization: buyerOrganization } = useFetchOrganization(tmpInvoice?.buyer?.organizationId)
    const { organization: supplierOrganization } = useFetchOrganization(tmpInvoice?.supplier?.organizationId)

    const [fetchInvoiceRawData, { data: invoiceRawData, status: invoiceRawDataStatus }] =
        useLazyFetchInvoiceRawDataQuery()

    const fetchInvoice = useCallback(async () => {
        if (!invoiceId) return
        try {
            if (withOcrData) {
                fetchInvoiceRawData(invoiceId)
            }
            const invoiceResponse = await invoiceApi.getById(invoiceId)
            if (isResultError(invoiceResponse)) {
                dispatch(invoiceActions.fetchInvoiceTypedFailed(invoiceResponse.error))
                return
            }

            setTmpInvoice({ ...invoiceResponse.result })
        } catch (error) {
            displayAndCaptureError(formatMessage(commonMessages.error), error)
        }
    }, [dispatch, invoiceId])

    useEffect(() => {
        if (forceRefetch || forceRefetch !== null) {
            fetchInvoice()
        }
    }, [fetchInvoice, shouldReload, forceRefetch])

    useEffect(() => {
        if (tmpInvoice && invoiceRawDataStatus !== "pending") {
            const invoiceWithOrganizations = setInvoiceOrganizations(tmpInvoice, {
                buyerOrganization,
                supplierOrganization,
            })
            if (invoiceRawData) {
                if (
                    invoiceWithOrganizations.buyer.countryCode === CountryCode.UNKNOWN &&
                    invoiceRawData.buyer?.countryCode
                ) {
                    invoiceWithOrganizations.buyer.countryCode = invoiceRawData.buyer.countryCode
                }
                if (
                    invoiceWithOrganizations.supplier.countryCode === CountryCode.UNKNOWN &&
                    invoiceRawData.supplier?.countryCode
                ) {
                    invoiceWithOrganizations.supplier.countryCode = invoiceRawData.supplier.countryCode
                }
                if (!invoiceWithOrganizations.paymentReferenceNumber && invoiceRawData.paymentDetails?.kid) {
                    invoiceWithOrganizations.paymentReferenceNumber = invoiceRawData.paymentDetails?.kid as string
                }
                const importingInvoice: ImportingInvoiceI = {
                    ...invoiceWithOrganizations,
                    loaded: true,
                }
                dispatch(
                    invoiceActions.setCreatingInvoice({
                        invoice: importingInvoice,
                        error: null,
                        otherError: null,
                        suggestedSupplierCompanies: invoiceRawData.suggestedSupplierCompanies,
                        suggestedBuyerCompanies: invoiceRawData.suggestedBuyerCompanies,
                        ocrBuyer: invoiceRawData.buyer,
                        ocrSupplier: invoiceRawData.supplier,
                    })
                )
            }
            const allInvolvedPeople = new Set(
                [...invoiceWithOrganizations.involvedPeople, ...invoiceWithOrganizations.supplierInvolvedPeople].map(
                    (person) => person.userId
                )
            )

            /* Invoice in Budgets */
            if (
                invoiceWithOrganizations &&
                ((viewType === ViewTypeI.buyer && buyerOrganization) ||
                    (viewType === ViewTypeI.supplier && supplierOrganization))
            ) {
                if (isConnected) {
                    const organizationId =
                        viewType === ViewTypeI.buyer ? buyerOrganization?.id : supplierOrganization?.id
                    budgetApi
                        .getBudgetByTransaction(
                            organizationId || "",
                            invoiceWithOrganizations.id,
                            TransactionType.INVOICE
                        )
                        .then((result) => {
                            dispatch(invoiceActions.setInvoice({ ...invoiceWithOrganizations, budgets: result }))
                        })
                }
                batch(() => {
                    dispatch(invoiceActions.setInvoice(invoiceWithOrganizations))
                    dispatch(invoiceActions.addPeople([...allInvolvedPeople]))
                })
            } else {
                dispatch(invoiceActions.setInvoice(invoiceWithOrganizations))
            }
        }
    }, [buyerOrganization, supplierOrganization, tmpInvoice, invoiceRawData, invoiceRawDataStatus])

    useEffect(() => {
        if (invoice?.id && invoice.version !== "V1" && isConnected) {
            dispatch(invoiceActions.fetchCommunication(invoice.id))
            dispatch(invoiceActions.fetchEvents(invoice.id))
        }
    }, [invoice?.id, isConnected])

    return { invoice, buyerOrganization, supplierOrganization, fetchError, refetchInvoice: fetchInvoice }
}
