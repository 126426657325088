import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Button,
    Divider,
    MenuItem,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import cls from "classnames"
import dayjs, { Dayjs } from "dayjs"
import React, { FC, Fragment, useEffect, useState } from "react"
import { Plus } from "react-feather"
import { useIntl } from "react-intl"
import { v4 as uuid } from "uuid"

import { SafeFormattedMessage } from "~/components"
import { organizationApi } from "~/domains/identity/organization/api/organisationApi"
import { AdvancedFields } from "~/domains/orchestration/flows/components/configuration/AdvancedFields"
import { ConfigurationNode } from "~/domains/orchestration/flows/components/configuration/ConfigurationNode"
import { messages } from "~/domains/orchestration/flows/locale"
import {
    AdaptedPurchaseOrder,
    Address,
    BuyerOrSupplier,
    ConfigurationProps,
    CreatePurchaseOrderNode,
    PoCreationStatus,
    PurchaseOrderLine as PurchaseOrderLineType,
} from "~/domains/orchestration/flows/types"
import { isExpression } from "~/domains/orchestration/flows/utils"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { OrganizationI } from "~/types"

import { AddressFields, OrganizationSelector, PurchaseOrderLine } from "./CreatePurchaseOrderComponents"

const formatOrganizationAddress = (organizationAddress: OrganizationI["address"] | undefined): Address | undefined => {
    if (!organizationAddress) return undefined

    return {
        street: organizationAddress.addressLine || "",
        street2: organizationAddress.secondaryAddressLine || "",
        city: organizationAddress.city || "",
        zipCode: organizationAddress.zipCode || "",
        country: organizationAddress.country || "",
    }
}

const adaptPurchaseOrder = ({
    buyerOrganization,
    supplierOrganization,
    currentNode,
}: {
    buyerOrganization?: OrganizationI
    supplierOrganization?: OrganizationI
    buyerOrSupplier: BuyerOrSupplier
    currentNode: CreatePurchaseOrderNode
}): AdaptedPurchaseOrder | null => {
    const buyerId = buyerOrganization?.id || ""
    const supplierId = supplierOrganization?.id || ""

    const buyerName = buyerOrganization?.name || ""
    const supplierName = supplierOrganization?.name || ""

    return {
        buyerId: currentNode.buyerId || buyerId,
        supplierId: currentNode.supplierId || supplierId,
        buyerName: buyerName || "",
        supplierName: supplierName || "",
        billingAddress: currentNode.billingAddress || formatOrganizationAddress(buyerOrganization?.address),
        shippingAddress: currentNode.shippingAddress || formatOrganizationAddress(buyerOrganization?.address),
        description: currentNode.description || "",
        lines: currentNode.lines,
        status: currentNode.status || PoCreationStatus.DRAFT,
        supplierEmail: currentNode.supplierEmail || "",
        shortId: currentNode.shortId || "",
        expectedDeliveryDate: currentNode.expectedDeliveryDate || "",
    }
}

export const CreatePurchaseOrderConfiguration: FC<ConfigurationProps<CreatePurchaseOrderNode>> = ({
    selectedNode,
    advancedFields,
    validateNode,
}) => {
    const [currentNode, setCurrentNode] = useState(selectedNode)
    const { locale, formatMessage } = useIntl()
    const userOrganization = useAppSelector(selectCurrentOrganization)
    const initialBuyerOrSupplier =
        userOrganization?.id === currentNode.supplierId ? BuyerOrSupplier.SUPPLIER : BuyerOrSupplier.BUYER

    const [buyerOrSupplier, setBuyerOrSupplier] = useState<BuyerOrSupplier>(initialBuyerOrSupplier)

    const isUserSupplier = buyerOrSupplier === BuyerOrSupplier.SUPPLIER
    const isUserBuyer = buyerOrSupplier === BuyerOrSupplier.BUYER

    const [buyerOrganization, setBuyerOrganization] = useState<OrganizationI | undefined>(
        isUserBuyer ? userOrganization : undefined
    )
    const [supplierOrganization, setSupplierOrganization] = useState<OrganizationI | undefined>(
        isUserSupplier ? userOrganization : undefined
    )

    const [pickerOpen, setPickerOpen] = useState(false)

    const handleChange = (field: keyof CreatePurchaseOrderNode) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentNode((prev) => ({
            ...prev,
            [field]: event.target.value,
        }))
    }

    const handleDateChange = (date: Dayjs | null) => {
        setCurrentNode((prev) => ({
            ...prev,
            expectedDeliveryDate: date?.toISOString(),
        }))
    }

    const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentNode((prev) => ({
            ...prev,
            status: event.target.value as PoCreationStatus,
        }))
    }

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentNode((prev) => ({
            ...prev,
            description: event.target.value,
        }))
    }

    const handleAddLine = () => {
        setCurrentNode((prev) => ({
            ...prev,
            lines: [
                ...prev.lines,
                {
                    id: uuid(),
                    description: "",
                    quantity: "1",
                    unitPrice: { amount: "0", currency: "EUR" },
                    unitPriceExcludingTax: { amount: "0", currency: "EUR" },
                    taxRate: "0",
                },
            ],
        }))
    }

    const handleLineChange = (id: string) => (line: PurchaseOrderLineType) => {
        setCurrentNode((prev) => ({
            ...prev,
            lines: prev.lines.map((l) => (l.id === id ? line : l)),
        }))
    }

    const handleDeleteLine = (id: string) => () => {
        setCurrentNode((prev) => ({
            ...prev,
            lines: prev.lines.filter((line) => line.id !== id),
        }))
    }

    const handleSupplierChange = (org: OrganizationI) => {
        setSupplierOrganization(org)
        setCurrentNode((prev) => ({
            ...prev,
            supplierId: org.id,
            supplierName: org.name,
        }))
    }

    const handleBuyerChange = (org: OrganizationI) => {
        setBuyerOrganization(org)
        setCurrentNode((prev) => ({
            ...prev,
            buyerId: org.id,
            buyerName: org.name,
            shippingAddress: formatOrganizationAddress(org.address),
            billingAddress: formatOrganizationAddress(org.address),
        }))
    }

    const handleRoleToggle = (_: React.MouseEvent<HTMLElement>, newRole: BuyerOrSupplier | null) => {
        if (newRole !== null) {
            const isNewRoleSupplier = newRole === BuyerOrSupplier.SUPPLIER
            const isNewRoleBuyer = newRole === BuyerOrSupplier.BUYER

            const newBuyerOrganization = isNewRoleSupplier ? supplierOrganization : userOrganization
            const newSupplierOrganization = isNewRoleBuyer ? buyerOrganization : userOrganization

            setBuyerOrSupplier(newRole)
            setBuyerOrganization(newBuyerOrganization)
            setSupplierOrganization(newSupplierOrganization)

            const newAddress = formatOrganizationAddress(newBuyerOrganization?.address)

            setCurrentNode((prev) => ({
                ...prev,
                buyerId: newBuyerOrganization?.id || "",
                supplierId: newSupplierOrganization?.id || "",
                shippingAddress: newAddress,
                billingAddress: newAddress,
                supplierEmail: "",
                lines: prev.lines.map((line) => ({
                    ...line,
                    buyerItemId: isNewRoleSupplier ? "" : line.buyerItemId,
                    supplierItemId: isNewRoleBuyer ? "" : line.supplierItemId,
                })),
            }))
        }
    }

    const handleShippingAddressChange = (address: Address) => {
        setCurrentNode((prev) => ({
            ...prev,
            shippingAddress: address,
        }))
    }

    const handleBillingAddressChange = (address: Address) => {
        setCurrentNode((prev) => ({
            ...prev,
            billingAddress: address,
        }))
    }
    const handleOpenPicker = () => setPickerOpen(true)
    const handleClosePicker = () => setPickerOpen(false)

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    const purchaseOrder = adaptPurchaseOrder({
        buyerOrganization,
        supplierOrganization,
        buyerOrSupplier,
        currentNode,
    })

    useEffect(() => {
        const otherOrganizationId = isUserSupplier ? purchaseOrder?.buyerId : purchaseOrder?.supplierId
        const getOtherOrganization = async () => {
            if (!otherOrganizationId) return

            const otherOrganization = await organizationApi.getOrganizationById(otherOrganizationId)
            if (isUserSupplier) {
                setBuyerOrganization(() => otherOrganization)
            } else {
                setSupplierOrganization(() => otherOrganization)
            }
        }

        if (otherOrganizationId && isExpression(otherOrganizationId)) {
            getOtherOrganization()
        }
        setCurrentNode((prev) => ({
            ...prev,
            buyerId: purchaseOrder?.buyerId || "",
            supplierId: purchaseOrder?.supplierId || "",
        }))
    }, [isUserSupplier, purchaseOrder?.buyerId, purchaseOrder?.supplierId])

    if (!purchaseOrder) {
        return null
    }

    const expectedDeliveryDate = purchaseOrder.expectedDeliveryDate ? dayjs(purchaseOrder.expectedDeliveryDate) : null

    return (
        <ConfigurationNode selectedNode={currentNode} validateNode={validateNode}>
            {purchaseOrder.lines.length === 0 && (
                <Alert severity="error">
                    <SafeFormattedMessage {...messages.createPurchaseOrderConfiguration.noLinesAddedAlert} />
                </Alert>
            )}
            <Stack spacing={2} className={configurationNodeItemClassName}>
                <ToggleButtonGroup value={buyerOrSupplier} exclusive onChange={handleRoleToggle} fullWidth>
                    <ToggleButton value={BuyerOrSupplier.BUYER}>
                        <SafeFormattedMessage {...messages.createPurchaseOrderConfiguration.actingAsBuyer} />
                    </ToggleButton>
                    <ToggleButton value={BuyerOrSupplier.SUPPLIER}>
                        <SafeFormattedMessage {...messages.createPurchaseOrderConfiguration.actingAsSupplier} />
                    </ToggleButton>
                </ToggleButtonGroup>

                <OrganizationSelector
                    key={purchaseOrder.buyerId}
                    title={formatMessage(messages.createPurchaseOrderConfiguration.buyerTitle)}
                    organizationId={purchaseOrder.buyerId}
                    organizationName={purchaseOrder.buyerName}
                    isUserOrganization={isUserBuyer}
                    onOrganizationSelect={handleBuyerChange}
                />

                <OrganizationSelector
                    key={purchaseOrder.supplierId}
                    title={formatMessage(messages.createPurchaseOrderConfiguration.supplierTitle)}
                    organizationId={purchaseOrder.supplierId}
                    organizationName={purchaseOrder.supplierName}
                    onOrganizationSelect={handleSupplierChange}
                    isUserOrganization={isUserSupplier}
                />

                <TextField
                    label={formatMessage(messages.createPurchaseOrderConfiguration.descriptionLabel)}
                    value={purchaseOrder.description}
                    onChange={handleDescriptionChange}
                    fullWidth
                    required
                />

                <TextField
                    select
                    label={formatMessage(messages.createPurchaseOrderConfiguration.statusLabel)}
                    value={purchaseOrder.status}
                    onChange={handleStatusChange}
                    fullWidth
                >
                    {Object.values(PoCreationStatus).map((option) => (
                        <MenuItem key={option} value={option}>
                            <SafeFormattedMessage {...messages.purchaseOrderCreationStatusOptions[option]} />
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    label={formatMessage(messages.createPurchaseOrderConfiguration.supplierEmailLabel)}
                    value={purchaseOrder.supplierEmail || ""}
                    onChange={handleChange("supplierEmail")}
                    fullWidth
                />

                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                    <DatePicker
                        open={pickerOpen}
                        onOpen={handleOpenPicker}
                        onClose={handleClosePicker}
                        label={
                            <SafeFormattedMessage
                                {...messages.createPurchaseOrderConfiguration.expectedDeliveryDateLabel}
                            />
                        }
                        value={expectedDeliveryDate}
                        onChange={handleDateChange}
                        slotProps={{
                            textField: {
                                id: "expected-delivery-date",
                                onClick: handleOpenPicker,
                            },
                        }}
                    />
                </LocalizationProvider>

                <Accordion>
                    <AccordionSummary>
                        <Typography>
                            <SafeFormattedMessage {...messages.createPurchaseOrderConfiguration.billingAddressLabel} />
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <AddressFields
                            address={purchaseOrder.billingAddress}
                            onChange={handleBillingAddressChange}
                            label={formatMessage(messages.createPurchaseOrderConfiguration.billingAddressLabel)}
                        />
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary>
                        <Typography>
                            <SafeFormattedMessage {...messages.createPurchaseOrderConfiguration.shippingAddressLabel} />
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <AddressFields
                            address={purchaseOrder.shippingAddress}
                            onChange={handleShippingAddressChange}
                            label={formatMessage(messages.createPurchaseOrderConfiguration.shippingAddressLabel)}
                        />
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary>
                        <Typography>
                            <SafeFormattedMessage {...messages.createPurchaseOrderConfiguration.linesLabel} />
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack spacing={2}>
                            {purchaseOrder.lines.map((line) => (
                                <Fragment key={line.id}>
                                    <PurchaseOrderLine
                                        line={line}
                                        onChange={handleLineChange(line.id)}
                                        buyerOrSupplier={buyerOrSupplier}
                                        onDelete={handleDeleteLine(line.id)}
                                    />
                                    <Divider />
                                </Fragment>
                            ))}
                            <Button startIcon={<Plus size={16} />} onClick={handleAddLine} variant="outlined" fullWidth>
                                <SafeFormattedMessage {...messages.createPurchaseOrderConfiguration.addLineLabel} />
                            </Button>
                        </Stack>
                    </AccordionDetails>
                </Accordion>
            </Stack>

            <AdvancedFields<CreatePurchaseOrderNode>
                fields={advancedFields}
                currentNode={currentNode}
                setCurrentNode={setCurrentNode}
            />
        </ConfigurationNode>
    )
}
