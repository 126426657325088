import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import { FC, ReactNode, useState } from "react"
import { useIntl } from "react-intl"

interface ExtractIssueDateProps {
    label: ReactNode
    issueDate: string | undefined | null
    onChange: (newValue: dayjs.Dayjs | null) => void
    disabled?: boolean
}

export const ExtractGenericDate: FC<ExtractIssueDateProps> = ({ label, issueDate, onChange, disabled }) => {
    const { locale } = useIntl()

    const [datePickerOpen, setDatePickerOpen] = useState(false)

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
            <DatePicker
                open={datePickerOpen}
                onOpen={() => setDatePickerOpen(true)}
                onClose={() => setDatePickerOpen(false)}
                label={label}
                value={issueDate ? dayjs(issueDate) : null}
                onChange={onChange}
                disabled={disabled}
                slotProps={{
                    textField: {
                        required: true,
                        onClick: () => setDatePickerOpen(true),
                    },
                }}
            />
        </LocalizationProvider>
    )
}
