import { FulfillmentStatus } from "~/domains/transactions/_shared/types/Purchases"
import { PurchaseOrderStatusTypes } from "~/domains/transactions/purchase-orders/core/lifecyclePurchaseOrder"
import { isStatusNotCurrent } from "~/domains/transactions/purchase-orders/core/lifecyclePurchaseOrder"
import { isStatusCurrentOrCompleted } from "~/domains/transactions/purchase-orders/core/lifecyclePurchaseOrder"
import { statusesLinePurchaseOrder } from "~/domains/transactions/purchase-orders/core/statuses-line"
import { PurchaseOrderApprovalStatus } from "~/domains/transactions/purchase-orders/types"
import { ViewTypeI } from "~/types"

interface LifecycleLinePurchaseOrder {
    position: number
    actions: string[]
}

export const getLifecycleLinePurchaseOrder = (status: FulfillmentStatus): LifecycleLinePurchaseOrder | undefined => {
    return statusesLinePurchaseOrder[status] as LifecycleLinePurchaseOrder | undefined
}

const isFulfillmentStatusCurrent = (status: FulfillmentStatus, statusToCompare: FulfillmentStatus) =>
    (getLifecycleLinePurchaseOrder(status)?.position || 0) ===
    (getLifecycleLinePurchaseOrder(statusToCompare)?.position || 0)

export const isFulfillmentStatusNotCurrent = (status: FulfillmentStatus, statusToCompare: FulfillmentStatus) =>
    (getLifecycleLinePurchaseOrder(status)?.position || 0) !==
    (getLifecycleLinePurchaseOrder(statusToCompare)?.position || 0)

const isFulfillmentStatusCompleted = (status: FulfillmentStatus, statusToCompare: FulfillmentStatus) =>
    (getLifecycleLinePurchaseOrder(status)?.position || 0) >
    (getLifecycleLinePurchaseOrder(statusToCompare)?.position || 0)

const isFulfillmentStatusIncompleted = (status: FulfillmentStatus, statusToCompare: FulfillmentStatus) =>
    (getLifecycleLinePurchaseOrder(status)?.position || 0) <
    (getLifecycleLinePurchaseOrder(statusToCompare)?.position || 0)

export const isFulfillmentStatusCurrentOrCompleted = (status: FulfillmentStatus, statusToCompare: FulfillmentStatus) =>
    isFulfillmentStatusCurrent(status, statusToCompare) || isFulfillmentStatusCompleted(status, statusToCompare)

export const isFulfillmentStatusCurrentOrIncompleted = (
    status: FulfillmentStatus,
    statusToCompare: FulfillmentStatus
) => isFulfillmentStatusCurrent(status, statusToCompare) || isFulfillmentStatusIncompleted(status, statusToCompare)

export const canRejectLinePurchaseOrder = (
    view: ViewTypeI,
    POStatus: PurchaseOrderStatusTypes,
    approvalStatus: PurchaseOrderApprovalStatus,
    buyerFulfillment: FulfillmentStatus,
    supplierFulfillment: FulfillmentStatus,
    isApprovalRequired: boolean
) =>
    isStatusNotCurrent(POStatus, "CLOSED") &&
    ((isStatusCurrentOrCompleted(POStatus, "SUBMITTED") &&
        ["PENDING", "APPROVED"].includes(approvalStatus) &&
        (view === ViewTypeI.buyer ? buyerFulfillment === "PENDING" : supplierFulfillment === "PENDING")) ||
        isApprovalRequired)

export const canApproveLinePurchaseOrder = (
    view: ViewTypeI,
    POStatus: PurchaseOrderStatusTypes,
    approvalStatus: PurchaseOrderApprovalStatus,
    buyerFulfillment: FulfillmentStatus,
    supplierFulfillment: FulfillmentStatus,
    isApprovalRequired: boolean
) =>
    isStatusNotCurrent(POStatus, "CLOSED") &&
    ((isStatusCurrentOrCompleted(POStatus, "SUBMITTED") &&
        ["PENDING", "REJECTED"].includes(approvalStatus) &&
        (view === ViewTypeI.buyer ? buyerFulfillment === "PENDING" : supplierFulfillment === "PENDING")) ||
        isApprovalRequired)

export const canMarkInPreparationLinePurchaseOrder = (
    view: ViewTypeI,
    POStatus: PurchaseOrderStatusTypes,
    buyerFulfillment: FulfillmentStatus,
    supplierFulfillment: FulfillmentStatus
) => {
    if (view === ViewTypeI.buyer || isStatusNotCurrent(POStatus, "OPEN")) {
        return false
    }

    return isFulfillmentStatusCurrent(supplierFulfillment, "PENDING")
}

export const canMarkOutFordeliveryLinePurchaseOrder = (
    view: ViewTypeI,
    POStatus: PurchaseOrderStatusTypes,
    buyerFulfillment: FulfillmentStatus,
    supplierFulfillment: FulfillmentStatus
) => {
    if (view === ViewTypeI.buyer || isStatusNotCurrent(POStatus, "OPEN")) {
        return false
    }

    return isFulfillmentStatusCurrent(supplierFulfillment, "IN_PREPARATION")
}

export const canMarkDeliveredLinePurchaseOrder = (
    view: ViewTypeI,
    POStatus: PurchaseOrderStatusTypes,
    buyerFulfillment: FulfillmentStatus,
    supplierFulfillment: FulfillmentStatus
) => {
    if (isStatusNotCurrent(POStatus, "OPEN")) {
        return false
    }

    if (view === ViewTypeI.buyer) {
        return isFulfillmentStatusIncompleted(buyerFulfillment, "DELIVERED")
    }

    return isFulfillmentStatusCurrent(supplierFulfillment, "OUT_FOR_DELIVERY")
}

export const canMarkCanceledLinePurchaseOrder = (
    view: ViewTypeI,
    POStatus: PurchaseOrderStatusTypes,
    supplierFulfillment: FulfillmentStatus
) => {
    if (view === ViewTypeI.buyer || isStatusNotCurrent(POStatus, "OPEN")) {
        return false
    }

    return isFulfillmentStatusCurrent(supplierFulfillment, "PENDING")
}
