import { InvoiceParty } from "~/domains/transactions/invoices-v1/types/Invoice"
import { CountryCode, ImportInvoiceCompanyInfoI, isValidCountryCode } from "~/types"

export const organizationFromApiAdapter = (organization: InvoiceParty): ImportInvoiceCompanyInfoI => {
    const countryCode =
        organization.billingAddress?.country?.length === 2 ? organization.billingAddress?.country.toUpperCase() : null
    return {
        countryCode: countryCode && isValidCountryCode(countryCode) ? countryCode : CountryCode.UNKNOWN,
        organizationId: organization.id ?? "",
        name: "",
        contactName: "",
    }
}
