import {
    Autocomplete,
    AutocompleteRenderGetTagProps,
    AutocompleteRenderInputParams,
    Chip,
    TextField,
    styled,
} from "@mui/material"
import React, { FC, useCallback } from "react"

import { SafeFormattedMessage } from "~/components"
import { messages } from "~/domains/orchestration/flows/locale"
import { OrganizationTeamI } from "~/types"

const Tag = styled(Chip)`
    margin: var(--spacing-xxs);
`

interface TeamSelectProps {
    teams: OrganizationTeamI[]
    allTeams: OrganizationTeamI[]
    loading: boolean
    onSelectTeam: (event: unknown, values: OrganizationTeamI[]) => void
}

const getOptionLabel = (option: OrganizationTeamI) => option.name ?? ""
const getOptionKey = (option: OrganizationTeamI) => option.teamId
const isOptionEqualToValue = (option: OrganizationTeamI, value: OrganizationTeamI) => option.teamId === value.teamId

const renderTags = (value: OrganizationTeamI[], getTagProps: AutocompleteRenderGetTagProps) => {
    return value.map((option, index) => {
        const { onDelete } = getTagProps({ index })
        const name = option?.name ?? ""
        return <Tag key={option.teamId} label={name} variant="outlined" onDelete={onDelete} />
    })
}

export const TeamSelect: FC<TeamSelectProps> = ({ teams, allTeams, loading, onSelectTeam }) => {
    const renderInput = useCallback(
        (params: AutocompleteRenderInputParams) => (
            <TextField {...params} label={<SafeFormattedMessage {...messages.checkNodeConfiguration.team} />} />
        ),
        []
    )

    return (
        <Autocomplete
            multiple
            autoComplete
            loading={loading}
            options={allTeams}
            value={teams}
            renderTags={renderTags}
            getOptionKey={getOptionKey}
            getOptionLabel={getOptionLabel}
            filterSelectedOptions
            isOptionEqualToValue={isOptionEqualToValue}
            onChange={onSelectTeam}
            renderInput={renderInput}
        />
    )
}
