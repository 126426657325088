import { Box, Stack, Typography, styled } from "@mui/material"
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath, useReactFlow } from "@xyflow/react"
import React from "react"
import { X } from "react-feather"
import { useIntl } from "react-intl"

import { EdgeLabel } from "~/domains/orchestration/flows/components/EdgeLabel"
import { IconButton } from "~/domains/orchestration/flows/components/IconButton"
import { useEditor, useEditorDispatch } from "~/domains/orchestration/flows/context/editorContext"
import { messages } from "~/domains/orchestration/flows/locale"
import { isBranchNode } from "~/domains/orchestration/flows/types/"

const Label = styled(Typography)({
    backgroundColor: "var(--color-white)",
    padding: "var(--spacing-xs)",
    borderRadius: "var(--border-radius-sm)",
})

const DeleteButton = styled(IconButton)({
    opacity: 0,
})

export const ButtonEdgeWithLabel = ({
    id,
    target,
    source,
    sourceHandleId,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
}: EdgeProps) => {
    const { setEdges } = useReactFlow()
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    })
    const { formatMessage } = useIntl()
    const state = useEditor()
    const currentFlow = state.flow
    const dispatch = useEditorDispatch()
    const node = currentFlow?.nodes.find((n) => n.slug === source)

    if (!node || !isBranchNode(node)) return null
    const branchIndex = node.branches.findIndex((branch) => branch.nextNode === target)

    const isDefaultBranchEdge = isBranchNode(node) && sourceHandleId?.includes("default")

    const edgeLabel = isDefaultBranchEdge
        ? formatMessage(messages.branchNode.defaultBranch)
        : node.branches.find((branch) => branch.nextNode === target)?.name || ""

    const handleDelete = () => {
        const newBranches = [...node.branches]
        if (isDefaultBranchEdge) {
            dispatch({
                type: "UPDATE_NODE",
                payload: {
                    ...node,
                    default: "",
                },
            })
        } else {
            newBranches[branchIndex] = {
                ...newBranches[branchIndex],
                nextNode: null,
            }
            dispatch({
                type: "UPDATE_NODE",
                payload: {
                    ...node,
                    branches: newBranches,
                },
            })
        }

        setEdges((edges) => edges.filter((edge) => edge.id !== id))
    }

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
            <EdgeLabelRenderer>
                <EdgeLabel labelX={labelX} labelY={labelY} className="nodrag nopan">
                    <Box>
                        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                            <DeleteButton type="close" onClick={handleDelete}>
                                <X size={14} />
                            </DeleteButton>
                            <Label variant="body2">{edgeLabel}</Label>
                        </Stack>
                    </Box>
                </EdgeLabel>
            </EdgeLabelRenderer>
        </>
    )
}
