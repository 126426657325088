import { Grid } from "@mui/material"
import { defineMessages } from "react-intl"

import { SafeFormattedMessage } from "~/components"

const messages = defineMessages({
    emptyList: {
        id: "dashboard.list.empty",
        defaultMessage: "No items to display",
    },
})

export const DashboardEmptyState = () => (
    <Grid container justifyContent="center" alignItems="center" padding={5}>
        <SafeFormattedMessage {...messages.emptyList} />
    </Grid>
)
