import dayjs from "dayjs"
import { useEffect, useState } from "react"

import {
    DocumentForApproval,
    DocumentForApprovalType,
    isPurchaseOrderforApproval,
    isPurchaseRequestforApproval,
} from "~/domains/analytics/dashboard/types/DocumentForApproval"
import { DocumentType } from "~/domains/identity/documents/types"
import { ListPurchaseOrders } from "~/domains/transactions/purchase-orders/types"
import {
    PurchaseRequestStatus,
    PurchaseRequestSummary,
} from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { selectUser } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"
import { InvoiceStatus, ReceivedDocumentI } from "~/types"

/**
 * Calculates monthly and change for an array of items
 * @param items - an array of items
 * @param statusProperty - property used for filtering the items
 * @param expectedStatus - status used for filtering
 * @param dateProperty - which property holds the date
 * @param setThisMonth - state function to call to set monthly value
 * @param setPercentage - state function to call to set percentage value
 */
const calculateMonthlyChange = <T>(
    items: T[],
    statusProperty: keyof T,
    expectedStatus: string | number,
    userIdProperty: keyof T,
    userId: string,
    dateProperty: keyof T
) => {
    let currentMonthCount = 0
    let previousMonthCount = 0

    const startOfCurrentMonth = dayjs().startOf("month")
    const startOfPreviousMonth = dayjs().subtract(1, "month").startOf("month")

    items.forEach((item) => {
        if (
            item[statusProperty] === expectedStatus &&
            item[userIdProperty] === userId &&
            typeof item[dateProperty] === "string"
        ) {
            const itemDate = dayjs(item[dateProperty])
            if (itemDate.isAfter(startOfCurrentMonth)) {
                currentMonthCount++
            } else if (itemDate.isAfter(startOfPreviousMonth)) {
                previousMonthCount++
            }
        }
    })

    const percentage =
        previousMonthCount !== 0 ? ((currentMonthCount - previousMonthCount) / previousMonthCount) * 100 : 0
    return { currentMonthCount, percentage }
}

interface DashboardMetricsProps {
    purchaseRequests: PurchaseRequestSummary[]
    purchaseOrders: ListPurchaseOrders
    receivedDocuments: ReceivedDocumentI[]
}

const getCreationDate = (approval: DocumentForApproval) => {
    if (isPurchaseRequestforApproval(approval)) {
        return approval.document.creationDate
    }
    if (isPurchaseOrderforApproval(approval)) {
        return approval.document.creationDate
    }
    return approval.document.receptionDate
}

const sortApprovalsByDate = (docA: DocumentForApproval, docB: DocumentForApproval) => {
    const compareDateA = dayjs(getCreationDate(docA))
    const compareDateB = dayjs(getCreationDate(docB))
    if (compareDateA.isAfter(compareDateB)) {
        return -1
    } else if (compareDateB.isAfter(compareDateA)) {
        return 1
    }
    return 0
}

export const useDashboardMetrics = ({ purchaseRequests, purchaseOrders, receivedDocuments }: DashboardMetricsProps) => {
    const user = useAppSelector(selectUser)
    const [metrics, setMetrics] = useState({
        pr: { currentMonthCount: 0, percentage: 0 },
        po: { currentMonthCount: 0, percentage: 0 },
        invoices: { currentMonthCount: 0, percentage: 0 },
    })
    const [approvals, setApprovals] = useState<Array<DocumentForApproval>>([])

    useEffect(() => {
        const prMetrics = calculateMonthlyChange(
            purchaseRequests,
            "status",
            PurchaseRequestStatus.APPROVED,
            "requesterUserId",
            user.id,
            "creationDate"
        )
        const poMetrics = calculateMonthlyChange(
            purchaseOrders,
            "statusPresentation",
            "INTERNALLY_APPROVED",
            "requesterUserId",
            user.id,
            "creationDate"
        )
        const invoicesMetrics = calculateMonthlyChange(
            receivedDocuments,
            "invoiceType",
            DocumentType.INVOICE,
            "userId",
            user.id,
            "receptionDate"
        )

        setMetrics({ pr: prMetrics, po: poMetrics, invoices: invoicesMetrics })

        const filteredPurchaseRequests = purchaseRequests
            .filter((pr) => pr.status === PurchaseRequestStatus.SUBMITTED)
            .map((document): DocumentForApproval => ({ type: DocumentForApprovalType.PURCHASE_REQUEST, document }))

        const filteredPurchaseOrders = purchaseOrders
            .filter((po) => po.status === "OPEN")
            .map((document): DocumentForApproval => ({ type: DocumentForApprovalType.PURCHASE_ORDER, document }))

        const filteredInvoices = receivedDocuments
            .filter((doc) => doc.invoiceType === DocumentType.INVOICE && doc.status === InvoiceStatus.CONFIRMED)
            .map((document): DocumentForApproval => ({ type: DocumentForApprovalType.INVOICE, document }))

        const approvalsList = [...filteredPurchaseRequests, ...filteredPurchaseOrders, ...filteredInvoices].sort(
            sortApprovalsByDate
        )

        setApprovals(approvalsList)

        return () => {
            setMetrics({
                pr: { currentMonthCount: 0, percentage: 0 },
                po: { currentMonthCount: 0, percentage: 0 },
                invoices: { currentMonthCount: 0, percentage: 0 },
            })
            setApprovals([])
        }
    }, [purchaseRequests, purchaseOrders, receivedDocuments, user.id])

    return { metrics, approvals }
}
