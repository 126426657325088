import { Disc } from "react-feather"

import { EntityTriggerConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { EntityTriggerNode } from "~/domains/orchestration/flows/components/nodes"
import { hasCurrencyConversion } from "~/domains/orchestration/flows/types"
import { Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    CurrencyConversionMode,
    EditorNode,
    EntityTriggerNode as EntityTriggerNodeType,
    Node,
    NodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const entityTriggerNode: Omit<EntityTriggerNodeType, keyof Node> = {
    entity: null,
    filter: [],
    nextNode: null,
    type: NodeType.ENTITY_TRIGGER_NODE,
}

const advancedFields: (keyof typeof entityTriggerNode)[] = []

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.ENTITY_TRIGGER_NODE) return false

    if (!node.entity || !node.entity.startMode || !node.entity.restartMode) return false

    if (hasCurrencyConversion(node.entity)) {
        if (!node.entity.currencyConversion) return false
        if (!node.entity.currencyConversion.type) return false
        if (node.entity.currencyConversion.type === CurrencyConversionMode.ENABLED) {
            if (!node.entity.currencyConversion.targetCurrency) return false
        }
    }

    return true
}

const entityTriggerConfig: Configuration<NodeType.ENTITY_TRIGGER_NODE> = {
    type: NodeType.ENTITY_TRIGGER_NODE,
    baseSlug: "entity_trigger",
    Icon: Disc,
    Configuration: EntityTriggerConfiguration,
    advancedFields,
    Node: EntityTriggerNode,
    factory: (props: FactoryProps): EntityTriggerNodeType => ({
        ...entityTriggerNode,
        ...createCommonNodeProperties(props),
        error: true,
    }),
    validateNode,
}

export { entityTriggerConfig, advancedFields as entityTriggerAdvancedFields }
