import { useCallback, useEffect } from "react"

import { useEditor, useEditorDispatch } from "~/domains/orchestration/flows/context/editorContext"
import { Flow } from "~/domains/orchestration/flows/types/"
import useLocalStorage from "~/hooks/useLocalStorage"

export const useFlowHistory = (flowId: string) => {
    const { history } = useEditor()
    const dispatch = useEditorDispatch()

    // Store the latest state in localStorage
    const [savedState, setSavedState] = useLocalStorage<Flow | null>(`flow-history-${flowId}`, null)

    const restoreFromLocalStorage = useCallback(() => {
        if (savedState) {
            dispatch({
                type: "SET_FLOW",
                payload: savedState,
            })
            dispatch({
                type: "SET_HISTORY",
                payload: [savedState],
            })
        }
    }, [dispatch, savedState])

    useEffect(() => {
        if (history.length > 1) {
            setSavedState(history.at(-1) ?? null)
        }
    }, [dispatch, history, setSavedState])

    const canRestoreFlow = history.length < 2 && history.at(-1)?.version === savedState?.version

    return {
        canRestoreFlow,
        restoreFromLocalStorage,
    }
}
