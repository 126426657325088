import { Stack } from "@mui/material"
import { Link } from "react-router-dom"

import { MenuItem, sideBarMenuItems } from "~/components"
import { SafeFormattedMessage } from "~/components"
import { PlusIcon } from "~/components/Icons"
import { getSidebarFeatureFlags } from "~/components/Sidebar/getSidebarFeatureFlags"
import { OcrPendingJobsQueue } from "~/domains/transactions/invoices/_views/ocr/components/OcrPendingJobsQueue"
import { HOME_ROUTE, UPLOAD_ROUTE } from "~/routes/routes"
import { selectDisplayMenu, selectKeepSidebarOpened } from "~/store/global/globalSlice"
import { useAppSelector } from "~/store/hooks"

const ICON_SIZE = 18

interface SidebarNewUploadProps {
    organizationId?: string
}

export const SidebarNewUpload = ({ organizationId }: SidebarNewUploadProps) => {
    const sideBarOpened = useAppSelector(selectKeepSidebarOpened)
    const displayMenu = useAppSelector(selectDisplayMenu)

    const { isInvoiceV0Enabled } = getSidebarFeatureFlags(organizationId)

    const to = !isInvoiceV0Enabled ? UPLOAD_ROUTE : HOME_ROUTE

    return (
        <>
            <Link to={to} className="upload-button">
                <Stack direction="row" alignItems="center" gap={1}>
                    <PlusIcon size={ICON_SIZE} color="white" />
                    {(sideBarOpened || displayMenu) && (
                        <SafeFormattedMessage {...sideBarMenuItems[MenuItem.NEW_UPLOAD].message} />
                    )}
                </Stack>
            </Link>
            <OcrPendingJobsQueue component="li" compact />
        </>
    )
}
