import { Stack, capitalize } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import { MessageDescriptor, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { commonMessages } from "~/common-messages"
import { Button, Loader, Modal, SafeFormattedMessage, SharedObjectList, Size } from "~/components"
import { PartnerContactsSelector } from "~/domains/identity/partners/components/PartnerSelectors"
import { usePartnerContactSave } from "~/domains/identity/partners/store/hooks"
import { PartnerProfileContactI } from "~/domains/identity/partners/types"
import { useShareObjectMutation } from "~/domains/identity/roles-permissions/api/sharesApi"
import { sharedObjectPermissionsModalMessages } from "~/domains/identity/roles-permissions/types/SpiceDB"
import { OrganizationSummary } from "~/domains/transactions/_shared/components/Organizations/Organizations"
import { selectPurchaseViewType } from "~/domains/transactions/purchase-orders/store/purchaseOrdersSlice"
import { selectUser } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { getOrCreateUserByEmail } from "~/store/users/utils"
import { OrganizationId, ViewTypeI } from "~/types"
import { SharedObjectType } from "~/types/SharedObjects"

export const sharedObjectMessages: Record<SharedObjectType, MessageDescriptor> = {
    [SharedObjectType.PurchaseRequest]: {
        id: "share.object.modal.purchaseRequest",
        defaultMessage: "purchase request",
    },
    [SharedObjectType.PurchaseOrder]: {
        id: "share.object.modal.purchaseOrder",
        defaultMessage: "purchase order",
    },
    [SharedObjectType.Invoice]: {
        id: "share.object.modal.invoice",
        defaultMessage: "invoice",
    },
    [SharedObjectType.Partnership]: {
        id: "share.object.modal.partnership",
        defaultMessage: "partnership",
    },
    [SharedObjectType.Contract]: {
        id: "share.object.modal.contract",
        defaultMessage: "contract",
    },
    [SharedObjectType.Task]: {
        id: "share.object.modal.task",
        defaultMessage: "task",
    },
}

interface Props {
    open: boolean
    close: () => void
    organizationId: OrganizationId
    objectType: SharedObjectType
    objectId: string
    buyerOrganization: OrganizationSummary
    supplierOrganization: OrganizationSummary
    sendShareEmail: boolean
    callback?: () => void
}

export const ShareObjectModal: React.FC<Props> = ({
    open,
    close,
    organizationId,
    objectType,
    objectId,
    buyerOrganization,
    supplierOrganization,
    sendShareEmail = true,
    callback,
}) => {
    const { formatMessage } = useIntl()
    const [sharedEmail, setSharedEmail] = useState("")
    const [partnerId, setPartnerId] = useState<OrganizationId | null>(null)
    const [loadingTimeout, setLoadingTimeout] = useState(false)
    const [selectedContact, setSelectedContact] = useState<PartnerProfileContactI | null>(null)

    const viewType = useAppSelector(selectPurchaseViewType)
    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const currentUser = useAppSelector(selectUser)

    const targetOrganizationId = viewType === ViewTypeI.buyer ? supplierOrganization?.id : buyerOrganization?.id

    const [createSharedObject, { isLoading: loading }] = useShareObjectMutation()

    useEffect(() => {
        const partnerIdFromViewType = viewType === ViewTypeI.buyer ? supplierOrganization?.id : buyerOrganization?.id
        setPartnerId(partnerIdFromViewType ?? null)
    }, [buyerOrganization?.id, supplierOrganization?.id, viewType])

    const handleClose = useCallback(() => {
        close()
        setSharedEmail("")
    }, [close])

    const handleContactSave = usePartnerContactSave(currentOrganizationId, partnerId)

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (!targetOrganizationId || !sharedEmail.length) {
            return
        }

        setLoadingTimeout(true)
        try {
            const user = await getOrCreateUserByEmail({ email: sharedEmail })
            if (selectedContact && targetOrganizationId !== currentOrganizationId) {
                handleContactSave(selectedContact)
            }

            await createSharedObject({
                organizationId,
                objectId,
                objectType,
                userId: user.userId,
                userOrganizationId: targetOrganizationId,
                sendShareEmail,
                creator: currentUser.id,
            })

            setSharedEmail("")
            setSelectedContact(null)

            toast.success(
                formatMessage(sharedObjectPermissionsModalMessages.createSharingSuccess, {
                    objectType: capitalize(formatMessage(sharedObjectMessages[objectType])),
                })
            )

            if (callback) {
                callback()
            }
        } catch (e) {
            console.error(e)
        } finally {
            setLoadingTimeout(false)
        }
    }

    const onPartnerContactChange = useCallback(
        (contact: PartnerProfileContactI | null) => {
            setSelectedContact(contact)
            setSharedEmail(contact?.email ?? "")
        },
        [setSelectedContact, setSharedEmail]
    )

    return (
        <>
            <Modal open={open} onClose={handleClose} size={Size.SM}>
                <Modal.Header>
                    <h4>
                        <SafeFormattedMessage {...sharedObjectPermissionsModalMessages.sendTitle} />
                    </h4>
                </Modal.Header>
                <form onSubmit={onSubmit}>
                    <Modal.Content>
                        {loading || loadingTimeout ? (
                            <Loader />
                        ) : (
                            <Stack direction="column" gap={2}>
                                <PartnerContactsSelector
                                    currentOrganizationId={currentOrganizationId}
                                    partnerId={partnerId === targetOrganizationId ? partnerId : null}
                                    onContactChange={onPartnerContactChange}
                                    selectedContact={selectedContact}
                                />
                                <SharedObjectList
                                    organizationId={organizationId}
                                    objectId={objectId}
                                    objectType={objectType}
                                />
                            </Stack>
                        )}
                    </Modal.Content>
                    <Modal.Footer>
                        <Button type="transparent" onClick={handleClose}>
                            <SafeFormattedMessage {...commonMessages.cancel} />
                        </Button>
                        <Button buttonType="submit" disabled={loading || !sharedEmail.length}>
                            <SafeFormattedMessage {...commonMessages.send} />
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}
