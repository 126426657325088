import { useEffect, useRef, useState } from "react"

import { OrganizationId } from "~/types"

export const useHasChangedOrganization = <T>(
    organizationId: OrganizationId | undefined,
    object?: T | undefined | null,
    objectIsOwnedByOrganization?: (object: T, organizationId: OrganizationId) => boolean
) => {
    const [changedOrganization, setChangedOrganization] = useState(false)
    const lastOrganizationId = useRef<string | null>(null)

    useEffect(() => {
        if (!lastOrganizationId.current && organizationId) {
            lastOrganizationId.current = organizationId
            return
        }
        if (lastOrganizationId && organizationId && organizationId !== lastOrganizationId.current) {
            lastOrganizationId.current = organizationId
            if (!objectIsOwnedByOrganization || (object && !objectIsOwnedByOrganization(object, organizationId))) {
                setChangedOrganization(true)
            }
        }
    }, [organizationId, object])

    return changedOrganization
}
