import { Stack, TextField } from "@mui/material"
import React, { FC } from "react"
import { useIntl } from "react-intl"

import { messages } from "~/domains/orchestration/flows/locale"
import { Address } from "~/domains/orchestration/flows/types"

interface Props {
    address: Address | undefined
    onChange: (address: Address) => void
    label: string
}

export const AddressFields: FC<Props> = ({ address, onChange }) => {
    const intl = useIntl()

    const handleChange = (field: keyof Address) => (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange({
            ...(address || {
                street: "",
                city: "",
                zipCode: "",
                country: "",
            }),
            [field]: event.target.value,
        })
    }

    return (
        <Stack spacing={2}>
            <TextField
                label={intl.formatMessage(messages.createPurchaseOrderConfiguration.streetLabel)}
                value={address?.street || ""}
                onChange={handleChange("street")}
                fullWidth
            />
            <TextField
                label={intl.formatMessage(messages.createPurchaseOrderConfiguration.street2Label)}
                value={address?.street2 || ""}
                onChange={handleChange("street2")}
                fullWidth
            />
            <Stack direction="row" spacing={2}>
                <TextField
                    label={intl.formatMessage(messages.createPurchaseOrderConfiguration.cityLabel)}
                    value={address?.city || ""}
                    onChange={handleChange("city")}
                    fullWidth
                />
                <TextField
                    label={intl.formatMessage(messages.createPurchaseOrderConfiguration.zipCodeLabel)}
                    value={address?.zipCode || ""}
                    onChange={handleChange("zipCode")}
                    fullWidth
                />
            </Stack>
            <TextField
                label={intl.formatMessage(messages.createPurchaseOrderConfiguration.countryLabel)}
                value={address?.country || ""}
                onChange={handleChange("country")}
                fullWidth
            />
        </Stack>
    )
}
