import { Slide, Stack } from "@mui/material"
import { forwardRef } from "react"

import {
    AknowledgeCheckItem,
    CheckItemProps,
} from "~/domains/identity/account/components/AknowledgePurchasePolicyWall/AknowledgeCheckItem"

interface Props extends CheckItemProps {
    visible: boolean
    direction: "left" | "right"
}

export const AknowledgeCheckItemWithSlide = forwardRef<HTMLLIElement | null, Props>(
    ({ primary, secondary, visible, direction }, ref) => (
        <Slide direction={direction} in={visible}>
            <Stack minWidth="calc(100% - 500px)" display={visible ? "block" : "none"}>
                <AknowledgeCheckItem primary={primary} secondary={secondary} ref={ref} />
            </Stack>
        </Slide>
    )
)
AknowledgeCheckItemWithSlide.displayName = "AknowledgeCheckItemWithSlide"
