import { GridRenderCellParams } from "@mui/x-data-grid-premium"
import type { NodeProps, Node as XYFlowNode } from "@xyflow/react"
import { FC } from "react"

import {
    AllAdvancedFieldsUnion,
    CreatePurchaseOrderNode,
    CurrencyConversion,
    EntityType,
    Event,
    NodeType,
    RegularNode,
} from "~/domains/orchestration/flows/types"

export type Handle = {
    id: string
}

export enum SortBy {
    NAME = "name",
    CREATION_DATE = "creationDate",
}

export enum StringType {
    STRING = "string",
    EXPRESSION = "expression",
}

export enum EmailMailType {
    ERROR = "error",
    VALID = "valid",
    EXPRESSION = "expression",
}

export enum SideBarState {
    CLOSED = "closed",
    OPEN = "open",
    WIDE = "wide",
}

export enum BuyerOrSupplier {
    BUYER = "buyer",
    SUPPLIER = "supplier",
}

export type AdaptedPurchaseOrder = Omit<
    CreatePurchaseOrderNode,
    "type" | "nextNode" | "metadata" | "name" | "slug" | "error"
> & {
    buyerName: string
    supplierName: string
}

export type RegularNodeProps = FC<NodeProps<XYFlowNode<RegularNode & { type: NodeType }>>>

export type AdvancedFieldsType<T> = Extract<keyof T, AllAdvancedFieldsUnion>[]

export interface GridRow {
    id: string
    key: string
    [key: string]: string // For dynamic value columns
}

export interface MappingCellProps extends GridRenderCellParams<GridRow> {
    isDefaultRow: boolean
    value: string
}

export enum TriggerMode {
    EVENT = "event",
    ENTITY = "entity",
}

export type EventTriggerType = {
    type: Event | null
    mode: TriggerMode.EVENT
    slug: string
    currencyConversion: null
}

export type EntityTriggerType = {
    type: EntityType | null
    mode: TriggerMode.ENTITY
    slug: string
    currencyConversion: CurrencyConversion | null
}

export type Trigger = EventTriggerType | EntityTriggerType

export const isEventTriggerType = (trigger: Trigger): trigger is EventTriggerType => {
    return trigger.mode === TriggerMode.EVENT
}

export const isEntityTriggerType = (trigger: Trigger): trigger is EntityTriggerType => {
    return trigger.mode === TriggerMode.ENTITY
}
