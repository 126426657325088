import { Box, Typography } from "@mui/material"
import React, { Dispatch, SetStateAction, useState } from "react"
import { DollarSign } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { Button, Card, NoPermission, SafeFormattedMessage, TooltipConditional } from "~/components"
import { permissionMessages } from "~/domains/identity/roles-permissions/utils/permissions"
import { BudgetLink } from "~/domains/transactions/_shared/components/BudgetLink/BudgetLink"
import { ModalAddToBudget } from "~/domains/transactions/_shared/components/ModalAddToBudget/ModalAddToBudget"
import { TransactionBudgetGauge } from "~/domains/transactions/_shared/components/TransactionInBudgets/TransactionBudgetGauge"
import { useHasBudgetPermissions } from "~/domains/transactions/budget/hooks/useHasBudgetPermissions"
import { AddTransactionPayloadI, BudgetDataWithMetricsI, TransactionType } from "~/domains/transactions/budget/types"
import { PurchaseOrders } from "~/domains/transactions/purchase-orders/types"
import { PurchaseRequestDetails } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { useFetchBudgetsData } from "~/store/budget/hooks"
import { InvoiceI, ReceivedDocumentI } from "~/types"

const messages = defineMessages({
    budgets: { id: "purchase.orders.order.budgets", defaultMessage: "Budgets" },
    addToBudget: {
        id: "transactions.in.addToBudget",
        defaultMessage: "Add to budget",
    },
    addToBudgetTooltip: {
        id: "transactions.in.addToBudgetTooltip",
        defaultMessage: "You cannot add this transaction to a budget because it is already approved.",
    },
})

type InvoiceTransaction = {
    objectType: TransactionType.INVOICE | TransactionType.INVOICE_V1
    transaction: InvoiceI
}

type PurchaseRequestTransaction = {
    objectType: TransactionType.PURCHASE_REQUEST
    transaction: PurchaseRequestDetails
}

type PurchaseOrderTransaction = {
    objectType: TransactionType.PURCHASE_ORDER
    transaction: PurchaseOrders
}

type ReceivedDocumentTransaction = {
    objectType: TransactionType.DEPOSIT | TransactionType.PAYMENT
    transaction: ReceivedDocumentI
}

type TransactionWithObjectTypeProps =
    | InvoiceTransaction
    | PurchaseRequestTransaction
    | PurchaseOrderTransaction
    | ReceivedDocumentTransaction

type TransactionInBudgetsProps = TransactionWithObjectTypeProps & {
    draftBudgets?: BudgetDataWithMetricsI[]
    setDraftBudgets?: Dispatch<SetStateAction<BudgetDataWithMetricsI[]>>
    organizationId: string
    withGauge?: boolean
    handleSuccess?: () => void
    readonly?: boolean
}

const getTransactionAmountWithoutTaxes = ({ transaction, objectType }: TransactionWithObjectTypeProps): number => {
    if (objectType === TransactionType.PURCHASE_REQUEST) {
        return transaction.totalAmountExcludingTax
    }
    if (objectType === TransactionType.PURCHASE_ORDER) {
        return transaction.totalAmountExcludingTax
    }
    if (objectType === TransactionType.INVOICE || objectType === TransactionType.INVOICE_V1) {
        return transaction.totalExcludedTaxes ?? 0
    }
    return transaction.totalExcludedTaxes ?? 0
}

const getTransactionTotalAmount = ({ transaction, objectType }: TransactionWithObjectTypeProps): number => {
    if (objectType === TransactionType.PURCHASE_REQUEST) {
        return transaction.totalAmount ?? transaction.totalAmountExcludingTax
    }
    if (objectType === TransactionType.PURCHASE_ORDER) {
        return transaction.totalAmount
    }
    if (objectType === TransactionType.INVOICE || objectType === TransactionType.INVOICE_V1) {
        return transaction.total ?? transaction.totalExcludedTaxes ?? 0
    }
    return transaction.total ?? transaction.totalExcludedTaxes ?? 0
}

const getTransactionAmountRemainingToPay = ({ transaction, objectType }: TransactionWithObjectTypeProps): number => {
    if (objectType === TransactionType.PURCHASE_REQUEST) {
        return transaction.totalAmount ?? transaction.totalAmountExcludingTax
    }
    if (objectType === TransactionType.PURCHASE_ORDER) {
        return transaction.totalAmount
    }
    if (objectType === TransactionType.INVOICE || objectType === TransactionType.INVOICE_V1) {
        if (transaction.paidAt) {
            return 0
        }
        return transaction.total ?? transaction.totalExcludedTaxes ?? 0
    }
    if (transaction.paidAt) {
        return 0
    }
    return transaction.total ?? transaction.totalExcludedTaxes ?? 0
}

export const TransactionInBudgets: React.FC<TransactionInBudgetsProps> = ({
    withGauge = false,
    organizationId,
    draftBudgets,
    setDraftBudgets,
    handleSuccess,
    readonly = false,
    ...transactionAndType
}) => {
    const { transaction, objectType } = transactionAndType
    const { formatMessage } = useIntl()
    const [displayModal, setDisplayModal] = useState(false)

    const { budgetsData } = useFetchBudgetsData({ organizationId, withMetrics: false })

    const { permissions: budgetPermissions } = useHasBudgetPermissions({
        organizationId: organizationId,
        authorizations: ["read", "assign_document"],
    })

    const hasReadBudgetPermission = Boolean(budgetPermissions?.read?.hasPermission)
    const hasAssignBudgetPermission = Boolean(budgetPermissions?.assign_document?.hasPermission)

    const budgets = draftBudgets?.length ? draftBudgets : transaction.budgets

    const transactionPayload: AddTransactionPayloadI = {
        transactionRefId: transaction.id as string,
        transactionStatus: transaction.status,
        transactionType: objectType,
        supplierOrgId: transaction.supplierId,
        buyerOrgId: organizationId ?? "",
        description: transaction.description ?? "",
        currency: transaction.currency,
        amount: getTransactionTotalAmount(transactionAndType),
        amountWithoutTaxes: getTransactionAmountWithoutTaxes(transactionAndType),
        amountRemainingToPay: getTransactionAmountRemainingToPay(transactionAndType),
        partialAmount: 0,
        partialRate: 0,
    }

    const handleOpenModal = () => setDisplayModal(true)

    const handleCloseModal = () => setDisplayModal(false)

    const tooltipMessage = readonly ? messages.addToBudgetTooltip : permissionMessages.errorNoAccessAdministrator
    const hasNoPermission = !hasReadBudgetPermission && !hasAssignBudgetPermission

    return (
        <>
            <Card
                title={formatMessage(messages.budgets)}
                header={
                    <Box display="flex" gap={1} alignItems="center">
                        <Typography variant="h4">
                            <SafeFormattedMessage {...messages.budgets} />
                        </Typography>
                        {hasAssignBudgetPermission && (
                            <Button size="x-small" type="title" onClick={handleOpenModal}>
                                <SafeFormattedMessage {...commonMessages.edit} />
                            </Button>
                        )}
                    </Box>
                }
                expandable
                isExpandedByDefault={!hasNoPermission}
            >
                {hasNoPermission && <NoPermission size="sm" hideIcon />}

                {!hasNoPermission && (
                    <>
                        {budgets?.length ? (
                            <>
                                <BudgetLink budgets={budgets} />
                                {(objectType === TransactionType.PURCHASE_ORDER ||
                                    objectType === TransactionType.PURCHASE_REQUEST) &&
                                    withGauge && <TransactionBudgetGauge transaction={transaction} />}
                            </>
                        ) : (
                            <TooltipConditional
                                condition={!hasAssignBudgetPermission}
                                title={<SafeFormattedMessage {...tooltipMessage} />}
                            >
                                <div>
                                    <Button
                                        type="tertiary"
                                        onClick={handleOpenModal}
                                        disabled={!hasAssignBudgetPermission}
                                    >
                                        <DollarSign size={16} />
                                        <SafeFormattedMessage {...messages.addToBudget} />
                                    </Button>
                                </div>
                            </TooltipConditional>
                        )}
                    </>
                )}
            </Card>

            <ModalAddToBudget
                open={displayModal}
                close={handleCloseModal}
                organizationId={organizationId}
                budgets={budgetsData}
                transaction={transaction}
                transactionPayload={transactionPayload}
                setDraftBudgets={setDraftBudgets}
                handleSuccess={handleSuccess}
            />
        </>
    )
}
