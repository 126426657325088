import { useUserObjectPermissionsCheckQuery } from "~/domains/identity/roles-permissions/api/spiceDbApi"
import { useHasPermissions } from "~/domains/identity/roles-permissions/store/hooks"
import { DomainName, ScopeName } from "~/domains/identity/roles-permissions/types/RolesPermissions"
import { SpiceDBObjectType } from "~/domains/identity/roles-permissions/types/SpiceDB"
import { selectUser } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"
import { AuthorizationName } from "~/types"

type DashboardPermissions = {
    createPurchaseRequests: boolean
    createPurchaseOrders: boolean
    createPartnership: boolean
    createInvoices: boolean
    payInvoices: boolean
}

export const useDashboardPermissions = (organizationId: string | undefined): DashboardPermissions => {
    const currentUser = useAppSelector(selectUser)

    // SpiceDB check for permissions
    const { data: purchaseRequestPermissions } = useUserObjectPermissionsCheckQuery(
        {
            userId: currentUser?.id ?? "",
            objectType: SpiceDBObjectType.PURCHASE_REQUEST,
            organizationId: organizationId as string,
            authorizations: [AuthorizationName.CREATE],
        },
        { skip: !organizationId || !currentUser?.id }
    )

    // SpiceDB check for permissions
    const { data: purchaseOrderPermissions } = useUserObjectPermissionsCheckQuery(
        {
            userId: currentUser?.id ?? "",
            objectType: SpiceDBObjectType.PURCHASE_ORDER,
            organizationId: organizationId as string,
            authorizations: [AuthorizationName.CREATE],
        },
        { skip: !organizationId || !currentUser?.id }
    )

    // JWT token check for permissions
    const { hasPermission: hasCreatePartnershipPermission } = useHasPermissions(
        organizationId ?? "",
        AuthorizationName.CREATE,
        DomainName.TRANSACTION,
        ScopeName.BOOK_OF_RELATIONS
    )

    // JWT token check for permissions
    const { hasPermission: hasPayInvoicesPermission } = useHasPermissions(
        organizationId ?? "",
        AuthorizationName.CREATE,
        DomainName.PAYMENT,
        ScopeName.PAYMENTS
    )

    // JWT token check for permissions
    const { hasPermission: hasCreateInvoicesPermission } = useHasPermissions(
        organizationId ?? "",
        AuthorizationName.CREATE,
        DomainName.TRANSACTION,
        ScopeName.INVOICES
    )

    return {
        createPurchaseRequests: Boolean(purchaseRequestPermissions?.create),
        createPurchaseOrders: Boolean(purchaseOrderPermissions?.create),
        createPartnership: hasCreatePartnershipPermission,
        createInvoices: hasCreateInvoicesPermission,
        payInvoices: hasPayInvoicesPermission,
    }
}
