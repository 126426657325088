import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { purchaseOrdersApi } from "~/domains/transactions/purchase-orders/api"
import { getPurchaseOrderId } from "~/domains/transactions/purchase-orders/core/purchaseOrder"
import { purchaseOrdersActions } from "~/domains/transactions/purchase-orders/store/purchaseOrdersSlice"
import { useAppDispatch } from "~/store/hooks"
import { OrganizationId } from "~/types"
import { sleep } from "~/utils"
import { isDefined } from "~/utils/isDefined"

const messages = defineMessages({
    purchaseOrderSent: {
        id: "purchase.orders.purchaseOrderSent",
        defaultMessage: "Your purchase {count, plural, =0 {} one {order} other {orders}} has been successfully sendd.",
    },
    unableToSendPurchaseOrder: {
        id: "purchase.orders.unableToSendPurchaseOrder",
        defaultMessage:
            "An error occured: your purchase {count, plural, =0 {} one {order} other {orders}} could not be sendd.",
    },
    unableToSendSomePurchaseOrder: {
        id: "purchase.orders.unableToSendSomePurchaseOrder",
        defaultMessage:
            "An error occured: {count} purchase {count, plural, =0 {} one {order} other {orders}} could not be sendd.",
    },
})

export const useSendPurchaseOrders = () => {
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()
    return async (purchaseOrderIds: string[], organizationId: OrganizationId) => {
        const results = await Promise.allSettled(
            purchaseOrderIds.map((purchaseOrderId) =>
                purchaseOrdersApi.updateStatus(organizationId, purchaseOrderId, "OPEN")
            )
        )

        // We have to wait for the PO updates to be processed, cause we fetch them right after to get updated statuses
        await sleep(200)

        const resultPOs = await Promise.allSettled(
            results.map((r, index) => {
                const purchaseOrderId = getPurchaseOrderId(r, index, purchaseOrderIds)
                if (purchaseOrderId) {
                    return purchaseOrdersApi.findById(organizationId, purchaseOrderId)
                }
                return undefined
            })
        )

        const successSenddPOs = resultPOs
            .map((r) => (r.status === "fulfilled" && r.value ? r.value : null))
            .filter(isDefined)

        const failSenddLength = results.length - successSenddPOs.length
        dispatch(purchaseOrdersActions.updatePurchaseOrders(successSenddPOs))
        if (failSenddLength > 0) {
            toast.error(
                formatMessage(
                    failSenddLength === results.length
                        ? messages.unableToSendPurchaseOrder
                        : messages.unableToSendSomePurchaseOrder,
                    { count: failSenddLength }
                )
            )
        } else {
            toast.success(formatMessage(messages.purchaseOrderSent, { count: purchaseOrderIds.length }))
        }
        return true
    }
}
