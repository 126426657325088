import { Stack, Typography } from "@mui/material"
import React from "react"
import { AlertTriangle } from "react-feather"
import { defineMessages } from "react-intl"

import { commonMessages } from "~/common-messages"
import { Button, Modal, SafeFormattedMessage } from "~/components"

const messages = defineMessages({
    overrideFlow: {
        id: "approval.overrideFlow",
        defaultMessage: "This action will bypass the current approval flow",
    },
})

interface ApprovalOverrideModalProps {
    open: boolean
    onClose: () => void
    onConfirm: () => void
}

export const ApprovalOverrideModal: React.FC<ApprovalOverrideModalProps> = ({ open, onClose, onConfirm }) => (
    <Modal open={open} onClose={onClose}>
        <Modal.Header>
            <Stack direction="row" spacing={2} alignItems="center" mb={1}>
                <AlertTriangle size={24} color="var(--color-yellow)" />
                <Typography variant="h4">
                    <SafeFormattedMessage {...messages.overrideFlow} />
                </Typography>
            </Stack>
        </Modal.Header>

        <Modal.Footer>
            <Button type="neutral" onClick={onClose}>
                <SafeFormattedMessage {...commonMessages.cancel} />
            </Button>
            <Button type="primary" onClick={onConfirm}>
                <SafeFormattedMessage {...commonMessages.confirm} />
            </Button>
        </Modal.Footer>
    </Modal>
)
