import { AlertTriangle, Check, Clock, HelpCircle, Pause, Play, X } from "react-feather"

import { RUN_STATUS_COLORS } from "~/domains/orchestration/flows/constants"
import { RunStatus } from "~/domains/orchestration/flows/types"

export const StatusIcon = ({ status }: { status: RunStatus }) => {
    switch (status) {
        case RunStatus.CANCELED:
            return <X size={16} color={RUN_STATUS_COLORS[RunStatus.CANCELED]} />
        case RunStatus.QUEUED:
            return <Clock size={16} color={RUN_STATUS_COLORS[RunStatus.QUEUED]} />
        case RunStatus.FAILED:
            return <AlertTriangle size={16} color={RUN_STATUS_COLORS[RunStatus.FAILED]} />
        case RunStatus.FINISHED:
            return <Check size={16} color={RUN_STATUS_COLORS[RunStatus.FINISHED]} />
        case RunStatus.RUNNING:
            return <Play size={16} color={RUN_STATUS_COLORS[RunStatus.RUNNING]} />
        case RunStatus.SUSPENDED:
            return <Pause size={16} color={RUN_STATUS_COLORS[RunStatus.SUSPENDED]} />
        case RunStatus.UNKNOWN:
            return <HelpCircle size={16} color={RUN_STATUS_COLORS[RunStatus.UNKNOWN]} />
    }
}
