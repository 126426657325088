import { MessageDescriptor, defineMessages } from "react-intl"

import { TaskStatus } from "~/domains/communication/tasks/types"

export const taskStatusLabel: Record<TaskStatus, MessageDescriptor> = defineMessages({
    [TaskStatus.PENDING]: { id: "communication.tasks.status.pending", defaultMessage: "Pending" },
    [TaskStatus.IN_PROGRESS]: { id: "communication.tasks.status.inProgress", defaultMessage: "In Progress" },
    [TaskStatus.COMPLETED]: { id: "communication.tasks.status.completed", defaultMessage: "Completed" },
    [TaskStatus.REJECTED]: { id: "communication.tasks.status.rejected", defaultMessage: "Rejected" },
})
