import { defineMessages } from "react-intl"

export const messages = defineMessages({
    modalTitle: {
        id: "partners.bookofrelations.modal.create.title",
        defaultMessage: "Add a new partner",
    },
    modalTitleContact: {
        id: "partners.bookofrelations.modal.create.titleContact",
        defaultMessage: "{organizationName} -  Add a contact",
    },
    modalCancel: {
        id: "partners.bookofrelations.modal.create.cancel",
        defaultMessage: "Cancel",
    },
    backButton: {
        id: "partners.bookofrelations.modal.create.back",
        defaultMessage: "Back",
    },
    modalCreateAnother: {
        id: "partners.bookofrelations.modal.create.another",
        defaultMessage: "Create & add another",
    },
    skipContactCreation: {
        id: "partners.bookofrelations.modal.create.skipContactCreation",
        defaultMessage: "Create without contact",
    },
    modalConfirm: {
        id: "partners.bookofrelations.modal.create.confirm",
        defaultMessage: "Create",
    },
    organization: {
        id: "partners.bookofrelations.modal.create.organization",
        defaultMessage: "Organization",
    },
    searchPartner: {
        id: "partners.bookofrelations.modal.create.searchPartner",
        defaultMessage: "Search for the partner's company",
    },
    createSuccess: {
        id: "partners.bookofrelations.modal.create.success",
        defaultMessage: "The new partner has been added to your Partners network",
    },
    createPartnershipError: {
        id: "partners.bookofrelations.modal.create.partnershipError",
        defaultMessage: "We encountered an error creating the partnership. Please try again.",
    },
    createOrganizationError: {
        id: "partners.bookofrelations.modal.create.organizationError",
        defaultMessage: "We encountered an error creating the organization. Please try again.",
    },
    addressLabel: {
        id: "partners.bookofrelations.modal.create.addressLabel",
        defaultMessage: "Address",
    },
    contactLabel: {
        id: "customrules.company.seche.modal.create.primaryContactLabel",
        defaultMessage: "Primary contact",
    },
    modalNext: {
        id: "customrules.company.seche.modal.create.next",
        defaultMessage: "Next",
    },
    modalBack: {
        id: "partners.bookofrelations.modal.create.back",
        defaultMessage: "Back",
    },
    errorContactCreation: {
        id: "partners.bookofrelations.modal.create.errorContactCreation",
        defaultMessage: "There has been an error creating this partnership, preventing the creation of the contact.",
    },
    organizationRequired: {
        id: "partners.bookofrelations.modal.create.organizationRequired",
        defaultMessage: "Please select or create an organization",
    },
    customFieldsRequired: {
        id: "partners.bookofrelations.modal.create.customFieldsRequired",
        defaultMessage: "Please fill in all required custom fields",
    },
    partnershipConflict: {
        id: "partners.bookofrelations.modal.create.partnershipConflict",
        defaultMessage: "This partnership type conflicts with an existing partnership",
    },
})
