/* eslint-disable complexity */
import { Stack, useMediaQuery, useTheme } from "@mui/material"
import classNames from "classnames"
import React, { useCallback, useState } from "react"
import { ChevronLeft, ChevronRight, X as CloseMenu, LogIn } from "react-feather"
import { useIntl } from "react-intl"

import { Button, TooltipConditional } from "~/components"
import { SafeFormattedMessage } from "~/components"
import { LogoFlowie } from "~/components/OrganizationLogo/LogoFlowie"
import { SideBarMenuItem } from "~/components/Sidebar/SideBarMenuItem"
import { SidebarNewUpload } from "~/components/Sidebar/SidebarNewUpload"
import { experimentalOrgs, getSidebarFeatureFlags } from "~/components/Sidebar/getSidebarFeatureFlags"
import { MenuItem, sideBarMenuItems } from "~/components/Sidebar/sideBarMenuItems"
import { useSidebarPermissions } from "~/components/Sidebar/useSidebarPermissions"
import { SelectOrganization } from "~/domains/identity/account/components/Organization/SelectOrganization/SelectOrganization"
import { permissionMessages } from "~/domains/identity/roles-permissions/utils/permissions"
import { authActions, selectIsConnected } from "~/store/auth/authSlice"
import { globalActions, selectDisplayMenu, selectKeepSidebarOpened } from "~/store/global/globalSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"

import "./Sidebar.scss"

const ICON_SIZE = 18

export const Sidebar = () => {
    const theme = useTheme()
    const { formatMessage } = useIntl()
    const [open] = useState(false)

    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const isConnected = useAppSelector(selectIsConnected)
    const keepOpen = useAppSelector(selectKeepSidebarOpened)
    const displayMenu = useAppSelector(selectDisplayMenu)
    const sidebarClasses = classNames("sidebar", {
        ["open"]: displayMenu,
        ["keep-open"]: keepOpen || displayMenu,
    })
    const dispatch = useAppDispatch()

    const {
        isMetricsEnabled,
        isInvoiceV0Enabled,
        isDocumentsEnabled,
        isCopilotEnabled,
        isCustomFormsEnabled,
        isCatalogV1Enabled,
        isCommunicationPageEnabled,
        isTasksEnabled,
        isPOsEnabled,
        isPurchaseOrdersPaginatedEnabled,
    } = getSidebarFeatureFlags(currentOrganizationId)

    const { hasBudgetsPermission, hasWorkflowsPermission, hasInvoicesPermission, hasBookOfRelationsPermission } =
        useSidebarPermissions(currentOrganizationId)

    const isMobile = useMediaQuery(theme.breakpoints.down("lg"))
    const isOpen = open || isMobile || keepOpen

    const toggleKeepSidebarOpened = useCallback(() => {
        dispatch(globalActions.toggleKeepSidebarOpened())
    }, [dispatch])

    const handleDisplayHamburgerMenu = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        event.stopPropagation()
        dispatch(globalActions.setDisplayMenu())
    }

    const handleLogin = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            event.preventDefault()
            dispatch(authActions.openPopupSignIn())
        },
        [dispatch]
    )

    return (
        <nav className={sidebarClasses}>
            <Stack width="100%" height="100%" justifyContent="space-between">
                <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%" marginBottom={1}>
                    {!isConnected ? (
                        <Stack width="100%" justifyContent="center" alignItems="center" padding={1.5}>
                            <LogoFlowie size={85} iconOnly={!isOpen} />
                        </Stack>
                    ) : (
                        <SelectOrganization hideOrganizationName={!isOpen} />
                    )}

                    {isMobile && (
                        <Button type="transparent" size="small" onClick={handleDisplayHamburgerMenu}>
                            <CloseMenu size={22} />
                        </Button>
                    )}

                    <div className="expand-sidebar" onClick={toggleKeepSidebarOpened}>
                        {isOpen ? (
                            <ChevronLeft size={16} color="var(--color-grey-light)" />
                        ) : (
                            <ChevronRight size={16} color="var(--color-grey-light)" />
                        )}
                    </div>
                </Stack>

                <Stack flexGrow={1} gap={0.5} overflow="auto" sx={{ overflowX: isOpen ? "auto" : "hidden" }}>
                    <SidebarNewUpload organizationId={currentOrganizationId} />

                    <SideBarMenuItem menuItem={sideBarMenuItems[MenuItem.DASHBOARD]} disabled={false} />

                    <SideBarMenuItem menuItem={sideBarMenuItems[MenuItem.INBOX]} disabled={false} />

                    <hr />

                    <TooltipConditional
                        condition={!isConnected}
                        title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                    >
                        <div>
                            <SideBarMenuItem
                                menuItem={sideBarMenuItems[MenuItem.PURCHASE_REQUESTS]}
                                disabled={!isConnected || !currentOrganizationId}
                            />
                        </div>
                    </TooltipConditional>

                    {isPurchaseOrdersPaginatedEnabled && (
                        <SideBarMenuItem
                            menuItem={sideBarMenuItems[MenuItem.PURCHASE_ORDERS_PAGINATED]}
                            disabled={false}
                        />
                    )}

                    {!isPurchaseOrdersPaginatedEnabled && isPOsEnabled && (
                        <SideBarMenuItem menuItem={sideBarMenuItems[MenuItem.PURCHASE_ORDERS]} disabled={false} />
                    )}

                    {(isInvoiceV0Enabled || !!experimentalOrgs[currentOrganizationId || ""]) && (
                        <TooltipConditional
                            condition={!hasInvoicesPermission}
                            title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                        >
                            <div>
                                <SideBarMenuItem
                                    menuItem={sideBarMenuItems[MenuItem.INVOICES]}
                                    disabled={!hasInvoicesPermission}
                                />
                            </div>
                        </TooltipConditional>
                    )}

                    {!isInvoiceV0Enabled && (
                        <TooltipConditional
                            condition={!hasInvoicesPermission}
                            title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                        >
                            <div>
                                <SideBarMenuItem
                                    menuItem={sideBarMenuItems[MenuItem.INVOICES_V1]}
                                    disabled={!hasInvoicesPermission}
                                />
                            </div>
                        </TooltipConditional>
                    )}

                    {isCatalogV1Enabled && (
                        <SideBarMenuItem menuItem={sideBarMenuItems[MenuItem.CATALOG]} disabled={!isCatalogV1Enabled} />
                    )}

                    {isDocumentsEnabled && (
                        <SideBarMenuItem
                            menuItem={sideBarMenuItems[MenuItem.DOCUMENTS]}
                            disabled={!isDocumentsEnabled}
                        />
                    )}

                    {isCustomFormsEnabled && (
                        <SideBarMenuItem
                            menuItem={sideBarMenuItems[MenuItem.SURVEY]}
                            disabled={!isCustomFormsEnabled}
                        />
                    )}

                    <hr />

                    {(isInvoiceV0Enabled || !!experimentalOrgs[currentOrganizationId || ""]) && (
                        <TooltipConditional
                            condition={!hasWorkflowsPermission}
                            title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                        >
                            <div>
                                <SideBarMenuItem
                                    menuItem={sideBarMenuItems[MenuItem.WORKFLOWS]}
                                    disabled={!hasWorkflowsPermission}
                                />
                            </div>
                        </TooltipConditional>
                    )}

                    {!isInvoiceV0Enabled && (
                        <TooltipConditional
                            condition={!hasWorkflowsPermission}
                            title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                        >
                            <div>
                                <SideBarMenuItem
                                    menuItem={sideBarMenuItems[MenuItem.FLOWS]}
                                    disabled={!hasWorkflowsPermission}
                                />
                            </div>
                        </TooltipConditional>
                    )}

                    <TooltipConditional
                        condition={!hasBookOfRelationsPermission}
                        title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                    >
                        <div>
                            <SideBarMenuItem
                                menuItem={sideBarMenuItems[MenuItem.PARTNERS]}
                                disabled={!hasBookOfRelationsPermission}
                            />
                        </div>
                    </TooltipConditional>

                    <SideBarMenuItem menuItem={sideBarMenuItems[MenuItem.PAYMENTS]} disabled={!hasInvoicesPermission} />

                    <TooltipConditional
                        condition={!hasBudgetsPermission}
                        title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                        placement="right"
                        arrow
                    >
                        <div>
                            <SideBarMenuItem
                                menuItem={sideBarMenuItems[MenuItem.BUDGETS]}
                                disabled={!hasBudgetsPermission}
                            />
                        </div>
                    </TooltipConditional>

                    {isMetricsEnabled && hasBudgetsPermission && (
                        <SideBarMenuItem
                            menuItem={sideBarMenuItems[MenuItem.METRICS]}
                            disabled={!hasBudgetsPermission}
                        />
                    )}
                    {isCommunicationPageEnabled && (
                        <SideBarMenuItem
                            menuItem={sideBarMenuItems[MenuItem.COMMUNICATION]}
                            disabled={!isCommunicationPageEnabled}
                        />
                    )}
                    {isTasksEnabled && (
                        <SideBarMenuItem menuItem={sideBarMenuItems[MenuItem.TASKS]} disabled={!isTasksEnabled} />
                    )}

                    {isCopilotEnabled && (
                        <>
                            <hr />
                            <SideBarMenuItem
                                menuItem={sideBarMenuItems[MenuItem.COPILOT]}
                                disabled={!isCopilotEnabled}
                            />
                        </>
                    )}
                </Stack>

                <Stack gap={0.5}>
                    <hr />
                    <SideBarMenuItem menuItem={sideBarMenuItems[MenuItem.SETTINGS]} disabled={!isConnected} />

                    {isConnected && (
                        <SideBarMenuItem menuItem={sideBarMenuItems[MenuItem.ACCOUNT]} disabled={!isConnected} />
                    )}
                </Stack>

                {!isConnected && (
                    <TooltipConditional
                        condition={!isOpen}
                        type="primary"
                        title={formatMessage(sideBarMenuItems[MenuItem.LOGIN].message)}
                        placement="right"
                        arrow
                    >
                        <a href="#" onClick={handleLogin}>
                            <Stack direction="row" alignItems="center" gap={1}>
                                <LogIn size={ICON_SIZE} />
                                {isOpen && <SafeFormattedMessage {...sideBarMenuItems[MenuItem.LOGIN].message} />}
                            </Stack>
                        </a>
                    </TooltipConditional>
                )}
            </Stack>
        </nav>
    )
}
