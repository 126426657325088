import {
    GridCellEditStopParams,
    GridCellEditStopReasons,
    GridCellModes,
    GridCellModesModel,
    GridCellParams,
    MuiBaseEvent,
    MuiEvent,
} from "@mui/x-data-grid-premium"
import { ChangeEvent, KeyboardEvent, MouseEvent, useCallback, useRef, useState } from "react"
import { v4 as uuid } from "uuid"

import type { MappingGridRef } from "~/domains/orchestration/flows/components/configuration/MappingComponents/MappingGrid"
import { GridRow, MappingNode } from "~/domains/orchestration/flows/types"

export const useMappingConfiguration = (selectedNode: MappingNode) => {
    const [currentNode, setCurrentNode] = useState(selectedNode)
    const [newMappingKey, setNewMappingKey] = useState("")
    const [hasKeyError, setKeyError] = useState(false)
    const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({})
    const isEditingRef = useRef(false)
    const gridRef = useRef<MappingGridRef>(null)

    const handleCustomCellClick = useCallback((params: GridCellParams, event: MuiEvent<MuiBaseEvent>) => {
        if (!params.isEditable) return

        const currentEvent = event as MuiEvent<MouseEvent>
        const target = currentEvent.target as Element

        if (target.nodeType === 1 && !currentEvent.currentTarget.contains(target)) return
        isEditingRef.current = true

        // Update the cell modes model to edit the cell
        // Thisis from the MUI docs:
        // https://mui.com/x/react-data-grid/recipes-editing/#single-click-editing.
        setCellModesModel((prevModel) => ({
            ...Object.keys(prevModel).reduce(
                (acc, id) => ({
                    ...acc,
                    [id]: Object.keys(prevModel[id]).reduce(
                        (acc2, field) => ({
                            ...acc2,
                            [field]: { mode: GridCellModes.View },
                        }),
                        {}
                    ),
                }),
                {}
            ),
            [params.id]: {
                ...Object.keys(prevModel[params.id] || {}).reduce(
                    (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
                    {}
                ),
                [params.field]: { mode: GridCellModes.Edit },
            },
        }))
    }, [])

    const handleValueToMapChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setCurrentNode((prev) => ({
            ...prev,
            valueToMap: event.target.value,
        }))
    }, [])

    const handleUpdateDefaultLabel = useCallback(
        (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
            const label = e.target.value
            setCurrentNode((prev) => {
                const updatedDefaultValues = prev.defaultValues.map((item, i) =>
                    i === index ? { ...item, label } : item
                )

                const updatedMappingTable = Object.entries(prev.mappingTable).reduce(
                    (acc, [key, values]) => ({
                        ...acc,
                        [key]: {
                            ...values,
                            elements: values.elements.map((item, i) => (i === index ? { ...item, label } : item)),
                        },
                    }),
                    {}
                )

                return {
                    ...prev,
                    defaultValues: updatedDefaultValues,
                    mappingTable: updatedMappingTable,
                }
            })
        },
        []
    )

    const handleUpdateDefaultValue = useCallback((index: number, value: string) => {
        setCurrentNode((prev) => ({
            ...prev,
            defaultValues: prev.defaultValues.map((item, i) => (i === index ? { ...item, value } : item)),
        }))
    }, [])

    const handleAddMappingKey = useCallback(() => {
        if (!newMappingKey.trim()) return

        if (currentNode.mappingTable[newMappingKey]) {
            setKeyError(true)
            return
        }

        const newItems = currentNode.defaultValues.map((defaultValue) => ({
            label: defaultValue.label,
            value: "",
        }))

        setCurrentNode((prev) => ({
            ...prev,
            mappingTable: {
                ...prev.mappingTable,
                [newMappingKey]: {
                    id: uuid(),
                    elements: newItems,
                },
            },
        }))
        setKeyError(false)
        setNewMappingKey("")
    }, [currentNode.defaultValues, currentNode.mappingTable, newMappingKey])

    const handleUpdateMappingValue = useCallback((key: string, index: number, value: string) => {
        setCurrentNode((prev) => ({
            ...prev,
            mappingTable: {
                ...prev.mappingTable,
                [key]: {
                    ...prev.mappingTable[key],
                    elements: prev.mappingTable[key].elements.map((item, i) =>
                        i === index ? { ...item, value } : item
                    ),
                },
            },
        }))
    }, [])

    const handleRemoveMappingKey = useCallback(
        (id: string) => {
            const key = Object.keys(currentNode.mappingTable).find((k) => currentNode.mappingTable[k].id === id)
            if (!key) return

            setCurrentNode((prev) => {
                const newMappingTable = { ...prev.mappingTable }
                delete newMappingTable[key]
                return {
                    ...prev,
                    mappingTable: newMappingTable,
                }
            })
            gridRef.current?.api?.updateRows([{ id, _action: "delete" }])
        },
        [currentNode.mappingTable]
    )

    const handleNewMappingKeyChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setNewMappingKey(event.target.value)
        setKeyError(false)
    }, [])

    const handleKeyDown = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Enter") {
                event.preventDefault()
                handleAddMappingKey()
            }
        },
        [handleAddMappingKey]
    )

    const handleUpdateMappingKey = useCallback(
        (oldKey: string, newKey: string) => {
            if (!newKey.trim() || oldKey === newKey) return

            if (currentNode.mappingTable[newKey]) {
                setKeyError(true)
                return
            }

            setCurrentNode((prev) => ({
                ...prev,
                mappingTable: Object.fromEntries(
                    Object.entries(prev.mappingTable).map(([key, value]) =>
                        key === oldKey ? [newKey, value] : [key, value]
                    )
                ),
            }))
            setKeyError(false)
        },
        [currentNode.mappingTable]
    )

    const handleRemoveDefaultValue = useCallback(
        (index: number) => () => {
            setCurrentNode((prev) => {
                const updatedDefaultValues = prev.defaultValues.filter((_, i) => i !== index)
                const updatedMappingTable = Object.entries(prev.mappingTable).reduce(
                    (acc, [key, values]) => ({
                        ...acc,
                        [key]: {
                            ...values,
                            elements: values.elements.filter((_, i) => i !== index),
                        },
                    }),
                    {}
                )

                return {
                    ...prev,
                    defaultValues: updatedDefaultValues,
                    mappingTable: updatedMappingTable,
                }
            })
        },
        []
    )

    const handleCellModesModelChange = useCallback((newModel: GridCellModesModel) => {
        setCellModesModel(newModel)
    }, [])

    const handleCellEditStop = useCallback(
        ({ row, field, reason }: GridCellEditStopParams, event: MuiEvent<MuiBaseEvent>) => {
            const currentRow = row as GridRow
            const isDefaultRow = currentRow.id === "default"
            const isEditingKey = field === "key"
            const key = currentRow.key
            const index = parseInt(field.replace("value", ""), 10)

            let newValue = ""
            if ("target" in event) {
                newValue = (event.target as HTMLInputElement).value
            }

            if (reason === GridCellEditStopReasons.cellFocusOut) {
                event.defaultMuiPrevented = true
                setCellModesModel((prev) => ({
                    ...prev,
                    [currentRow.id]: {
                        ...prev[currentRow.id],
                        [field]: { mode: GridCellModes.View },
                    },
                }))
                isEditingRef.current = false
                return
            }

            if (newValue === undefined) return

            if (isEditingKey && newValue) {
                handleUpdateMappingKey(key, newValue)
            } else if (isDefaultRow) {
                handleUpdateDefaultValue(index, newValue)
            } else {
                handleUpdateMappingValue(key, index, newValue)
            }
            isEditingRef.current = false
        },
        [handleUpdateMappingKey, handleUpdateDefaultValue, handleUpdateMappingValue]
    )

    const handleAddMappingValue = useCallback(() => {
        setCurrentNode((prev) => ({
            ...prev,
            mappingTable: Object.fromEntries(
                Object.entries(prev.mappingTable).map(([key, value]) => [
                    key,
                    { ...value, elements: [...value.elements, { label: "", value: "" }] },
                ])
            ),
            defaultValues: [...prev.defaultValues, { label: "", value: "" }],
        }))
    }, [])

    return {
        isEditing: isEditingRef.current,
        currentNode,
        setCurrentNode,
        newMappingKey,
        hasKeyError,
        cellModesModel,
        gridRef,
        handleCustomCellClick,
        handleValueToMapChange,
        handleUpdateDefaultLabel,
        handleUpdateDefaultValue,
        handleAddMappingKey,
        handleUpdateMappingValue,
        handleRemoveMappingKey,
        handleNewMappingKeyChange,
        handleKeyDown,
        handleUpdateMappingKey,
        handleRemoveDefaultValue,
        handleCellModesModelChange,
        handleCellEditStop,
        handleAddMappingValue,
    }
}
