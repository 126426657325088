import { toast } from "react-toastify"

import { NewInvitationsToastContent } from "~/components/Toasts/NewInvitationsToast/NewInvitationsToastContent"
import { UserI } from "~/types"

export const NewInvitationsToast = ({ currentUser }: { currentUser: UserI }) => {
    if (!currentUser?.invitations?.length) return null

    toast.info(<NewInvitationsToastContent invitations={currentUser?.invitations} />, {
        autoClose: false,
        closeOnClick: false,
    })

    return null
}
