import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorization as prepareHeaders } from "~/api/prepareHeaders"
import { commonMessages } from "~/common-messages"
import { OrganizationId } from "~/types"

const BASE_URL = import.meta.env.VITE_API_IMPORT_FROM_FILES_URL

export const catalogImportApi = createApi({
    reducerPath: "catalogImportApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, prepareHeaders }),
    endpoints: (builder) => ({
        importCatalogBatch: builder.mutation<string, { file: File; organizationId: OrganizationId }>({
            query: ({ file, organizationId }) => {
                const formData = new FormData()
                formData.append("file", file)
                formData.append("organizationId", organizationId)

                return {
                    url: "catalog/v2/upload-file",
                    method: "POST",
                    body: formData,
                }
            },
            transformErrorResponse: (response) => ({
                meta: {
                    errorMessage: commonMessages.errorUpload,
                    tags: {
                        domain: "transaction",
                        subdomain: "catalog",
                        feature: "import-catalogs",
                    },
                },
                ...response,
            }),
        }),
    }),
})

export const { useImportCatalogBatchMutation } = catalogImportApi
