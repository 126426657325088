import { Alert, IconButton, Stack, TextField } from "@mui/material"
import { RiEdit2Line, RiFormula } from "@remixicon/react"
import cls from "classnames"
import React, { ChangeEvent, FC, useEffect, useState } from "react"
import { useIntl } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import { AdvancedFields } from "~/domains/orchestration/flows/components/configuration/AdvancedFields"
import { messages } from "~/domains/orchestration/flows/locale"
import {
    CheckNode,
    ConfigurationProps,
    Reviewer,
    TeamReviewer,
    UserReviewer,
    UserType,
    isTeamReviewer,
    isUserReviewer,
} from "~/domains/orchestration/flows/types"
import { useAppSelector } from "~/store/hooks"
import { useFetchOrganizationMembers, useFetchOrganizationTeams } from "~/store/organization/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { OrganizationMemberUserI, OrganizationTeamI } from "~/types"

import { ExpressionMode, TeamSelect, UserSelect } from "./CheckNodeComponents"
import { ConfigurationNode } from "./ConfigurationNode"

const getReviewerNames = (users: OrganizationMemberUserI[], teams: OrganizationTeamI[]) => {
    const reviewerNames = [
        ...users.map((user) => user.user?.fullName).filter(Boolean),
        ...teams.map((team) => team.name),
    ]
    return reviewerNames.join(", ") || ""
}

const getReviewers = (users: OrganizationMemberUserI[], teams: OrganizationTeamI[]) => {
    const usersReviewers: UserReviewer[] = users.map((user) => ({
        userId: user.userId,
        type: UserType.USER,
    }))

    const teamsReviewers: TeamReviewer[] = teams.map((team) => ({
        teamId: team.teamId,
        type: UserType.TEAM,
    }))

    return [...usersReviewers, ...teamsReviewers]
}

export const CheckNodeConfiguration: FC<ConfigurationProps<CheckNode>> = ({
    selectedNode,
    advancedFields,
    validateNode,
}) => {
    const { formatMessage } = useIntl()
    const organization = useAppSelector(selectCurrentOrganization)
    const [members] = useFetchOrganizationMembers(organization!)
    const { teams: allTeams, loading: loadingAllTeams = false } = useFetchOrganizationTeams(organization!.id, true)

    const [users, setUsers] = useState<OrganizationMemberUserI[]>([])
    const [teams, setTeams] = useState<OrganizationTeamI[]>([])

    const [currentNode, setCurrentNode] = useState(selectedNode)
    const [isExpressionMode, setIsExpressionMode] = useState(false)

    const handleSelectUser = (_, values: OrganizationMemberUserI[] = []) => {
        setUsers(values)
        setCurrentNode((prev) => ({
            ...prev,
            reviewers: getReviewers(values, teams),
            metadata: {
                ...prev.metadata,
                additionalInformation: getReviewerNames(values, teams),
            },
        }))
    }

    const handleSelectTeam = (_, values: OrganizationTeamI[]) => {
        setTeams(values)
        setCurrentNode((prev) => ({
            ...prev,
            reviewers: getReviewers(users, values),
            metadata: {
                ...prev.metadata,
                additionalInformation: getReviewerNames(users, teams),
            },
        }))
    }

    const handleChangeThreshold =
        (field: "passThreshold" | "refuseThreshold") => (e: ChangeEvent<HTMLInputElement>) => {
            const value = parseInt(e.target.value || "0", 10)

            if (Number.isInteger(value)) {
                setCurrentNode({ ...currentNode, [field]: value })
            }
        }

    useEffect(() => {
        setCurrentNode((prev) => ({
            ...prev,
            metadata: {
                ...prev.metadata,
                additionalInformation: getReviewerNames(users, teams),
            },
        }))
    }, [users, teams])

    useEffect(() => {
        let initialUsers: OrganizationMemberUserI[] = []
        let initialTeams: OrganizationTeamI[] = []

        initialUsers = members.filter((member) =>
            selectedNode.reviewers
                .filter(isUserReviewer)
                .map((r) => r.userId.replaceAll("'", ""))
                .includes(`${member.userId}`)
        )

        if (initialUsers.length) {
            setUsers(initialUsers)
        }

        if (allTeams.length) {
            initialTeams = allTeams.filter((team) =>
                selectedNode.reviewers
                    .filter(isTeamReviewer)
                    .map((r) => r.teamId.replaceAll("'", ""))
                    .includes(`${team.teamId}`)
            )
            if (initialTeams.length) {
                setTeams(initialTeams)
            }
        }
    }, [members, allTeams, selectedNode.reviewers])

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    const handleToggleExpressionMode = () => {
        setIsExpressionMode(!isExpressionMode)
    }

    const handleExpressionReviewersChange = (reviewers: Reviewer[]) => {
        setCurrentNode((prev) => ({
            ...prev,
            expressionReviewers: reviewers,
        }))
    }

    return (
        <ConfigurationNode selectedNode={currentNode} validateNode={validateNode}>
            <Alert severity="info">
                <SafeFormattedMessage {...messages.checkNodeConfiguration.warning} />
            </Alert>
            <Stack gap={2} className={configurationNodeItemClassName}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <SafeFormattedMessage tagName="h5" {...messages.checkNodeConfiguration.usersTitle} />
                    <IconButton onClick={handleToggleExpressionMode} size="small">
                        {isExpressionMode ? <RiEdit2Line size={16} /> : <RiFormula size={16} />}
                    </IconButton>
                </Stack>

                {isExpressionMode ? (
                    <ExpressionMode
                        expressionReviewers={currentNode.expressionReviewers}
                        onExpressionReviewersChange={handleExpressionReviewersChange}
                    />
                ) : (
                    <>
                        <UserSelect users={users} members={members} onSelectUser={handleSelectUser} />
                        <SafeFormattedMessage tagName="h5" {...messages.checkNodeConfiguration.teamsTitle} />
                        <TeamSelect
                            teams={teams}
                            allTeams={allTeams}
                            loading={loadingAllTeams}
                            onSelectTeam={handleSelectTeam}
                        />
                    </>
                )}
            </Stack>

            <Stack gap={2} className={configurationNodeItemClassName}>
                <TextField
                    label={formatMessage(messages.checkNodeConfiguration.passThreshold)}
                    value={currentNode.passThreshold}
                    size="small"
                    onChange={handleChangeThreshold("passThreshold")}
                    type="number"
                    inputProps={{ min: 1 }}
                />

                <TextField
                    label={formatMessage(messages.checkNodeConfiguration.refuseThreshold)}
                    value={currentNode.refuseThreshold}
                    size="small"
                    onChange={handleChangeThreshold("refuseThreshold")}
                    type="number"
                    inputProps={{ min: 1 }}
                />
            </Stack>
            <AdvancedFields<CheckNode>
                fields={advancedFields}
                currentNode={currentNode}
                setCurrentNode={setCurrentNode}
            />
        </ConfigurationNode>
    )
}
