import { useMemo, useState } from "react"
import { Download } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { Button, Loader, SafeFormattedMessage } from "~/components"
import { SupportedLanguages } from "~/constants/lang"
import { selectLinesTags } from "~/domains/analytics/tags/store/tagsSlice"
import { purchaseOrdersApi } from "~/domains/transactions/purchase-orders/api"
import { PoToPdfItem, PurchaseOrderPDFDTO, PurchaseOrders } from "~/domains/transactions/purchase-orders/types"
import { useAppSelector } from "~/store/hooks"
import { useFetchOrganization } from "~/store/organization/hooks"
import { useGetAllUsersQuery } from "~/store/users/hooks"
import { CountryCode } from "~/types"

const messages = defineMessages({
    pdf: {
        id: "purchase.orders.order.download.pdf",
        defaultMessage: "Download PDF",
    },
    errorAddress: {
        id: "purchase.orders.order.download.errorAddress",
        defaultMessage: "Please fill in the billing and shipping addresses before downloading the PDF",
    },
    errorDownloading: {
        id: "purchase.orders.order.download.errorDownloading",
        defaultMessage: "An error occurred while downloading the PDF",
    },
    prefixPOPdf: {
        id: "purchase.orders.order.download.purchaseOrder.prefix",
        defaultMessage: "Purchase Order",
    },
})

const languagePerCountryCode: Partial<Record<CountryCode, SupportedLanguages>> = {
    [CountryCode.FR]: SupportedLanguages.FR,
    [CountryCode.BE]: SupportedLanguages.FR, // discutable
    [CountryCode.CH]: SupportedLanguages.FR, // discutable
    [CountryCode.DE]: SupportedLanguages.DE,
    [CountryCode.US]: SupportedLanguages.EN,
    [CountryCode.CA]: SupportedLanguages.EN, // discutable
    [CountryCode.GB]: SupportedLanguages.EN,
    [CountryCode.NO]: SupportedLanguages.NO,
}

const getLanguageFromCountryCode = (countryCode: CountryCode) => {
    const language = languagePerCountryCode[countryCode]
    if (language) return language
    return SupportedLanguages.EN
}

interface Props {
    purchaseOrder: PurchaseOrders
}

export const DownloadPOPdf = ({ purchaseOrder }: Props) => {
    const [loading, setLoading] = useState(false)
    const { organization: supplier } = useFetchOrganization(purchaseOrder.supplierId)
    const { organization: buyer } = useFetchOrganization(purchaseOrder.buyerId)
    const tags = useAppSelector(selectLinesTags)
    // useMemo is to avoid giving useGetAllUsersQuery as new array each time
    // because it will cause an infinite call of the API
    const requesterUserId = useMemo(
        () => (purchaseOrder.requesterUserId ? [purchaseOrder.requesterUserId] : []),
        [purchaseOrder.requesterUserId]
    )
    const { users } = useGetAllUsersQuery(requesterUserId)
    const requester = users[0]
    const { formatMessage } = useIntl()

    const purchaseOrderToPdf = (po: PurchaseOrders) => {
        if (!buyer || !supplier) return null
        const items = po.lines.map((line): PoToPdfItem => {
            const tagsForItem = tags
                .filter((tag) => tag.objectId === line.id)
                .map((tag) => ({
                    tagId: tag.tagId,
                    name: tag.name,
                    tagGroupId: tag.tagGroupId,
                    parentId: tag.parentId,
                    description: tag.description,
                    externalId: tag.externalId,
                    value: tag.value,
                    ratio: tag.ratio,
                    tagGroupName: tag.tagGroupName,
                }))
            return {
                description: line.description,
                quantity: line.quantity,
                unitPriceExcludingTax: line.unitPriceExcludingTax,
                taxRate: line.taxRate,
                totalAmount: line.totalAmount,
                unit: line.units,
                tags: tagsForItem,
            }
        })
        if (po.billingAddress && po.shippingAddress) {
            const purchaseOrderPdf: PurchaseOrderPDFDTO = {
                budgets:
                    po.budgets?.map((budget) => ({
                        id: budget.budget.id,
                        name: budget.budget.name,
                        description: budget.budget.description,
                        internalReference: budget.budget.internalReference,
                    })) ?? [],
                supplier: {
                    name: po.supplierName,
                    address: {
                        street: supplier.address?.addressLine ?? "",
                        street2: supplier.address?.secondaryAddressLine ?? "",
                        zipCode: supplier.address?.zipCode ?? "",
                        city: supplier.address?.city ?? "",
                        country: supplier.address?.country ?? "",
                    },
                    registrationInformation: {
                        registrationNumber: supplier.registration.preferredRegistrationNumber?.registrationNumber,
                        registrationType: supplier.registration.preferredRegistrationNumber?.registrationType,
                        vatNumber: supplier.registration.vatNumber ?? undefined,
                    },
                    contact: supplier.accountingContact
                        ? {
                              name: `${supplier.accountingContact.firstName} ${supplier.accountingContact.lastName}`,
                              email: supplier.accountingContact.email,
                              phone: supplier.accountingContact.phoneNumber,
                          }
                        : undefined,
                },
                buyer: {
                    name: po.buyerName,
                    billingAddress: po.billingAddress,
                    shippingAddress: po.shippingAddress,
                    registrationInformation: {
                        registrationNumber: buyer.registration.preferredRegistrationNumber?.registrationNumber,
                        registrationType: buyer.registration.preferredRegistrationNumber?.registrationType,
                        vatNumber: buyer.registration.vatNumber ?? undefined,
                    },
                    contact: requester
                        ? {
                              name:
                                  po.requesterName ??
                                  requester.fullName ??
                                  `${requester.givenName} ${requester.familyName}`,
                              email: requester.email,
                              phone: requester.phoneNumber,
                          }
                        : undefined,
                },
                description: po.description,
                purchaseOrderId: po.shortId,
                issueDate: po.creationDate,
                deliveryDate: po.expectedDeliveryDate,
                paymentTerms: "-",
                items: items,
                currency: po.currency,
                totalAmount: po.totalAmount,
                totalAmountExcludingTax: po.totalAmountExcludingTax,
                totalTax: po.totalTax,
                language: getLanguageFromCountryCode(supplier.registration.countryCode),
            }
            return purchaseOrderPdf
        } else {
            toast.error(formatMessage(messages.errorAddress))
            return false
        }
    }

    const handleDownload = async () => {
        setLoading(true)
        try {
            const poData = purchaseOrderToPdf(purchaseOrder)
            if (poData) {
                const fileName = `[${formatMessage(messages.prefixPOPdf)}]${poData.buyer.name}-${poData.supplier.name}-${poData.purchaseOrderId}.pdf`
                await purchaseOrdersApi.toPDF(poData, fileName, purchaseOrder.buyerId)
            }
        } catch (error) {
            console.error(error)
            toast.error(formatMessage(messages.errorDownloading))
        } finally {
            setLoading(false)
        }
    }
    return !loading && supplier ? (
        <Button onClick={handleDownload} type="menu-item">
            <Download size={16} />
            <SafeFormattedMessage {...messages.pdf} />
        </Button>
    ) : (
        <Loader small={true} />
    )
}
