export enum UnitEnum {
    UNIT = "UNIT",
    LINEAR_METER = "LINEAR_METER",
    DAY = "DAY",
    TON = "TON",
    AMOUNT = "AMOUNT",
    METRE_CUBE = "METRE_CUBE",
    HOUR = "HOUR",
    BOX = "BOX",
    MONTH = "MONTH",
    METER = "METER",
    SQUARE_METER = "SQUARE_METER",
    LITER = "LITER",
    KILOGRAM = "KILOGRAM",
    HALF_DAY = "HALF_DAY",
    BAG = "BAG",
    PAIR = "PAIR",
    SET = "SET",
    PIECE = "PIECE",
    CARTON = "CARTON",
    PACKAGE = "PACKAGE",
    ROLL = "ROLL",
    BAGGIE = "BAGGIE",
    CAN = "CAN",
    LOT = "LOT",
    BAR = "BAR",
    WEEK = "WEEK",
    KILOMETER = "KILOMETER",
    PALLET = "PALLET",
    REEL = "REEL",
    TRUCK_TOUR = "TRUCK_TOUR",
    BOTTLE = "BOTTLE",
    COIL = "COIL",
    QUARTER_DAY = "QUARTER_DAY",
    QUARTER = "QUARTER",
    BUCKET = "BUCKET",
    DRUM = "DRUM",
  }