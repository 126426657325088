import { Route } from "react-router-dom"

import { ProtectedRoutes } from "~/auth/ProtectedRoute"
import { PurchaseOrders } from "~/domains/transactions/purchase-orders/pages"
import { PurchaseOrder } from "~/domains/transactions/purchase-orders/pages/[ID]"
import { PurchaseOrderNew } from "~/domains/transactions/purchase-orders/pages/[ID]/create"
import { PurchaseOrdersPaginated } from "~/domains/transactions/purchase-orders/pages/paginated"
import {
    PURCHASE_ORDERS_PAGINATED_ROUTE,
    PURCHASE_ORDERS_ROUTE,
    PURCHASE_ORDER_EDIT_ROUTE,
    PURCHASE_ORDER_NEW_ROUTE,
    PURCHASE_ORDER_ROUTE,
} from "~/domains/transactions/purchase-orders/routes"

export default {
    routes: [
        <Route element={<ProtectedRoutes />} key="pos_route">
            <Route path={PURCHASE_ORDERS_ROUTE} element={<PurchaseOrders />} />
            <Route path={PURCHASE_ORDERS_PAGINATED_ROUTE} element={<PurchaseOrdersPaginated />} />
            <Route path={PURCHASE_ORDER_EDIT_ROUTE} element={<PurchaseOrder edit={true} />} />
            <Route path={PURCHASE_ORDER_NEW_ROUTE} element={<PurchaseOrderNew />} />
        </Route>,
        <Route key="pos_unsafe_route">
            <Route path={PURCHASE_ORDER_ROUTE} element={<PurchaseOrder />} />
        </Route>,
    ],
}
