import { FC, Suspense, lazy, useEffect, useState } from "react"
import { Outlet } from "react-router-dom"

import { Loader } from "~/components"
import { AknowledgePurchasePolicyInitialConfirmationModal } from "~/domains/identity/account/components/AknowledgePurchasePolicyWall/AknowledgePurchasePolicyInitialConfirmationModal"
import { AknowledgePurchasePolicySummaryModal } from "~/domains/identity/account/components/AknowledgePurchasePolicyWall/AknowledgePurchasePolicySummaryModal"
import { FinalPurchasePolicyAknowledgeModal } from "~/domains/identity/account/components/AknowledgePurchasePolicyWall/FinalPurchasePolicyAknowledgeModal"
import { useAcknowledgePurchasePolicyMutation, useGetUserByIdQuery } from "~/domains/identity/users-v2/api/userV2Api"
import { OrganizationId, UserI } from "~/types"
import { Features, isFeatureEnabled } from "~/utils/featureFlag"

const ReadPurchasePolicyModal = lazy(() =>
    import("~/domains/identity/account/components/AknowledgePurchasePolicyWall/ReadPurchasePolicyModal").then(
        ({ ReadPurchasePolicyModal: ReadPurchasePolicyModalComponent }) => ({
            default: ReadPurchasePolicyModalComponent,
        })
    )
)

enum AknowledgePurchasePolicyWallSteps {
    INITIAL_CONFIRMATION = "INITIAL_CONFIRMATION",
    READING_PURCHASE_POLICY = "READING_PURCHASE_POLICY",
    AKNOWLEDGE_SUMMARY = "AKNOWLEDGE_SUMMARY",
    AKNOWLEDGE_POLICIES = "AKNOWLEDGE_POLICIES",
    AKNOWLEDGE_COMPLETED = "AKNOWLEDGE_COMPLETED",
}

const followingStep: Record<AknowledgePurchasePolicyWallSteps, AknowledgePurchasePolicyWallSteps | null> = {
    [AknowledgePurchasePolicyWallSteps.INITIAL_CONFIRMATION]: AknowledgePurchasePolicyWallSteps.READING_PURCHASE_POLICY,
    [AknowledgePurchasePolicyWallSteps.READING_PURCHASE_POLICY]: AknowledgePurchasePolicyWallSteps.AKNOWLEDGE_SUMMARY,
    [AknowledgePurchasePolicyWallSteps.AKNOWLEDGE_SUMMARY]: AknowledgePurchasePolicyWallSteps.AKNOWLEDGE_POLICIES,
    [AknowledgePurchasePolicyWallSteps.AKNOWLEDGE_POLICIES]: AknowledgePurchasePolicyWallSteps.AKNOWLEDGE_COMPLETED,
    [AknowledgePurchasePolicyWallSteps.AKNOWLEDGE_COMPLETED]: null,
}

type AcknowledgePurchasePolicyWallState = {
    step: AknowledgePurchasePolicyWallSteps
    validations: Partial<Record<AknowledgePurchasePolicyWallSteps, string>>
}

const INITIAL_STATE = { step: AknowledgePurchasePolicyWallSteps.INITIAL_CONFIRMATION, validations: {} }

interface Props {
    isConnected: boolean
    user: UserI
    organizationId: OrganizationId | undefined
}

export const AknowledgePurchasePolicyWall: FC<Props> = ({ isConnected, user, organizationId }) => {
    const [state, setState] = useState<AcknowledgePurchasePolicyWallState | null>(null)
    const isAcknowledgementEnabled = isFeatureEnabled(Features.AknowledgePurchasePolicyWall, organizationId)

    const [acknowledgePurchasePolicy] = useAcknowledgePurchasePolicyMutation()

    const doUserQuery = isConnected && isAcknowledgementEnabled && user.id
    const renderWall = doUserQuery && state && state.step !== AknowledgePurchasePolicyWallSteps.AKNOWLEDGE_COMPLETED

    // FIXME: We need this call to get the purchasePolicyAck field
    // This field should be in the current user from the beginning
    // To remove when we switch to Org V2
    const { data: userData } = useGetUserByIdQuery({ userId: user.id }, { skip: !doUserQuery })
    useEffect(() => {
        if (userData) {
            setState(userData.purchasePolicyAck ? null : INITIAL_STATE)
        }
    }, [userData])

    const getSetStateForStep = (step: AknowledgePurchasePolicyWallSteps) => (confirmDate: Date) => {
        const nextStep = followingStep[step]
        setState({
            step: nextStep ?? step,
            validations: {
                ...state?.validations,
                [step]: confirmDate.toISOString(),
            },
        })

        if (step === AknowledgePurchasePolicyWallSteps.AKNOWLEDGE_POLICIES) {
            acknowledgePurchasePolicy({ userId: user.id })
        }
    }

    if (!renderWall) {
        return <Outlet />
    }

    if (state.step === AknowledgePurchasePolicyWallSteps.INITIAL_CONFIRMATION) {
        return (
            <AknowledgePurchasePolicyInitialConfirmationModal
                onConfirm={getSetStateForStep(AknowledgePurchasePolicyWallSteps.INITIAL_CONFIRMATION)}
            />
        )
    }
    if (state.step === AknowledgePurchasePolicyWallSteps.READING_PURCHASE_POLICY) {
        return (
            <Suspense fallback={<Loader />}>
                <ReadPurchasePolicyModal
                    onConfirm={getSetStateForStep(AknowledgePurchasePolicyWallSteps.READING_PURCHASE_POLICY)}
                />
            </Suspense>
        )
    }
    if (state.step === AknowledgePurchasePolicyWallSteps.AKNOWLEDGE_SUMMARY) {
        return (
            <AknowledgePurchasePolicySummaryModal
                onConfirm={getSetStateForStep(AknowledgePurchasePolicyWallSteps.AKNOWLEDGE_SUMMARY)}
            />
        )
    }
    if (state.step === AknowledgePurchasePolicyWallSteps.AKNOWLEDGE_POLICIES) {
        return (
            <FinalPurchasePolicyAknowledgeModal
                onConfirm={getSetStateForStep(AknowledgePurchasePolicyWallSteps.AKNOWLEDGE_POLICIES)}
            />
        )
    }
    return <Outlet />
}
