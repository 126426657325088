import { Draft } from "@reduxjs/toolkit"

import { InvoiceStatus } from "~/types"

import { InvoiceState } from "./invoiceState"

export const supplierActions = {
    markAsResolved(state: Draft<InvoiceState>) {
        state.loading = true
    },
    markAsResolvedSuccess(state: Draft<InvoiceState>) {
        state.loading = false
        if (state.invoice) {
            state.invoice.status = InvoiceStatus.CONFIRMED
        }
    },
    markAsResolvedFailed(state: Draft<InvoiceState>) {
        state.loading = false
        // TODO: the error state was **never** used anywhere, I removed it but we might want to handle the error case somehow.
    },
    patchInvoice(state: Draft<InvoiceState>) {
        state.loading = true
    },
    patchInvoiceSuccess(state: Draft<InvoiceState>) {
        state.loading = false
    },
    patchInvoiceFailed(state: Draft<InvoiceState>) {
        state.loading = false
        // TODO: the error state was **never** used anywhere, I removed it but we might want to handle the error case somehow.
    },
    addInvoice(state: Draft<InvoiceState>) {
        state.loading = true
    },
    addInvoiceSuccess(state: Draft<InvoiceState>) {
        state.loading = false
    },
    addInvoiceFailed(state: Draft<InvoiceState>) {
        state.loading = false
        // TODO: the error state was **never** used anywhere, I removed it but we might want to handle the error case somehow.
    },
}
