import { Edit } from "react-feather"

import { SetPoFulfillmentStatusConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { RegularNode } from "~/domains/orchestration/flows/components/nodes"
import { adaptEntityTriggerProperties } from "~/domains/orchestration/flows/core"
import { adaptEventTriggerProperties } from "~/domains/orchestration/flows/core"
import { isEventTriggerType } from "~/domains/orchestration/flows/types"
import { Trigger } from "~/domains/orchestration/flows/types"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    EditorNode,
    Node,
    NodeType,
    SetPoFulfillmentStatusNode as SetPoFulfillmentStatusNodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const setPoFulfillmentStatusNode = (trigger: Trigger): Omit<SetPoFulfillmentStatusNodeType, keyof Node> => {
    const { objectId } = isEventTriggerType(trigger)
        ? adaptEventTriggerProperties(trigger.type, trigger.slug)
        : adaptEntityTriggerProperties(trigger.type, trigger.slug)
    return {
        purchaseOrderId: objectId,
        statusToSet: null,
        type: NodeType.SET_PO_FULFILLMENT_STATUS_NODE,
        nextNode: null,
    }
}

const advancedFields: AllAdvancedFieldsUnion[] = [
    AllAdvancedFieldsUnion.PURCHASE_ORDER_ID,
    AllAdvancedFieldsUnion.STATUS_TO_SET,
]

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.SET_PO_FULFILLMENT_STATUS_NODE) return false
    return Boolean(node.purchaseOrderId && node.statusToSet)
}

const setPurchaseOrderFulfillmentStatusConfig: Configuration<NodeType.SET_PO_FULFILLMENT_STATUS_NODE> = {
    type: NodeType.SET_PO_FULFILLMENT_STATUS_NODE,
    baseSlug: "set-po-fulfillment-status",
    Icon: Edit,
    Configuration: SetPoFulfillmentStatusConfiguration,
    advancedFields,
    Node: RegularNode,
    factory: (props: FactoryProps): SetPoFulfillmentStatusNodeType => ({
        ...setPoFulfillmentStatusNode(props.trigger),
        ...createCommonNodeProperties(props),
    }),
    validateNode,
}

export { setPurchaseOrderFulfillmentStatusConfig, advancedFields as setPurchaseOrderFulfillmentStatusAdvancedFields }
