import { XSquare } from "react-feather"

import { RetractReviewsConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { RegularNode } from "~/domains/orchestration/flows/components/nodes"
import { adaptEntityTriggerProperties, adaptEventTriggerProperties } from "~/domains/orchestration/flows/core"
import { Trigger, isEventTriggerType } from "~/domains/orchestration/flows/types"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    EditorNode,
    Node,
    NodeType,
    RetractReviewsNode as RetractReviewsNodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const retractReviewsNode = (trigger: Trigger): Omit<RetractReviewsNodeType, keyof Node> => {
    const { objectType, objectId } = isEventTriggerType(trigger)
        ? adaptEventTriggerProperties(trigger.type, trigger.slug)
        : adaptEntityTriggerProperties(trigger.type, trigger.slug)
    return {
        objectId,
        objectType,
        type: NodeType.RETRACT_REVIEWS_NODE,
        nextNode: null,
    }
}

const advancedFields: AllAdvancedFieldsUnion[] = [AllAdvancedFieldsUnion.OBJECT_ID, AllAdvancedFieldsUnion.OBJECT_TYPE]

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.RETRACT_REVIEWS_NODE) return false
    return Boolean(node.objectId && node.objectType)
}

const retractReviewsConfig: Configuration<NodeType.RETRACT_REVIEWS_NODE> = {
    type: NodeType.RETRACT_REVIEWS_NODE,
    baseSlug: "retract-reviews",
    Icon: XSquare,
    Configuration: RetractReviewsConfiguration,
    advancedFields,
    Node: RegularNode,
    factory: (props: FactoryProps): RetractReviewsNodeType => ({
        ...retractReviewsNode(props.trigger),
        ...createCommonNodeProperties({ ...props, error: false }),
    }),
    validateNode,
}

export { retractReviewsConfig, advancedFields as retractReviewsAdvancedFields }
