import * as Sentry from "@sentry/react"
import { call, put, select } from "redux-saga/effects"

import { invoiceApi } from "~/domains/transactions/invoices/api/invoiceApi"
import { invoiceActions, selectInvoice } from "~/store/invoice/invoiceSlice"
import { InvoiceI } from "~/types"

export function* markAsResolved() {
    try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const invoice: InvoiceI | null = yield select(selectInvoice)
        if (invoice) {
            yield call(invoiceApi.markAsResolved, invoice.id)
            yield put(invoiceActions.markAsResolvedSuccess())
        } else {
            yield put(invoiceActions.markAsResolvedFailed())
        }
    } catch (error) {
        Sentry.captureException(error)
        yield put(invoiceActions.markAsResolvedFailed())
    }
}
