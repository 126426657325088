import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorizationAndOrganizationId as prepareHeaders } from "~/api/prepareHeaders"
import { userFromApiAdapter } from "~/domains/identity/account/api/adapters/userFromApiAdapter"
import { GetUserByIdsQueryParams } from "~/domains/identity/account/api/types/userV1Api.types"
import { UserI } from "~/domains/identity/account/types/UserTypes"

const STAKEHOLDERS_BASE_URL = import.meta.env.VITE_API_STAKEHOLDERS_URL
const BASE_URL = `${STAKEHOLDERS_BASE_URL}v1/users`

export const userV1Api = createApi({
    reducerPath: "userApi",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        getUserByIds: builder.query<UserI[], GetUserByIdsQueryParams>({
            query: ({ ids }) => `?ids=${encodeURIComponent(ids.join(","))}`,
            transformResponse: (response: UserI[]) => response.map(userFromApiAdapter),
        }),
    }),
})

export const { useGetUserByIdsQuery, useLazyGetUserByIdsQuery } = userV1Api
