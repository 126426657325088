import { Send } from "react-feather"

import { commonMessages } from "~/common-messages"
import { SafeFormattedMessage, TooltipConditional } from "~/components"
import { Button } from "~/components/Button/Button"
import { permissionMessages } from "~/domains/identity/roles-permissions/utils/permissions"
import { useUpdatePurchaseRequest } from "~/domains/transactions/purchase-requests/store/hooks/useUpdatePurchaseRequest"
import { useUpdatePurchaseRequestStatus } from "~/domains/transactions/purchase-requests/store/hooks/useUpdatePurchaseRequestStatus"
import { PurchaseRequestStatus } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"

interface SumitPRProps {
    organizationId: string
    id: string
    hasPermission: boolean
}

export const SubmitPR = ({ organizationId, id, hasPermission }: SumitPRProps) => {
    const { updatePR } = useUpdatePurchaseRequest(organizationId, id)
    const { updatePRStatus } = useUpdatePurchaseRequestStatus(organizationId, id)

    const handleSubmit = async () => {
        await updatePR(false)
        await updatePRStatus(PurchaseRequestStatus.SUBMITTED)
    }

    return (
        <TooltipConditional
            arrow
            title={<SafeFormattedMessage {...permissionMessages.errorNoAccessAdministrator} />}
            condition={!hasPermission}
        >
            <span>
                <Button type="primary-light" disabled={!hasPermission} onClick={handleSubmit}>
                    <Send size={18} />
                    <SafeFormattedMessage {...commonMessages.submit} />
                </Button>
            </span>
        </TooltipConditional>
    )
}
