import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { ExtendedFetchBaseQueryError, taggedBaseQuery } from "~/api/rtkFetchBaseExtend"
import { commonMessages } from "~/common-messages"
import { SupportedLanguages } from "~/constants/lang"
import { userV2FromApiAdapter } from "~/domains/identity/users-v2/api/adapters/userV2FromApiAdapter"
import { UserV2 } from "~/domains/identity/users-v2/types/UserV2Types"
import { UserI } from "~/types"

interface GetUserByIdQuery {
    userId: string
}

interface UpdateUserLanguageMutation {
    userId: string
    language: SupportedLanguages
}

interface AcknowledgePurchasePolicyMutation {
    userId: string
}

const BASE_URL = import.meta.env.VITE_API_ORG_V2_URL

export const userV2Api = createApi({
    reducerPath: "userV2Api",
    baseQuery: taggedBaseQuery({
        baseQueryFn: fetchBaseQuery({
            baseUrl: BASE_URL,
        }),
        domain: "identity",
        subdomain: "users",
    }),
    tagTypes: ["User"],
    endpoints: (builder) => ({
        getUserById: builder.query<UserI, GetUserByIdQuery>({
            query: ({ userId }) => ({
                url: `/users/${userId}`,
            }),
            transformResponse: (response: UserV2) => userV2FromApiAdapter(response),
            providesTags: ["User"],
        }),
        updateUserLanguage: builder.mutation<boolean, UpdateUserLanguageMutation>({
            query: ({ userId, language }) => ({
                url: `/users/language`,
                method: "PUT",
                body: {
                    userId,
                    language: language.toUpperCase(),
                },
            }),
            invalidatesTags: ["User"],
            transformErrorResponse: (response: ExtendedFetchBaseQueryError) => ({
                ...response,
                meta: {
                    ...response.meta,
                    errorMessage: commonMessages.error,
                },
            }),
        }),
        acknowledgePurchasePolicy: builder.mutation<void, AcknowledgePurchasePolicyMutation>({
            query: ({ userId }) => ({
                url: `/users/purchase-policy-ack`,
                method: "PUT",
                body: {
                    userId,
                },
            }),
            transformErrorResponse: (response: ExtendedFetchBaseQueryError) => ({
                ...response,
                meta: {
                    ...response.meta,
                    errorMessage: commonMessages.error,
                },
            }),
        }),
    }),
})

export const { useGetUserByIdQuery, useUpdateUserLanguageMutation, useAcknowledgePurchasePolicyMutation } = userV2Api
