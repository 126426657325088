import { useCallback, useEffect, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { PaymentTermsSelector } from "~/domains/payment/payment-terms/components"
import { useManagePaymentTerms } from "~/domains/payment/payment-terms/hooks/useManagePaymentTerms"
import { PaymentTerm } from "~/domains/payment/payment-terms/types"
import { ObjectType } from "~/domains/payment/types"

const messages = defineMessages({
    paymentTerms: {
        id: "payment.documentPaymentTerm.paymentTerms",
        defaultMessage: "Payment terms",
    },
    linkError: {
        id: "payment.documentPaymentTerm.linkError",
        defaultMessage: "Failed to link payment term",
    },
})

interface DocumentPaymentTermProps {
    loading?: boolean
    paymentId: string
}

export const DocumentPaymentTerm = ({ paymentId, loading = false }: DocumentPaymentTermProps) => {
    const { formatMessage } = useIntl()
    const [paymentTermId, setPaymentTermId] = useState<string>("")

    const {
        loading: isLoading,
        paymentTerms,
        objectPaymentTerm,
        defaultPaymentTermObject,
        createPaymentTermObject,
        updatePaymentTermObject,
    } = useManagePaymentTerms({ objectId: paymentId, objectType: ObjectType.PAYMENT }, { showList: true })

    const isLoadingState = loading || isLoading

    const handlePaymentTermChange = useCallback(
        async (paymentTerm: PaymentTerm | null) => {
            if (!objectPaymentTerm) {
                try {
                    await createPaymentTermObject({
                        object_id: paymentId,
                        object_type: ObjectType.PAYMENT,
                        payment_term_id: paymentTerm?.id ?? "",
                        is_default: true,
                    }).unwrap()
                    setPaymentTermId(paymentTerm?.id ?? "")
                } catch (error) {
                    toast.error(formatMessage(messages.linkError))
                }
            } else if (paymentTerm && defaultPaymentTermObject?.payment_term_id !== paymentTerm.id) {
                try {
                    await updatePaymentTermObject({
                        id: defaultPaymentTermObject?.id,
                        payment_term_id: paymentTerm.id,
                        object_type: ObjectType.PAYMENT,
                    }).unwrap()
                    setPaymentTermId(paymentTerm?.id ?? "")
                } catch (error) {
                    toast.error(formatMessage(messages.linkError))
                }
            }
        },
        [
            defaultPaymentTermObject,
            objectPaymentTerm,
            createPaymentTermObject,
            updatePaymentTermObject,
            paymentId,
            formatMessage,
        ]
    )

    useEffect(() => {
        if (objectPaymentTerm?.id && paymentTerms.length > 0) {
            setPaymentTermId(objectPaymentTerm.id)
        }
    }, [objectPaymentTerm?.id, paymentTerms])

    return (
        <PaymentTermsSelector
            label={formatMessage(messages.paymentTerms)}
            items={paymentTerms}
            disabled={isLoadingState}
            loading={isLoadingState}
            value={paymentTermId}
            onChange={handlePaymentTermChange}
        />
    )
}
