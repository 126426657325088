import { Stack } from "@mui/material"
import { AlertTriangle, CheckCircle } from "react-feather"
import { defineMessages } from "react-intl"

import { SafeFormattedMessage } from "~/components"

const messages = defineMessages({
    dueDateSooner: {
        id: "payment.paymentTerms.dueDateSooner",
        defaultMessage: "The due date is earlier than the expected date based on the active payment terms.",
    },
    dueDateLater: {
        id: "payment.paymentTerms.dueDateLater",
        defaultMessage: "The due date is later than the expected date based on the active payment terms.",
    },
})

interface DueDateNotificationsProps {
    isSooner: boolean
}

export const DueDateNotifications = ({ isSooner }: DueDateNotificationsProps) =>
    isSooner ? (
        <Stack direction="row" gap={1}>
            <AlertTriangle color="var(--color-yellow)" size={20} />
            <SafeFormattedMessage {...messages.dueDateSooner} />
        </Stack>
    ) : (
        <Stack direction="row" gap={1}>
            <CheckCircle color="var(--color-green)" size={20} />
            <SafeFormattedMessage {...messages.dueDateLater} />
        </Stack>
    )
