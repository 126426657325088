import { Stack, Typography } from "@mui/material"
import { User } from "react-feather"

import { SafeFormattedMessage } from "~/components/SafeFormattedMessage/SafeFormattedMessage"
import { permissionMessages } from "~/domains/identity/roles-permissions/utils/permissions"

interface NoPermissionProps {
    size?: "md" | "sm"
    hideIcon?: boolean
}

export const NoPermission = ({ size = "md", hideIcon = false }: NoPermissionProps) => {
    const iconSize = size === "md" ? 34 : 24
    const textSize = size === "md" ? "var(--font-size-md)" : "var(--font-size-sm)"

    return (
        <Stack direction="column" justifyContent="center" alignItems="center" gap={1}>
            {!hideIcon && <User size={iconSize} color="var(--color-grey-light)" />}
            <Typography variant="body2" textAlign="center" color="var(--color-grey-light)" fontSize={textSize}>
                <SafeFormattedMessage {...permissionMessages.errorNoAccess} />
            </Typography>
        </Stack>
    )
}
