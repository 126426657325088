import { useEffect, useState } from "react"

import { useLazyGetUserByIdsQuery } from "~/domains/identity/account/api/userV1Api"
import { useAppSelector } from "~/store/hooks"
import { selectUsers } from "~/store/users/usersSlice"

interface UserNameProps {
    userId: string
}

export const UserName = ({ userId }: UserNameProps) => {
    const [userName, setUserName] = useState<string | null>(null)
    const users = useAppSelector(selectUsers)

    const [getUserByIds, { data }] = useLazyGetUserByIdsQuery()

    useEffect(() => {
        if (!users) return
        ;(async () => {
            if (users?.[userId]) {
                setUserName(users?.[userId]?.fullName ?? null)
            } else {
                await getUserByIds({ ids: [userId] })
                setUserName(data?.[0]?.fullName ?? null)
            }
        })()
    }, [getUserByIds, users, userId, data])

    return <div>{userName ?? "-"}</div>
}
