import * as Sentry from "@sentry/react"
import { useCallback, useMemo } from "react"

import { invoiceApi } from "~/domains/transactions/invoices/api/invoiceApi"
import { store } from "~/store"
import { accountActions, selectUser } from "~/store/account/accountSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { useCurrentOrganization, useFetchOrganization } from "~/store/organization/hooks"
import { CountryCode, InvoiceI, InvoiceUserType, UpdateInvoicePayload } from "~/types"

import { ConfirmInvoiceOptions, getImportCompanyIdentifierOrThrow, setUserOrganizationId } from "../core"
import { invoiceActions, selectInvoice } from "../invoiceSlice"

export const usePatchInvoice = (initiator: InvoiceUserType) => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(selectUser)
    const organization = useCurrentOrganization(user.organizations)
    const options: ConfirmInvoiceOptions = useMemo(() => {
        const payload = {
            userId: user.id,
            organizationId: organization ? organization.id : undefined,
        }
        return initiator === InvoiceUserType.SUPPLIER ? { supplier: payload } : { buyer: payload }
    }, [initiator, user.id, organization])

    const { organization: supplierOrganization } = useFetchOrganization(
        options.supplier ? options.supplier.organizationId : undefined
    )
    const { organization: buyerOrganization } = useFetchOrganization(
        options.buyer ? options.buyer.organizationId : undefined
    )

    return useCallback(
        // eslint-disable-next-line complexity
        async (noLoading = false, newInvoice?: InvoiceI) => {
            try {
                !noLoading && dispatch(invoiceActions.patchInvoice())
                const invoice = newInvoice || selectInvoice(store.getState())
                if (!invoice) {
                    throw new Error("Invoice must be defined")
                }

                const supplierIdentifier = getImportCompanyIdentifierOrThrow(invoice.supplier, InvoiceUserType.SUPPLIER)
                const buyerIdentifier = getImportCompanyIdentifierOrThrow(invoice.buyer, InvoiceUserType.BUYER)

                const supplier = await setUserOrganizationId(
                    options.supplier,
                    {
                        countryCode: invoice.supplier?.countryCode ?? CountryCode.UNKNOWN,
                        email: invoice.supplier.email ?? "",
                        identifier: supplierIdentifier,
                        name: invoice.supplier.contactName ?? "",
                    },
                    supplierOrganization,
                    {
                        name: invoice.supplier.name,
                        countryCode: invoice.supplier.countryCode,
                        identifier: supplierIdentifier,
                        dunsNumber: invoice.supplier.dunsNumber,
                        registrationNumber: invoice.supplier.registrationNumber,
                        vatNumber: invoice.supplier.taxId,
                    }
                )
                dispatch(invoiceActions.updatePartialInvoiceSupplier({ organizationId: supplier.organizationId }))
                if (initiator === InvoiceUserType.SUPPLIER && supplier.organizationFounded) {
                    dispatch(accountActions.addOrganization(supplier.organizationId))
                }
                const buyer = await setUserOrganizationId(
                    options.buyer,
                    {
                        email: invoice.buyer.email ?? "",
                        countryCode: invoice.buyer.countryCode,
                        identifier: buyerIdentifier,
                        name: invoice.buyer.contactName ?? "",
                    },
                    buyerOrganization,
                    {
                        name: invoice.buyer.name,
                        countryCode: invoice.buyer.countryCode,
                        identifier: buyerIdentifier,
                        dunsNumber: invoice.buyer.dunsNumber,
                        registrationNumber: invoice.buyer.registrationNumber,
                        vatNumber: invoice.buyer.taxId,
                    }
                )
                dispatch(invoiceActions.updatePartialInvoiceBuyer({ organizationId: buyer.organizationId }))
                if (initiator === InvoiceUserType.BUYER && buyer.organizationFounded) {
                    dispatch(accountActions.addOrganization(buyer.organizationId))
                }
                if (buyer.userId) {
                    dispatch(invoiceActions.setBuyerId(buyer.userId))
                }
                const updatedInvoice = selectInvoice(store.getState())
                if (!updatedInvoice) {
                    throw new Error("Invoice must be defined")
                }
                const updateData: UpdateInvoicePayload = {
                    version: updatedInvoice.version,
                    description: updatedInvoice.description || "",
                    reference: updatedInvoice.reference || "",
                    purchaseOrderNumber: updatedInvoice.purchaseOrderNumber,
                    total: updatedInvoice.total || 0,
                    totalDiscount: updatedInvoice.totalDiscount || null,
                    totalExcludedTaxes: updatedInvoice.totalExcludedTaxes || 0,
                    issueDate: updatedInvoice.issueDate ?? "",
                    dueDate: updatedInvoice.dueDate ?? "",
                    buyer: {
                        organizationId: buyer.organizationId,
                        involvedUserIds: buyer.userId ? [buyer.userId] : [],
                    },
                    supplier: {
                        organizationId: supplier.organizationId,
                        involvedUserIds: supplier.userId ? [supplier.userId] : [],
                    },
                    paymentDetails: {
                        iban: updatedInvoice.paymentDetails?.iban,
                    },
                    lines: updatedInvoice.lines || undefined,
                }
                await invoiceApi.updateInvoice(invoice.id, updateData)
                !noLoading && dispatch(invoiceActions.patchInvoiceSuccess())
            } catch (error) {
                Sentry.captureException(error)
                dispatch(invoiceActions.patchInvoiceFailed())
            }
        },
        [dispatch, initiator, supplierOrganization, buyerOrganization, options]
    )
}
