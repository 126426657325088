import { Grid, Stack, Tooltip } from "@mui/material"
import { Info } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { ItemLabel, Loader } from "~/components"
import { useManagePaymentTerms } from "~/domains/payment/payment-terms/hooks/useManagePaymentTerms"
import { ObjectType } from "~/domains/payment/types"

const messages = defineMessages({
    paymentTerms: {
        id: "payment.documentPaymentTerm.paymentTerms",
        defaultMessage: "Payment terms",
    },
})

interface PaymentTermInfoProps {
    paymentId: string
}

export const PaymentTermInfo = ({ paymentId }: PaymentTermInfoProps) => {
    const { formatMessage, locale } = useIntl()
    const { loading, objectPaymentTerm } = useManagePaymentTerms(
        { objectId: paymentId, objectType: ObjectType.PAYMENT },
        { showList: false }
    )

    if (!objectPaymentTerm) {
        return null
    }

    const { name, description_translations, description, id } = objectPaymentTerm

    if (loading) {
        return <Loader small />
    }

    return (
        <Grid key={id} item xs={12}>
            <ItemLabel>{formatMessage(messages.paymentTerms)}</ItemLabel>
            <Stack direction="row" gap={1} alignItems="center">
                {name ?? "-"}
                <Tooltip arrow title={description_translations[locale] ?? description}>
                    <Info size={16} className="cursor-pointer" color="var(--color-grey-light)" />
                </Tooltip>
            </Stack>
        </Grid>
    )
}
