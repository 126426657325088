import React, { FC } from "react"
import { useIntl } from "react-intl"

import { SharedUserSelect } from "~/domains/orchestration/flows/components/configuration/SharedUserSelect"
import { messages } from "~/domains/orchestration/flows/locale"
import { OrganizationMemberUserI } from "~/types"

interface UserSelectProps {
    users: OrganizationMemberUserI[]
    members: OrganizationMemberUserI[]
    onSelectUser: (event: unknown, values: OrganizationMemberUserI[]) => void
}

export const UserSelect: FC<UserSelectProps> = ({ users, members, onSelectUser }) => {
    const { formatMessage } = useIntl()

    return (
        <SharedUserSelect
            members={members}
            selectedUsers={users}
            onSelectUsers={onSelectUser}
            label={formatMessage(messages.checkNodeConfiguration.user)}
            loading={Boolean(members.length)}
        />
    )
}
