import * as t from "io-ts"

import { CountryCode } from "~/types/CountryCode"
import { Opaque } from "~/utils"
import { optional } from "~/utils/io-ts"

import { OrganizationItemI, UserI, UserId } from "../../account/types/UserTypes"

export type OrganizationId = Opaque<string, { readonly T: unique symbol }>
export type OrganizationIdentifier = Opaque<string, { readonly T: unique symbol }>
export type TeamId = Opaque<string, { readonly T: unique symbol }>
export type DimensionId = Opaque<string, { readonly T: unique symbol }>

export enum OrganizationRole {
    FOUNDER = "FOUNDER",
    STAFF = "STAFF",
    UNKNOWN = "UNKNOWN",
}

export const OrganizationMemberIO = t.type({
    userId: t.string,
    role: t.string,
})

export const OrganizationInvitationIO = t.type({
    userId: t.string,
    role: t.string,
})

export const OrganizationRegistrationNumberIO = t.type({
    registrationType: t.string,
    registrationNumber: t.string,
})

export const OrganizationRegistrationIO = t.intersection([
    t.type({
        countryCode: t.string,
    }),
    t.partial({
        legalName: t.string,
        vatNumber: optional(t.string),
        dunsNumber: optional(t.string),
        preferredRegistrationNumber: optional(OrganizationRegistrationNumberIO),
    }),
])

export const TagsIO = t.intersection([
    t.type({
        tagId: t.string,
        tagName: t.string,
    }),
    t.partial({
        tagValue: optional(t.string),
    }),
])

export const OrganizationDimensionsIO = t.type({
    dimensionId: t.string,
    dimensionName: t.string,
    tags: t.array(TagsIO),
})

export const OrganizationAddressIO = t.type({
    addressLine: t.string,
    secondaryAddressLine: optional(t.string),
    zipCode: t.string,
    city: t.string,
    country: t.string,
})

export const OrganizationAccountingContactIO = t.type({
    firstName: t.string,
    lastName: t.string,
    email: t.string,
    phoneNumber: t.string,
})

export const AxonautCredentialsIO = t.type({
    axonautApiKey: t.string,
})

export const PennylaneCredentialsIO = t.type({
    pennylaneApiKey: t.string,
})

export const TeamMemberIO = t.type({
    userId: t.string,
})

export const CommonOrganizationTeamIO = t.intersection([
    t.type({
        name: t.string,
    }),
    t.partial({
        description: t.string,
    }),
])

export const CreateOrganizationTeamIO = t.intersection([
    CommonOrganizationTeamIO,
    t.partial({
        teamId: t.string,
    }),
])

export const OrganizationTeamIO = t.intersection([
    CommonOrganizationTeamIO,
    t.type({
        members: t.array(TeamMemberIO),
        teamId: t.string,
    }),
])

export const OrganizationIO = t.intersection([
    t.type({
        id: t.string,
        name: t.string,
        members: t.array(OrganizationMemberIO),
        invitations: t.array(OrganizationInvitationIO),
        membershipRequests: t.array(OrganizationInvitationIO),
    }),
    t.partial({
        registration: optional(OrganizationRegistrationIO),
        emailIntegration: optional(t.string),
        axonautProvided: optional(t.boolean),
        pennylaneProvided: optional(t.boolean),
        address: optional(OrganizationAddressIO),
        accountingContact: optional(OrganizationAccountingContactIO),
    }),
])

export type OrganizationMemberI = t.TypeOf<typeof OrganizationMemberIO> & { role: OrganizationRole }
export type OrganizationInvitationI = t.TypeOf<typeof OrganizationInvitationIO> & { role: OrganizationRole }
export type OrganizationRegistrationNumberI = t.TypeOf<typeof OrganizationRegistrationNumberIO>
export type OrganizationRegistrationI = t.TypeOf<typeof OrganizationRegistrationIO> & {
    countryCode: CountryCode
    preferredRegistrationNumber: OrganizationRegistrationNumberI | null
    legalName: string
}
export type OrganizationAddressI = t.TypeOf<typeof OrganizationAddressIO>
export type OrganizationAccountingContactI = t.TypeOf<typeof OrganizationAccountingContactIO>
export type AxonautCredentialsI = t.TypeOf<typeof AxonautCredentialsIO>
export type PennylaneCredentialsI = t.TypeOf<typeof PennylaneCredentialsIO>
export type OrganizationI = t.TypeOf<typeof OrganizationIO> & {
    id: OrganizationId
    identifier: OrganizationIdentifier
    members: OrganizationMemberI[]
    invitations: OrganizationInvitationI[]
    membershipRequests: OrganizationInvitationI[]
    registration: OrganizationRegistrationI
    domain?: string
    isPreferred?: boolean
}

export type OrganizationMemberUserI = OrganizationMemberI & {
    user: UserI
}

export type TeamMemberI = t.TypeOf<typeof TeamMemberIO> & { userId: UserId }
export type OrganizationTeamI = t.TypeOf<typeof OrganizationTeamIO> & {
    organizationId: OrganizationId
    creatorId: UserId
}
export type CreateOrganizationTeamI = t.TypeOf<typeof CreateOrganizationTeamIO> & {
    organizationId: OrganizationId
    creatorId: UserId
}
export type TagsI = t.TypeOf<typeof TagsIO>

export const NO_ORGANIZATION_ID: OrganizationId = "00000000-0000-0000-0000-000000000000"
const NO_ORGANIZATION_IDENTIFIER: OrganizationIdentifier = "XX000000000000"
// This organization is used to fetch self owned documents in my documents
export const NO_ORGANIZATION: OrganizationI = {
    id: NO_ORGANIZATION_ID,
    identifier: NO_ORGANIZATION_IDENTIFIER,
    name: "External Organization",
    members: [],
    invitations: [],
    membershipRequests: [],
    registration: {
        countryCode: CountryCode.UNKNOWN,
        legalName: "External Organization",
        preferredRegistrationNumber: {
            registrationNumber: "",
            registrationType: "UNKNOWN",
        },
    },
}

export const organizationIsNotFake = (organization: OrganizationI | OrganizationItemI) =>
    organization.id !== NO_ORGANIZATION_ID
export const organizationIsFake = (organization: OrganizationI | OrganizationItemI) =>
    organization.id === NO_ORGANIZATION_ID
