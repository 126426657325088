import { useCallback } from "react"

import {
    useCreatePaymentMethodDetailsMutation,
    useCreatePaymentMethodDetailsObjectMutation,
    useLazyGetPartnerPaymentMethodDetailsQuery,
    useLazyGetPaymentMethodDetailsObjectsQuery,
    useValidateIBANAndExtractBankDetailsMutation,
} from "~/domains/payment/payment-method-details/api/paymentMethodDetailsApi"
import { BankTransferPaymentMethod, PaymentMethodDetailsStatus } from "~/domains/payment/payment-method-details/types"
import { useLazyGetPaymentMethodsQuery } from "~/domains/payment/payment-methods/api/paymentMethodsApi"
import { PaymentMethodType } from "~/domains/payment/payment-methods/types"
import { DocumentData, ObjectType } from "~/domains/payment/types"

const sanitizeValue = (value: string): string => (value ? value.replace(/[\s-]/g, "") : "")

interface ManagePaymentMethodDetails {
    payerId: string
    payeeId: string
    documentData?: DocumentData
}

export interface InitializePaymentMethodDetailsResult {
    payeePaymentMethodDetailsId?: string
    payerPaymentMethodDetailsId?: string
    paymentMethodId?: string
}

export const useManagePaymentMethodDetails = ({ payerId, payeeId, documentData }: ManagePaymentMethodDetails) => {
    const [loadPaymentMethods, { isLoading: isLoadingPaymentMethods }] = useLazyGetPaymentMethodsQuery()
    const [loadPartnerPaymentMethodDetails, { isLoading: isLoadingPartnerPaymentMethodDetails }] =
        useLazyGetPartnerPaymentMethodDetailsQuery()
    const [createPaymentMethodDetails, { isLoading: isCreatingPaymentMethodDetails }] =
        useCreatePaymentMethodDetailsMutation()
    const [createPaymentMethodDetailsObject, { isLoading: isCreatingPaymentMethodDetailsObject }] =
        useCreatePaymentMethodDetailsObjectMutation()
    const [loadDefaultPaymentMethodDetailsObjects, { isLoading: isLoadingPaymentMethodDetailsObjects }] =
        useLazyGetPaymentMethodDetailsObjectsQuery()
    const [validateIban, { isLoading: isValidatingIban }] = useValidateIBANAndExtractBankDetailsMutation()
    const isLoading =
        isLoadingPaymentMethods ||
        isLoadingPartnerPaymentMethodDetails ||
        isCreatingPaymentMethodDetails ||
        isCreatingPaymentMethodDetailsObject ||
        isLoadingPaymentMethodDetailsObjects ||
        isValidatingIban

    // eslint-disable-next-line complexity
    const initializePaymentMethodDetails = useCallback(async (): Promise<InitializePaymentMethodDetailsResult> => {
        try {
            const { iban, accountNumber } = documentData ?? {}

            const [
                paymentMethodsData,
                paymentMethodDetailsData,
                partnerDefaultPaymentMethodDetailsData,
                defaultPaymentMethodDetailsData,
            ] = await Promise.all([
                loadPaymentMethods(null).unwrap(),
                loadPartnerPaymentMethodDetails({ ownerId: payeeId, partnerId: payerId }).unwrap(),
                loadDefaultPaymentMethodDetailsObjects({ objectId: payeeId, isDefault: true }).unwrap(),
                loadDefaultPaymentMethodDetailsObjects({ objectId: payerId, isDefault: true }).unwrap(),
            ])

            const paymentMethods = paymentMethodsData?.items ?? []
            const paymentMethodDetails = paymentMethodDetailsData?.items ?? []
            const partnerDefaultPaymentMethodDetailsId =
                partnerDefaultPaymentMethodDetailsData?.items?.[0]?.payment_method_details_id
            const defaultPaymentMethodDetailsId = defaultPaymentMethodDetailsData?.items?.[0]?.payment_method_details_id

            if (!iban && !accountNumber) {
                return {
                    payeePaymentMethodDetailsId: partnerDefaultPaymentMethodDetailsId,
                    payerPaymentMethodDetailsId: defaultPaymentMethodDetailsId,
                }
            }

            const bankTransferPaymentMethod = paymentMethods.find(
                (item) => item.payment_method_type === PaymentMethodType.BANK_TRANSFER
            )
            const bankTransferPaymentMethodId = bankTransferPaymentMethod?.id

            const existingPaymentMethodDetailsId = paymentMethodDetails.find((item) => {
                const bankTransferItem = item as BankTransferPaymentMethod
                return (
                    (bankTransferItem?.iban &&
                        sanitizeValue(bankTransferItem.iban) === sanitizeValue(iban as string)) ||
                    (bankTransferItem?.bank_account_number &&
                        sanitizeValue(bankTransferItem.bank_account_number) === sanitizeValue(accountNumber as string))
                )
            })?.id

            if (existingPaymentMethodDetailsId) {
                return {
                    payeePaymentMethodDetailsId: existingPaymentMethodDetailsId,
                    payerPaymentMethodDetailsId: defaultPaymentMethodDetailsId,
                    paymentMethodId: bankTransferPaymentMethodId,
                }
            }

            const { valid, bankDetails } = iban
                ? await validateIban(iban).unwrap()
                : await Promise.resolve({ valid: false, bankDetails: null })

            if (iban && !valid && !accountNumber) {
                return {
                    payeePaymentMethodDetailsId: partnerDefaultPaymentMethodDetailsId,
                    payerPaymentMethodDetailsId: defaultPaymentMethodDetailsId,
                    paymentMethodId: bankTransferPaymentMethodId,
                }
            }

            const newPaymentMethodDetails = await createPaymentMethodDetails({
                payment_method_id: bankTransferPaymentMethodId ?? "",
                owner: payeeId,
                payment_method_type: PaymentMethodType.BANK_TRANSFER,
                status: PaymentMethodDetailsStatus.PENDING,
                ...(iban && valid && { iban }),
                ...(valid && bankDetails?.sortCode && { sort_code: bankDetails.sortCode }),
                ...(valid && bankDetails?.bicSwift && { bic: bankDetails.bicSwift }),
                ...(valid && bankDetails?.bankName && { bank_name: bankDetails.bankName }),
                ...(valid && bankDetails?.countryCode && { country: bankDetails.countryCode }),
                ...(valid && bankDetails?.bankAccountNumber && { bank_account_number: bankDetails.bankAccountNumber }),
                ...(accountNumber && { bank_account_number: accountNumber }),
            }).unwrap()

            if (newPaymentMethodDetails?.id) {
                const newPaymentMethodDetailsObject = await createPaymentMethodDetailsObject({
                    payment_method_details_id: newPaymentMethodDetails.id,
                    object_id: payerId,
                    object_type: ObjectType.ORGANIZATION,
                }).unwrap()

                if (newPaymentMethodDetailsObject?.id) {
                    return {
                        payeePaymentMethodDetailsId: newPaymentMethodDetails.id,
                        payerPaymentMethodDetailsId: defaultPaymentMethodDetailsId,
                        paymentMethodId: bankTransferPaymentMethodId,
                    }
                }
            }

            return {
                payeePaymentMethodDetailsId: partnerDefaultPaymentMethodDetailsId,
                payerPaymentMethodDetailsId: defaultPaymentMethodDetailsId,
                paymentMethodId: bankTransferPaymentMethodId,
            }
        } catch (error) {
            return {}
        }
    }, [
        loadPaymentMethods,
        loadPartnerPaymentMethodDetails,
        loadDefaultPaymentMethodDetailsObjects,
        createPaymentMethodDetails,
        createPaymentMethodDetailsObject,
    ])

    return { initializePaymentMethodDetails, isLoading }
}
