import { useCallback, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { purchaseRequestApi } from "~/domains/transactions/purchase-requests/api/purchaseRequestsApi"
import { purchaseRequestsActions } from "~/domains/transactions/purchase-requests/store/purchaseRequestsSlice"
import { PurchaseRequestStatus } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { useAppDispatch } from "~/store/hooks"
import { OrganizationId } from "~/types"

const messages = defineMessages({
    successSubmitted: {
        id: "purchase.requests.request.submitted.success",
        defaultMessage: "Purchase request submitted",
    },
    successValidate: {
        id: "purchase.requests.request.validate.success",
        defaultMessage: "Purchase request validated",
    },
    successReject: {
        id: "purchase.requests.request.reject.success",
        defaultMessage: "Purchase request rejected",
    },
    error: {
        id: "purchase.requests.request.update.error",
        defaultMessage: "Something went wrong. Please contact your administrator",
    },
})

export const useUpdatePurchaseRequestStatus = (organizationId: OrganizationId, purchaseRequestId: string) => {
    const { formatMessage } = useIntl()
    const [statusLoading, setLoading] = useState(false)
    const [error, setError] = useState<string>()
    const dispatch = useAppDispatch()

    const updatePRStatus = useCallback(
        async (status: PurchaseRequestStatus, PRID: string | undefined = undefined) => {
            try {
                if (PRID) {
                    purchaseRequestId = PRID
                }
                setLoading(true)
                switch (status) {
                    case PurchaseRequestStatus.SUBMITTED:
                        await purchaseRequestApi.submit(organizationId, purchaseRequestId)
                        dispatch(
                            purchaseRequestsActions.updateData({
                                field: "status",
                                value: PurchaseRequestStatus.SUBMITTED,
                            })
                        )
                        toast.success(formatMessage(messages.successSubmitted))
                        break
                    case PurchaseRequestStatus.APPROVED:
                        await purchaseRequestApi.approve(organizationId, purchaseRequestId)
                        dispatch(
                            purchaseRequestsActions.updateData({
                                field: "status",
                                value: PurchaseRequestStatus.APPROVED,
                            })
                        )
                        toast.success(formatMessage(messages.successValidate))
                        break
                    case PurchaseRequestStatus.REJECTED:
                        await purchaseRequestApi.reject(organizationId, purchaseRequestId)
                        dispatch(
                            purchaseRequestsActions.updateData({
                                field: "status",
                                value: PurchaseRequestStatus.REJECTED,
                            })
                        )
                        toast.warn(formatMessage(messages.successReject))
                        break
                }
                setLoading(false)
            } catch (e) {
                setError(`${e}`)
                setLoading(false)
                toast.error(formatMessage(messages.error))
                throw e
            }
        },
        [organizationId, purchaseRequestId, dispatch]
    )

    return {
        updatePRStatus,
        error,
        statusLoading,
    }
}
