import { CircularProgress, Stack } from "@mui/material"
import { ArrowDropDownIcon } from "@mui/x-date-pickers"
import React, { ChangeEvent, useEffect, useState } from "react"
import { MessageDescriptor, defineMessages, useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import { Actions, ActionsMenu, Button, Loader, Modal, SafeFormattedMessage } from "~/components"
import {
    DisplayContext,
    customFieldsListByOrganizationId,
} from "~/domains/identity/custom-fields/components/CompanyCustomFields/CompanyCustomFieldsConfig"
import {
    useHasRequiredSecheCustomFields,
    usePendingCustomFieldsByFieldNames,
} from "~/domains/identity/custom-fields/hooks"
import { selectPendingCustomFieldObjects } from "~/domains/identity/custom-fields/store"
import { CompanyResult } from "~/domains/identity/organization/types/CompanyAutocomplete"
import { useGetPartnersQuery } from "~/domains/identity/partners/api/partnerApiV1"
import { PartnerOrganizationManualImport } from "~/domains/identity/partners/components/ModalCreatePartnership/PartnerOrganizationManualImport"
import { PartnerOrganizationSelector } from "~/domains/identity/partners/components/ModalCreatePartnership/PartnerOrganizationSelector"
import { PartnershipTypeSelector } from "~/domains/identity/partners/components/ModalCreatePartnership/PartnershipTypeSelector"
import {
    createEmptyPartner,
    createEmptyPartnershipPayload,
} from "~/domains/identity/partners/components/ModalCreatePartnership/helpers"
import { ModalContact } from "~/domains/identity/partners/components/PartnerProfile/components"
import { PartnerProfileSecheCustomFields } from "~/domains/identity/partners/components/PartnerProfile/components/PartnerProfileSecheCustomFields"
import { getCustomRules } from "~/domains/identity/partners/core/partnerCustomRulesByOrgId"
import { PARTNER_DETAILS_ROUTE } from "~/domains/identity/partners/routes"
import { useCreatePartnership } from "~/domains/identity/partners/store/hooks"
import { useCreateOrganizationWithoutRegistration } from "~/domains/identity/partners/store/hooks/useCreateOrganizationWithoutRegistration"
import {
    CreatePartnershipPayload,
    CreatePartnershipPayloadContact,
    PartnershipTypeOption,
} from "~/domains/identity/partners/types"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { CountryCode, ImportInvoiceCompanyInfoI, OrganizationId } from "~/types"

import { PartnershipStatusSwitches } from "./PartnershipStatusSwitches"
import { messages } from "./messages"

export const partnershipTypeMessages: Record<PartnershipTypeOption, MessageDescriptor> = defineMessages({
    [PartnershipTypeOption.BUYER]: {
        id: "partners.bookofrelations.createPartnershipType.buyer",
        defaultMessage: "client",
    },
    [PartnershipTypeOption.SUPPLIER]: {
        id: "partners.bookofrelations.createPartnershipType.supplier",
        defaultMessage: "vendor",
    },
    [PartnershipTypeOption.BOTH]: {
        id: "partners.bookofrelations.createPartnershipType.both",
        defaultMessage: "both Vendor & Client",
    },
})

const contactRequiredFields = ["lastName", "firstName", "email"]

interface CreatePartnershipModalProps {
    organizationId: OrganizationId
    open: boolean
    close: () => void
    displayContactModal?: () => void
}

const emptyFunction = () => {}

export const ModalCreatePartnership: React.FC<CreatePartnershipModalProps> = ({ organizationId, open, close }) => {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [companyResult, setCompanyResult] = useState<CompanyResult | undefined>()
    const [partnershipPayload, setPartnershipPayload] = useState<CreatePartnershipPayload>(
        createEmptyPartnershipPayload()
    )

    const [displayManualImport, setDisplayManualImport] = useState(false)
    const [displayContactCreation, setDisplayContactCreation] = useState(false)
    const [contact, setContact] = useState<CreatePartnershipPayloadContact | null>(null)
    const [contactInvalid, setContactInvalid] = useState(false)

    const currentOrganization = useAppSelector(selectCurrentOrganization)
    const currentOrganizationCountryCode = currentOrganization?.registration?.countryCode ?? CountryCode.FR
    const [partnerOrganization, setPartnerOrganization] = useState(createEmptyPartner(currentOrganizationCountryCode))

    const { createPartnershipWithContact } = useCreatePartnership(organizationId)
    const { data: partnersData, refetch: refetchPartners } = useGetPartnersQuery(
        { organizationId, withMetrics: true },
        { skip: !organizationId }
    )
    const { createNewOrganization } = useCreateOrganizationWithoutRegistration()

    const customRules = getCustomRules(organizationId)
    const customFieldsList = customFieldsListByOrganizationId[organizationId ?? ""]
    const pendingCustomFieldObject = useAppSelector(selectPendingCustomFieldObjects)
    const pendingCustomFields = usePendingCustomFieldsByFieldNames(customFieldsList, pendingCustomFieldObject)
    const hasRequiredCustomFields = useHasRequiredSecheCustomFields(pendingCustomFields)

    useEffect(() => {
        setPartnerOrganization(createEmptyPartner(currentOrganizationCountryCode))
    }, [currentOrganizationCountryCode])

    const updatePartnerOrganizationData = (data: Partial<ImportInvoiceCompanyInfoI>) => {
        setPartnerOrganization((currentState) =>
            data ? { ...currentState, ...data } : createEmptyPartner(currentState.countryCode)
        )
    }

    const updatePartnershipPayload = (e: ChangeEvent<HTMLInputElement>) => {
        const { value, name, type, checked } = e.target
        setPartnershipPayload((prevState) => ({
            ...prevState,
            [name]: type === "checkbox" ? checked : value,
        }))
    }

    const selectedOrganisationPartnership = partnersData?.find(
        (partner) => partner.organizationId === partnerOrganization.organizationId
    )

    const isSelectedOrganizationAlreadySupplier = !!selectedOrganisationPartnership?.supplier
    const isSelectedOrganizationAlreadyBuyer = !!selectedOrganisationPartnership?.buyer
    const isSupplierConflict =
        isSelectedOrganizationAlreadySupplier &&
        (partnershipPayload.partnershipType === PartnershipTypeOption.SUPPLIER ||
            partnershipPayload.partnershipType === PartnershipTypeOption.BOTH)

    const isBuyerConflict =
        isSelectedOrganizationAlreadyBuyer &&
        (partnershipPayload.partnershipType === PartnershipTypeOption.BUYER ||
            partnershipPayload.partnershipType === PartnershipTypeOption.BOTH)

    const validateContactFields = () => {
        const contactValid = contactRequiredFields.every((field) => contact?.[field as keyof typeof contact])
        setContactInvalid(!contactValid)
        return contactValid
    }

    const handleCreatePartnership = async (partnerOrgId: OrganizationId | undefined, skipContact = false) => {
        if (!partnerOrgId) {
            toast.error(formatMessage(messages.createOrganizationError))
            return
        }

        if (!currentOrganization) {
            toast.error(formatMessage(messages.createOrganizationError))
            return
        }

        try {
            await createPartnershipWithContact(
                {
                    partnership: partnershipPayload,
                    contact: skipContact ? undefined : (contact as CreatePartnershipPayloadContact),
                },
                currentOrganization.id,
                partnerOrgId
            )
            return partnerOrgId
        } catch (error) {
            toast.error(formatMessage(messages.createPartnershipError))
            return undefined
        }
    }

    const clearComponent = () => {
        setPartnerOrganization(createEmptyPartner(currentOrganizationCountryCode))
        setPartnershipPayload(createEmptyPartnershipPayload())
        setDisplayContactCreation(false)
        setCompanyResult(undefined)
        setContact(null)
        setContactInvalid(false)
    }

    const handleClose = () => {
        clearComponent()
        close()
    }

    const handleCreateOrganizationWithPartnership = async (createAnother = false) => {
        if (!validateContactFields()) {
            return
        }

        setLoading(true)

        try {
            const newOrganizationId =
                partnerOrganization.organizationId || (await createNewOrganization(partnerOrganization))?.id

            const createdPartnerId = await handleCreatePartnership(newOrganizationId)

            if (createdPartnerId) {
                toast.success(formatMessage(messages.createSuccess))
                refetchPartners()

                if (!createAnother) {
                    handleClose()
                    navigate(generatePath(PARTNER_DETAILS_ROUTE, { organizationId: createdPartnerId }))
                } else {
                    clearComponent()
                }
            }
        } catch (error) {
            toast.error(formatMessage(messages.createPartnershipError))
        } finally {
            setLoading(false)
        }
    }

    const handleCreatePartnerWithoutContact = async () => {
        setLoading(true)

        try {
            const newOrganizationId =
                partnerOrganization.organizationId || (await createNewOrganization(partnerOrganization))?.id

            const createdPartnerId = await handleCreatePartnership(newOrganizationId, true)

            if (createdPartnerId) {
                handleClose()
                toast.success(formatMessage(messages.createSuccess))
                refetchPartners()
                navigate(generatePath(PARTNER_DETAILS_ROUTE, { organizationId: createdPartnerId }))
            }
        } catch (error) {
            toast.error(formatMessage(messages.createPartnershipError))
        } finally {
            setLoading(false)
        }
    }

    const onManualImportClick = () => setDisplayManualImport(true)
    const cancelManualImport = () => setDisplayManualImport(false)
    const showContactCreation = () => setDisplayContactCreation(true)

    const handleBackOrClose = () => {
        if (displayContactCreation) {
            return setDisplayContactCreation(false)
        }
        handleClose()
    }

    const onContactChange = (newContact: CreatePartnershipPayloadContact) => {
        setContact(newContact)
    }

    const handleCreateAnother = () => {
        handleCreateOrganizationWithPartnership(true)
    }

    const saveDisabled =
        !partnerOrganization.registrationNumber ||
        (customRules.hasCustomFields && !hasRequiredCustomFields) ||
        isSupplierConflict ||
        isBuyerConflict

    const getFooterActions = () => {
        const actions: Actions<() => void>[] = [
            {
                label: formatMessage(messages.modalCreateAnother),
                action: handleCreateAnother,
            },
        ]

        if (!customRules.isContactRequired) {
            actions.push({
                label: formatMessage(messages.skipContactCreation),
                action: handleCreatePartnerWithoutContact,
            })
        }

        return actions
    }

    const renderFooter = () => {
        return (
            <Modal.Footer>
                <>
                    <Button onClick={handleBackOrClose} type="transparent">
                        <SafeFormattedMessage
                            {...(displayContactCreation ? messages.modalBack : messages.modalCancel)}
                        />
                    </Button>
                    {displayContactCreation ? (
                        <>
                            <ActionsMenu
                                labelButton={formatMessage(messages.modalConfirm)}
                                actions={getFooterActions()}
                                splitButton
                                primaryAction={() => handleCreateOrganizationWithPartnership(false)}
                                buttonType="primary"
                                size="small"
                                icon={<ArrowDropDownIcon />}
                                isActionIcon={false}
                                disabled={loading}
                            />
                        </>
                    ) : (
                        <Button onClick={showContactCreation} disabled={saveDisabled} type="primary">
                            {loading ? (
                                <CircularProgress size={20} color="inherit" />
                            ) : (
                                <SafeFormattedMessage {...messages.modalNext} />
                            )}
                        </Button>
                    )}
                </>
            </Modal.Footer>
        )
    }

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-create-partnership">
            <Modal.Header>
                <h4>
                    {displayContactCreation ? (
                        <SafeFormattedMessage
                            {...messages.modalTitleContact}
                            values={{ organizationName: partnerOrganization.name }}
                        />
                    ) : (
                        <SafeFormattedMessage {...messages.modalTitle} />
                    )}
                </h4>
            </Modal.Header>

            {displayManualImport ? (
                <PartnerOrganizationManualImport
                    updateData={updatePartnerOrganizationData}
                    cancelManualImport={cancelManualImport}
                    companyResult={companyResult}
                    setCompanyResult={setCompanyResult}
                />
            ) : (
                <>
                    {loading && !displayContactCreation ? (
                        <div className="p-24 flex justify-center">
                            <Loader />
                        </div>
                    ) : (
                        <>
                            {displayContactCreation ? (
                                <ModalContact
                                    save={emptyFunction} // These are not used in non-modal mode
                                    open={true}
                                    close={emptyFunction} // These are not used in non-modal mode
                                    requiredFields={contactRequiredFields}
                                    label={formatMessage(messages.contactLabel)}
                                    modalMode={false}
                                    onChange={onContactChange}
                                    formInvalid={contactInvalid}
                                />
                            ) : (
                                <Modal.Content>
                                    <Stack gap={2}>
                                        {customRules.hasOnlySupplierCreation ? null : (
                                            <PartnershipTypeSelector
                                                value={partnershipPayload?.partnershipType}
                                                onChange={updatePartnershipPayload}
                                            />
                                        )}
                                        <PartnerOrganizationSelector
                                            partnerOrganization={partnerOrganization}
                                            onManualImportClick={onManualImportClick}
                                            updateData={updatePartnerOrganizationData}
                                            companyResult={companyResult}
                                            setCompanyResult={setCompanyResult}
                                            isSelectedOrganizationAlreadySupplier={
                                                isSelectedOrganizationAlreadySupplier
                                            }
                                            isSelectedOrganizationAlreadyBuyer={isSelectedOrganizationAlreadyBuyer}
                                        />
                                        {customRules.hasCustomFields && (
                                            <>
                                                <hr />
                                                <PartnerProfileSecheCustomFields
                                                    partnershipId={partnerOrganization?.organizationId ?? ""}
                                                    currentOrganizationId={organizationId}
                                                    readOnly={false}
                                                    customFieldsList={customFieldsList}
                                                    displayContext={DisplayContext.PARTNER_MODAL}
                                                    organizationCountryCode={currentOrganizationCountryCode}
                                                />
                                            </>
                                        )}
                                        {customRules.hasPartnershipAttributes && (
                                            <PartnershipStatusSwitches
                                                partnershipPayload={partnershipPayload}
                                                updatePartnershipPayload={updatePartnershipPayload}
                                            />
                                        )}
                                    </Stack>
                                </Modal.Content>
                            )}
                        </>
                    )}
                    {renderFooter()}
                </>
            )}
        </Modal>
    )
}
