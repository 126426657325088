import { Container } from "@mui/material"
import { ReactNode, Suspense, memo, useCallback, useEffect, useState } from "react"
import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import { useIntercom } from "react-use-intercom"

import { ModalFinalizeAccount, NewInvitationsToast, Sidebar } from "~/components/"
import { AknowledgePurchasePolicyWall } from "~/domains/identity/account/components/AknowledgePurchasePolicyWall/AknowledgePurchasePolicyWall"
import { useGetPartnersQuery } from "~/domains/identity/partners/api/partnerApiV1"
import { OcrSocketIOContextProvider } from "~/domains/transactions/invoices/_views/ocr/components/OcrSocketIOContext"
import { useSegment } from "~/hooks/useSegment"
import { DASHBOARD_ROUTE } from "~/routes/routes"
import { selectDisplayModalFinalizeAccount, selectUser } from "~/store/account/accountSlice"
import { selectIsConnected } from "~/store/auth/authSlice"
import { globalActions, selectDisplayMenu, selectKeepSidebarOpened } from "~/store/global/globalSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { UserI } from "~/types"
import MarkerComponent from "~/utils/MarkerIO/MarkerIO"

interface LayoutProps {
    modulesRoutes: ReactNode[]
    modulesNavItems: []
}

const NewInvitationsToastMemo = memo(NewInvitationsToast)

const isDevelopment = import.meta.env.VITE_ENV === "development"

function Layout({ modulesRoutes }: LayoutProps) {
    const displayModalFinalizeAccount = useAppSelector(selectDisplayModalFinalizeAccount)
    const sideBarOpened = useAppSelector(selectKeepSidebarOpened)
    const [prevPath, setPrevPath] = useState<null | string>(null)
    const { update } = useIntercom()
    const { segmentIdentify, segmentPage } = useSegment()
    const isConnected = useAppSelector(selectIsConnected)
    const user = useAppSelector(selectUser)
    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const displayMenu = useAppSelector(selectDisplayMenu)
    const dispatch = useAppDispatch()
    const location = useLocation()
    const pathname = location.pathname
    const [page, setPage] = useState<string>(pathname)

    useEffect(() => {
        const newPage = pathname.split("/")[2]
        if (newPage !== page) {
            setPage(newPage)
        }
    }, [pathname, page])

    useEffect(() => {
        localStorage?.removeItem("invoice.store.current")
    }, [])

    useEffect(() => {
        const initTrackingUser = (id: string, localUser: UserI) => {
            update({
                name: localUser.fullName,
                userId: id,
                email: localUser.email,
                company: {
                    companyId: id, // TODO: change when companies will be detached from user entity
                    name: "",
                    //name: user.companyDetails.name ?? "",
                },
            })
            segmentIdentify(id, localUser)
        }
        if (isConnected && user.id && !isDevelopment) {
            initTrackingUser(user.id, user)
        }
    }, [isConnected, user.id, update, segmentIdentify])

    const updateIntercomLocation = useCallback(() => {
        update()
    }, [update])

    useEffect(() => {
        if (!isDevelopment) {
            updateIntercomLocation()
            if (prevPath !== location.pathname) {
                segmentPage(location, prevPath)
                setPrevPath(location.pathname)
            }
        }
    }, [location, prevPath, segmentPage, updateIntercomLocation])

    useGetPartnersQuery({ organizationId: currentOrganizationId || "" }, { skip: !currentOrganizationId })

    const handleHideMenu = () => {
        dispatch(globalActions.setDisplayMenu())
    }

    return (
        <OcrSocketIOContextProvider>
            <div className={isConnected ? "logged-layout" : ""}>
                <Sidebar />
                <div className={`overlay-menu ${displayMenu ? "" : "hidden"}`} onClick={handleHideMenu} />
                <main className={`${sideBarOpened ? "sidebar-opened" : ""} page-${page}`}>
                    <>
                        <Container maxWidth={false}>
                            <Suspense fallback={<></>}>
                                <Routes>
                                    <Route
                                        element={
                                            <AknowledgePurchasePolicyWall
                                                isConnected={isConnected}
                                                user={user}
                                                organizationId={currentOrganizationId}
                                            />
                                        }
                                    >
                                        {modulesRoutes}
                                        <Route path="*" element={<Navigate to={DASHBOARD_ROUTE} replace />} />
                                    </Route>
                                </Routes>
                            </Suspense>
                        </Container>
                        {!displayModalFinalizeAccount ? null : (
                            <ModalFinalizeAccount displayModal={displayModalFinalizeAccount} />
                        )}
                        <NewInvitationsToastMemo currentUser={user} />
                    </>
                </main>
                <MarkerComponent user={user} organizationId={currentOrganizationId} />

            </div>
        </OcrSocketIOContextProvider>
    )
}

export default Layout
