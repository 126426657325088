import { CheckCircle } from "react-feather"

import { CheckNodeConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { CheckNode } from "~/domains/orchestration/flows/components/nodes"
import { adaptEntityTriggerProperties, adaptEventTriggerProperties } from "~/domains/orchestration/flows/core"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import { Trigger, TriggerMode } from "~/domains/orchestration/flows/types/Editor"
import { CheckNode as CheckNodeType, EditorNode, Node, NodeType } from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const checkNode = (trigger: Trigger): Omit<CheckNodeType, keyof Node> => {
    const { objectType, objectId } =
        trigger.mode === TriggerMode.EVENT
            ? adaptEventTriggerProperties(trigger.type, trigger.slug)
            : adaptEntityTriggerProperties(trigger.type, trigger.slug)
    return {
        objectId,
        objectType,
        reviewers: [],
        expressionReviewers: [],
        passThreshold: 1,
        refuseThreshold: 1,
        nextIfSuccess: null,
        nextIfFailure: null,
        type: NodeType.CHECK_NODE,
    }
}

const advancedFields: AllAdvancedFieldsUnion[] = [AllAdvancedFieldsUnion.OBJECT_ID, AllAdvancedFieldsUnion.OBJECT_TYPE]

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.CHECK_NODE) return false

    const hasReviewers = node.reviewers?.length || node.expressionReviewers?.length

    return Boolean(node.passThreshold && node.refuseThreshold && node.objectId && hasReviewers)
}

const checkConfig: Configuration<NodeType.CHECK_NODE> = {
    type: NodeType.CHECK_NODE,
    baseSlug: "check",
    Icon: CheckCircle,
    Configuration: CheckNodeConfiguration,
    advancedFields,
    Node: CheckNode,
    validateNode,
    factory: (props: FactoryProps): CheckNodeType => ({
        ...checkNode(props.trigger),
        ...createCommonNodeProperties(props),
    }),
}

export { checkConfig, advancedFields as checkAdvancedFields }
