import React, { useState } from "react"
import { Check } from "react-feather"
import { defineMessages } from "react-intl"

import { Button, SafeFormattedMessage, TooltipConditional } from "~/components"
import { permissionMessages } from "~/domains/identity/roles-permissions/utils/permissions"
import { ApprovalOverrideModal } from "~/domains/orchestration/flows-v0/components/ApprovalBlock/ApprovalOverrideModal"

const messages = defineMessages({
    validate: {
        id: "purchase.requests.request.validate",
        defaultMessage: "Validate",
    },
})

interface ApprovePRProps {
    canApprove: boolean
    currentUserApproval?: boolean
    isApprovalDone: boolean
    onApprove: () => void
}

export const ApprovePR: React.FC<ApprovePRProps> = ({ canApprove, currentUserApproval, isApprovalDone, onApprove }) => {
    const [showModal, setShowModal] = useState(false)
    const permissionLabel = <SafeFormattedMessage {...permissionMessages.errorNoAccessAdministrator} />

    const handleClose = () => {
        setShowModal(false)
    }

    const handleConfirm = () => {
        onApprove()
        handleClose()
    }

    const handleApprove = () => {
        if (isApprovalDone) {
            onApprove()
        } else {
            setShowModal(true)
        }
    }

    return (
        <>
            <TooltipConditional arrow title={permissionLabel} condition={!canApprove}>
                <div>
                    <Button
                        type="primary-light"
                        onClick={handleApprove}
                        disabled={!canApprove || currentUserApproval === true}
                        disableDelay={0}
                    >
                        <SafeFormattedMessage {...messages.validate} />
                        <Check size={16} />
                    </Button>
                </div>
            </TooltipConditional>
            <ApprovalOverrideModal open={showModal} onClose={handleClose} onConfirm={handleConfirm} />
        </>
    )
}
