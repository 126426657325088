import { defineMessages } from "react-intl"

import { commonMessages } from "~/common-messages"
import { SafeFormattedMessage } from "~/components"
import { CreatePaymentBatch } from "~/domains/payment/payment-batches/types"
import { ValidationSchema } from "~/domains/payment/types"

const messages = defineMessages({
    required: {
        id: "common.required",
        defaultMessage: "{field} is required",
    },
    invalid: {
        id: "common.invalid",
        defaultMessage: "{field} is invalid",
    },
})

export const batchFormValidationSchema: ValidationSchema<CreatePaymentBatch> = {
    name: {
        required: {
            value: true,
            message: (
                <SafeFormattedMessage
                    {...messages.required}
                    values={{ field: <SafeFormattedMessage {...commonMessages.name} /> }}
                />
            ),
        },
    },
    recurrenceType: {
        required: {
            value: true,
            message: (
                <SafeFormattedMessage
                    {...messages.required}
                    values={{ field: <SafeFormattedMessage {...commonMessages.recurrenceType} /> }}
                />
            ),
        },
    },
    startDate: {
        required: {
            value: true,
            message: (
                <SafeFormattedMessage
                    {...messages.required}
                    values={{ field: <SafeFormattedMessage {...commonMessages.startDate} /> }}
                />
            ),
        },
        date: {
            value: true,
            message: (
                <SafeFormattedMessage
                    {...messages.invalid}
                    values={{ field: <SafeFormattedMessage {...commonMessages.startDate} /> }}
                />
            ),
        },
    },
}
