import { Box, Grid, Stack } from "@mui/material"
import { useEffect } from "react"
import { Navigate, generatePath, useNavigate, useParams } from "react-router-dom"

import { CenterViewport, HeaderH1, Loader } from "~/components"
import { ResizableCol } from "~/components/Layout/ResizableCol"
import { ResizableGrid } from "~/components/Layout/ResizableGrid"
import { PREFIX_INVOICE } from "~/constants"
import {
    InvoiceActions,
    InvoiceApproval,
    InvoiceBudgets,
    InvoiceCommonFields,
    InvoiceCommunication,
    InvoiceCopilot,
    InvoiceCustomFields,
    InvoiceDescription,
    InvoiceDocuments,
    InvoiceDuplicateWarning,
    InvoiceItems,
    InvoiceLifeCycle,
    InvoiceOrganizations,
    InvoicePeopleInvolved,
    InvoicePreview,
    InvoicePurchaseOrders,
    InvoiceTags,
} from "~/domains/transactions/invoices-v1/components"
import { InvoiceNavigation } from "~/domains/transactions/invoices-v1/components/InvoiceNavigation"
import { InvoicePayment } from "~/domains/transactions/invoices-v1/components/InvoicePayment"
import { InvoiceTotalAmount } from "~/domains/transactions/invoices-v1/components/InvoiceTotalAmount"
import { INVOICES_V1_ROUTE } from "~/domains/transactions/invoices-v1/routes"
import { INVOICE_BUYER_ROUTE } from "~/domains/transactions/invoices/_views/buyer/routes"
import { useTitle } from "~/hooks"
import { useAppDispatch } from "~/store/hooks"
import { useFetchInvoice } from "~/store/invoice/hooks"
import { invoiceActions } from "~/store/invoice/invoiceSlice"
import { InvoiceBackendVersion, ViewTypeI } from "~/types"
import { FetchErrorType } from "~/utils/apiClient"

const STATE_KEY = "transaction"

export const Invoice = () => {
    const { invoiceId } = useParams<{ invoiceId: string }>()

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        return () => {
            dispatch(invoiceActions.reset())
        }
    }, [dispatch])

    const { invoice, buyerOrganization, fetchError } = useFetchInvoice(invoiceId, {
        viewType: ViewTypeI.buyer,
        shouldReload: 0,
        forceRefetch: false,
    })

    useEffect(() => {
        if (fetchError && fetchError.type === FetchErrorType.HTTP_REQUEST_ERROR) {
            navigate(generatePath(INVOICES_V1_ROUTE), { replace: true })
        }
    }, [fetchError])

    useTitle(`${PREFIX_INVOICE} ${invoice?.number}`)

    if (!invoice) {
        return (
            <CenterViewport>
                <Loader />
            </CenterViewport>
        )
    }

    if (invoice.version !== InvoiceBackendVersion.V1) {
        return <Navigate to={generatePath(INVOICE_BUYER_ROUTE, { invoiceId: invoice.id })} />
    }

    return (
        <div className="purchase-page">
            <Grid container>
                <HeaderH1 title={`${PREFIX_INVOICE} ${invoice.number}`} backLink={INVOICES_V1_ROUTE}>
                    <InvoiceActions invoice={invoice} />
                </HeaderH1>
                <InvoiceNavigation invoice={invoice} />
                <InvoiceDuplicateWarning invoice={invoice} />
            </Grid>

            <Box className="main-box">
                <Grid container spacing={0} gap={2}>
                    <InvoiceLifeCycle />

                    <ResizableGrid stateKey={STATE_KEY}>
                        <ResizableCol minSize={200} maxSize={400} defaultSize={250}>
                            <Stack gap={1} className="column">
                                <InvoiceTotalAmount invoice={invoice} />
                                <InvoiceDescription invoice={invoice} />
                                <InvoiceCommonFields invoice={invoice} />
                                <InvoicePeopleInvolved invoice={invoice} />
                                <InvoiceCustomFields invoice={invoice} />
                            </Stack>
                        </ResizableCol>

                        <ResizableCol minSize={600}>
                            <InvoiceOrganizations invoice={invoice} />
                            <InvoiceCopilot invoice={invoice} />
                            <InvoiceItems invoice={invoice} />
                            <InvoicePreview invoice={invoice} />
                        </ResizableCol>

                        <ResizableCol minSize={200} maxSize={400} defaultSize={250}>
                            <Stack gap={1} className="column">
                                <InvoiceApproval invoice={invoice} />
                                <InvoiceTags invoice={invoice} />
                                <InvoicePurchaseOrders invoice={invoice} />
                                <InvoiceBudgets invoice={invoice} buyerOrganization={buyerOrganization} />
                                <InvoicePayment invoice={invoice} />
                                <InvoiceDocuments invoiceId={invoice.id} />
                                <InvoiceCommunication invoice={invoice} />
                            </Stack>
                        </ResizableCol>
                    </ResizableGrid>
                </Grid>
            </Box>
        </div>
    )
}
