import { IconButton, InputAdornment, TextField, TextFieldProps, styled } from "@mui/material"
import { RiFormula, RiText } from "@remixicon/react"
import cls from "classnames"
import React, { FC, useState } from "react"

import { adaptExpressionToText, adaptTextToExpression } from "~/domains/orchestration/flows/core"
import { isExpression } from "~/domains/orchestration/flows/utils"

const ExpressionField = styled(TextField)({
    borderRadius: "var(--border-radius-md)",

    "& .MuiInputBase-root": {
        backgroundColor: "var(--color-grey-lighter)",
        border: "1px solid var(--color-grey-light)",
        borderTopLeftRadius: "0",
    },
    "& .MuiInputBase-input": {
        color: "var(--color-red)",
        fontFamily: "var(--font-family-code)",
        letterSpacing: "-0.05em",
    },
    "& .MuiInputAdornment-root": {
        color: "var(--color-red)",
    },
    "& .MuiFormLabel-root": {
        backgroundColor: "var(--color-grey-lighter)",
        fontSize: "var(--font-size-sm)",
        color: "var(--color-grey-dark)",
    },
    "&:hover .MuiFormLabel-root": {
        border: "transparent",
    },
    "& .MuiFormLabel-root.Mui-focused": {
        border: "transparent",
    },
    "& .Mui-focused": {
        border: "none",
    },
    "& .MuiInputLabel-shrink": {
        padding: "3px var(--spacing-sm) 0px",
        borderRadius: "var(--border-radius-xs) var(--border-radius-xs) 0 0",
        border: "1px solid var(--color-grey-light)",
        marginTop: "-8px",
        marginLeft: "-14px",
        borderBottom: "none",
        fontSize: "var(--font-size-md)",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        display: "none",
    },
})

const ValueField = styled(TextField)({
    "& .MuiIconButton-root": {
        color: "var(--color-red)",
    },
    "& .MuiInputBase-input.expression": {
        color: "var(--color-red)",
        fontFamily: "var(--font-family-code)",
        letterSpacing: "-0.05em",
        backgroundColor: "var(--color-grey-lighter)",
    },
})

interface EnhancedTextFieldProps extends Omit<TextFieldProps, "onChange"> {
    expressionOnly?: boolean
    onChange: (value: unknown) => void
    value: unknown
    label?: string
}

export const EnhancedTextField: FC<EnhancedTextFieldProps> = ({
    expressionOnly = false,
    onChange,
    value,
    label = "",
    className,
    ...props
}) => {
    const [isExpressionMode, setIsExpressionMode] = useState(expressionOnly || false)
    const valueIsExpression = isExpression(value)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value
        if (isExpressionMode) {
            onChange(newValue)
        } else {
            // Only adapt to expression format if it's not already an expression
            if (!isExpression(newValue)) {
                onChange(adaptTextToExpression(newValue))
            } else {
                onChange(newValue)
            }
        }
    }

    const toggleMode = () => {
        if (!expressionOnly) {
            setIsExpressionMode(!isExpressionMode)
        }
    }

    const displayValue = isExpressionMode ? value : adaptExpressionToText(`${value}`)

    if (isExpressionMode || expressionOnly) {
        return (
            <ExpressionField
                {...props}
                type="text"
                multiline={true}
                label={label}
                value={displayValue}
                onChange={handleChange}
                className={cls("enhanced-textfield", className)}
                InputProps={{
                    ...props.InputProps,
                    endAdornment: !expressionOnly && (
                        <InputAdornment position="end">
                            <IconButton onClick={toggleMode} size="small" aria-label="toggle expression mode">
                                <RiText size={14} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        )
    }

    return (
        <ValueField
            {...props}
            type={valueIsExpression ? "text" : props.type}
            className={cls("enhanced-textfield", className)}
            value={displayValue}
            label={label}
            onChange={handleChange}
            disabled={valueIsExpression}
            InputProps={{
                ...props.InputProps,
                className: cls(props.InputProps?.className, { expression: valueIsExpression }),
                endAdornment: !expressionOnly && (
                    <InputAdornment position="end">
                        <IconButton onClick={toggleMode} size="small" aria-label="toggle expression mode">
                            <RiFormula size={14} />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    )
}
