import { Grid, Stack, Tooltip } from "@mui/material"
import * as Sentry from "@sentry/browser"
import React, { Dispatch, SetStateAction, useCallback, useMemo, useRef, useState } from "react"
import { Save, Send } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import { commonMessages } from "~/common-messages"
import { Button, ButtonWithMandatoryTagGroups, ConfirmModal, SafeFormattedMessage } from "~/components"
import { DraftDocumentI, ObjectId } from "~/components/UploadDocument/Documents"
import { useUploadDocument } from "~/components/UploadDocument/hooks"
import { TagObjectType } from "~/domains/analytics/tags/types"
import { DocumentObjectType } from "~/domains/identity/documents/types"
import { useAddTransactionToBudgetMutation } from "~/domains/transactions/budget/api/budgetApi_"
import { BudgetDataWithMetricsI } from "~/domains/transactions/budget/types"
import { convertPOtoCreatePODTO } from "~/domains/transactions/purchase-orders/core/purchaseOrder"
import { PURCHASE_ORDER_ROUTE } from "~/domains/transactions/purchase-orders/routes"
import { useCreatePurchaseOrder, useUpdatePOStatus } from "~/domains/transactions/purchase-orders/store/hooks"
import {
    purchaseOrdersActions,
    selectPurchaseOrder,
} from "~/domains/transactions/purchase-orders/store/purchaseOrdersSlice"
import { PurchaseOrderLine } from "~/domains/transactions/purchase-orders/types"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { CurrencyCodes, OrganizationId } from "~/types"

const messages = defineMessages({
    save: {
        id: "purchase.orders.order.header.actions.save",
        defaultMessage: "Save",
    },
    send: {
        id: "purchase.orders.order.header.actions.send",
        defaultMessage: "Submit",
    },
    errorDescription: {
        id: "purchase.orders.order.header.actions.errorDescription",
        defaultMessage: "The description is mandatory",
    },
    errorItems: {
        id: "purchase.orders.order.header.actions.errorLines",
        defaultMessage: "You must add at least one item",
    },
    errorVendor: {
        id: "purchase.orders.order.header.actions.errorVendor",
        defaultMessage: "You must specified a vendor",
    },
    errorCreatingPR: {
        id: "purchase.orders.order.header.actions.errorCreatingPO",
        defaultMessage: "An error occurred while creating this purchase order.",
    },
    modalConfirmTitle: {
        id: "purchase.orders.order.header.modalConfirmTitle",
        defaultMessage: "Warning",
    },
    amountEqual0: {
        id: "purchase.orders.order.header.amountEqualZero",
        defaultMessage: "The amount of the PO is 0. Do you want to continue?",
    },
    errorAssigningToBudget: {
        id: "purchase.orders.order.header.errorAssigningToBudget",
        defaultMessage: "An error occurred while assigning to budget.",
    },
})

type AmountWithCurrency = {
    amount: number
    currency: CurrencyCodes
}
type LinesTotals = {
    amount: AmountWithCurrency
    amountExcludingTax: AmountWithCurrency
    tax: AmountWithCurrency
}

const newLinesTotals = (): LinesTotals => ({
    amount: {
        amount: 0,
        currency: CurrencyCodes.EUR,
    },
    amountExcludingTax: {
        amount: 0,
        currency: CurrencyCodes.EUR,
    },
    tax: {
        amount: 0,
        currency: CurrencyCodes.EUR,
    },
})
const computeLinesTotal = (lines: PurchaseOrderLine[]): LinesTotals => {
    return lines.reduce((acc, line) => {
        return {
            amount: {
                amount: acc.amount.amount + line.totalAmount,
                currency: CurrencyCodes.EUR,
            },
            amountExcludingTax: {
                amount: acc.amountExcludingTax.amount + line.totalAmountExcludingTax,
                currency: CurrencyCodes.EUR,
            },
            tax: {
                amount: acc.tax.amount + line.totalTax,
                currency: CurrencyCodes.EUR,
            },
        }
    }, newLinesTotals())
}

interface PropsPOCreateActions {
    organizationId: OrganizationId
    draftDocuments?: DraftDocumentI[]
    draftBudgets?: BudgetDataWithMetricsI[]
    setDraftDocuments?: Dispatch<SetStateAction<DraftDocumentI[]>>
    setDraftBudgets?: Dispatch<SetStateAction<BudgetDataWithMetricsI[]>>
}

export function ActionsHeaderCreate({
    organizationId,
    draftDocuments,
    draftBudgets,
    setDraftDocuments,
    setDraftBudgets,
}: PropsPOCreateActions) {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()

    const [showConfirmDialog, setShowConfirmDialog] = useState(false)
    const shouldSubmitRef = useRef(false)
    const PO = useAppSelector(selectPurchaseOrder)

    const { createPO } = useCreatePurchaseOrder(organizationId)
    const { uploadDocument } = useUploadDocument(organizationId)
    const { updateStatus } = useUpdatePOStatus(PO.id)
    const [addBudgetToTransaction] = useAddTransactionToBudgetMutation()

    const linesTotals = useMemo(() => computeLinesTotal(PO.lines), [PO.lines])

    const handleSave = (e: React.MouseEvent) => {
        e.preventDefault()
        checkAmount()
    }

    const handleSubmit = (e: React.MouseEvent) => {
        e.preventDefault()
        checkAmount(true)
    }

    const checkAmount = useCallback(
        (submit = false) => {
            if (PO.description === "") {
                return toast.warning(formatMessage(messages.errorDescription))
            }
            if (!PO.lines.length) {
                return toast.warning(formatMessage(messages.errorItems))
            }
            if (PO.supplierId === "") {
                return toast.warning(formatMessage(messages.errorVendor))
            }
            if (!linesTotals.amountExcludingTax.amount && !PO.totalAmount) {
                setShowConfirmDialog(true)
                shouldSubmitRef.current = submit
                return false
            }
            handleCreate(submit)
        },
        [PO, linesTotals, formatMessage]
    )

    const handleConfirmDialog = async () => {
        await handleCreate(shouldSubmitRef.current)
        return true
    }

    async function handleDraftDocuments(draftDocs: DraftDocumentI[], id: ObjectId) {
        if (!draftDocs.length) return
        const uploadPromises = draftDocs.map((doc) => {
            if (doc.file) {
                return uploadDocument({
                    file: doc.file,
                    name: doc.name,
                    documentType: doc.documentType,
                    objectType: DocumentObjectType.PURCHASE_ORDER,
                    objectId: id,
                    organizationIds: [organizationId],
                })
            } else {
                return Promise.resolve(null)
            }
        })

        await Promise.allSettled(uploadPromises)
    }

    const handleDraftBudgets = async (bd: BudgetDataWithMetricsI[], id: string) => {
        if (!bd.length) return

        const savePromises = bd.map((budget) => {
            return addBudgetToTransaction({
                organizationId,
                budgetId: budget.budget.id,
                payload: {
                    ...budget.transaction,
                    transactionRefId: id,
                },
            })
        })

        const results = await Promise.allSettled(savePromises)

        const failedResults = results.filter((result) => result.status === "rejected")
        if (failedResults.length) {
            Sentry.captureException(messages.errorAssigningToBudget, {
                extra: {
                    purchaseOrderId: id,
                    failedResults,
                },
            })
        }
    }

    const handleCreate = async (submit = false) => {
        const data = convertPOtoCreatePODTO(PO)

        setLoading(true)
        try {
            const result = await createPO(data, false)

            if (draftDocuments && setDraftDocuments) {
                await handleDraftDocuments(draftDocuments, result.id)
                setDraftDocuments([])
            }

            if (draftBudgets && setDraftBudgets) {
                await handleDraftBudgets(draftBudgets, result.id)
                setDraftBudgets([])
            }

            dispatch(purchaseOrdersActions.resetData())

            if (submit) {
                await updateStatus(organizationId, "SUBMITTED", result.id)
            }

            navigate(generatePath(PURCHASE_ORDER_ROUTE, { purchaseOrderId: result.id }))
        } catch (error) {
            toast(formatMessage(messages.errorCreatingPR), { type: "error" })
            Sentry.captureException(error, {
                extra: {
                    organizationId,
                    purchaseorderData: data,
                },
            })
        } finally {
            setLoading(false)
        }
    }

    return (
        <Grid item className="actions">
            <Stack direction="row" gap={1}>
                <Tooltip arrow title={formatMessage(messages.save)}>
                    <div>
                        <Button type="grey" onClick={handleSave} disabled={loading}>
                            <Save size={18} />
                            <SafeFormattedMessage {...commonMessages.save} />
                        </Button>
                    </div>
                </Tooltip>

                <ButtonWithMandatoryTagGroups
                    organizationId={organizationId}
                    tagObjectType={TagObjectType.PURCHASE_ORDER}
                >
                    <Button type="primary-light" onClick={handleSubmit} disabled={loading}>
                        <Send size={18} />
                        <SafeFormattedMessage {...commonMessages.submit} />
                    </Button>
                </ButtonWithMandatoryTagGroups>
            </Stack>
            <ConfirmModal
                open={showConfirmDialog}
                close={() => setShowConfirmDialog(false)}
                onConfirm={handleConfirmDialog}
                title={<SafeFormattedMessage {...messages.amountEqual0} />}
            />
        </Grid>
    )
}
