import { Stack, Switch } from "@mui/material"
import cls from "classnames"
import { FC, Fragment, useEffect } from "react"
import { Plus, Trash2 } from "react-feather"

import { Button, SafeFormattedMessage } from "~/components"
import { useConditionsManager } from "~/domains/orchestration/flows/hooks/useConditionsManager"
import { messages } from "~/domains/orchestration/flows/locale"
import { Conditions } from "~/domains/orchestration/flows/types"

import { Condition } from "./Condition"
import { ConditionBuilder } from "./ConditionBuilder"

interface SharedTriggerConditionProps {
    enableConditions: boolean
    onEnableConditionsChange: () => void
    initialConditions: Conditions[]
    onChangeCondition: (conditions: Conditions[]) => void
}

export const SharedTriggerCondition: FC<SharedTriggerConditionProps> = ({
    enableConditions,
    onEnableConditionsChange,
    initialConditions,
    onChangeCondition,
}) => {
    const {
        conditions: filter,
        showConditionBuilder,
        currentCondition,
        handleChangeCondition,
        handleDelete,
        handleNewConditionItem,
        handleSaveConditionItem,
        handleAddConditionGroup,
        handleEditCondition,
        handleCloseConditionBuilder,
        handleResetConditionBuilder,
    } = useConditionsManager({
        initialConditions: initialConditions,
        onConditionsChange: onChangeCondition,
    })
    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    useEffect(() => {
        if (!enableConditions) {
            handleResetConditionBuilder()
        }
    }, [enableConditions])

    if (showConditionBuilder && currentCondition) {
        return (
            <ConditionBuilder
                condition={currentCondition}
                handleSave={handleSaveConditionItem}
                handleCancel={handleCloseConditionBuilder}
            />
        )
    }

    return (
        <>
            <Stack gap={1} className={configurationNodeItemClassName}>
                <SafeFormattedMessage tagName="span" {...messages.eventTriggerConfiguration.enableConditionsLabel} />
                <Switch checked={enableConditions} onChange={onEnableConditionsChange} />
            </Stack>
            {enableConditions && (
                <>
                    <Stack gap={1} className={configurationNodeItemClassName}>
                        <SafeFormattedMessage tagName="h5" {...messages.eventTriggerConfiguration.conditionsTitle} />

                        {filter.map((g, index) => (
                            <Fragment key={g.id}>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    className="flows-eventTriggerConfiguration-orTitle"
                                >
                                    <SafeFormattedMessage
                                        tagName="span"
                                        {...(index === 0
                                            ? messages.eventTriggerConfiguration.orConditionFirstLabel
                                            : messages.eventTriggerConfiguration.orConditionOtherLabel)}
                                    />

                                    <Trash2 size={18} color="var(--color-grey)" onClick={handleDelete(g)} />
                                </Stack>
                                <Condition
                                    conditions={g}
                                    handleChangeConditions={handleChangeCondition}
                                    handleNewCondition={handleNewConditionItem}
                                    handleEditCondition={handleEditCondition}
                                />
                            </Fragment>
                        ))}
                    </Stack>
                    <Button
                        type="primary-light"
                        onClick={handleAddConditionGroup}
                        className="flows-eventTriggerConfiguration-newConditionGroup"
                    >
                        <Plus size={18} />
                        <SafeFormattedMessage {...messages.eventTriggerConfiguration.newConditionGroupLabel} />
                    </Button>
                </>
            )}
        </>
    )
}
