import { ValidationErrorsIBAN, electronicFormatIBAN, validateBIC, validateIBAN } from "ibantools"
import { MessageDescriptor, defineMessages } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import {
    BankTransferFormState,
    CardFormState,
    DirectDebitFormState,
    ValidationSchema,
} from "~/domains/payment/payment-method-details/types"
import { CountryCode } from "~/types"

const messages = defineMessages({
    requiredIbanOrBankAccountNumber: {
        id: "payment.formsValidationSchemas.ibanOrBankAccountNumber.required",
        defaultMessage: "Either an IBAN or a bank account number is required",
    },
    invalidIban: {
        id: "payment.formsValidationSchemas.iban.invalid",
        defaultMessage: "IBAN is invalid",
    },
    requiredBankAccountNumber: {
        id: "payment.formsValidationSchemas.bankAccountNumber.required",
        defaultMessage: "Bank account number is required",
    },
    invalidBicSwift: {
        id: "payment.formsValidationSchemas.bicSwift.invalid",
        defaultMessage: "BIC / SWIFT is invalid",
    },
    requiredCountry: {
        id: "payment.formsValidationSchemas.country.required",
        defaultMessage: "Country is required",
    },
    requiredCardNumber: {
        id: "payment.formsValidationSchemas.cardNumber.required",
        defaultMessage: "Card number is required",
    },
    invalidCardNumber: {
        id: "payment.formsValidationSchemas.cardNumber.invalid",
        defaultMessage: "Please enter the first 6 and the last 4 digits of the card number",
    },
    requiredCardExpirationDate: {
        id: "payment.formsValidationSchemas.cardExpirationDate.required",
        defaultMessage: "Card expiration date is required",
    },
    invalidCardExpirationDate: {
        id: "payment.formsValidationSchemas.cardExpirationDate.invalid",
        defaultMessage: "Card expiration date is invalid",
    },
    requiredMandateReference: {
        id: "payment.formsValidationSchemas.mandateReference.required",
        defaultMessage: "Mandate reference is required",
    },
    requiredMandateDate: {
        id: "payment.formsValidationSchemas.mandateDate.required",
        defaultMessage: "Mandate date is required",
    },
    invalidMandateDate: {
        id: "payment.formsValidationSchemas.mandateDate.invalid",
        defaultMessage: "Mandate date is invalid",
    },
    invalidKid: {
        id: "payment.formsValidationSchemas.kid.invalid",
        defaultMessage: "KID is invalid",
    },
})

const ibanErrorMessages: Record<ValidationErrorsIBAN, MessageDescriptor> = {
    [ValidationErrorsIBAN.NoIBANProvided]: {
        id: "iban.validationCheck.errors.noIbanProvided",
        defaultMessage: "No IBAN provided",
    },
    [ValidationErrorsIBAN.NoIBANCountry]: {
        id: "iban.validationCheck.errors.noIbanCountry",
        defaultMessage: "Invalid country code in IBAN",
    },
    [ValidationErrorsIBAN.WrongBBANLength]: {
        id: "iban.validationCheck.errors.wrongBBANLength",
        defaultMessage: "Incorrect IBAN length",
    },
    [ValidationErrorsIBAN.WrongBBANFormat]: {
        id: "iban.validationCheck.errors.wrongBBANFormat",
        defaultMessage: "Incorrect IBAN format",
    },
    [ValidationErrorsIBAN.ChecksumNotNumber]: {
        id: "iban.validationCheck.errors.checksumNotNumber",
        defaultMessage: "IBAN checksum contains non-numeric characters",
    },
    [ValidationErrorsIBAN.WrongIBANChecksum]: {
        id: "iban.validationCheck.errors.wrongIBANChecksum",
        defaultMessage: "Incorrect IBAN checksum",
    },
    [ValidationErrorsIBAN.WrongAccountBankBranchChecksum]: {
        id: "iban.validationCheck.errors.wrongAccountBankBranchChecksum",
        defaultMessage: "Incorrect bank or branch account checksum",
    },
    [ValidationErrorsIBAN.QRIBANNotAllowed]: {
        id: "iban.validationCheck.errors.qrIbanNotAllowed",
        defaultMessage: "QR IBANs are not allowed",
    },
}

export const validateKidNumber = (kid: string): boolean => {
    // Ensure the KID number is only digits
    if (!/^\d+$/.test(kid)) {
        return false
    }

    const length = kid.length
    const controlDigit = parseInt(kid[length - 1], 10)
    const baseNumber = kid.slice(0, length - 1)

    // Calculate the control digit using Modulus 11
    let multiplier = 2
    let sum = 0

    for (let i = baseNumber.length - 1; i >= 0; i--) {
        sum += parseInt(baseNumber[i], 10) * multiplier
        multiplier = multiplier === 7 ? 2 : multiplier + 1
    }

    const remainder = sum % 11
    const calculatedControlDigit = remainder === 0 ? 0 : 11 - remainder

    // If remainder is 1, the KID number is considered invalid
    if (calculatedControlDigit === 10) {
        return false
    }

    // Check if the control digit matches
    return calculatedControlDigit === controlDigit
}

export const bankTransferFormValidationSchema: ValidationSchema<BankTransferFormState> = {
    iban: {
        custom: (value: string, values: BankTransferFormState | undefined) => {
            if (!value) {
                return {
                    value: values?.bankAccountNumber ? undefined : "required",
                    message: !values?.bankAccountNumber ? (
                        <SafeFormattedMessage {...messages.requiredIbanOrBankAccountNumber} />
                    ) : undefined,
                }
            }

            const electronicIban = electronicFormatIBAN(value)
            const result = validateIBAN(electronicIban ?? "")

            return result.valid
                ? {
                      value: undefined,
                  }
                : {
                      value: `Error codes: ${result.errorCodes.join(", ")}`,
                      message: <SafeFormattedMessage {...ibanErrorMessages[result.errorCodes[0]]} />,
                  }
        },
    },
    bankAccountNumber: {
        custom: (value: string, values: BankTransferFormState | undefined) => {
            if (!value && !values?.iban) {
                return {
                    value: "required",
                    message: <SafeFormattedMessage {...messages.requiredIbanOrBankAccountNumber} />,
                }
            }

            return {
                value: undefined,
            }
        },
    },
    bicSwift: {
        custom: (value: string) => {
            if (!value) {
                return { value: undefined }
            }

            const result = validateBIC(value ?? "")

            return result.valid
                ? {
                      value: undefined,
                  }
                : {
                      value: `Error codes: ${result.errorCodes.join(", ")}`,
                      message: <SafeFormattedMessage {...messages.invalidBicSwift} />,
                  }
        },
    },
    kid: {
        custom: (value: string, values: BankTransferFormState | undefined) => {
            if (values?.country !== CountryCode.NO) {
                return { value: undefined }
            }

            const isValid = value && validateKidNumber(value)

            return isValid
                ? {
                      value: undefined,
                  }
                : {
                      value: "invalid",
                      message: <SafeFormattedMessage {...messages.invalidKid} />,
                  }
        },
    },
    bankKey: {
        maxLength: {
            value: 10,
        },
    },
}

export const cardPaymentMethodFormValidationSchema: ValidationSchema<CardFormState> = {
    cardNumber: {
        required: {
            value: true,
            message: <SafeFormattedMessage {...messages.requiredCardNumber} />,
        },
        minLength: {
            value: 10,
            message: <SafeFormattedMessage {...messages.invalidCardNumber} />,
        },
    },
    cardExpirationDate: {
        required: {
            value: true,
            message: <SafeFormattedMessage {...messages.requiredCardExpirationDate} />,
        },
        date: {
            value: true,
            message: <SafeFormattedMessage {...messages.invalidCardExpirationDate} />,
        },
    },
}

export const directDebitFormValidationSchema: ValidationSchema<DirectDebitFormState> = {
    bankAccountNumber: {
        required: {
            value: true,
            message: <SafeFormattedMessage {...messages.requiredBankAccountNumber} />,
        },
    },
    mandateReference: {
        required: {
            value: true,
            message: <SafeFormattedMessage {...messages.requiredMandateReference} />,
        },
    },
    mandateDate: {
        required: {
            value: true,
            message: <SafeFormattedMessage {...messages.requiredMandateDate} />,
        },
        date: {
            value: true,
            message: <SafeFormattedMessage {...messages.invalidMandateDate} />,
        },
    },
}
