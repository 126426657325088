import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { purchaseOrdersApi } from "~/domains/transactions/purchase-orders/api"
import { purchaseOrdersActions } from "~/domains/transactions/purchase-orders/store/purchaseOrdersSlice"
import { ListPurchaseOrders } from "~/domains/transactions/purchase-orders/types"
import { useAppDispatch } from "~/store/hooks"
import { OrganizationId } from "~/types"

const messages = defineMessages({
    purchaseOrderDeleted: {
        id: "purchase.orders.purchaseOrderDeleted",
        defaultMessage:
            "Your purchase {count, plural, =0 {} one {order has} other {orders have}} been successfully deleted.",
    },
    unableToDeletePurchaseOrder: {
        id: "purchase.orders.unableToDeletePurchaseOrder",
        defaultMessage:
            "An error occured: your purchase {count, plural, =0 {} one {order} other {orders}} could not be deleted.",
    },
    unableToDeleteSomePurchaseOrder: {
        id: "purchase.orders.unableToDeleteSomePurchaseOrder",
        defaultMessage:
            "An error occured: {count} purchase {count, plural, =0 {} one {order} other {orders}} could not be deleted.",
    },
})

export const useDeletePurchaseOrders = () => {
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()

    return async (purchaseOrders: ListPurchaseOrders, organizationId: OrganizationId) => {
        const results = await Promise.allSettled(
            purchaseOrders.map(async (purchaseOrder) => {
                await purchaseOrdersApi.delete(organizationId, purchaseOrder.id)
                return purchaseOrder
            })
        )

        const successDeletedPOs = results.reduce((acc, r) => {
            if (r.status === "fulfilled") {
                acc.push(r.value)
            }
            return acc
        }, [] as ListPurchaseOrders)

        const failDeletedLength = results.length - successDeletedPOs.length
        dispatch(purchaseOrdersActions.deletePurchaseOrders(successDeletedPOs))
        if (failDeletedLength > 0) {
            toast.error(
                formatMessage(
                    failDeletedLength === results.length
                        ? messages.unableToDeletePurchaseOrder
                        : messages.unableToDeleteSomePurchaseOrder,
                    { count: failDeletedLength }
                )
            )
        } else {
            toast.success(formatMessage(messages.purchaseOrderDeleted, { count: purchaseOrders.length }))
        }
        return true
    }
}
