import { Tag } from "react-feather"

import { AssignTagGroupConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { RegularNode } from "~/domains/orchestration/flows/components/nodes"
import { adaptEntityTriggerProperties, adaptEventTriggerProperties } from "~/domains/orchestration/flows/core"
import { Trigger, TriggerMode } from "~/domains/orchestration/flows/types"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    AssignTagGroupNode as AssignTagGroupNodeType,
    EditorNode,
    Node,
    NodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const assignTagGroupNode = (trigger: Trigger): Omit<AssignTagGroupNodeType, keyof Node> => {
    const properties =
        trigger.mode === TriggerMode.ENTITY
            ? adaptEntityTriggerProperties(trigger.type, trigger.slug)
            : adaptEventTriggerProperties(trigger.type, trigger.slug)
    if (!properties)
        return {
            objectId: "",
            objectType: null,
            nodes: [],
            nextNode: null,
            type: NodeType.ASSIGN_TAG_GROUP_NODE,
        }
    return {
        objectId: properties.objectId || "",
        objectType: properties.objectType,
        nodes: [],
        nextNode: null,
        type: NodeType.ASSIGN_TAG_GROUP_NODE,
    }
}

const advancedFields: AllAdvancedFieldsUnion[] = [AllAdvancedFieldsUnion.OBJECT_ID, AllAdvancedFieldsUnion.OBJECT_TYPE]

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.ASSIGN_TAG_GROUP_NODE) return false
    return Boolean(node.objectId && node.objectType && node.nodes.length)
}

const assignTagGroupConfig: Configuration<NodeType.ASSIGN_TAG_GROUP_NODE> = {
    type: NodeType.ASSIGN_TAG_GROUP_NODE,
    baseSlug: "assign_tag_group",
    Icon: Tag,
    Configuration: AssignTagGroupConfiguration,
    advancedFields,
    Node: RegularNode,
    factory: (props: FactoryProps): AssignTagGroupNodeType => ({
        ...assignTagGroupNode(props.trigger),
        ...createCommonNodeProperties(props),
    }),
    validateNode,
}

export { assignTagGroupConfig, advancedFields as assignTagGroupAdvancedFields }
