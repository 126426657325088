/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { PayloadAction } from "@reduxjs/toolkit"
import * as Sentry from "@sentry/react"
import { call, put, select } from "redux-saga/effects"

import { userApi } from "~/domains/identity/account/api/userApi"
import { WorkflowApprovePayload } from "~/domains/orchestration/flows-v0/types"
import { invoiceApi } from "~/domains/transactions/invoices/api/invoiceApi"
import { invoiceActions, selectInvoiceId, selectInvolvedPeople } from "~/store/invoice/invoiceSlice"
import { InvoiceRequestI, InvolvedPeopleI, OrganizationId, UserI } from "~/types"

export function* fetchInvolvedUsers() {
    try {
        const involvedPeople: InvolvedPeopleI[] = yield select(selectInvolvedPeople)
        const userIds = involvedPeople.map((person) => person.userId)
        const users: UserI[] = yield call(userApi.findUsersByIds, [...new Set(userIds)])
        yield put(invoiceActions.fetchInvolvedUsersSuccess(users))
    } catch (error) {
        Sentry.captureException(error)
        yield put(invoiceActions.fetchInvolvedUsersFailed())
    }
}

export function* sendRequest(action: PayloadAction<InvoiceRequestI>) {
    try {
        yield call(invoiceApi.sendRequest, action.payload)
        yield put(invoiceActions.sendRequestSuccess(action.payload))
    } catch (error) {
        Sentry.captureException(error)
        yield put(invoiceActions.sendRequestFailed())
    }
}

export function* validateInvoice(action: PayloadAction<{ comment: string; tags: string[] }>) {
    try {
        const id = yield select(selectInvoiceId)
        const comment = action.payload.comment
        const tags = action.payload.tags
        yield call(invoiceApi.validate, {
            id,
            comment,
            tags,
        })
        yield put(invoiceActions.validateInvoiceSuccess())
    } catch (error) {
        Sentry.captureException(error)
        yield put(invoiceActions.validateInvoiceFailed())
    }
}

export function* approveInvoice(action: PayloadAction<{ organizationId: OrganizationId } & WorkflowApprovePayload>) {
    try {
        const { ...payload } = action.payload
        yield call(invoiceApi.approve, payload)
        yield put(invoiceActions.approveInvoiceSuccess())
    } catch (error) {
        Sentry.captureException(error)
        yield put(invoiceActions.approveInvoiceFailed())
    }
}

export function* rejectInvoice(action: PayloadAction<{ organizationId: OrganizationId } & WorkflowApprovePayload>) {
    try {
        const { ...payload } = action.payload
        yield call(invoiceApi.refuse, payload)
        yield put(invoiceActions.approveInvoiceSuccess())
    } catch (error) {
        Sentry.captureException(error)
        yield put(invoiceActions.approveInvoiceFailed())
    }
}

export function* setAsPaid(action: PayloadAction<string>) {
    try {
        yield call(invoiceApi.markAsPaid, action.payload)
        yield put(invoiceActions.setAsPaidSuccess())
    } catch (error) {
        Sentry.captureException(error)
        yield put(invoiceActions.setAsPaidFailed())
    }
}
