import { HandleProps, Position } from "@xyflow/react"
import type { NodeProps, Node as NodeType } from "@xyflow/react"
import React, { FC } from "react"

import { SafeFormattedMessage } from "~/components"
import { messages } from "~/domains/orchestration/flows/locale"
import { IfNode as IfNodeType } from "~/domains/orchestration/flows/types"

import { Node } from "./Node"

export const IfNode: FC<NodeProps<NodeType<IfNodeType>>> = ({ data, selected }) => {
    const handles: HandleProps[] = [
        {
            type: "source",
            isConnectableStart: true,
            id: `${data.slug}-failure`,
            position: Position.Left,
            className: "flows-node-handle-left",
            children: <SafeFormattedMessage tagName="span" {...messages.ifNode.falseLabel} />,
        },
        {
            type: "source",
            isConnectableStart: true,
            id: `${data.slug}-success`,
            position: Position.Right,
            className: "flows-node-handle-right",
            children: <SafeFormattedMessage tagName="span" {...messages.ifNode.trueLabel} />,
        },
        {
            type: "target",
            position: Position.Top,
        },
    ]

    return (
        <Node
            type={data.type}
            name={data.name}
            information={data.metadata?.additionalInformation}
            handles={handles}
            selected={selected}
            error={Boolean(data.error)}
            slug={data.slug}
        />
    )
}
