import { ENTITY_TRIGGER_PROPERTY_MAP, SIDE_BAR_WIDE_WIDTH } from "~/domains/orchestration/flows/constants"
import { SIDE_BAR_WIDTH } from "~/domains/orchestration/flows/constants"
import { nodeConfig } from "~/domains/orchestration/flows/core/config"
import {
    Configuration,
    CurrencyConversionMode,
    EmailMailType,
    EntityType,
    Event,
    NodeType,
    Trigger,
    TriggerMode,
    isEntityTriggerNode,
    isEventTriggerNode,
} from "~/domains/orchestration/flows/types"
import { EditorNode } from "~/domains/orchestration/flows/types"
import { SideBarState } from "~/domains/orchestration/flows/types"
import { isExpression } from "~/domains/orchestration/flows/utils/expression"
import { isEmailValid } from "~/utils/email"

/**
 *
 * @param nodeType
 * @param currentNodes
 * @returns string
 * @description generate a readable id for a node
 */
const generateReadableId = (nodeType: NodeType, currentNodes: EditorNode[]): string => {
    const configuration: Configuration<NodeType> = nodeConfig[nodeType] as Configuration<NodeType>

    const BaseSlug = configuration.baseSlug
    if (!BaseSlug) {
        throw new Error(`Unknown node type: ${nodeType}`)
    }

    const existingNodes = currentNodes.filter((node) => node.type === nodeType)

    const BaseSlugFilter = (id: string) => (node: EditorNode) => node.slug.startsWith(id)
    const isSameBaseSlug = BaseSlugFilter(BaseSlug)

    const getIdCountFromNode = (node: EditorNode) => {
        const match = node.slug.match(new RegExp(`${BaseSlug}_(\\d+)$`))
        return match ? parseInt(match[1], 10) : 0
    }

    const existingIds = existingNodes.filter(isSameBaseSlug).map(getIdCountFromNode)

    const maxNumber = Math.max(0, ...existingIds)
    return `${BaseSlug}_${maxNumber + 1}`
}

/**
 *
 * @param email
 * @returns EmailMailType
 * @description get the type of the email address (valid, expression or error)
 */
const getEmailMailType = (email: string): EmailMailType => {
    // Check if it's an expression (enclosed in {{ }})
    if (isExpression(email)) {
        return EmailMailType.EXPRESSION
    }

    // Check if it's a valid email

    return isEmailValid(email) ? EmailMailType.VALID : EmailMailType.ERROR
}

/**
 *
 * @param state
 * @returns number
 * @description get the width of the sidebar
 */
const getSideBarWidth = (state: SideBarState) => {
    if (state === SideBarState.CLOSED) return 0
    if (state === SideBarState.OPEN) return SIDE_BAR_WIDTH
    if (state === SideBarState.WIDE) return SIDE_BAR_WIDE_WIDTH
    return 0
}

/**
 *
 * @param trigger
 * @param nodes
 * @returns string
 * @description get the slug of the trigger
 */
const getTriggerSlug = (trigger: Event | EntityType | null, nodes: EditorNode[]) => {
    if (!trigger) return null
    const eventTriggerNode = nodes.find(isEventTriggerNode)
    const entityTriggerNode = nodes.find(isEntityTriggerNode)

    if (eventTriggerNode) return "trigger_event"
    if (entityTriggerNode) return entityTriggerNode.slug || "entity_trigger_1"

    return null
}

const getTriggerId = (trigger: Event | EntityType | null, nodes: EditorNode[]) => {
    if (!trigger) return null
    const eventTriggerNode = nodes.find(isEventTriggerNode)
    const entityTriggerNode = nodes.find(isEntityTriggerNode)

    if (eventTriggerNode) return "trigger_event"
    if (entityTriggerNode) return entityTriggerNode.slug || `entity_trigger_`

    return null
}

/**
 *
 * @param nodes
 * @returns boolean
 * @description check if the entity trigger node has currency conversion
 */
const getCurrencyConversion = (nodes: EditorNode[]) => {
    const entityTriggerNode = nodes.find(isEntityTriggerNode)
    if (!entityTriggerNode || entityTriggerNode.entity?.type !== EntityType.PURCHASE_ORDER) return null
    return entityTriggerNode.entity?.currencyConversion
}

/**
 *
 * @param nodes
 * @param currency
 * @returns string
 * @description get the amount path
 * if PO or PR. The path is .totals.{currency}.amount.amount
 * if Invoice return .totalAmountDue.{currency}
 * if Entity has currency conversion return .convertedTotals.{currency}.amount.amount
 */
const getAmountPath = (trigger: Trigger, currency: string) => {
    const prOrPoAmountPath = `totals.${currency}.amount.amount`
    const otherAmountPath = `totalAmountDue.${currency}`
    const convertedAmountPath = `convertedTotals.amount.amount`
    const isPurchaseOrderOrPurchaseRequest =
        trigger.type === EntityType.PURCHASE_ORDER || trigger.type === EntityType.PURCHASE_REQUEST
    const hasCurrencyConversion =
        isPurchaseOrderOrPurchaseRequest && trigger.currencyConversion?.type === CurrencyConversionMode.ENABLED

    if (trigger.mode === TriggerMode.ENTITY && trigger.type) {
        const basePath = `$${trigger.slug}.${ENTITY_TRIGGER_PROPERTY_MAP[trigger.type]}`
        if (hasCurrencyConversion) return `${basePath}.${convertedAmountPath}`
        if (isPurchaseOrderOrPurchaseRequest) return `${basePath}.${prOrPoAmountPath}`
        if (trigger.type === EntityType.PARTNERSHIP) return `${basePath}.${otherAmountPath}`
    }
    if (trigger.mode === TriggerMode.EVENT) {
        if (trigger.type?.toLocaleLowerCase().startsWith("invoice")) return `$${trigger.slug}.${otherAmountPath}`
        if (trigger.type?.toLocaleLowerCase().includes("purchase")) return `$${trigger.slug}.${prOrPoAmountPath}`
    }
    return null
}

export {
    generateReadableId,
    getEmailMailType,
    getSideBarWidth,
    getTriggerSlug,
    getTriggerId,
    getCurrencyConversion,
    getAmountPath,
}
