import { Alert, Stack } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { defineMessages } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"

import { SafeFormattedMessage } from "~/components"
import { CompanyAutocomplete } from "~/domains/identity/organization/components/CompanyAutocomplete"
import { ManualCompanyInputTrigger } from "~/domains/identity/organization/components/CreateOrganizationContainer/ManualInputContainer"
import { CompanyResult } from "~/domains/identity/organization/types/CompanyAutocomplete"
import { PARTNER_DETAILS_ROUTE } from "~/domains/identity/partners/routes"
import { usePartnersAsOrganizations } from "~/domains/identity/partners/store/hooks"
import { PartnershipType } from "~/domains/identity/partners/types"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { CountryCode, EstablishmentI, ImportInvoiceCompanyInfoI } from "~/types"

const messages = defineMessages({
    // TODO: improve the text displayed in the label: "siret" if FR code, else should be "establishments"
    alreadyPartnership: {
        id: "partners.bookofrelations.modal.create.alreadyPartnership",
        defaultMessage: "This organization is already in your partners list",
    },
    viewDetails: {
        id: "partners.bookofrelations.modal.create.viewDetails",
        defaultMessage: "View details",
    },
    alreadySupplier: {
        id: "partners.bookofrelations.modal.create.alreadySupplier",
        defaultMessage:
            "This organization is already in your partners list as a vendor, you can still add it as a client",
    },
    alreadyBuyer: {
        id: "partners.bookofrelations.modal.create.alreadyBuyer",
        defaultMessage:
            "This organization is already in your partners list as a client, you can still add it as a vendor",
    },
})

interface PartnerOrganizationSelectorProps {
    companyResult: CompanyResult | undefined
    setCompanyResult: (companyResult: CompanyResult | undefined) => void
    partnerOrganization: ImportInvoiceCompanyInfoI
    onManualImportClick: () => void
    updateData: (data: Partial<ImportInvoiceCompanyInfoI>) => void
    isSelectedOrganizationAlreadySupplier: boolean
    isSelectedOrganizationAlreadyBuyer: boolean
}

export const PartnerOrganizationSelector: React.FC<PartnerOrganizationSelectorProps> = ({
    companyResult,
    setCompanyResult,
    partnerOrganization,
    onManualImportClick,
    updateData,
    isSelectedOrganizationAlreadySupplier,
    isSelectedOrganizationAlreadyBuyer,
}) => {
    const [countryCode, setCountryCode] = useState(CountryCode.FR)

    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const { organizations: partnersOrganizations } = usePartnersAsOrganizations(
        PartnershipType.SUPPLIER,
        currentOrganizationId || ""
    )

    const navigate = useNavigate()

    useEffect(() => {
        const cc = partnerOrganization?.countryCode
        if (cc) {
            setCountryCode(cc)
        }
    }, [partnerOrganization])

    const updateDataIfChanged = useCallback(
        (newData: Partial<ImportInvoiceCompanyInfoI> | undefined) => {
            if (!newData) return

            const keys = Object.keys(newData) as (keyof ImportInvoiceCompanyInfoI)[]
            const hasChanged = keys.some((key) => partnerOrganization[key] !== newData[key])

            if (hasChanged) {
                updateData(newData)
            }
        },
        [partnerOrganization, updateData]
    )

    const onCompanyChange = (company: CompanyResult | undefined, establishmentsToSelect?: EstablishmentI) => {
        setCompanyResult(company)
        if (!company) return updateDataIfChanged(undefined)
        if (company.type === "org") {
            updateDataIfChanged({
                organizationId: company.value.id,
                countryCode: company.value.registration.countryCode,
                name: company.value.name,
                registrationNumber:
                    company.value.registration.dunsNumber ??
                    company.value.registration.preferredRegistrationNumber?.registrationNumber,
            })
        } else if (company.type === "wp") {
            const registrationNumber =
                establishmentsToSelect?.id ?? (company.value.establishments?.length ? undefined : company.value.id)
            updateDataIfChanged({
                organizationId: undefined,
                countryCode: company.value.countryCode,
                name: company.value.name,
                registrationNumber,
                establishments: company.value.establishments,
            })
        }
    }

    const handleGotoParnter = () => {
        navigate(generatePath(PARTNER_DETAILS_ROUTE, { organizationId: partnerOrganization.organizationId }))
    }

    let alertMessage = messages.alreadyBuyer
    if (isSelectedOrganizationAlreadySupplier && isSelectedOrganizationAlreadyBuyer) {
        alertMessage = messages.alreadyPartnership
    } else if (isSelectedOrganizationAlreadySupplier) {
        alertMessage = messages.alreadySupplier
    }

    return (
        <Stack gap={0}>
            <Stack gap={2}>
                <CompanyAutocomplete
                    company={companyResult}
                    setCompany={onCompanyChange}
                    countryCode={countryCode}
                    setCountryCode={setCountryCode}
                    organizations={partnersOrganizations}
                    suggestedCompanies={[]}
                    displayType="partner_creation"
                />
                {(isSelectedOrganizationAlreadyBuyer || isSelectedOrganizationAlreadySupplier) && (
                    <Alert severity="info">
                        <SafeFormattedMessage {...alertMessage} />
                        <a onClick={handleGotoParnter} className="text-link ml-12">
                            <SafeFormattedMessage {...messages.viewDetails} />
                        </a>
                    </Alert>
                )}
            </Stack>
            <ManualCompanyInputTrigger onClick={onManualImportClick} />
        </Stack>
    )
}
