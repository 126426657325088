import axiosClient from "~/api/axiosClient"
import {
    BudgetCreatePayloadI,
    BudgetDataI,
    BudgetDataWithMetricsI,
    BudgetUpdatePayloadI,
    TransactionType,
} from "~/domains/transactions/budget/types"
import { OrganizationId } from "~/types"
import { ImportBatchResponseI } from "~/types/ImportBatch"
import { Result } from "~/types/Result"

const BASE_URL = import.meta.env.VITE_API_BUDGET_URL
const IMPORT_BASE_URL = import.meta.env.VITE_API_IMPORT_FROM_FILES_URL

export const budgetApi = {
    fetchBudgetsData(
        organizationId: OrganizationId,
        withMetrics = false,
        withSubBudgets = false
    ): Promise<BudgetDataI[]> {
        const url = `${BASE_URL}organizations/${organizationId}/budgets?withMetrics=${withMetrics}&withSubBudgets=${withSubBudgets}`
        return axiosClient.get(url)
    },

    fetchBudgetDetails(organizationId: OrganizationId, budgetId: string): Promise<BudgetDataI> {
        const url = `${BASE_URL}organizations/${organizationId}/budgets/${budgetId}`
        return axiosClient.get(url)
    },

    createBudget(organizationId: OrganizationId, budgetPayload: BudgetCreatePayloadI): Promise<BudgetDataI> {
        const url = `${BASE_URL}organizations/${organizationId}/budgets`
        return axiosClient.post(url, budgetPayload)
    },

    updateBudget: async (
        organizationId: OrganizationId,
        budgetId: string,
        budgetPayload: BudgetUpdatePayloadI
    ): Promise<string> => {
        const url = `${BASE_URL}organizations/${organizationId}/budgets/${budgetId}`
        return axiosClient.put(url, budgetPayload)
    },

    deleteBudget(organizationId: OrganizationId, budgetId: string): Promise<string> {
        const url = `${BASE_URL}organizations/${organizationId}/budgets/${budgetId}`
        return axiosClient.delete(url)
    },

    importBatch(organizationId: string, attachedFile: File): Promise<Result<ImportBatchResponseI>> {
        const formData = new FormData()
        formData.append("file", attachedFile)
        formData.append("organizationId", organizationId)

        return axiosClient.post(`${IMPORT_BASE_URL}budget/upload-file`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
    },

    getBudgetByTransaction(
        organizationId: OrganizationId,
        transactionId: string,
        transactionType: TransactionType,
        withMetrics: boolean = false
    ): Promise<BudgetDataWithMetricsI[]> {
        const url = `${BASE_URL}organizations/${organizationId}/transactions/budget?transactionRefId=${transactionId}&transactionType=${transactionType}&withMetrics=${withMetrics}`
        return axiosClient.get(url)
    },

    // Not used at the moment but available endpoints:

    // fetchBudgetMetrics(budgetId: string): Promise<BudgetMetricsI> {
    //     const url = `${BASE_URL}organization/${organizationId}/budgets/${budgetId}/metrics`
    //     return (axiosClient.get(url))
    // },

    // fetchBudgetAmountHistory(budgetId: string): Promise<any[]> {
    //     const url = `${BASE_URL}organization/${organizationId}/budgets/${budgetId}/amountHistory`
    //     return (axiosClient.get(url))
    // },

    // addBudgetAmount(budgetId: string, amountPayload: any): Promise<void> {
    //     const url = `${BASE_URL}organization/${organizationId}/budgets/${budgetId}/amount`
    //     return axiosClient.post(url, amountPayload)
    // },

    // fetchBudgetTransactions(budgetId: string): Promise<any[]> {
    //     const url = `${BASE_URL}organization/${organizationId}/budgets/${budgetId}/transactions`
    //     return (axiosClient.get(url))
    // },
}
